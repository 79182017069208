import React, { useState, useEffect, useContext } from "react";
import "../css/PrivateWrapper.css";
import { Outlet, useNavigate } from "react-router-dom";
import LeftNavigation_Component from "./LeftNavigation_Component";
import Modal from "react-modal";
import { AutoPortalContext } from "../Context";
import { Button, Dropdown, Modal as Mod } from "react-bootstrap";
import UserNotificationCompo from "./UserNotificationCompo";
import axios from "axios";
import img from "../ppp.png";
import Avatar from "react-avatar-edit";
import ImpersonateCompo from "./ImpersonateCompo";
import CogCompo from "./CogCompo";
import UpdateJsonCompo from "./UpdateJsonCompo";
import LogoutModal from "./LogoutModal";
import CommonModal from "./CommonModal";

const PrivateWrapper = () => {
  const {
    token,
    loca,
    flag,
    setFlag,
    setShowContainer,
    userDetails,
    msg,
    cnt,
    setCnt,
    showAll,
    setShowAll,
    appName,
    setheadHeight,
    impersonate,
    adminVerified,
    user
  } = useContext(AutoPortalContext);
  const navigation = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showEye, setShowEye] = useState(false);
  const [showCog, setShowCog] = useState(false);
  const [showLog, setShowLog] = useState(false);
  const [logContent, setLogContent] = useState("");
  const navigate = useNavigate();
  const [src, setSrc] = useState(false);
  const [pfile, setProfile] = useState([]);
  const [imageCrop, setImageCrop] = useState(false);
  const [pview, setPview] = useState(false);
  const [updateJsonRec, setUpdateJsonRec] = useState([]);
  const [currentRec, setCurrentRec] = useState();
  const [unselectedRec, setUnselectedRec] = useState([]);
  const [showMenu, setShowMenu] = useState(false);
  const profileFinal = pfile.map((item) => item.pview);

  // let isVerified = localStorage.getItem("autoToken") !== null ? true : false;

  const onClose = () => {
    setPview(null);
  };

  const goBack = () => {
    navigation(-1);
  };

  const onCrop = (view) => {
    setPview(view);
  };

  const saveCropImage = () => {
    setProfile([...pfile, { pview }]);
    setImageCrop(false);
  };


  const logOut = () => {
    localStorage.removeItem("autoToken");
    localStorage.clear();
    navigation("/login");
  };

  const Home = () => {
    console.log(user);
    
    if (window.screen.width < 600) {
      setFlag(false);
    }
    navigation(user+"/dashboard");
  };

  const profile = () => {
    navigation("/profilePage");
  };

  const opennav = () => {
    document.getElementById("mySidebar").style.width = "100%";
    document.getElementById("mySidebar").style.marginLeft = "0";
    setFlag(true);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const cancelNotification = () => {
    // console.log("open");
    setShowNotification(false);
  };

  const cancelImpersonation = () => {
    // console.log("open");
    setShowEye(false);
  };

  const cancelCog = () => {
    // console.log("open");
    setShowCog(false);
  };
  const cancelLog = () => {
    // console.log("open");
    setShowLog(false);
  };

  const getInitial = () => {
    axios
      .get(loca + "/lom/get/updatejson", {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let res = resp.data;
          console.log(res);
          let rec = res.record;
          let arr = [];
          let curr = undefined;
          for (let i = 0; i < rec.length; i++) {
            if (rec[i].selected === "true") {
              curr = rec[i];
            } else {
              arr.push(rec[i]);
            }
          }
          setCurrentRec(curr);
          setUnselectedRec([...arr]);
          // setUpdateJsonRec(res.record);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const clearLog = () => {
    console.log("getLog");
    const url = "/lom/delete/log";
    axios
      .get(loca + url, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          const data = resp.data;
          setLogContent(data.content);
          console.log(data);
          // getApplicationsDetails();
        },
        (error) => {
          navigation("/error");
          console.log(error);
        }
      );
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const eyefn = () => {
    setShowEye(true);
  };
  const cogfn = () => {
    setShowCog(true);
  };
  const logFun = () => {
    getLog();
    console.log("logfun");
    setShowLog(true);
  };

  const cogChangefn = (val) => {
    let url = "";
    if (val === "true") {
      url = "/lom/set/visible-all";
    } else {
      url = "/lom/set/visible";
    }
    axios
      .get(loca + url, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          const userSet = resp.data;
          setShowAll(val);
          localStorage.setItem("showAll", val);
          // getApplicationsDetails();
        },
        (error) => {
          navigation("/error");
          console.log(error);
          setShowAll(val);
          localStorage.setItem("showAll", val);
        }
      );
  };
  const getLog = () => {
    console.log("getLog");
    const url = "/lom/get/log";
    axios
      .get(loca + url, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          const data = resp.data;
          setLogContent(data.content);
          console.log(data);
          // getApplicationsDetails();
        },
        (error) => {
          navigation("/error");
          console.log(error);
        }
      );
  };

  useEffect(() => {
    let h = document.getElementById("topbar")?.getBoundingClientRect().height;
    if (h > 10) {
      setheadHeight(h);
    }
  }, []);

  useEffect(() => {
    if (adminVerified && userDetails) {
      console.log(adminVerified, userDetails);
      if (userDetails.isadmin !== "true" && impersonate) {
        navigate("/user/dashboard");
        return;
      }
    }
  }, [adminVerified, userDetails]);

  const navModals = (show, hide, title, body, footer) => {
    return (
      <CommonModal show={show} hide={hide} title={title} body={body} footer={footer} />
    );
  };
  console.log(adminVerified, userDetails);

  if (adminVerified && userDetails) {
    return (
      <>
        <div>
          {console.log("Private Wrapper")
          }
          <div id="topbar" className="mainTopBar sticky-top">
            <div
              style={
                showAll === "true"
                  ? { background: "red" }
                  : appName !== "autoportal"
                    ? { background: "cadetblue" }
                    : {}
              }
              className="disfl mainbgcolor gutter"
            >
              <div className="fl1 no_pad disfl">
                {!flag && (
                  <div className="gutter">
                    <div className="arrow_icon no_pad">
                      <span>
                        <i
                          className="primary_clr backic fa fa-arrow-right  "

                          onClick={opennav}
                        ></i>
                      </span>
                    </div>
                  </div>
                )}
                <div className="marleft10 marright10">
                  <div className="homeicon">
                    <i
                      className="fa fa-home home_top"
                      data-toggle="tooltip"
                      title="Home"

                      onClick={() => Home()}
                    ></i>
                  </div>
                </div>
                <span className="text-light u-name text-capitalize ">
                  {appName}
                </span>
              </div>
              <div className="fl1 no_pad">
                <div className="rig">
                  <span className="u-name text-capitalize">
                    {console.log(userDetails)}
                    {userDetails.name}
                  </span>
                  <span className="usericon " onClick={() => navigation("/ai")}>
                    Ai
                  </span>
                  <span
                    className="usericon "
                    onClick={() => navigation("/application")}
                  >
                    S
                  </span>
                  <span className="usericon ">
                    <i
                      className="fa fa-user-circle-o"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Profile"

                      onClick={() => openModal()}
                    ></i>
                  </span>

                  <span className="usericon ">
                    <i
                      className="fa fa-bell"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Notification"
                      // aria-label="notification"

                      onClick={() => setShowNotification(true)}
                    >
                      {cnt > 0 && <span className="span-cnt">{cnt}</span>}
                    </i>
                  </span>

                  <span className="usericon ">
                    <i
                      className="fa fa-eye"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Impersonate"

                      onClick={() => eyefn()}
                    ></i>
                  </span>
                  <span className="usericon ">
                    <i
                      className="fa fa-cog"

                      onClick={() => {
                        cogfn();
                      }}
                    ></i>
                  </span>
                  <span className="usericon ">
                    <i
                      className="fa-brands fa-css3"

                      onClick={() => logFun()}
                    ></i>
                  </span>
                  <span className="usericon ">
                    <Dropdown id="updatedrop" as="span">
                      <span>
                        <Dropdown.Toggle
                          as="span"
                          className="text-white "
                          variant="Dark"
                          id="dropdown-basic"
                        >
                          <span

                            onClick={() => {
                              if (
                                !document
                                  .getElementById("updatemenu")
                                  ?.classList.contains("show")
                              ) {
                                console.log("run");
                                getInitial();
                              }
                            }}
                          >
                            U
                          </span>
                        </Dropdown.Toggle>
                      </span>
                      <Dropdown.Menu id="updatemenu" className="me-3">
                        <UpdateJsonCompo
                          unRec={unselectedRec}
                          currRec={currentRec}
                          getInitial={getInitial}
                        />
                      </Dropdown.Menu>
                    </Dropdown>
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div
              className="row fr"
              onClick={() => {
                setShowContainer(false);
              }}
            >
              {flag && (
                <div
                  className="g-overlay"
                  onClick={() => {
                    setFlag(false);
                  }}
                ></div>
              )}
              {console.log(flag)
              }
              <div
                id="windowscreen"
                className={flag ? "cl_small gx-0" : "cl_close"}
              >
                <LeftNavigation_Component />
              </div>
              <div className={flag ? "cl_big gx-0 g-blur" : "cl_bigClose gx-0"}>
                <Outlet />
              </div>
            </div>
          </div>
          <div>
            {modalIsOpen === true && (
              <LogoutModal
                modalIsOpen={modalIsOpen}
                closeModal={closeModal}
                username={userDetails.userId}
              />
            )}


            {navModals(
              showNotification,
              cancelNotification,
              "Notification",
              <UserNotificationCompo
                setCnt={setCnt}
                msg={msg}
                token={token}
                loca={loca}
              ></UserNotificationCompo>,
              <Button variant="secondary" onClick={cancelNotification}>
                Close
              </Button>
            )}
            {navModals(
              showEye,
              cancelImpersonation,
              "Impersonation",
              <ImpersonateCompo cancel={cancelImpersonation} />,
              <button
                className="btn btn-danger"
                variant="secondary"
                onClick={cancelImpersonation}
              >
                Close
              </button>
            )}
            {navModals(
              showCog,
              cancelCog,
              "Extras",
              <CogCompo cogChangefn={cogChangefn} cancelCog={cancelCog} />,
              <button
                className="btn btn-secondary"
                variant="secondary"
                onClick={cancelCog}
              >
                Close
              </button>
            )}
            {navModals(
              showLog,
              cancelLog,
              "Log File",
              <div>
                <pre
                  style={{
                    fontSize: "small",
                    whiteSpace: "pre-wrap",
                    overflowY: "auto",
                    maxHeight: "60vh",
                  }}
                >
                  {logContent}
                </pre>
              </div>,
              <>
                <button
                  className="btn btn-danger"
                  variant="secondary"
                  onClick={clearLog}
                >
                  Clear Log
                </button>
                <button
                  className="btn btn-danger"
                  variant="secondary"
                  onClick={cancelLog}
                >
                  Close
                </button>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
};

const customStyles = {
  content: {
    // zIndex:1000,
    width: "320px",
    height: "350px",
    height: "fit-content",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "11px",
    background: "#010154",
  },
};

export default PrivateWrapper;
