import React, { useContext, useEffect, useRef, useState } from "react";
import WorkInProgress from "./WorkInProgress";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { AutoPortalContext } from "../Context";

const KnowledgeCompo = () => {
  // Base Hooks
  const { loca, token } = useContext(AutoPortalContext);
  const navigation = useNavigate();
  const location = useLocation().state;
  const [searchParam, setSearchParam] = useSearchParams();

  // UseStates
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);
  const [pageError, setPageError] = useState(true);
  const [title, setTitle] = useState("");
  const [html, setHtml] = useState("");

  // UseRefs
  const tableName = useRef("");

  // Functions
  const getKnowledge = () => {
    tableName.current = searchParam.get("tableName");
    console.log(tableName.current);
      console.log(token);
      setLoading(true);
      if (tableName.current) {      
      axios
        .get(loca + "/lom/get/knowledge/" + tableName.current, {
          headers: {
            authorization: "Bearer " + token,
          },
        })
        .then(
          (resp) => {
            const rvrecord = resp.data.knowledge;
            console.log(resp,rvrecord);
            if ("Error" in rvrecord) {
              setPageError(true);
              setError(rvrecord.error);
              setLoading(false);
            } else {
              setHtml(rvrecord.html);
              setTitle(rvrecord.title);
              setLoading(false);
            }
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      toast.error("Table Name not Found");
      setLoading(false);
      return;
    }
  };

  // UseEffects
  useEffect(() => {
    console.log(location);
    if (location.knowledge.knowledge !== '') {
      setTitle(location.knowledge.knowledge.title);
      setHtml(location.knowledge.knowledge.html);
      setLoading(false);
    }else{
      getKnowledge();
    }
  }, []);

  if (!loading) {
    return (
      <div className="pagesetup">
        {console.log(title)}
        <h1 className=" text-center">{title}</h1>
        <div className="container justify-content-center">
        <div
          className=" card card-body"
          dangerouslySetInnerHTML={{ __html: html }}
        ></div>
        </div>
      </div>
    );
  } else {
    return <WorkInProgress />;
  }
};

export default KnowledgeCompo;
