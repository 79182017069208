import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const HtmlPage = () => {
  const { html, script } = useLocation().state||"";
  console.log(html);
  let r = "<Button onClick={()=>func()}>Steve</Button>";

  useEffect(() => {
    innerhtml();
  },[]);

  const func = () => {
    console.log("demo");

    document.getElementById("demo").style.fontSize = "25px";
    document.getElementById("demo").style.color = "red";
    document.getElementById("demo").style.backgroundColor = "yellow";
  };

  const func2 = () => {
    console.log("demo");

    document.getElementById("demo").style.fontSize = "25px";
    document.getElementById("demo").style.color = "blue";
    document.getElementById("demo").style.backgroundColor = "green";
  };

  const getBody = (string) =>
    string.substring(string.indexOf("{" + 1, string.lastIndexOf("}")));

  const innerhtml = () => {
    // document.getElementById("one".innerHTML = html2;
    // return(
    // window.func=func();
    // console.log(script);
    // console.log(html);
    if (script != "null" && document.getElementById("button")!=null) {
      let p =
      'document.getElementById("button".onclick=function pi(){ ' +
      getBody(script) +
      "};";
    let f = new Function(["document"], p);
    f(document);
    } else {
      
    }
  
  };
  return (
    <div style={{flexGrow:1}}>
      <div id="one" className="justify-content-center" dangerouslySetInnerHTML={{ __html: html }} />
      {/* // <JsxParser jsx="<hr></hr><button onClick={func} className='btn btn-primary'>Foo</button>" /> */}
    </div>
)};
export default HtmlPage;
