import axios from "axios";
import { AutoPortalContext } from "../Context";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import * as Babel from "@babel/standalone";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import { auto } from "@popperjs/core";
import RestApiJS from "../HelperClasses/RestApiJS";
import ExternalApiJS from "../HelperClasses/ExternalApiJS";
import { TwitterTweetEmbed } from 'react-twitter-embed';
import {XEmbed,InstagramEmbed,YouTubeEmbed,FacebookEmbed} from 'react-social-media-embed';

const converter = (temp) => {
  // let kk ="zakir "
  // const tagRegex = /<([a-zA-Z0-9]+)(.*?)>(.*?)<\/\1>|<([a-zA-Z0-9]+) *>/g;
  // let ind=kk.search(/<([a-zA-Z0-9 ""'']+)>/)
  // let string=kk.substring(0,ind);
  // let htmlString=kk.substring(ind,kk.length-1);
  //   return `React.createElement('h1', {},['${string}'])`
  let conv = "";
  let cont = 0;
  for (let i = 0; i < temp.children.length; i++) {
    let tag,
      id,
      props,
      content = "";
    let item = temp.children.item(i);
    tag = item.tagName.toLowerCase();
    id = item.id;
    let onClick = item.getAttribute("onclick");
    let onChange = item.getAttribute("onChange");
    let className = item.getAttribute("class");
    props =
      '{id:"' +
      id +
      '",className:"' +
      className +
      '",' +
      "onChange:(e)=>{" +
      onChange +
      "},onClick: ()=>{" +
      onClick +
      "}}";
    console.log(className);

    if (i > 0) {
      conv += ",";
    }

    if (tag === "input") {
      conv += `React.createElement('${tag}', ${props},null)`;
      continue;
    }

    if (item.children.length > 0) {
      // conv += converter(item)
      let content = item.innerHTML;
      let pattern = /^([ \w]*){+/;
      let ind = content.search(pattern);

      // content = content.replace(/{ *(\w*) *}/, (_, w) => {
      //   console.log(_);
      //   return `'+${w}+'`;
      // });
      console.log(/^([ \w]*){+/.test(content));
      if (ind === -1) {
        conv += `React.createElement('${tag}', ${props},[${converter(item)}])`;
      } else {
        ind = content.search(/{/);
        console.log(ind);
        let string = content.substring(0, ind);
        let curString = content.substring(ind, content.length);
        let innerInd = curString.search(/<([a-zA-Z0-9 ""'']+)>/);
        let subString = curString.substring(0, innerInd);
        let htmlString = curString.substring(innerInd, curString.length);
        console.log(subString, htmlString);
        conv +=
          "React.createElement('" +
          tag +
          "', " +
          props +
          "," +
          `${string},${subString} ${converter(item)}` +
          ")";
      }
    } else {
      content = item.textContent;
      console.log(content);
      content = content.replace(/{ *(\w*) *}/, (_, w) => {
        console.log(_);
        return `'+${w}+'`;
      });

      conv += `React.createElement('${tag}', ${props},'${content}')`;
    }
    // cont++;
  }
  return conv;
};

const handleConvert = (jsxCode) => {
  try {
    const result = Babel.transform(jsxCode, {
      presets: ["react"],
      comments: false, // Disable adding comments
      minified: true,
    });
    return result.code;
  } catch (error) {
    console.error("Error converting JSX:", error);
  }
};

const stringToComponent = (
  FnName,
  compoFunction,
  compoTemplate,
  token,
  loca
) => {
  if (!FnName) {
    return;
  }
  // Use Function constructor to create a function from the string
  // let pDiv = document.createElement("div");
  // pDiv.innerHTML = compoTemplate;
  // let template = converter(pDiv);
  // console.log(template);
  //   const convertedFunction = compoFunction.replace(/return (.*)/, (match, p1) => {
  //     console.log("return");
  //     const convertedJSX = handleConvert(p1);
  //     return `return ${convertedJSX};`;
  // });
  compoFunction = handleConvert(compoFunction);
  let temp = handleConvert(compoTemplate);
  temp = temp.substring(0, temp.length - 1);
  console.log(temp);
  // let temp = htmlToReact(`<div><button onclick="print()" ></button><div>`)
  // console.log(temp);
  const componentFunction = new Function(
    [
      "React",
      "useNavigate",
      "useContext",
      "useState",
      "useEffect",
      "useLocation",
      "useRef",
      "useSearchParams",
      "createSearchParams",
      "Modal",
      "AutoPortalContext",
      "axios",
      "document",
      "auto",
      "RestApiJS",
      "ExternalApiJS",
      "TwitterTweetEmbed",
      "XEmbed",
      "YouTubeEmbed",
      "InstagramEmbed",
      "FacebookEmbed"
    ],
    `
      return  function ${FnName}() {
        ${compoFunction}
    
       return(
        
        ${temp}
       )
    }
          
      `
  );

  // Call the created function with React as an argument to create a React component
  return componentFunction(
    React,
    useNavigate,
    useContext,
    useState,
    useEffect,
    useLocation,
    useRef,
    useSearchParams,
    createSearchParams,
    Modal,
    AutoPortalContext,
    axios,
    document,
    auto,
    RestApiJS,
    ExternalApiJS,
    TwitterTweetEmbed,
    XEmbed,
    YouTubeEmbed,
    InstagramEmbed,
    FacebookEmbed
  );
};
export { stringToComponent };
