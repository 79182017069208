import React, {
  Component,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import axios from "axios";
import { AutoPortalContext } from "../Context";
import WorkInProgress from "./WorkInProgress";
import "../css/FormView.css";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import { createSearchParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Header from "./Header";

const FormView_Component = () => {
  const { token, loca, user } = useContext(AutoPortalContext);

  const navigation = useNavigate();

  const [tab_mn, setTab_mn] = useState([{}]);
  const [loading, setLoading] = useState(false);
  const [tabname, setTabname] = useState(false);
  const [typename, setTypename] = useState(false);
  const [colState, setcolState] = useState(false);
  // const [viewData, setViewData] = useState({});
  const [tabState, setTabState] = useState(true);
  const [col_mn, setCol_mn] = useState([]);
  const [columnarray2, setColumnarray2] = useState([""]);
  // const [type, setType] = useState("");
  // const [tablename, setTableName] = useState("");
  const [page_error, setPage_Error] = useState(false);
  const [error, setError] = useState("");
  const [warning, setWarning] = useState(false);
  const [json, setJson] = useState([]);
  const [flag, setFlag] = useState(false);
  const [viewList, setViewList] = useState([]);
  const [mncolor, setMncolor] = useState(false);
  const [disableColArr, setDisableColArr] = useState(false);
  const [disableColArr2, setDisableColArr2] = useState(false);
  const [isRefFieldSelected, setisRefFieldSelected] = useState(false);
  const [allcol_mn, setAllcol_mn] = useState(new Map());
  const [refCols, setrefCols] = useState(new Map());
  const [isClick, setIsClick] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedRefTab, setselectedRefTab] = useState("");
  const [searchParam, setSearchParam] = useSearchParams();

  let parentApp = useRef("");
  const ind = useRef(-1);
  const index = useRef(-1);
  const loc = useLocation().state;

  useEffect(() => {
    console.log(loc);
    ind.current = -1;
    if (loc) {
      // setTabname(loc.tabName);
      parentApp.current = searchParam.get("parentApplication");
      setTabState(true);
      formChangeTable(loc.tabName);
      getFormViewRecord();
    } else {
      parentApp.current = searchParam.get("parentApplication");
      getFormViewRecord();
    }
  }, [refresh]);

  const getFormViewRecord = () => {
    setLoading(true);
    setTabState(false);
    setWarning(false);
    let url = "";
    if (parentApp.current === "Marketplace") {
      url =
        `http://localhost:8084/marketplace/get/tables`;
    } else {
      url = loca + `/lom/get/tables`;
    }
    axios
      .get(url, {
        headers: {
          authorization: "Bearer " + token,
          "market-application": "marketplace",
        },
      })
      .then((resp) => {
        const formdata = resp.data;
        console.log(formdata);
        if (formdata !== "") {
          if ("Error" in formdata) {
            setLoading(false);
            setPage_Error(true);
            setError(formdata.Error);
          } else {
            setLoading(false);
            setTabState(true);
            formdata.tableRecords.sort((a, b) => {
              return a.name > b.name ? 1 : a.name < b.name ? -1 : 0;
            });
            setTab_mn(formdata.tableRecords);
            setFlag(false);
          }
        }
      });
  };
  const setCurrentRefCol_mn = (arr, key) => {
    let colm2 = columnarray2;

    console.log(columnarray2);
    console.log(colm2);
    console.log(arr);
    console.log(key);
    for (let k = 0; k < arr.length; k++) {

      for (let i = 0; i < colm2.length; i++) {

        if (arr[k].key === colm2[i].key && arr[k].name === colm2[i].name) {
          arr[k].check = true;
          break;
        }
      }
    }
    return arr;

  }

  const formChangeTable = (val, dontFalse, key) => {

    const newMap = new Map(allcol_mn);
    if (newMap.has(key)) {
      setCol_mn(newMap.get(key));
      return;
    }
    console.log("dontFalse", dontFalse, val);
    if (!dontFalse) {
      setMncolor(false);
      setFlag(false);
    }

    setDisableColArr(false);
    setDisableColArr2(false);
    if (val !== "" && val !== "none") {
      setcolState(false);

      setCol_mn([]);
      if (!dontFalse) {
        setTabname(val);
        setColumnarray2([]);
        setViewList([]);
        const newMap2 = new Map(allcol_mn);
        newMap2.clear()
        setAllcol_mn(newMap2);
        const newMap3 = new Map(refCols)
        newMap3.clear();
        setrefCols(newMap3)

      }
      document.getElementById("one").value = "none";
      axios
        .get(loca + "/lom/get/columnview/" + val, {
          headers: {
            authorization: "Bearer " + token,
          },
        })
        .then((resp) => {
          const coldata = resp.data;
          console.log(coldata);
          if (coldata !== "") {
            if ("Error" in coldata) {
              setLoading(false);
              setPage_Error(true);
              setError(coldata.Error);
            } else {
              setcolState(true);
              let arr = [...coldata.colView[0].columnRecords];
              if (!dontFalse) {

                for (let pp = 0; pp < arr.length; pp++) {
                  arr[pp].key = val;
                }
                newMap.set(val, arr);
              }
              else if (!newMap.has(key)) {
                for (let pp = 0; pp < arr.length; pp++) {
                  arr[pp].key = key;
                }
                arr = setCurrentRefCol_mn(arr, key)
                newMap.set(key, arr);

              }

              setAllcol_mn(newMap);
              console.log(newMap);
              setCol_mn([...coldata.colView[0].columnRecords]);
              if (!dontFalse) {
                setViewList([...coldata.colView[1].viewRecords]);
              }


              if (coldata.colView[1].viewRecords.length === 0) {
                setWarning(true);
              } else {
                setWarning(true);
              }

              setMncolor(true);
            }
          }
        });
    } else {
      setTabname(val);
      setViewList([]);
      setWarning(false);
    }
  };
  const callReferenceCol = () => {

    console.log("fafd", selectedRefTab);
    setisRefFieldSelected(false)
    if (selectedRefTab) {

      let key2 = "";
      let st = "";
      const newMap = new Map(refCols);
      console.log("1", refCols, newMap.has(selectedRefTab.refTbName));
      //    if (!newMap.has(selectedRefTab.refTbName)) {

      if (newMap.size == 0) {
        const key1 = selectedRefTab.tabName;
        newMap.set(key1, {
          "one": selectedRefTab.tabLabel, "two": st + selectedRefTab.tabLabel + " fields",
          path: selectedRefTab.tabName, tabPath: selectedRefTab.tabName
        });
      }
      console.log("size", newMap.size);
      /*        if (newMap.size == 1) {
               st += ". "
             } else
               if (newMap.size == 2) {
                 st += ". . "
               }
               else
               if (newMap.size == 3) {
                 st += ". . . "
               }
               else
               if (newMap.size == 4) {
                 st += ". . . ."
               } */

      let lastKey;
      // let path = "";
      st = "";

      for (let [kk, value] of newMap) {
        lastKey = kk
        st += ". "

      }

      key2 = lastKey;
      let leb = selectedRefTab.label;
      leb = leb.substring(0, leb.length - 3);
      key2 += leb;
      // newMap.set(key2, { "one": selectedRefTab.refTbLabel, "two": st + selectedRefTab.refTbLabel + " fields", path: selectedRefTab.refTbName });
      //}

      newMap.set(key2, {
        "one": leb, "two": st + leb + " fields",
        path: selectedRefTab.name, tabPath: selectedRefTab.refTbName
      });
      console.log(key2);
      console.log(newMap);
      formChangeTable(selectedRefTab.refTbName, true, key2)
      setrefCols(newMap)


    }
  }

  const setColumn = (prefArray, cmm) => {
    let clm = col_mn;
    if (cmm)
      clm = cmm;

    let clm2 = [];
    let co = 1;
    console.log(clm);

    for (var i = 0; i < clm.length; i++) {
      clm[i].check = false;
    }
    for (let i = 0; i < prefArray.length; i++) {
      for (let j = 0, cnt = 0; j < clm.length; j++) {

        if (clm[j].name !== "--split--" && clm[j].name !== "--split-start--" && clm[j].name !== "--split-end--" && clm[j].name === prefArray[i].name && !prefArray[i].secondary) {

          clm[j].check = true;
          // let jj = {
          //   label: clm[j].label, name: clm[j].name, co: prefArray[i].co, tabName: prefArray[i].tabName,
          //   key: prefArray[i].key, path: prefArray[i].path, type: prefArray[i].type, colId: prefArray[i].colId ,
          //   tabPath:prefArray[i].tabPath,
          // }
          let jj = {
            label: clm[j].label, name: clm[j].name, co: co.toString(), tabName: prefArray[i].tabName,
            key: prefArray[i].key, path: prefArray[i].path, type: prefArray[i].type, colId: prefArray[i].colId,
            tabPath: prefArray[i].tabPath,
          }
          clm2.push(jj);
          co++;
          break;
        }
        if (cnt === clm.length - 1) {
          if (prefArray[i].name === "--split--") {
            var jj = {
              label: prefArray[i].name,
              name: prefArray[i].name,
              // co: prefArray[i].co,
              co: co.toString(),
            };
            clm2.push(jj);
            co++;
            break;
          } else if (prefArray[i].name === "--split-start--") {
            var jj = {
              label: prefArray[i].name,
              name: prefArray[i].name,
              // co: prefArray[i].co,
              co: co.toString(),
            };
            clm2.push(jj);
            co++;
            break;
          } else if (prefArray[i].name === "--split-end--") {
            var jj = {
              label: prefArray[i].name,
              name: prefArray[i].name,
              // co: prefArray[i].co,
              co: co.toString(),
            };
            clm2.push(jj);
            co++
            break;
          }
        }

        if (prefArray[i].secondary) {

          // let jj = {
          //   label: prefArray[i].label, name:prefArray[i].name, co: prefArray[i].co, tabName: prefArray[i].tabName, key: prefArray[i].key
          //   , path: prefArray[i].path, type: prefArray[i].type ,colId: prefArray[i].colId ,secondary:prefArray[i].secondary,
          //   tabPath:prefArray[i].tabPath
          // }
          let jj = {
            label: prefArray[i].label, name: prefArray[i].name, co: co.toString(), tabName: prefArray[i].tabName, key: prefArray[i].key
            , path: prefArray[i].path, type: prefArray[i].type, colId: prefArray[i].colId, secondary: prefArray[i].secondary,
            tabPath: prefArray[i].tabPath
          }
          clm2.push(jj);
          co++;
          break;
        }


        cnt++;
      }
    }

    if (clm[clm.length - 1].label !== "--split-end--") {

      clm.push({
        label: "--split-start--",
        type: "ui",
        name: "--split-start--",
        check: false,
      });
      clm.push({
        label: "--split--",
        type: "ui",
        name: "--split--",
        check: false,
      });
      clm.push({
        label: "--split-end--",
        type: "ui",
        name: "--split-end--",
        check: false,
      });
    }

    setCol_mn([...clm]);
    setColumnarray2([...clm2]);
    setFlag(true);
  };



  const formChangeType = (val) => {
    setDisableColArr(false);
    setDisableColArr2(false);
    console.log(val);
    console.log("hello");
    if (val !== "" && val !== "none") {
      let obj = viewList.find((obj, ind) => obj.id === val).type;

      setTypename(obj);
      if (tabname !== "" && tabname !== "none") {
        axios
          .get(loca + "/lom/get/view/" + tabname + "/" + val, {
            headers: {
              authorization: "Bearer " + token,
            },
          })
          .then((resp) => {
            const data = resp.data;
            console.log(data);
            if (data !== "") {
              if ("Error" in data) {
                setLoading(false);
                setPage_Error(true);
                setError(data.Error);
              } else {
                setJson(data);
                let clm = changeIntoOriginalColmn(tabname)  // target key will be maintableName
                if (clm) {
                  setColumn(data.formView[2].column, clm);
                } else
                  setColumn(data.formView[2].column);

              }
            }
          });
      }
    } else {
      setFlag(false);
    }
  };
  const submitColumnbtn = () => {
    setIsClick(true);
    var jsson = json;
    var colm = changeIntoOriginalColmn(tabname);
    console.log(colm);
    var colm2 = columnarray2;
    var sub = [];
    console.log(colm2);

    for (var i = 0; i < colm2.length; i++) {
      for (var j = 0; j < colm.length; j++) {
        if (colm2[i].key === colm[j].key && colm2[i].name === colm[j].name) {
          sub.push({ name: colm[j].name, tabName: colm[j].tabName ?? "", type: colm[j].type, colId: colm[j].colId ?? "", key: colm[j].key ?? "" });
          break;
        }
        if (colm2[i].secondary) {
          sub.push({
            name: colm2[i].name, tabName: colm2[i].tabName, secondary: "" + colm2[i].secondary, path: colm2[i].path, type: colm2[i].type,
            colId: colm2[i].colId, key: colm2[i].key ?? "", label: colm2[i].label, tabPath: colm2[i].tabPath
          })
          break;
        }
      }
    }
    jsson.formView[2].column = sub;
    jsson.formView[1].table.value = tabname;
    console.log(jsson);

    console.log(typename);
    if (typename === "new" || typename === "record") {

      axios
        .post(loca + "/lom/set/view/column", jsson, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
          },
        })
        .then((resp) => {
          var sysrecord = resp.data;

          console.log("ddddd " + JSON.stringify(sysrecord));
          if ("Error" in sysrecord) {
            toast(sysrecord.Error, {
              position: "top-center",
              theme: "colored",
              type: "error",
            });
          } else
            toast("Submit Successfully", {
              position: "top-center",
              theme: "colored",
              type: "success",
            });
        })
        .finally(() => {
          console.log("finally");
          setIsClick(false);
        });
    }
  };

  const changeIntoOriginalColmn = (key) => {
    console.log(key);
    const oldALmmCOl_mn = new Map(allcol_mn)
    console.log(allcol_mn);
    let clm = allcol_mn.get(key)
    if (clm) {
      setCol_mn((prevCol_mn) => [...clm]);
      console.log(clm);
      console.log(col_mn);
      const newMap3 = new Map(refCols)
      newMap3.clear();
      setrefCols(newMap3)
      for (let [pp] of oldALmmCOl_mn) {
        console.log(pp, key);
        if (pp != key) {
          console.log("del");
          oldALmmCOl_mn.delete(pp)
        }

      }
      setAllcol_mn(oldALmmCOl_mn);
      return clm;

    }

    setTimeout(function () {
      console.log("after6", col_mn);

    }, 6000)
  }

  const geToBackRefCol = (targetKey) => {

    let found = false;
    let clm
    //  
    for (const [key] of refCols) {
      if (found) {
        refCols.delete(key)
      }
      if (key === targetKey) {
        found = true;
        clm = allcol_mn.get(key)
      }

    }


    console.log(targetKey, refCols.get(targetKey));
    console.log(refCols);
    console.log(clm);

    console.log("setting");
    if (refCols.size == 1) {
      refCols.clear()
      if (clm[clm.length - 1].label !== "--split-end--") {

        clm.push({
          label: "--split-start--",
          type: "ui",
          name: "--split-start--",
          check: false,
        });
        clm.push({
          label: "--split--",
          type: "ui",
          name: "--split--",
          check: false,
        });
        clm.push({
          label: "--split-end--",
          type: "ui",
          name: "--split-end--",
          check: false,
        });
      }
      setCol_mn([...clm]);

    }
    else {

      setCol_mn([...clm])

      setisRefFieldSelected(false)
      setDisableColArr(false);
      setDisableColArr2(false);
    }
  }
  const selected = (index, clm) => {
    console.log(index);
    setDisableColArr(true);
    setDisableColArr2(false);
    console.log(col_mn, clm);

    setisRefFieldSelected(false)
    if (clm === "clm1") {
      if (col_mn[index]?.type === "reference") {
        console.log("true");
        setisRefFieldSelected(true)

        console.log(col_mn[index], col_mn[index].refTbName);

        setselectedRefTab(col_mn[index])
      }
      for (let i = 0; i < col_mn.length; i++) {
        if (i === index) {
          document
            .getElementById("colm1")
            .children.item(i)
            .classList.add("selected-row");
        } else {
          document
            .getElementById("colm1")
            .children.item(i)
            .classList.remove("selected-row");
        }
      }
      for (let i = 0; i < columnarray2.length; i++) {
        document
          .getElementById("colm2")
          .children.item(i)
          .classList.remove("selected-row");
      }
    } else {
      if (columnarray2.length == 1) {
        setDisableColArr(true);
      } else {
        setDisableColArr(false);
      }
      setDisableColArr2(true);
      ind.current = index;
      for (let i = 0; i < columnarray2.length; i++) {
        if (i === index) {
          document
            .getElementById("colm2")
            .children.item(i)
            .classList.add("selected-row");
        } else {
          document
            .getElementById("colm2")
            .children.item(i)
            .classList.remove("selected-row");
        }
      }
      for (let i = 0; i < col_mn.length; i++) {
        document
          .getElementById("colm1")
          .children.item(i)
          .classList.remove("selected-row");
      }
    }
  };

  const setColumnOrder = (pm) => {
    var clm = [];
    var col_odr = ind.current;
    var col_odr2 = 0;
    console.log(col_odr, pm);
    if (ind.current !== -1) {
      if (pm) {
        if (col_odr < columnarray2.length - 1) {
          col_odr2 = col_odr + 1;
          for (let i = 0; i < columnarray2.length; i++) {
            if (i !== col_odr2 && i !== col_odr) {
              clm.push(columnarray2[i]);
            } else if (i === col_odr2) {
              document
                .getElementById("colm2")
                .children.item(col_odr2)
                .classList.add("selected-row");
              console.log("col_odr2");
              let cjj = columnarray2[i];
              cjj.co = (col_odr + 1).toString();
              console.log((col_odr + 1).toString());

              clm.push(cjj);
            } else if (i === col_odr) {
              document
                .getElementById("colm2")
                .children.item(col_odr)
                .classList.remove("selected-row");
              console.log("col_odr");
              let cjj = columnarray2[i];
              cjj.co = (col_odr2 + 1).toString();
              clm.push(cjj);
            }
          }
          clm.sort((a, b) =>
            parseInt(a.co) > parseInt(b.co)
              ? 1
              : parseInt(a.co) < parseInt(b.co)
                ? -1
                : 0
          );
          ind.current = col_odr2;
          console.log(clm);
          setColumnarray2([...clm]);
        }
      } else {
        if (col_odr > 0) {
          col_odr2 = col_odr - 1;
          for (let i = 0; i < columnarray2.length; i++) {
            if (i !== col_odr2 && i !== col_odr) {
              clm.push(columnarray2[i]);
            } else if (i === col_odr2) {
              document
                .getElementById("colm2")
                .children.item(col_odr2)
                .classList.add("selected-row");
              console.log("col_odr2");
              let cjj = columnarray2[i];
              cjj.co = (col_odr + 1).toString();
              clm.push(cjj);
            } else if (i === col_odr) {
              document
                .getElementById("colm2")
                .children.item(col_odr)
                .classList.remove("selected-row");
              console.log("col_odr");
              let cjj = columnarray2[i];
              cjj.co = (col_odr2 + 1).toString();
              clm.push(cjj);
            }
          }
          clm.sort((a, b) =>
            parseInt(a.co) > parseInt(b.co)
              ? 1
              : parseInt(a.co) < parseInt(b.co)
                ? -1
                : 0
          );
          ind.current = col_odr2;
          console.log(clm);
          setColumnarray2([...clm]);
        }
      }
    }
  };

  const shiftRight = () => {
    let clm = col_mn;
    let clm2 = columnarray2;
    if (document.getElementsByClassName("selected-row").item(0)) {
      let lab = document
        .getElementsByClassName("selected-row")
        .item(0).innerText;
      console.log(index.current, clm);
      for (let i = 0; i < col_mn.length; i++) {
        if (i === index.current) {
          if (clm[i].type !== "ui") {
            console.log("inside");
            clm[i].check = true;
          }
          let n_co = clm2.length + 1;
          let st = "";
          let pt = "";
          let tbpth = "";
          console.log(refCols);
          console.log(clm[i]);

          let ct = 0;

          for (const [key] of refCols) {
            if (ct == 0) {
              st = st + refCols.get(key).one
              pt = pt + refCols.get(key).path
              tbpth = tbpth + refCols.get(key).tabPath
            } else {
              st = st + ".";
              st = st + refCols.get(key).one
              pt = pt + "."
              pt = pt + refCols.get(key).path
              tbpth = tbpth + ".";
              tbpth = tbpth + refCols.get(key).tabPath
            }
            ct++;
          }
          if (ct > 0) {
            st = st + ".";
            pt = pt + "."
          }

          console.log(st);
          console.log(pt);
          console.log(tbpth);

          let n = {
            label: st + lab, name: clm[i].name, co: n_co.toString(), tabName: clm[i].tabName,
            key: clm[i].key, path: pt + clm[i].name, secondary: !(st === ""),
            type: clm[i].type, colId: clm[i].colId, tabPath: tbpth
          };
          clm2.push(n);
        }
      }
      ind.current = -1
      console.log(clm);
      setDisableColArr(false);
      setCol_mn([...clm]);
      setColumnarray2([...clm2]);
    }
  };

  const shiftLeft = () => {
    let clm = col_mn;
    console.log(col_mn);
    let clm2 = columnarray2;
    let name = "";
    let obj = {};
    let removedIndex = -1
    if (document.getElementsByClassName("selected-row").item(0)) {
      // let lab = document.getElementsByClassName("selected-row").item(0).innerText;
      for (let i = 0; i < columnarray2.length; i++) {
        if (i === ind.current) {
          name = clm2[i].name;
          obj = clm2[i];
          removedIndex = i;

        }
        if (i > ind.current) {
          console.log(clm2[i].co);
          let newCO = Number(clm2[i].co) - 1
          clm2[i].co = newCO.toString()
        }

      }
      console.log(obj);
      console.log(name);
      console.log(allcol_mn);

      if (removedIndex != -1) {
        clm2.splice(removedIndex, 1);
        document
          .getElementsByClassName("selected-row")
          .item(0)
          .classList.remove("selected-row");
      }


      if (allcol_mn.has(obj.key)) {
        clm = allcol_mn.get(obj.key)
      }
      console.log(allcol_mn.has(obj.key));
      for (let i = 0; i < col_mn.length; i++) {
        if (clm[i].key === obj.key &&
          clm[i].name === name &&
          !(
            clm[i].label === "--split--" ||
            clm[i].label === "--split-end--" ||
            clm[i].label === "--split-start--"
          )
        ) {
          console.log(name);
          clm[i].check = false;
        }
      }
      ind.current = -1
      console.log("before", clm);
      clm = col_mn;
      console.log("after", clm);
      setDisableColArr(false);
      setDisableColArr2(false);
      setCol_mn([...clm]);
      setColumnarray2([...clm2]);
    }
  };

  return (
    <div
      className="Card"
      style={
        isMobile
          ? { height: "93vh", overflow: "auto", flexGrow: 1 }
          : { height: "95vh", overflow: "auto", flexGrow: 1 }
      }
    >
      <div style={{ position: "sticky", top: "0",zIndex:"1" }}>
        <Header tabName={"Form View SetUp"}
          isClick={isClick}
          refresh={refresh}
          setRefresh={setRefresh}
        />
      </div>
      <div className="formViewBack" aria-readonly={getFormViewRecord}>
        {loading === true ? (
          <div
            className="d-flex justify-content-center align-items-center w-100"
            style={{ height: "95vh" }}
          >
            <WorkInProgress />
          </div>
        ) : (
          <div>
            {tabState === true && (
              <div
                className="row fr"
                style={{
                  // width: "1002px",
                  marginLeft: "0rem",
                }}
              >
                {/* zakir Added  below div at 28-12-20323*/}
                {viewList.length === 0 && warning && (
                  <div>
                    <div className="alert alert-warning align-center p-0 ps-1">
                      <span className="fs-5">
                        You Don't have any custom view <br />
                        Please create new custom View by presing button { }
                        <i className="fa-solid fa-caret-up fa-rotate-90"></i>
                      </span>
                      <span>
                        <button
                          onClick={() => {
                            navigation({
                              pathname: user + "/form",
                              search: createSearchParams({
                                tableName: "view_rule",
                                rty: "new",
                              }).toString(),
                            });
                          }}
                          className="btn btn-primary"
                        >
                          Create Custom View
                        </button>
                      </span>{" "}
                    </div>
                    <div></div>
                  </div>
                )}
                <div className="col-md-6 px-1">
                  <div className="text-start">
                    <span
                      className={mncolor ? "mndtryfals" : "mndtrytru"}
                      style={{ fontSize: "30px" }}
                    >
                      *
                    </span>
                    <span className="heading">Table</span>
                  </div>
                  {console.log(tabname)}

                  <select
                    className="form-select pref_mar "
                    aria-label="Default"
                    onChange={(e) => formChangeTable(e.target.value)}
                    value={tabname}
                  >
                    <option value="none">None</option>
                    {tab_mn.map((obj, index) => (
                      <option key={index} value={obj.name}>
                        {obj.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className=" col-md-6 px-1">
                  <div className="text-start">
                    <span
                      className={mncolor ? "mndtryfals" : "mndtrytru"}
                      style={{ fontSize: "30px" }}
                    >
                      *
                    </span>
                    <span className="heading">View</span>
                  </div>
                  {console.log(viewList)}
                  <select
                    id="one"
                    className="form-select pref_mar"
                    aria-label="Default"
                    onChange={(e) => formChangeType(e.target.value)}
                  >
                    <option value="none">None</option>
                    {viewList.length > 0 &&
                      viewList.map((obbj, ind) => (
                        <option key={ind} value={obbj.id}>
                          {obbj.label}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            )}
          </div>
        )}
        {flag && (
          <div>
            <div className="row" style={{ marginTop: "2rem" }}>
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <div className="row fr">
                  {Array.from(refCols).map(([key, value]) => (
                    <div key={key}>
                      <div
                        /* className="col-md heading" */
                        onClick={() => {
                          geToBackRefCol(key);

                        }}
                      >
                        {value.two}
                      </div>
                    </div>

                  ))}
                  <div id="colm1" className="col-md-5">
                    {col_mn.length > 0 &&
                      col_mn.sort((a, b) => (a.label > b.label ? 1 : -1)).map((obj, obj_i) => (
                        <div key={obj_i}>
                          {!obj.check && (
                            <div className="row fr">
                              <div
                                className="col-md heading"
                                onClick={() => {
                                  selected(obj_i, "clm1");
                                  index.current = obj_i;
                                }}
                              >
                                <span className={obj?.type === "reference" ? "greenLab" : "blackLab"}>{obj.label}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                  <div className="col-md-1 up-down-dis">
                    {isRefFieldSelected && (
                      <div
                        onClick={() => {
                          callReferenceCol()
                        }}
                      >

                        <i className="fa-solid fa-code-branch"></i>
                      </div>

                    )}
                    <div>
                      <button
                        className="view_btn"
                        disabled={disableColArr2}
                        onClick={() => {
                          shiftRight();
                        }}
                      >
                        {">"}
                      </button>
                    </div>
                    <div>
                      <button
                        className="view_btn"
                        disabled={disableColArr}
                        onClick={() => {
                          shiftLeft();
                        }}
                      >
                        {"<"}
                      </button>
                    </div>
                  </div>
                  <div id="colm2" className="col-md-5">
                    {console.log("columnarray2", columnarray2)}
                    {columnarray2.map((obj, index) => (
                      <p
                        style={{ margin: 0 }}
                        key={index}
                        className="columnarray2 heading"
                        onClick={() => {
                          selected(index, "clm2");
                        }}
                        value={obj.label}
                      >
                        {obj.label}
                      </p>
                    ))}
                  </div>
                  <div className="col-md-1 up-down-dis">
                    <div>
                      <button
                        className="up-down-view"

                        onClick={(e) => setColumnOrder(false)}
                      >
                        {">"}
                      </button>
                    </div>
                    <div>
                      <button
                        className="up-down-view"

                        onClick={(e) => setColumnOrder(true)}
                      >
                        {"<"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-2"></div>
            </div>
            <div className="row my-4">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <div className="row">
                  <div className="col-md-4"></div>
                  <div className="col-md-3 cen" style={{ textAlign: "center" }}>
                    <button
                      style={{
                        fontSize: "15px",
                        // width: "20%",
                        borderRadius: "3px",
                      }}
                      type="button"
                      className=" btnnn btn btn-primary"
                      onClick={() => submitColumnbtn()}
                    >
                      Submit
                    </button>
                  </div>
                  <div className="col-md-4"></div>
                  <div className="col-md-2"></div>
                </div>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        )}
      </div>
    </div >
  );
};

export default FormView_Component;
