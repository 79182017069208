
import MainComponent from "./Components/MainComponent";
import "./App.css"

function App() {
  return (
    <div className="App">
      <MainComponent />
    </div>
  );
}

export default App;
