import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { ListGroupItem } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const UserNotificationCompo = ({ msg, setCnt,token,loca }) => {
  const navigation = useNavigate();
  useEffect(() => {
    setCnt(0)
    let jo={messages:msg};
    axios
      .post(loca+"/lom/set/seen",jo, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (res) => {
          let resp = res.data;
        },
        (error) => { navigation("/error")
          console.log(error);
        }
      );
  }, []);

  return (
    <div style={{flexGrow:1}}>
      {msg.length > 0 &&
        msg.map((obj, index) => (
          <ListGroupItem key={index}><pre>{obj.message}</pre></ListGroupItem>
        ))}
    </div>
  );
};

export default UserNotificationCompo;
