import axios from "axios";
import React, { useState, useEffect, useContext, Fragment } from "react";
import { AutoPortalContext } from "../Context";
import WorkInProgress from "./WorkInProgress";
import "../css/Check_list_Component.css";
import WorkInProgressSmall from "./WorkInProgressSmall";
import { useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import ModelList from "./ModelList";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
// import NewFilterCompo from "./NewFilterCompo";
const ChecklistComponent = () => {
  const navigation = useNavigate();
  // useContext hook
  const { token, loca } = useContext(AutoPortalContext);
  // useState hooks
  const [loading, setLoading] = useState(false);
  const [stateLoading, setStateLoading] = useState(false);
  const [evtLoading, setEvtLoading] = useState(false);
  const [chekLoading, setChekLoading] = useState(false);
  const [cl_Record, setCl_Record] = useState("");
  const [refrecord, setrefRecord] = useState({});
  const [chk_record, setChk_Record] = useState([]);
  const [event_record, setEvent_Record] = useState([]);
  const [event_Record1, setEvent_Record1] = useState([]);
  const [imp_record, setImp_Record] = useState([]);
  const [imp_record1, setImp_Record1] = useState([]);
  const [evt_choice, setEvt_Choice] = useState([]);
  const [ier_button, setIer_Button] = useState({});
  const [evt_button, setEvt_Button] = useState({});
  const [ck_button, setCk_Button] = useState({});
  const [page_error, setPage_Error] = useState(false);
  const [error, setError] = useState("");
  const [page_message, setPage_Message] = useState(false);
  const [message, setMessage] = useState("");
  const [showModelList, setshowModelList] = useState(false);
  // const [showlist, setShowlist] = useState(false);
  const [filt, setFilt] = useState("null");
  // const [filter, setFilter] = useState("");
  // const [timeLine, setTimeLine] = useState("");
  // const [listName, setListName] = useState("");
  // const [col_mn, setCol_mn] = useState([]);
  const [filtarray, setFiltarray] = useState([
    {
      co: "",
      cl: "",
      mc: "",
      an: "",
      ct: "",
      af: "",
      rf: { id: "", value: "" },
    },
  ]);

  const [columnId, setColumnId] = useState();
  const [objName, setObjName] = useState();
  const [objLabel, setObjLabel] = useState();
  const [objIndex, setObjIndex] = useState();
  const [objIndex_in, setObjIndex_in] = useState();
  const [objType, setObjType] = useState();

  // useRef hooks
  const oneRender = useRef(true);

  // useEffect hook
  useEffect(() => {
    if (oneRender.current) {
      console.log("checkkkkking");
      getChecklistRecord();
    } else {
      oneRender.current = false;
    }
  }, []);

  const getChecklistRecord = () => {
    axios
      .get(loca + "/lom/today/checklist", {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then((resp) => {
        const chkliRcd = resp.data;
        console.log("checkkkkkkk " + JSON.stringify(chkliRcd));
        console.log("json obj ", chkliRcd);
        setRecord(chkliRcd);
      });
  };

  const setRecord = (chkrecord) => {
    if (chkrecord !== "") {
      // if ("Error" in chkrecord) {
      //   this.setState({
      //     loading: false,
      //     page_error: true,
      //     error: chkrecord.Error,
      //   });
      // } else {
      //   this.setState({
      //     loading: false,
      //     chk_record: chkrecord.checkList[0].checkListRecords,
      //     event_record1: chkrecord.checkList[1].eventRecords[0],
      //     event_record: chkrecord.checkList[1].eventRecords[0].records,
      //     evt_choice: chkrecord.checkList[1].eventRecords[1].choice,
      //     evt_button: chkrecord.checkList[1].eventRecords[2].button,
      //     imp_record: chkrecord.checkList[2].importantRecords[0].records,
      //     imp_record1: chkrecord.checkList[2].importantRecords[0],
      //     ier_button: chkrecord.checkList[2].importantRecords[1].button,
      //     cl_record: chkrecord,
      //   });
      // }
      if ("Error" in chkrecord) {
        setLoading(false);
        setPage_Error(true);
        setError(chkrecord.Error);
      } else {
        for (
          var i = 0;
          i < chkrecord.checkList[2].importantRecords[0].imp_records.length;
          i++
        ) {
          chkrecord.checkList[2].importantRecords[0].imp_records[i].position =
            i;
          chkrecord.checkList[2].importantRecords[0].imp_records[i].ref = true;
          for (
            var j = 0;
            j <
            chkrecord.checkList[2].importantRecords[0].imp_records[i].records
              .length;
            j++
          ) {
            chkrecord.checkList[2].importantRecords[0].imp_records[i].records[
              j
            ].rec_chg = false;
          }
        }

        var imp_record = [];
        var ier_button = [];
        if (
          chkrecord.checkList[2].importantRecords.length > 0 &&
          JSON.stringify(chkrecord.checkList[2].importantRecords[0]) !== "{}"
        ) {
          imp_record = chkrecord.checkList[2].importantRecords[0].imp_records;
          ier_button = chkrecord.checkList[2].importantRecords[1].button;
        }

        setChk_Record(chkrecord.checkList[0].checkListRecords[0].records);
        setCk_Button(chkrecord.checkList[0].checkListRecords[1].button);
        setEvt_Choice(chkrecord.checkList[1].eventRecords[1].choice);
        setEvent_Record(chkrecord.checkList[1].eventRecords[0].records);
        setEvent_Record1(chkrecord.checkList[1].eventRecords[0]);
        console.log(
          JSON.stringify(chkrecord.checkList[2].importantRecords[0].records)
        );
        setImp_Record(chkrecord.checkList[2].importantRecords[0].imp_records);
        setCl_Record(chkrecord);
        setEvt_Button(chkrecord.checkList[1].eventRecords[2].button);
        setIer_Button(chkrecord.checkList[2].importantRecords[1].button);
        setImp_Record1(chkrecord.checkList[2].importantRecords[0]);
        setLoading(false);
      }
    }
  };

  const formChangefn = (vl, index, index_in, ob, type, id, vrf) => {
    console.log(vl, index, index_in, ob, type, id, vrf);
    var frecord = imp_record;
    console.log(frecord);
    console.log(frecord[index].records[index_in].record[1].value);
    if (type === "reference") {
      // if (ob === "loomTable" || ob === "loomtable") {
      //   callFieldList(ob);
      // }
      if (vrf === false) {
        frecord[index].records[index_in].record[1].clicked = false;
      }
      if (vl.length > 2) {
        if (vrf === true) {
          if (frecord[index].records[index_in].record[1].name === ob) {
            frecord[index].records[index_in].record[1].value.name = vl;
            frecord[index].records[index_in].record[1].value.id = id;
            frecord[index].records[index_in].record[1].clicked = true;
            var rfrcd2 = refrecord;
            rfrcd2.record = {};
            // this.setState({ record: frecord, refrecord: rfrcd2 });
            console.log(frecord);
            setImp_Record([...frecord]);
            setrefRecord({ ...rfrcd2 });
            validationfn(vl, index, index_in, ob, type, id);
          }
        } else {
          if (frecord[index].records[index_in].record[1].name === ob) {
            frecord[index].records[index_in].record[1].value.name = vl;
            frecord[index].records[index_in].record[1].value.id = id;
            // this.setState({ record: frecord });
            setImp_Record([...frecord]);
          }
          var veri = '{"referenceRecord":[{"columnid":"' + id + '"},';
          veri += '{"tabvalue":"' + vl + '"}]}';
          var rff = [];
          axios
            .post(loca + "/lom/reference/record", veri.toString(), {
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + token,
              },
            })
            .then((resp) => {
              const refrencercd = resp.data;
              if (refrencercd !== "") {
                if ("Error" in refrencercd) {
                  // this.setState({
                  //   loading: false,
                  //   page_error: true,
                  //   error: refrencercd.Error,
                  // });
                  setPage_Error(true);
                  setError(refrencercd.Error);
                  setLoading(true);
                } else {
                  rff = refrencercd.referenceRecordList[2].records;
                }
              }
              var rf = { index: index, record: rff };
              // this.setState({ refrecord: rf });
              setrefRecord({ ...rf });
            });
        }
      } else {
        if (frecord[index].records[index_in].record[1].name === ob) {
          frecord[index].records[index_in].record[1].value.name = vl;
          frecord[index].records[index_in].record[1].value.id = id;
          var rfrcd3 = refrecord;
          rfrcd3.record = [];
          // this.setState({ record: frecord, refrecord: rfrcd3 });
          setImp_Record([...frecord]);
          setrefRecord({ ...rfrcd3 });
        }
      }
    } else {
      if (frecord[index].records[index_in].record[1].name === ob) {
        frecord[index].records[index_in].record[1].value = vl;
        // this.setState({ record: frecord });
        setImp_Record([...frecord]);
      }
    }
  };

  const validationfn = (vl, index, index_in, ob, type, id) => {
    var formrecord = imp_record;
    if (type === "reference") {
      if (vl !== "") {
        if (formrecord[index].records[index_in].clicked === true) {
          formrecord[index].records[index_in].verified = "verified";
        } else {
          var veri = '{"referenceRecord":[{"columnid":"' + id + '"},';
          veri += '{"tabvalue":"' + vl + '"}]}';
          axios
            .post(loca + "/lom/reference/verify", veri.toString(), {
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + token,
              },
            })
            .then((resp) => {
              const refrencercd = resp.data;
              console.log(refrencercd);
              if (refrencercd !== "") {
                if ("Error" in refrencercd) {
                  // this.setState({
                  //   loading: false,
                  //   page_error: true,
                  //   error: refrencercd.Error,
                  // });
                  setPage_Error(true);
                  setError(refrencercd.Error);
                  setLoading(false);
                } else {
                  if (refrencercd.Result === "Unverified") {
                    formrecord[index].records[index_in].verified = "unverified";
                    // this.setState({ record: formrecord });
                    setImp_Record([...formrecord]);
                  } else {
                    formrecord[index].records[index_in].verified = "verified";
                    var rfrcd = refrecord;
                    rfrcd.record = [];
                    // this.setState({ record: formrecord, refrecord: rfrcd });
                    setImp_Record([...formrecord]);
                    setrefRecord({ ...rfrcd });
                  }
                  // this.setState({ record: formrecord });
                  setImp_Record([...formrecord]);
                  return;
                }
              }
            });
        }
      } else {
        formrecord[index].records[index_in].verified = "initial";
        // this.setState({ record: formrecord });
        setImp_Record([...formrecord]);
      }
    } else {
      if (formrecord[index].records[index_in].name === ob) {
        if (vl !== "") {
          formrecord[index].records[index_in].verified = fieldverify(
            formrecord[index].records[index_in].type,
            vl
          );
        } else {
          formrecord[index].records[index_in].verified = "initial";
        }
      }
      // this.setState({ record: formrecord });
      setImp_Record([...formrecord]);
    }
  };

  const setRef = (id, val) => {
    formChangefn(val, objIndex, objIndex_in, objName, objType, id, true);
    console.log("zak");
    cancelModelList();
  };

  const clickRefrence = (e, id, name, type, index, index_in, label) => {
    e.preventDefault();
    setColumnId(id);
    setObjName(name);
    setObjType(type);
    setObjIndex(index);
    setObjIndex_in(index_in);
    setObjLabel(label);
    setshowModelList(true);
    // if (
    //   name === "field_id" ||
    //   name === "form_column_id"
    // ) {
    //   setReference();
    // }
  };

  const cancelModelList = () => {
    // console.log("llllllllll" + val);
    setFilt("null");
    setshowModelList(false);
  };

  const setRefrecord = (vl, index, index_in, ob, type, id) => {
    console.log(vl, index, index_in, ob, type, id);
    formChangefn(vl, index, index_in, ob, type, id, true);
  };

  const callbtnNew = async (obj, index) => {
    console.log(obj ,index);
    var imp_rcd = imp_record1.imp_records;
    console.log("imp : ", imp_rcd);
    let obj1=imp_rcd[index].records;
    var lt = imp_rcd[index].formTable;
    var id = imp_rcd[index].id;
    var rcd = {
      importantRecords: { id: id, formtable: lt, records: obj1 },
    };

     
   
   
    // this.setState({ stateLoading: true });
    console.log(rcd);
    setStateLoading(true); 
 
     axios
      .post(loca + "/lom/save/checklist/imporant/record", rcd, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then((resp) => {
        var record = resp.data;
        setRecord(record);
        // this.setState({ stateLoading: false });
        setStateLoading(false);
        console.log(record);
      }); 
  };

  const callEventbtn = async (obj) => {
    console.log(obj);
    var chc = evt_choice;
    var rcd = {
      eventRecords: { record: obj, choice: chc },
    };
    console.log(rcd);
    // this.setState({ evtLoading: true });
    setEvtLoading(true);
    axios
      .post(loca + "/lom/save/checklist/event/record", rcd, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          var record = resp.data;
          console.log(record);
          // setRecord(record);
          // this.setState({ evtLoading: false });
          setEvtLoading(false);
        },
        (error) => {
          navigation("/error");
          console.log(error);
        }
      );
  };

  const callCheckListbtn = async () => {
    var array = [];
    for (var c = 0; c < chk_record.length; c++) {
      if ("button" in chk_record[c]) {
      } else {
        array.push(chk_record[c]);
      }
    }
    var chk = { checkListRecords: array };
    // this.setState({ chekLoading: true });
    setChekLoading(true);
    axios
      .post(loca + "/lom/save/checklist/record", chk, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          var record = resp.data;
          console.log(record);
          // this.setState({ chekLoading: false });
          setChekLoading(false);
          var msg = record.Message;
          if (msg !== "") {
            // this.setState({ page_message: true, message: msg });
            toast.success(msg);
            setPage_Message(true);
            setMessage(msg);
          }
        },
        (error) => {
          navigation("/error");
          console.log(error);
        }
      );
  };

  const changeState = (e, index) => {
    var evt_rcd = event_record;
    evt_rcd[index].state = e.target.value;
    // this.setState({ event_record: evt_rcd });
    setEvent_Record([...evt_rcd]);
  };

  const changeValue = (e, index) => {
    var chk_rcd = chk_record;
    chk_rcd[index].flag_set = e.target.checked.toString();
    // this.setState({ chk_record: chk_rcd });
    setChk_Record([...chk_rcd]);
  };

  const setOpen = (i, rf) => {
    console.log(i, rf);
    const rcd = imp_record;
    rcd[i].ref = !rf;
    setImp_Record([...rcd]);
    console.log(rcd);
  };

  const fieldverify = (type, vl) => {
    if (type === "String") {
      if (/[a-zA-Z]/g.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }

    if (type === "email") {
      if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }
    if (type === "int") {
      if (/^[0-9]*[0-9][0-9]*$/.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }
    if (type === "date") {
      if (
        /^(?:(?:(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec))(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:(?:0?2|(?:Feb))(\/|-|\.)(?:29)\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(
          vl
        )
      ) {
        return "verified";
      } else {
        return "unverified";
      }
    }
    if (type === "reference") {
      if (/[a-zA-Z]/g.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }
    if (type === "filter") {
      if (/[a-zA-Z]/g.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }
  };

  return (
    <div className="pagesetup">
      {loading === true ? (
        <WorkInProgress />
      ) : (
        <div>
          {page_error === true && (
            <div
              className="alert alert-danger"
              role="alert"
              style={{
                padding: "0.2rem 0.2rem",
                marginBottom: "0px",
              }}
            >
              {error}
            </div>
          )}

          <div className="card">
            <div className="card-body">
              <table className="table table-striped">
                <thead style={{ textAlign: "center" }}>
                  <tr>
                    <th scope="col">Checklist</th>
                    <th scope="col"></th>
                    {chekLoading === true ? (
                      <WorkInProgressSmall></WorkInProgressSmall>
                    ) : (
                      <th scope="col"></th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Id</th>
                    <th>Name</th>
                    <th>Checklist Name</th>
                    <th>Flag Set</th>
                    <th></th>
                  </tr>
                  {chk_record.map((obj, index) => (
                    <tr key={index}>
                      <td>{obj.id}</td>
                      <td>{obj.checklist_name}</td>
                      <td>{obj.name}</td>
                      <td>
                        <input
                          type="checkbox"
                          checked={obj.flag_set === "true" ? true : false}
                          onChange={(e) => changeValue(e, index)}
                        ></input>
                      </td>
                      <td>
                        {ck_button.map((btn, btn_i) => (
                          <button
                            className=" insrtbtn2 btn btn-primary "
                            key={btn_i}
                            onClick={(e) => callCheckListbtn()}
                          >
                            {btn.name}
                          </button>
                        ))}
                        {/* <button
                  className=" insrtbtn2 btn btn-primary "
                  onClick={(e) => callCheckListbtn()}
                >
                  {"save"}
                </button> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div>
                <table className="table table-striped">
                  <thead style={{ textAlign: "center" }}>
                    <tr>
                      <th scope="col">Event</th>
                      <th scope="col"></th>
                      {evtLoading === true ? (
                        <WorkInProgressSmall></WorkInProgressSmall>
                      ) : (
                        <th scope="col"></th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Id</th>
                      {/* <th>Name</th> */}
                      <th>Event</th>
                      <th>State</th>
                      <th></th>
                    </tr>
                    {event_record.length > 0 &&
                      event_record.map((obj, index) => (
                        <tr key={index}>
                          <td>{obj.id}</td>
                          {/* <td>{obj.name}</td> */}
                          <td>{obj.event_id.value}</td>
                          <td>
                            <select
                              className="form-select evtinputlis"
                              aria-label="Default"
                              value={obj.state}
                              onChange={(e) => changeState(e, index)}
                            >
                              {console.log(JSON.stringify(evt_choice))}
                              {evt_choice.length > 0 &&
                                evt_choice.map((obj2, index2) => (
                                  <option key={index2} value={obj2.value}>
                                    {obj2.name}
                                  </option>
                                ))}
                            </select>
                          </td>
                          <td>
                            {evt_button.map((btn, btn_i) => (
                              <button
                                className=" insrtbtn2 btn btn-primary "
                                key={btn_i}
                                onClick={(e) => callEventbtn(obj)}
                              >
                                {btn.name}
                              </button>
                            ))}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="card">
            <div className="c1">
              <div>
                <div className="mb-3 ">
                  <strong>Important Records</strong>
                </div>
                {stateLoading === true ? (
                  <WorkInProgressSmall></WorkInProgressSmall>
                ) : null}
                {console.log(imp_record.length, stateLoading)}
                {imp_record.length > 0 &&
                  imp_record.map((obj, index) => (
                    <div>
                      {console.log(obj)}
                      <strong key={index}>
                        {obj.name.charAt(0).toUpperCase() + obj.name.slice(1)}
                      </strong>
                      <button
                        type="button"
                        className={
                          obj.ref
                            ? "imp_record_dropdown_btn"
                            : "imp_record_dropdown_btnp"
                        }
                        onClick={(pr) => setOpen(obj.position, obj.ref)}
                      >
                        {obj.ref ? "-" : "+"}
                      </button>
                      {console.log(obj.ref)}
                      {obj.ref === true && (
                        <div>
                          <table className="table table-striped">
                            <tbody>
                              <tr>
                                <th>Id</th>
                                <th>Name</th>
                                <th>Value</th>
                                <th></th>
                              </tr>
                              <Fragment key={index}>
                                {obj.records.map((obj_in, index_in) => (
                                  <tr key={index_in}>
                                    <td>{obj_in.id}</td>
                                    <td>{obj_in.name}</td>
                                    <td>
                                      {obj_in.record[1].type === "String" ? (
                                        <div className="form-group objpdg ">
                                          <span>{obj_in.record[1].label}</span>
                                          <input
                                            type="text"
                                            value={obj_in.record[1].value}
                                            readOnly={
                                              obj_in.record[1].uivalid
                                                .read_only === "true"
                                            }
                                            maxLength={
                                              obj_in.record[1].uivalid
                                                .max_length
                                            }
                                            onChange={(e) =>
                                              formChangefn(
                                                e.target.value,
                                                index,
                                                index_in,
                                                obj_in.record[1].name,
                                                obj_in.record[1].type,
                                                obj_in.record[1].id,
                                                false
                                              )
                                            }
                                            onMouseOut={(e) =>
                                              validationfn(
                                                e.target.value,
                                                index,
                                                index_in,
                                                obj_in.record[1].name,
                                                obj_in.record[1].type,
                                                obj_in.record[1].id
                                              )
                                            }
                                          ></input>
                                        </div>
                                      ) : null}
                                      {obj_in.record[1].type === "int" ? (
                                        <div className="form-group">
                                          <span>{obj_in.record[1].label}</span>
                                          <input
                                            type="text"
                                            value={obj_in.record[1].value}
                                            readOnly={
                                              obj_in.record[1].uivalid
                                                .read_only === "true"
                                            }
                                            maxLength={
                                              obj_in.record[1].uivalid
                                                .max_length
                                            }
                                            onChange={(e) =>
                                              formChangefn(
                                                e.target.value,
                                                index,
                                                index_in,
                                                obj_in.record[1].name,
                                                obj_in.record[1].type,
                                                obj_in.record[1].id,
                                                false
                                              )
                                            }
                                            onMouseOut={(e) =>
                                              validationfn(
                                                e.target.value,
                                                index,
                                                index_in,
                                                obj_in.record[1].name,
                                                obj_in.record[1].type,
                                                obj_in.record[1].id
                                              )
                                            }
                                          ></input>
                                        </div>
                                      ) : null}
                                      {obj_in.record[1].type === "date" ? (
                                        <div className=" form-group">
                                          <span>{obj_in.record[1].label}</span>
                                          <input
                                            type="date"
                                            value={obj.record.value}
                                            readOnly={
                                              obj_in.record[1].uivalid
                                                .read_only === "true"
                                            }
                                            maxLength={
                                              obj_in.record[1].uivalid
                                                .max_length
                                            }
                                            onChange={(e) =>
                                              formChangefn(
                                                e.target.value,
                                                index,
                                                index_in,
                                                obj_in.record[1].name,
                                                obj_in.record[1].type,
                                                obj_in.record[1].id,
                                                false
                                              )
                                            }
                                            onMouseOut={(e) =>
                                              validationfn(
                                                e.target.value,
                                                index,
                                                index_in,
                                                obj_in.record[1].name,
                                                obj_in.record[1].type,
                                                obj_in.record[1].id
                                              )
                                            }
                                          ></input>
                                        </div>
                                      ) : null}
                                      {obj_in.record[1].type === "datetime" ? (
                                        <div className=" form-group">
                                          <span>{obj_in.record[1].label}</span>
                                          <input
                                            type="datetime-local"
                                            step="1"
                                            value={obj_in.record[1].value}
                                            readOnly={
                                              obj_in.record[1].uivalid
                                                .read_only === "true"
                                            }
                                            maxLength={
                                              obj_in.record[1].uivalid
                                                .max_length
                                            }
                                            onChange={(e) =>
                                              formChangefn(
                                                e.target.value,
                                                index,
                                                index_in,
                                                obj_in.record[1].name,
                                                obj_in.record[1].type,
                                                obj_in.record[1].id,
                                                false
                                              )
                                            }
                                            onMouseOut={(e) =>
                                              validationfn(
                                                e.target.value,
                                                index,
                                                index_in,
                                                obj_in.record[1].name,
                                                obj_in.record[1].type,
                                                obj_in.record[1].id
                                              )
                                            }
                                          ></input>
                                        </div>
                                      ) : null}
                                      {obj_in.record[1].type === "boolean" ? (
                                        <div className="form-check fmcheck">
                                          <span>{obj_in.record[1].label}</span>
                                          <input
                                            type="checkbox"
                                            className={
                                              obj_in.verified === "unverified"
                                                ? "checkpadd unverifi"
                                                : "checkpadd"
                                            }
                                            checked={
                                              obj_in.record[1].value === "true"
                                                ? true
                                                : false
                                            }
                                            onChange={(e) =>
                                              formChangefn(
                                                e.target.checked.toString(),
                                                index,
                                                index_in,
                                                obj_in.record[1].name,
                                                obj_in.record[1].type,
                                                obj_in.record[1].id,
                                                false
                                              )
                                            }
                                            // onMouseOut={(e) =>
                                            //   validationfn(
                                            //     e.target.value,
                                            //     index,
                                            //     obj.record[1].name,
                                            //     obj.record[1].type,
                                            //     obj.record[1].id
                                            //   )
                                            // }
                                          ></input>
                                        </div>
                                      ) : null}
                                      {obj_in.record[1].type === "choice" ? (
                                        <div className="form-group ">
                                          <span>{obj_in.record[1].label}</span>
                                          <select
                                            className="form-control form-select formpadd "
                                            aria-label="Default select example"
                                            // onMouseOut={(e) =>
                                            //   this.validationfn(
                                            //     e.target.value,
                                            //     index,
                                            //     obj_in.record[1].name
                                            //   )
                                            // }
                                            value={obj_in.record[1].value}
                                            onChange={(e) => {
                                              console.log(e.target.value);
                                              formChangefn(
                                                e.target.value,
                                                index,
                                                index_in,
                                                obj_in.record[1].name,
                                                obj_in.record[1].type,
                                                obj_in.record[1].id,
                                                false
                                              );
                                            }}
                                            maxLength={
                                              obj_in.record[1].uivalid
                                                .max_length
                                            }
                                            readOnly={
                                              obj_in.record[1].uivalid
                                                .read_only === "true"
                                            }
                                          >
                                            <option value="None">None</option>
                                            {obj_in.record[1].choice.map(
                                              (ch, chi) => (
                                                <option
                                                  key={chi}
                                                  value={ch.value}
                                                >
                                                  {ch.name}
                                                </option>
                                              )
                                            )}
                                          </select>
                                        </div>
                                      ) : null}
                                      {obj_in.record[1].type === "reference" ? (
                                        <div>
                                          <div className="form-group">
                                            <span>
                                              {obj_in.record[1].label}
                                            </span>
                                            <div style={{ display: "flex" }}>
                                              <input
                                                type="text"
                                                value={
                                                  obj_in.record[1].value.name
                                                }
                                                readOnly={
                                                  obj_in.record[1].uivalid
                                                    .read_only === "true"
                                                }
                                                onChange={(e) =>
                                                  formChangefn(
                                                    e.target.value,
                                                    index,
                                                    index_in,
                                                    obj_in.record[1].name,
                                                    obj_in.record[1].type,
                                                    obj_in.record[1].id,
                                                    false
                                                  )
                                                }
                                                onMouseOut={(e) => {
                                                  // validationfn(
                                                  //   e.target.value,
                                                  //   index,
                                                  //   index_in,
                                                  //   obj_in.record[1].name,
                                                  //   obj_in.record[1].type,
                                                  //   obj_in.record[1].id
                                                  // )
                                                }}
                                              ></input>
                                              <div className="btnsrc vlpointer">
                                                <i
                                                  className="fa fa-search"
                                                  onClick={(e) => {
                                                    clickRefrence(
                                                      e,
                                                      obj_in.record[1].id,
                                                      obj_in.record[1].name,
                                                      obj_in.record[1].type,
                                                      index,
                                                      index_in,
                                                      obj_in.record[1].label
                                                    );
                                                    //   setcolumn(
                                                    //   index,
                                                    //   index_in,
                                                    //   obj_in.record[1].name,
                                                    //   obj_in.record[1].type,
                                                    //   obj_in.record[1].id
                                                    // )
                                                  }}
                                                ></i>
                                              </div>
                                            </div>
                                          </div>
                                          {refrecord.index === index &&
                                            refrecord.record.length > 0 && (
                                              <div
                                                style={{ position: "absolute" }}
                                              >
                                                {refrecord.record.map(
                                                  (obj_ref, or_i) => (
                                                    <div
                                                      className="refrcd"
                                                      onClick={(e) => {
                                                        setRefrecord(
                                                          obj_ref.value,
                                                          index,
                                                          index_in,
                                                          obj_in.record[1].name,
                                                          obj_in.record[1].type,
                                                          obj_ref.id
                                                        );
                                                      }}
                                                      key={or_i}
                                                    >
                                                      {obj_ref.value}
                                                    </div>
                                                  )
                                                )}
                                              </div>
                                            )}
                                        </div>
                                      ) : null}
                                      {/* {obj_in.record[1].type === "filter" ? (
                              <div className="form-group objpdg ">
                                <span>{obj.record.label}</span>
                                <div>
                                  <NewFilterCompo
                                  filtarray={filtarray}
                                  timeline={timeLine}
                                  setTimeLine={setTimeLine}
                                  setFiltArray={setFiltarray}
                                  col_mn={col_mn}
                                  showlist={showlist}
                                  tableName={listName}
                                  ></NewFilterCompo>
                                </div>
                              </div>
                            ) : null} */}
                                    </td>
                                  </tr>
                                ))}
                              </Fragment>
                            </tbody>
                          </table>

                          <button
                            className=" insrtbtn2 btn btn-primary "
                           
                          /*    onClick={(e) => callbtnNew(obj_in, index)}  */
                             onClick={(e) => callbtnNew(obj,index)} 
                          >
                            Save
                          </button>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal show={showModelList} onHide={cancelModelList} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Personalized List Column</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mod-ht">
          <ModelList
            setRef={setRef}
            columnid={columnId}
            onCancel={cancelModelList}
            previousFilter={"null"}
            previousTimeLine={" "}
            tableName={objLabel}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelModelList}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ChecklistComponent;
