import React, { useContext, useState } from "react";
import { AutoPortalContext } from "../Context";
import { Card, CardBody, CardHeader, CardFooter } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import AlertCompo from "./AlertCompo";

const SetNewPassword = () => {
  // useContext hook
  const { token, loca } = useContext(AutoPortalContext);

  //   useLocation hook
  const { op, un, app } = useLocation().state || 0;

  //   useState hook
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [sp, setSp] = useState(true);
  const [flag, setFlag] = useState(false);
  const [errorMsg, setErrorMsg] = useState([]);
  const navigation = useNavigate();

  const logincall = () => {
    navigation("/login");
  };

  const updateCall = () => {
    let eMsg = [];
    console.log(op, oldPassword);

    // if (op === oldPassword) {
    //   setErrorMsg([]);
    //   setFlag(false);
    // } else {
    //   eMsg.push("old password is wrong!!");
    //   setFlag(true);
    // }

    // if (newPassword === confirmPassword) {
    //   setErrorMsg([]);
    //   setFlag(false);
    // } else {
    //   eMsg.push("NewPassword and ConfirmPassword does not match!!");
    //   setFlag(true);
    // }

    if (!oldPassword && !newPassword && !confirmPassword) {
      eMsg.push("Password fields are empty");
      setFlag(true);
    } else if (oldPassword && !newPassword && !confirmPassword) {
      eMsg.push("NewPassword and confirmPassword is empty");
      setFlag(true);
    } else if (oldPassword && newPassword && !confirmPassword) {
      eMsg.push("ConfirmPassword is empty");
      setFlag(true);
    } else if (oldPassword && !newPassword && confirmPassword) {
      eMsg.push("NewPassword is empty");
      setFlag(true);
    } else if (!oldPassword && newPassword && confirmPassword) {
      eMsg.push("OldPassword is empty");
      setFlag(true);
    } else if (!oldPassword && newPassword && !confirmPassword) {
      eMsg.push("OldPassword and confirmPassword is empty");
      setFlag(true);
    } else if (!oldPassword && !newPassword && confirmPassword) {
      eMsg.push("OldPassword and newPassword is empty");
      setFlag(true);
    } else if (op !== oldPassword) {
      eMsg.push("old password is wrong!!");
      setFlag(true);
    } else if (oldPassword === newPassword) {
      eMsg.push("OldPassword and NewPassword cannot be same");
      setFlag(true);
    } else if (newPassword !== confirmPassword) {
      eMsg.push("NewPassword and ConfirmPassword does not match!!");
      setFlag(true);
    } else {
      setErrorMsg([]);
      setFlag(false);
    }

    if (!eMsg.length > 0) {
      let json = {
        username: un,
        password: newPassword,
        application: app,
      };
      console.log(JSON.stringify(json));
      console.log("t : " + token);
      axios
        .post(loca + "/lom/set/newpassword", json, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
          },
        })
        .then(
          (resp) => {
            let result = resp.data;
            if ("Error" in result) {
              console.log(result.Error);
            } else {
              navigation("/login");
              console.log(result.Success);
            }
          },
          (error) => {
            navigation("/error");
            console.log(error);
          }
        );
    } else {
      console.log(JSON.stringify(eMsg));
      setErrorMsg([...eMsg]);
    }
  };

  // useEffect(() => {
  //   console.log(op);
  //   console.log(un);
  //   if (op === undefined || un === undefined) {
  //     navigation("/login");
  //   }
  // }, []);

  return (
    <div className="parent-div">
      <Card className="bg-auth-1 card-width-manual">
        <CardHeader className="border-0">
          <h4 className="bg-auth-1 text-center mb-0 mt-1">Set New Password</h4>
          <hr className="my-2" />
          {flag &&
            errorMsg.map((obj, index) => (
              <div className="my-2">
                <AlertCompo type={"danger"} msg={obj} />
              </div>
            ))}
        </CardHeader>

        <CardBody className="bg-auth-1 pt-0 pb-0 text-center">
          <div className="mt-2">
            <i
              className="fa fa-user"

              style={{
                border: "2.5px solid white",
                height: "4.5rem",
                width: "4.5rem",
                fontSize: "62px",
                textAlign: "center",
                borderRadius: "39px",
              }}
            ></i>
          </div>
          <div className="mt-4">
            <div className="pb-2">
              <input
                type="text"
                name="password"
                placeholder="Oldpassword"
                className=" inpptt "
                value={oldPassword}
                onChange={(e) => {
                  setOldPassword(e.target.value);
                }}
              ></input>
            </div>
            <div className="pb-2">
              <input
                type={sp ? "password" : "text"}
                name="password"
                placeholder="NewPassword"
                className=" inpptt "
                maxLength={8}
                value={newPassword}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                }}
              ></input>
            </div>

            <div className="pb-2">
              <input
                type={sp ? "password" : "text"}
                name="password"
                placeholder="ConfirmPassword"
                className="inpptt"
                maxLength={8}
                value={confirmPassword}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                }}
              ></input>
            </div>
            <div className="d-flex align-items-center  justify-content-center">
              <span> Show Password</span>
              <input
                className="ms-2 manual-checkbox"
                type="checkbox"
                checked={!sp}
                onChange={(e) => {
                  setSp(!e.target.checked);
                }}
              />
            </div>
          </div>

          <div className="mt-3 mt-2 mb-3">
            <button
              className="btn btn-warning btn-width"
              onClick={() => {
                updateCall();
              }}
            >
              Update
            </button>
          </div>
          <hr className="mt-2 mb-0" />
        </CardBody>

        <CardFooter className="border-0">
          <div className="cursor-pointer d-flex align-items-center gap-1 mb-1 mx-1">
            <i className="fa fa-arrow-left"></i>
            <span onClick={logincall}>Login</span>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};

export default SetNewPassword;
