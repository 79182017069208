import axios from "axios";
import React from "react";
import { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { AutoPortalContext } from "../Context";
import MultiInsertMT from "./MultiInsertMT";
import WorkInProgress from "./WorkInProgress";

const MultiTableRelation = () => {
  // useNavigation hook
  const navigation = useNavigate();
  // useContext hook
  const { token, loca } = useContext(AutoPortalContext);

  //   useLocation hook
  const { tableName } = useLocation().state;

  // useState hooks
  const [name, setName] = useState(tableName);
  const [firstRecord, setFirstRecord] = useState({});
  const [multiRecord, setMultiRecord] = useState({});
  const [loading, setLoading] = useState(true);
  const [stateMt, setStateMt] = useState(0);
  const [multTrcd, setMultTrcd] = useState([]);
  const [record, setRecord] = useState("");
  const [button, setButton] = useState("");
  const [mainRecord, setMainRecord] = useState("");
  const [tabName, setTabName] = useState("");
  const [pageError, setPageError] = useState(false);
  const [error, setError] = useState("");
  const [pageMessage, setPageMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [insertedRecord, setInsertedRecord] = useState([]);
  const [tabObj, setTabObj] = useState([]);
  const [tabFill, setTabFill] = useState([]);
  const [errorList, setErrorList] = useState([]);
  const [stateNxt, setStateNxt] = useState(0);
  const [childTable, setChildTable] = useState("");

  const getInitialData = () => {
    axios
      .get(loca + "/lom/get/multipage/" + name, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          const mprecord = resp.data;
          console.log(JSON.stringify(mprecord));
          if (mprecord !== "") {
            if ("Error" in mprecord) {
              //   setState({
              //     loading: false,
              //     page_error: true,
              //     error: mprecord.Error,
              //   });
              setLoading(false);
              setPageError(true);
              setError(mprecord.Error);
            } else {
              // setState({
              //   loading: false,
              //   first_record: mprecord,
              //   state_mt: 1,
              //   record: mprecord.formRecord[2].record,
              //   mainrecord: mprecord,
              //   button: mprecord.formRecord[3].button,
              // });
              console.log("check");
              setFirstRecord(mprecord);
              setRecord(mprecord.formRecord[2].record);
              setStateMt(1);
              setMainRecord(mprecord);
              setButton(mprecord.formRecord[3].button);
              setLoading(false);
            }
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const callbtn = (nam, rcd) => {
    console.log("record : " + JSON.stringify(rcd));
    var btn = button;
    var mnrecord = mainRecord;
    // var rcd = record;
    var mandatory = [];
    var unverified = [];

    for (var i = 0; i < rcd.length; i++) {
      if (rcd[i].uivalid.visible === "true") {
        if (rcd[i].uivalid.mandatory === "true") {
          if (rcd[i].value === "") {
            mandatory.push(rcd[i].name);
          }
        }
        if (
          rcd[i].type === "String" ||
          rcd[i].type === "int" ||
          rcd[i].type === "email" ||
          rcd[i].type === "date"
        ) {
          var veri = fieldverify(rcd[i].type, rcd[i].value);
          console.log("verif : " + veri);
          if (veri === "unverified") {
            unverified.push(rcd[i].name);
          }
        }
      }
    }
    var btntype = "";
    var foundbtn = false;

    for (var ij = 0; ij < btn.length; ij++) {
      if (btn[ij].value === nam) {
        btntype = btn[ij].returnWebLocation;
        foundbtn = true;
        break;
      }
    }
    if (foundbtn === true && btntype !== "") {
      if (mandatory.length === 0 && unverified.length === 0) {
        // setState({
        //   page_error: false,
        //   error: "",
        //   loading: true,
        //   page_message: false,
        //   message: "",
        //   state_nxt: 0,
        // });
        setPageError(false);
        setError("");
        setPageMessage(false);
        setMessage("");
        setStateNxt(0);

        mnrecord.formRecord[2].record = rcd;
        // console.log("mnrecord : "+JSON.stringify(mnrecord));
        // var token = localStorage.getItem("token");
        axios
          .post(loca + btn[ij].webUrl, mnrecord, {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + token,
            },
          })
          .then((resp) => {
            const rcd = resp.data;
            console.log(rcd);
            if (rcd !== "") {
              if ("Error" in rcd) {
                // setState({
                //   loading: false,
                //   page_error: true,
                //   error: rcd.Error,
                // });
                setPageError(true);
                setError(rcd.Error);
                setLoading(false);
              } else {
                var arr = insertedRecord;
                var tab_arr = tabObj;
                // var fill_arr = tab_fill;
                arr.push(rcd.formRecordList[10].firstRecord);
                tab_arr.push(
                  rcd.formRecordList[10].firstRecord.formRecord[1].table
                );

                // setState({
                //   multi_record: rcd,
                //   loading: false,
                //   tab_obj: tab_arr,
                //   child_table: rcd.formRecordList[1].table.value,
                //   insertedRecord: arr,
                //   state_mt: 2,
                //   button: rcd.formRecordList[8].button,
                //   mainrecord: rcd,
                //   state_nxt: 1,
                // });
                console.log(JSON.stringify(rcd));
                setMultiRecord(rcd);
                setTabObj(tab_arr);
                setChildTable(rcd.formRecordList[1].table.value);
                setInsertedRecord(arr);
                setStateMt(2);
                setButton(rcd.formRecordList[8].button);
                setMainRecord(rcd);
                setStateNxt(1);
                setLoading(false);

                // if (btntype === "NextPage") {
                //   var nextP = rcd.formRecord[3].button.nextPage;
                //   if (nextP === "List") {
                //     callNextPage();
                //   }
                // }
              }
            }
          });
      } else {
        var error_String = "";
        if (mandatory.length > 0) {
          error_String += " Check mandatory fields not set: " + mandatory;
        }
        if (unverified.length > 0) {
          error_String += " Fields unverified:" + unverified;
        }
        // setState({
        //   loading: false,
        //   page_error: true,
        //   error: error_String,
        // });
        setPageError(true);
        setError(error_String);
        setLoading(false);
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }
    }
  };

  const callbtnMI = (nam, rec, js) => {
    // setState({ record: rec });
    setLoading(true);
    console.log("rec : " + JSON.stringify(rec));
    setRecord(rec);
    var btn = button;
    var json = js;
    console.log(json);

    var mnrecord = js.formRecordList[2].records;
    var relationType = mainRecord.formRecordList[9].multiPage.mtr.type;
    var rcd = rec;
    mainRecord.formRecordList[2].records = rcd;
    var errorlist = errorList;
    var blankcount = 0;
    for (var i = 0; i < rcd.length; i++) {
      for (var k = 0; k < rcd[i].record.length; k++) {
        validationMI(
          rcd[i].record[k].value,
          i,
          k,
          rcd[i].record[k].name,
          rcd[i].record[k].type,
          rcd
        );
        if (rcd[i].record[k].value === "") {
          rcd[i].record[k].blank = true;
          blankcount++;
        }
      }
    }
    // console.log(mnrecord);
    if (blankcount === 0) {
      if (errorlist.length === 0) {
        // if (relationType === "user") {
        //   for (let i = 0; i < rcd.length-1; i++) {
        //     var reco=JSON.parse(JSON.stringify(mainRecord.formRecordList[2].records[0]))
        //     console.log(reco);
        //     mnrecord.push(reco)
        //   }
        // }
        console.log(mnrecord);
        for (var kk = 0; kk < mnrecord.length; kk++) {
          for (var r = 0; r < mnrecord[kk].record.length; r++) {
            for (var m = 0; m < rcd[kk].record.length; m++) {
              if (mnrecord[kk].record[r].name === rcd[kk].record[m].name) {
                mnrecord[kk].record[r].value = rcd[kk].record[m].value;
              }
            }
          }
        }
        // mnrecord=rcd;
        json.formRecordList[2].records = mnrecord;
      }
    } else {
      //   setState({ record: rcd, fieldblank: true });
      setRecord(rcd);
      // setFieldblank(true)
    }
    console.log("json : " + JSON.stringify(json));
    if (blankcount === 0) {
      for (var ij = 0; ij < btn.length; ij++) {
        if (btn[ij].name === nam) {
          var btntype = btn[ij].returnWebLocation;
          // var token = localStorage.getItem("token");
          if (btntype === "NextPage") {
            console.log("checking " + btn[ij].webUrl);
            // mnrecord.formRecord[2].record = record;
            axios
              .post(loca + btn[ij].webUrl, json, {
                headers: {
                  "Content-Type": "application/json",
                  authorization: "Bearer " + token,
                },
              })
              .then((resp) => {
                const refrencercd = resp.data;
                console.log("result : " + JSON.stringify(refrencercd));
                if (refrencercd !== "") {
                  if ("Error" in refrencercd) {
                    // setState({
                    //   loading: false,
                    //   page_error: true,
                    //   error: refrencercd.Error,
                    // });
                    setPageError(true);
                    setError(refrencercd.Error);
                    setLoading(false);
                  } else if ("Message" in refrencercd) {
                    // setState({ loading: false });
                    var msg = refrencercd.Message;
                    if (msg !== "") {
                      //   setState({
                      //     page_message: true,
                      //     message: msg,
                      //     loading: false,
                      //   });
                      setPageMessage(true);
                      setMessage(msg);
                      setLoading(false);
                      var nxt_rcd = insertedRecord;
                      var tab = nxt_rcd[0].formRecord[1].table.value;
                      console.log(tab);
                      navigation("/list", { state: { listName: tab } });
                      //   props.showListCompo(tab, "", "");
                      console.log("kkk");
                    }
                  } else {
                    console.log("checking insert MT");
                    var nxt_rcd = insertedRecord;
                    var tabname = tabObj;
                    nxt_rcd.push(refrencercd.formRecordList[10].nextRecord);
                    console.log(nxt_rcd);
                    tabname.push(
                      refrencercd.formRecordList[10].nextRecord
                        .formRecordList[1].table
                    );
                    // setState({
                    //   multi_record: refrencercd,
                    //   loading: false,
                    //   child_table: refrencercd.formRecordList[1].table.value,
                    //   state_nxt: 1,
                    //   tab_obj: tabname,
                    //   state_mt:
                    //     refrencercd.formRecordList[9].multiPage.mtr
                    //       .relationOrder,
                    //   insertedRecord: nxt_rcd,
                    //   button: refrencercd.formRecordList[8].button,
                    //   mainrecord: refrencercd,
                    // });
                    setMultiRecord(refrencercd);
                    setChildTable(refrencercd.formRecordList[1].table.value);
                    setStateNxt(1);
                    setTabObj(tabname);
                    setStateMt(
                      refrencercd.formRecordList[9].multiPage.mtr.relationOrder
                    );
                    setInsertedRecord(nxt_rcd);
                    setButton(refrencercd.formRecordList[8].button);
                    setMainRecord(refrencercd);
                    setLoading(false);
                  }
                }
              });
          } else {
          }
        }
      }
    }
  };

  const validationMI = (vl, index, i, ob, type, formrecord) => {
    // var formrecord = record;
    if (type === "reference") {
      if (formrecord[index].record[i].noref === "false") {
        formrecord[index].record[i].verified = "verified";
      } else if (formrecord[index].record[i].noref === "true") {
        if (vl !== "") {
          if (formrecord[index].record[i].clicked === true) {
            formrecord[index].record[i].verified = "verified";
          } else {
            // var token = localStorage.getItem("token");
            // var veri = '{"referenceRecord":[{"columnid":"' + i + '"},';
            // veri += '{"tabvalue":"' + vl + '"}]}';
            // axios
            //   .post(
            //     loca+"/loom/reference/verify",
            //     veri.toString(),
            //     {
            //       headers: {
            //         "Content-Type": "application/json",
            //         authorization: "Bearer " + token,
            //       },
            //     }
            //   )
            //   .then((resp) => {
            //     const refrencercd = resp.data;
            //     if ("Error" in refrencercd) {
            //       setState({
            //         loading: false,
            //         page_error: true,
            //         error: refrencercd.Error,
            //       });
            //     } else {
            //       if (refrencercd.Result === "Unverified") {
            //         formrecord[index].record[i].verified = "unverified";
            //         setState({ record: formrecord });
            //       } else {
            //         formrecord[index].record[i].verified = "verified";
            //         var rfrcd = refrecord;
            //         rfrcd.record = [];
            //         setState({ record: formrecord, refrecord: rfrcd });
            //       }
            //       setState({ record: formrecord });
            //       return;
            //     }
            //   });
          }
        } else {
          formrecord[index].record[i].verified = "initial";
          //   setState({ record: formrecord });
          setRecord(formrecord);
        }
      }
    } else {
      var err = pageError;
      var nam = formrecord[index].record[i].name;
      var errorlist = errorList;
      if (nam === ob) {
        var lab = formrecord[index].record[i].label;
        if (vl !== "") {
          var vari = fieldverify(formrecord[index].record[i].type, vl);
          if (vari === "verified" && errorlist.length === 0) {
            err = false;
          } else if (vari === "verified" && errorlist.length > 0) {
            if (errorlist.length === 1) {
              if (errorlist[0].name === nam) {
                if (errorlist[0].count.length === 1) {
                  if (
                    errorlist[0].count[0].index === index &&
                    errorlist[0].count[0].i === i
                  ) {
                    errorlist.pop();
                    err = false;
                  }
                } else if (errorlist[0].count.length > 1) {
                  for (var bb = 0; bb < errorlist[0].count.length; bb++) {
                    if (
                      errorlist[0].count[bb].index === index &&
                      errorlist[0].count[bb].i === i
                    ) {
                      errorlist[0].count.splice(bb, 1);
                      err = true;
                    }
                  }
                }
              }
            } else if (errorlist.length > 1) {
              for (var cc = 0; cc < errorlist.length; cc++) {
                if (errorlist[cc].name === nam) {
                  if (errorlist[cc].count.length === 1) {
                    if (
                      errorlist[cc].count[0].index === index &&
                      errorlist[cc].count[0].i === i
                    ) {
                      errorlist.splice(cc, 1);
                      err = true;
                    }
                  } else if (errorlist[cc].count.length > 1) {
                    for (var cb = 0; cb < errorlist[cc].count.length; cb++) {
                      if (
                        errorlist[cc].count[cb].index === index &&
                        errorlist[cc].count[cb].i === i
                      ) {
                        errorlist[cc].count.splice(cb, 1);
                        err = true;
                      }
                    }
                  }
                }
              }
            }
          } else if (vari === "unverified" && errorlist.length === 0) {
            if (!errorlist.includes(nam)) {
              errorlist.push({
                name: nam,
                label: lab,
                count: [{ index: index, i: i }],
              });
            }
            err = true;
          } else if (vari === "unverified" && errorlist.length > 0) {
            if (errorlist.length === 1) {
              if (errorlist[0].name === nam) {
                if (errorlist[0].count.length === 1) {
                  if (
                    errorlist[0].count[0].index === index &&
                    errorlist[0].count[0].i === i
                  ) {
                    err = true;
                  } else if (
                    errorlist[0].count[0].index !== index ||
                    errorlist[0].count[0].i !== i
                  ) {
                    errorlist[0].count.push({ index: index, i: i });
                    err = true;
                  }
                } else if (errorlist[0].count.length > 1) {
                  var incount = 0;
                  for (var ii = 0; ii < errorlist[0].count.length; ii++) {
                    if (
                      errorlist[0].count[ii].index === index &&
                      errorlist[0].count[ii].i === i
                    ) {
                      err = true;
                    } else if (
                      errorlist[0].count[ii].index !== index &&
                      errorlist[0].count[ii].i !== i
                    ) {
                      incount++;
                    }
                  }
                  if (incount === errorlist[0].count.length) {
                    errorlist[0].count.push({ index: index, i: i });
                    err = true;
                  }
                }
              } else {
                errorlist.push({
                  name: nam,
                  label: lab,
                  count: [{ index: index, i: i }],
                });
                err = true;
              }
            } else if (errorlist.length > 1) {
              var errcount = 0;
              for (var kk = 0; kk < errorlist.length; kk++) {
                if (errorlist[kk].name === nam) {
                  if (errorlist[kk].count.length === 1) {
                    if (
                      errorlist[kk].count[0].index === index &&
                      errorlist[kk].count[0].i === i
                    ) {
                      err = true;
                    } else if (
                      errorlist[kk].count[0].index !== index ||
                      errorlist[kk].count[0].i !== i
                    ) {
                      errorlist[kk].count.push({ index: index, i: i });
                      err = true;
                    }
                  } else if (errorlist[kk].count.length > 1) {
                    var incount2 = 0;
                    for (var iid = 0; iid < errorlist[kk].count.length; iid++) {
                      if (
                        errorlist[kk].count[iid].index === index &&
                        errorlist[kk].count[iid].i === i
                      ) {
                        err = true;
                      } else if (
                        errorlist[kk].count[iid].index !== index ||
                        errorlist[kk].count[iid].i !== i
                      ) {
                        incount2++;
                      }
                    }
                    if (incount2 === errorlist[kk].count.length) {
                      errorlist[kk].count.push({ index: index, i: i });
                      err = true;
                    }
                  }
                } else if (errorlist[kk].name !== nam) {
                  errcount++;
                }
              }
              if (errcount === errorlist.length) {
                errorlist.push({
                  name: nam,
                  label: lab,
                  count: [{ index: index, i: i }],
                });
                err = true;
              }
            }
          }
          formrecord[index].record[i].verified = vari;
        } else {
          if (errorlist.length === 1) {
            if (errorlist[0].name === nam) {
              if (errorlist[0].count.length === 1) {
                if (
                  errorlist[0].count[0].index === index &&
                  errorlist[0].count[0].i === i
                ) {
                  errorlist.pop();
                  err = false;
                }
              } else if (errorlist[0].count.length > 1) {
                for (var bbd = 0; bbd < errorlist[0].count.length; bbd++) {
                  if (
                    errorlist[0].count[bbd].index === index &&
                    errorlist[0].count[bbd].i === i
                  ) {
                    errorlist[0].count.splice(bbd, 1);
                    err = true;
                  }
                }
              }
            }
          } else if (errorlist.length > 1) {
            for (var ccd = 0; ccd < errorlist.length; ccd++) {
              if (errorlist[ccd].name === nam) {
                if (errorlist[ccd].count.length === 1) {
                  if (
                    errorlist[ccd].count[0].index === index &&
                    errorlist[ccd].count[0].i === i
                  ) {
                    errorlist.splice(ccd, 1);
                  }
                } else if (errorlist[ccd].count.length > 1) {
                  for (var cf = 0; cf < errorlist[ccd].count.length; cf++) {
                    if (
                      errorlist[ccd].count[cf].index === index &&
                      errorlist[ccd].count[cf].i === i
                    ) {
                      errorlist[ccd].count.splice(cf, 1);
                      err = true;
                    }
                  }
                }
              }
            }
          }
          formrecord[index].record[i].verified = "initial";
        }
      }

      //   setState({
      //     record: formrecord,
      //     page_error: err,
      //     errorlist: errorlist,
      //   });
      setRecord(formrecord);
      setPageError(err);
      setErrorList(errorlist);
    }
  };

  //   callNextPage() {
  //     // showListCompo(tabname);
  //   }

  const fieldverify = (type, vl) => {
    if (type === "String") {
      if (/[a-zA-Z]/.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }

    if (type === "email") {
      if (/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }
    if (type === "int") {
      if (/^[0-9]*[0-9][0-9]*$/.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }
    if (type === "date") {
      if (
        /^(?:(?:(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec))(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:(?:0?2|(?:Feb))(\/|-|\.)(?:29)\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(
          vl
        )
      ) {
        return "verified";
      } else {
        return "unverified";
      }
    }
  };

  //   useEffect hooks
  useEffect(() => {
    getInitialData();
  }, []);

  if (!loading) {
    return (
      <div style={{ flexGrow: 1 }}>
        {console.log("loading : " + loading)}
        {pageError === true && (
          <div
            className="alert alert-danger"
            role="alert"
            style={{
              padding: "0.2rem 0.2rem",
              marginBottom: "0px",
            }}
          >
            {error}
          </div>
        )}
        {pageMessage === true && (
          <div
            className="alert alert-success"
            role="alert"
            style={{
              padding: "0.2rem 0.2rem",
              marginBottom: "0px",
            }}
          >
            {message}
          </div>
        )}
        {stateMt === 1 && (
          <div>
            {/* <FormComponentMp firstRecord={firstRecord} callbtn={callbtn} /> */}
          </div>
        )}
        {stateMt > 1 && stateNxt === 1 && (
          <div>
            {console.log(insertedRecord)}
            <MultiInsertMT
              multi_record={multiRecord}
              callbtn={(nam, rec, js) => callbtnMI(nam, rec, js)}
              tableObj={tabObj}
              inserted_record={insertedRecord}
              state_mt={stateMt}
              child_table={childTable}
              // multiT_rcd={this.state.multiT_rcd}
            />
          </div>
        )}
      </div>
    );
  } else {
    return (
      <div className="compo-loader">
        <WorkInProgress />
      </div>
    );
  }
};

export default MultiTableRelation;
