import React, { useContext, useEffect, useRef, useState } from 'react'
import "../css/ListComponent.css";
import "../css/Invoice.css";
import DateFormatter from './DateFormatter'
import { AutoPortalContext } from '../Context';
import axios from 'axios';
import FormComponent from './FormComponent';
import { Button, Modal } from 'react-bootstrap';
import ModelList from './ModelList';
import { useNavigate } from 'react-router-dom';

function InvoiceCompo({ value, setMSC }) {

    const { token, loca } = useContext(AutoPortalContext)
    console.log(value);
    const navigation = useNavigate();
    const [heading, setHeading] = useState();
    const [record, setRecord] = useState();
    const [details, setDetails] = useState();
    const [col, setCol] = useState(value.record[0]);
    const [loading, setLoading] = useState(true);
    const [quantity, setQuantity] = useState(0);
    const [amount, setAmount] = useState(0);
    const [itemList, setItemList] = useState();
    const [columnId, setColumnId] = useState();
    const [objName, setObjName] = useState();
    const [objLabel, setObjLabel] = useState();
    const [tabName, setTabName] = useState();
    const [row, setRow] = useState();
    const [colum, setColum] = useState();
    const [showModelList, setshowModelList] = useState(false);
    const [ref_filter, setref_filter] = useState([]);
    const firstRender = useRef(false);

    useEffect(() => {
        init();
        const refarray = {
            filter: [
                {
                    co: "",
                    cl: "",
                    mc: "",
                    an: "",
                    ct: "",
                    af: "",
                    rf: { id: "", value: "" },
                    dc: { id: "", value: "" },
                },
            ],
        };
        setref_filter(refarray);

    }, [])

    useEffect(() => {
        if (firstRender.current) {
            let r = { record: record }
            setMSC(r);
        } else {
            firstRender.current = true;
        }
    }, [record])


    const init = () => {
        setLoading(true);
        var head = [];
        var colr = value.record[0].colr;
        for (let i = 0; i < colr.length; i++) {
            if (colr[i].name != "GST Ledger" && colr[i].name != "Tax Type" && colr[i].name != "Tax Amount") {
                head.push(colr[i].name);
            }
        }
        setHeading(head);
        setRecord(JSON.parse((JSON.stringify(value.record))));
        setLoading(false);
    }

    const setRef = (id, val) => {
        console.log("objjjjj" + JSON.stringify(val) + id, colum + 1);
        var hsnCode = "";
        axios
            .get(
                loca + `/lom/get/singlerecord/${tabName}/${id}`,
                {
                    headers: {
                        // "Content-Type": "application/json",
                        authorization: "Bearer " + token,
                    },
                }
            )
            .then(
                (resp) => {
                    const rcd = resp.data;
                    var mmm = rcd.formRecord[2].record;
                    console.log("dan2", mmm);
                    for (let i = 0; i < mmm.length; i++) {
                        if (mmm[i].name === "hsn_code") {
                            hsnCode = mmm[i].value;
                            formChangefn(
                                hsnCode,
                                "HSNCode",
                                row,
                                colum + 1
                            );
                        }
                        if (mmm[i].name === "gst_ledger") {
                            let frecord = record;
                            let a = record[row].colr.findIndex((e) => e.name === "GST Ledger");
                            frecord[row].colr[a].value = mmm[i].value;
                            // setRecord([...frecord]);
                        }
                        if (mmm[i].name === "tax_type") {
                            let frecord = record;
                            let a = record[row].colr.findIndex((e) => e.name === "Tax Type");
                            frecord[row].colr[a].value = mmm[i].value;
                            // setRecord([...frecord]);
                        }
                    }
                },
                (error) => {
                    // setLoading(false)
                    console.log(error);
                    let err = { message: error.message, code: error.response.status };
                    navigation("/error", { state: { err: err } });
                }
            );

        formChangefn(
            val,
            "Description",
            row,
            colum
        );
        console.log("zak");
        cancelModelList();
    };

    const formChangefn = (vl, name, row, col) => {
        console.log(record);
        console.log(vl, name, row, col);
        let frecord = record;
        if (frecord[row].colr[col].name === name) {
            frecord[row].colr[col].value = vl;
            console.log(frecord);
            setRecord([...frecord]);
        }

    }

    const totalfn = (vl, name, row, col) => {
        if (name === "Quantity") {
            var total = 0;
            for (let i = 0; i < record.length - 2; i++) {
                var val = record[i].colr[col].value;
                total += Number(val);
                console.log(total);
                total = Math.round(total * 100) / 100;
            }
            let a = record[record.length - 2].colr.findIndex((e) => e.name === "Total Quantity");
            // setQuantity(total);
            record[record.length - 2].colr[a].value = total;
        }

        let a = record[row].colr.findIndex((e) => e.name === "Amount");
        let tam = record[row].colr.findIndex((e) => e.name === "Tax Amount");
        let gs = record[row].colr.findIndex((e) => e.name === "GST Ledger");
        if (name === "Quantity" || name === "Rate") {

            if (name === "Quantity") {
                let b = record[row].colr.findIndex((e) => e.name === "Rate");

                if (record[row].colr[col].value !== "" && record[row].colr[b].value !== "") {
                    let qty = record[row].colr[col].value;
                    let rate = record[row].colr[b].value;
                    var val = Number(qty) * Number(rate);
                    val = Math.round(val * 100) / 100;
                    record[row].colr[a].value = val;
                    let gst = record[row].colr[gs].value.replace(/%/g, '');
                    let tax = val * (Number(gst) / 100);
                    tax = Math.round(tax * 100) / 100;
                    record[row].colr[tam].value = tax;
                    console.log(record[row]);
                } else {
                    record[row].colr[a].value = "";
                }
            } else if (name === "Rate") {
                let b = record[row].colr.findIndex((e) => e.name === "Quantity");
                console.log(b);
                if (record[row].colr[col].value !== "" && record[row].colr[b].value !== "") {
                    let qty = record[row].colr[b].value;
                    let rate = record[row].colr[col].value;
                    var val = Number(qty) * Number(rate);
                    val = Math.round(val * 100) / 100;
                    record[row].colr[a].value = val;
                    let gst = record[row].colr[gs].value.replace(/%/g, '');
                    let tax = val * (Number(gst) / 100);
                    tax = Math.round(tax * 100) / 100;
                    record[row].colr[tam].value = tax;
                    console.log(record[row]);
                } else {
                    record[row].colr[a].value = "";
                }
            }
        }
        var total = 0;
        var totalTax = 0;
        for (let i = 0; i < record.length - 2; i++) {
            var val = record[i].colr[a].value;
            total += Number(val);
            console.log(total);
            var tax = record[i].colr[tam].value;
            totalTax += Number(tax);
        }
        let parts = total.toString().split('.');
        total = parts[0] + '.' + '00';
        let ta = record[record.length - 2].colr.findIndex((e) => e.name === "Total Amount");
        record[record.length - 2].colr[ta].value = total;

        let tv = record[record.length - 1].colr.findIndex((e) => e.name === "Taxable Value");
        record[record.length - 1].colr[tv].value = total;

        let part = totalTax.toString().split('.');
        totalTax = part[0] + '.' + '00';
        let tx = record[record.length - 1].colr.findIndex((e) => e.name === "Tax Amount");
        record[record.length - 1].colr[tx].value = totalTax;

    }

    const handleAdd = () => {
        let frecord = record;
        for (let i = 0; i < col.colr.length; i++) {
            col.colr[i].value = "";
        }
        var co = JSON.parse((JSON.stringify(col)));
        const index = frecord.length - 2
        frecord.splice(index, 0, co);
        setRecord([...frecord]);
    }

    const handleRemove = (row) => {
        let frecord = record;
        frecord.splice(row, 1);
        setRecord([...frecord]);
        if (frecord.length === 0) {
            setAmount(0);
            setQuantity(0);
        } else {
            let a = record[0].colr.findIndex((e) => e.name === "Quantity");
            let b = record[0].colr.findIndex((e) => e.name === "Amount");

            var totalqty = 0;
            for (let i = 0; i < record.length - 2; i++) {
                var val = record[i].colr[a].value;
                totalqty += Number(val);
                console.log(totalqty);
            }
            let tq = record[record.length - 2].colr.findIndex((e) => e.name === "Total Quantity");
            // setQuantity(total);
            record[record.length - 2].colr[tq].value = totalqty;


            let tam = 0;
            if (row === 0) {
                record[row].colr.findIndex((e) => e.name === "Tax Amount");
            } else {
                record[row - 1].colr.findIndex((e) => e.name === "Tax Amount");
            }
            console.log(tam, row, record[row].colr);
            var totalamt = 0;
            var totalTax = 0;
            for (let i = 0; i < record.length - 2; i++) {
                var val = record[i].colr[b].value;
                totalamt += Number(val);
                console.log(totalamt);
                var tax = record[i].colr[tam].value;
                totalTax += Number(tax);
            }
            let parts = totalamt.toString().split('.');
            totalamt = parts[0] + '.' + '00';
            let ta = record[record.length - 2].colr.findIndex((e) => e.name === "Total Amount");
            record[record.length - 2].colr[ta].value = totalamt;
            // setAmount(totalamt);
            let tv = record[record.length - 1].colr.findIndex((e) => e.name === "Taxable Value");
            record[record.length - 1].colr[tv].value = totalamt;

            let part = totalTax.toString().split('.');
            totalTax = part[0] + '.' + '00';
            let tx = record[record.length - 1].colr.findIndex((e) => e.name === "Tax Amount");
            record[record.length - 1].colr[tx].value = totalTax;
        }

    }

    const cancelModelList = () => {
        console.log("llllllllll");
        // setFilt("null");
        setshowModelList(false);
    };

    const clickRefrence = (e, row, col) => {
        e.preventDefault();
        setRow(row);
        setColum(col);
        var record = "";
        axios
            .get(loca + "/lom/get/itemdetails", {
                headers: {
                    "Content-Type": "application/json",
                    authorization: "Bearer " + token,
                },
            })
            .then((resp) => {
                record = resp.data;
                setItemList(record);
                setColumnId(record.id);
                setObjName(record.colName);
                setObjLabel(record.tabLabel);
                setTabName(record.tabName);
                setshowModelList(true);
                console.log(record);
            },
                (error) => {
                    console.log(error);
                }
            );
    };

    if (!loading) {

        return (
            <div className="pagesetup pe-3">
                <table className="table table-bordered table-striped table-hover p-1 ">
                    <thead >
                        <tr className="obj_name">
                            {heading.map((obj, ind) => (
                                <th className="vlpointer p-0"
                                    style={{ verticalAlign: "middle", textAlign: "center" }}
                                    key={ind}
                                >
                                    <div className="">
                                        <span>{obj}</span>
                                    </div>
                                </th>
                            ))}
                        </tr>
                    </thead>

                    <tbody>
                        {record.map((ob, index) => (
                            <>
                                {index !== record.length - 1 && index !== record.length - 2 &&
                                    <tr className="obj_value" key={index}>
                                        {ob.colr.map((objj, indx) => (
                                            <>
                                                {
                                                    objj.name !== "GST Ledger" && objj.name !== "Tax Type" && objj.name !== "Tax Amount" &&
                                                    <td key={indx} className={indx === ob.colr.length - 1 ? "pos-rel" : ""} style={{ width: "10px", textAlign: "center" }}>
                                                        <div className="">
                                                            {objj.name !== "Description" &&
                                                                <input type='text'
                                                                    className='lom_form_control'
                                                                    value={objj.value}
                                                                    onChange={(e) => {
                                                                        formChangefn(
                                                                            e.target.value,
                                                                            objj.name,
                                                                            index,
                                                                            indx
                                                                        );
                                                                        totalfn(
                                                                            e.target.value,
                                                                            objj.name,
                                                                            index,
                                                                            indx
                                                                        )
                                                                    }
                                                                    }
                                                                >
                                                                </input>}
                                                            {objj.name === "Description" &&
                                                                <div>
                                                                    <div className="form-group">
                                                                        <div style={{ display: "flex", height: "1.9rem" }}>
                                                                            <input
                                                                                style={{ width: "-webkit-fill-available", border: "none" }}
                                                                                type="text"
                                                                                className="inpt_typefil_ref"
                                                                                value={objj.value}
                                                                                onChange={(e) => {
                                                                                    formChangefn(
                                                                                        e.target.value,
                                                                                        objj.name,
                                                                                        index,
                                                                                        indx
                                                                                    );
                                                                                }}
                                                                            // onBlur={(e) => {
                                                                            //     setTimeout(() => {
                                                                            //         if (obj.verified !== "verified") {
                                                                            //             validationfn(
                                                                            //                 // obj.value,
                                                                            //                 // obj.index,
                                                                            //                 // obj.name,
                                                                            //                 // obj.type,
                                                                            //                 // obj.id
                                                                            //             );
                                                                            //         }
                                                                            //     }, 500);
                                                                            // }}
                                                                            ></input>
                                                                            <div
                                                                                style={{ border: "none" }}
                                                                                className="btnsrc_fil vlpointer"
                                                                                onClick={(e) => {
                                                                                    clickRefrence(
                                                                                        e,
                                                                                        index,
                                                                                        indx
                                                                                    );
                                                                                }}
                                                                            >
                                                                                <i
                                                                                    className="fa fa-search"
                                                                                    
                                                                                    data-bs-toggle="modal"
                                                                                    data-bs-target="#staticBackdrop"
                                                                                ></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }
                                                            {indx === ob.colr.length - 4 &&
                                                                <span className='pos-abs' style={{ cursor: "pointer" }} onClick={() => handleRemove(index)}>
                                                                    <i className="fa-solid fa-circle-minus" style={{ color: "#f7481d" }}></i>
                                                                </span>
                                                            }
                                                        </div>
                                                    </td>
                                                }
                                            </>
                                        ))}
                                    </tr>}
                            </>
                        ))}
                        <tr>
                            {record[record.length - 2].colr.map((obj, ind) => (
                                // (obj.name ? 
                                <td>
                                    <div className="lom_flx fw-bold" key={ind} value={obj.value}>
                                        {obj.value}
                                    </div>
                                </td>
                                //  : <td style={{ border: "none", borderBottom: "hidden" }}></td>)
                            ))}
                        </tr>

                    </tbody>
                </table>
                <div className="" >
                    <span style={{ cursor: "pointer" }} onClick={handleAdd}>
                        <i className="fa-solid fa-circle-plus m-2" style={{ color: "#1fd19b" }}></i>
                        Add Line Item
                    </span>
                </div>

                <div style={{ marginTop: "1rem" }}>
                    <table className="table table-bordered table-striped table-hover p-1 ">
                        <thead className='text-ctr'>
                            <tr className="obj_name">
                                <th className="vlpointer p-0"
                                >
                                    <div>
                                        <span>Description</span>
                                    </div>
                                </th>
                                <th className="vlpointer p-0"
                                >
                                    <div>
                                        <span>Taxable Value</span>
                                    </div>
                                </th>
                                <th className="vlpointer p-0"
                                >
                                    <div>
                                        <span>GST</span>
                                    </div>
                                </th>
                                <th className="vlpointer p-0"
                                >
                                    <div>
                                        <span>Total Tax Amount</span>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {record.map((ob, index) => (
                                <>
                                    {index !== record.length - 1 && index !== record.length - 2 &&
                                        <tr className="obj_value text-ctr" key={index}>
                                            {ob.colr.map((objj, indx) => (
                                                <>
                                                    {(indx === 0 || objj.name === "Amount") &&
                                                        <td key={indx} className="pos-rel" >
                                                            <div value={objj.value}>
                                                                {objj.value}
                                                            </div>
                                                        </td>
                                                    }
                                                    {objj.name === "GST Ledger" &&
                                                        <td className="pos-rel" style={{ padding: '0' }}>
                                                            <table className="inner-table">
                                                                {(ob.colr[7].value === "Central Tax" || ob.colr[7].value === "State Tax") &&
                                                                    <>
                                                                        <tr className='fw-bold'>
                                                                            <td style={{ width: '50%' }} className="inner-td">{ob.colr[7].value}</td>
                                                                            <td style={{ width: '20%' }} className="inner-td">Rate</td>
                                                                            <td style={{ width: '30%' }} className="inner-td">Amount</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ width: '50%' }} className="inner-td">CGST</td>
                                                                            <td style={{ width: '20%' }} className="inner-td">{Number(objj.value.replace(/%/g, '')) / 2 + "%"}</td>
                                                                            <td style={{ width: '30%' }} className="inner-td">{Number(ob.colr[8].value) / 2}</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ width: '50%' }} className="inner-td">SGST</td>
                                                                            <td style={{ width: '20%' }} className="inner-td">{Number(objj.value.replace(/%/g, '')) / 2 + "%"}</td>
                                                                            <td style={{ width: '30%' }} className="inner-td">{Number(ob.colr[8].value) / 2}</td>
                                                                        </tr>
                                                                    </>
                                                                }
                                                                {ob.colr[7].value === "Integrated Tax" &&
                                                                    <>
                                                                        <tr className='fw-bold'>
                                                                            <td style={{ width: '50%' }} className="inner-td">{ob.colr[7].value}</td>
                                                                            <td style={{ width: '20%' }} className="inner-td">Rate</td>
                                                                            <td style={{ width: '30%' }} className="inner-td">Amount</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td style={{ width: '50%' }} className="inner-td">IGST</td>
                                                                            <td style={{ width: '20%' }} className="inner-td">{objj.value}</td>
                                                                            <td style={{ width: '30%' }} className="inner-td">{ob.colr[8].value}</td>
                                                                        </tr>
                                                                    </>
                                                                }
                                                            </table>
                                                            {/* <div value={objj.value}>
                                                                {objj.value}
                                                            </div> */}
                                                        </td>}
                                                    {objj.name === "Tax Amount" &&
                                                        <td className="pos-rel" >
                                                            <div value={objj.value}>
                                                                {objj.value}
                                                            </div>
                                                        </td>}
                                                </>
                                            ))}
                                        </tr>
                                    }
                                </>
                            ))}
                            <tr>
                                {record[record.length - 1].colr.map((obj, ind) => (
                                    <td>
                                        <div className="lom_flx fw-bold" key={ind} value={obj.value}>
                                            {obj.value}
                                        </div>
                                    </td>
                                ))}
                            </tr>
                        </tbody>
                    </table>
                </div>

                <Modal show={showModelList} onHide={cancelModelList} size="xl">

                    <Modal.Header closeButton>
                        <Modal.Title>Personalized List Column</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="mod-ht">
                        <ModelList
                            setRef={setRef}
                            columnid={columnId}
                            onCancel={cancelModelList}
                            previousFilter={ref_filter}
                            previousTimeLine={" "}
                            tableName={objLabel}
                            tabName={objName}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={cancelModelList}>
                            Close
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div>
        )
    } else {
        return <></>
    }
}

export default InvoiceCompo