import axios from "axios";
import React, { useContext, useEffect, useState, useRef } from "react";
import { AutoPortalContext } from "../Context";
import "../css/UserPref.css";
import { isMobile, isTablet } from "react-device-detect";
import { tab } from "@testing-library/user-event/dist/tab";

const PrefCompo = ({ handleClose, pref, setRef, colarray, tablename, parentApp }) => {
  const { token, loca } = useContext(AutoPortalContext);
  const firstRender = useRef(false);
  const oneRef = useRef(false);
  // const [tablename, setTableName] = useState("");
  const [columnarray, setColumnArray] = useState(colarray);
  const [disableColArr, setDisableColArr] = useState(false);
  const [disableColArr2, setDisableColArr2] = useState(false);
  const [columnarray2, setColumnarray2] = useState([]);
  const [prefarray, setPrefArray] = useState([]);
  const [json, setJson] = useState({});
  const [col_val, setCol_val] = useState(0);
  // const [pref, setPref] = useState("");
  const ind = useRef(0);
  const index = useRef(0);

  useEffect(() => {
    ind.current = -1
    getPrefRecord();
  }, []);

  useEffect(() => {
    if (firstRender.current && oneRef.current) {
      setColumn();
    } else {
      firstRender.current = true;
      oneRef.current = true;
    }
  }, [prefarray]);

  const getPrefRecord = () => {
    console.log(pref, colarray, parentApp);
    let url = "";
    if (parentApp === "Marketplace") {
      url = "http://localhost:8084/marketplace/get/pref/" + pref + "/" + tablename
    } else {
      url = loca + "/lom/get/pref/" + pref + "/" + tablename;
    }
    axios
      .get(url, {
        headers: {
          authorization: "Bearer " + token,
          "market-application": "marketplace",
        },
      })
      .then((resp) => {
        var sysrecord = resp.data;
        console.log(sysrecord);
        if (pref === "user") {
          console.log(sysrecord);
          setPrefArray([...sysrecord.userPreference[2].column]);

          setJson(sysrecord);
        } else if (pref === "owner") {
          setPrefArray([...sysrecord.ownerPreference[2].column]);
          setJson(sysrecord);
        }
      });
  };

  const setColumn = () => {
    var clm = columnarray;
    var clm2 = columnarray2;
    var prf = prefarray;
    console.log(columnarray);
    for (var ii = 0; ii < clm.length; ii++) {
      clm[ii].check = false;
    }
    for (var i = 0; i < clm.length; i++) {
      for (var p = 0; p < prf.length; p++) {
        if (clm[i].name === prf[p].name) {
          clm[i].check = true;
          var jj = {
            label: clm[i].label,
            name: clm[i].name,
            co: prf[p].co,
          };
          clm2.push(jj);
          // clm.splice(i,1)
        }
      }
    }
    let c = [];
    // if (clm[clm.length-1].label!=="split-end") {
    //     clm.push({label:"split-start",type:"ui",check:"false"})
    //     clm.push({label:"split",type:"ui",check:"false"})
    //     clm.push({label:"split-end",type:"ui",check:"false"})
    // }

    clm2.sort((a, b) => (Number(a.co) > Number(b.co) ? 1 : -1));
    clm.sort((a, b) => (a.label > b.label ? 1 : -1))
    console.log(clm2);
    setColumnArray([...clm]);
    setColumnarray2([...clm2]);
    oneRef.current = false;
  };

  const changeColumn = (e, index) => {
    var col = columnarray;
    col[index].check = e;
    setColumnArray([...col]);
  };

  const setColumnbtn = () => {
    var colm = columnarray;
    var colm2 = columnarray2;
    for (var i = 0; i < colm.length; i++) {
      var dd = false;
      var ind = 0;
      var ee = false;
      for (var c = 0; c < colm2.length; c++) {
        if (colm[i].check === true) {
          if (colm[i].label === colm2[c].label) {
            dd = true;
          }
        } else if (colm[i].check === false) {
          if (colm[i].label === colm2[c].label) {
            ee = true;
            ind = c;
          }
        }
      }
      if (dd === false && colm[i].check === true) {
        var ln = colm2.length;
        ln = ln + 1;
        colm2.push({ label: colm[i].label, co: "" + ln });
      }
      if (ee === true && colm[i].check === false) {
        colm2.splice(ind, 1);
      }
    }
    console.log(colm2);
    setColumnarray2([...colm2]);
  };

  const submitColumnbtn = () => {
    var colm = columnarray;
    var colm2 = columnarray2;
    var sub = [];
    console.log(colm2);

    // // var count = 0;
    // for (var i = 0; i < colm2.length; i++) {
    //   for (var j = 0; j < colm.length; j++) {
    //     if (colm2[i].name === colm[j].name) {
    //       sub.push({ name: colm2[i].name, co: colm2[i].co });
    //     }
    //   }
    // }


    for (var i = 0; i < colm2.length; i++) {
      sub.push({ name: colm2[i].name, co: colm2[i].co });
    }

    console.log(sub);
    if (pref === "user") {
      console.log("kmds;;");
      json.userPreference[2].column = sub;
      json.userPreference[1].table.name = tablename;
      console.log(json);
      console.log("kmds;;");
      let url = "";
      if (parentApp === "Marketplace") {
        url = "http://localhost:8084/marketplace/set/user/preference/";
      } else {
        url = loca + "/lom/set/user/preference/";
      }
      axios
        .post(url, json, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
            "market-application": "marketplace",
          },
        })
        .then((resp) => {
          var sysrecord = resp.data;
          console.log(sysrecord);
          handleClose();
        });
    } else if (pref === "owner") {
      json.ownerPreference[2].column = sub;
      json.ownerPreference[1].table.name = tablename;
      let url = "";
      if (parentApp === "Marketplace") {
        url = "http://localhost:8084/marketplace/set/owner/preference/";
      } else {
        url = loca + "/lom/set/owner/preference/";
      }
      axios
        .post(url, json, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
          },
        })
        .then((resp) => {
          var sysRecord = resp.data;
          console.log("syssssssssss : " + JSON.stringify(sysRecord));
          handleClose();
        });
    }
  };

  const selected = (index, clm) => {
    console.log(index, clm);
    if (clm === "clm1") {
      setDisableColArr(true);
      setDisableColArr2(false);
      for (let i = 0; i < columnarray.length; i++) {
        if (i === index) {
          document
            .getElementById("clm1")
            .children.item(i)
            .classList.add("selected-row");
        } else {
          document
            .getElementById("clm1")
            .children.item(i)
            .classList.remove("selected-row");
        }
      }
      for (let i = 0; i < columnarray2.length; i++) {
        document
          .getElementById("clm2")
          .children.item(i)
          .classList.remove("selected-row");
      }
    } else {
      if (columnarray2.length == 1) {
        setDisableColArr(true);
      } else {
        setDisableColArr(false);
      }
      setDisableColArr2(true);
      ind.current = index;
      for (let i = 0; i < columnarray2.length; i++) {
        if (i === index) {
          document
            .getElementById("clm2")
            .children.item(i)
            .classList.add("selected-row");
        } else {
          document
            .getElementById("clm2")
            .children.item(i)
            .classList.remove("selected-row");
        }
      }
      for (let i = 0; i < columnarray.length; i++) {
        document
          .getElementById("clm1")
          .children.item(i)
          .classList.remove("selected-row");
      }
    }
  };

  const setColumnOrder = (pm) => {
    var clm = [];
    var col_odr = ind.current;
    var col_odr2 = 0;
    console.log(col_odr, columnarray2[col_odr]);

    if (ind.current !== -1) {
      // for down shift
      if (pm) {
        if (col_odr < columnarray2.length - 1) {
          col_odr2 = col_odr + 1;
          for (let i = 0; i < columnarray2.length; i++) {
            if (i !== col_odr2 && i !== col_odr) {
              clm.push(columnarray2[i]);
            } else if (i === col_odr2) {
              document
                .getElementById("clm2")
                .children.item(col_odr2)
                .classList.add("selected-row");
              console.log("col_odr2");
              let cjj = columnarray2[i];
              cjj.co = (col_odr + 1).toString();
              clm.push(cjj);
            } else if (i === col_odr) {
              document
                .getElementById("clm2")
                .children.item(col_odr)
                .classList.remove("selected-row");
              console.log("col_odr");
              let cjj = columnarray2[i];
              cjj.co = (col_odr2 + 1).toString();
              clm.push(cjj);
            }
          }
          clm.sort((a, b) => (Number(a.co) > Number(b.co) ? 1 : -1));
          ind.current = col_odr2;
          console.log(clm);
          setColumnarray2([...clm]);
        }
      } else {
        // for up shift
        if (col_odr > 0) {
          col_odr2 = col_odr - 1;
          for (let i = 0; i < columnarray2.length; i++) {
            if (i !== col_odr2 && i !== col_odr) {
              clm.push(columnarray2[i]);
            } else if (i === col_odr2) {
              document
                .getElementById("clm2")
                .children.item(col_odr2)
                .classList.add("selected-row");
              console.log("col_odr2");
              let cjj = columnarray2[i];
              cjj.co = (col_odr + 1).toString();
              clm.push(cjj);
            } else if (i === col_odr) {
              document
                .getElementById("clm2")
                .children.item(col_odr)
                .classList.remove("selected-row");
              console.log("col_odr");
              let cjj = columnarray2[i];
              cjj.co = (col_odr2 + 1).toString();
              clm.push(cjj);
            }
          }
          clm.sort((a, b) => (Number(a.co) > Number(b.co) ? 1 : -1));
          ind.current = col_odr2;
          console.log(clm);
          setColumnarray2([...clm]);
        }
      }
    }
  };

  const shiftRight = () => {
    let clm = columnarray;
    let clm2 = columnarray2;
    if (document.getElementsByClassName("selected-row").item(0)) {
      let lab = document.getElementsByClassName("selected-row").item(0).innerText;
      console.log(lab);
      for (let i = 0; i < columnarray.length; i++) {
        // console.log(clm[i].label, lab, clm[i].type);
        if (i === index.current) {
          console.log("checkkkkkkk");
          document.getElementsByClassName("selected-row").item(0).classList.remove("selected-row");
          if (clm[i].type !== "ui") {
            clm[i].check = true;
          }
          let n_co = clm2.length + 1;
          let n = { label: lab, name: clm[i].name, co: n_co.toString() };
          clm2.push(n);
        }
      }
      console.log(clm);
      console.log(clm2);
      setDisableColArr(false); console.log(disableColArr);
      setColumnArray([...clm]);
      setColumnarray2([...clm2]);
    }
  };

  const shiftLeft = () => {
    let clm = columnarray;
    let clm2 = columnarray2;
    let name = "";
    if (document.getElementsByClassName("selected-row").item(0)) {
      console.log("shift left : ", document.getElementsByClassName("selected-row").item(0));
      let lab = document.getElementsByClassName("selected-row").item(0).innerText;
      let removedIndex = -1;
      for (let i = 0; i < columnarray2.length; i++) {
        if (i === ind.current) {
          console.log(i);
          name = clm2[i].name
          removedIndex = i;

        }
        if (i > ind.current) {
          console.log(clm2[i].co);
          let newCO = Number(clm2[i].co) - 1
          clm2[i].co = newCO.toString()
        }
      }

      if (removedIndex != -1) {
        clm2.splice(removedIndex, 1);
        document.getElementsByClassName("selected-row").item(0).classList.remove("selected-row");
      }


      for (let i = 0; i < columnarray.length; i++) {
        if (clm[i].name === name) {
          clm[i].check = false;
        }
      }
      console.log(clm2);

      console.log(disableColArr2);


      ind.current = -1
      setDisableColArr2(false);

      setColumnArray([...clm]);
      setColumnarray2([...clm2]);
    }
  };

  // const setColumnOrder = (pm) => {
  //   console.log("tf: " + columnarray2.length);
  //   var clm2 = columnarray2[ind.current].label;
  //   console.log("vl2: " + ind.current);
  //   var col = columnarray2;
  //   var clm = [];
  //   var col_odr = 0;
  //   var col_odr2 = 0;
  //   for (var i = 0; i < col.length; i++) {
  //     if (col[i].label === clm2) {
  //       col_odr = parseInt(col[i].co);
  //     }
  //   }
  //   console.log("col_order" + col_odr);
  //   if (pm) {
  //     col_odr2 = col_odr + 1;
  //     // setCol_val(col_val+1);
  //     ind.current += 1;
  //   } else {
  //     col_odr2 = col_odr - 1;
  //     // setCol_val(col_val-1);
  //     ind.current -= 1;
  //   }
  //   var cll = {};
  //   for (var i = 0; i < col.length; i++) {
  //     if (pm) {
  //       if (i + 1 !== col_odr2 && i + 1 !== col_odr) {
  //         clm.push(col[i]);
  //       } else if (i + 1 === col_odr && i+1<=col.length) {
  //         cll = col[i];
  //       } else if (i + 1 === col_odr2 && i+1<=col.length) {
  //         col[i].co = col_odr.toString();
  //         clm.push(col[i]);
  //         cll.co = col_odr2.toString();
  //         clm.push(cll);
  //       }
  //     } else {
  //       if (i + 1 !== col_odr2 && i + 1 !== col_odr) {
  //         clm.push(col[i]);
  //       } else if (i + 1 === col_odr2) {
  //         cll = col[i];
  //       } else if (i + 1 === col_odr) {
  //         col[i].co = col_odr2.toString();
  //         clm.push(col[i]);
  //         cll.co = col_odr.toString();
  //         clm.push(cll);
  //       }
  //     }
  //   }
  //   console.log("vl1: " + ind.current);
  //   console.log(clm);
  //   setColumnarray2([...clm]);
  // };

  return (
    <div>
      <div style={{ flexGrow: 1 }}>
        <div className="row fr justify-content-center">
          {/* {columnarray2.length === 1 && <h4>minimum 1 column require</h4>} */}
          <div id="clm1" className="col-md-4 box-pref">
            {columnarray.length > 0 &&
              columnarray.map((obj, obj_i) => (
                <div className="columnarray2" key={obj_i}>
                  {!obj.check && (
                    <div className="row fr">
                      <div
                        className="col-md"
                        onClick={() => {
                          selected(obj_i, "clm1");
                          index.current = obj_i
                        }}
                      >
                        {obj.label}
                      </div>
                    </div>
                  )}
                </div>
              ))}
          </div>
          {!(isMobile || isTablet) ? (
            <div className="up-down">
              <div>
                <button
                  className="ryt-left-btn"
                  disabled={disableColArr2}

                  onClick={() => {
                    shiftRight();
                  }}
                >
                  {">"}
                </button>
              </div>
              <div>
                <button
                  className="ryt-left-btn"
                  disabled={disableColArr}

                  onClick={() => {
                    shiftLeft();
                  }}
                >
                  {"<"}
                </button>
              </div>
            </div>
          ) : (
            <div className="up_down d-flex justify-content-center">
              <button
                disabled={disableColArr}
                className="up-down-view-pref"

                onClick={(e) => shiftLeft()}
              >
                {">"}
              </button>
              <button
                disabled={disableColArr2}
                className="up-down-view-pref"

                onClick={(e) => shiftRight()}
              >
                {"<"}
              </button>
            </div>
          )}
          <div id="clm2" className="col-md-4 box-pref">
            {console.log(columnarray2)}
            {columnarray2.map((obj, index) => (
              <p
                style={{ margin: 0 }}
                key={index}
                className="columnarray2"
                onClick={() => {
                  selected(index, "clm2");
                }}
                value={obj.label}
              >
                {obj.label}
              </p>
            ))}
          </div>
          <div className="col-md-2 up_down_btn_pre">
            <div className="btn-group-vertical">
              <button
                className="up-down-view-pref"

                onClick={(e) => setColumnOrder(false)}
              >
                {">"}
              </button>
              <button
                className="up-down-view-pref"

                onClick={(e) => setColumnOrder(true)}
              >
                {"<"}
              </button>
            </div>
            <span style={{ margin: 'auto 22px' }}>
              <button
                style={{
                  fontSize: "15px",
                  // width: "20%",
                  borderRadius: "3px",
                }}
                type="button"
                className=" btnnn btn btn-primary btn-sm"
                onClick={() => submitColumnbtn()}
              >
                Submit
              </button>
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-md"></div>
        </div>
      </div>
    </div>

    // <div className="row" style={{ marginTop: "2rem" }}>
    //   <div className="col-md-2"></div>
    //   <div className="col-md-8">
    //     <div className="row fr">
    //       <div id="clm1" className="col-md-5">
    //         {columnarray.length > 0 &&
    //           columnarray.map((obj, obj_i) => (
    //             <div key={obj_i}>
    //               {!obj.check && (
    //                 <div className="row fr">
    //                   <div
    //                     className="col-md"
    //                     onClick={() => {
    //                       selected(obj_i, "clm1");
    //                       index.current = obj_i
    //                     }}
    //                   >
    //                     {obj.label}
    //                   </div>
    //                 </div>
    //               )}
    //             </div>
    //           ))}
    //       </div>
    //       {!(isMobile || isTablet) ? (
    //         <div className="col-md-1 up-down-dis">
    //           <div>
    //             <button
    //               className="view_btn"
    //               disabled={disableColArr2}
    //               onClick={() => {
    //                 shiftRight();
    //               }}
    //             >
    //               {">"}
    //             </button>
    //           </div>
    //           <div>
    //             <button
    //               className="view_btn"
    //               disabled={disableColArr}
    //               onClick={() => {
    //                 shiftLeft();
    //               }}
    //             >
    //               {"<"}
    //             </button>
    //           </div>
    //         </div>
    //       ) : (
    //         <div className="up_down d-flex justify-content-center">
    //           <button
    //             disabled={disableColArr}
    //             className="up-down-view"
    //             
    //             onClick={(e) => shiftLeft()}
    //           >
    //             {">"}
    //           </button>
    //           <button
    //             disabled={disableColArr2}
    //             className="up-down-view"
    //             
    //             onClick={(e) => shiftRight()}
    //           >
    //             {"<"}
    //           </button>
    //         </div>
    //       )}
    //       <div id="clm2" className="col-md-5">
    //         {console.log(columnarray2)}
    //         {columnarray2.map((obj, index) => (
    //           <p
    //             style={{ margin: 0 }}
    //             key={index}
    //             className="columnarray2"
    //             onClick={() => {
    //               selected(index, "clm2");
    //             }}
    //             value={obj.label}
    //           >
    //             {obj.label}
    //           </p>
    //         ))}
    //       </div>
    //       <div className="col-md-1 up-down-dis">
    //         <div>
    //           <button
    //             className="up-down-view"
    //             
    //             onClick={(e) => setColumnOrder(false)}
    //           >
    //             {">"}
    //           </button>
    //         </div>
    //         <div>
    //           <button
    //             className="up-down-view"
    //             
    //             onClick={(e) => setColumnOrder(true)}
    //           >
    //             {"<"}
    //           </button>
    //         </div>
    //         <button
    //           style={{
    //             fontSize: "15px",
    //             // width: "20%",
    //             borderRadius: "3px",
    //           }}
    //           type="button"
    //           className=" btnnn btn btn-primary"
    //           onClick={() => submitColumnbtn()}
    //         >
    //           Submit
    //         </button>
    //       </div>
    //     </div>
    //   </div>
    //   <div className="col-md-2"></div>
    // </div>
  );
};

export default PrefCompo;
