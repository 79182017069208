import axios from "axios";
import React, {
  Component,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import {
  Link,
  createSearchParams,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AutoPortalContext } from "../Context";
import WorkInProgress from "./WorkInProgress";
import "../css/ListComponent.css";
import { tab } from "@testing-library/user-event/dist/tab";
import NewFilterCompo from "./NewFilterCompo";
import { isMobile } from "react-device-detect";
import { Button, Modal } from "react-bootstrap";
import PrefCompo from "./PrefCompo";
import { toast } from "react-toastify";
import FormComponent from "./FormComponent";

const ModelList = ({
  previousFilter,
  previousTimeLine,
  columnid,
  onCancel,
  tableName,
  tabName,
  setRef,
  isVar,
  tabId,
  i_d,
}) => {
  //  useContext hook

  console.log(columnid, tabName, tableName);
  console.log("runniggg " + JSON.stringify(previousFilter));

  const { token, loca, user, formHeigh, userDetails } = useContext(AutoPortalContext);
  const navigation = useNavigate();
  // useState hooks
  const [name, setName] = useState("");
  const [list, setList] = useState([]);
  const [iList, setIList] = useState(true);
  const [page_clicked, setPage_clicked] = useState(0);
  const [page_count, setPage_count] = useState(0);
  const [showlist, setShowlist] = useState(false);
  const [showbtn, setShowbtn] = useState(false);
  const [col_mn, setCol_mn] = useState([]);
  const [showUpDownbtn, setShowUpDownbtn] = useState(false);
  const [prefCall, setPrefCall] = useState([]);
  const [int_fields, setInt_fields] = useState([]);
  const [date_fields, setDate_fields] = useState([]);
  const [email_fields, setEmail_fields] = useState([]);
  const [boln_fields, setBoln_fields] = useState([]);
  const [str_fields, setStr_fields] = useState([]);
  const [ref_fields, setRef_fields] = useState([]);
  const [booleanfld, setBooleanfld] = useState([]);
  const [colWidth, setColWidth] = useState([]);
  const [filtarray, setFiltarray] = useState([]);
  // flag To detect change in filtarray
  const [filtArrayChange, setFiltArrayChange] = useState(false);
  const [filt, setFilt] = useState({
    co: "",
    cl: "",
    mc: "",
    an: "",
    ct: "",
    af: "",
    rf: { id: "", value: "" },
  });
  const [filter_unset, setFilter_unset] = useState(false);
  const [filString, setFilString] = useState("");
  const [button, setButton] = useState([]);
  const [sortColumn, setSortColumn] = useState({ name: "", sort: false });
  const [listName, setListName] = useState(tableName);
  const [columnList, setColumnList] = useState([]);
  const [srch, setSrch] = useState("");
  const [srch_column, setSrch_column] = useState("name");

  // different loading compo or loading button
  const [showLoading, setShowLoading] = useState(true);
  const [clearButtonLoading, setClearButtonLoading] = useState(false);
  const [runButtonLoading, setRunButtonLoading] = useState(false);

  const [lt, setLt] = useState("<");
  const [gt, setGt] = useState(">");
  console.log(previousFilter);
  const [filter, setFilter] = useState(previousFilter);
  const [timeLine, setTimeLine] = useState(previousTimeLine);
  const [tm_list, setTm_list] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [isClick, setIsClick] = useState(false);
  const [btnNamee, setbtnNamee] = useState("");
  const [url, setUrl] = useState("");
  const [showPref, setShowPref] = useState(false);
  const [pageRecords, setPageRecords] = useState("");
  const [recordCount, setRecordCount] = useState();
  const [mainrecord, setMainRecord] = useState({});
  const [page_error, setPage_Error] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [pageMessage, setPageMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [record, setRecord] = useState([]);

  // useRef hook
  const firstRenderForiListChange = useRef(false);
  const firstRenderForFiltArrayChange = useRef(false);

  const ScorllViewRef = useRef([]);
  let pickerWidth = useRef();
  let pickerWidthForCT = useRef();
  let mySelect = useRef([]);

  //   set list intially

  const initialList = () => {
    // filtarray.push(filt);
    let f = [];
    let timeLineList = [];
    let strFields = ["", "=", "starts with"];
    let intField = ["", ">", "="];
    let bolnField = ["", "=", "starts with"];
    let emailField = ["", "=", "starts with"];
    let dateField = ["", "8-11-21", "new date"];
    let booleanField = ["", "true", "false"];
    ref_fields.push("");
    ref_fields.push("=");
    ref_fields.push("starts with");
    timeLineList.push({ name: "", label: "None" });
    timeLineList.push({ name: "current24", label: "Current 24" });
    timeLineList.push({ name: "previous24", label: "Previous 24" });
    timeLineList.push({ name: "week", label: "Week" });
    timeLineList.push({ name: "last14days", label: "Last 14 Days" });
    timeLineList.push({ name: "last30", label: "Last 30" });
    timeLineList.push({ name: "last6month", label: "Last 6 Month" });
    timeLineList.push({ name: "last1year", label: "Last 1 Year" });
    f.push(filt);
    setFiltarray([...f]);
    setTm_list(timeLineList);
    setStr_fields(strFields);
    setInt_fields(intField);
    setBoln_fields(bolnField);
    setEmail_fields(emailField);
    setBooleanfld(booleanField);
    setDate_fields(dateField);
    console.log("filterrr : " + JSON.stringify(filter));
    if (
      filter.toString() === "" ||
      filter.toString() === "undefined" ||
      filter.toString() === undefined ||
      filter.toString() === "null" ||
      filter.toString() === null
    ) {
      var farray = [];
      farray.push(filt);
      console.log("llllll" + JSON.stringify(farray));
      setFilter([...farray]);
      setIList(!iList);
    } else {
      var farray = [];

      console.log(";;:::::" + JSON.stringify(filter));

      farray = filter.filter;
      console.log(farray);
      setFilter([...farray]);
      setIList(!iList);
    }
  };

  const getList = (tablere, isPref) => {
    // let token = await AsyncStorage.getItem("token");
    console.log("tablere : " + tablere, isPref, name);

    let url = "";
    if (isVar) {
      url = loca + `/get/multiplerecord/ref`;
    } else if (isPref) {
      url = loca + `/lom/get/multiple/record`;
    } else {
      url = loca + `/lom/get/multiplerecord/col`;
    }
    console.log("url : " + url, tablere.toString());
    // functions for calling server

    axios
      .post(url, tablere.toString(), {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          const listrecord = resp.data;
          console.log(listrecord);
          // console.log("listRecord : " + JSON.stringify(listrecord.formRecordList[1].table.name));
          var columnarry = [];
          var hd = [];
          var va_ll = [];
          if ("Error" in listrecord) {
            console.log("Error");
            setRunButtonLoading(false);
            toast.error(listrecord.Error);
          } else {
            var scnd = listrecord.formRecordList[2];
            if ("Error" in scnd) {
              if (scnd.Error === "No record found.") {
                // setState({ list: [] });
                setList([]);
              }
            } else {
              var page_clicked1 = parseInt(
                listrecord.formRecordList[3].page.page_clicked
              );
              var page_count1 = parseInt(
                listrecord.formRecordList[3].page.page_count
              );
              var page_records1 =
                listrecord.formRecordList[3].page.page_records;
              var record_count1 = parseInt(
                listrecord.formRecordList[3].page.record_count
              );

              var flt = listrecord.formRecordList[5];
              var fltarr = listrecord.formRecordList[5].filter;
              var tmln = listrecord.formRecordList[6].timeLine;
              var filterString = "";
              var leng = fltarr.length;
              console.log("kkk" + JSON.stringify(fltarr), leng);

              for (var f = 0; f < fltarr.length; f++) {
                console.log(fltarr[f]);
                if (leng === 1 && fltarr[f].co === "") {
                  break;
                } else {
                  if (f > 0) {
                    filterString += " " + fltarr[f].af + " ";
                  }
                  filterString += fltarr[f].cl + " ";
                  if (fltarr[f].mc === "is") {
                    filterString += "= ";
                  } else {
                    filterString += fltarr[f].mc + " ";
                  }

                  if (
                    fltarr[f].ct === "reference" ||
                    fltarr[f].ct === "multi_select"
                  ) {
                    filterString += fltarr[f].rf.value + " ";
                  } else {
                    filterString += fltarr[f].an + " ";
                  }
                }
              }

              for (
                var i = 0;
                i < listrecord.formRecordList[2].records.length;
                i++
              ) {
                var in_vl = [];
                var record_id;
                var ref_value;
                for (
                  var j = 0;
                  j < listrecord.formRecordList[2].records[i].record.length;
                  j++
                ) {
                  if (i === 0) {
                    if (j === 0) {
                    } else {
                      // hd.push({
                      //   label:
                      //     listrecord.formRecordList[2].records[i].record[j]
                      //       .label,
                      //   name: listrecord.formRecordList[2].records[i].record[j]
                      //     .name,
                      // });
                    }
                  }
                  if (j === 0) {
                    record_id =
                      listrecord.formRecordList[2].records[i].record[j].value;
                    if (
                      listrecord.formRecordList[2].records[i].record[j]
                        .refDisplay.type === "reference"
                    ) {
                      if (
                        listrecord.formRecordList[2].records[i].record[j]
                          .refDisplay.value.value
                      ) {
                        ref_value =
                          listrecord.formRecordList[2].records[i].record[j]
                            .refDisplay.value.value;
                      } else {
                        ref_value =
                          listrecord.formRecordList[2].records[i].record[j]
                            .refDisplay.value.name;
                      }
                    } else {
                      ref_value =
                        listrecord.formRecordList[2].records[i].record[j]
                          .refDisplay.value;
                    }
                  } else if (j === 1) {
                    if (
                      listrecord.formRecordList[2].records[i].record[j].type ===
                      "reference"
                    ) {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: true,
                        table: listrecord.formRecordList[1].table.name,
                        r_id: record_id,
                        ref_value: ref_value,
                      });
                    } else {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: true,
                        table: listrecord.formRecordList[1].table.name,
                        r_id: record_id,
                        ref_value: ref_value,
                      });
                    }
                  } else {
                    if (
                      listrecord.formRecordList[2].records[i].record[j].type ===
                      "reference"
                    ) {
                      //  console.log(
                      //     JSON.stringify(
                      //       listrecord.formRecordList[2].records[i].record[j]
                      //     ))
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: false,
                        table: listrecord.formRecordList[1].table.name,
                        r_id: record_id,
                      });
                    } else {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: false,
                        table: listrecord.formRecordList[1].table.name,
                        r_id: record_id,
                      });
                    }
                  }
                }
                in_vl.splice(0, 0, { ref: false });
                va_ll.push({ colr: in_vl });
              }
              let col_list = [];
              for (
                var c = 0;
                c < listrecord.formRecordList[9].column.length;
                c++
              ) {
                if (listrecord.formRecordList[9].column[c].type === "String") {
                  col_list.push({
                    label: listrecord.formRecordList[9].column[c].label,
                    name: listrecord.formRecordList[9].column[c].name,
                    type: listrecord.formRecordList[9].column[c].type,
                  });
                }
              }
              for (
                var p = 0;
                p < listrecord.formRecordList[10].preference.length;
                p++
              ) {
                hd.push({
                  label: listrecord.formRecordList[10].preference[p].label,
                  name: listrecord.formRecordList[10].preference[p].name,
                });
              }
              hd.splice(0, 0, { ref: false });
              columnarry.push({ heading: hd, rcd: va_ll });
              let column = listrecord.formRecordList[9].column;
              console.log(column);
              if ("Error" in column) {
                setPage_Error(true);
                setError(column.Error);
                setLoading(false);
                console.log(column.Error);
                toast(column.Error, {
                  type: "error",
                  position: "top-center",
                });
              } else {
                console.log("check  : " + columnarry, fltarr);
                var cla = listrecord.formRecordList[9].column;
                cla.unshift({ label: "None", name: "none" });

                // // setting col width

                // for (let i = 0; i < columnarry.length; i++) {
                //   for (let j = 0; j < columnarry[i].heading.length; j++) {
                //     let hd_val = columnarry[i].heading[j].label.length;
                //     let lab_val = columnarry[i].heading[j].label;
                //     if (hd_val < 4) {
                //       colWidth.push({ index: j, value: 50, label: lab_val });
                //     } else {
                //       if (hd_val < 8) {
                //         colWidth.push({ index: j, value: 100, label: lab_val });
                //       } else {
                //         if (hd_val < 14) {
                //           colWidth.push({ index: j, value: 150, label: lab_val });
                //         } else {
                //           colWidth.push({ index: j, value: 200, label: lab_val });
                //         }
                //       }
                //     }
                //   }
                //   for (let k = 0; k < columnarry[i].rcd.length; k++) {
                //     for (
                //       let kk = 0;
                //       kk < columnarry[i].rcd[k].colr.length;
                //       kk++
                //     ) {
                //       let colr_val = columnarry[i].rcd[k].colr[kk].value.length;
                //       let hd_val = columnarry[i].heading[kk].label.length;

                //       if (colr_val < 4) {
                //         if (hd_val < colr_val) {
                //           // colWidth.push({index:kk ,value:50})
                //           colWidth[kk].value = 50;
                //         }
                //       } else {
                //         if (colr_val < 8) {
                //           if (hd_val < colr_val) {
                //             // colWidth.push({index:kk ,value:100})
                //             colWidth[kk].value = 100;
                //           }
                //         } else {
                //           if (colr_val < 14) {
                //             if (hd_val < colr_val) {
                //               // colWidth.push({index:kk ,value:150})
                //               colWidth[kk].value = 150;
                //             }
                //           } else {
                //             if (hd_val < colr_val) {
                //               // colWidth.push({index:kk ,value:200})
                //               colWidth[kk].value = 200;
                //             }
                //           }
                //         }
                //       }
                //     }
                //   }
                // }

                console.log(columnarry);
                setFiltarray(fltarr);

                setMainRecord(listrecord);
                setList(columnarry);
                setName(listrecord.formRecordList[1].table.name);
                setListName(listrecord.formRecordList[1].table.label);
                setPage_clicked(page_clicked1);
                setPage_count(page_count1);
                setPageRecords(page_records1);
                setRecordCount(record_count1);
                setCol_mn(cla);
                setPrefCall(column);
                setColumnList(col_list);
                setFilter(fltarr);
                setTimeLine(tmln);
                console.log("filterString" + filterString);
                setFilString(filterString);
                {
                  console.log(listrecord.formRecordList[11].button);
                }
                setButton(listrecord.formRecordList[11].button);
                // console.log("fltfltfltflt: " + JSON.stringify(fltarr));
                setClearButtonLoading(false);
                setRunButtonLoading(false);
                setShowLoading(false);
              }
            }
          }
        },
        (error) => {
          navigation("/error");
          console.log(error);
        }
      );
  };

  const changeSearch = (val) => {
    if (val === "") {
      // this.setState({ srch: "" });
      console.log("val +");
      setSrch("");
      filterClear();
    } else {
      console.log(val);
      // this.setState({ srch: val });
      setSrch(val);
    }
  };

  const callSearchbtn = () => {
    // var fld = this.state.srch_column;
    // var val = this.state.srch;
    // var col = this.state.columnList;
    if (srch === "") {
      return;
    }
    var type = "";
    var label = "";
    for (var c = 0; c < columnList.length; c++) {
      if (srch_column === columnList[c].name) {
        type = columnList[c].type;
        label = columnList[c].label;
      }
    }
    let filte = [
      {
        ct: type,
        af: "",
        mc: "contains",
        cl: label,
        co: srch_column,
        an: srch,
        rf: { id: "", value: "" },
      },
    ];
    var fs = '{"formRecordList":[';
    fs += '{"application":{"id":"","name":"autoportal"}}';
    fs += ',{"table":{"id":"' + columnid + '","name":""}}';
    fs += ',{"records":[]}';
    fs +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    fs += ',{"sort":{"asc":"true","column":"id" ,"init":"false"}}';
    fs += ',{"filter":' + JSON.stringify(filte) + "}";
    fs += ',{"timeLine":"' + timeLine + '"}]}';
    setFiltarray([...filte]);
    getList(fs, false);
  };

  const searchColumn = (e) => {
    // this.setState({ srch_column: e.target.value });
    setSrch_column(e.target.value);
  };

  // Input Field onchange() functions

  const changecolumn = (e, index) => {
    const vl = e;
    var farray = filtarray;
    // console.log("col_mn.length   " + JSON.stringify(col_mn));
    for (var i = 0; i < col_mn.length; i++) {
      if (col_mn[i].name === vl) {
        farray[index].ct = col_mn[i].type;
        farray[index].cl = col_mn[i].label;
        farray[index].co = col_mn[i].name.toString();
        break;
      }
    }
    // setState({ filtarray: farray });
    console.log("farray  " + JSON.stringify(farray));

    setFiltarray([...farray]);
  };

  const changemiddle = (e, index) => {
    // console.log("changeeeeeeeeee" + e);
    var farray = filtarray;
    if (e !== undefined) {
      farray[index].mc = e;
    }
    // setState({ filtarray: farray });
    setFiltarray([...farray]);
  };

  const changelast = (e, index) => {
    var farray = filtarray;
    if (e !== undefined) {
      farray[index].an = e;
    }
    // setState({ filtarray: farray });
    setFiltarray([...farray]);
  };

  const cancelfilt = (i) => {
    ScorllViewRef.current[i]?.scrollTo({ x: 0, animated: true });

    filtarray.splice(i, 1);
    if (filtarray.length === 0) {
      filtarray.push({
        co: "",
        cl: "",
        mc: "",
        an: "",
        af: "",
      });
    }

    if (filtarray.length === 1) {
      filtarray[0].af = "";
    }
    // setState({ filtarray: filtarray });
    setFiltarray([...filtarray]);
  };

  const filterset = () => {
    for (var i = 0; i < filtarray.length; i++) {
      console.log("filterarrayyyyyy" + JSON.stringify(filtarray[i]));
      if (filtarray[i].an === "" || filtarray[i].mc === "") {
        return false;
      }
    }
    return true;
  };

  const addbtn = (index) => {
    if (filterset()) {
      filtarray.push({
        co: "",
        cl: "",
        mc: "",
        an: "",
        ct: "",
        af: "AND",
      });
      // setState({ filtarray: filtarray, filter_unset: false });
      setFiltarray([...filtarray]);
      setFilter_unset(false);
      ScorllViewRef.current[index]?.scrollTo({ x: 0, animated: true });
    } else {
      // setState({ filter_unset: true });
      setFilter_unset(true);
    }
  };

  const orbtn = (index) => {
    if (filterset()) {
      var len = filtarray.length;
      filtarray.push({
        co: "",
        cl: "",
        mc: "",
        an: "",
        af: "OR",
      });
      // setState({ filtarray: filtarray, filter_unset: false });
      setFiltarray([...filtarray]);
      setFilter_unset(false);
      ScorllViewRef.current[index]?.scrollTo({ x: 0, animated: true });
    } else {
      // setState({ filter_unset: true });
      setFilter_unset(true);
    }
  };

  // filter function

  const filterSubmit = () => {
    setRunButtonLoading(true);
    console.log(filtarray);
    var fs = '{"formRecordList":[';
    fs += '{"application":{"id":"","name":"autoportal"}}';
    fs += ',{"table":{"id":"' + columnid + '","name":""}}';
    fs += ',{"records":[]}';
    fs +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    fs += ',{"sort":{"asc":"true","column":"id","init":"false"}}';
    fs += ',{"filter":' + JSON.stringify(filtarray) + "}";
    fs += ',{"timeLine":"' + timeLine + '"}]}';

    let len = filtarray.length;
    if (
      filtarray[len - 1].an !== "" ||
      filtarray[len - 1].rf.value !== "" ||
      filtarray[len - 1].dc.value !== ""
    ) {
      getList(fs, false);
    }
  };

  const filterClear = () => {
    console.log(name);
    var fltarray = [];
    let flt = {
      co: "",
      cl: "",
      mc: "",
      an: "",
      ct: "",
      af: "",
      rf: { id: "", value: "" },
    };
    fltarray.push(flt);
    console.log(JSON.stringify(fltarray));
    setFiltarray(fltarray);
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"autoportal"}}';
    pp += ',{"table":{"id":"","name":"' + name + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(fltarray) + "}";
    pp += ',{"timeLine":"' + timeLine + '"}]}';

    getList(pp, true);

    // setClearButtonLoading(true);
    // var fltarray = [];
    // fltarray.push(filt);
    // setState({ filtarray: fltarray });
    // setFiltarray(pp);
    // setFiltArrayChange(!filtArrayChange);
  };

  // sorting function

  const showupdownbtn = (hd) => {
    var sarray = sortColumn;
    var clmn = "";
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"autoportal"}}';
    pp += ',{"table":{"id":"' + columnid + '","name":""}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';

    if (sarray.name === hd) {
      sarray.sort = !sarray.sort;
      for (var l = 0; l < list.length; l++) {
        for (var ll = 0; ll < list[l].heading.length; ll++) {
          if (list[l].heading[ll].label === hd) {
            clmn = list[l].heading[ll].name;
            break;
          }
        }
      }

      if (sarray.sort === true) {
        pp += ',{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
      } else {
        pp +=
          ',{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
      }
    } else {
      sarray.name = hd;
      sarray.sort = true;
      for (var li = 0; li < list.length; li++) {
        for (var lll = 0; lll < list[li].heading.length; lll++) {
          if (list[li].heading[lll].label === hd) {
            clmn = list[li].heading[lll].name;
            break;
          }
        }
      }
      if (sarray.sort === true) {
        pp += ',{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
      } else {
        pp +=
          ',{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
      }
    }
    // setState({ sortColumn: sarray });
    setSortColumn(sarray);
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeLine + '"}]}';

    getList(pp, false);
  };

  const setSort = () => {
    var clmn = "";
    var pp = "";
    var srt = sortColumn.name;
    if (srt !== "") {
      for (var l = 0; l < list.length; l++) {
        for (var ll = 0; ll < list[l].heading.length; ll++) {
          if (list[l].heading[ll].label === sortColumn.name) {
            clmn = list[l].heading[ll].name;
            break;
          }
        }
      }
      if (sortColumn.sort === true) {
        pp += ',{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
      } else {
        pp +=
          ',{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
      }
      return pp;
    } else {
      pp +=
        ',{"sort":{"asc":"true","column":"' +
        list[0].heading[0].name +
        '","init":"false"}}';
    }
    return pp;
  };

  // pagination functions

  const leftReadOnly = () => {
    if (page_clicked === 1) {
      return true;
    } else {
      return false;
    }
  };

  const rightReadOnly = () => {
    if (
      page_clicked === page_count ||
      (page_clicked + 1 === page_count && page_clicked !== 1)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const previousPage = () => {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"autoportal"}}';
    pp += ',{"table":{"id":"' + columnid + '","name":""}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      (page_clicked - 1) +
      '","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeLine + '"}]}';

    getList(pp, false);
  };

  const nextPage = () => {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"autoportal"}}';
    pp += ',{"table":{"id":"' + columnid + '","name":""}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      (page_clicked + 1) +
      '","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeLine + '"}]}';

    getList(pp, false);
  };

  const firstPage = () => {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"autoportal"}}';
    pp += ',{"table":{"id":"' + columnid + '","name":""}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeLine + '"}]}';

    getList(pp, false);
  };

  const lastPage = () => {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"autoportal"}}';
    pp += ',{"table":{"id":"' + columnid + '","name":""}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      (recordCount % 50 == 0 ? page_count - 1 : page_count) +
      '","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeLine + '"}]}';

    getList(pp, false);
  };

  const filterItem = () => {
    // this.setState({ showlist: !showlist });
    // this.setState({ showbtn: !showbtn });
    setShowlist(!showlist);
    setShowbtn(!showbtn);
  };
  const callform = (nam, r_id, refDisplay, rowIndex, oj, refTable) => {
    console.log("clickkkk  " + nam, r_id, refDisplay, rowIndex, refTable);
    let properties;
    if (nam === "first") {
      console.log(rowIndex, list);
      console.log(list[0].rcd[rowIndex].colr);
      var colr = list[0].rcd[rowIndex].colr;
      for (let i = 0; i < colr.length; i++) {
        if (
          colr[i].type === "group_key_value" ||
          colr[i].type === "key_value"
        ) {
          properties = colr[i].value;
          break;
        }
      }

      setRef(refDisplay, r_id, properties, refTable);
    } else if (nam === "second") {
    }
    console.log(properties);
  };

  const callNewForm = (nam, tab, r_id) => {
    console.log("tab : " + tab);
    console.log(nam);
    if (nam === "first") {
      navigation(user + "/form", {
        state: { rty: "record", tableName: tab, rid: r_id },
      });
      onCancel();
      // showFormCompo(tab, r_id, "record");
    } else if (nam === "second") {
    } else if (nam === "new") {
      navigation("/form", { state: { rty: "new", tableName: tab, rid: 0 } });
      // showFormCompo(tab, 0, "new");
      onCancel();
    }
  };

  // prefrence compo functions

  const cancelModal = (val) => {
    // console.log("llllllllll" + val);
    setShowModal(val);
  };

  const submitModal = (val) => {
    console.log("zakssssssssss");
    setShowLoading(true);
    initialList();
    cancelModal(val);
  };

  const getChoiceRcd = async (col_id) => {
    let chk = [];
    let ck = await axios
      .get(loca + "/lom/get/choice/" + col_id, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let res = resp.data;
          console.log(res);
          let chk = res.choiceRecords;
          return chk;
          // console.log(rcd);
        },
        (error) => {
          navigation("/error");
          console.log(error);
        }
      );
    return ck;
  };

  const callfilter = async (farray, in_index, col_id) => {
    console.log(farray);
    if (col_id !== -1) {
      // for (let i = 0; i < rcd.length; i++) {
      //   console.log(index+" index "+i+" rcddddddd "+JSON.stringify(rcd[i]));
      if (
        farray[in_index].ct === "choice" &&
        farray[in_index].ch.length === 0
      ) {
        let chc = await getChoiceRcd(col_id).then((res) => {
          if (res.length > 0) {
            farray[in_index].ch = res;
            farray[in_index].an = res[0].name;
          } else {
            farray[in_index].ch = [];
          }
        });
        //  console.log(chcRcd.current);
      }
      // }
    }
    setFiltarray([...farray]);
  };

  const calltimeline = (timeline) => {
    console.log(timeline);
    setTimeLine(timeline);
  };

  // useEffect hooks

  useEffect(() => {
    console.log("filternewwwww");
  }, []);

  useEffect(() => {
    if (firstRenderForiListChange.current) {
      console.log(previousFilter);
      console.log(filter);
      console.log("check1234556", JSON.stringify(filter));
      var tablere = '{"formRecordList":[';
      tablere += '{"application":{"id":"","name":"autoportal"}}';
      tablere += ',{"table":{"id":"' + columnid + '","name":""}}';
      tablere += ',{"records":[]}';
      tablere +=
        ',{"page":{"record_count":"0","page_count":"1",' +
        '"page_clicked":"1","page_records":"0"}}';
      tablere += ',{"sort":{"asc":"true","column":"id","init":"true"}}';
      tablere += ',{"filter":' + JSON.stringify(filter) + "}";
      tablere += ',{"timeLine":"' + timeLine + '"}]}';

      // console.log("checkssssssssssssssssssssssssssssss" + tablere);
      getList(tablere, false);
      // firstRenderForiListChange.current = false;
    } else {
      // if (filter != "null") {
      firstRenderForiListChange.current = true;
      // }
      initialList();
    }
    // console.log("initialfilter :" + firstRenderForiListChange.current);
  }, [iList]);


  const setDefaultOption = () => {
    const selectElement = mySelect.current;
    selectElement.selectedIndex = 0;
  };

  const selectedAction = (url) => {
    if (list[0].rcd.length > 0) {
      if (url === "/lom/delete/record") {
        setbtnNamee("Delete");
        setModal(true);
        setUrl(url);
      } else if (url === "/lom/accept") {
        setbtnNamee("Accept");
        setModal(true);
        setUrl(url);
      } else {
        selectAction(url);
      }
    }
  };

  const selectAction = (url) => {
    setIsClick(true);
    let rcd = list[0].rcd;
    let postBody = [];
    for (let i = 0; i < rcd.length; i++) {
      if (rcd[i].colr[0].ref) {
        // rcd[i].colr.splice(0,1)
        postBody.push(
          rcd[i].colr[1].r_id
          // table: rcd[i].colr[1].table
        );
        continue;
      }
    }
    var jso = {
      url: url,
      table: rcd[0].colr[1].table,
      record: postBody,
    };
    // setLoading(true);
    axios
      .post(loca + "/lom/selectedAction", jso, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          const record = resp.data;
          if (record.Error) {
            toast(record.Error, {
              position: "top-center",
              theme: "colored",
              type: "error",
              style: {
                marginBottom: userDetails.OS !== "null" ? 12 : 0,
              },
            });
          } else {
            if (record.message) {
              toast(record.message, {
                position: "top-center",
                theme: "colored",
                type: "success",
                style: {
                  marginBottom: userDetails.OS !== "null" ? 12 : 0,
                },
              });
            } else {
              toast(record, {
                position: "top-center",
                theme: "colored",
                type: "success",
                style: {
                  marginBottom: userDetails.OS !== "null" ? 12 : 0,
                },
              });
            }
            // refresh();
            // setLoading(false);
          }
        },
        (error) => {
          setLoading(false);
          setModal(false);
          toast("Something went wrong", {
            position: "top-center",
            theme: "colored",
            type: "error",
          });
        }
      )
      .finally(() => {
        setModal(false);
        setIsClick(false);
      });

    if (url === "1") {
    }
  };

  const callbtn = (nam) => {
    var btn = button;
    var mnrecord = mainrecord;

    for (var ij = 0; ij < btn.length; ij++) {
      if (btn[ij].value === nam) {
        console.log(JSON.stringify(btn[ij]));
        var btntype = btn[ij].buttonWebType;
        var nextPage = btn[ij].returnWebLocation;
        var callType = btn[ij].call_type;

        // var token = localStorage.getItem("token");
        // setState({
        //   page_error: false,
        //   error: "",
        //   loading: true,
        //   page_message: false,
        //   message: "",
        // });
        setPage_Error(false);
        setError("");
        setLoading(true);
        setPageMessage(false);
        setMessage("");
        if (callType === "selection") {
          var returnType = btn[ij].recordReturnType;
          console.log(returnType);
          navigation("/role-selection", {
            state: { i_d: i_d, rt: returnType },
          });
        } else if (callType === "module") {
          console.log(btn[ij].script);
          // onCancel(false);
          var scpt = btn[ij].script;
          var f = new Function(
            ["navigation", "createSearchParams", "user"],
            scpt
          );
          f(navigation, createSearchParams, user);
          onCancel();
        } else if (nextPage === "NextPage") {
          console.log("next pageeee" + tab);
          callNewForm("new", name, 0);
        } else {
          mnrecord.formRecordList[2].records = record;
          axios
            .post(loca + btn[ij].webUrl, mnrecord, {
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + token,
              },
            })
            .then((resp) => {
              const rcd = resp.data;
              setLoading(false);
              var msg = rcd.formRecord[4].message;
              if (msg !== "") {
                // setState({ page_message: true, message: msg });
                setPageMessage(true);
                setMessage(msg);
              }
              // if (btntype === "NextPage") {
              // // var nextP = btn[ij].nextPage;
              // navigation("/form",{state:{ rty: "new", tableName: listName, rid: "0" },})
              // if (nextPage === "List") {
              //   // callNextPage();
              // }
              // }
            });
        }

        // } else {
        // 	setState({
        // 		page_error: true,
        // 		error: error_String,
        // 	});
        // 	document.body.scrollTop = 0;
        // 	document.documentElement.scrollTop = 0;
        // 	// props.unmountMe();
        // }
      }
    }
  };

  // useEffect(() => {
  //   if (firstRenderForFiltArrayChange.current) {
  //     console.log("filtttttttttttttttttttt" + JSON.stringify(filtarray));
  //     var pp = '{"formRecordList":[';
  //     pp += '{"application":{"id":"","value":"loom"}}';
  //     pp += ',{"table":{"id":"' + listName + '","value":""}}';
  //     pp += ',{"records":[]}';
  //     pp +=
  //       ',{"page":{"record_count":"0","page_count":"1",' +
  //       '"page_clicked":"1","page_records":"0"}}';
  //     pp += setSort();
  //     pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
  //     pp += ',{"timeLine":"' + timeLine + '"}]}';
  //     getList(pp);
  //   } else {
  //     firstRenderForFiltArrayChange.current = true;
  //   }
  // }, [filtArrayChange]);

  //selection functions for picker based on data type

  let strlist = () => {
    console.log("str" + JSON.stringify(str_fields));

    if (str_fields.length > 0) {
      return str_fields.map((item, st_i) => (
        <option key={st_i} value={item} label={item} />
      ));
    }
  };

  let intlist = () => {
    // console.log("str");

    if (int_fields.length > 0) {
      return int_fields.map((item, i_i) => (
        <option key={i_i} value={item} label={item} />
      ));
    }
  };

  let bolnlist = () => {
    console.log("str");

    if (boln_fields.length > 0) {
      return boln_fields.map((item, b_i) => (
        <option key={b_i} value={item} label={item} />
      ));
    }
  };

  let booleanfd = () => {
    if (booleanfld.length > 0) {
      return booleanfld.map((item, bf_i) => (
        <option key={bf_i} value={item} label={item} />
      ));
    }
  };

  let reflist = () => {
    if (ref_fields.length > 0) {
      return ref_fields.map((item, bf_i) => (
        <option key={bf_i} value={item} label={item} />
      ));
    }
  };

  const handleShow = () => {
    console.log("pref check");
    setShowPref(true);
  };

  const handleClose = () => {
    setShowPref(false);
  };

  const handleCloseRefresh = () => {
    handleClose();
    var tablere = '{"formRecordList":[';
    tablere += '{"application":{"id":"","name":"autoportal"}}';
    tablere += ',{"table":{"id":"","name":"' + name + '"}}';
    tablere += ',{"records":[]}';
    tablere +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    tablere += ',{"sort":{"asc":"true","column":"id","init":"true"}}';
    tablere += ',{"filter":' + JSON.stringify(filtarray) + "}";
    tablere += ',{"timeLine":"' + timeLine + '"}]}';
    console.log(name, filtarray, timeLine, tablere);
    getList(tablere, true);
  };

  const selectedRows = (checked, index) => {
    list[0].rcd[index].colr[0].ref = checked;
    if (!checked) {
      list[0].heading[0].ref = false;
    }
    setList([...list]);
  };

  const selectedAllRows = (checked) => {
    let rcd = list[0].rcd;
    console.log(list);

    if (checked) {
      list[0].heading[0].ref = true;
      for (let i = 0; i < rcd.length; i++) {
        list[0].rcd[i].colr[0].ref = true;
      }
      setList([...list]);
    } else {
      list[0].heading[0].ref = false;
      for (let i = 0; i < rcd.length; i++) {
        list[0].rcd[i].colr[0].ref = false;
      }
      setList([...list]);
    }
  };

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      callSearchbtn();
      console.log("Enter key pressed!");
    }
  };

  return (
    <div className="pagesetup">
      <Modal
        show={modal}
        onHide={() => {
          setModal(!modal);
          setDefaultOption();
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm {btnNamee}</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setModal(!modal);
              setDefaultOption();
            }}
            disabled={isClick}
            style={{ backgroundColor: isClick ? "gray" : "" }}
          >
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={() => selectAction(url)}
            disabled={isClick}
            style={{ backgroundColor: isClick ? "gray" : "" }}
          >
            {btnNamee}
          </Button>
        </Modal.Footer>
      </Modal>
      {showLoading === true ? (
        <WorkInProgress></WorkInProgress>
      ) : (
        <div>
          {isMobile === true ? (
            <div className="row bck bck-rel">
              <div className="col-lg mid">
                <span className="obj_head ">{listName}</span>
              </div>
              <div className=" filter-ic">
                {showlist === false && (
                  <select
                    className="form-select namelist-mob"
                    aria-label="Default"
                    value={srch_column}
                    onChange={(e) => searchColumn(e)}
                  >
                    {columnList.map((obj2, index) => (
                      <option key={index} value={obj2.name}>
                        {obj2.label}
                      </option>
                    ))}
                  </select>
                )}

                {showlist === false && (
                  <input
                    className="srch-mob form-control"
                    type="search"
                    aria-label="Search"
                    value={srch}
                    onChange={(e) => changeSearch(e.target.value)}
                  ></input>
                )}

                {showlist === false && (
                  <input
                    className="csm_btn csm_btn_pri col-md-2 sub-btn"
                    type="button"
                    value="Search"
                    onClick={() => callSearchbtn()}
                  ></input>
                )}
              </div>
              <div className=" martop10 disfl filter-ic">
                <i
                  className="fa fa-filter vlpointer pdtop5"
                  onClick={filterItem}
                ></i>
                <i
                  className="fa fa-cog vlpointer cog_pd pdtop5"
                  onClick={handleShow}
                ></i>
                <div className="text-end">
                  <span style={{ marginLeft: "0.5em" }}>
                    <input
                      className=" mybt"
                      type="button"
                      value="<<"
                      onClick={firstPage}
                      disabled={leftReadOnly()}
                    />
                    <input
                      className=" mybt"
                      type="button"
                      value="<"
                      onClick={previousPage}
                      disabled={leftReadOnly()}
                    />
                  </span>
                  <span>
                    <span className="in-put">{pageRecords}</span>
                  </span>
                  <span className="text-uppercase spfont"> of </span>
                  <span className="rcd_count spfont">{recordCount}</span>
                  <span>
                    <input
                      className=" mybt"
                      type="button"
                      value=">"
                      onClick={nextPage}
                      disabled={rightReadOnly()}
                    />
                  </span>
                  <span>
                    <input
                      className=" mybt"
                      type="button"
                      value=">>"
                      disabled={rightReadOnly()}
                      onClick={lastPage}
                    />
                  </span>
                </div>
              </div>
              <div className="d-flex flex-row align-items-center  col-lg martop10">
                {button.length > 0 &&
                  button.map((obj, oo_i) => (
                    <div>
                      {console.log(obj)}
                      {obj.show_type === "modelList" && (
                        <button
                          // type="button"
                          className="csm_btn csm_btn_pri col-md-2 sub-btn"
                          key={oo_i}
                          onClick={(e) => callbtn(obj.value)}
                        >
                          {obj.name}
                        </button>
                      )}
                    </div>
                  ))}
              </div>
              <div className="col-lg martop10">
                {showlist === true && (
                  <input
                    className="csm_btn csm_btn_pri col-md-2 sub-btn"
                    type="button"
                    value="Run"
                    onClick={filterSubmit}
                  ></input>
                )}

                {showlist === true && (
                  <input
                    className="csm_btn csm_btn_pri col-md-2 sub-btn"
                    type="button"
                    value="Clear"
                    onClick={filterClear}
                  ></input>
                )}
              </div>
            </div>
          ) : (
            <div className="row bck bck-rel">
              <div className="col-lg filter-ic pt-1">
                <i className="fa fa-filter vlpointer" onClick={filterItem}></i>

                <i
                  className="fa fa-cog vlpointer cog_pd"
                  onClick={handleShow}
                ></i>

                {showlist === true && (
                  <input
                    className="btn btn-primary sub-btn"
                    type="button"
                    value="Run"
                    onClick={filterSubmit}
                  ></input>
                )}

                {showlist === true && (
                  <input
                    className="btn btn-primary sub-btn"
                    type="button"
                    value="Clear"
                    onClick={filterClear}
                  ></input>
                )}

                {showlist === false && (
                  <select
                    style={{
                      // lineHeight: "0.8em",
                      width: "10em",
                      display: "inline",
                    }}
                    className="form-select namelist"
                    aria-label="Default"
                    value={srch_column}
                    onChange={(e) => searchColumn(e)}
                  >
                    {columnList.map((obj2, index) => (
                      <option key={index} value={obj2.name}>
                        {obj2.label}
                      </option>
                    ))}
                  </select>
                )}

                {showlist === false && (
                  <input
                    className="srch form-control"
                    type="search"
                    aria-label="Search"
                    value={srch}
                    onChange={(e) => changeSearch(e.target.value)}
                    onKeyDown={handleEnterPress}
                  ></input>
                )}

                {showlist === false && (
                  <button
                    className="btn btn-primary btn-sm col-md ms-1"
                    onClick={() => callSearchbtn()}
                  >
                    Search
                  </button>
                )}
              </div>
              <div className="col-lg mid">
                <p className="obj_head ">{listName}</p>
              </div>
              <div className="col-lg bt_padd ">
                <div>
                  <select
                    ref={mySelect}
                    className="dropdn"
                    onChange={(e) => {
                      e.target.value !== "none" &&
                        selectedAction(e.target.value);
                    }}
                  >
                    <option value={"none"}>None</option>
                    {button.length > 0 &&
                      list[0].rcd.length > 0 &&
                      button.map(
                        (obj, oo_i) =>
                          obj.ui_type === "selected_action" && (
                            <option key={oo_i} value={obj.webUrl}>
                              {obj.value}
                            </option>
                          )
                      )}
                  </select>
                </div>
                {button.length > 0 &&
                  button.map((obj, oo_i) => (
                    <div key={oo_i}>
                      {console.log(obj)}
                      {obj.show_type === "modelList" && (
                        <button
                          type="button"
                          className="insrtbtn2 btn btn btn-primary btn-sm"
                          key={oo_i}
                          onClick={(e) => callbtn(obj.value)}
                        >
                          {obj.name}
                        </button>
                      )}
                    </div>
                  ))}
                <div>
                  <input
                    className=" mybt"
                    type="button"
                    value="<<"
                    onClick={firstPage}
                    disabled={leftReadOnly()}
                  />
                  <input
                    className=" mybt"
                    type="button"
                    value="<"
                    onClick={previousPage}
                    disabled={leftReadOnly()}
                  />

                  <span className="in-put">{pageRecords}</span>
                  <span className="text-uppercase spfont"> of </span>

                  <span className="rcd_count spfont">{recordCount}</span>
                  <input
                    className=" mybt"
                    type="button"
                    value=">"
                    onClick={nextPage}
                    disabled={rightReadOnly()}
                  />
                  <input
                    className=" mybt"
                    type="button"
                    value=">>"
                    disabled={rightReadOnly()}
                    onClick={lastPage}
                  />
                </div>
              </div>
            </div>
          )}
          {showlist === false && filString !== "" && <div>{filString}</div>}
          {showlist === true && (
            <NewFilterCompo
              showlist={showlist}
              col_mn={col_mn}
              call_fil={(filtarray, in_index, col_id) => {
                callfilter(filtarray, in_index, col_id);
              }}
              filtarray={filtarray}
              setFiltArray={setFiltarray}
              timeline={timeLine}
              setTimeLine={(timeline) => calltimeline(timeline)}
              setScript={(script) => { }}
              isScript={false}
              tableName={name}
            />
          )}
          {list.length === 0 && <div>No Record Found</div>}
          {list.map((lstobj, l_i) => (
            <div
              className="heading_top table_set overflow-auto"
              style={{ height: "65vh", position: "relative" }}
              key={l_i}
            >
              <table className="table table-bordered table-striped table-hover p-1">
                <thead style={{ position: "sticky", top: "0px" }}>
                  <tr className="obj_name">
                    {lstobj.heading.map((obj, o_i) => (
                      <th
                        className="vlpointer p-0"
                        style={
                          o_i === 0
                            ? { verticalAlign: "middle", width: "4%" }
                            : { verticalAlign: "middle" }
                        }
                        key={o_i}
                      >
                        {o_i === 0 ? (
                          <span>
                            <input
                              type="checkbox"
                              checked={obj.ref}
                              onChange={(e) => {
                                selectedAllRows(e.target.checked);
                              }}
                            ></input>
                          </span>
                        ) : (
                          <div
                            style={{
                              height: "100%",
                              width: "100%",
                              padding: "0.5rem",
                            }}
                            onClick={(e) => {
                              showupdownbtn(obj.label);
                            }}
                          >
                            {obj.label}
                            {sortColumn.name === obj.label &&
                              sortColumn.sort === true && (
                                <i className=" icpadding fa fa-arrow-up"></i>
                              )}
                            {sortColumn.name === obj.label &&
                              sortColumn.sort === false && (
                                <i className=" icpadding fa fa-arrow-down"></i>
                              )}
                          </div>
                        )}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {console.log(lstobj.rcd)}
                  {lstobj.rcd.map((objj, ob_i) => (
                    <tr className="obj_value" key={ob_i}>
                      {objj.colr.map((objr, or_i) => (
                        <td
                          className={
                            objr.firstrecord === true
                              ? "val_pad val_under vlpointer"
                              : "val_pad"
                          }
                          onClick={
                            objr.firstrecord === true
                              ? (e) => {
                                e.preventDefault();
                                console.log(
                                  "objr_r : " + JSON.stringify(objr)
                                );
                                callform(
                                  "first",
                                  objr.ref_value,
                                  objr.r_id,
                                  ob_i,
                                  or_i,
                                  objr.table
                                );
                              }
                              : (e) =>
                                callform("second", "", "", "", "", objr.table)
                          }
                          key={or_i}
                        >
                          {or_i == 0 ? (
                            <span>
                              <input
                                type="checkbox"
                                checked={objr.ref}
                                onChange={(e) => {
                                  selectedRows(e.target.checked, ob_i);
                                }}
                              ></input>
                            </span>
                          ) : (
                            <span>
                              {(objr.name === "filter" || objr.name === "json") &&
                                objr.value !== null ? (
                                JSON.stringify(objr.value.name)
                              ) : objr.type === "reference" ||
                                objr.type === "depend_table" ? (
                                objr.value.name
                              ) : objr.type === "group_key_value" ? (
                                JSON.stringify(
                                  objr.value.properties?.length > 50
                                    ? objr.value.properties.slice(0, 50) + " ..."
                                    : objr.value.properties
                                )
                              ) : objr.type === "html" ? (
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      objr.value?.length > 50
                                        ? objr.value.slice(0, 50) + " ..."
                                        : objr.value,
                                  }}
                                ></span>
                              ) : objr.value?.length > 50 ? (
                                objr.value.slice(0, 50) + " ..."
                              ) : (
                                objr.value
                              )}
                            </span>
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
              {lstobj.rcd.length === 0 && (
                <div
                  style={{
                    fontWeight: "bold",
                    textDecoration: "none",
                    fontStyle: "normal",
                  }}
                >
                  No Record Found
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      <Modal
        dialogClassName="modal-dialog my-modal-personalized modal-centered"
        size="xl"
        show={showPref}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Personalized List Column</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body className="mod-ht" sty> */}
        <Modal.Body>
          <PrefCompo
            pref="user"
            setRefer={(val) => setRef_fields(val)}
            colarray={prefCall}
            tablename={name}
            handleClose={() => handleCloseRefresh()}
          ></PrefCompo>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ModelList;
