import React, { useState } from 'react'
import { useContext } from 'react';
import { useEffect } from 'react';
import { AutoPortalContext } from '../Context';
import axios from 'axios';
import { useNavigate } from "react-router-dom"
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import WorkInProgress from './WorkInProgress';

const CogCompo = ({ cogChangefn, cancelCog }) => {
  const navigation = useNavigate();
  const { showAll, setShowAll, token, loca, userDetails, getVerify } = useContext(AutoPortalContext);
  const [file, setFile] = useState();
  const [modal, setModal] = useState(false);
  const [isClick, setIsClick] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios
      .get(loca + "/lom/get/visible", {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let result = resp.data;
          // alert(result)
          setShowAll(result.toString())
        },
        (error) => {
          navigation("/error")
          console.log(error);
        }
      );
  }, [showAll])

  const csvDownload = () => {
    axios.get(loca+"/exportFullSchema", {
      headers: {
        authorization: "Bearer " + token,
      },
      responseType: 'blob'
    }).then(
      (response) => {
        let res = response.data;
        const link = document.createElement('a');
        const url = URL.createObjectURL(res);
        console.log(url);
        link.href = url;
        link.download = 'full_db';
        link.click();
        link.remove()

      },
      (err) => {
        console.log(err);
      }
    );
  };

  const deleteApp = () => {
    setLoading(true);
    axios.get(loca+"/delete/application", {
      headers: {
        authorization: "Bearer " + token,
      },
    }).then(
      (response) => {
        let res = response.data;
        setModal(false);
        cancelCog();
        getVerify();
        toast(res, {
          position: "top-center",
          theme: "colored",
          type: "success",
          style: {
            marginBottom: userDetails.OS !== "null" ? 12 : 0,
          },
        });
        setLoading(false);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const csvUpload = () => {
    if (!file) {
      alert("Please select a file first!");
      return;
    }
    setLoading(true);
    // cancelCog();
    const formData = new FormData();
    formData.append("file", file);
    axios.post(loca+"/importFullSchema", formData, {
      headers: {
        authorization: "Bearer " + token,
      },
    }).then(
      (response) => {
        let res = response.data;
        console.log(res);
        toast(res, {
          position: "top-center",
          theme: "colored",
          type: "success",
          style: {
            marginBottom: userDetails.OS !== "null" ? 12 : 0,
          },
        });
        setLoading(false);
      },
      (err) => {
        console.log(err);
      }
    );
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  if (!loading) {
    return (
      <div style={{ flexGrow: 1 }}>
        <Modal show={modal} onHide={() => setModal(!modal)} >
          <Modal.Header closeButton>
            <Modal.Title>Confirm Delete</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setModal(!modal)}
              disabled={isClick}
              style={{ backgroundColor: isClick ? "gray" : "" }}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={() => deleteApp()}
              disabled={isClick}
              style={{ backgroundColor: isClick ? "gray" : "" }}
            >
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="d-flex align-items-center">
        <input
          type="checkbox"
          className={"checkpadd"}
          // value={obj.value === "true" ? true : false}

          checked={showAll === "true" ? true : false}
          onChange={(e) => {
            cogChangefn(
              e.target.checked.toString(),
            );
          }}
        ></input>
        <span>Show All</span>
        </div>
        <hr />
        <h5>Import/Export Application</h5>

        <ul>
        <li>
          <button
            type="button"
            className="btn btn-info btn-sm ms-0"
            onClick={csvDownload}
          >
            Export
          </button>
        </li>

        <li className="mt-2">
          <input
            type="file"
            accept=".zip"
            className="bg-slate-50"
            onChange={handleFileChange}
          />
          <br />
          <button
            className="btn btn-success mt-2 btn-sm"
            onClick={() => {
              csvUpload();
            }}
            style={{ margin: "0px" }}
          >
            Import
          </button>
        </li>
      </ul>


        {/* <div>
          <button type="button" className="btn btn-info" onClick={csvDownload} style={{ margin: "0px", marginTop: "2%" }}>Export Application</button>
        </div>

        <div className="" style={{ marginTop: "2%" }}>
          <input
            type="file"
            accept=".zip"
            className="bg-slate-50"
            onChange={handleFileChange}
          />
          <button
            className="btn btn-success"
            onClick={() => {
              csvUpload();
            }}
            style={{ margin: "0px" }}
          >
            Upload
          </button>
        </div> */}
 <hr />
      <div>
        <button
          type="button"
          className="btn btn-danger"
          onClick={() => setModal(!modal)}
          style={{ margin: "0px", marginTop: "2%" }}
        >
          Delete Application
        </button>
      </div>
        {/* <div>
          <button type="button" className="btn btn-danger" onClick={() => setModal(!modal)} style={{ margin: "0px", marginTop: "2%" }}>Delete Application</button>
        </div> */}
      </div>
    )
  } else {
    return (
      <div className="modal-load">
        <WorkInProgress />
      </div>
    );
  }
}
export default CogCompo
