import React, { useState, useEffect, useContext } from "react";
import "../css/landing.css";
import axios from "axios";
import { createSearchParams, useNavigate } from "react-router-dom";
import { AutoPortalContext } from "../Context.jsx";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Carousel from "react-bootstrap/Carousel";
import "../css/newPage.css";
import { Dropdown, Table } from "react-bootstrap";

//application
const NewPageTest = () => {
  const { token, loca,ipaddress, setIpaddress, autoTime, setAutoTime } =
    useContext(AutoPortalContext);
  const navigation = useNavigate();
  const [html, setHtml] = useState("");
  const [mainClass, setMainClass] = useState("");
  const [cssClass, setCssClass] = useState([]);
  const [record, setRecord] = useState([]);
  const [imageUrl, setImageUrl] = useState("http://192.168.3.117:8081/");
  const [imgSize, setImgSize] = useState(0);
  const [details, setDetails] = useState(null);
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [loading, setLoading] = useState(true);
  const [page_message, setPageMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [refrecord, setRefRecord] = useState({});
  const [button, setButton] = useState([]);

  const [mainRecord, setMainRecord] = useState([]);
  useEffect(() => {
    load();
  }, []);

  // const handleSelect = (selectedIndex, e) => {
  //   setIndex(selectedIndex);
  // };

  function locations() {
    navigation({
      pathname: "/landing",
      search: createSearchParams({ app: "zacooks", page: "list" }).toString(),
    });
  }

  function home() {
    navigation({
      pathname: "/landing",
      search: createSearchParams({
        app: "zacooks",
        page: "home",
      }).toString(),
    });
  }

  function submit() {
    console.log(document.getElementById('1167').checked);
    mainRecord.formRecord[2].record = record;
    console.log(mainRecord);
    axios
      .post(loca+"/api/insert", mainRecord, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          console.log(resp.data);
        },
        (error) => { navigation("/error")
          console.log(error);
        }
      );
  }

  function add() {
    navigation({
      pathname: "/landing",
      search: createSearchParams({
        app: "zacooks",
        page: "add",
      }).toString(),
    });
  }

  function load() {
    axios
      .get(loca+"/api/get/blankrecord", {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          console.log(resp.data);
          let blkrecord = resp.data;
          setMainRecord(blkrecord);
          setRecord(blkrecord.formRecord[2].record);
        },
        (error) => { navigation("/error")
          console.log(error);
        }
      );
  }

  function formChangefn(val, index) {
    let frecord = record;
    console.log(typeof val);
    frecord[index].value = val;
    setRecord([...frecord]);
  }

  return (
    <div style={{flexGrow:1}}>
      <div id="zack">
        <div className="shades ">
          <div>
            <nav className="navbar sticky-top navbar-expand-lg navbar-dark nav-shadow  ">
              <div className="container-fluid">
                <a style={{ fontSize: 24 }} className="navbar-brand" href="#">
                  Zacooks
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        aria-current="page"
                        href="#"
                        onClick={home}
                      >
                        Home
                      </a>
                    </li>
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        href="#"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        popular
                      </a>
                      <ul
                        className="dropdown-menu"
                        aria-labelledby="navbarDropdown"
                      >
                        <li>
                          <a className="dropdown-item" href="#burger">
                            Burger
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#biryani">
                            Biryani
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#bowl">
                            Ramen
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="#seekhkabab">
                            Seekh Kabab
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a className="dropdown-item" href="#coockies">
                            coockies
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        aria-current="page"
                        href="#"
                        onClick={add}
                      >
                        Add New Location
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>

            {/* <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-bs-ride="carousel"
          >
            <div className="carousel-indicators above-overlay ">
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="0"
                aria-current="true"
                aria-label="Slide 1"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="1"
                className="active"
                aria-label="Slide 2"
              ></button>
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to="2"
                aria-label="Slide 3"
              ></button>
            </div>
            <div className="carousel-inner">
              <div className="carousel-item ">
                <div className="overlay"></div>
                <img src="pack.jpg" className="d-block w-100" alt="First" />
              </div>
              <div className="carousel-item active">
                <div className="overlay"></div>
                <img src="egg.jpg" className="d-block w-100" alt="Second" />
                <div class="carousel-caption d-none d-md-block mid-overlay">
                  <button className="btn btn-primary " onClick={locations}>
                    All Branch Location
                  </button>
                  <p>Click Above Button To See All branch!</p>
                </div>
              </div>
              <div className="carousel-item">
                <div className="overlay"></div>
                <img src="daal2.jpg" className="d-block w-100 " alt="Third" />
              </div>
            </div>
            <button
              className="above-overlay carousel-control-prev "
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="prev"
            >
              <span
                className="carousel-control-prev-icon"
                
              ></span>
              <span className="visually-hidden">Previous</span>
            </button>
            <button
              className="carousel-control-next above-overlay"
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide="next"
            >
              <span
                className="carousel-control-next-icon"
                
              ></span>
              <span className="visually-hidden">Next</span>
            </button>
          </div>

            <div className="container card card-body mt-3 card-shadow">
            <div id="burger">
              <div style={{ marginTop: 100 }} className="row">
                <div
                  style={{ fontSize: 24 }}
                  className="text col-md-6 d-flex align-items-center d-flex justify-content-between"
                >
                  Zacooks it a new Emerging Restaurant."Within an hour, we had
                  probably our best landing page yet and for a fraction of the
                  price. It's amazing. Since then, I've built about 15 sites on
                  Strikingly – it's currently my favorite startup tech
                  tool."testing is much better than system.
                </div>
                <div className="col-md-6">
                  <img
                    className="main img-shadow"
                    src="burger.jpg"
                    height={500}
                    width={500}
                  />
                </div>
              </div>
            </div>
            <div id="bowl">
              <div style={{ marginTop: 100 }} className="row">
                <div className="col-md-6">
                  <img
                    className="main img-shadow"
                    style={{}}
                    src="knife.jpg"
                    height={500}
                    width={500}
                  />
                </div>
                <div
                  style={{ fontSize: 24 }}
                  className="text col-md-6 d-flex align-items-center"
                >
                  Zacooks it a new Emerging Restaurant."Within an hour, we had
                  probably our best landing page yet and for a fraction of the
                  price. It's amazing. Since then, I've built about 15 sites on
                  Strikingly – it's currently my favorite startup tech tool."
                </div>
              </div>
            </div>
            <div id="biryani">
              <div style={{ marginTop: 100 }} className="row">
                <div
                  style={{ fontSize: 24 }}
                  className="text col-md-6 d-flex align-items-center"
                >
                  Zacooks it a new Emerging Restaurant."Within an hour, we had
                  probably our best landing page yet and for a fraction of the
                  price. It's amazing. Since then, I've built about 15 sites on
                  Strikingly – it's currently my favorite startup tech tool."
                </div>
                <div className="col-md-6">
                  <img
                    className="main img-shadow"
                    src="biryani.jpg"
                    height={500}
                    width={500}
                  />
                </div>
              </div>
            </div>
            <div id="seekhkabab">
              <div style={{ marginTop: 100 }} className="row">
                <div className="col-md-6">
                  <img
                    className="main img-shadow"
                    alt="100%"
                    src="bowl.jpg"
                    height={500}
                    width={500}
                  />
                </div>
                <div
                  style={{ fontSize: 24 }}
                  className="text col-md-6 d-flex align-items-center d-flex align-items-center"
                >
                  Zacooks it a new Emerging Restaurant.we had emerging quality
                  of indian food as well as other contries foods which make you
                  travel through the world by eating just one bite. It's
                  amazing. Since then, we've provide lots of food to people
                </div>
              </div>
            </div>
            <div id="coockies">
              <div style={{ marginTop: 100 }} className="row">
                <div
                  style={{ fontSize: 24 }}
                  className="text col-md-6 d-flex align-items-center"
                >
                  Zacooks is also provides pure-veg foods for vegeterians and
                  all these food cooks in a seprate kitchen so you don't have to
                  take any tension about your religious thoghts
                </div>
                <div className="col-md-6">
                  <img
                    className="main img-shadow"
                    src="chai.jpg"
                    height={500}
                    width={500}
                  />
                </div>
              </div>
            </div>
          </div> */}
            <div id="card" className="container card card-body card-shadow my-5">
                 {/* <div className="heading_top table_set over">
                  <table className="table table-bordered table-striped table-hover p-1">
                    <thead>
                      <tr className="obj_name">
                        <th>Sr No.</th>
                        <th>Address</th>
                        <th>location</th>
                      </tr>
                    </thead>   
                    <tbody>
                    <tr >
                        <td>1</td>
                        <td>mumbai</td>
                        <td>Maharashtra</td>
                      </tr>
                    </tbody>
                  </table>
                </div>             */}
            </div>
              
            {/* <div
              
              className="container card card-body card-shadow my-5"
            >
              <form >
                <div id="card">
                {record.length > 0 &&
                  record.map((e, index) => (
                    <div key={index}>
                      {e.type === "String" && (
                        <div>
                          {e.uivalid.visible === "true" && (
                            <div class="mb-3 text-start">
                              <label for={e.id} class="form-label">
                                {e.label}
                              </label>
                              <input
                                value={e.value}
                                onChange={(e) => {
                                  formChangefn(e.target.value, index);
                                }}
                                type="text"
                                class="form-control"
                                id={e.id}
                              />
                            </div>
                          )}
                        </div>
                      )}
                      {e.type === "boolean" && (
                        <div>
                          {e.uivalid.visible === "true" && (
                            <div class="mb-3 form-check text-start">
                              <input
                                type="checkbox"
                                onChange={(e) => {
                                  formChangefn(
                                    e.target.checked.toString(),
                                    index
                                  );
                                }}
                                checked={e.value === "true" ? true : false}
                                class="form-check-input"
                                id={e.id}
                              />
                              <label class="form-check-label" for={e.id}>
                                {e.label}
                              </label>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  ))}
                  </div>
                <button type="button" class="btn btn-primary" onClick={submit}>
                  Submit
                </button>
              </form>
            </div> */}
            <footer className="bg-light text-center text-lg-start text-muted">
              <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
                <div className="me-5 d-none d-lg-block">
                  <span>Get connected with us on social networks:</span>
                </div>
                <div>
                  <a
                    href="https://www.facebook.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="me-4 text-reset"
                  >
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a
                    href="https://twitter.com/i/flow/login"
                    target="_blank"
                    rel="noreferrer"
                    className="me-4 text-reset"
                  >
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a
                    href="https://www.google.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="me-4 text-reset"
                  >
                    <i className="fab fa-google"></i>
                  </a>
                  <a
                    href="https://www.instagram.com/accounts/login/"
                    target="_blank"
                    rel="noreferrer"
                    className="me-4 text-reset"
                  >
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/login"
                    target="_blank"
                    rel="noreferrer"
                    className="me-4 text-reset"
                  >
                    <i className="fab fa-linkedin"></i>
                  </a>
                  <a
                    href="https://github.com/"
                    target="_blank"
                    rel="noreferrer"
                    className="me-4 text-reset"
                  >
                    <i className="fab fa-github"></i>
                  </a>
                </div>
              </section>
              <section className="">
                <div className="container text-center text-md-start mt-5">
                  <div className="row mt-3">
                    <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                      <h6 className="text-uppercase fw-bold mb-4">
                        Managed By CloudSmaya Services Pvt Ltd.
                      </h6>
                      <p>We are Emerging Restaurant.</p>
                    </div>
                    <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                      <h6 className="text-uppercase fw-bold mb-4">Products</h6>
                      <p>
                        <a href="#!" className="text-reset">
                          Indian
                        </a>
                      </p>
                      <p>
                        <a href="#!" className="text-reset">
                          Japanese
                        </a>
                      </p>
                      <p>
                        <a href="#!" className="text-reset">
                          American
                        </a>
                      </p>
                      <p>
                        <a href="#!" className="text-reset">
                          French
                        </a>
                      </p>
                    </div>
                    <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
                      <h6 className="text-uppercase fw-bold mb-4">
                        Useful links
                      </h6>
                      <p>
                        <a href="#!" className="text-reset">
                          Pricing
                        </a>
                      </p>
                      <p>
                        <a href="#!" className="text-reset">
                          Settings
                        </a>
                      </p>
                      <p>
                        <a href="#!" className="text-reset">
                          Orders
                        </a>
                      </p>
                      <p>
                        <a href="#!" className="text-reset">
                          Help
                        </a>
                      </p>
                    </div>
                    <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                      <h6 className="text-uppercase fw-bold mb-4">Contact</h6>
                      <p>
                        <i className="fa fa-home me-2"></i>Building No 12 Room
                        No 26 2ndFloor Sai Nagar, Bhiwandi, India
                      </p>
                      <p>
                        <i className="fa fa-envelope me-3"></i>
                        Customer.care@cloudsmaya.com
                      </p>
                      <p>
                        <i className="fa fa-phone me-3"></i> +91-568876205
                      </p>
                      <p>
                        <i className="fa fa-print me-3"></i> + 91-986571235
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <div
                className="text-center p-4"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.05)" }}
              >
                © 2021 Copyright:
                <a
                  className="text-reset fw-bold"
                  href="https://cloudsmaya.com/"
                >
                  CloudSmaya.com
                </a>
              </div>
            </footer>
          </div>
        </div>
      </div>

      <Button
        onClick={() => {
          console.log(document.getElementById("zack").innerHTML);
        }}
      >
        innerHtml
      </Button>
    </div>
  );
};
export default NewPageTest;
