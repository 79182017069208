import React from "react";
import "../css/UiPolicy_Action.css";

const UiPolicy_Action = () => {
  return (
    <div style={{flexGrow:1}} className="Card cardcss">
      <h1 className="hhh">UiPolicy_Action</h1>
      <div className="" style={{ display: "flex" }}>
        <div className="ttt">
          <text>UiPolicy</text>
        </div>
        <input
          className=" inpttt "
          aria-label="Default select example"
          // onChange={(e) => changeApp(e.target.value)}
        >
          
          {/* {tableList.map((obj, index) => (
          <option
            key={index}
            value={obj.name}
            // selected={userApplication.label === obj.label}
          >
            {obj.label}
          </option>
        ))} */}
        </input>
        <div style={{ display: "flex", marginTop: "5px",marginLeft:"19px" }}>
          <div className="ttt">
            <text>Mandtory</text>
          </div>
          <select
          className=" inpttt "
          aria-label="Default select example"
          // onChange={(e) => changeApp(e.target.value)}
        >
          <option>Leave alone</option>
          <option>True</option>
          <option>False</option>
          {/* {tableList.map((obj, index) => (
          <option
            key={index}
            value={obj.name}
            // selected={userApplication.label === obj.label}
          >
            {obj.label}
          </option>
        ))} */}
        </select>
        </div>
      </div>
      <div className="" style={{ display: "flex" }}>
        <div className="ttt">
          <text>Table</text>
        </div>
        <input
          className=" inpttt "
          aria-label="Default select example"
          // onChange={(e) => changeApp(e.target.value)}
        >
         
          {/* {tableList.map((obj, index) => (
          <option
            key={index}
            value={obj.name}
            // selected={userApplication.label === obj.label}
          >
            {obj.label}
          </option>
        ))} */}
        </input>
        <div style={{ display: "flex", marginTop: "5px",marginLeft:"67px" }}>
          <div className="ttt">
            <text>Visible</text>
          </div>
          <select
          className=" inpttt "
          aria-label="Default select example"
          // onChange={(e) => changeApp(e.target.value)}
        >
          <option>Leave alone</option>
          <option>True</option>
          <option>False</option>
          {/* {tableList.map((obj, index) => (
          <option
            key={index}
            value={obj.name}
            // selected={userApplication.label === obj.label}
          >
            {obj.label}
          </option>
        ))} */}
        </select>
        </div>
      </div>
      <div className="" style={{ display: "flex" }}>
        <div className="ttt">
          <text>FieldName</text>
        </div>
        <select
          className=" inpttt "
          aria-label="Default select example"
          // onChange={(e) => changeApp(e.target.value)}
        >
          <option>None</option>
          {/* {tableList.map((obj, index) => (
          <option
            key={index}
            value={obj.name}
            // selected={userApplication.label === obj.label}
          >
            {obj.label}
          </option>
        ))} */}
        </select>
        <div style={{ display: "flex", marginTop: "5px" }}>
          <div className="ttt">
            <text>ReadOnly</text>
          </div>
          <select
          className=" inpttt "
          aria-label="Default select example"
          // onChange={(e) => changeApp(e.target.value)}
        >
          <option>Leave alone</option>
          <option>True</option>
          <option>False</option>
          {/* {tableList.map((obj, index) => (
          <option
            key={index}
            value={obj.name}
            // selected={userApplication.label === obj.label}
          >
            {obj.label}
          </option>
        ))} */}
        </select>
        </div>
      </div>
      <div className="divvvvvcheck">
        <div className="ttt">
          <text>Clear the field value</text>
        </div>
        <input
          type="checkbox"
          className=" inpcheckbox "
          aria-label="Default select example"
          // checked={active}
          // onChange={(e)=>changeActive(e.target.checked)}
        ></input>
      </div>

      <div className="">
        <input
          type="button"
          className="submitbtnnnnnn"
          value="submit"
          //  onClick={()=>console.log(JSON.stringify(record))}
        ></input>
      </div>

      </div>
   
  );
};

export default UiPolicy_Action;
