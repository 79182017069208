import axios from "axios";
import React, { useState, useContext, useEffect, useRef } from "react";
import {
  createSearchParams,
  useBlocker,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { AutoPortalContext } from "../Context";
import WorkInProgress from "./WorkInProgress";
import RelationList from "./RelationList";
import "../css/FormCSS.css";
import "../css/relationlist.css";
import ApForm from "../HelperClasses/ApForm";
import ModelList from "./ModelList";
import ApUser from "../HelperClasses/ApUser";
import { Modal, Button, Tab, Nav, ListGroup, Dropdown } from "react-bootstrap";
import FormInnerCompo from "./FormInnerCompo";
import JoditEditor from "jodit-react";
import { toast } from "react-toastify";
import DragModel from "./DargModel";
import Invoice from "./Invoice";
import AlertCompo from "./AlertCompo";
import ContextMenu from "./ContextMenu";
import { useFilePicker } from "use-file-picker";

const FormComponent = ({ dash_tabname, dash_rty, dash_rid, form_id }) => {
  const {
    token,
    loca,
    setShowContainer,
    contextMenu,
    labelContext,
    showContainer,
    userDetails,
    user,
    listAccess,
    imageloca,
    newAccess,
    appName,
    isMobile,
  } = useContext(AutoPortalContext);
  const { openFilePicker, filesContent, loadingg, clear } = useFilePicker({
    accept: [".txt", ".csv"],
  });
  const navigation = useNavigate();
  const [searchParam, setSearchParam] = useSearchParams();
  const location = useLocation().state;
  const pathName = useLocation().pathname;
  const [loading, setLoading] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [verifyError, setVerifyError] = useState(true);
  const [defUiValid, setDefUiValid] = useState([]);
  const [tabRel, setTabRel] = useState(false);
  const [tabRelation, setTabRelation] = useState({});
  const [page_error, setPageError] = useState(false);
  const [error, setError] = useState("");
  const [page_message, setPageMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [record, setRecord] = useState([]);
  const [rcdView, setRcdView] = useState([]);
  const [infoRcdView, setInfoRcdView] = useState([]);
  const [infoRcdDetail, setInfoRcdDetail] = useState({});
  const [downRcdView, setDownRcdView] = useState([]);
  const [activity, setActivity] = useState([]);
  const [refrecord, setRefRecord] = useState({});
  const [button, setButton] = useState([]);
  const [modal, setModal] = useState(false);
  const [cid, setCid] = useState(-1);
  const [add, setAdd] = useState(false);
  const [multiSv, setMultiSv] = useState("");
  const [imgObj, setImgObj] = useState([]);
  const multipart = useRef(new FormData()).current;
  const [all_ref_cols, setAll_ref_cols] = useState([]);
  const [copyRcd, setCopyRcd] = useState([]);
  const [column_depend, setColumn_depend] = useState([]);
  const [mainRecord, setMainRecord] = useState([]);
  const [selection, setSelection] = useState("");
  const [uiScript, setUiScript] = useState([]);
  const [ap_form, setap_form] = useState();
  const [ap_user, setap_user] = useState();
  const [showModelList, setshowModelList] = useState();
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [passwordReadOnly, setPasswordReadOnly] = useState(false);
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [showContext, setShowContext] = useState(false);
  const [mscList, setMscList] = useState([]);
  const [viewList, setViewList] = useState([]);
  const [dcFiltVal, setDcFiltVal] = useState();
  const [showModelInfo, setShowModelInfo] = useState(false);
  const [invRcd, setInvRcd] = useState();
  const [formChange, setFormChange] = useState(true);
  const [tabId, setTabId] = useState("");
  const [subLoadAck, setSubLoadAck] = useState("");
  const loadtext = useRef(null);
  const [filtarray, setFiltArray] = useState([
    {
      co: "",
      cl: "",
      mc: "",
      an: "",
      ct: "",
      af: "",
      rf: { id: "", value: "" },
      dc: { id: "", value: "" },
      ch: [],
    },
  ]);
  const [filtRefArray, setFiltRefArray] = useState([
    {
      co: "",
      cl: "",
      mc: "",
      an: "",
      ct: "",
      af: "",
      rf: { id: "", value: "" },
      dc: { id: "", value: "" },
      ch: [],
    },
  ]);
  const [selectedJson, setSelectedJson] = useState();
  const [tablabel, setTabLabel] = useState("");
  const [filt, setFilt] = useState();
  const [ref_filter, setref_filter] = useState([]);
  const [showlist, setShowList] = useState(false);
  const [col_mn, setCol_mn] = useState([]);
  const [col_mn_ref, setCol_mn_ref] = useState([]);
  const [choice_mn, setChoice_mn] = useState([]);
  const [choice_ref_mn, setChoice_ref_mn] = useState([]);
  const [timeline, setTimeline] = useState("");
  const [columnId, setColumnId] = useState();
  const [objName, setObjName] = useState();
  const [depCol, setDepCol] = useState();
  const [objLabel, setObjLabel] = useState();
  const [objIndex, setObjIndex] = useState();
  const [objType, setObjType] = useState();
  const [record_rq, setrecord_rq] = useState();
  const [isClick, setIsClick] = useState(false);
  const [policyaction, setPolicyAction] = useState([]);
  const [policyFilter, setPolicyFilter] = useState([]);
  const [reScript, setReScript] = useState([]);
  const [btnNamee, setbtnNamee] = useState("");
  const [keyValueJson, setKeyValueJson] = useState("");
  const [secondaryStartIndex, setSecondaryStartIndex] = useState(0);
  const [secondarycallsCols, setSecondarycallsCols] = useState(new Map());
  const [shouldBlock, setShouldBlock] = useState(false);
  const [showFilePicker, setShowFilePicker] = useState(false);
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [searchrcd, setSearchrcd] = useState([
    { name: "ap_form", show: false },
    { name: "getFieldValue()", show: false },
    { name: "setFieldValue()", show: false },
  ]);
  const [recordSuggestion, setRecordSuggestion] = useState([
    { name: "ap_form", show: false },
    { name: "ap_user", show: false },
    { name: "getFieldValue()", show: false },
    { name: "setFieldValue()", show: false },
    { name: "setMandatory()", show: false },
    { name: "isMandatory()", show: false },
    { name: "setVisible()", show: false },
    { name: "addOption()", show: false },
    { name: "removeOption()", show: false },
    { name: "removeAllOption()", show: false },
  ]);
  const [userRecordSuggestion, setUserRecordSuggestion] = useState([
    { name: "ap_user", show: false },
    { name: "getUserId()", show: false },
    { name: "getId()", show: false },
    { name: "getLabel ", show: false },
    { name: "getUniId()", show: false },
  ]);
  const [overlayH, setOverlayH] = useState(0);
  let parentApp = useRef("");
  let tableName = useRef("");
  let rty = useRef("");
  let rid = useRef(0);
  let uScript = useRef("");
  let rcrd = useRef("");
  let tabRe = useRef("");
  let isSub = useRef(false);
  let fieldName = useRef("").current;
  let recordNew = useRef("").current;
  let count = useRef(1);
  let listRef = useRef([]);
  let i_d = useRef(0);
  const editor = useRef(null);
  let menuX = useRef(0);
  let menuY = useRef(0);
  let m_rid = useRef(0);
  let checkMenu = useRef(false);
  let refValue = useRef("");
  let rcdRef = useRef("");
  let closeRef = useRef(false);
  let deftimeline = useRef("");
  // flag to control whether to show  alert or not while going back
  let isBackAlertShow = useRef(true);

  useEffect(() => {
    if (searchParam.get("rty") === "new" && location) {
      console.log("dataa", record);
      ref_cols_insert();
    }
  }, [formChange]);

  const ref_cols_insert = () => {
    const excluded_cols = location.excluded_cols;
    console.log(excluded_cols, location);

    let rcdJson = excluded_cols
      ? location.ref_cols.filter(
          (element) => !excluded_cols.includes(element.name)
        )
      : location.ref_cols;
    console.log(rcdJson);

    if (rcdJson) {
      let rdJson = JSON.parse(JSON.stringify(rcdJson));
      let obj = "";
      var rcd = record;
      console.log(rcd);
      for (let i = 0; i < rdJson.length; i++) {
        for (let j = 0; j < rcd.length; j++) {
          if (rcd[j].name === rdJson[i].name) {
            // appending new values
            let vl = rdJson[i].value,
              index = j,
              ob = rcd[j].name,
              type = rcd[j].type,
              id = 0,
              vrf = true,
              obj = rcd[j],
              outerIndex = 0,
              innerIndex = 0,
              nme = "",
              props = null,
              depend_column = null,
              readonly = false;
            if (type === "reference" || type === "depend_table") {
              vl = rdJson[i].value.name;
              id = rdJson[i].value.id;
              depend_column = rdJson[i].depend_column;
            }

            isBackAlertShow.current = false;
            formChangefn(
              vl,
              index,
              ob,
              type,
              id,
              vrf,
              obj,
              outerIndex,
              innerIndex,
              nme,
              props,
              depend_column,
              readonly
            );
          }
        }
      }
      setTimeout(() => {
        setLoading(false);
      }, 200);
    }
  };

  const getCaretPos = (e, index) => {
    // Getting the textarea element
    let textarea = document.getElementById("script" + index);

    // Adjusting textarea to prevent scrolling
    textarea.style.height = `${e.target.scrollHeight}px`;

    // Appending element to the DOM after textarea
    textarea.insertAdjacentHTML(
      "afterend",
      `<span id = 'dummy'>${textarea.value}</span>`
    );

    // Getting position info of the rectangles inside dummy element
    let rectangles = document.getElementById("dummy").getClientRects();
    let last = rectangles[rectangles.length - 1];

    // Getting position info of the textarea
    let text = document
      .getElementById("script" + index)
      .getBoundingClientRect();

    // Setting coordinates
    let x = last.x + last.width;
    let y = text.y + text.height - last.height;

    // Removing dummy
    document.getElementById("dummy").remove();

    // Returning variables
    return [x, y];
  };

  const onSelection = (type) => {
    let s;
    switch (type) {
      case "onsubmit":
        s = "function onSubmit(){";
        break;
      case "onload":
        s = "function onLoad(){";
        break;
      case "onchange":
        s = "function onChange(val){";
        break;
      case "oncelledit":
        s = "function onCellEdit(val){";
        break;

      case "onreference":
        s = "function onReference(){";
        break;

      default:
        s = "";
        break;
    }
    setSelection(s);
  };

  const searchModule = (nam, where, type) => {
    if (true) {
      if (type === "form") {
        var search = recordSuggestion;
        var abc = [];
        for (var i = 0; i < search.length; i++) {
          var app_m = search[i].name.toLowerCase();
          if (app_m.includes(nam.toLowerCase())) {
            if (where) {
              if (i === 0 || i === 1) {
                abc.push({
                  name: search[i].name,
                  show: true,
                });
              }
            } else {
              if (i > 0) {
                abc.push({
                  name: search[i].name,
                  show: true,
                });
              }
            }
          }
        }
        setSearchrcd([...abc]);
      } else if (type === "user") {
        var search = userRecordSuggestion;
        var abc = [];
        for (var i = 0; i < search.length; i++) {
          var app_m = search[i].name.toLowerCase();
          if (app_m.includes(nam.toLowerCase())) {
            if (where) {
              if (i === 0) {
                abc.push({
                  name: search[i].name,
                  show: true,
                });
              }
            } else {
              if (i > 0) {
                abc.push({
                  name: search[i].name,
                  show: true,
                });
              }
            }
          }
        }
        setSearchrcd([...abc]);
      }
    } else if (nam === "") {
      setSearchrcd([...recordSuggestion]);
    }
  };

  const callFieldList = (tabId, call_check) => {
    var filterstate = filtarray;
    var farray = [];
    if (
      filtarray === "" ||
      filtarray === "undefined" ||
      filtarray === undefined ||
      filtarray === "null" ||
      filtarray === null
    ) {
      farray.push(filt);
      filterstate = farray;
    } else {
      if (filtarray instanceof Array) {
        farray = filtarray;
        filterstate = farray;
      } else {
        farray = filtarray.filter;
        filterstate = farray;
      }
    }

    var tablere = '{"formRecordList":[';
    tablere += '{"application":{"id":"","name":"autoportal"}}';
    tablere += ',{"table":{"id":"' + tabId + '","name":"","label":""}}';
    tablere += ',{"records":[]}';
    tablere +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    tablere += ',{"sort":{"asc":"true","column":"id","init":"true"}}';
    tablere += ',{"filter":' + JSON.stringify(filterstate) + "}";
    tablere += ',{"timeLine":"' + timeline + '"}]}';
    setList(tablere, call_check);
  };

  const setList = (tablere, call_check) => {
    axios
      .post(loca + "/lom/get/multiple/record", tablere.toString(), {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then((resp) => {
        const listrecord = resp.data;
        var columnarry = [];
        var hd = [];
        var va_ll = [];
        if (listrecord !== "") {
          if ("Error" in listrecord) {
            // this.setState({
            //   loading: false,
            //   page_error: true,
            //   error: listrecord.Error,
            // });
            setLoading(false);
            setPageError(true);
            setError(listrecord.Error);
          } else {
            var scnd = listrecord.formRecordList[2];
            if ("Error" in scnd) {
              if (scnd.Error === "No record found.") {
                // this.setState({ list: [] });
                // setlist([]);
                toast("No record found", { type: "error" });
              }
            } else {
              var page_clicked1 = parseInt(
                listrecord.formRecordList[3].page.page_clicked
              );
              var page_count1 = parseInt(
                listrecord.formRecordList[3].page.page_count
              );
              var page_records1 =
                listrecord.formRecordList[3].page.page_records;
              var record_count1 = parseInt(
                listrecord.formRecordList[3].page.record_count
              );

              var flt = listrecord.formRecordList[5];
              var fltarr = listrecord.formRecordList[5].filter;
              var tmln = listrecord.formRecordList[6].timeLine;
              var filterString = "";
              var leng = fltarr.length;
              for (var f = 0; f < fltarr.length; f++) {
                if (leng === 1 && fltarr[f].cl === "") {
                  break;
                } else {
                  if (f > 0) {
                    filterString += fltarr[f].af + " ";
                  }
                  filterString += fltarr[f].cl + " ";
                  filterString += fltarr[f].mc + " ";
                  filterString += fltarr[f].an + " ";
                }
              }

              for (
                var i = 0;
                i < listrecord.formRecordList[2].records.length;
                i++
              ) {
                var in_vl = [];
                var record_id;
                for (
                  var j = 0;
                  j < listrecord.formRecordList[2].records[i].record.length;
                  j++
                ) {
                  if (i === 0) {
                    if (j === 0) {
                    } else {
                      hd.push({
                        label:
                          listrecord.formRecordList[2].records[i].record[j]
                            .label,
                        name: listrecord.formRecordList[2].records[i].record[j]
                          .name,
                      });
                    }
                  }
                  if (j === 0) {
                    record_id =
                      listrecord.formRecordList[2].records[i].record[j].value;
                  } else if (j === 1) {
                    if (
                      listrecord.formRecordList[2].records[i].record[j].type ===
                      "reference"
                    ) {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value.value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: true,
                        table: listrecord.formRecordList[1].table.name,
                        r_id: record_id,
                      });
                    } else {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: true,
                        table: listrecord.formRecordList[1].table.name,
                        r_id: record_id,
                      });
                    }
                  } else {
                    if (
                      listrecord.formRecordList[2].records[i].record[j].type ===
                      "reference"
                    ) {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value.value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: false,
                        table: listrecord.formRecordList[1].table.name,
                        r_id: record_id,
                      });
                    } else {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: false,
                        table: listrecord.formRecordList[1].table.name,
                        r_id: record_id,
                      });
                    }
                  }
                }
                va_ll.push({ colr: in_vl });
              }
              columnarry.push({ heading: hd, rcd: va_ll });
              var cla = JSON.parse(
                JSON.stringify(listrecord.formRecordList[9].column)
              );
              var choicearry = [];
              for (
                var z = 0;
                z < listrecord.formRecordList[9].column.length;
                z++
              ) {
                choicearry.push({
                  id: listrecord.formRecordList[9].column[z].id,
                  name: listrecord.formRecordList[9].column[z].name,
                  label: listrecord.formRecordList[9].column[z].label,
                  value: {
                    id: listrecord.formRecordList[9].column[z].id,
                    name: listrecord.formRecordList[9].column[z].name,
                  },
                  choice_order: z * 100 + "",
                  form_column_id: "",
                });
              }
              cla.unshift({ label: "None", name: "none" });
              setPageError(false);
              setChoice_mn(choicearry);
              setCol_mn(cla);
              // setFiltArray(fltarr);
              setTimeline(tmln);
            }
          }
        }
      });
  };

  const callRefFieldList = (tabId, call_check) => {
    var filterstate = filtRefArray;
    var farray = [];
    if (
      filtRefArray === "" ||
      filtRefArray === "undefined" ||
      filtRefArray === undefined ||
      filtRefArray === "null" ||
      filtRefArray === null
    ) {
      farray.push(filt);
      filterstate = farray;
    } else {
      if (filtRefArray instanceof Array) {
        farray = filtRefArray;
        filterstate = farray;
      } else {
        farray = filtRefArray.filter;
        filterstate = farray;
      }
    }
    var tablere = '{"formRecordList":[';
    tablere += '{"application":{"id":"","name":"autoportal"}}';
    tablere += ',{"table":{"id":"' + tabId + '","name":"","label":""}}';
    tablere += ',{"records":[]}';
    tablere +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    tablere += ',{"sort":{"asc":"true","column":"id","init":"true"}}';
    tablere += ',{"filter":' + JSON.stringify(filterstate) + "}";
    tablere += ',{"timeLine":"' + timeline + '"}]}';

    setRefList(tablere, call_check);
  };

  const setRefList = (tablere, call_check) => {
    axios
      .post(loca + "/lom/get/multiple/record", tablere.toString(), {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then((resp) => {
        const listrecord = resp.data;
        console.log(listrecord, tablere.toString());

        var columnarry = [];
        var hd = [];
        var va_ll = [];
        if (listrecord !== "") {
          if ("Error" in listrecord) {
            setLoading(false);
            setPageError(true);
            setError(listrecord.Error);
          } else {
            var scnd = listrecord.formRecordList[2];
            if ("Error" in scnd) {
              if (scnd.Error === "No record found.") {
                // this.setState({ list: [] });
                // setlist([]);
                toast("No record found", { type: "error" });
              }
            } else {
              var page_clicked1 = parseInt(
                listrecord.formRecordList[3].page.page_clicked
              );
              var page_count1 = parseInt(
                listrecord.formRecordList[3].page.page_count
              );
              var page_records1 =
                listrecord.formRecordList[3].page.page_records;
              var record_count1 = parseInt(
                listrecord.formRecordList[3].page.record_count
              );

              var flt = listrecord.formRecordList[5];
              var fltarr = listrecord.formRecordList[5].filter;
              var tmln = listrecord.formRecordList[6].timeLine;
              var filterString = "";
              var leng = fltarr.length;
              for (var f = 0; f < fltarr.length; f++) {
                if (leng === 1 && fltarr[f].cl === "") {
                  break;
                } else {
                  if (f > 0) {
                    filterString += fltarr[f].af + " ";
                  }
                  filterString += fltarr[f].cl + " ";
                  filterString += fltarr[f].mc + " ";
                  filterString += fltarr[f].an + " ";
                }
              }

              for (
                var i = 0;
                i < listrecord.formRecordList[2].records.length;
                i++
              ) {
                var in_vl = [];
                var record_id;
                for (
                  var j = 0;
                  j < listrecord.formRecordList[2].records[i].record.length;
                  j++
                ) {
                  if (i === 0) {
                    if (j === 0) {
                    } else {
                      hd.push({
                        label:
                          listrecord.formRecordList[2].records[i].record[j]
                            .label,
                        name: listrecord.formRecordList[2].records[i].record[j]
                          .name,
                      });
                    }
                  }
                  if (j === 0) {
                    record_id =
                      listrecord.formRecordList[2].records[i].record[j].value;
                  } else if (j === 1) {
                    if (
                      listrecord.formRecordList[2].records[i].record[j].type ===
                      "reference"
                    ) {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value.value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: true,
                        table: listrecord.formRecordList[1].table.name,
                        r_id: record_id,
                      });
                    } else {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: true,
                        table: listrecord.formRecordList[1].table.name,
                        r_id: record_id,
                      });
                    }
                  } else {
                    if (
                      listrecord.formRecordList[2].records[i].record[j].type ===
                      "reference"
                    ) {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value.value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: false,
                        table: listrecord.formRecordList[1].table.name,
                        r_id: record_id,
                      });
                    } else {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: false,
                        table: listrecord.formRecordList[1].table.name,
                        r_id: record_id,
                      });
                    }
                  }
                }
                va_ll.push({ colr: in_vl });
              }
              columnarry.push({ heading: hd, rcd: va_ll });
              var cla = JSON.parse(
                JSON.stringify(listrecord.formRecordList[9].column)
              );
              var choicearry = [];
              for (
                var z = 0;
                z < listrecord.formRecordList[9].column.length;
                z++
              ) {
                choicearry.push({
                  id: listrecord.formRecordList[9].column[z].id,
                  name: listrecord.formRecordList[9].column[z].name,
                  label: listrecord.formRecordList[9].column[z].label,
                  value: listrecord.formRecordList[9].column[z].name,
                  choice_order: z * 100 + "",
                  form_column_id: "",
                });
              }
              cla.unshift({ label: "None", name: "none" });
              // setTabLabel(listrecord.formRecordList[1].table.label);
              setPageError(false);
              setChoice_ref_mn(choicearry);
              setCol_mn_ref(cla);
              setTimeline(tmln);
            }
          }
        }
      });
  };

  const checkSelectedProperties = (data) => {
    let itemFeild;
    let itemFeildVal;
    for (let j = 0; j < data.length; j++) {
      console.log(data[j]);
      if (data[j].type === "reference" && "group_key_value" in data[j].value) {
        itemFeildVal = data[j].value.group_key_value;
      }
      console.log(data[j].type);
      if (data[j].type === "key_value") {
        console.log(data[j]);
        itemFeild = data[j];
        let sp = JSON.parse(JSON.stringify(data[j].value));
        setKeyValueJson(sp);
      }
    }
    itemFeild.value = itemFeildVal;
    setRecord(data);
    return;
  };

  const setPolicy = (frecord, policyId, ob, reverse) => {
    console.log(" setPolicy ", policyaction, reverse);
    let arr = defUiValid;
    // if (!(reverse === "false")) {
    if (!(reverse === "false")) {
      for (let t = 0; t < policyaction.length; t++) {
        for (let n = 0; n < frecord.length; n++) {
          if (
            policyId === policyaction[t].policy_id &&
            policyaction[t].form_column_id === frecord[n].id
          ) {
            let foundObject = arr.find((obj) => obj.id === frecord[n].id);
            if (!foundObject) {
              arr.push({
                id: frecord[n].id,
                uivalid: JSON.parse(JSON.stringify(frecord[n].uivalid)),
                name: ob,
              });
            }
          }
        }
      }
    }
    console.log("arr", arr);
    setDefUiValid(arr);
    for (let t = 0; t < policyaction.length; t++) {
      if (policyId === policyaction[t].policy_id) {
        for (let j = 0; j < frecord.length; j++) {
          if (frecord[j].id === policyaction[t].form_column_id) {
            if (
              policyaction[t].visible !== "null" &&
              policyaction[t].visible !== "leave"
            ) {
              frecord[j].uivalid.visible = policyaction[t].visible;
            }
            if (
              policyaction[t].read_only !== "null" &&
              policyaction[t].read_only !== "leave"
            ) {
              frecord[j].uivalid.read_only = policyaction[t].read_only;
            }
            if (
              policyaction[t].mandatory !== "null" &&
              policyaction[t].mandatory !== "leave"
            ) {
              frecord[j].uivalid.mandatory = policyaction[t].mandatory;
            }
          }
        }
      }
    }
    // }
  };

  const deleteImage = (objj) => {
    objj.localimg = true;
    formChangefn("", objj.index, objj.name, objj.type, objj.id, false);
  };

  const launchChoose = () => {
    let l = document.querySelector(".ourCustomized");
    console.log(l, "clciked");
    l.click();
  };

  const handleFileChange = (event, objj) => {
    if (event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      let uarr = "images/" + selectedFile.name;
      const imageURL = URL.createObjectURL(selectedFile);
      let tmp = [];
      if (imgObj) {
        tmp = imgObj;
      }
      console.log(imgObj);
      tmp.push(uarr);
      setImgObj([...tmp]);
      let frecord = record;
      frecord[objj.index].value = imageURL;
      frecord[objj.index].localimg = true;
      setRecord([...frecord]);
      formChangefn(imageURL, objj.index, objj.name, objj.type, objj.id, false);
      multipart.set("path", null);
      multipart.append("files", event.target.files[0]);
      console.log(multipart);
    }
  };

  const uploadImage = (rcd, ind, path) => {
    console.log(multipart.get("files"), rcd, tableName.current, imageloca);
    multipart.set("userId", userDetails.id);
    console.log(path);
    multipart.set("path", path);
    multipart.set("compress", false);
    if (multipart.has("files")) {
      axios
        .post(imageloca + `upload/images/`, multipart, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
        .then(
          (res) => {
            let resp = res.data;
            console.log(resp);
            var imgs = "";
            // if (path) {
            //   imgs = "images/" + path + "/" + resp[ind];
            // } else {
            //   imgs = "images/" + resp[ind];
            // }
            rcd.value = resp;
            rcdRef.current = imgs;
            console.log(rcd);

            // return imgs;
          },
          (error) => {
            console.log(error);
          }
        );
    }
  };

  const removeAction = (frecord, ob) => {
    let arr = [];

    for (let o = 0; o < frecord.length; o++) {
      for (let p = 0; p < defUiValid.length; p++) {
        if (frecord[o].id === defUiValid[p].id && ob === defUiValid[p].name) {
          frecord[o].uivalid = defUiValid[p].uivalid;
          arr.push(defUiValid[p]);
        }
      }
    }
    let newArray2 = defUiValid.filter(
      (obj2) => !arr.some((obj1) => obj1.id === obj2.id)
    );
    setDefUiValid(newArray2);
  };

  const getSecondaryFields = (index, ob, type, id, vl) => {
    // console.log(index, ob, type, id, vl);
    // console.log(secondarycallsCols.get(ob));
    let arr = secondarycallsCols.get(ob);

    let pt = "";
    if (secondarycallsCols.get(ob)) {
      var frecord = record;
      for (let p = 0; p < arr.length; p++) {
        if (p > 0) pt += ",";
        pt += JSON.stringify(arr[p]);
      }
      console.log(arr, pt);
      // let st=`{tabName:"${vl} , id:"${id} , columns:${pt}}`
      let st = { tabname: vl, id: id, columns: arr };

      console.log("kk ", JSON.stringify(st));
      axios
        .post(loca + "/lom/get/secondary/record", st, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
          },
        })
        .then((resp) => {
          console.log(resp.data);
          let data = resp.data;
          let colsRecord = data.colsRecord;

          console.log("secondaryStartIndex", secondaryStartIndex);
          for (let k = 0; k < colsRecord.length; k++) {
            for (
              let start = secondaryStartIndex;
              start < frecord.length;
              start++
            ) {
              if (frecord[start].formView.path === colsRecord[k].path) {
                frecord[start].value = colsRecord[k].value;
                if (frecord[start].type === "reference") {
                  frecord[start].rcd_info = "true";
                }
              }
            }
          }
          setRecord([...frecord]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const formChangefn = (
    vl,
    index,
    ob,
    type,
    id,
    vrf,
    obj,
    outerIndex,
    innerIndex,
    nme,
    props,
    depCol,
    readonly
  ) => {
    let frecord = record;
    //  setting inital states before changing values
    fcf_intital(index, ob, vl, type, id);

    //  runs ui policy
    fcf_Uipolicy(ob, vl, frecord);

    if (type === "reference") {
      fcf_reference(index, vl, id, type, ob, obj, vrf, props, depCol, frecord);
    } else if (type === "depend_table") {
      console.log(vl, id, ob, frecord[index].name);
      if (frecord[index].name === ob) {
        console.log(vl, id);
        if (vl === "") {
          frecord[index].value.name = "";
          frecord[index].value.id = "0";
        } else {
          frecord[index].value.name = vl;
          frecord[index].value.id = id;
        }
        frecord[index].verified = "";
        frecord[index].rcd_info = "true";
        console.log(frecord[index]);

        setRecord([...frecord]);
      }
    } else if (type === "choice") {
      console.log(vl, readonly);
      if (readonly === "false") {
        console.log(vl);
        frecord[index].value = vl;
        setRecord([...frecord]);
      }
    } else if (type === "dynamic_key_value") {
      fcf_dynamicKeyValue(vl, index, innerIndex, outerIndex, frecord);
    } else if (type === "String") {
      fcf_string(index, vl, ob, frecord);
    } else if (type === "key_value") {
      if (frecord[index].name === ob) {
        console.log(" key value check", nme, vl);
        const updatedProperties = [...keyValueJson.properties];
        updatedProperties[outerIndex] = {
          name: nme,
          value: vl,
        };
        setKeyValueJson({
          properties: updatedProperties,
        });
      }
    }
    // else if (type === "dynamic_key_value") {
    //   if (frecord[index].name === ob) {
    //     if (nme === "name") {
    //       frecord[index].value.properties[outerIndex][nme] = vl;
    //     }
    //     if (nme === "choice" && innerIndex !== null) {
    //       console.log(outerIndex, innerIndex);
    //       frecord[index].value.properties[outerIndex][nme][innerIndex].value =
    //         vl;
    //     }
    //     console.log(frecord);
    //     setRecord([...frecord]);
    //   }
    // }
    else {
      // console.log(type, ob, frecord[index], index, frecord);
      if (frecord[index].name === ob) {
        // console.log(index, vl);
        frecord[index].value = vl;
        setRecord([...frecord]);
      }
    }

    // ui script for onchange
    fcf_Uiscript(ob, vl, obj);

    // console.log(record);
  };

  // form Change Function (fcf) sub functions

  const fcf_intital = (index, name, value, object, type, refId, frecord) => {
    if (isBackAlertShow.current) {
      setShouldBlock(true);
    } else {
      isBackAlertShow.current = true;
    }

    if (name === "form_table_id" && value === "") {
      setChoice_mn([]);
    }

    if (name === "item_details_id") {
      let a = frecord.findIndex((e) => e.name === name);
      index = a;
    }

    if (type === "reference" && value.length < 3) {
      let a = frecord.findIndex(
        (e) => e.depend_column === object.name && e.name !== object.name
      );

      console.log(a);
      if (a >= 0) {
        frecord[a].dep_choice = [];
        setRecord(frecord);
      }
    }

    if (tableName.current === "reference_filter") {
      if (name === "form_column_id") {
        console.log("check column");
        callColumnByColId(refId);
      }
    }
    // console.log(" record ", frecord);

    // console.log(" policyFilter", policyFilter);
    // console.log(" viewList", viewList);
  };

  const fcf_Uipolicy = (colName, value, frecord) => {
    let selectedId = "";
    for (let t = 0; t < viewList?.length; t++) {
      if (viewList[t].selected === "true") selectedId = viewList[t].id;
    }

    if (policyFilter != undefined) {
      for (let s = 0; s < policyFilter.length; s++) {
        let pf = policyFilter[s].filter;
        let pfId = policyFilter[s].id;
        let global = policyFilter[s].global;
        let form_view_id = policyFilter[s].form_view_id;
        let reverse = policyFilter[s].reverse_if_false;

        if (
          pf !== undefined &&
          pf !== "" &&
          (global === "true" || selectedId === form_view_id)
        ) {
          console.log(pf);
          var pfilt = pf.filter;
          for (let i = 0; i < pfilt.length; i++) {
            let p_policy = pfilt;
            let name = pfilt[i].co;
            let type = pfilt[i].ct;
            let sign = pfilt[i].mc;
            let answer;
            if (type === "reference") {
              answer = pfilt[i].rf.value;
            } else if (type === "depend_table") {
              answer = pfilt[i].dc.value;
            } else {
              answer = pfilt[i].an;
            }
            console.log(" name colName ", name, colName);
            console.log(" answer colName ", answer, value);
            if (name == colName) {
              let arr = defUiValid;
              console.log("check policy", sign);
              console.log("defUiValid g ", defUiValid);
              switch (sign) {
                case "is":
                  console.log(answer, value);
                  if (answer == value) {
                    setPolicy(frecord, pfId, colName, reverse);
                    // setPolicy(frecord, pfId, colName, reverse);
                  } else {
                    removeAction(frecord, colName);
                    // removeAction(frecord, colName);
                  }
                  break;
                case ">":
                  if (answer > value) {
                    if (
                      !isNaN(Date.parse(answer)) &&
                      !isNaN(Date.parse(value))
                    ) {
                    } else {
                    }
                    setPolicy(frecord, pfId, colName);
                  } else {
                    removeAction(frecord, colName);
                  }
                  break;
                case "<":
                  if (answer < value) {
                    setPolicy(frecord, pfId, colName);
                  } else {
                    removeAction(frecord);
                  }
                  break;

                default:
                  break;
              }
            }
          }
        }
      }
    }
  };

  const fcf_reference = (
    index,
    value,
    refId,
    type,
    colName,
    object,
    verify,
    properties,
    depend_column,
    frecord
  ) => {
    console.log(refId, tableName.current, frecord[index]);
    if (frecord[index].type.includes("group_key_value")) {
      if (value.properties) {
        keyValueJson.properties = [];
        let len = value.properties.length;
        for (let i = 0; i < len; i++) {
          keyValueJson.properties.push({
            name: "",
            value: "",
          });
        }
        setKeyValueJson(keyValueJson);
      }
      for (let i = 0; i < frecord.length; i++) {
        if (frecord[i].type === "key_value") {
          frecord[i].value = properties;
          break;
        }
      }
      console.log(frecord);
      setRecord([...frecord]);
    }

    if (tableName.current === "reference_filter") {
      console.log("ref filt check");
      if (colName === "form_column_id") {
        callColumnByColId(refId);
      }
      if (colName === "form_table_id") {
        console.log(value);
        callTableColumn(value, false, depend_column);
      }
    } else if (
      (colName === "form_table_id" ||
        colName === "u_form_table_id" ||
        object === "form_table") &&
      tableName.current !== "table_access" &&
      verify
    ) {
      // if (depend_column === colName){
      console.log("obbbbbbbb");
      callTableColumn(value, false, depend_column);
      // }
    } else {
      if (tableName.current === "report") {
        callColumnByColId(refId);
      }
    }
    console.log(colName, refId);

    if (colName === "reference_id" && refId !== "0") {
      callRefFieldList(refId, true);
    }
    if (verify === false) {
      frecord[index].clicked = false;
    }
    if (value.length > 2) {
      if (verify === true) {
        if (frecord[index].name === colName) {
          console.log("iffffff", refId, index, frecord);
          frecord[index].value.name = value;
          frecord[index].clicked = true;
          frecord[index].value.id = refId;
          frecord[index].verified = "verified";
          frecord[index].rcd_info = "true";
          console.log(frecord);

          // index colName type
          getSecondaryFields(
            index,
            colName,
            type,
            refId,
            frecord[index]?.refTable?.value
          );
          setRecord([...frecord]);
          setRefRecord({});
          // validationfn(value, index, colName, type, id);
        }
      } else {
        console.log("hjjhjhjhjjhj ");
        if (frecord[index].name === colName) {
          frecord[index].value.name = value;
          frecord[index].value.id = refId;
          frecord[index].rcd_info = "true";
          // console.log("checking2");
          // setState({ record: frecord });
          getSecondaryFields(
            index,
            colName,
            type,
            refId,
            frecord[index]?.refTable?.value
          );
          console.log("rcd_info", frecord);
          setRecord([...frecord]);
        }

        var veri = '{"referenceRecord":[{"columnid":"' + refId + '"},';

        veri += '{"tabvalue":"' + value + '"}]}';
        var rff = [];
        axios
          .post(loca + `/lom/reference/record`, veri.toString(), {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + token,
            },
          })
          .then(
            (resp) => {
              const refrencercd = resp.data;
              console.log("ref : " + JSON.stringify(refrencercd));
              if ("Error" in refrencercd) {
              } else {
                rff = {
                  index: index,
                  record: refrencercd.referenceRecordList[2].records,
                };
                if (refrencercd.referenceRecordList[2].records.length > 0) {
                  frecord[index].verified = "";
                } else {
                  frecord[index].verified = "unverified";
                }
                frecord[index].value.name = value;
                frecord[index].value.id = refId;

                setShowContainer(true);
                setRefRecord({ ...rff });
                setRecord([...frecord]);
              }
            },
            (error) => {
              console.log(error);
            }
          );
      }
    } else {
      if (frecord[index].name === colName) {
        frecord[index].value.name = value;
        if (value === "") {
          frecord[index].value.id = "0";
        } else {
          frecord[index].value.id = refId;
        }
        frecord[index].verified = "";
        setRecord([...frecord]);
        setRefRecord({});
      }
    }
  };

  const fcf_dynamicKeyValue = (
    value,
    index,
    keyInnerIndex,
    keyOuterIndex,
    frecord
  ) => {
    let props = value;
    let abc = frecord[index].value[keyOuterIndex];
    if (keyInnerIndex === 0) {
      // console.log(
      //   frecord[index].value,
      //   Object.keys(abc),
      //   Object.keys(abc).includes(props)
      // );
      for (let i = 0; i < frecord[index].value.length; i++) {
        const ob = frecord[index].value[i];
        if (Object.keys(ob).includes(props)) {
          return;
        }
      }
      var key = Object.keys(abc)[0];
      var val = Object.values(abc)[0];
      delete abc[key];
      if (val) {
        abc[props] = val;
      } else {
        abc[props] = "";
      }
    } else {
      var key = Object.keys(abc)[0];
      if (key) {
        abc[key] = props;
      } else {
        abc[""] = props;
      }
    }
    setRecord([...frecord]);
  };

  const fcf_string = (index, value, colName, frecord) => {
    if (frecord[index].name === colName && value.length > 0) {
      if (frecord[index].validation === "number") {
        if (/^[0-9]*$/.test(value)) {
          frecord[index].value = value;
          setRecord([...frecord]);
        } else {
          value = frecord[index].value;
        }
      } else if (frecord[index].validation === "name") {
        if (/^[a-z][a-z0-9_]*$/.test(value)) {
          frecord[index].value = value;
          setRecord([...frecord]);
        } else {
          value = frecord[index].value;
        }
      } else if (frecord[index].validation === "numbersAndCharacters") {
        if (/^[a-z0-9_]*$/.test(value)) {
          frecord[index].value = value;
          setRecord([...frecord]);
        } else {
          value = frecord[index].value;
        }
      } else if (frecord[index].validation === "character") {
        if (/^[a-zA-Z\s]+$/.test(value)) {
          frecord[index].value = value;
          setRecord([...frecord]);
        } else {
          value = frecord[index].value;
        }
      } else if (frecord[index].validation === "withoutSpecialCharacter") {
        if (/^[_A-z0-9\s]*((-|\s)*[_A-z0-9])*$/.test(value)) {
          frecord[index].value = value;
          setRecord([...frecord]);
        } else {
          value = frecord[index].value;
        }
      } else if (frecord[index].validation === "zipCode") {
        if (/^[0-9]{5}(?:-[0-9]{4})?$/.test(value)) {
          frecord[index].value = value;
          setRecord([...frecord]);
        } else {
          value = frecord[index].value;
        }
      } else if (frecord[index].validation === "decimal") {
        if (/^\d*\.?\d*$/.test(value)) {
          frecord[index].value = value;
          setRecord([...frecord]);
        } else {
          value = frecord[index].value;
        }
      } else if (frecord[index].validation === "app_code") {
        if (/^[a-z][a-z0-9]*$/.test(value)) {
          frecord[index].value = value;
          setRecord([...frecord]);
        } else {
          value = frecord[index].value;
        }
      } else if (frecord[index].validation === "ipAddress") {
        if (/((([0-9a-fA-F]){1,4})\\:){7}([0-9a-fA-F]){1,4}$/.test(value)) {
          frecord[index].value = value;
          setRecord([...frecord]);
          //Ipv4 = (([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\\.){3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])
          //Ipv6 = ((([0-9a-fA-F]){1,4})\\:){7}([0-9a-fA-F]){1,4}
        } else {
          value = frecord[index].value;
        }
      } else if (frecord[index].validation === "all") {
        frecord[index].value = value;
        setRecord([...frecord]);
      } else {
        console.log("its work");
        // if (/^[a-zA-Z0-9_\s]*$/.test(value)) {
        frecord[index].value = value;
        setRecord([...frecord]);
        // }
      }
    } else {
      frecord[index].value = value;
      setRecord([...frecord]);
    }
  };

  const fcf_Uiscript = (colName, value, object) => {
    console.log("onChange");

    for (let i = 0; i < uiScript.length; i++) {
      let field = uiScript[i].field.name;
      let func = uiScript[i].script;
      let type = uiScript[i].type;
      if (field === colName && type === "onchange") {
        console.log("runnner", func);
        setTimeout(() => {
          onChange(func, value, object);
        }, 100);
      }
    }
  };

  const fieldverify = (type, vl) => {
    if (type === "String" || type === "filter") {
      // if (/^[a-zA-Z_\w]*$/g.test(vl)) {
      return "verified";
      // } else {
      //   return "unverified";
      // }
    } else if (type === "email") {
      console.log("check email");
      if (/^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(vl)) {
        return "verified";
      } else {
        setVerifyError("Please verify your email!");
        return "unverified";
      }
    } else if (type === "int") {
      // const regex = new RegExp(/[0-9]/);
      if (/^[0-9]*$/.test(vl)) {
        // console.log("verified");
        return "verified";
      } else {
        // console.log("unverified...");

        return "unverified";
      }
    } else if (type === "datetime") {
      // if (
      //   /^(?:(?:(?:0?[13578]|1[02]|(?:Jan|Mar|May|Jul|Aug|Oct|Dec))(\/|-|\.)31)\1|(?:(?:0?[1,3-9]|1[0-2]|(?:Jan|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec))(\/|-|\.)(?:29|30)\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:(?:0?2|(?:Feb))(\/|-|\.)(?:29)\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:(?:0?[1-9]|(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep))|(?:1[0-2]|(?:Oct|Nov|Dec)))(\/|-|\.)(?:0?[1-9]|1\d|2[0-8])\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/.test(
      //     vl
      //   )
      // ) {
      //   return "verified";
      // } else {
      //   return "unverified";
      // }
      return "verified";
    }
  };

  const validationfn = (vl, index, ob, type, id) => {
    var formrecord = record;
    console.log(vl, index, ob, type, id);
    if (type === "reference") {
      if (vl !== "") {
        console.log(vl);
        var veri = '{"referenceRecord":[{"columnid":"' + id + '"},';
        veri += '{"tabvalue":' + JSON.stringify(vl) + "}]}";
        console.log(veri);
        axios
          .post(loca + `/lom/reference/verify`, veri.toString(), {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + token,
            },
          })
          .then((resp) => {
            const refrencercd = resp.data;
            console.log(refrencercd);
            if (refrencercd.Result === "Unverified") {
              formrecord[index].verified = "unverified";
              // this.setState({ record: formrecord });
              setRecord([...formrecord]);
            } else {
              formrecord[index].verified = "verified";
              // this.setState({ record: formrecord, refrecord: [] });
              setRecord([...formrecord]);
              setRefRecord([]);
            }

            // this.setState({ record: formrecord });
            setRecord([...formrecord]);

            return;
          });
      } else {
        formrecord[index].verified = "initial";
        // this.setState({ record: formrecord });
        setRecord([...formrecord]);
      }
    } else {
      var minLength = formrecord[index].uivalid.min_length;
      if (minLength !== 0 && vl.length < minLength && vl.length != 0) {
        setVerifyError("Length of character or number should be " + minLength);
        formrecord[index].verified = "unverified";
      } else {
        if (formrecord[index].name === ob) {
          if (type === "int" || vl !== "") {
            formrecord[index].verified = fieldverify(
              formrecord[index].type,
              vl
            );
          } else {
            formrecord[index].verified = "initial";
          }
        }
      }
      // this.setState({ record: formrecord });

      setRecord([...formrecord]);
    }
    // if (type === "boolean") {
    // } else {
    //   // setCheck(!check);
    // }
  };

  const changeView = (index) => {
    let stdView = viewList[index];
    axios
      .post(loca + `/lom/change/view`, stdView, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then((resp) => {
        let rsp = resp.data;
        if ("Error" in rsp) {
          console.log(rsp.Error);
        } else {
          setViewList(resp);
          refresh();
        }
      });
  };

  // const callForClient = (rcd, nam, btn, ij, btntype) => {
  //   let app = "";
  //   for (let i = 0; i < rcd.length; i++) {
  //     if (rcd[i].name === "application") {
  //       app = rcd[i].value;
  //     }
  //   }
  //   if (app === "") {
  //     app = mainRecord.formRecord[0].application.value;
  //   }
  //   var jn = {
  //     button: nam,
  //     recordId: rid.current,
  //     table: tableName.current,
  //     application: app,
  //     type: "manual",
  //     script: "",
  //   };
  //   axios
  //     .post(loca + btn[ij].webUrl, jn, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         authorization: "Bearer " + token,
  //       },
  //     })
  //     .then((resp) => {
  //       const rcd = resp.data;
  //       if (rcd !== "") {
  //         if ("Error" in rcd) {
  //           setSubmitLoading(false);
  //           setPageError(true);
  //           setError(rcd.Error);
  //         } else {
  //           setSubmitLoading(false);
  //           var msg = rcd.message;
  //           if (msg !== "") {
  //             setPageMessage(true);
  //             setMessage(msg);
  //             getFormDetails(true);
  //             checkMenu.current += false;
  //           }
  //         }
  //       }
  //     })
  //     .finally(() => {
  //       console.log("check finally");
  //       setIsClick(false);
  //     });
  // };

  const callForClient = (rcd, nam, btn, ij, btntype) => {
    let script = btn[ij].script;
    console.log(script);
    onSubmit(script);
  };

  const callForScript = (nam, mnrecord) => {
    axios
      .post(loca + "/lom/serverrule/" + nam, mnrecord, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let rcdd = resp.data;

          if (rcdd !== "" && rcdd !== null) {
            if ("Error" in rcdd) {
              setModal(false);
              toast(rcdd.Error, {
                position: "top-center",
                theme: "colored",
                type: "error",
              });
            } else if ("Message" in rcdd) {
              refresh();
              toast(rcdd.Message, {
                position: "top-center",
                theme: "colored",
                type: "success",
              });
            } else {
              toast("Unexpected Error", {
                position: "top-center",
                theme: "colored",
                type: "error",
              });
            }
          }
        },
        (error) => {
          console.log(error);
          toast("Something went wrong", {
            position: "top-center",
            theme: "colored",
            type: "error",
          });
        }
      )
      .finally(() => {
        setIsClick(false);
        setSubmitLoading(false);
        console.log("isClick finally");
      });
  };

  const callForOthers = (mnrecord, btn, ij, nam, app) => {
    console.log(nam);

    let loc = "";
    if (parentApp.current === "Marketplace") {
      loc = "http://localhost:8084";
    } else {
      loc = loca;
    }
    let bd;
    if (nam === "Approve" || nam === "Reject") {
      bd = {
        button: nam,
        recordId: rid.current,
        table: tableName.current,
        application: app,
        type: "manual",
        script: "",
      };
    } else {
      bd = mnrecord;
    }
    console.log(bd);

    axios
      .post(loc + btn[ij].webUrl, bd, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": "marketplace",
        },
      })
      .then(
        (resp) => {
          let rcdd = resp.data;
          console.log(rcdd);

          if (rcdd !== "" && rcdd !== null) {
            if ("Error" in rcdd) {
              setModal(false);
              toast(rcdd.Error, {
                position: "top-center",
                theme: "colored",
                type: "error",
              });
            } else if ("Inform" in rcdd) {
              toast(rcdd.Inform, {
                position: "top-center",
                theme: "colored",
                type: "info",
              });
            } else {
              let r_id = 0;
              if ("appRecord" in rcdd) {
                r_id = rcdd.appRecord[2].record[0].value;
              } else if ("columnRecord" in rcdd) {
                r_id = rcdd.columnRecord[2].record[0].value;
              } else if ("formRecord" in rcdd) {
                r_id = rcdd.formRecord[2].record[0].value;
              } else if ("choiceRecord" in rcdd) {
                r_id = rcdd.choiceRecord[2].record[0].value;
              } else if ("labelRecord" in rcdd) {
                r_id = rcdd.labelRecord[2].record[0].value;
              } else if ("buttonRecord" in rcdd) {
                r_id = rcdd.buttonRecord[2].record[0].value;
              } else if ("tableRecord" in rcdd) {
                r_id = rcdd.tableRecord[2].record[0].value;
              } else if ("typeRecord" in rcdd) {
                r_id = rcdd.typeRecord[2].record[0].value;
              } else if ("uivalidationRecord" in rcdd) {
                r_id = rcdd.uivalidationRecord[2].record[0].value;
              } else if ("languageRecord" in rcdd) {
                r_id = rcdd.languageRecord[2].record[0].value;
              } else if ("userRecord" in rcdd) {
                r_id = rcdd.userRecord[1].record[0].value;
              }
              // setLoading(false);

              if (btn[ij].returnWebLocation === "NextPage") {
                localStorage.removeItem("pageClicked");
                console.log(tableName.current);
                navigation({
                  pathname: user + "/list",
                  search: createSearchParams({
                    parentApplication: parentApp.current,
                    tableName: tableName.current,
                  }).toString(),
                });
              } else {
                console.log(checkMenu.current);
                // if (checkMenu.current || ) {
                getFormDetails();
                checkMenu.current += false;
                // } else {
                //   searchParam.set("rid", r_id);
                //   searchParam.set("tableName", tableName.current);
                //   searchParam.set("rty", "record");
                //   setSearchParam(searchParam);
                //   // getRecordForm(r_id, "");
                // }
              }
              if (nam.toLowerCase() === "insert") {
                toast("Insert Successfully", {
                  position: "top-center",
                  theme: "colored",
                  type: "success",
                  style: {
                    marginBottom: userDetails.OS !== "null" ? 12 : 0,
                  },
                });
              } else if (nam.toLowerCase() === "update") {
                setModal(false);
                toast("Updated Successfully", {
                  position: "top-center",
                  theme: "colored",
                  type: "success",
                  style: {
                    marginBottom: userDetails.OS !== "null" ? 12 : 0,
                  },
                });
              } else if (nam.toLowerCase() === "delete") {
                setModal(false);
                toast("Deleted Successfully", {
                  position: "top-center",
                  theme: "colored",
                  type: "success",
                  style: {
                    marginBottom: userDetails.OS !== "null" ? 12 : 0,
                  },
                });
              } else {
                toast("Successfull", {
                  position: "top-center",
                  theme: "colored",
                  type: "success",
                });
              }
            }
          } else {
            toast("Unexpected Error", {
              position: "top-center",
              theme: "colored",
              type: "error",
            });
          }
        },
        (error) => {
          console.log(error);
          toast("Something went wrong", {
            position: "top-center",
            theme: "colored",
            type: "error",
          });
        }
      )
      .catch(() => {
        console.log("catch block");
      })
      .finally(() => {
        setSubmitLoading(false);
        // setLoading(false);
        setIsClick(false);
        console.log("isClick finally");
      });
  };

  const checkMandatories = (rcd, mandatory, unverified, imggg) => {
    console.log("inside checkMandatories");

    for (var i = 0; i < rcd.length; i++) {
      if (rcd[i].uivalid.visible === "true" && rcd[i].type !== "boolean") {
        if (rcd[i].uivalid.mandatory === "true") {
          if (rcd[i].value === "") {
            mandatory.push(rcd[i].label.name);
          } else if (
            (rcd[i].type === "reference" || rcd[i].type === "depend_table") &&
            rcd[i].value.name === ""
          ) {
            mandatory.push(rcd[i].label.name);
          } else if (
            typeof rcd[i].value === "string" &&
            rcd[i].value.trim() === ""
          ) {
            mandatory.push(rcd[i].label.name);
          }
        }
        if (
          rcd[i].type === "String" ||
          rcd[i].type === "int" ||
          rcd[i].type === "email" ||
          rcd[i].type === "date"
        ) {
          var veri = rcd[i].verified;
          console.log("verif : " + veri);
          if (veri === "unverified") {
            unverified.push(rcd[i].label.name);
          }
        }
        if (
          rcd[i].type === "image_upload" &&
          rcd[i].value !== "" &&
          rcd[i].value.includes("blob")
        ) {
          // waitt = true;
          uploadImage(rcd[i], imggg, null);
          // imggg++;
        }
        if (rcd[i].type === "reference") {
          // var veri =refVerify(rcd[i].id,rcd[i].value);
          if (rcd[i].verified === "unverified") {
            unverified.push(rcd[i].label.name);
          }
        }
      }
    }
  };
  const finalFormSubmit = (btnRtype, btntype, rcd, mnrecord, btn, ij, nam) => {
    if (btnRtype === "html") {
      var ht_ml = "";
      var script = "";
      for (let i = 0; i < rcd.length; i++) {
        if (rcd[i].type === "html") {
          ht_ml = rcd[i].value;
        }
        if (rcd[i].name === "script") {
          script = rcd[i].value;
        }
      }
      navigation("/htmlcall", {
        state: { html: ht_ml, script: script },
      });
    } else if (btnRtype === "Grid") {
      navigation("/clientinforecord", {
        state: { rid: rid.current, tableName: tableName.current },
      });
    } else {
      //Check record type
      const keys = [
        "appRecord",
        "columnRecord",
        "formRecord",
        "choiceRecord",
        "labelRecord",
        "buttonRecord",
        "tableRecord",
        "typeRecord",
        "uivalidationRecord",
        "languageRecord",
        "userRecord",
      ];

      keys.forEach((key) => {
        if (key in mnrecord) {
          mnrecord[key][2].record = rcd;
        }
      });

      if (btn[ij].call_type === "client") {
        callForClient(rcd, nam, btn, ij, btntype);
      } else if (btn[ij].call_type === "run_script_server") {
        callForScript(nam, mnrecord);
      } else if (btn[ij].webUrl === "/update/json/export") {
        exportData(mnrecord, btn[ij].webUrl);
      } else {
        let app = "";
        for (let i = 0; i < rcd.length; i++) {
          if (rcd[i].name === "application") {
            app = rcd[i].value;
          }
        }
        callForOthers(mnrecord, btn, ij, nam, app);
      }
    }
  };
  const showMandatoryError = (mandatory, unverified) => {
    setSubmitLoading(false);
    setSubmitLoading(false);
    setIsClick(false);
    var error_String = "";
    if (mandatory.length > 0) {
      error_String += " Check mandatory fields not set: " + mandatory;
    }
    if (unverified.length > 0) {
      error_String += " Fields unverified:" + unverified;
    }
    setError(error_String);
    setPageError(true);
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  const callbtn = (nam) => {
    setIsClick(true);
    setSubmitLoading(true);
    setShouldBlock(false);
    var btn = button;
    var mnrecord = mainRecord;
    var rcd = record;
    var mandatory = [];
    var unverified = [];
    let imggg = 0;
    var btntype = "";
    var btnRtype = "";
    var foundbtn = false;

    for (let i = 0; i < uiScript?.length; i++) {
      let func = uiScript[i].script;
      let type = uiScript[i].type;
      if (type === "onsubmit") {
        onSubmit(func);
      }
    }
    checkMandatories(rcd, mandatory, unverified, imggg);
    for (var ij = 0; ij < btn.length; ij++) {
      if (btn[ij].value === nam) {
        btntype = btn[ij].returnWebLocation;
        btnRtype = btn[ij].recordReturnType;
        foundbtn = true;
        break;
      }
    }
    console.log("final form submit", btntype);
    if (foundbtn === true) {
      setPageError(false);
      setError("");
      setPageMessage(false);
      setMessage("");
      setTimeout(() => {
        if (mandatory.length === 0 && unverified.length === 0) {
          finalFormSubmit(btnRtype, btntype, rcd, mnrecord, btn, ij, nam);
        } else {
          showMandatoryError(mandatory, unverified);
        }
      }, 100);
    } else {
      setIsClick(false);
      setSubmitLoading(false);
      setShouldBlock(true);
    }
  };

  const setMSC = (val, index, name, type, id) => {
    formChangefn(val, index, name, type, id, true);
  };

  const setContextMenu = (val, x, y) => {
    console.log(" val x y", val, x, y);
    if (val === 2) {
      menuX.current = x;
      menuY.current = y;
      setShowContextMenu(true);
    } else {
      setShowContextMenu(false);
    }
  };

  const setContext = (val, x, y) => {
    if (val === 2) {
      menuX.current = x;
      menuY.current = y;
      setShowContext(true);
    } else {
      setShowContext(false);
    }
  };

  const generatePassword = () => {
    let frecord = record;
    let count = 0;
    var chars =
      "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var passwordLength = 8;
    var password = "";
    for (var i = 0; i < passwordLength; i++) {
      var randomNumber = Math.floor(Math.random() * chars.length);
      password += chars.substring(randomNumber, randomNumber + 1);
    }
    for (let i = 0; i < frecord.length; i++) {
      if (frecord[i].name === "password") {
        frecord[i].value = password;
        count++;
      }
      if (frecord[i].name === "reset_password") {
        frecord[i].value = "true";
        count++;
      }
      if (count == 2) {
        break;
      }
    }
    console.log(frecord);
    setRecord([...frecord]);
    setPasswordReadOnly(true);
  };

  // const getRecordForm = (r_id, tabName) => {
  //   // setLoading(true);
  //   let tabN = "";
  //   if (tabName !== "") {
  //     tabN = tabName;
  //   } else {
  //     tabN = tableName.current;
  //   }
  //   console.log("tableName.current " + tabN);
  //   axios
  //     .get(loca + `/lom/get/singlerecord/${tabN}/${r_id}`, {
  //       headers: {
  //         authorization: "Bearer " + token,
  //       },
  //     })
  //     .then(
  //       (resp) => {
  //         const s_rcd = resp.data;
  //         let rcd = [];
  //         if ("Error" in s_rcd) {
  //           navigation("/dashboard", { state: { error: s_rcd.Error } });
  //         } else {
  //           console.log(JSON.stringify(s_rcd));
  //           setMainRecord(s_rcd);

  //           if ("appRecord" in s_rcd) {
  //             rcd = s_rcd.appRecord[2].record;
  //             setButton(s_rcd.appRecord[3].button);
  //             setTabLabel(s_rcd.appRecord[1].table.label);
  //           } else if ("columnRecord" in s_rcd) {
  //             rcd = s_rcd.columnRecord[2].record;
  //             setButton(s_rcd.columnRecord[3].button);
  //             setTabLabel(s_rcd.columnRecord[1].table.label);
  //           } else if ("formRecord" in s_rcd) {
  //             rcd = s_rcd.formRecord[2].record;
  //             setButton(s_rcd.formRecord[3].button);
  //             setTabLabel(s_rcd.formRecord[1].table.label);
  //           } else if ("choiceRecord" in s_rcd) {
  //             rcd = s_rcd.choiceRecord[2].record;
  //             setButton(s_rcd.choiceRecord[3].button);
  //             setTabLabel(s_rcd.choiceRecord[1].table.label);
  //           } else if ("labelRecord" in s_rcd) {
  //             rcd = s_rcd.labelRecord[2].record;
  //             setButton(s_rcd.labelRecord[3].button);
  //             setTabLabel(s_rcd.labelRecord[1].table.label);
  //           } else if ("buttonRecord" in s_rcd) {
  //             rcd = s_rcd.buttonRecord[2].record;
  //             setButton(s_rcd.buttonRecord[3].button);
  //             setTabLabel(s_rcd.buttonRecord[1].table.label);
  //           } else if ("tableRecord" in s_rcd) {
  //             rcd = s_rcd.tableRecord[2].record;
  //             setButton(s_rcd.tableRecord[3].button);
  //             setTabLabel(s_rcd.tableRecord[1].table.label);
  //           } else if ("typeRecord" in s_rcd) {
  //             rcd = s_rcd.typeRecord[2].record;
  //             setButton(s_rcd.typeRecord[3].button);
  //             setTabLabel(s_rcd.typeRecord[1].table.label);
  //           } else if ("uivalidationRecord" in s_rcd) {
  //             rcd = s_rcd.uivalidationRecord[2].record;
  //             setButton(s_rcd.uivalidationRecord[3].button);
  //             setTabLabel(s_rcd.uivalidationRecord[1].table.label);
  //           } else if ("languageRecord" in s_rcd) {
  //             rcd = s_rcd.languageRecord[2].record;
  //             setButton(s_rcd.languageRecord[3].button);
  //             setTabLabel(s_rcd.languageRecord[1].table.label);
  //           } else if ("userRecord" in s_rcd) {
  //             rcd = s_rcd.userRecord[2].record;
  //             setButton(s_rcd.userRecord[3].button);
  //             setTabLabel(s_rcd.userRecord[1].table.label);
  //           } else {
  //             console.log("table s_rcd not found");
  //           }
  //         }
  //         var mmm = s_rcd.formRecord[2].record;

  //         i_d.current = mmm[0].value;
  //         let count = 0;
  //         let call_check = true;
  //         for (let i = 0; i < mmm.length; i++) {
  //           console.log(mmm[i].type);
  //           if (mmm[i].type === "filter" && mmm[i].value !== "") {
  //             setFiltArray(mmm[i].value.record);
  //             console.log(mmm[i].value);
  //             setTimeline(mmm[i].value.timeline);
  //             count++;
  //           }
  //           if (mmm[i].type === "multi_select" && mmm[i].value !== "") {
  //             setMscList(mmm[i].value.record);
  //             count++;
  //           }
  //           if (mmm[i].name === "form_table_id") {
  //             call_check = false;
  //             console.log("name : " + mmm[i].value.name);
  //             callFieldList(mmm[i].value.id, true);
  //             count++;
  //           }
  //           if (mmm[i].type === "reference") {
  //             console.log("check selected Properties");
  //             if (mmm[i].col_types.includes("group_key_value")) {
  //               checkSelectedProperties(mmm);
  //             }
  //           }
  //           if (count === 3) {
  //             break;
  //           }
  //         }
  //         console.log("vvbefore : " + JSON.stringify(rcd));
  //         for (let i = 0; i < rcd.length; i++) {
  //           rcd[i].index = i;
  //         }
  //         rcd.sort((a, b) =>
  //           parseInt(a.formView.co) > parseInt(b.formView.co)
  //             ? 1
  //             : parseInt(a.formView.co) < parseInt(b.formView.co)
  //             ? -1
  //             : 0
  //         );
  //         console.log("jjghhht : " + JSON.stringify(rcd));
  //         let rd = [];
  //         let rdd = [];
  //         let left_rd = [];
  //         let left_check = false;
  //         let downRcd = [];
  //         for (let i = 0; i < rcd.length; i++) {
  //           if (rcd[i].type === "activity") {
  //             downRcd.push(rcd[i]);
  //           } else if (rcd[i].formView.position === "left") {
  //             // rcd[i].index = i;
  //             rd.push(rcd[i]);
  //             if (rcd[i + 1].formView.position === "full") {
  //               left_check = true;
  //             }
  //           } else if (rcd[i].formView.position === "right") {
  //             // rcd[i].index = i;
  //             rd.push(rcd[i]);
  //             console.log(
  //               JSON.stringify(rcd[i + 1]) + " rccddd : " + JSON.stringify(rd)
  //             );
  //             if (i < rcd.length && rcd[i + 1].formView.position === "full") {
  //               rdd.push({
  //                 split: "true",
  //                 rcd: rd,
  //                 formView: { co: rd[0].formView.co, pn: rd[0].formView.pn },
  //               });
  //               for (let j = 0; j < left_rd.length; j++) {
  //                 rdd.push(left_rd[j]);
  //               }
  //               rd = [];
  //               left_check = false;
  //               left_rd = [];
  //             }
  //           } else {
  //             if (left_check) {
  //               rcd[i].split = "false";
  //               // rcd[i].index = i;
  //               left_rd.push(rcd[i]);
  //             } else {
  //               rcd[i].split = "false";
  //               // rcd[i].index = i;
  //               rdd.push(rcd[i]);
  //             }
  //           }
  //         }

  //         rdd.sort((a, b) =>
  //           parseInt(a.formView.pn) > parseInt(b.formView.pn)
  //             ? 1
  //             : parseInt(a.formView.pn) < parseInt(b.formView.pn)
  //             ? -1
  //             : 0
  //         );
  //         console.log("mmmm" + JSON.stringify(mmm));
  //         // rcd.sort((a,b)=>parseInt(a.beforeIndex)>parseInt(b.beforeIndex)?1:parseInt(a.beforeIndex)<parseInt(b.beforeIndex)?-1:0)
  //         // rcd.sort((a, b) =>
  //         //   parseInt(a.index) > parseInt(b.index)
  //         //     ? 1
  //         //     : parseInt(a.index) < parseInt(b.index)
  //         //       ? -1
  //         //       : 0
  //         // );
  //         console.log("rcd", downRcd);
  //         setRecord(rcd);
  //         setRcdView(rdd);
  //         setDownRcdView(downRcd);
  //         setMainRecord(s_rcd);
  //         if (call_check) {
  //           relationVerify();
  //         }
  //         // setLoading(false);

  //         // setTimeout(() => {
  //         //   // console.log("yyyyyyyzzzz" + JSON.stringify(button));
  //         // }, 2000);
  //       },
  //       (error) => {
  //         console.log(error);
  //         let err = { message: error.message, code: error.response.status };
  //         navigation("/error", { state: { err: err } });
  //       }
  //     );
  // };

  const setRefRcd = (vl, index, ob, type, id, obj) => {
    console.log("dan", vl, index, ob, type, id, obj);
    // setcolumn(index, ob, type, obj.id, "false", obj.depend_column)
    formChangefn(
      vl,
      index,
      ob,
      type,
      id,
      true,
      obj,
      0,
      0,
      "",
      "",
      obj.depend_column
    );
  };

  const getFieldValue = async (col_id) => {
    var rcd = record;
    console.log(col_id, record);
    if (rcd !== "null" && rcd !== "") {
      for (var r = 0; r < rcd.length; r++) {
        if (rcd[r].id === col_id) {
          console.log(rcd[r].value);
          return rcd[r].value;
        }
      }
    }
  };

  const setcolumn = async (index, name, type, col, readonly, depCol) => {
    if (readonly === "false") {
      var rf = record_rq?.referenceQualifier;
      console.log(index, name, type, col, readonly, depCol);
      // var ref_filt = ref_filter;
      let ref_filt = [];
      let ref_check = false;
      let filtt = [];
      for (var r = 0; r < rf.length; r++) {
        if (rf[r].formColumn.id === col && "filter" in rf[r]) {
          filtt = rf[r].filter.filter;
          console.log(filtt);
          for (var f = 0; f < filtt.length; f++) {
            if (
              filtt[f].mc === "is dependent" ||
              filtt[f].ct === "depend_table"
            ) {
              var col_name = filtt[f].dc.value;
              console.log("is depend");
              var co_label = filtt[f].dc.label;
              let ab = await getFieldValue(filtt[f].rf.id);
              let filtt1 = {};
              console.log(ab.id, filtt1.rf);
              filtt1.co = col_name;
              filtt1.cl = co_label;
              filtt1.ct = type;
              filtt1.mc = "is";
              filtt1.an = "";
              filtt1.af = "";
              filtt1.rf = { id: ab.id, value: ab.name };
              filtt1.dc = { id: 0, value: "" };
              ref_filt.push(filtt1);
              console.log(filtt1);
            } else {
              ref_filt.push(filtt[f]);
            }
          }
        }
      }
      console.log("lopg rf " + JSON.stringify(rf));
      if (ref_filt.length === 0) {
        console.log("reffilt");
        const refarray = {
          filter: [
            {
              co: "",
              cl: "",
              mc: "",
              an: "",
              ct: "",
              af: "",
              rf: { id: "", value: "" },
              dc: { id: "", value: "" },
            },
          ],
        };
        setref_filter(refarray);
      } else {
        console.log(filtt);
        const refarray = {
          filter: ref_filt,
        };
        setref_filter(refarray);
      }
      // this.setState({
      //   ref_filter: ref_filt,
      //   columnid: col,
      //   cur_ref_name: name,
      //   cur_ref_type: type,
      //   cur_ref_index: index,
      //   showmodel: true,
      // });
      // console.log("lopg filt "+JSON.stringify(ref_filt))

      setColumnId(col);
      setObjIndex(index);
      setObjType(type);
      setDepCol(depCol);
      setObjName(name);
      setshowModelList(true);
      setTimeout(() => {
        if (name === "field_id" || name === "form_column_id") {
          if (ref_filter.length > 0) {
            setReference();
          }
        }
      }, 500);
    }
  };

  const clickRefrence = (
    e,
    id,
    name,
    type,
    index,
    label,
    readonly,
    depend_column
  ) => {
    e.preventDefault();
    console.log(record_rq, depend_column);
    setcolumn(index, name, type, id, readonly, depend_column);
    console.log(readonly);
    // setColumnId(id);
    // setObjName(name);
    // setObjType(type);
    // setObjIndex(index);
    // setshowModelList(true);
    setObjLabel(label);
  };

  const getSingleInfo = (
    e,
    obj,
    id,
    name,
    type,
    index,
    label,
    readonly,
    depend_column
  ) => {
    e.preventDefault();
    let infoRecord = {};
    if (obj.type === "depend_table") {
      infoRecord.tabName = "form_column";
      infoRecord.tabid = obj.value.id;
    } else {
      infoRecord.tabName = obj.refTable.value;
      infoRecord.tabid = obj.value.id;
    }
    console.log(infoRecord.tabid);
    console.log(infoRecord.tabid);
    closeRef.current = true;
    if (infoRecord.tabid != "0") {
      axios
        .get(
          loca +
            `/lom/get/singlerecord/${infoRecord.tabName}/${infoRecord.tabid}`,
          {
            headers: {
              // "Content-Type": "application/json",
              authorization: "Bearer " + token,
            },
          }
        )
        .then(
          (resp) => {
            const record = resp.data;
            console.log("dan3", record);
            var mmm;
            if ("userRecord" in record) {
              mmm = record.userRecord[2].record;
              infoRecord.tabLabel = record.userRecord[1].table.label;
            } else {
              mmm = record.formRecord[2].record;
              infoRecord.tabLabel = record.formRecord[1].table.label;
            }
            console.log(infoRecord);
            setInfoRcdDetail(infoRecord);

            let rcd = [];
            if ("Error" in record) {
              navigation("/dashboard", { state: { error: record.Error } });
            } else {
              if ("formRecord" in record) {
                rcd = record.formRecord[2].record;
              } else if ("buttonRecord" in record) {
                rcd = record.buttonRecord[2].record;
              } else if ("tableRecord" in record) {
                rcd = record.tableRecord[2].record;
              } else if ("typeRecord" in record) {
                rcd = record.typeRecord[2].record;
              } else if ("uivalidationRecord" in record) {
                rcd = record.uivalidationRecord[2].record;
              } else if ("languageRecord" in record) {
                rcd = record.languageRecord[2].record;
              } else if ("userRecord" in record) {
                rcd = record.userRecord[2].record;
              } else {
                console.log("table record not found");
              }
            }
            if (tableName.current === "reference_filter") {
              axios
                .get(loca + `/lom/get/refFilter/${infoRecord.tabid}`, {
                  headers: {
                    // "Content-Type": "application/json",
                    authorization: "Bearer " + token,
                  },
                })
                .then(
                  (resp) => {
                    const coldata = resp.data;
                    console.log(coldata);
                    if (coldata !== "") {
                      if ("Error" in coldata) {
                        setLoading(false);
                        setPageError(true);
                        setError(coldata.Error);
                      } else {
                        var col_array = [];
                        col_array.push({ id: "", name: "none", label: "None" });
                        for (var c = 0; c < coldata.columnRecords.length; c++) {
                          col_array.push(coldata.columnRecords[c]);
                        }
                        var choicearry = [];
                        for (var z = 0; z < coldata.columnRecords.length; z++) {
                          choicearry.push({
                            id: coldata.columnRecords[z].id,
                            name: coldata.columnRecords[z].name,
                            label: coldata.columnRecords[z].label,
                            value: coldata.columnRecords[z].name,
                            choice_order: z * 100 + "",
                            form_column_id: "",
                          });
                        }
                        setColumn_depend(choicearry);
                      }
                    }
                  },
                  (error) => {
                    // setLoading(false)
                    console.log(error);
                    let err = {
                      message: error.message,
                      code: error.response.status,
                    };
                    navigation("/error", { state: { err: err } });
                  }
                );
            }
            for (let i = 0; i < rcd.length; i++) {
              rcd[i].index = i;
            }

            rcd.sort((a, b) =>
              parseInt(a.formView.pn) > parseInt(b.formView.pn)
                ? 1
                : parseInt(a.formView.pn) < parseInt(b.formView.pn)
                ? -1
                : 0
            );
            let rd = [];
            let rdd = [];
            let left_rd = [];
            let left_check = false;
            let downRcd = [];
            for (let i = 0; i < rcd.length; i++) {
              if (rcd[i].type === "activity") {
                downRcd.push(rcd[i]);
              } else if (rcd[i].formView.position === "left") {
                // rcd[i].index = i;
                rd.push(rcd[i]);
                if (
                  i + 1 < rcd.length &&
                  rcd[i + 1].formView.position === "full"
                ) {
                  left_check = true;
                }
              } else if (rcd[i].formView.position === "right") {
                // rcd[i].index = i;
                rd.push(rcd[i]);
                if (
                  i === rcd.length - 1 ||
                  rcd[i + 1].formView.position === "full"
                ) {
                  rdd.push({
                    split: "true",
                    rcd: rd,
                    formView: { co: rd[0].formView.co },
                  });
                  for (let j = 0; j < left_rd.length; j++) {
                    rdd.push(left_rd[j]);
                  }
                  rd = [];
                  left_check = false;
                  left_rd = [];
                }
              } else {
                if (left_check) {
                  rcd[i].split = "false";
                  // rcd[i].index = i;
                  left_rd.push(rcd[i]);
                } else {
                  rcd[i].split = "false";
                  // rcd[i].index = i;
                  rdd.push(rcd[i]);
                }
              }
            }
            console.log(rdd, rd, left_rd);
            for (let i = 0; i < rdd.length; i++) {
              if (rdd[i].split === "true") {
                rdd[i].rcd.sort((a, b) =>
                  parseInt(a.formView.pn) > parseInt(b.formView.pn)
                    ? 1
                    : parseInt(a.formView.pn) < parseInt(b.formView.pn)
                    ? -1
                    : 0
                );
              }
            }
            rdd.sort((a, b) =>
              parseInt(a.formView.pn) > parseInt(b.formView.pn)
                ? 1
                : parseInt(a.formView.pn) < parseInt(b.formView.pn)
                ? -1
                : 0
            );
            console.log(rdd);
            console.log("mmmm" + JSON.stringify(mmm));
            rcd.sort((a, b) =>
              parseInt(a.index) > parseInt(b.index)
                ? 1
                : parseInt(a.index) < parseInt(b.index)
                ? -1
                : 0
            );
            //  setRecord(rcd);

            setInfoRcdView([...rdd]);

            //  setDownRcdView(downRcd);
            //  console.log(policy);
            //  setPolicyFilter(policy.ui_policy);
            //  setPolicyAction(policy.policy_action);
            // setTimeout(() => {
            //   console.log(infoRcdView);
            // }, 5000);
            //  setMainRecord(record);
            // setUiScript(script);
            //    setap_form(new ApForm(rcd, setRecord, tabRelation, setTabRelation));
            //  setap_user(new ApUser(userDetails, setRecord));
            //   uScript.current = script;
            /*     if (call_check) {
         console.log("Check verify");
         var chk = false;
         for (let i = 0; i < script.length; i++) {
           // let field = script[i].field.name;
           let func = script[i].script;
           let type = script[i].type;
           console.log(count.current, type);
           if (type === "onload") {
             chk = true;
             count.current = 1;
           }
         }
         console.log(chk);
         if (!chk) {
           setLoading(false);
         }
         relationVerify();
       } else {
         checkRefrecord();
       } */

            setShowModelInfo(!showModelInfo);
          },
          (error) => {
            // setLoading(false)
            console.log(error);
            let err = { message: error.message, code: error.response.status };
            navigation("/error", { state: { err: err } });
          }
        );
    } else {
      toast.error("No Record");
    }
  };

  const dashBoardForm = () => {
    tableName.current = dash_tabname;
    rty.current = dash_rty;
    rid.current = dash_rid;
    isSub.current = true;
    getScriptList();
  };

  const checkAccess = () => {
    let tabname = searchParam.get("tableName");
    let listCheck = listAccess.current.find((e) => e === tabname);
    let newCheck = newAccess.current.find((e) => e === tabname);
    parentApp.current = searchParam.get("parentApplication");
    console.log(newCheck, listCheck);
    if (newCheck || listCheck) {
      tableName.current = tabname;
      rty.current = searchParam.get("rty");
      if (rty.current === "new" && newCheck) {
        return true;
      } else if (rty.current === "record" && (listCheck || newCheck)) {
        rid.current = searchParam.get("rid");
        return true;
      }
    }
    return false;
  };

  const invoice = (url) => {
    console.log(rid.current, tableName.current);
    axios
      .get(loca + `/lom/get/invoice/${rid.current}`, {
        headers: {
          // "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          const record = resp.data;
          console.log("Arman", record);
          setInvRcd(record);
          // navigation(
          //   url.toString(), { state: { inv: record, rcd: rcdView[1].value } }
          // );
        },
        (error) => {
          console.log(error);
          let err = { message: error.message, code: error.response.status };
          navigation("/error", { state: { err: err } });
        }
      );
  };

  const getScriptList = () => {
    let isAccessable = true;
    if (!isSub.current) {
      isAccessable = checkAccess();
    }
    if (isAccessable) {
      axios
        .get(loca + "/lom/get/script", {
          headers: { authorization: "Bearer " + token },
        })
        .then(
          (resp) => {
            let res = resp.data;
            console.log(res);
            setReScript(res.reusableScript);
            setLoading(true);
            getFormDetails();
          },
          (error) => {
            console.log(error);
            let err = { message: error.message, code: error.response.status };
            navigation("/error", { state: { err: err } });
          }
        );
    } else {
      setLoading(false);
      toast(
        "Incorrect search query or you don't have permission to access this",
        { pauseOnHover: true, theme: "colored", type: "error" }
      );
    }
  };
  const getInitial = async () => {
    let res = await axios
      .get(loca + "/lom/get/updatejson", {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let res = resp.data;
          console.log(res);
          let rec = res.record;
          // let arr=[]
          let curr = undefined;
          for (let i = 0; i < rec.length; i++) {
            if (rec[i].selected === "true") {
              curr = rec[i];
              return curr;
            }
            // else{
            //   arr.push(rec[i])
            // }
          }
          // setCurrentRec(curr)
          // setUnselectedRec([...arr])
          // setUpdateJsonRec(res.record);
        },
        (error) => {
          console.log(error);
          return undefined;
        }
      );
    console.log(res);
    return res;
  };

  const hasError = (data) => {
    console.log(data);
    for (const key in data) {
      if (Array.isArray(data[key])) {
        for (const record of data[key]) {
          if (record.record && record.record.Error) {
            return { error: true, msg: record.record.Error }; // Error found
          }
        }
      }
    }
    return { error: false, msg: "" }; // No errors found
  };
  const getFormDetails = async (bool) => {
    let updatedJSON = await getInitial();
    console.log(updatedJSON);
    // setLoading(true);
    let jj = [];
    if (bool != true) {
      setCol_mn(jj);
      setError("");
      setPageError(false);
      setMessage("");
      setPageMessage(false);
    }
    console.log("getFormDetails", rty, tableName.current, parentApp);
    if (rty.current === "new") {
      let url = "";
      if (parentApp.current === "Marketplace") {
        url =
          `http://localhost:8084/marketplace/get/blankrecord/` +
          tableName.current;
      } else {
        url = loca + `/lom/get/blankrecord/` + tableName.current;
      }
      console.log(url);
      axios
        .get(url, {
          headers: {
            authorization: "Bearer " + token,
            "market-application": "marketplace",
          },
          // setViewList,
        })
        .then(
          (resp) => {
            // setRelationload(false);
            const blkrecord = resp.data;
            // setCopyRcd(blkrecord.appRecord[2].record);
            console.log("dan1", resp);
            console.log("dan2", JSON.parse(JSON.stringify(blkrecord)));
            let ref_tab_name = "";
            const err = hasError(blkrecord);
            if (Object.values(blkrecord).includes("Error")) {
              navigation("/dashboard", { state: { error: blkrecord.Error } });
            } else if (err.error) {
              setError(err.msg);
              setPageError(true);
              setRecord([]);
              setLoading(false);
            } else {
              // setMainRecord(blkrecord);
              let view_list = [];
              if ("appRecord" in blkrecord) {
                setCopyRcd(blkrecord.appRecord[2].record);
                setRecord(blkrecord.appRecord[2].record);
                setButton(blkrecord.appRecord[3].button);
                setTabLabel(blkrecord.appRecord[1].table.label);
                setTabId(blkrecord.appRecord[1].table.id);
                i_d.current = blkrecord.appRecord[1].table.id;
                view_list = blkrecord?.appRecord[7]?.view;
              } else if ("columnRecord" in blkrecord) {
                setCopyRcd(blkrecord.columnRecord[2].record);
                setRecord(blkrecord.columnRecord[2].record);
                setButton(blkrecord.columnRecord[3].button);
                setTabLabel(blkrecord.columnRecord[1].table.label);
                setTabId(blkrecord.columnRecord[1].table.id);
                i_d.current = blkrecord.columnRecord[1].table.id;
                view_list = blkrecord?.columnRecord[7]?.view;
              } else if ("formRecord" in blkrecord) {
                setCopyRcd(blkrecord.formRecord[2].record);
                setRecord(blkrecord.formRecord[2].record);
                setButton(blkrecord.formRecord[3].button);
                setTabLabel(blkrecord.formRecord[1].table.label);
                setTabId(blkrecord.formRecord[1].table.id);
                i_d.current = blkrecord.formRecord[1].table.id;
                view_list = blkrecord?.formRecord[7]?.view;
              } else if ("choiceRecord" in blkrecord) {
                setCopyRcd(blkrecord.choiceRecord[2].record);
                setRecord(blkrecord.choiceRecord[2].record);
                setButton(blkrecord.choiceRecord[3].button);
                setTabLabel(blkrecord.choiceRecord[1].table.label);
                setTabId(blkrecord.choiceRecord[1].table.id);
                i_d.current = blkrecord.choiceRecord[1].table.id;
                view_list = blkrecord?.choiceRecord[7]?.view;
              } else if ("labelRecord" in blkrecord) {
                setCopyRcd(blkrecord.labelRecord[2].record);
                setRecord(blkrecord.labelRecord[2].record);
                setButton(blkrecord.labelRecord[3].button);
                setTabLabel(blkrecord.labelRecord[1].table.label);
                setTabId(blkrecord.labelRecord[1].table.id);
                i_d.current = blkrecord.labelRecord[1].table.id;
                view_list = blkrecord?.labelRecord[7]?.view;
              } else if ("buttonRecord" in blkrecord) {
                setCopyRcd(blkrecord.buttonRecord[2].record);
                setRecord(blkrecord.buttonRecord[2].record);
                setButton(blkrecord.buttonRecord[3].button);
                setTabLabel(blkrecord.buttonRecord[1].table.label);
                setTabId(blkrecord.buttonRecord[1].table.id);
                i_d.current = blkrecord.buttonRecord[1].table.id;
                view_list = blkrecord?.buttonRecord[7]?.view;
              } else if ("tableRecord" in blkrecord) {
                setCopyRcd(blkrecord.tableRecord[2].record);
                setRecord(blkrecord.tableRecord[2].record);
                setButton(blkrecord.tableRecord[3].button);
                setTabLabel(blkrecord.tableRecord[1].table.label);
                setTabId(blkrecord.tableRecord[1].table.id);
                i_d.current = blkrecord.tableRecord[1].table.id;
                view_list = blkrecord?.tableRecord[7]?.view;
              } else if ("typeRecord" in blkrecord) {
                setCopyRcd(blkrecord.typeRecord[2].record);
                setRecord(blkrecord.typeRecord[2].record);
                setButton(blkrecord.typeRecord[3].button);
                setTabLabel(blkrecord.typeRecord[1].table.label);
                setTabId(blkrecord.typeRecord[1].table.id);
                i_d.current = blkrecord.typeRecord[1].table.id;
                view_list = blkrecord?.typeRecord[7]?.view;
              } else if ("uivalidationRecord" in blkrecord) {
                setCopyRcd(blkrecord.uivalidationRecord[2].record);
                setRecord(blkrecord.uivalidationRecord[2].record);
                setButton(blkrecord.uivalidationRecord[3].button);
                setTabLabel(blkrecord.uivalidationRecord[1].table.label);
                setTabId(blkrecord.uivalidationRecord[1].table.id);
                i_d.current = blkrecord.uivalidationRecord[1].table.id;
                view_list = blkrecord?.uivalidationRecord[7]?.view;
              } else if ("languageRecord" in blkrecord) {
                setCopyRcd(blkrecord.languageRecord[2].record);
                setRecord(blkrecord.languageRecord[2].record);
                setButton(blkrecord.languageRecord[3].button);
                setTabLabel(blkrecord.languageRecord[1].table.label);
                setTabId(blkrecord.languageRecord[1].table.id);
                i_d.current = blkrecord.languageRecord[1].table.id;
                view_list = blkrecord?.languageRecord[7]?.view;
              } else if ("userRecord" in blkrecord) {
                setCopyRcd(blkrecord.userRecord[2].record);
                setRecord(blkrecord.userRecord[2].record);
                setButton(blkrecord.userRecord[3].button);
                setTabLabel(blkrecord.userRecord[1].table.label);
                setTabId(blkrecord.userRecord[1].table.id);
                i_d.current = blkrecord.userRecord[1].table.id;
                view_list = blkrecord?.userRecord[7]?.view;
              } else {
                console.log("table record not found");
              }
              setViewList(view_list);
              let script = null;
              let policy = [];
              let rcd = [];
              console.log(blkrecord);
              if ("formRecord" in blkrecord) {
                script = blkrecord.formRecord[4].uiscript;
                rcd = blkrecord.formRecord[2].record;
                policy = blkrecord.formRecord[5].uipolicy;
                setCid(blkrecord.formRecord[6].client.cid);
              } else if ("appRecord" in blkrecord) {
                script = blkrecord.appRecord[4].uiscript;
                policy = blkrecord.appRecord[5].uipolicy;
                rcd = blkrecord.appRecord[2].record;
                setCid(blkrecord.appRecord[6].client.cid);
              } else if ("tableRecord" in blkrecord) {
                script = blkrecord.tableRecord[4].uiscript;
                policy = blkrecord.tableRecord[5].uipolicy;
                rcd = blkrecord.tableRecord[2].record;
                setCid(blkrecord.tableRecord[6].client.cid);
              } else if ("typeRecord" in blkrecord) {
                script = blkrecord.typeRecord[4].uiscript;
                policy = blkrecord.typeRecord[5].uipolicy;
                rcd = blkrecord.typeRecord[2].record;
                setCid(blkrecord.typeRecord[6].client.cid);
              } else if ("uivalidationRecord" in blkrecord) {
                script = blkrecord.uivalidationRecord[4].uiscript;
                policy = blkrecord.uivalidationRecord[5].uipolicy;
                rcd = blkrecord.uivalidationRecord[2].record;
                setCid(blkrecord.uivalidationRecord[6].client.cid);
              } else if ("buttonRecord" in blkrecord) {
                script = blkrecord.buttonRecord[4].uiscript;
                policy = blkrecord.buttonRecord[5].uipolicy;
                rcd = blkrecord.buttonRecord[2].record;
                setCid(blkrecord.buttonRecord[6].client.cid);
              } else if ("choiceRecord" in blkrecord) {
                script = blkrecord.choiceRecord[4].uiscript;
                rcd = blkrecord.choiceRecord[2].record;
                policy = blkrecord.choiceRecord[5].uipolicy;
                setCid(blkrecord.choiceRecord[6].client.cid);
              } else if ("languageRecord" in blkrecord) {
                script = blkrecord.languageRecord[4].uiscript;
                rcd = blkrecord.languageRecord[2].record;
                policy = blkrecord.languageRecord[5].uipolicy;
                setCid(blkrecord.languageRecord[6].client.cid);
              } else if ("columnRecord" in blkrecord) {
                script = blkrecord.columnRecord[4].uiscript;
                rcd = blkrecord.columnRecord[2].record;
                policy = blkrecord.columnRecord[5].uipolicy;
                setCid(blkrecord.columnRecord[6].client.cid);
              } else if ("labelRecord" in blkrecord) {
                script = blkrecord.labelRecord[4].uiscript;
                rcd = blkrecord.labelRecord[2].record;
                policy = blkrecord.labelRecord[5].uipolicy;
                setCid(blkrecord.labelRecord[6].client.cid);
              } else if ("userRecord" in blkrecord) {
                script = blkrecord.userRecord[4].uiscript;
                policy = blkrecord.userRecord[5].uipolicy;
                rcd = blkrecord.userRecord[2].record;
                setCid(blkrecord.userRecord[6].client.cid);
              }
              // onload
              let userDetails = JSON.parse(localStorage.getItem("userDetails"));
              if (script !== null) {
                for (let i = 0; i < script.length; i++) {
                  // let field = script[i].field.name;
                  let func = script[i].script;
                  let type = script[i].type;
                  if (type === "onload" && count.current === 1) {
                    count.current = 0;
                    onLoad(
                      func,
                      new ApUser(userDetails, setRecord),
                      new ApForm(rcd, setRecord, tabRelation, setTabRelation)
                    );
                  }
                }
              }

              let StartIndex = 0;
              let mapObj = new Map();
              let ref_cols = [];
              for (let i = 0; i < rcd.length; i++) {
                rcd[i].index = i;
                if (
                  rcd[i].type === "reference" &&
                  rcd[i].name === "form_table_id"
                ) {
                  ref_cols.push(rcd[i]);
                }
                for (let k = 0; k < fieldName.length; k++) {
                  if (rcd[i].name === fieldName[k]) {
                    let ref = { id: 0, name: "" };
                    for (let j = 0; j < recordNew.length; j++) {
                      if (k > 0 && recordNew[j].name === fieldName[k]) {
                        ref = recordNew[j].value;
                        console.log(rcd[i].value);
                      } else if (k == 0) {
                        if (recordNew[j].name === "id") {
                          ref.id = recordNew[j].value;
                        }
                        if (recordNew[j].displayColumn === "true") {
                          ref.name = recordNew[j].value;
                        }
                      }
                    }
                    rcd[i].value = ref;
                  }
                }

                if (rcd[i]?.secondary === "true" && StartIndex == 0) {
                  setSecondaryStartIndex(i);
                  setTimeout(() => {
                    console.log("start index", i);
                  }, 5000);
                  StartIndex++;
                }

                // If addNone check box is not selected in form_choice then the field will not be mandatory
                if (rcd[i]?.type === "choice") {
                  if (
                    rcd[i]?.choice[0]?.name !== "none" &&
                    rcd[i]?.choice[0]?.name !== ""
                  ) {
                    rcd[i].uivalid.mandatory = "false";
                  }
                }

                if (rcd[i]?.secondary === "true") {
                  let fv = rcd[i]?.formView;
                  console.log("fv", fv);
                  let col = fv.path.split(".")[1];
                  if (mapObj.get(col)) {
                    mapObj.get(col).push(fv);
                  } else {
                    let vl = [];
                    vl.push(fv);
                    mapObj.set(col, vl);
                  }
                }
              }

              setSecondarycallsCols(mapObj);
              console.log("danish", rcd);
              rcd.sort((a, b) =>
                parseInt(a.formView.pn) > parseInt(b.formView.pn)
                  ? 1
                  : parseInt(a.formView.pn) < parseInt(b.formView.pn)
                  ? -1
                  : 0
              );
              let rd = [];
              let rdd = [];
              let left_rd = [];
              let downRcd = [];
              let left_check = false;
              for (let i = 0; i < rcd.length; i++) {
                // if (rcd[i].type === "choice") {
                //  rcd[i].choice.unshift({ id: "", name: "none", label: "None", value: "None" });
                //  console.log(rcd[i]);
                // }
                if (rcd[i].type === "table_insert") {
                  var clr = [
                    { name: "Total", value: "Total" },
                    { name: "", value: "" },
                    { name: "Total Quantity", value: "" },
                    { name: "", value: "" },
                    { name: "", value: "" },
                    { name: "Total Amount", value: "" },
                  ];
                  rcd[i].value.record.push({ colr: clr });
                  var clr1 = [
                    { name: "Total", value: "Total" },
                    { name: "Taxable Value", value: "" },
                    { name: "", value: "" },
                    { name: "Tax Amount", value: "" },
                  ];
                  rcd[i].value.record.push({ colr: clr1 });
                }
                if (rcd[i].type === "filter") {
                  //  rcd[i].value = keyValueJson;
                  deftimeline.current = rcd[i].value.timeLine;
                }
                if (rcd[i].type === "key_value") {
                  // rcd[i].value = groupkeyValue;
                  setKeyValueJson(rcd[i].value);
                }
                if (rcd[i].type === "activity") {
                  downRcd.push(rcd[i]);
                } else if (rcd[i].formView.position === "left") {
                  // rcd[i].index = i;
                  // if (rcd[i].uivalid.visible=== "true") {
                  rd.push(rcd[i]);
                  // }
                  if (
                    i + 1 < rcd.length &&
                    rcd[i + 1].formView.position === "full"
                  ) {
                    rdd.push({
                      split: "true",
                      rcd: rd,
                      formView: {
                        co: rd[0].formView.co,
                        pn: rd[0].formView.pn,
                      },
                    });
                    left_rd = [];
                    left_check = true;
                    rd = [];
                  }
                } else if (rcd[i].formView.position === "right") {
                  rd.push(rcd[i]);
                  // console.log(rcd[i + 1], " rccddd : ", rd);
                  if (
                    i === rcd.length - 1 ||
                    rcd[i + 1].formView.position === "full"
                  ) {
                    rdd.push({
                      split: "true",
                      rcd: rd,
                      formView: {
                        co: rd[0].formView.co,
                        pn: rd[0].formView.pn,
                      },
                    });
                    rd = [];
                    left_check = false;
                    left_rd = [];
                  }
                } else {
                  if (left_check) {
                    rcd[i].split = "false";
                    rdd.push(rcd[i]);
                  } else {
                    rcd[i].split = "false";
                    rdd.push(rcd[i]);
                  }
                }
              }
              console.log(rdd);
              for (let i = 0; i < rdd.length; i++) {
                if (rdd[i].split === "true") {
                  rdd[i].rcd.sort((a, b) =>
                    parseInt(a.formView.pn) > parseInt(b.formView.pn)
                      ? 1
                      : parseInt(a.formView.pn) < parseInt(b.formView.pn)
                      ? -1
                      : 0
                  );
                }
              }
              rdd.sort((a, b) =>
                parseInt(a.formView.pn) > parseInt(b.formView.pn)
                  ? 1
                  : parseInt(a.formView.pn) < parseInt(b.formView.pn)
                  ? -1
                  : 0
              );
              rcd.sort((a, b) =>
                parseInt(a.index) > parseInt(b.index)
                  ? 1
                  : parseInt(a.index) < parseInt(b.index)
                  ? -1
                  : 0
              );
              console.log(rdd);
              console.log(policy);

              setPolicyFilter(policy.ui_policy);
              setPolicyAction(policy.policy_action);
              setMainRecord(blkrecord);
              setUiScript([...script]);
              uScript.current = script;

              rcrd.current = rcd;
              setRcdView(rdd);
              setDownRcdView(downRcd);

              setap_form(
                new ApForm(rcd, setRecord, tabRelation, setTabRelation)
              );
              setap_user(new ApUser(userDetails, setRecord));

              // on load ui policy
              let selectedId = "";
              console.log(" viewList ", view_list);
              for (let t = 0; t < view_list?.length; t++) {
                if (view_list[t].selected === "true")
                  selectedId = view_list[t].id;
              }
              for (let s = 0; s < policy?.ui_policy?.length; s++) {
                let pf = policy.ui_policy[s].filter;
                let pfId = policy.ui_policy[s].id;
                let global = policy.ui_policy[s].global;
                let form_view_id = policy.ui_policy[s].form_view_id;
                let onload = policy.ui_policy[s].onload;

                console.log(
                  "pf",
                  selectedId,
                  pf,
                  pf !== undefined &&
                    pf !== "" &&
                    (global === "true" || selectedId === form_view_id) &&
                    onload === "true"
                );
                if (
                  pf !== undefined &&
                  pf !== "" &&
                  (global === "true" || selectedId === form_view_id) &&
                  onload === "true"
                ) {
                  var pfilt = pf.filter;

                  console.log("123", pfilt);

                  console.log("123", pfilt);
                  for (let i = 0; i < pfilt.length; i++) {
                    for (let t = 0; t < policy.policy_action.length; t++) {
                      if (pfId === policy.policy_action[t].policy_id) {
                        let valueMatch = false;
                        let colMatch = false;
                        let rcdIndex;
                        for (let j = 0; j < rcd.length; j++) {
                          if (
                            policy.policy_action[t].form_column_id == rcd[j].id
                          ) {
                            colMatch = true;
                            rcdIndex = j;
                          }
                          if (pfilt[i].co == rcd[j].name) {
                            if (pfilt[i].ct === "reference") {
                              if (
                                // pfilt[i].co == "" ||
                                pfilt[i].rf.value == rcd[j].value.name
                              ) {
                                valueMatch = true;
                              }
                            } else {
                              if (
                                // pfilt[i].co == "" ||
                                // pfilt[i].an == "" ||
                                pfilt[i].an == rcd[j].value
                              ) {
                                console.log("ty", pfilt[i].an, rcd[j].value);
                                valueMatch = true;
                              }
                            }
                          }
                        }
                        console.log(" colMatch", colMatch, valueMatch);
                        if (colMatch && valueMatch) {
                          if (
                            policy.policy_action[t].visible != "null" &&
                            policy.policy_action[t].visible != "leave"
                          ) {
                            rcd[rcdIndex].uivalid.visible =
                              policy.policy_action[t].visible;
                          }
                          if (
                            policy.policy_action[t].read_only != "null" &&
                            policy.policy_action[t].read_only != "leave"
                          ) {
                            rcd[rcdIndex].uivalid.read_only =
                              policy.policy_action[t].read_only;
                          }
                          if (
                            policy.policy_action[t].mandatory != "null" &&
                            policy.policy_action[t].mandatory != "leave"
                          ) {
                            rcd[rcdIndex].uivalid.mandatory =
                              policy.policy_action[t].mandatory;
                          }
                        }
                      }
                    }
                  }
                }
              }
              // let rccd=JSON.parse(JSON.stringify(rcd))
              setRecord(rcd);
              setAll_ref_cols(ref_cols);
              console.log("dataa123", rcd);
              checkRefrecord();

              // setTimeout(() => {
              setFormChange(!formChange);
              // }, 5000);
            }
          },
          (error) => {
            let err = { message: error.message, code: error.response.status };
            navigation("/error", { state: { err: err } });
          }
        );
    } else if (rty.current === "record") {
      console.log(rid.current, tableName.current, parentApp);
      let url = "";
      if (parentApp.current === "Marketplace") {
        url = `http://localhost:8084/marketplace/get/singlerecord/${tableName.current}/${rid.current}`;
      } else {
        url =
          loca + `/lom/get/singlerecord/${tableName.current}/${rid.current}`;
      }
      console.log(url);
      axios
        .get(url, {
          headers: {
            // "Content-Type": "application/json",
            authorization: "Bearer " + token,
            "market-application": "marketplace",
          },
        })
        .then(
          (resp) => {
            const record = resp.data;
            console.log("dan2", record);
            let script = null;
            let rcd = [];
            let policy = [];
            let view_list = [];
            if ("Error" in record) {
              // navigation("/dashboard");
              toast.error(record.Error);
            } else {
              console.log(record);
              setMainRecord(record);

              if ("appRecord" in record) {
                rcd = record.appRecord[2].record;
                script = record.formRecord[5].uiscript;
                setButton(record.appRecord[3].button);
                setTabLabel(record.appRecord[1].table.label);
                setTabId(record.appRecord[1].table.id);
                setCid(record.appRecord[6].client.cid);
                setViewList(record.appRecord[8]?.view);
                setActivity(record.appRecord[9]?.activity);
              } else if ("columnRecord" in record) {
                rcd = record.columnRecord[2].record;
                script = record.formRecord[5].uiscript;
                setButton(record.columnRecord[3].button);
                setTabLabel(record.columnRecord[1].table.label);
                setTabId(record.columnRecord[1].table.id);
                setCid(record.columnRecord[6].client.cid);
                setViewList(record.columnRecord[8]?.view);
                setActivity(record.columnRecord[9]?.activity);
              } else if ("formRecord" in record) {
                rcd = record.formRecord[2].record;
                script = record.formRecord[6].uiscript;
                setButton(record.formRecord[3].button);
                setTabLabel(record.formRecord[1].table.label);
                setTabId(record.formRecord[1].table.id);
                setCid(record.formRecord[7].client.cid);
                setViewList(record.formRecord[9]?.view);
                setActivity(record.formRecord[10]?.activity);
                policy = record.formRecord[4].uipolicy;
                view_list = record.formRecord[9]?.view;
              } else if ("choiceRecord" in record) {
                rcd = record.choiceRecord[2].record;
                script = record.formRecord[6].uiscript;
                setButton(record.choiceRecord[3].button);
                setTabLabel(record.choiceRecord[1].table.label);
                setTabId(record.choiceRecord[1].table.id);
                setCid(record.choiceRecord[7].client.cid);
                setViewList(record.choiceRecord[9]?.view);
                setActivity(record.choiceRecord[10]?.activity);
              } else if ("labelRecord" in record) {
                rcd = record.labelRecord[2].record;
                script = record.formRecord[5].uiscript;
                setButton(record.labelRecord[3].button);
                setTabLabel(record.labelRecord[1].table.label);
                setTabId(record.labelRecord[1].table.id);
                setCid(record.labelRecord[6].client.cid);
                setViewList(record.labelRecord[8]?.view);
                setActivity(record.labelRecord[9]?.activity);
              } else if ("buttonRecord" in record) {
                rcd = record.buttonRecord[2].record;
                script = record.formRecord[5].uiscript;
                setButton(record.buttonRecord[3].button);
                setTabLabel(record.buttonRecord[1].table.label);
                setTabId(record.buttonRecord[1].table.id);
                setCid(record.buttonRecord[6].client.cid);
                setViewList(record.buttonRecord[8]?.view);
                setActivity(record.buttonRecord[9]?.activity);
              } else if ("tableRecord" in record) {
                rcd = record.tableRecord[2].record;
                script = record.formRecord[5].uiscript;
                setButton(record.tableRecord[3].button);
                setTabLabel(record.tableRecord[1].table.label);
                setTabId(record.tableRecord[1].table.id);
                setCid(record.tableRecord[6].client.cid);
                setViewList(record.tableRecord[8]?.view);
                setActivity(record.tableRecord[9]?.activity);
              } else if ("typeRecord" in record) {
                rcd = record.typeRecord[2].record;
                script = record.formRecord[5].uiscript;
                setButton(record.typeRecord[3].button);
                setTabLabel(record.typeRecord[1].table.label);
                setTabId(record.typeRecord[1].table.id);
                setCid(record.typeRecord[6].client.cid);
                setViewList(record.typeRecord[8]?.view);
                setActivity(record.typeRecord[9]?.activity);
              } else if ("uivalidationRecord" in record) {
                rcd = record.uivalidationRecord[2].record;
                script = record.formRecord[5].uiscript;
                setButton(record.uivalidationRecord[3].button);
                setTabLabel(record.uivalidationRecord[1].table.label);
                setTabId(record.uivalidationRecord[1].table.id);
                setCid(record.uivalidationRecord[6].client.cid);
                setViewList(record.uivalidationRecord[8]?.view);
                setActivity(record.uivalidationRecord[9]?.activity);
              } else if ("languageRecord" in record) {
                rcd = record.languageRecord[2].record;
                script = record.formRecord[5].uiscript;
                setButton(record.languageRecord[3].button);
                setTabLabel(record.languageRecord[1].table.label);
                setTabId(record.languageRecord[1].table.id);
                setCid(record.languageRecord[6].client.cid);
                setViewList(record.languageRecord[8]?.view);
                setActivity(record.languageRecord[9]?.activity);
              } else if ("userRecord" in record) {
                rcd = record.userRecord[2].record;
                script = record.userRecord[6].uiscript;
                setButton(record.userRecord[3].button);
                setTabLabel(record.userRecord[1].table.label);
                setTabId(record.userRecord[1].table.id);
                setCid(record.userRecord[7].client.cid);
                setViewList(record.userRecord[9]?.view);
                setActivity(record.userRecord[10]?.activity);
                policy = record.userRecord[4]?.uipolicy;
                view_list = record.userRecord[9]?.view;
              } else {
                console.log("table record not found");
              }
            }

            var mmm;
            if ("userRecord" in record) mmm = record.userRecord[2].record;
            else mmm = record.formRecord[2].record;
            console.log(mmm);
            setCopyRcd(mmm);
            console.log(copyRcd);
            let b = mmm.findIndex(
              (e) =>
                e.depend_column !== "" &&
                e.type === "filter" &&
                e.type === "multi_select"
            );
            if (b >= 0) {
              var col_array = [];
              col_array.push({ id: "", name: "none", label: "None" });
              for (var c = 0; c < mmm[b].dep_choice.length; c++) {
                console.log(mmm[b].dep_choice[c].label);
                col_array.push({
                  id: mmm[b].dep_choice[c].id,
                  name: mmm[b].dep_choice[c].name,
                  label: mmm[b].dep_choice[c].label,
                });
              }
              setCol_mn(col_array);
            }
            let rValue = "";
            let ref_cols = [];
            // this will set first one as display Name
            let displayNameFixed = false;
            for (var i = 0; i < mmm.length; i++) {
              if (mmm[i].type === "reference") {
                ref_cols.push(mmm[i]);
              }

              // If addNone check box is not selected in form_choice then the field will not be mandatory
              if (mmm[i]?.type === "choice") {
                if (
                  mmm[i]?.choice[0]?.name !== "none" &&
                  mmm[i]?.choice[0]?.name !== ""
                ) {
                  mmm[i].uivalid.mandatory = "false";
                }
              }

              if (mmm[i].name === "name") {
                let jso = {};
                let id = 0;
                for (let tmp = 0; tmp < mmm.length; tmp++) {
                  if (mmm[tmp].name === "id") {
                    id = mmm[tmp].value;
                    break;
                  }
                }
                let fake_jso = JSON.parse(JSON.stringify(mmm[i]));
                fake_jso.name = tableName.current + "_id";
                jso.id = id;
                jso.name = mmm[i].value;
                fake_jso.value = jso;
                ref_cols.push(fake_jso);
              }
              if (mmm[i].value !== "") {
                mmm[i].verified = "verified";
              } else {
                mmm[i].verified = "initial";
              }
              if (mmm[i].type === "datetime") {
                console.log("iiiiiiiiiiiii : " + i);
                // showcal.push({ value: false });
                // showCalender.push({ index: i, value: false });
              }
              if (mmm[i].type === "password") {
                mmm[i].value = "";
              }
              if (mmm[i].displayColumn === "true" && !displayNameFixed) {
                console.log(mmm[i]);
                displayNameFixed = true;
                rValue = mmm[i].value;
              }
            }
            console.log(rValue);
            refValue.current = rValue;
            // console.log("check :" + JSON.stringify(showCalender));
            // console.log(
            //   "check :" +
            //     JSON.stringify(showCalender.find((sh) => sh.index === 5))
            // );

            // onload
            // let userDetails = JSON.parse(localStorage.getItem("userDetails"));
            // if (script !== null) {
            //   for (let i = 0; i < script.length; i++) {
            //     // let field = script[i].field.name;
            //     let func = script[i].script;
            //     let type = script[i].type;
            //     console.log(count.current,type);
            //     if (type === "onload"  && count.current === 1) {
            //       count.current = 0;
            //       console.log(type);
            //       console.log(tabRelation);
            //       onLoad(
            //         func,
            //         new ApUser(userDetails, setRecord),
            //         new ApForm(rcd, setRecord,tabRelation,setTabRelation),
            //       );
            //     }
            //   }
            // }
            i_d.current = mmm[0].value;
            let count1 = 0;
            let call_check = true;
            let StartIndex = 0;
            let mapObj = new Map();

            for (let i = 0; i < mmm.length; i++) {
              if (mmm[i].type === "filter" && mmm[i].value !== "") {
                var filter = mmm[i].value.filter;
                console.log(mmm[i].value);
                setDcFiltVal(filter[0].dc.label);
                setFiltArray(mmm[i].value);
                console.log("mmm", mmm[i].value.timeline);
                setTimeline(mmm[i].value.timeline);

                //setDefTimeline(mmm[i].value.timeline)
                // console.log("def" ,deftimeline);
                deftimeline.current = mmm[i].value.timeline;
                console.log("deftimeline.current", deftimeline.current);
                count1++;
              }
              if (mmm[i].type === "filter_script" && mmm[i].value !== "") {
                setFiltArray(mmm[i].value);
                count1++;
              }
              if (mmm[i].type === "filter_ref" && mmm[i].value !== "") {
                setFiltRefArray(mmm[i].value);
                count1++;
              }
              if (mmm[i].name === "form_table_id") {
                callFieldList(mmm[i].value.id, true);
                // call_check = false;
                count1++;
              }
              if (mmm[i].type === "multi_select") {
                let parsVal = mmm[i].value.record;
                setMscList(parsVal);
                count1++;
              }
              if (mmm[i].type === "reference") {
                console.log("Properties", mmm[i].name);
                if (mmm[i].col_types?.includes("group_key_value")) {
                  //   col_types

                  console.log("check selected", mmm);
                  checkSelectedProperties(mmm);
                }
              }
              // if (mmm[i].type === "dynamic_key_value") {
              //   setDynamicKeyValue(mmm[i].value);
              // }
              if (count1 === 5) {
                break;
              }

              if (mmm[i]?.secondary === "true" && StartIndex === 0) {
                setSecondaryStartIndex(i);
                setTimeout(() => {
                  console.log("start index", i);
                }, 5000);
                StartIndex++;
              }
              if (mmm[i]?.secondary === "true") {
                let fv = mmm[i]?.formView;
                console.log("fv", fv);
                let col = fv.path.split(".")[1];
                if (mapObj.get(col)) {
                  mapObj.get(col).push(fv);
                } else {
                  let vl = [];
                  vl.push(fv);
                  mapObj.set(col, vl);
                }
              }
            }

            setSecondarycallsCols(mapObj);

            if (tableName.current === "reference_filter") {
              axios
                .get(loca + `/lom/get/refFilter/${rid.current}`, {
                  headers: {
                    // "Content-Type": "application/json",
                    authorization: "Bearer " + token,
                  },
                })
                .then(
                  (resp) => {
                    const coldata = resp.data;
                    console.log(coldata);
                    if (coldata !== "") {
                      if ("Error" in coldata) {
                        setLoading(false);
                        setPageError(true);
                        setError(coldata.Error);
                      } else {
                        var col_array = [];
                        col_array.push({ id: "", name: "none", label: "None" });
                        for (var c = 0; c < coldata.columnRecords.length; c++) {
                          col_array.push(coldata.columnRecords[c]);
                        }
                        var choicearry = [];
                        for (var z = 0; z < coldata.columnRecords.length; z++) {
                          choicearry.push({
                            id: coldata.columnRecords[z].id,
                            name: coldata.columnRecords[z].name,
                            label: coldata.columnRecords[z].label,
                            value: coldata.columnRecords[z].name,
                            choice_order: z * 100 + "",
                            form_column_id: "",
                          });
                        }
                        setColumn_depend(choicearry);
                        // setCol_mn(coldata.columnRecords);
                      }
                    }
                  },
                  (error) => {
                    // setLoading(false)
                    console.log(error);
                    let err = {
                      message: error.message,
                      code: error.response.status,
                    };
                    navigation("/error", { state: { err: err } });
                  }
                );
            }
            for (let i = 0; i < rcd.length; i++) {
              rcd[i].index = i;
            }

            rcd.sort((a, b) =>
              parseInt(a.formView.pn) > parseInt(b.formView.pn)
                ? 1
                : parseInt(a.formView.pn) < parseInt(b.formView.pn)
                ? -1
                : 0
            );

            let rd = [];
            let rdd = [];
            let left_rd = [];
            let left_check = false;
            let downRcd = [];
            for (let i = 0; i < rcd.length; i++) {
              if (rcd[i].type === "activity") {
                downRcd.push(rcd[i]);
              }
              if (rcd[i].formView.position === "left") {
                // rcd[i].index = i;
                rd.push(rcd[i]);
                if (
                  i + 1 < rcd.length &&
                  rcd[i + 1].formView.position === "full"
                ) {
                  left_check = true;
                }
              } else if (rcd[i].formView.position === "right") {
                // rcd[i].index = i;
                rd.push(rcd[i]);
                if (
                  i === rcd.length - 1 ||
                  rcd[i + 1].formView.position === "full"
                ) {
                  rdd.push({
                    split: "true",
                    rcd: rd,
                    formView: { co: rd[0].formView.co },
                  });
                  for (let j = 0; j < left_rd.length; j++) {
                    rdd.push(left_rd[j]);
                  }
                  rd = [];
                  left_check = false;
                  left_rd = [];
                }
              } else {
                if (left_check) {
                  rcd[i].split = "false";
                  // rcd[i].index = i;
                  left_rd.push(rcd[i]);
                } else {
                  rcd[i].split = "false";
                  // rcd[i].index = i;
                  rdd.push(rcd[i]);
                }
              }
            }
            for (let i = 0; i < rdd.length; i++) {
              if (rdd[i].split === "true") {
                rdd[i].rcd.sort((a, b) =>
                  parseInt(a.formView.pn) > parseInt(b.formView.pn)
                    ? 1
                    : parseInt(a.formView.pn) < parseInt(b.formView.pn)
                    ? -1
                    : 0
                );
              }
            }
            rdd.sort((a, b) =>
              parseInt(a.formView.pn) > parseInt(b.formView.pn)
                ? 1
                : parseInt(a.formView.pn) < parseInt(b.formView.pn)
                ? -1
                : 0
            );
            // rcd.sort((a,b)=>parseInt(a.beforeIndex)>parseInt(b.beforeIndex)?1:parseInt(a.beforeIndex)<parseInt(b.beforeIndex)?-1:0)
            rcd.sort((a, b) =>
              parseInt(a.index) > parseInt(b.index)
                ? 1
                : parseInt(a.index) < parseInt(b.index)
                ? -1
                : 0
            );
            if (updatedJSON) {
              if (
                tableName.current === "updatejson" &&
                rcd[0].value === updatedJSON.id
              ) {
                setSelectedJson(true);
              } else {
                setSelectedJson(false);
              }
            }

            // on load ui policy
            console.log("");

            let selectedId = "";
            for (let t = 0; t < view_list?.length; t++) {
              if (view_list[t].selected === "true")
                selectedId = view_list[t].id;
            }
            for (let s = 0; s < policy?.ui_policy?.length; s++) {
              let pf = policy.ui_policy[s].filter;
              let pfId = policy.ui_policy[s].id;
              let global = policy.ui_policy[s].global;
              let form_view_id = policy.ui_policy[s].form_view_id;
              let onload = policy.ui_policy[s].onload;
              if (
                pf !== undefined &&
                pf !== "" &&
                (global === "true" || selectedId === form_view_id) &&
                onload === "true"
              ) {
                var pfilt = pf.filter;
                for (let i = 0; i < pfilt.length; i++) {
                  for (let t = 0; t < policy.policy_action.length; t++) {
                    if (pfId === policy.policy_action[t].policy_id) {
                      let valueMatch = false;
                      let colMatch = false;
                      let rcdIndex;
                      for (let j = 0; j < rcd.length; j++) {
                        if (
                          policy.policy_action[t].form_column_id == rcd[j].id
                        ) {
                          colMatch = true;
                          rcdIndex = j;
                        }
                        if (pfilt[i].co == rcd[j].name) {
                          if (pfilt[i].ct === "reference") {
                            if (
                              // pfilt[i].co == "" ||
                              pfilt[i].rf.value == rcd[j].value.name
                            ) {
                              valueMatch = true;
                            }
                          } else {
                            if (
                              // pfilt[i].co == "" ||
                              // pfilt[i].an == "" ||
                              pfilt[i].an == rcd[j].value
                            ) {
                              console.log("ty", pfilt[i].an, rcd[j].value);
                              valueMatch = true;
                            }
                          }
                        }
                      }
                      if (colMatch && valueMatch) {
                        console.log("colMatch && valueMatch");

                        if (
                          policy.policy_action[t].visible != "null" &&
                          policy.policy_action[t].visible != "leave"
                        ) {
                          rcd[rcdIndex].uivalid.visible =
                            policy.policy_action[t].visible;
                        }
                        if (
                          policy.policy_action[t].read_only != "null" &&
                          policy.policy_action[t].read_only != "leave"
                        ) {
                          rcd[rcdIndex].uivalid.read_only =
                            policy.policy_action[t].read_only;
                        }
                        if (
                          policy.policy_action[t].mandatory != "null" &&
                          policy.policy_action[t].mandatory != "leave"
                        ) {
                          rcd[rcdIndex].uivalid.mandatory =
                            policy.policy_action[t].mandatory;
                        }
                      }
                    }
                  }
                }
              }
            }

            setAll_ref_cols(ref_cols);
            setRecord(rcd);
            rcrd.current = rcd;
            setRcdView([...rdd]);
            setDownRcdView(downRcd);
            setPolicyFilter(policy?.ui_policy);
            setPolicyAction(policy?.policy_action);
            setMainRecord(record);
            setUiScript(script);
            setap_form(new ApForm(rcd, setRecord, tabRelation, setTabRelation));
            setap_user(new ApUser(userDetails, setRecord));
            uScript.current = script;
            if (call_check) {
              var chk = false;
              for (let i = 0; i < script?.length; i++) {
                // let field = script[i].field.name;
                let func = script[i].script;
                let type = script[i].type;
                if (type === "onload") {
                  chk = true;
                  count.current = 1;
                }
              }
              if (!chk) {
                setLoading(false);
              }

              relationVerify();
            } else {
              checkRefrecord();
            }
          },
          (error) => {
            let err = { message: error.message, code: error.response.status };
            navigation("/error", { state: { err: err } });
          }
        );
    }
  };

  // functions for model list

  const setRef = (id, val, properties, refTable) => {
    setKeyValueJson(properties);
    formChangefn(
      val,
      objIndex,
      objName,
      objType,
      id,
      true,
      refTable,
      "",
      "",
      "",
      properties,
      depCol
    );
    cancelModelList();
  };

  const setReference = () => {
    for (let i = 0; i < uiScript.length; i++) {
      // let field = uiScript[i].field.name;
      let func = uiScript[i].script;
      let type = uiScript[i].type;
      if (type === "onreference" && ref_filter[0].co === "") {
        onReference(func);
      }
    }
  };

  const cancelModelList = () => {
    setshowModelList(false);
  };

  const afterCellEdit = (vl, ob) => {
    for (let i = 0; i < uiScript.length; i++) {
      let field = uiScript[i].field.name;
      let func = uiScript[i].script;
      let type = uiScript[i].type;
      if (field === ob && type === "oncelledit") {
        onCellEdit(func, vl);
      }
    }
  };

  const checkRefrecord = () => {
    axios
      .get(loca + "/lom/get/reference/qualifier/" + tableName.current, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then((resp) => {
        const rq_data = JSON.parse(JSON.stringify(resp.data));
        if (rq_data !== "") {
          setrecord_rq(rq_data);
          let script = uScript.current;
          // onload
          let userDetails = JSON.parse(localStorage.getItem("userDetails"));
          if (script?.length > 0) {
            for (let i = 0; i < script.length; i++) {
              // let field = script[i].field.name;
              let func = script[i].script;
              let type = script[i].type;
              if (type === "onload" && count.current === 1) {
                count.current = 0;
                onLoad(
                  func,
                  new ApUser(userDetails, setRecord),
                  new ApForm(
                    rcrd.current,
                    setRecord,
                    tabRe.current,
                    setTabRelation
                  )
                );
              } else {
                setTabRel(true);
              }
            }
            // console.log(count.current);
            // if (count.current===1) {
            // setTimeout(() => {
            setLoading(false);
            // }, 1000);
            // }
          } else {
            // setTimeout(() => {
            setLoading(false);
            setTabRel(true);
            // }, 1000);
          }
          // this.setState({ record_rq: rq_data });
        }
      });
  };

  const relationVerify = () => {
    // var token = localStorage.getItem("token");
    // this.setState({ relation_loading: true });

    let rvalue = refValue.current;
    var json = {
      relation: [
        {
          tabname: tableName.current,
          recordid: rid.current,
          value: rvalue,
        },
      ],
    };
    // console.log(json);

    setTabRel(false);
    let url = "";
    if (parentApp.current === "Marketplace") {
      url = `http://localhost:8084/marketplace/get/relation`;
    } else {
      url = loca + "/lom/get/relation";
    }
    axios
      .post(url, json, {
        headers: {
          authorization: "Bearer " + token,
          "market-application": "marketplace",
        },
      })
      .then(
        (resp) => {
          const rvrecord = resp.data;
          if ("Error" in rvrecord) {
            setLoading(false);
            setPageError(true);
            setError(rvrecord.error);
          } else {
            if (rvrecord.relation.length === 0) {
              setTabRelation(rvrecord);
              tabRe.current = rvrecord;
              // setTabRel(true);
            } else if ("Error" in rvrecord.relation[0]) {
            } else {
              setTabRelation(rvrecord);
              tabRe.current = rvrecord;
            }
            checkRefrecord();
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const onChange = (func, val, obj) => {
    let fn = new Function(["ap_user", "ap_form", "val", "obj"], func);
    fn(ap_user, ap_form, val, obj);
  };

  const onCellEdit = (func, val) => {
    let fn = new Function(["ap_user", "ap_form", "val"], func);
    fn(ap_user, ap_form, val);
  };

  const onSubmit = (func) => {
    let fn = new Function(
      ["ap_user", "ap_form", "navigation", "user", "createSearchParams"],
      func
    );
    fn(ap_user, ap_form, navigation, user, createSearchParams);
  };

  const onLoad = (func, user, form) => {
    let fn = new Function(
      ["ap_user", "ap_form", "appName", "recordType"],
      func
    );
    fn(user, form, appName, rty.current);
    setTimeout(() => {
      setLoading(false);
      setTabRel(true);
    }, 500);
  };

  const onReference = (func) => {
    let fn = new Function(["ap_user", "ap_form"], func);
    let result = fn(ap_user, ap_form);
    // let result=ap_form.getFieldValue('form_table_id').name
    if (result !== undefined) {
      setFilt(result);
      setref_filter([result]);
    }
  };

  const callfilter = async (filtarray, index, in_index, col_id) => {
    var rcd = record;
    console.log(filtarray, index, in_index, col_id);

    if (col_id !== -1) {
      if (
        (rcd[index].type === "filter" ||
          rcd[index].type === "filter_script" ||
          rcd[index].type === "condition") &&
        filtarray[in_index].ct === "choice"
        // && filtarray[in_index].ch.length === 0
      ) {
        let chc = await getChoiceRcd(col_id).then((res) => {
          console.log(res);

          if (res.length > 0) {
            filtarray[in_index].ch = res;
            filtarray[in_index].an = res[0].name;
          } else {
            filtarray[in_index].ch = [];
          }
        });

        //  console.log(chcRcd.current);
      }
      // }
    }
    rcd[index].value.filter = filtarray;
    setRecord([...rcd]);
    // setFiltArray([...filtarray]);
  };

  const calltimeline = (timeline, index) => {
    var rcd = record;
    //  console.log(timeline);
    // setTimeline(timeline);
    deftimeline.current = timeline;
    if (index != undefined && index != null) {
      rcd[index].value.timeline = timeline;
      setRecord([...rcd]);
    }
  };

  const callScript = (script, index) => {
    var rcd = record;
    rcd[index].value.script = script;
    // rcd[index].value = { filter: rcd[index].value.filter,script:script };
    // console.log(rcd[index].value);
    setRecord([...rcd]);
  };

  const getChoiceRcd = async (col_id) => {
    let chk = [];
    let ck = await axios
      .get(loca + "/lom/get/choice/" + col_id, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let res = resp.data;
          let chk = res.choiceRecords;
          return chk;
          // console.log(rcd);
        },
        (error) => {
          console.log(error);
        }
      );
    return ck;
  };

  const goToFormTable = () => {
    checkMenu.current = true;
    setTabRel(false);
    console.log(tabId);
    searchParam.set("rty", "record");
    searchParam.set("tableName", "form_table");
    searchParam.set("rid", tabId);
    setSearchParam(searchParam);
    // getRecordForm(m_rid.current, "form_column");
  };

  const goToColumn = () => {
    checkMenu.current = true;
    setTabRel(false);
    searchParam.set("rty", "record");
    searchParam.set("tableName", "form_column");
    searchParam.set("rid", m_rid.current);
    setSearchParam(searchParam);
    // getRecordForm(m_rid.current, "form_column");
  };

  const goToLabel = () => {
    checkMenu.current = true;
    axios
      .get(loca + "/lom/get/singlerecord/label/" + m_rid.current, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let lab_id = resp.data;
          if (
            lab_id !== null ||
            lab_id !== "" ||
            lab_id !== "null" ||
            lab_id !== undefined
          ) {
            searchParam.set("rty", "record");
            searchParam.set("tableName", "form_label");
            searchParam.set("rid", lab_id);
            setSearchParam(searchParam);
            // getRecordForm(lab_id, "form_label");
          }
        },
        (error) => {
          toast("Some thing went wrong", { type: "error" });
        }
      );
  };

  const goToUivalid = () => {
    checkMenu.current = true;
    let id = "";
    let type = "";
    for (let i = 0; i < viewList.length; i++) {
      if (viewList[i].selected === "true") {
        id = viewList[i].id;
        type = viewList[i].type;
      }
    }
    axios
      .get(
        loca +
          "/lom/get/singlerecord/uivalid/" +
          m_rid.current +
          "/" +
          searchParam.get("rty"),
        {
          headers: {
            authorization: "Bearer " + token,
          },
        }
      )
      .then(
        (resp) => {
          let lab_id = resp.data;
          if (
            lab_id !== null ||
            lab_id !== "" ||
            lab_id !== "null" ||
            lab_id !== undefined
          ) {
            searchParam.set("rty", "record");
            searchParam.set("tableName", "uivalidation");
            searchParam.set("rid", lab_id);
            setSearchParam(searchParam);
            // getRecordForm(lab_id, "form_label");
          }
        },
        (error) => {
          toast("Some thing went wrong", { type: "error" });
        }
      );
  };

  const copy = () => {
    localStorage.setItem("rcdJson", JSON.stringify(copyRcd));
    localStorage.setItem("tableName", tableName.current);
    let rcdJson = localStorage.getItem("rcdJson");
  };

  const paste = () => {
    // vl,index,ob,type,id,vrf,obj,outerIndex,innerIndex,nme,props
    setLoading(true);
    let rcdJson = localStorage.getItem("rcdJson");
    let tab = localStorage.getItem("tableName");
    let tabId = "";
    let rdJson = JSON.parse(rcdJson);
    let obj = "";
    if (tab === tableName.current) {
      var rcd = record;
      for (let i = 0; i < rdJson.length; i++) {
        for (let j = 0; j < rcd.length; j++) {
          if (rcd[j].name === rdJson[i].name) {
            // appending new values
            let vl = rdJson[i].value,
              index = j,
              ob = rcd[j].name,
              type = rcd[j].type,
              id = 0,
              vrf = true,
              obj = rcd[j],
              outerIndex = 0,
              innerIndex = 0,
              nme = "",
              props = null,
              depend_column = null,
              readonly = "false";
            if (type === "reference" || type === "depend_table") {
              vl = rdJson[i].value.name;
              id = rdJson[i].value.id;
              depend_column = rdJson[i].depend_column;
            }
            // calling form change function
            formChangefn(
              vl,
              index,
              ob,
              type,
              id,
              vrf,
              obj,
              outerIndex,
              innerIndex,
              nme,
              props,
              depend_column,
              readonly
            );
            // rcd[j].value = rdJson[i].value;
            // if (rdJson[i].name === "form_table_id") {
            //   console.log(rcd[j].value.id);
            //   tabId = rcd[j].value.id;
            //   if (tabId > 0) {
            //     callFieldList(tabId);
            //   }
            // }
            // if (rdJson[i].type === "multi_select") {
            //   console.log(rdJson[i].value);

            //   setMultiSv(rdJson[i].value);
            //   setAdd(true);
            // }
            // console.log(uiScript);
            // for (let k = 0; k < uiScript.length; k++) {
            //   let field = uiScript[k].field.name;
            //   console.log(field, rdJson[i].name);
            //   let func = uiScript[k].script;
            //   let type = uiScript[k].type;
            //   console.log(type);
            //   if (field === rdJson[i].name && type === "onchange") {
            //     console.log("runnner", func);
            //     onChange(func, rdJson[i].value, obj);
            //   }
            // }
          }
        }
      }
    }
    setTimeout(() => {
      setLoading(false);
    }, 200);
  };

  const refresh = () => {
    getScriptList();
    checkMenu.current = true;
    count.current = 1;
    // setRelationload(true);
    setShouldBlock(false);
    setChoice_mn([]);
    setLoading(true);
  };

  const back = () => {
    navigation(-1);
    // setLoading(true);
  };

  const menuFn = (body) => {
    let fn = new Function(
      [
        "back",
        "refresh",
        "copy",
        "paste",
        "navigation",
        "tableName",
        "goToColumn",
        "goToFormTable",
        "goToLabel",
        "goToUivalid",
        "user",
        "all_ref_cols",
        "tabId",
      ],
      body
    );
    fn(
      back,
      refresh,
      copy,
      paste,
      navigation,
      tableName.current,
      goToColumn,
      goToFormTable,
      goToLabel,
      goToUivalid,
      user,
      all_ref_cols,
      tabId
    );
  };

  const callColumnByColId = (id) => {
    if (id !== "0" && id !== "none") {
      setColumn_depend([]);
      axios
        .get(loca + "/lom/get/columnrecords/" + id, {
          headers: {
            authorization: "Bearer " + token,
          },
        })
        .then((resp) => {
          const coldata = resp.data;
          console.log(coldata);

          if (coldata !== "") {
            if ("Error" in coldata) {
              setLoading(false);
              setPageError(true);
              setError(coldata.Error);
            } else {
              let col_array = [];
              col_array.push({ id: "", name: "none", label: "None" });
              for (let c = 0; c < coldata.columnRecords.length; c++) {
                col_array.push(coldata.columnRecords[c]);
              }
              let choicearry = [];
              for (let z = 0; z < coldata.columnRecords.length; z++) {
                choicearry.push({
                  id: coldata.columnRecords[z].id,
                  name: coldata.columnRecords[z].name,
                  label: coldata.columnRecords[z].label,
                  value: coldata.columnRecords[z].name,
                  choice_order: z * 100 + "",
                  form_column_id: "",
                });
              }
              setColumn_depend(choicearry);
            }
          }
        });
    }
  };

  const callTableColumn = (val, ot, dep_col) => {
    console.log(val, ot, dep_col);

    if (val !== "" && val !== "none") {
      setbtnNamee(val);
      // setCol_mn([]);
      axios
        .get(loca + "/lom/get/column/" + val, {
          headers: {
            authorization: "Bearer " + token,
          },
        })
        .then((resp) => {
          const coldata = resp.data;
          console.log(coldata);

          if (coldata !== "") {
            if ("Error" in coldata) {
              setLoading(false);
              setPageError(true);
              setError(coldata.Error);
            } else {
              let col_array = [];
              col_array.push({ id: "", name: "none", label: "None" });
              for (let c = 0; c < coldata.columnRecords.length; c++) {
                col_array.push(coldata.columnRecords[c]);
              }
              let choicearry = [];
              let rcd = [];
              for (let i = 0; i < record.length; i++) {
                if (
                  record[i].depend_column === dep_col &&
                  record[i].name !== dep_col &&
                  record[i].type !== "filter" &&
                  record[i].type !== "condition"
                ) {
                  rcd.push(record[i]);
                } else if (
                  record[i].depend_column === dep_col &&
                  (record[i].type === "filter" ||
                    record[i].type === "condition")
                ) {
                  rcd.push(record[i]);
                }
              }
              for (let z = 0; z < coldata.columnRecords.length; z++) {
                choicearry.push({
                  id: coldata.columnRecords[z].id,
                  name: coldata.columnRecords[z].name,
                  label: coldata.columnRecords[z].label,
                  value: coldata.columnRecords[z].name,
                  choice_order: z * 100 + "",
                  form_column_id: "",
                });
              }
              for (let j = 0; j < rcd.length; j++) {
                let a = record.findIndex((e) => e.id === rcd[j].id);
                if (ot === true) {
                  let frecord = record;
                  frecord[a].dep_choice = choicearry;
                  setRecord(frecord);
                  setChoice_mn(choicearry);
                } else {
                  let frecord = record;
                  if (
                    frecord[a].type !== "filter" &&
                    frecord[a].type !== "condition"
                  ) {
                    frecord[a].dep_choice = choicearry;
                    setRecord(frecord);
                  } else {
                    frecord[a].dep_choice = col_array;
                    setRecord(frecord);
                    setCol_mn(col_array);
                  }
                }
                setChoice_mn(choicearry);
                setLoading(false);
              }
            }
          }
        });
    } else {
      setCol_mn([]);
    }
  };

  let blocker = useBlocker(() => {
    return shouldBlock;
  });

  // const importt = (files) => {
  //   if (
  //     files.length !== 0 &&
  //     files[0].name.replace(/(\(\d+\))|(\.csv$)/g, "").trim() === tableName
  //   ) {
  //     setIsClick(true);
  //     const blob = new Blob([files[0].content], { type: "text/csv" });
  //     const file = new File([blob], files[0].name, {
  //       type: "text/csv",
  //     });
  //     const formData = new FormData();
  //     formData.append("file", file);
  //     axios
  //       .post(loca + "/data/import", formData, {
  //         headers: {
  //           "Content-Type": "multipart/form-data",
  //           authorization: "Bearer " + token,
  //         },
  //       })
  //       .then(
  //         (resp) => {
  //           const record = resp.data;
  //           toast(record, {
  //             position: "top-center",
  //             theme: "colored",
  //             type: "success",
  //             style: {
  //               marginBottom: userDetails.OS !== "null" ? 12 : 0,
  //             },
  //           });
  //           clear();
  //           refresh();
  //         },
  //         (error) => {
  //           setLoading(false);
  //           setModal(false);
  //           toast("Something went wrong", {
  //             position: "top-center",
  //             theme: "colored",
  //             type: "error",
  //           });
  //         }
  //       )
  //       .finally(() => {
  //         setShowFilePicker(false);
  //         setIsClick(false);
  //       });
  //   } else {
  //     toast("Table not match", {
  //       position: "top-center",
  //       theme: "colored",
  //       type: "error",
  //     });
  //     setShowFilePicker(false);
  //     clear();
  //   }
  // };

  const cleanMapKeys = (obj) => {
    if (Array.isArray(obj)) {
      return obj.map(cleanMapKeys); // Recursively process array elements
    } else if (typeof obj === "object" && obj !== null) {
      const cleanedObj = {};
      for (const key in obj) {
        if (key === "map" && typeof obj[key] === "object") {
          // If the key is "map", merge its properties with the parent
          Object.assign(cleanedObj, cleanMapKeys(obj[key]));
        } else if (key === "myArrayList" && Array.isArray(obj[key])) {
          // If the key is "myArrayList", unwrap the array
          return cleanMapKeys(obj[key]);
        } else {
          cleanedObj[key] = cleanMapKeys(obj[key]); // Process nested objects
        }
      }
      return cleanedObj;
    }
    return obj; // Return the value if it's not an array or object
  };

  const download = (res, tablenm) => {
    const element = document.createElement("a");

    const cleanedData = cleanMapKeys(res);
    const jsonString = JSON.stringify(cleanedData, null, 2);
    element.href = `data:application/json;charset=utf-8,${encodeURIComponent(
      jsonString
    )}`;
    element.download = `${tablenm}.json`;
    // for image
    // element.href = `data:image/png;base64,${res}`;
    // element.download = 'image';
    element.click();
    element.remove();
  };

  const exportData = (mnrecord, url) => {
    console.log(mnrecord, url);
    axios
      .post(loca + url, mnrecord, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          const record = resp.data;
          download(record, mnrecord.formRecord[1].table.name);
        },
        (error) => {
          console.log(error);
          toast("Something went wrong", {
            position: "top-center",
            theme: "colored",
            type: "error",
          });
        }
      )
      .finally(() => {
        setSubmitLoading(false);
        // setLoading(false);
        setIsClick(false);
        console.log("isClick finally");
      });
  };

  useEffect(() => {
    if (searchParam.get("rty") === "new" && location) {
      console.log("dataa", record);
      ref_cols_insert();
    }
  }, [formChange]);

  useEffect(() => {
    count.current = 1;
    // setRelationload(true);
    if (dash_tabname) {
      // Sub Form Component

      dashBoardForm();
    } else {
      // Parent Form Component

      if (location?.fieldName) {
        recordNew = location.recordNew;
        fieldName = location.fieldName;
      }
      let p = user + "/form";
      if (pathName === p) {
        getScriptList();
      }
    }

    document
      .getElementsByTagName("body")
      .item(0)
      .addEventListener("mousedown", () => {
        setShowContextMenu(false);
        setShowContext(false);
      });

    return () => {
      setShowModelInfo(false);
    };
  }, [searchParam]);
  useEffect(() => {
    let h = document
      .getElementById("form" + tableName.current)
      ?.getBoundingClientRect().height;
    if (h > 10) {
      setOverlayH(h);
    }
  }, [loading]);

  useEffect(() => {
    if (submitLoading) {
      const messageTimeouts = [
        setTimeout(() => {
          loadtext.current?.classList.add("show");
          setSubLoadAck("Hang tight, we're processing your request...");
        }, 2000),
        setTimeout(
          () =>
            setSubLoadAck("Working on it... This might take a few moments."),
          7000
        ),
      ];

      // Clear timeouts when component unmounts
      return () => {
        loadtext.current?.classList.add("hide");
        setSubLoadAck("");
        messageTimeouts.forEach((timeout) => clearTimeout(timeout));
      };
    }
  }, [submitLoading]);
  try {
    if (!loading) {
      return (
        <div id={"form" + tableName.current} className="pagesetup">
          {submitLoading && (
            <>
              <div
                style={{ height: overlayH, width: "75%" }}
                className="loading-overlay d-flex align-items-center justify-content-center"
              ></div>
              <div style={{ height: overlayH }} className="loading-ack">
                <h3 ref={loadtext} className="loading-msg">
                  {subLoadAck}
                </h3>
              </div>
            </>
          )}
          {invRcd && (
            <Invoice
              inv={invRcd}
              rcd={rcdView[1].value}
              setInvRcd={setInvRcd}
            />
          )}
          <Modal show={modal} onHide={() => setModal(!modal)}>
            <Modal.Header closeButton>
              <Modal.Title>Confirm {btnNamee}</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setModal(!modal)}
                disabled={isClick}
                style={{ backgroundColor: isClick ? "gray" : "" }}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                onClick={() => callbtn(btnNamee)}
                disabled={isClick}
                style={{ backgroundColor: isClick ? "gray" : "" }}
              >
                {btnNamee}
              </Button>
            </Modal.Footer>
          </Modal>
          {/* <Modal
            show={showFilePicker}
            onHide={() => setShowFilePicker(!showFilePicker)}
          >
            <Modal.Header closeButton onClick={() => clear()}>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body className="" sty>
              <div>
                {filesContent.length === 0 && (
                  <button onClick={() => openFilePicker()}>
                    Select files{" "}
                  </button>
                )}
                {filesContent.map((file, index) => (
                  <div key={index}>
                    <div key={file.name}>{file.name}</div>
                  </div>
                ))}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => clear()}>
                Clear
              </Button>
              <Button
                variant="danger"
                onClick={() => importt(filesContent)}
                disabled={isClick}
                style={{ backgroundColor: isClick ? "gray" : "" }}
              >
                Upload
              </Button>
            </Modal.Footer>
          </Modal> */}

          {showContextMenu && labelContext.length > 0 && (
            <ContextMenu
              menuX={menuX}
              menuY={menuY}
              contextMenu={labelContext}
              menuFn={menuFn}
              conditionFn={labelContext[0].cid === cid}
            />
          )}
          {showContext && contextMenu.length > 0 && (
            <ContextMenu
              menuX={menuX}
              menuY={menuY}
              contextMenu={contextMenu}
              menuFn={menuFn}
              excluded_menu={["import_data", "export_data", "open_in_new_tab"]}
              conditionFn={true}
            />
          )}
          <div
            className="btndiv"
            onContextMenu={(e) => {
              e.preventDefault();
              // m_rid.current = obj.id;
              if (isSub.current) {
                let parent = document
                  .getElementById(form_id)
                  .getBoundingClientRect();
                setContext(
                  e.button,
                  e.nativeEvent.clientX - parent.left,
                  e.nativeEvent.clientY - parent.top
                );
              } else {
                setContext(e.button, e.nativeEvent.pageX, e.nativeEvent.pageY);
              }
            }}
          >
            {isMobile ? (
              <div className="row fr">
                <div
                  onTouchStart={(e) => {
                    setTimeout(() => {
                      e.preventDefault();
                      // m_rid.current = obj.id;
                      setContext(2, e.touches[0].pageX, e.touches[0].pageY);
                    }, 100);
                  }}
                  className="col-md tab_head"
                  style={{ alignSelf: "center" }}
                >
                  {tablabel}
                </div>

                <div
                  className="col d-flex "
                  style={{ alignSelf: "center", textAlign: "start" }}
                >
                  <span className="align-self-center">
                    <i
                      disabled={isClick}
                      className=" backic fa fa-arrow-left bck_btt_mrg"
                      onClick={() => {
                        if (!isClick) {
                          navigation(-1);
                        }
                      }}
                    ></i>
                  </span>
                  <Dropdown className="col-sm">
                    <span style={{ width: "30px" }}>
                      <Dropdown.Toggle
                        className="text-white "
                        variant="Dark"
                        id="dropdown-basic"
                      >
                        <i className="fa fa-wpforms"></i>
                      </Dropdown.Toggle>
                    </span>
                    <Dropdown.Menu>
                      {viewList?.length > 0 &&
                        viewList.map((obj, ind) => (
                          <Dropdown.Item
                            onClick={() => {
                              changeView(ind);
                            }}
                            key={ind}
                            className="d-flex justify-content-between fw-medium"
                            href="#"
                          >
                            <span>{obj.name}</span>
                            {obj.selected === "true" && (
                              <span>
                                <i
                                  style={{ color: "green" }}
                                  className="fa fa-check"
                                ></i>
                              </span>
                            )}
                          </Dropdown.Item>
                        ))}
                      <Dropdown.Divider />
                      <Dropdown.Item
                        onClick={() => {
                          if (tableName.current !== "view_rule") {
                            navigation({
                              pathname: user + "/form",
                              search: createSearchParams({
                                parentApplication: parentApp.current,
                                tableName: "view_rule",
                                rty: "new",
                              }).toString(),
                            });
                          } else {
                            toast.info("You are already on view_rule page");
                          }
                        }}
                        className="d-flex justify-content-between fw-medium"
                        href="#"
                      >
                        <span>Create New View</span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <div className="col" style={{ alignSelf: "center" }}>
                  <div className="btndivin my-1">
                    {button.map((obj, oo_i) => {
                      if (
                        obj.name === "make_current" &&
                        tableName.current === "updatejson" &&
                        selectedJson
                      ) {
                        return;
                      } else {
                        return (
                          <button
                            className=" insrtbtn2 btn btn-primary btn-sm mx-1"
                            key={oo_i}
                            onClick={() => {
                              if (obj.webUrl === "/lom/delete/record") {
                                setbtnNamee(obj.name);

                                setModal(true);
                              } else if (obj.webUrl === "/invoice") {
                                invoice(obj.webUrl);
                                // navigation(
                                //   obj.webUrl.toString(), { state: { rcd: rcdView } }
                                // );
                              } else {
                                callbtn(obj.value);
                              }
                            }}
                            disabled={isClick}
                            style={{ backgroundColor: isClick ? "gray" : "" }}
                          >
                            {obj.value}
                          </button>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div className="row fr">
                <div
                  className="col-md-3 d-flex "
                  style={{ alignSelf: "center", textAlign: "start" }}
                >
                  <span className="align-self-center">
                    <i
                      disabled={isClick}
                      className=" backic fa fa-arrow-left bck_btt_mrg"
                      onClick={() => {
                        if (!isClick) {
                          navigation(-1);
                        }
                      }}
                    ></i>
                  </span>
                  <Dropdown className="col-sm">
                    <span style={{ width: "30px" }}>
                      <Dropdown.Toggle
                        className="text-white "
                        variant="Dark"
                        id="dropdown-basic"
                      >
                        <i className="fa fa-wpforms"></i>
                      </Dropdown.Toggle>
                    </span>
                    <Dropdown.Menu>
                      {viewList?.length > 0 &&
                        viewList.map((obj, ind) => (
                          <Dropdown.Item
                            onClick={() => {
                              changeView(ind);
                            }}
                            key={ind}
                            className="d-flex justify-content-between fw-medium"
                            href="#"
                          >
                            <span>{obj.name}</span>
                            {obj.selected === "true" && (
                              <span>
                                <i
                                  style={{ color: "green" }}
                                  className="fa fa-check"
                                ></i>
                              </span>
                            )}
                          </Dropdown.Item>
                        ))}
                      <Dropdown.Divider />
                      <Dropdown.Item
                        onClick={() => {
                          if (tableName.current !== "view_rule") {
                            navigation({
                              pathname: user + "/form",
                              search: createSearchParams({
                                parentApplication: parentApp.current,
                                tableName: "view_rule",
                                rty: "new",
                              }).toString(),
                            });
                          } else {
                            toast.info("You are already on view_rule page");
                          }
                        }}
                        className="d-flex justify-content-between fw-medium"
                        href="#"
                      >
                        <span>Create New View</span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                {/* <div className="col-md-3"></div> */}
                <div
                  onTouchStart={(e) => {
                    setTimeout(() => {
                      e.preventDefault();
                      // m_rid.current = obj.id;
                      setContext(2, e.touches[0].pageX, e.touches[0].pageY);
                    }, 100);
                  }}
                  className="col-md tab_head"
                  style={{ alignSelf: "center" }}
                >
                  {tablabel}
                </div>
                <div className="col-md-3" style={{ alignSelf: "center" }}>
                  <div className="btndivin my-1">
                    {button.map((obj, oo_i) => {
                      if (
                        obj.name === "make_current" &&
                        tableName.current === "updatejson" &&
                        selectedJson
                      ) {
                        return;
                      } else {
                        return (
                          <button
                            className=" insrtbtn2 btn btn-primary btn-sm mx-1"
                            key={oo_i}
                            onClick={() => {
                              if (obj.webUrl === "/lom/delete/record") {
                                setbtnNamee(obj.name);

                                setModal(true);
                              } else if (obj.webUrl === "/invoice") {
                                invoice(obj.webUrl);
                                // navigation(
                                //   obj.webUrl.toString(), { state: { rcd: rcdView } }
                                // );
                              } else {
                                callbtn(obj.value);
                              }
                            }}
                            disabled={isClick}
                            style={{ backgroundColor: isClick ? "gray" : "" }}
                          >
                            {obj.value}
                          </button>
                        );
                      }
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className=" maincompo ">
            {console.log(page_error)}

            {page_error && <AlertCompo type={"danger"} msg={error} />}
            {page_message && <AlertCompo type={"success"} msg={message} />}
            {record.length === 0 && <div>Dont have response...</div>}
            {record.length > 0 && (
              <div style={{ textAlign: "start" }}>
                <div>
                  {rcdView.map((obj, index) => (
                    <div key={index}>
                      {/* <div> */}
                      {obj.split === "false" && (
                        <span key={index}>
                          <FormInnerCompo
                            index={index}
                            mscList={mscList}
                            setMSC={setMSC}
                            obj={obj}
                            rid={rid.current}
                            record={record}
                            setRecord={setRecord}
                            m_rid={m_rid}
                            setContextMenu={setContextMenu}
                            formChangefn={formChangefn}
                            validationfn={validationfn}
                            passwordReadOnly={passwordReadOnly}
                            tableName={tableName.current}
                            selection={selection}
                            setShowSuggestion={setShowSuggestion}
                            getCaretPos={getCaretPos}
                            setY={setY}
                            setX={setX}
                            recordSuggestion={recordSuggestion}
                            userRecordSuggestion={userRecordSuggestion}
                            setSearchrcd={setSearchrcd}
                            searchModule={searchModule}
                            afterCellEdit={afterCellEdit}
                            x={x}
                            y={y}
                            showSuggestion={showSuggestion}
                            searchrcd={searchrcd}
                            ListGroup={ListGroup}
                            listRef={listRef}
                            onSelection={onSelection}
                            clickRefrence={clickRefrence}
                            getSingleInfo={getSingleInfo}
                            refrecord={refrecord}
                            showContainer={showContainer}
                            setRefRcd={setRefRcd}
                            showlist={showlist}
                            col_mn={col_mn}
                            callfilter={callfilter}
                            col_depend={column_depend}
                            dcFiltVal={dcFiltVal}
                            setFiltArray={setFiltArray}
                            timeline={timeline}
                            calltimeline={calltimeline}
                            callScript={callScript}
                            reScript={reScript}
                            deleteImage={deleteImage}
                            handleFileChange={handleFileChange}
                            setImgObj={setImgObj}
                            imgObj={imgObj}
                            launchChoose={launchChoose}
                            JoditEditor={JoditEditor}
                            editor={editor}
                            choice_mn={choice_mn}
                            choice_ref_mn={choice_ref_mn}
                            verifyError={verifyError}
                            multiSv={multiSv}
                            add={add}
                            deftimeline={deftimeline}
                            isSub={isSub}
                            form_id={form_id}
                          ></FormInnerCompo>
                        </span>
                      )}

                      {/* </div> */}
                      {obj.split === "true" && (
                        <div className={isMobile ? "" : "row"}>
                          <div className="col-md-6">
                            {obj.rcd.map((obj_i, ind_i) => (
                              <div key={ind_i}>
                                {obj_i.formView.position === "left" && (
                                  <span>
                                    <FormInnerCompo
                                      obj={obj_i}
                                      rid={rid.current}
                                      m_rid={m_rid}
                                      index={index}
                                      record={record}
                                      setRecord={setRecord}
                                      setContextMenu={setContextMenu}
                                      formChangefn={formChangefn}
                                      validationfn={validationfn}
                                      passwordReadOnly={passwordReadOnly}
                                      tableName={tableName.current}
                                      selection={selection}
                                      setShowSuggestion={setShowSuggestion}
                                      getCaretPos={getCaretPos}
                                      setY={setY}
                                      setX={setX}
                                      recordSuggestion={recordSuggestion}
                                      userRecordSuggestion={
                                        userRecordSuggestion
                                      }
                                      setSearchrcd={setSearchrcd}
                                      searchModule={searchModule}
                                      afterCellEdit={afterCellEdit}
                                      x={x}
                                      y={y}
                                      showSuggestion={showSuggestion}
                                      searchrcd={searchrcd}
                                      ListGroup={ListGroup}
                                      listRef={listRef}
                                      onSelection={onSelection}
                                      clickRefrence={clickRefrence}
                                      getSingleInfo={getSingleInfo}
                                      refrecord={refrecord}
                                      showContainer={showContainer}
                                      setRefRcd={setRefRcd}
                                      showlist={showlist}
                                      col_mn={col_mn}
                                      callfilter={callfilter}
                                      col_depend={column_depend}
                                      dcFiltVal={dcFiltVal}
                                      setFiltArray={setFiltArray}
                                      timeline={timeline}
                                      calltimeline={calltimeline}
                                      callScript={callScript}
                                      reScript={reScript}
                                      JoditEditor={JoditEditor}
                                      deleteImage={deleteImage}
                                      setImgObj={setImgObj}
                                      imgObj={imgObj}
                                      launchChoose={launchChoose}
                                      handleFileChange={handleFileChange}
                                      editor={editor}
                                      choice_mn={choice_mn}
                                      choice_ref_mn={choice_ref_mn}
                                      verifyError={verifyError}
                                      mscList={mscList}
                                      setMSC={setMSC}
                                      multiSv={multiSv}
                                      add={add}
                                      deftimeline={deftimeline}
                                      isSub={isSub}
                                      form_id={form_id}
                                    ></FormInnerCompo>
                                  </span>
                                )}
                              </div>
                            ))}
                          </div>
                          <div className="col-md-6">
                            {obj.rcd.map((obj_i, ind_i) => (
                              <div key={ind_i}>
                                {obj_i.formView.position === "right" && (
                                  <span key={ind_i}>
                                    <FormInnerCompo
                                      obj={obj_i}
                                      rid={rid.current}
                                      m_rid={m_rid}
                                      index={index}
                                      record={record}
                                      setRecord={setRecord}
                                      mscList={mscList}
                                      setMSC={setMSC}
                                      setContextMenu={setContextMenu}
                                      formChangefn={formChangefn}
                                      validationfn={validationfn}
                                      passwordReadOnly={passwordReadOnly}
                                      tableName={tableName.current}
                                      selection={selection}
                                      setShowSuggestion={setShowSuggestion}
                                      getCaretPos={getCaretPos}
                                      setY={setY}
                                      setX={setX}
                                      recordSuggestion={recordSuggestion}
                                      userRecordSuggestion={
                                        userRecordSuggestion
                                      }
                                      setSearchrcd={setSearchrcd}
                                      searchModule={searchModule}
                                      afterCellEdit={afterCellEdit}
                                      x={x}
                                      y={y}
                                      ref_filter={filtRefArray}
                                      col_mn_ref={col_mn_ref}
                                      setref_filter={setFiltRefArray}
                                      showSuggestion={showSuggestion}
                                      searchrcd={searchrcd}
                                      ListGroup={ListGroup}
                                      listRef={listRef}
                                      onSelection={onSelection}
                                      clickRefrence={clickRefrence}
                                      getSingleInfo={getSingleInfo}
                                      refrecord={refrecord}
                                      showContainer={showContainer}
                                      setRefRcd={setRefRcd}
                                      showlist={showlist}
                                      col_mn={col_mn}
                                      callfilter={callfilter}
                                      col_depend={column_depend}
                                      dcFiltVal={dcFiltVal}
                                      setFiltArray={setFiltArray}
                                      timeline={timeline}
                                      calltimeline={calltimeline}
                                      callScript={callScript}
                                      reScript={reScript}
                                      deleteImage={deleteImage}
                                      setImgObj={setImgObj}
                                      imgObj={imgObj}
                                      launchChoose={launchChoose}
                                      handleFileChange={handleFileChange}
                                      JoditEditor={JoditEditor}
                                      editor={editor}
                                      choice_mn={choice_mn}
                                      choice_ref_mn={choice_ref_mn}
                                      verifyError={verifyError}
                                      multiSv={multiSv}
                                      add={add}
                                      isSub={isSub}
                                      form_id={form_id}
                                    ></FormInnerCompo>
                                  </span>
                                )}
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}

                  {tableName.current === "updatejson" &&
                    rty.current === "record" && (
                      <div className="form-check fmcheck ">
                        <h3 className="text-center underline">
                          Shows Update Json Selected or not
                        </h3>
                        <input
                          type="checkbox"
                          className="checkpadd"
                          checked={selectedJson}
                          disabled={true}
                          // onChange={(e) => {
                          //   formChangefn(
                          //     e.target.checked.toString(),
                          //     obj.index,
                          //     obj.name,
                          //     obj.type,
                          //     obj.id,
                          //     false
                          //   );
                          // }}
                          // onBlur={(e) =>
                          //   validationfn(
                          //     e.target.value,
                          //     obj.index,
                          //     obj.name,
                          //     obj.type,
                          //     obj.id
                          //   )
                          // }
                        ></input>
                        <span
                          // id={"tiptar" + obj.index}
                          className="field_hd"
                          // onTouchStart={(e) => {
                          //   setTimeout(() => {
                          //     e.preventDefault();
                          //     m_rid.current = obj.id;
                          //     setContextMenu(2, e.touches[0].pageX, e.touches[0].pageY);
                          //   }, 100);
                          // }}
                          // onContextMenu={(e) => {
                          //   e.preventDefault();
                          //   m_rid.current = obj.id;
                          //   if (isSub.current) {
                          //     let parent = document
                          //       .getElementById(form_id)
                          //       .getBoundingClientRect();
                          //     setContextMenu(
                          //       e.button,
                          //       e.nativeEvent.clientX - parent.left,
                          //       e.nativeEvent.clientY - parent.top
                          //     );
                          //   } else {
                          //     setContextMenu(
                          //       e.button,
                          //       e.nativeEvent.pageX,
                          //       e.nativeEvent.pageY
                          //     );
                          //   }
                          // }}
                          // data-bs-custom-class="tooltip"
                          // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
                        >
                          Selected
                        </span>
                      </div>
                    )}
                </div>
                <div className="form-group">
                  {tableName.current === "local_user" &&
                    rty.current === "new" && (
                      <button
                        className=" insrtbtn2 btn btn-primary btn-sm"
                        onClick={(e) => {
                          generatePassword();
                        }}
                        disabled={isClick}
                        style={{ backgroundColor: isClick ? "gray" : "" }}
                      >
                        Generate password
                      </button>
                    )}
                  {/* {modal && <ModalComponent setModal={setModal} modalValue={modalValue} />} */}
                  {button.map((obj, oo_i) => {
                    if (
                      obj.name === "make_current" &&
                      tableName.current === "updatejson" &&
                      selectedJson
                    ) {
                      return;
                    }
                    if (
                      obj.ui_type === "form_button" ||
                      obj.ui_type === "market_button"
                    ) {
                      return (
                        <button
                          className=" insrtbtn2 btn btn-primary btn-sm mx-1 "
                          key={oo_i}
                          onClick={() => {
                            if (obj.webUrl === "/lom/delete/record") {
                              setbtnNamee(obj.name);
                              setModal(true);
                            } else if (obj.webUrl === "/invoice") {
                              invoice(obj.webUrl);
                              // navigation(
                              //   obj.webUrl.toString(), { state: { rcd: rcdView } }
                              // );
                            } else {
                              callbtn(obj.value);
                            }
                          }}
                          // onClick={(e) => callbtn(obj.value)}

                          disabled={isClick}
                          style={{ backgroundColor: isClick ? "gray" : "" }}
                        >
                          {obj.value}
                        </button>
                      );
                    }
                  })}
                </div>
                {button.map(
                  (obj, oo_i) =>
                    obj.ui_type === "form_link" && (
                      <a
                        href=""
                        onClick={(event) => {
                          event.preventDefault();
                          navigation(
                            obj.webUrl.toString()
                            // pathname: user + "/form", search: createSearchParams({ rty: "new", tableName: "application" }).toString()
                          );
                        }}
                      >
                        {obj.name}
                      </a>
                    )
                )}
                {downRcdView.map((obj, index) => (
                  <div key={index}>
                    {obj.uivalid.visible === "true" && (
                      <div>
                        {activity.map((obj, ind) => (
                          <div key={ind}>
                            <div
                              className="card mt-2"
                              /* style="width: 18rem;" */ style={{
                                width: "60rem",
                                marginLeft: "200px",
                              }}
                            >
                              <div className="card-body ">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex ">
                                    <i
                                      className="fa fa-user-circle-o me-2"
                                      data-toggle="tooltip"
                                      data-placement="bottom"
                                      title="Profile"
                                    ></i>

                                    <b>
                                      <h6>{obj.noteBy}</h6>
                                    </b>
                                  </div>
                                  <div className="d-flex ">
                                    <h6 className="me-4">{obj.columnName}</h6>
                                    <h6>{obj.update}</h6>
                                  </div>
                                </div>
                                <div style={{ textAlign: "start" }}>
                                  <h6>{obj.content}</h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
                {console.log("frm3")}
                <div>
                  {/* {!relation_loading ? ( */}
                  <div>
                    {tabRel &&
                      tabRelation !== undefined &&
                      rty.current === "record" && (
                        <div>
                          {console.log(tabRelation, tabRel)}
                          {tabRelation?.relation?.length > 0 ? (
                            <div className="form_tab">
                              <Tab.Container
                                id="left-tabs-example"
                                defaultActiveKey={
                                  tabRelation.relation[
                                    tabRelation.relation.findIndex(
                                      (o, i) => o.visible === "true"
                                    )
                                  ].formRecordList[1].table.label
                                }
                              >
                                <Nav
                                  variant="pills"
                                  className="flex-column ownNav taboverflow"
                                >
                                  {/* <Row className="rw">
                                    {console.log(
                                      "tableRelation : " +
                                      JSON.stringify(
                                        tabRelation.relation[
                                          tabRelation.relation.findIndex(
                                            (o, i) => o.visible === "true"
                                          )
                                        ].formRecordList[1].table.label
                                      )
                                    )}
                                    {tabRelation.relation.map(
                                      (nama, tab_ia) => (
                                        <Col
                                          style={{
                                            display:
                                              nama.visible === "true"
                                                ? ""
                                                : "none",
                                          }}
                                          md={2}
                                          key={tab_ia}
                                          className="nopad"
                                        >
                                          {console.log(nama.visible)}
                                          {isMobile ? (
                                            <Nav.Item className="cur">
                                              <Nav.Link
                                                className=""
                                                eventKey={
                                                  nama.formRecordList[1].table
                                                    .label
                                                }
                                              >
                                                {
                                                  nama.formRecordList[1].table
                                                    .label
                                                }
                                              </Nav.Link>
                                            </Nav.Item>
                                          ) : (
                                            <Nav.Item
                                              color="black"
                                              className="cur"
                                            >
                                              <Nav.Link
                                                eventKey={
                                                  nama.formRecordList[1].table
                                                    .label
                                                }
                                              >
                                                {console.log(
                                                  nama.formRecordList[1].table
                                                    .label
                                                )}
                                                {
                                                  nama.formRecordList[1].table
                                                    .label
                                                }
                                              </Nav.Link>
                                            </Nav.Item>
                                          )}
                                        </Col>
                                      )
                                    )}
                                  </Row> */}
                                  <div className="rel_flex rw ">
                                    {console.log(
                                      "tableRelation : " +
                                        JSON.stringify(
                                          tabRelation.relation[
                                            tabRelation.relation.findIndex(
                                              (o, i) => o.visible === "true"
                                            )
                                          ].formRecordList[1].table.label
                                        )
                                    )}
                                    {tabRelation.relation.map(
                                      (nama, tab_ia) => (
                                        <div
                                          style={{
                                            display:
                                              nama.visible === "true"
                                                ? ""
                                                : "none",
                                          }}
                                          md={2}
                                          key={tab_ia}
                                          className="nopad"
                                        >
                                          {isMobile ? (
                                            <Nav.Item
                                              className="cur"
                                              color="black"
                                            >
                                              <Nav.Link
                                                className=""
                                                eventKey={
                                                  nama.formRecordList[1].table
                                                    .label
                                                }
                                              >
                                                {nama.formRecordList[3].page
                                                  .record_count !== "0"
                                                  ? nama.formRecordList[1].table
                                                      .label +
                                                    " (" +
                                                    nama.formRecordList[3].page
                                                      .record_count +
                                                    ")"
                                                  : nama.formRecordList[1].table
                                                      .label}
                                              </Nav.Link>
                                            </Nav.Item>
                                          ) : (
                                            <Nav.Item
                                              className="cur"
                                              color="black"
                                            >
                                              <Nav.Link
                                                eventKey={
                                                  nama.formRecordList[1].table
                                                    .label
                                                }
                                              >
                                                {nama.formRecordList[3].page
                                                  .record_count !== "0"
                                                  ? nama.formRecordList[1].table
                                                      .label +
                                                    " (" +
                                                    nama.formRecordList[3].page
                                                      .record_count +
                                                    ")"
                                                  : nama.formRecordList[1].table
                                                      .label}
                                              </Nav.Link>
                                            </Nav.Item>
                                          )}
                                        </div>
                                      )
                                    )}
                                  </div>
                                </Nav>

                                <Tab.Content>
                                  {tabRelation.relation.map((nam, tab_i) => (
                                    <Tab.Pane
                                      key={tab_i}
                                      eventKey={
                                        nam.formRecordList[1].table.label
                                      }
                                    >
                                      <RelationList
                                        listname={
                                          nam.formRecordList[1].table.label
                                        }
                                        tableName={
                                          nam.formRecordList[1].table.name
                                        }
                                        recordList={nam}
                                        i_d={i_d.current}
                                        prevTab={tableName.current}
                                        rd={record}
                                        all_ref_cols={all_ref_cols}

                                        // showFormCompo={showFormCompo(nm, rid, ty)}
                                      ></RelationList>
                                    </Tab.Pane>
                                  ))}
                                </Tab.Content>
                              </Tab.Container>
                            </div>
                          ) : null}
                        </div>
                      )}
                  </div>
                  {/* ) : (
                    <div className="related-loader mt-3">
                      <WorkInProgress/>
                    </div>
                  )} */}
                </div>
              </div>
            )}
          </div>
          <Modal show={showModelList} onHide={cancelModelList} size="xl">
            <Modal.Header closeButton>
              <Modal.Title>Personalized List Column</Modal.Title>
            </Modal.Header>
            <Modal.Body className="mod-ht">
              <ModelList
                setRef={setRef}
                columnid={columnId}
                onCancel={cancelModelList}
                previousFilter={ref_filter}
                previousTimeLine={" "}
                tableName={objLabel}
                tabName={objName}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={cancelModelList}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          {/* Block navigation modal  */}
          <Modal backdrop show={blocker.state === "blocked"}>
            <Modal.Body className="pb-0">
              <h5 className="text-secondary">
                Your unsaved changes will be lost! Still leave?
              </h5>
            </Modal.Body>
            <Modal.Footer className="p-2 border-top-0">
              <Button
                size="sm"
                variant="danger"
                onClick={() => {
                  setShouldBlock(false);
                  blocker.proceed();
                }}
              >
                Yes
              </Button>
              <Button
                size="sm"
                variant="secondary"
                onClick={() => blocker.reset()}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
          {showModelInfo === true && (
            <DragModel
              setShowModelInfo={setShowModelInfo}
              rcdView={infoRcdView}
              showModelInfo={showModelInfo}
              closeRef={closeRef}
              rid={rid}
              m_rid={m_rid}
              tableName={tableName}
              infoRcdDetail={infoRcdDetail}
              selection={selection}
              setShowSuggestion={setShowSuggestion}
              getCaretPos={getCaretPos}
              setY={setY}
              setX={setX}
              recordSuggestion={recordSuggestion}
              userRecordSuggestion={userRecordSuggestion}
              setSearchrcd={setSearchrcd}
              searchModule={searchModule}
              afterCellEdit={afterCellEdit}
              x={x}
              y={y}
              showSuggestion={showSuggestion}
              searchrcd={searchrcd}
              ListGroup={ListGroup}
              getSingleInfo={getSingleInfo}
              refrecord={refrecord}
              showContainer={showContainer}
              setRefRcd={setRefRcd}
              showlist={showlist}
              col_mn={col_mn}
              callfilter={callfilter}
              column_depend={column_depend}
              dcFiltVal={dcFiltVal}
              setFiltArray={setFiltArray}
              timeline={timeline}
              calltimeline={calltimeline}
              callScript={callScript}
              reScript={reScript}
              deleteImage={deleteImage}
              handleFileChange={handleFileChange}
              setImgObj={setImgObj}
              imgObj={imgObj}
              launchChoose={launchChoose}
              JoditEditor={JoditEditor}
              editor={editor}
              choice_mn={choice_mn}
              choice_ref_mn={choice_ref_mn}
              verifyError={verifyError}
              multiSv={multiSv}
              add={add}
              deftimeline={deftimeline}
              isMobile={isMobile}
              filtRefArray={filtRefArray}
              setFiltRefArray={setFiltRefArray}
            ></DragModel>
          )}
          {console.log("frm6")}
        </div>
      );
    } else {
      return (
        <div
          className="d-flex justify-content-center align-items-center w-100"
          style={{ height: "95vh" }}
        >
          <WorkInProgress />
        </div>
      );
    }
  } catch (error) {
    console.log(error);
    let err = { message: error };
    navigation("/error", { state: { err: error } });
  }
};

export default FormComponent;

// const formChangefn = (
//   vl,
//   index,
//   ob,
//   type,
//   id,
//   vrf,
//   obj,
//   outerIndex,
//   innerIndex,
//   nme,
//   props,
//   depend_column,
//   readonly
// ) => {
//   setShouldBlock(true);

//   if (ob === "form_table_id" && vl === "") {
//     setChoice_mn([]);
//   }

//   if (ob === "item_details_id") {
//     let a = record.findIndex((e) => e.name === ob);
//     index = a;
//     setItemVal(record[a]);
//   }

//   if (type === "reference" && vl.length < 3) {
//     let a = record.findIndex(
//       (e) => e.depend_column === obj.name && e.name !== obj.name
//     );
//     let frecord = record;
//     console.log(a);
//     if (a >= 0) {
//       frecord[a].dep_choice = [];
//       setRecord(frecord);
//     }
//   }

//   if (tableName.current === "reference_filter") {
//     if (ob === "form_column_id") {
//       console.log("check column");
//       callColumnByColId(id);
//     }
//   }
//   console.log(" record ", record);
//   var frecord = record;
//   console.log(" policyFilter", policyFilter);
//   console.log(" viewList", viewList);
//   let selectedId = "";
//   for (let t = 0; t < viewList?.length; t++) {
//     if (viewList[t].selected === "true") selectedId = viewList[t].id;
//   }
//   if (policyFilter != undefined) {
//     for (let s = 0; s < policyFilter.length; s++) {
//       let pf = policyFilter[s].filter;
//       let pfId = policyFilter[s].id;
//       let global = policyFilter[s].global;
//       let form_view_id = policyFilter[s].form_view_id;
//       let reverse = policyFilter[s].reverse_if_false;

//       if (
//         pf !== undefined &&
//         pf !== "" &&
//         (global === "true" || selectedId === form_view_id)
//       ) {
//         console.log(pf);
//         var pfilt = pf.filter;
//         for (let i = 0; i < pfilt.length; i++) {
//           let p_policy = pfilt;
//           let name = pfilt[i].co;
//           let sign = pfilt[i].mc;
//           let answer = pfilt[i].an;
//           console.log(" name ob ", name, ob);
//           console.log(" answer ob ", answer, vl);
//           if (name == ob) {
//             let arr = defUiValid;
//             console.log("check policy", sign);
//             console.log("defUiValid g ", defUiValid);
//             switch (sign) {
//               case "is":
//                 console.log(answer, vl);
//                 if (answer == vl) {
//                   setPolicy(frecord, pfId, ob, reverse);
//                   // setPolicy(frecord, pfId, ob, reverse);
//                 } else {
//                   removeAction(frecord, ob);
//                   // removeAction(frecord, ob);
//                 }
//                 break;
//               case ">":
//                 if (answer > vl) {
//                   if (!isNaN(Date.parse(answer)) && !isNaN(Date.parse(vl))) {
//                   } else {
//                   }
//                   setPolicy(frecord, pfId, ob);
//                 } else {
//                   removeAction(frecord, ob);
//                 }
//                 break;
//               case "<":
//                 if (answer < vl) {
//                   setPolicy(frecord, pfId, ob);
//                 } else {
//                   removeAction(frecord);
//                 }
//                 break;

//               default:
//                 break;
//             }
//           }
//         }
//       }
//     }
//   }

//   if (type === "reference") {
//     console.log(id, tableName.current, record[index]);
//     if (record[index].col_types.includes("group_key_value")) {
//       if (vl.properties) {
//         keyValueJson.properties = [];
//         let len = vl.properties.length;
//         for (let i = 0; i < len; i++) {
//           keyValueJson.properties.push({
//             name: "",
//             value: "",
//           });
//         }
//         setKeyValueJson(keyValueJson);
//       }
//       for (let i = 0; i < frecord.length; i++) {
//         if (frecord[i].type === "key_value") {
//           frecord[i].value = props;
//           break;
//         }
//       }
//       console.log(frecord);
//       setRecord([...frecord]);
//     }

//     if (tableName.current === "reference_filter") {
//       console.log("ref filt check");
//       if (ob === "form_column_id") {
//         callColumnByColId(id);
//       }
//       if (ob === "form_table_id") {
//         console.log(vl);
//         callTableColumn(vl, false, depend_column);
//       }
//     } else if (
//       (ob === "form_table_id" ||
//         ob === "u_form_table_id" ||
//         obj === "form_table") &&
//       tableName.current !== "table_access" &&
//       vrf
//     ) {
//       // if (depend_column === ob){
//       console.log("obbbbbbbb");
//       callTableColumn(vl, false, depend_column);
//       // }
//     } else {
//       if (tableName.current === "report") {
//         callColumnByColId(id);
//       }
//     }
//     if (ob === "reference_id") {
//       callRefFieldList(id, true);
//     }
//     if (vrf === false) {
//       frecord[index].clicked = false;
//     }
//     if (vl.length > 2) {
//       if (vrf === true) {
//         if (frecord[index].name === ob) {
//           console.log("iffffff", id, index);
//           frecord[index].value.name = vl;
//           frecord[index].clicked = true;
//           frecord[index].value.id = id;
//           frecord[index].verified = "verified";
//           frecord[index].rcd_info = "true";
//           console.log(frecord);

//           // index ob type
//           getSecondaryFields(
//             index,
//             ob,
//             type,
//             id,
//             frecord[index]?.refTable?.value
//           );
//           setRecord([...frecord]);
//           setRefRecord({});
//           // validationfn(vl, index, ob, type, id);
//         }
//       } else {
//         console.log("hjjhjhjhjjhj ");
//         if (frecord[index].name === ob) {
//           frecord[index].value.name = vl;
//           frecord[index].value.id = id;
//           frecord[index].rcd_info = "true";
//           // console.log("checking2");
//           // setState({ record: frecord });
//           getSecondaryFields(
//             index,
//             ob,
//             type,
//             id,
//             frecord[index]?.refTable?.value
//           );
//           console.log("rcd_info", frecord);
//           setRecord([...frecord]);
//         }

//         var veri = '{"referenceRecord":[{"columnid":"' + id + '"},';

//         veri += '{"tabvalue":"' + vl + '"}]}';
//         var rff = [];
//         axios
//           .post(loca + `/lom/reference/record`, veri.toString(), {
//             headers: {
//               "Content-Type": "application/json",
//               authorization: "Bearer " + token,
//             },
//           })
//           .then(
//             (resp) => {
//               const refrencercd = resp.data;
//               console.log("ref : " + JSON.stringify(refrencercd));
//               if ("Error" in refrencercd) {
//               } else {
//                 rff = {
//                   index: index,
//                   record: refrencercd.referenceRecordList[2].records,
//                 };
//                 if (refrencercd.referenceRecordList[2].records.length > 0) {
//                   frecord[index].verified = "";
//                 } else {
//                   frecord[index].verified = "unverified";
//                 }
//                 frecord[index].value.name = vl;
//                 frecord[index].value.id = id;

//                 setShowContainer(true);
//                 setRefRecord({ ...rff });
//                 setRecord([...frecord]);
//               }
//             },
//             (error) => {
//               console.log(error);
//             }
//           );
//       }
//     } else {
//       if (frecord[index].name === ob) {
//         frecord[index].value.name = vl;
//         if (vl === "") {
//           frecord[index].value.id = "0";
//         } else {
//           frecord[index].value.id = id;
//         }
//         frecord[index].verified = "";
//         setRecord([...frecord]);
//         setRefRecord({});
//       }
//     }
//   } else if (type === "depend_table") {
//   console.log(vl,id,ob,frecord[index].name);
//     if (frecord[index].name === ob) {
//       console.log(vl,id);
//       frecord[index].value.name = vl;
//       frecord[index].value.id = id;
//       frecord[index].verified = "";
//       frecord[index].rcd_info = "true";
//     console.log(frecord[index]);

//       setRecord([...frecord]);
//     }
//   } else if (type === "choice") {
//     console.log(vl,readonly);
//     if (readonly === "false") {
//       console.log(vl);
//       frecord[index].value = vl;
//       setRecord([...frecord]);
//     }
//   } else if (type === "dynamic_key_value") {
//     let props = vl;
//     let abc = frecord[index].value[outerIndex];
//     if (innerIndex === 0) {
//       console.log(
//         frecord[index].value,
//         Object.keys(abc),
//         Object.keys(abc).includes(props)
//       );
//       for (let i = 0; i < frecord[index].value.length; i++) {
//         const ob = frecord[index].value[i];
//         if (Object.keys(ob).includes(props)) {
//           return;
//         }
//       }
//       var key = Object.keys(abc)[0];
//       var val = Object.values(abc)[0];
//       delete abc[key];
//       if (val) {
//         abc[props] = val;
//       } else {
//         abc[props] = "";
//       }
//     } else {
//       var key = Object.keys(abc)[0];
//       if (key) {
//         abc[key] = props;
//       } else {
//         abc[""] = props;
//       }
//     }
//     setRecord([...frecord]);
//   } else {
//     console.log(type, ob, frecord[index], index, frecord);
//     if (frecord[index].name === ob) {
//       if (frecord[index].type === "String" && vl.length > 0) {
//         console.log(frecord[index].validation);
//         if (frecord[index].validation === "number") {
//           if (/^[0-9]*$/.test(vl)) {
//             frecord[index].value = vl;
//             setRecord([...frecord]);
//           } else {
//             vl = frecord[index].value;
//           }
//         } else if (frecord[index].validation === "name") {
//           if (/^[a-z][a-z0-9_]*$/.test(vl)) {
//             frecord[index].value = vl;
//             setRecord([...frecord]);
//           } else {
//             vl = frecord[index].value;
//           }
//         } else if (frecord[index].validation === "numbersAndCharacters") {
//           if (/^[a-z0-9_]*$/.test(vl)) {
//             frecord[index].value = vl;
//             setRecord([...frecord]);
//           } else {
//             vl = frecord[index].value;
//           }
//         } else if (frecord[index].validation === "character") {
//           if (/^[a-zA-Z\s]+$/.test(vl)) {
//             frecord[index].value = vl;
//             setRecord([...frecord]);
//           } else {
//             vl = frecord[index].value;
//           }
//         } else if (frecord[index].validation === "withoutSpecialCharacter") {
//           if (/^[_A-z0-9\s]*((-|\s)*[_A-z0-9])*$/.test(vl)) {
//             frecord[index].value = vl;
//             setRecord([...frecord]);
//           } else {
//             vl = frecord[index].value;
//           }
//         } else if (frecord[index].validation === "zipCode") {
//           if (/^[0-9]{5}(?:-[0-9]{4})?$/.test(vl)) {
//             frecord[index].value = vl;
//             setRecord([...frecord]);
//           } else {
//             vl = frecord[index].value;
//           }
//         } else if (frecord[index].validation === "decimal") {
//           if (/^\d*\.?\d*$/.test(vl)) {
//             frecord[index].value = vl;
//             setRecord([...frecord]);
//           } else {
//             vl = frecord[index].value;
//           }
//         } else if (frecord[index].validation === "app_code") {
//           if (/^[a-z][a-z0-9]*$/.test(vl)) {
//             frecord[index].value = vl;
//             setRecord([...frecord]);
//           } else {
//             vl = frecord[index].value;
//           }
//         } else if (frecord[index].validation === "ipAddress") {
//           if (/((([0-9a-fA-F]){1,4})\\:){7}([0-9a-fA-F]){1,4}$/.test(vl)) {
//             frecord[index].value = vl;
//             setRecord([...frecord]);
//             //Ipv4 = (([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\\.){3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])
//             //Ipv6 = ((([0-9a-fA-F]){1,4})\\:){7}([0-9a-fA-F]){1,4}
//           } else {
//             vl = frecord[index].value;
//           }
//         } else if (frecord[index].validation === "all") {
//           frecord[index].value = vl;
//           setRecord([...frecord]);
//         } else {
//           console.log("its work");
//           // if (/^[a-zA-Z0-9_\s]*$/.test(vl)) {
//           frecord[index].value = vl;
//           setRecord([...frecord]);
//           // }
//         }
//       } else if (frecord[index].type === "key_value") {
//         console.log(" key value check", nme, vl);
//         const updatedProperties = [...keyValueJson.properties];
//         updatedProperties[outerIndex] = {
//           name: nme,
//           value: vl,
//         };

//         setKeyValueJson({
//           properties: updatedProperties,
//         });
//       } else if (frecord[index].type === "group_key_value") {
//         if (nme === "name") {
//           frecord[index].value.properties[outerIndex][nme] = vl;
//         }
//         if (nme === "choice" && innerIndex !== null) {
//           console.log(outerIndex, innerIndex);
//           frecord[index].value.properties[outerIndex][nme][innerIndex].value =
//             vl;
//         }
//         console.log(frecord);
//         setRecord([...frecord]);
//       } else {
//         console.log(index, vl);
//         frecord[index].value = vl;
//         setRecord([...frecord]);
//       }
//     }
//   }

//   for (let i = 0; i < uiScript.length; i++) {
//     let field = uiScript[i].field.name;
//     console.log(field, ob);
//     let func = uiScript[i].script;
//     let type = uiScript[i].type;
//     console.log(type);
//     if (field === ob && type === "onchange") {
//       console.log("runnner", func);
//       setTimeout(() => {
//         onChange(func, vl, obj);
//       }, 100);
//     }
//    }
//   console.log(record);
// };
