import React from 'react'
import { useNavigate } from 'react-router-dom';

function Header({ tabName, isClick, refresh, setRefresh }) {
    const navigation = useNavigate();
    return (
        <div className="">
            <div
                className="btndiv"
                onContextMenu={(e) => {
                    e.preventDefault();
                    // m_rid.current = obj.id;
                    // setContext(e.button, e.nativeEvent.pageX, e.nativeEvent.pageY);
                }}
            >
                <div className="row fr" style={{ padding: "8px 0px" }}>
                    <div
                        className="col-md-3"
                        style={{ alignSelf: "center", textAlign: "start" }}
                    >
                        <span>
                            <i
                                disabled={isClick}
                                className=" backic fa fa-arrow-left bck_btt_mrg"
                                
                                onClick={() => {
                                    if (!isClick) {
                                        navigation(-1);
                                    }
                                }}
                            ></i>
                        </span>
                        <span style={{ paddingLeft: "1.5rem" }}>
                            <i
                                className="fa fa-refresh"
                                
                                title="Refresh"
                                onClick={() => { setRefresh(!refresh) }}
                                style={{ cursor: "pointer" }}
                            ></i>
                        </span>
                    </div>
                    <div className="col-md tab_head">{tabName}</div>
                    <div className="col-md-3"></div>
                </div>
            </div>
        </div>
    )
}

export default Header