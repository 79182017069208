import React, { useState,useContext } from "react";
import { Modal } from "react-bootstrap";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";
import { AutoPortalContext } from "../Context";
import { useEffect } from "react";
import axios from "axios";


const Profile = () => {
  const {
    loca,
    setUserName,
    setU_Name,
    userDetails
  } = useContext(AutoPortalContext);
  let subtitle;
  const navigation = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(true);
  const [pageError, setPageError] = useState(false);
  const [error, setError] = useState("");

  const openModal = () => {
    setIsOpen(true);
  };

  const afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    subtitle.style.color = "#FFFFFF";
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const logOut = () => {
    localStorage.removeItem("autoToken");
    navigation("/login");
  };

  

  // const useEffect = () => {
  //   setLoading(true);
  //   axios
  //     .post(
  //       loca + "/authenticate",
  //       {
  //         username: username,
  //         // password: password,
  //         // application: application,
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //       }
  //     )
  //     .then(
  //       (res) => {
  //         console.log(res.data);
  //         if (res.status === 200) {
  //           if (res.data.authenticated === true) {
  //             var token = res.data.token;
  //             var un = res.data.username;
  //             var nm = res.data.name;
  //             console.log("name " + un);
  //             setU_Name(nm);
  //             setUserName(un);
           
  //           } else {
  //             setPageError(true);
  //             setError(res.data.error);
  //             setLoading(false);
  //           }
  //         }
  //       },
  //       (error) => { navigation("/error")
  //         setPageError(true);
  //         setError("Please check your username and password ");
  //       }
  //     );
  // };

  return (
    <div style={{flexGrow:1}}>
      <div onAuxClick={openModal}>
        
        <Modal
          isOpen={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <h2 ref={(_subtitle) => (subtitle = _subtitle)}>UserProfile</h2>
          <div style={{fontSize:'75px', color:'white',textAlign:'center'}}>
            <i className="fa fa-user-circle-o" ></i>
            <p style={{fontSize:'20px', color:'white',textAlign:'center'}}>
              {console.log("lllllllllllllll "+userDetails.name)}
              {userDetails.name}
              </p>
          </div>
          <div>
            <button className="btn btn-danger form-control" onClick={()=>logOut()}>logout</button>
            <button
              className="btn btn-primary form-control"
              onClick={closeModal}
            >
              close
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

const customStyles = {
  content: {
    width: "320px",
    height: "350px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "11px",
    background: "#000080",
    
  },
};

export default Profile;

