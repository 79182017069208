import React from 'react'
import '../css/WorkInProgress.css'


const WorkInProgressSmall = () => {
  return (
    <div style={{flexGrow:1}}>
       <div style={{display:"flex",justifyContent:"center",alignItems:"center",flexGrow:1,flexDirection:"column"}} >
    <div className="spinner-border text-primary" role="status">
      <span className="sr-only">Loading...</span>
    </div>
    </div>
  </div>
  )
}

export default WorkInProgressSmall
