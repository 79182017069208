import axios from "axios";
import "../css/ListComponent.css";
import WorkInProgress from "./WorkInProgress";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { AutoPortalContext } from "../Context";
import { Modal, Button } from "react-bootstrap";
import React, { useState, useContext, useEffect, useRef } from "react";
import NewFilterCompo from "./NewFilterCompo";
import { isMobile } from "react-device-detect";
import PrefCompo from "./PrefCompo";
import { toast } from "react-toastify";

const RelationList = ({
  recordList,
  listname,
  tableName,
  i_d,
  rd,
  prevTab,
  all_ref_cols,
}) => {
  const { token, loca, user, userDetails } = useContext(AutoPortalContext);
  // const ref = useRef(initialValue)
  console.log(recordList, tableName, i_d, prevTab);
  const navigation = useNavigate();
  const [searchParam, setSearchParam] = useSearchParams();
  // const [recordList, setRecordlist] = useState({});
  const counter = useRef(0);
  const [loading, setLoading] = useState(true);
  const [col_mn, setCol_mn] = useState([]);
  const [list, setlist] = useState([]);
  const [showlist, setShowList] = useState(false);
  const [showbtn, setShowbtn] = useState(false);
  const [button, setButton] = useState([]);
  const [btnNamee, setbtnNamee] = useState("");
  const [url, setUrl] = useState("");
  const [mainrecord, setMainRecord] = useState({});
  const [sortColumn, setSortColumn] = useState({ name: "", sort: false });
  const [prefCall, setPrefCall] = useState([]);
  const [filtarray, setFiltArray] = useState([]);

  const [filString, setfilString] = useState("");
  const [filter, setFilter] = useState();
  const [filt, setFilt] = useState({
    co: "",
    cl: "",
    mc: "",
    an: "",
    ct: "",
    af: "",
    rf: { id: "", value: "" },
  });
  const [page_error, setPage_Error] = useState(false);
  const [error, setError] = useState("");
  const [pageMessage, setPageMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [timeline, setTimeline] = useState("");
  const [pageClicked, setPageClicked] = useState(0);
  const [modal, setModal] = useState(false);
  const [isClick, setIsClick] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [pageRecords, setPageRecords] = useState("");
  const [recordCount, setRecordCount] = useState();
  const [listName, setListName] = useState(tableName);
  const [showPref, setShowPref] = useState(false);
  const [columnList, setColumnList] = useState([]);
  const [srch, setSrch] = useState("");
  const [srch_column, setSrch_column] = useState("name");
  const [tableLabel, setTableLabel] = useState("");

  let parentApp = useRef("");
  let executedFilter = useRef([]);
  let mySelect = useRef([]);

  //................ Functions ............//

  useEffect(() => {
    console.log("tableName : " + tableName);
    parentApp.current = searchParam.get("parentApplication");
    if (counter.current == 0) {
      setListRecord(recordList);
      setTableLabel(recordList.formRecordList[1].table.label);
      console.log(recordList);
      counter.current = 1;
    }
  }, [searchParam]);

  const handleShow = () => {
    setShowPref(true);
  };

  const handleClose = () => {
    setShowPref(false);
  };

  const handleCloseRefresh = () => {
    handleClose();
    var tablere = '{"formRecordList":[';
    tablere += '{"application":{"id":"","name":"autoportal"}}';
    tablere += ',{"table":{"id":"","name":"' + listName + '"}}';
    tablere += ',{"records":[]}';
    tablere +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    tablere += ',{"sort":{"asc":"true","column":"id","init":"true"}}';
    tablere += ',{"filter":' + JSON.stringify(filtarray) + "}";
    tablere += ',{"timeLine":"' + timeline + '"}]}';
    setList(tablere);
  };

  const leftReadOnly = () => {
    if (pageClicked === 1) {
      return true;
    } else {
      return false;
    }
  };

  const rightReadOnly = () => {
    if (pageClicked === pageCount) {
      return true;
    } else {
      return false;
    }
  };

  const previousPage = () => {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"autoportal"}}';
    pp += ',{"table":{"id":"","name":"' + listName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      (pageClicked - 1) +
      '","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';

    setList(pp);
  };

  const nextPage = () => {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"autoportal"}}';
    pp += ',{"table":{"id":"","name":"' + listName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      (pageClicked + 1) +
      '","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';

    setList(pp);
  };

  const firstPage = () => {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"autoportal"}}';
    pp += ',{"table":{"id":"","name":"' + listName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';

    setList(pp);
  };

  const lastPage = () => {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"autoportal"}}';
    pp += ',{"table":{"id":"","name":"' + listName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      pageCount +
      '","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';

    setList(pp);
    console.log("lasttttttt " + JSON.stringify(pp));
  };

  const setTimeLine = (e) => {
    setTimeline(e);
  };

  const showupdownbtn = (hd) => {
    var sarray = sortColumn;
    let flt = JSON.parse(JSON.stringify(executedFilter.current));
    var clmn = "";
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"autoportal"}}';
    pp += ',{"table":{"id":"","name":"' + listName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';

    if (sarray.name === hd) {
      sarray.sort = !sarray.sort;
      for (var l = 0; l < list.length; l++) {
        for (var ll = 0; ll < list[l].heading.length; ll++) {
          if (list[l].heading[ll].label === hd) {
            clmn = list[l].heading[ll].name;
            break;
          }
        }
      }

      if (sarray.sort === true) {
        pp += ',{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
      } else {
        pp +=
          ',{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
      }
    } else {
      sarray.name = hd;
      sarray.sort = true;
      for (var li = 0; li < list.length; li++) {
        for (var lll = 0; lll < list[li].heading.length; lll++) {
          if (list[li].heading[lll].label === hd) {
            clmn = list[li].heading[lll].name;
            break;
          }
        }
      }
      if (sarray.sort === true) {
        pp += ',{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
      } else {
        pp +=
          ',{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
      }
    }
    setCol_mn({ sortColumn: sarray });
    pp += ',{"filter":' + JSON.stringify(flt) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';
    setList(pp);
  };

  const setSort = () => {
    var clmn = "";
    var pp = "";
    var srt = sortColumn.name;
    if (srt !== "") {
      for (var l = 0; l < list.length; l++) {
        for (var ll = 0; ll < list[l].heading.length; ll++) {
          if (list[l].heading[ll].label === sortColumn.name) {
            clmn = list[l].heading[ll].name;
            break;
          }
        }
      }
      if (sortColumn.sort === true) {
        pp += ',{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
      } else {
        pp +=
          ',{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
      }
      return pp;
    } else {
      pp +=
        ',{"sort":{"asc":"true","column":"' +
        list[0].heading[0].name +
        '","init":"false"}}';
    }
    return pp;
  };

  //.................. Api Call ...............//

  const setListRecord = (listrecord) => {
    console.log(listrecord);
    var columnarry = [];
    var hd = [];
    var va_ll = [];
    if ("Error" in listrecord) {
    } else {
      var scnd = listrecord.formRecordList[2];
      if ("Error" in scnd) {
        if (scnd.Error === "No record found.") {
          setlist([]);

          // this.setState({ list: [] });
        }
      } else {
        var page_clicked1 = parseInt(
          listrecord.formRecordList[3].page.page_clicked
        );
        var page_count1 = parseInt(
          listrecord.formRecordList[3].page.page_count
        );
        var page_records1 = listrecord.formRecordList[3].page.page_records;
        var record_count1 = parseInt(
          listrecord.formRecordList[3].page.record_count
        );

        var flt = listrecord.formRecordList[5];
        var fltarr = listrecord.formRecordList[5].filter;
        var tmln = listrecord.formRecordList[6].timeLine;
        var filterString = "";
        var leng = fltarr.length;
        for (var f = 0; f < fltarr.length; f++) {
          if (leng === 1 && fltarr[f].cl === "") {
            break;
          } else {
            if (f > 0) {
              filterString += fltarr[f].af + " ";
            }
            filterString += fltarr[f].cl + " ";
            filterString += fltarr[f].mc + " ";
            if (
              fltarr[f].ct === "reference" ||
              fltarr[f].ct === "multi_select" ||
              fltarr[f].ct === "depend_table"
            ) {
              filterString += fltarr[f].rf.value + " ";
            } else {
              filterString += fltarr[f].an + " ";
            }
          }
        }
        console.log("records ", listrecord.formRecordList[2].records);
        for (var i = 0; i < listrecord.formRecordList[2].records.length; i++) {
          var in_vl = [];
          var record_id;
          // console.log("length "+listrecord.formRecordList[2].records[i].record.length);
          for (
            var j = 0;
            j < listrecord.formRecordList[2].records[i].record.length;
            j++
          ) {
            if (i === 0) {
              if (j === 0) {
              } else {
                // hd.push({
                //   label:
                //     listrecord.formRecordList[2].records[i].record[j].label,
                //   name: listrecord.formRecordList[2].records[i].record[j].name,
                // });
              }
            }
            if (j === 0) {
              record_id =
                listrecord.formRecordList[2].records[i].record[j].value;
            } else if (j === 1) {
              if (
                listrecord.formRecordList[2].records[i].record[j].type ===
                "reference"
              ) {
                in_vl.push({
                  value:
                    listrecord.formRecordList[2].records[i].record[j].value,
                  type: listrecord.formRecordList[2].records[i].record[j].type,
                  firstrecord: true,
                  table: listrecord.formRecordList[1].table.name
                    ? listrecord.formRecordList[1].table.name
                    : listrecord.formRecordList[1].table.value,
                  r_id: record_id,
                });
              } else {
                in_vl.push({
                  value:
                    listrecord.formRecordList[2].records[i].record[j].value,
                  type: listrecord.formRecordList[2].records[i].record[j].type,
                  firstrecord: true,
                  table: listrecord.formRecordList[1].table.name
                    ? listrecord.formRecordList[1].table.name
                    : listrecord.formRecordList[1].table.value,
                  r_id: record_id,
                });
              }
            } else {
              if (
                listrecord.formRecordList[2].records[i].record[j].type ===
                "reference"
              ) {
                in_vl.push({
                  value:
                    listrecord.formRecordList[2].records[i].record[j].value,
                  type: listrecord.formRecordList[2].records[i].record[j].type,
                  firstrecord: false,
                  table: listrecord.formRecordList[1].table.name
                    ? listrecord.formRecordList[1].table.name
                    : listrecord.formRecordList[1].table.value,
                  r_id: record_id,
                });
              } else {
                in_vl.push({
                  value:
                    listrecord.formRecordList[2].records[i].record[j].value,
                  type: listrecord.formRecordList[2].records[i].record[j].type,
                  firstrecord: false,
                  table: listrecord.formRecordList[1].table.name
                    ? listrecord.formRecordList[1].table.name
                    : listrecord.formRecordList[1].table.value,
                  r_id: record_id,
                });
              }
            }
          }
          // console.log("heading: " + JSON.stringify(in_vl))
          in_vl.splice(0, 0, { ref: false });
          va_ll.push({ colr: in_vl });
        }
        let col_list = [];
        for (var c = 0; c < listrecord.formRecordList[9].column.length; c++) {
          if (listrecord.formRecordList[9].column[c].type === "String") {
            col_list.push({
              label: listrecord.formRecordList[9].column[c].label,
              name: listrecord.formRecordList[9].column[c].name,
              type: listrecord.formRecordList[9].column[c].type,
            });
          }
        }
        for (
          var p = 0;
          p < listrecord.formRecordList[10].preference.length;
          p++
        ) {
          hd.push({
            label: listrecord.formRecordList[10].preference[p].label,
            name: listrecord.formRecordList[10].preference[p].name,
          });
        }
        hd.splice(0, 0, { ref: false });
        columnarry.push({ heading: hd, rcd: va_ll });
        var cla = JSON.parse(
          JSON.stringify(listrecord.formRecordList[9].column)
        );
        console.log(filterString);
        cla.unshift({ label: "None", name: "none" });
        // console.log(JSON.stringify(listrecord));
        console.log(columnarry);
        setlist(columnarry);
        setPageClicked(page_clicked1);
        setPageCount(page_count1);
        setPageRecords(page_records1);
        setRecordCount(record_count1);
        setCol_mn(cla);
        setColumnList(col_list);
        setPrefCall(listrecord.formRecordList[9].column);
        setFilter(flt);
        console.log("filt : " + JSON.stringify(fltarr));
        executedFilter.current = JSON.parse(JSON.stringify(fltarr));
        setFiltArray(fltarr);
        setTimeLine(tmln);
        setfilString(filterString);
        // console.log(
        //   "lll : " + JSON.stringify(listrecord.formRecordList[11].button)
        // );
        setButton(listrecord.formRecordList[11].button);
        setLoading(false);
      }
    }
  };

  const setList = (tablere) => {
    console.log("tablere : " + tablere);

    axios
      .post(loca + "/lom/get/relation/record", tablere.toString(), {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          const listrecord = resp.data;
          console.log(" aaaaaaaaaaa ", listrecord);
          setListRecord(listrecord);
          // var columnarry = [];
          // var hd = [];
          // var va_ll = [];
          // if ("Error" in listrecord) {
          // } else {
          //   var scnd = listrecord.formRecordList[2];
          //   if ("Error" in scnd) {
          //     if (scnd.Error === "No record found.") {
          //       setlist([]);
          //     }
          //   } else {
          //     var pageClicked1 = parseInt(
          //       listrecord.formRecordList[3].page.page_clicked
          //     );
          //     var pageCount1 = parseInt(
          //       listrecord.formRecordList[3].page.page_count
          //     );
          //     var page_records1 =
          //       listrecord.formRecordList[3].page.page_records;
          //     var record_count1 = parseInt(
          //       listrecord.formRecordList[3].page.record_count
          //     );

          //     // var flt = listrecord.formRecordList[5];
          //     var fltarr = listrecord.formRecordList[5].filter;
          //     var tmln = listrecord.formRecordList[6].timeLine;
          //     var filterString = "";
          //     var leng = fltarr.length;
          //     for (var f = 0; f < fltarr.length; f++) {
          //       if (leng === 1 && fltarr[f].cl === "") {
          //         console.log();
          //         break;
          //       } else {
          //         if (f > 0) {
          //           filterString += fltarr[f].af + " ";
          //         }
          //         filterString += fltarr[f].cl + " ";
          //         filterString += fltarr[f].mc + " ";
          //         filterString += fltarr[f].an + " ";
          //       }
          //     }

          //     for (
          //       var i = 0;
          //       i < listrecord.formRecordList[2].records.length;
          //       i++
          //     ) {
          //       var in_vl = [];
          //       var record_id;
          //       for (
          //         var j = 0;
          //         j < listrecord.formRecordList[2].records[i].record.length;
          //         j++
          //       ) {
          //         if (i === 0) {
          //           if (j === 0) {
          //           } else {
          //             hd.push({
          //               label:
          //                 listrecord.formRecordList[2].records[i].record[j]
          //                   .label,
          //               name: listrecord.formRecordList[2].records[i].record[j]
          //                 .name,
          //             });
          //           }
          //         }
          //         if (j === 0) {
          //           record_id =
          //             listrecord.formRecordList[2].records[i].record[j].value;
          //         } else if (j === 1) {
          //           if (
          //             listrecord.formRecordList[2].records[i].record[j].type ===
          //             "reference"
          //           ) {
          //             in_vl.push({
          //               value:
          //                 listrecord.formRecordList[2].records[i].record[j]
          //                   .value.value,
          //               type: listrecord.formRecordList[2].records[i].record[j]
          //                 .type,
          //               firstrecord: true,
          //               table: listrecord.formRecordList[1].table.value,
          //               r_id: record_id,
          //               name: listrecord.formRecordList[2].records[i].record[j]
          //                 .value.name,
          //             });
          //           } else {
          //             in_vl.push({
          //               value:
          //                 listrecord.formRecordList[2].records[i].record[j]
          //                   .value,
          //               type: listrecord.formRecordList[2].records[i].record[j]
          //                 .type,
          //               firstrecord: true,
          //               table: listrecord.formRecordList[1].table.value,
          //               r_id: record_id,
          //               name: listrecord.formRecordList[2].records[i].record[j]
          //                 .name,
          //             });
          //           }
          //         } else {
          //           if (
          //             listrecord.formRecordList[2].records[i].record[j].type ===
          //             "reference"
          //           ) {
          //             console.log("i : " + i + " j : " + j);
          //             console.log(
          //               JSON.stringify(
          //                 listrecord.formRecordList[2].records[i].record[j]
          //               )
          //             );
          //             in_vl.push({
          //               value:
          //                 listrecord.formRecordList[2].records[i].record[j]
          //                   .value,
          //               type: listrecord.formRecordList[2].records[i].record[j]
          //                 .type,
          //               firstrecord: false,
          //               table: listrecord.formRecordList[1].table.value,
          //               r_id: record_id,
          //               name: listrecord.formRecordList[2].records[i].record[j]
          //                 .name,
          //             });
          //           } else {
          //             in_vl.push({
          //               value:
          //                 listrecord.formRecordList[2].records[i].record[j]
          //                   .value,
          //               type: listrecord.formRecordList[2].records[i].record[j]
          //                 .type,
          //               firstrecord: false,
          //               table: listrecord.formRecordList[1].table.value,
          //               r_id: record_id,
          //               name: listrecord.formRecordList[2].records[i].record[j]
          //                 .name,
          //             });
          //           }
          //         }
          //       }
          //       va_ll.push({ colr: in_vl });
          //     }
          //     columnarry.push({ heading: hd, rcd: va_ll });
          //     var cla = JSON.parse(
          //       JSON.stringify(listrecord.formRecordList[9].column)
          //     );
          //     cla.unshift({ label: "None", name: "none" });
          //     console.log(JSON.stringify(columnarry));
          //     setLoading(false);
          //     setlist(columnarry);
          //     setPageClicked(pageClicked1);
          //     setPageCount(pageCount1);
          //     setPageRecords(page_records1);
          //     setRecordCount(record_count1);
          //     setCol_mn(cla);
          //     setPrefCall(listrecord.formRecordList[9].column);
          //     setFilter(fltarr);
          //     setTimeline(tmln);
          //     setClearButtonLoading(false);
          //     setRunButtonLoading(false);
          //     setLoading(false);
          //   }
          // }
        },
        (error) => {
          navigation("/error");
          console.log(error);
        }
      );
  };

  const changeSearch = (val) => {
    if (val === "") {
      // this.setState({ srch: "" });
      setSrch("");
      filterClear();
    } else {
      console.log(val);
      // this.setState({ srch: val });
      setSrch(val);
    }
  };

  const callSearchbtn = () => {
    // var fld = this.state.srch_column;
    // var val = this.state.srch;
    // var col = this.state.columnList;
    if (srch === "") {
      return;
    }
    var type = "";
    var label = "";
    for (var c = 0; c < columnList.length; c++) {
      if (srch_column === columnList[c].name) {
        type = columnList[c].type;
        label = columnList[c].label;
      }
    }
    let filte = [
      filtarray[0],
      {
        ct: type,
        af: "AND",
        mc: "contains",
        cl: label,
        co: srch_column,
        an: srch,
        rf: { id: "", value: "" },
        dc: { id: "", value: "" },
      },
    ];

    console.log(filte);

    var fs = '{"formRecordList":[';
    fs += '{"application":{"id":"","name":"autoportal"}}';
    fs += ',{"table":{"id":"","name":"' + listName + '","label":""}}';
    fs += ',{"records":[]}';
    fs +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    fs += ',{"sort":{"asc":"true","column":"id","init":"false"}}';
    fs += ',{"filter":' + JSON.stringify(filte) + "}";
    fs += ',{"timeLine":"' + timeline + '"}]}';
    setFiltArray([...filte]);
    setList(fs);
  };

  const searchColumn = (e) => {
    // this.setState({ srch_column: e.target.value });
    setSrch_column(e.target.value);
  };

  const callform = (nam, tab, r_id, e) => {
    console.log("tab : " + tab);
    console.log(nam);
    if (nam === "first") {
      // navigation({
      //   pathname: user + "/form",
      //   search: createSearchParams({
      //     parentApplication: parentApp.current,
      //     rty: "record",
      //     tableName: tab,
      //     rid: r_id,
      //   }).toString(),
      // });
      if (e.button === 0) {
        // navigateWithSearchParams(
        //   "/private/form",
        //   {
        //     rty: "record",
        //     tableName: tab,
        //     rid: r_id,
        //   },
        //   {}
        // );

        navigation({
          pathname: user + "/form",
          search: createSearchParams({
            parentApplication: parentApp.current,
            rty: "record",
            tableName: tab,
            rid: r_id,
          }).toString(),
        });
      } else if (e.button === 1) {


        window.open(user + `/form?parentApplication=${parentApp.current}&rty=record&tableName=${tab}&rid=${r_id}`,
          `_blank`
        );

      }
      // props.showFormCompo(tab, r_id, "record");
    } else if (nam === "second") {
    } else if (nam === "new") {
      navigation({
        pathname: user + "/form",
        search: createSearchParams({
          state: { rty: "new", tableName: tab, rid: 0 },
        }).toString(),
      });

      // props.showFormCompo(tab, 0, "new");
    }
  };

  // const dismiss = () => {
  //   unmountMe();
  // }

  const selectedRows = (checked, index) => {
    list[0].rcd[index].colr[0].ref = checked;
    if (!checked) {
      list[0].heading[0].ref = false;
    }
    setlist([...list]);
  };

  const selectedAllRows = (checked) => {
    let rcd = list[0].rcd;
    console.log(list);

    if (checked) {
      list[0].heading[0].ref = true;
      for (let i = 0; i < rcd.length; i++) {
        list[0].rcd[i].colr[0].ref = true;
      }
      setlist([...list]);
    } else {
      list[0].heading[0].ref = false;
      for (let i = 0; i < rcd.length; i++) {
        list[0].rcd[i].colr[0].ref = false;
      }
      setlist([...list]);
    }
  };

  const selectedAction = (url) => {
    if (list[0].rcd.length > 0) {
      if (url === "/lom/delete/record") {
        setbtnNamee("Delete");
        setModal(true);
        setUrl(url);
      } else if (url === "/lom/accept") {
        setbtnNamee("Accept");
        setModal(true);
        setUrl(url);
      } else {
        selectAction(url);
      }
    }
  };

  const selectAction = (url) => {
    setIsClick(true);
    let rcd = list[0].rcd;
    let postBody = [];
    for (let i = 0; i < rcd.length; i++) {
      if (rcd[i].colr[0].ref) {
        // rcd[i].colr.splice(0,1)
        postBody.push(
          rcd[i].colr[1].r_id
          // table: rcd[i].colr[1].table
        );
        continue;
      }
    }
    var jso = {
      url: url,
      table: rcd[0].colr[1].table,
      record: postBody,
    };
    // setLoading(true);
    axios
      .post(loca + "/lom/selectedAction", jso, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          const record = resp.data;
          if (record.Error) {
            toast(record.Error, {
              position: "top-center",
              theme: "colored",
              type: "error",
              style: {
                marginBottom: userDetails.OS !== "null" ? 12 : 0,
              },
            });
          } else {
            if (record.message) {
              toast(record.message, {
                position: "top-center",
                theme: "colored",
                type: "success",
                style: {
                  marginBottom: userDetails.OS !== "null" ? 12 : 0,
                },
              });
            } else {
              toast(record, {
                position: "top-center",
                theme: "colored",
                type: "success",
                style: {
                  marginBottom: userDetails.OS !== "null" ? 12 : 0,
                },
              });
            }
            // refresh();
            // setLoading(false);
          }
        },
        (error) => {
          setLoading(false);
          setModal(false);
          toast("Something went wrong", {
            position: "top-center",
            theme: "colored",
            type: "error",
          });
        }
      )
      .finally(() => {
        setModal(false);
        setIsClick(false);
      });

    if (url === "1") {
    }
  };

  const setDefaultOption = () => {
    const selectElement = mySelect.current;
    selectElement.selectedIndex = 0;
  };

  const filterItem = () => {
    setShowList(!showlist);
    setShowbtn(!showbtn);
  };

  const filterClear = () => {
    var fltarray = [filtarray[0]];
    console.log(fltarray);

    // fltarray.push(filt);
    setFiltArray(fltarray);
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"autoportal"}}';
    pp += ',{"table":{"id":"","name":"' + listName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    pp += setSort();
    // pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"filter":' + JSON.stringify(fltarray) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';

    setList(pp);
  };

  const filtersubmit = () => {
    var fs = '{"formRecordList":[';
    fs += '{"application":{"id":"","name":"autoportal"}}';
    fs += ',{"table":{"id":"","name":"' + listName + '"}}';
    fs += ',{"records":[]}';
    fs +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    fs += ',{"sort":{"asc":"true","column":"id","init":"false"}}';
    fs += ',{"filter":' + JSON.stringify(filtarray) + "}";
    fs += ',{"timeLine":"' + timeline + '"}]}';

    let len = filtarray.length;
    if (
      filtarray[len - 1].an !== "" ||
      filtarray[len - 1].rf.value !== "" ||
      filtarray[len - 1].dc.value !== ""
    ) {
      setList(fs);
    }
  };

  const filterset = () => {
    for (var i = 0; i < filtarray.length; i++) {
      if (filtarray[i].ct !== "String") {
        if (filtarray[i].an === "") {
          return false;
        }
      }
    }
    return true;
  };

  const callbtn = (nam) => {
    var btn = button;
    var mnrecord = mainrecord;
    console.log(parentApp.current);
    for (var ij = 0; ij < btn.length; ij++) {
      if (btn[ij].value === nam) {
        console.log(JSON.stringify(btn[ij]));
        var btntype = btn[ij].returnWebLocation;
        var callType = btn[ij].call_type;
        setPage_Error(false);
        setError("");
        setLoading(true);
        setPageMessage(false);
        setMessage("");
        if (callType === "selection") {
          navigation({
            pathname: user + "/role-selection",
            search: createSearchParams({
              parentApplication: parentApp.current,
              tableName: tableName,
              rid: i_d,
            }).toString(),
          });
          // navigation(user + "/role-selection", {
          //   state: { i_d: i_d, rt: tableName },
          // });
        } else if (callType === "module") {
          console.log(btn[ij].script);
          var scpt = btn[ij].script;
          console.log(prevTab, rd);
          var setTab = "";
          var tabId = "";
          for (let i = 0; i < rd.length; i++) {
            if (rd[i].name === "form_table_id") {
              setTab = rd[i].value.name;
              tabId = rd[i].value.id;
            }
          }

          var f = new Function(
            [
              "navigation",
              "fieldName",
              "record",
              "all_ref_cols",
              "createSearchParams",
              "user",
              "setTab",
              "tabId",
            ],
            scpt
          );
          f(
            navigation,
            prevTab,
            rd,
            all_ref_cols,
            createSearchParams,
            user,
            setTab,
            tabId
          );
        } else {
          // mnrecord.formRecord[2].record = record;
          console.log("nexttttt");
          axios
            .get(loca + btn[ij].webUrl, {
              headers: {
                "Content-Type": "application/json",
                authorization: "Bearer " + token,
              },
            })
            .then((resp) => {
              const rcd = resp.data;
              setLoading(false);
              console.log(rcd);
              // var msg = rcd.formRecord[4];
              // if (msg !== "") {
              // setState({ page_message: true, message: msg });
              //   setPageMessage(true);
              //   setMessage(msg);
              // }
              console.log("nexttttt2", btntype);
              if (btntype === "NextPage") {
                console.log("nexttttt");
                navigation("/form", {
                  state: { rty: "new", tableName: listName, rid: "0" },
                });
              }
            });
        }
      }
    }
  };

  const getChoiceRcd = async (col_id) => {
    let chk = [];
    let ck = await axios
      .get(loca + "/lom/get/choice/" + col_id, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let res = resp.data;
          console.log(res);
          let chk = res.choiceRecords;
          return chk;
          // console.log(rcd);
        },
        (error) => {
          navigation("/error");
          console.log(error);
        }
      );
    return ck;
  };

  const callfilter = async (farray, in_index, col_id) => {
    console.log(farray);
    if (col_id !== -1) {
      // for (let i = 0; i < rcd.length; i++) {
      //   console.log(index+" index "+i+" rcddddddd "+JSON.stringify(rcd[i]));
      if (
        farray[in_index].ct === "choice" &&
        farray[in_index].ch.length === 0
      ) {
        let chc = await getChoiceRcd(col_id).then((res) => {
          if (res.length > 0) {
            farray[in_index].ch = res;
            farray[in_index].an = res[0].name;
          } else {
            farray[in_index].ch = [];
          }
        });
        //  console.log(chcRcd.current);
      }
      // }
    }
    setFiltArray([...farray]);
  };

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      callSearchbtn();
      console.log("Enter key pressed!");
    }
  };

  const calltimeline = (timeline) => {
    console.log(timeline);
    setTimeline(timeline);
  };

  if (!loading) {
    return (
      <div className="pagesetup">
        <Modal
          show={modal}
          onHide={() => {
            setModal(!modal);
            setDefaultOption();
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirm {btnNamee}</Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setModal(!modal);
                setDefaultOption();
              }}
              disabled={isClick}
              style={{ backgroundColor: isClick ? "gray" : "" }}
            >
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={() => selectAction(url)}
              disabled={isClick}
              style={{ backgroundColor: isClick ? "gray" : "" }}
            >
              {btnNamee}
            </Button>
          </Modal.Footer>
        </Modal>
        {loading === true ? (
          <WorkInProgress />
        ) : (
          <div>
            {isMobile === true ? (
              <div className="row bck bck-rel">
                <div className="col-lg mid">
                  <span className="obj_head ">{listName}</span>
                </div>
                <div className="col-lg filter-ic">
                  {showlist === false && (
                    <select
                      className="form-select namelist-mob"
                      aria-label="Default"
                      value={srch_column}
                      onChange={(e) => searchColumn(e)}
                    >
                      {columnList.map((obj2, index) => (
                        <option key={index} value={obj2.name}>
                          {obj2.label}
                        </option>
                      ))}
                    </select>
                  )}

                  {showlist === false && (
                    <input
                      className="srch-mob form-"
                      type="search"
                      aria-label="Search"
                      value={srch}
                      onChange={(e) => changeSearch(e.target.value)}
                    ></input>
                  )}

                  {showlist === false && (
                    <input
                      className="csm_btn csm_btn_pri col-md-2 sub-btn"
                      type="button"
                      value="Search"
                      onClick={() => callSearchbtn()}
                    ></input>
                  )}
                </div>
                <div className="col-lg martop10 disfl filter-ic">
                  <i
                    className="fa fa-filter vlpointer pdtop5"
                    onClick={filterItem}
                  ></i>
                  <i
                    className="fa fa-cog vlpointer cog_pd pdtop5"
                    onClick={handleShow}
                  ></i>
                  <div className="">
                    <span
                    // style={{ marginLeft: "0.5rem" }}
                    >
                      <input
                        className=" mybt"
                        type="button"
                        value="<<"
                        onClick={firstPage}
                        disabled={leftReadOnly()}
                      />
                      <input
                        className=" mybt"
                        type="button"
                        value="<"
                        onClick={previousPage}
                        disabled={leftReadOnly()}
                      />
                    </span>
                    <span>
                      <input
                        className="in-put"
                        type="text"
                        readOnly={true}
                        value={pageRecords}
                      ></input>
                    </span>
                    <span className="text-uppercase spfont"> of </span>
                    <span className="rcd_count spfont">{recordCount}</span>
                    <span>
                      <input
                        className=" mybt"
                        type="button"
                        value=">"
                        onClick={nextPage}
                        disabled={rightReadOnly()}
                      />
                    </span>
                    <span>
                      <input
                        className=" mybt"
                        type="button"
                        value=">>"
                        disabled={rightReadOnly()}
                        onClick={lastPage}
                      />
                    </span>
                  </div>
                </div>
                <div className="col-lg martop10">
                  {button.length > 0 &&
                    button.map((obj, oo_i) => (
                      <button
                        type="button"
                        className="csm_btn csm_btn_pri col-md-2 sub-btn"
                        key={oo_i}
                        onClick={(e) => callbtn(obj.value)}
                      >
                        {obj.name}
                      </button>
                    ))}
                </div>
                <div className="col-lg martop10">
                  {showlist === true && (
                    <input
                      className="csm_btn csm_btn_pri col-md-2 sub-btn"
                      type="button"
                      value="Run"
                      onClick={filtersubmit}
                    ></input>
                  )}

                  {showlist === true && (
                    <input
                      className="csm_btn csm_btn_pri col-md-2 sub-btn"
                      type="button"
                      value="Clear"
                      onClick={filterClear}
                    ></input>
                  )}
                </div>
              </div>
            ) : (
              <div
                className="bck bck-rel"
                style={{
                  display: "grid",
                  gridTemplateColumns: "2fr 1fr 2fr",
                  justifyContent: "between",
                  alignItems: "center",
                  gridTemplateAreas: "a b c",
                }}
              >
                {/* <div className="row bck bck-rel"> */}
                <div className="filter-ic">
                  <i
                    className="fa fa-filter vlpointer"
                    onClick={filterItem}
                  ></i>
                  <i
                    className="fa fa-cog vlpointer cog_pd"
                    onClick={handleShow}
                  ></i>

                  {showlist === true && (
                    <input
                      className="btn btn-primary sub-btn  btn-sm"
                      type="button"
                      value="Run"
                      onClick={filtersubmit}
                    ></input>
                  )}

                  {showlist === true && (
                    <input
                      className="btn btn-primary sub-btn  btn-sm"
                      type="button"
                      value="Clear"
                      onClick={filterClear}
                    ></input>
                  )}

                  {showlist === false && (
                    <select
                      style={{
                        // lineHeight: "0.8em",
                        // width: "10rem",
                        display: "inline",
                      }}
                      className="form-select namelist"
                      aria-label="Default"
                      value={srch_column}
                      onChange={(e) => searchColumn(e)}
                    >
                      {columnList.map((obj2, index) => (
                        <option key={index} value={obj2.name}>
                          {obj2.label}
                        </option>
                      ))}
                    </select>
                  )}

                  {showlist === false && (
                    <input
                      className="srch form-control"
                      type="search"
                      aria-label="Search"
                      value={srch}
                      style={{ height: "1.7rem" }}
                      onChange={(e) => changeSearch(e.target.value)}
                      onKeyDown={handleEnterPress}
                    ></input>
                  )}

                  {showlist === false && (
                    <input
                      className="btn btn-primary  col-md sub-btn  btn-sm"
                      type="button"
                      value="Search"
                      onClick={() => callSearchbtn()}
                    ></input>
                  )}
                </div>
                <div className="col-lg mid">
                  <p className="obj_head ">{tableLabel}</p>
                </div>
                <div className="bt_padd">
                  <div>
                    <select
                      ref={mySelect}
                      className="dropdn"
                      onChange={(e) => {
                        e.target.value !== "none" &&
                          selectedAction(e.target.value);
                      }}
                    >
                      <option value={"none"}>None</option>
                      {button.length > 0 &&
                        list[0].rcd.length > 0 &&
                        button.map(
                          (obj, oo_i) =>
                            obj.ui_type === "selected_action" && (
                              <option key={oo_i} value={obj.webUrl}>
                                {obj.value}
                              </option>
                            )
                        )}
                    </select>
                  </div>
                  {button.length > 0 &&
                    button.map((obj, oo_i) => (
                      <button
                        className=" insrtbtn2 btn btn btn-primary btn-sm"
                        key={oo_i}
                        onClick={(e) => callbtn(obj.value)}
                      >
                        {obj.name}
                      </button>
                    ))}
                  <div>
                    <input
                      className=" mybt"
                      type="button"
                      value="<<"
                      onClick={firstPage}
                      disabled={leftReadOnly()}
                    />
                    <input
                      className=" mybt"
                      type="button"
                      value="<"
                      onClick={previousPage}
                      disabled={leftReadOnly()}
                    />

                    <span className="in-put">{pageRecords}</span>
                    <span className="text-uppercase spfont"> of </span>

                    <span className="rcd_count spfont">{recordCount}</span>
                    <input
                      className=" mybt"
                      type="button"
                      value=">"
                      onClick={nextPage}
                      disabled={rightReadOnly()}
                    />
                    <input
                      className=" mybt"
                      type="button"
                      value=">>"
                      disabled={rightReadOnly()}
                      onClick={lastPage}
                    />
                  </div>
                </div>
              </div>
            )}
            {showlist === false && filString !== "" && <div>{filString}</div>}
            <div></div>
            {showlist === true && (
              <NewFilterCompo
                showlist={showlist}
                col_mn={col_mn}
                call_fil={(filtarray, in_index, col_id) => {
                  callfilter(filtarray, in_index, col_id);
                }}
                filtarray={filtarray}
                setFiltArray={setFiltArray}
                timeline={timeline}
                setTimeLine={(timeline) => calltimeline(timeline)}
                setScript={(script) => { }}
                isScript={false}
                tableName={listName}
                isRelatedList={true}
              />
            )}
            {list.length > 0 &&
              list.map((lstobj, lst_i) => (
                <div className="heading_top table_set over" key={lst_i}>
                  <table className="table table-bordered table-striped table-hover p-1">
                    <thead>
                      <tr className="obj_name">
                        {lstobj.heading.map((obj, obj_i) => (
                          <th
                            key={obj_i}
                            className="vlpointer p-0"
                            style={
                              obj_i === 0
                                ? { verticalAlign: "middle", width: "4%" }
                                : { verticalAlign: "middle" }
                            }
                          // onClick={(e) => showupdownbtn(obj.label)}
                          >
                            {obj_i === 0 ? (
                              <span>
                                <input
                                  type="checkbox"
                                  checked={obj.ref}
                                  onChange={(e) => {
                                    selectedAllRows(e.target.checked);
                                  }}
                                ></input>
                              </span>
                            ) : (
                              <div
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  padding: "0.5rem",
                                }}
                                onClick={(e) => {
                                  showupdownbtn(obj.label);
                                }}
                              >
                                {obj.label}
                                {sortColumn.name === obj.label &&
                                  sortColumn.sort === true && (
                                    <i className=" icpadding fa fa-arrow-up"></i>
                                  )}
                                {sortColumn.name === obj.label &&
                                  sortColumn.sort === false && (
                                    <i className=" icpadding fa fa-arrow-down"></i>
                                  )}
                              </div>
                            )}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {lstobj.rcd.map((objj, objj_i) => (
                        <tr className="obj_value" key={objj_i}>
                          {objj.colr.map((objr, objr_i) => (
                            <td
                              key={objr_i}
                              className={
                                objr.firstrecord === true
                                  ? "val_pad val_under vlpointer"
                                  : "val_pad"
                              }
                              onMouseDown={
                                objr.firstrecord === true
                                  ? (e) =>
                                    callform("first", objr.table, objr.r_id, e)
                                  : (e) => callform("second", "", "", e)
                              }
                            >
                              {objr_i === 0 ? (
                                <span>
                                  <input
                                    type="checkbox"
                                    checked={objr.ref}
                                    onChange={(e) => {
                                      selectedRows(e.target.checked, objj_i);
                                    }}
                                  ></input>
                                </span>
                              ) : (
                                <span>
                                  {(objr.name === "filter" ||
                                    objr.name === "json") &&
                                    objr.value !== null ? (
                                    JSON.stringify(objr.value.name)
                                  ) : objr.type === "reference" ||
                                    objr.type === "depend_table" ? (
                                    objr.value.name
                                  ) : objr.type === "group_key_value" ? (
                                    JSON.stringify(
                                      objr.value.properties?.length > 50
                                        ? objr.value.properties.slice(0, 50) +
                                        " ..."
                                        : objr.value.properties
                                    )
                                  ) : objr.type === "html" ? (
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          objr.value?.length > 50
                                            ? objr.value.slice(0, 50) + " ..."
                                            : objr.value,
                                      }}
                                    ></span>
                                  ) : objr.value?.length > 50 ? (
                                    objr.value.slice(0, 50) + " ..."
                                  ) : (
                                    objr.value
                                  )}
                                </span>
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {lstobj.rcd.length === 0 && (
                    <div
                      style={{
                        fontWeight: "bold",
                        textDecoration: "none",
                        fontStyle: "normal",
                      }}
                    >
                      No Record Found
                    </div>
                  )}
                </div>
              ))}
          </div>
        )}

        <Modal
          dialogClassName="modal-dialog my-modal-personalized modal-centered"
          show={showPref}
          onHide={handleClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Personalized List Column</Modal.Title>
          </Modal.Header>
          {/* <Modal.Body className="mod-ht"> */}
          <Modal.Body className="">
            <PrefCompo
              pref="user"
              colarray={prefCall}
              tablename={tableName}
              handleClose={() => handleCloseRefresh()}
            ></PrefCompo>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  } else {
    <WorkInProgress />;
  }
};

export default RelationList;
