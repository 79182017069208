import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal, Button, Tab, Nav } from "react-bootstrap";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import WorkInProgress from "./WorkInProgress";
import { isMobile } from "react-device-detect";
import axios from "axios";
import RelationList from "./RelationList";
import { AutoPortalContext } from "../Context";
import { toast } from "react-toastify";

const ConfigureCompo = () => {

  // Base Hooks
  const { loca, token } = useContext(AutoPortalContext)
  const navigation = useNavigate();
  const location = useLocation().state;
  const [searchParam, setSearchParam] = useSearchParams();
console.log(location);

  // UseStates
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);
  const [pageError, setPageError] = useState(true);
  const [record, setRecord] = useState(location.recordNew);
  const [tableId, setTableId] = useState(location.tabId);
  const [tabRelation, setTabRelation] = useState([]);

  // UseRefs
  const tableName = useRef("")
  const i_d = useRef(-1)

  // Functions
  const relationVerify = () => {
    tableName.current = searchParam.get("tableName");
    console.log(tableName.current);
    console.log(record, tableId);
    if (record.length > 0) {
      for (let i = 0; i < record.length; i++) {

        if (record[i].name === "form_table_id") {

          record[i].value = { id: '0', name: '' };
          console.log(record[i]);
          record[i].value.id = tableId;
          record[i].value.name = tableName.current;
        }
      }
    } else {
      record[0] = {};
      record[0].name = "form_table_id";
      record[0].value = { id: '0', name: '' };
      console.log(record[0]);
      record[0].value.id = tableId;
      record[0].value.name = tableName.current;
    }

    if (tableName.current) {
      var json = {
        relation: [
          {
            tabname: tableName.current
          },
        ],
      };
      console.log(token);
      setLoading(true);
      axios
        .post(loca + "/lom/get/config/relation", json, {
          headers: {
            authorization: "Bearer " + token,
          },
        })
        .then(
          (resp) => {
            const rvrecord = resp.data;
            console.log(rvrecord);
            if ("Error" in rvrecord) {
              setPageError(true);
              setError(rvrecord.error);
              setLoading(false);
            } else {
              console.log("rvrecord ", rvrecord.relation);
              if (rvrecord.relation.length === 0) {
                setTabRelation(rvrecord);
                //   tabRe.current = rvrecord;
                // setTabRel(true);
                setLoading(false)
              } else if ("Error" in rvrecord.relation[0]) {
                setLoading(false);
              } else {
                setTabRelation(rvrecord);
                setLoading(false);

                //   tabRe.current = rvrecord;
              }
              // // setTimeout(() => {
              // // setRelationload(false);
              // checkRefrecord();
              // // }, 1000);
            }
          },
          (error) => {
            console.log(error);
          }
        );
    } else {
      toast.error("Table Name not Found")
      setLoading(false)
      return
    }
  };

  // UseEffects
  useEffect(() => {
    relationVerify()
  }, []);

  if (!loading) {
    return (
      <div className="pagesetup">
        {tabRelation.relation.length > 0 ? (
          <div >
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey={
                tabRelation.relation[
                  tabRelation.relation.findIndex((o, i) => o.visible === "true")
                ].formRecordList[1].table.label
              }
            >
              <Nav variant="pills" className="flex-column ownNav">
                {/* <Row className="rw">
                                    {console.log(
                                      "tableRelation : " +
                                      JSON.stringify(
                                        tabRelation.relation[
                                          tabRelation.relation.findIndex(
                                            (o, i) => o.visible === "true"
                                          )
                                        ].formRecordList[1].table.label
                                      )
                                    )}
                                    {tabRelation.relation.map(
                                      (nama, tab_ia) => (
                                        <Col
                                          style={{
                                            display:
                                              nama.visible === "true"
                                                ? ""
                                                : "none",
                                          }}
                                          md={2}
                                          key={tab_ia}
                                          className="nopad"
                                        >
                                          {console.log(nama.visible)}
                                          {isMobile ? (
                                            <Nav.Item className="cur">
                                              <Nav.Link
                                                className=""
                                                eventKey={
                                                  nama.formRecordList[1].table
                                                    .label
                                                }
                                              >
                                                {
                                                  nama.formRecordList[1].table
                                                    .label
                                                }
                                              </Nav.Link>
                                            </Nav.Item>
                                          ) : (
                                            <Nav.Item
                                              color="black"
                                              className="cur"
                                            >
                                              <Nav.Link
                                                eventKey={
                                                  nama.formRecordList[1].table
                                                    .label
                                                }
                                              >
                                                {console.log(
                                                  nama.formRecordList[1].table
                                                    .label
                                                )}
                                                {
                                                  nama.formRecordList[1].table
                                                    .label
                                                }
                                              </Nav.Link>
                                            </Nav.Item>
                                          )}
                                        </Col>
                                      )
                                    )}
                                  </Row> */}
                <div className="rel_flex rw">
                  {console.log(
                    "tableRelation : " +
                    JSON.stringify(
                      tabRelation.relation[
                        tabRelation.relation.findIndex(
                          (o, i) => o.visible === "true"
                        )
                      ].formRecordList[1].table.label
                    )
                  )}
                  {tabRelation.relation.map((nama, tab_ia) => (
                    <div
                      style={{
                        display: nama.visible === "true" ? "" : "none",
                      }}
                      md={2}
                      key={tab_ia}
                      className="nopad"
                    >
                      {console.log(nama.visible)}
                      {isMobile ? (
                        <Nav.Item className="cur">
                          <Nav.Link
                            className=""
                            eventKey={nama.formRecordList[1].table.label}
                          >
                            {/* {nama.formRecordList[1].table.label} */}
                            {console.log(nama.formRecordList[3].page
                              .record_count)}
                            {nama.formRecordList[3].page
                              .record_count !== "0"
                              ? nama.formRecordList[1].table
                                .label +
                              " (" +
                              nama.formRecordList[3].page
                                .record_count +
                              ")"
                              : nama.formRecordList[1].table
                                .label}
                          </Nav.Link>
                        </Nav.Item>
                      ) : (
                        <Nav.Item color="black" className="cur">
                          <Nav.Link eventKey={nama.formRecordList[1].table.label}>
                            {console.log(nama.formRecordList[1].table.label)}
                            {nama.formRecordList[3].page
                              .record_count !== "0"
                              ? nama.formRecordList[1].table
                                .label +
                              " (" +
                              nama.formRecordList[3].page
                                .record_count +
                              ")"
                              : nama.formRecordList[1].table
                                .label}
                          </Nav.Link>
                        </Nav.Item>
                      )}
                    </div>
                  ))}
                </div>
              </Nav>

              <Tab.Content>
                {tabRelation.relation.map((nam, tab_i) => (
                  <Tab.Pane
                    key={tab_i}
                    eventKey={nam.formRecordList[1].table.label}
                  >
                    {console.log(nam.formRecordList[1].table.label)}
                    <RelationList
                      listname={nam.formRecordList[1].table.label}
                      tableName={nam.formRecordList[1].table.name}
                      recordList={nam}
                      i_d={i_d.current}
                      prevTab={tableName.current}
                      rd={record}
                      all_ref_cols={record}
                    // showFormCompo={showFormCompo(nm, rid, ty)}
                    ></RelationList>
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          </div>
        ) : null}
      </div>
    );
  } else {
    return (
      <div
        className="d-flex justify-content-center align-items-center w-100"
        style={{ height: "95vh" }}
      >
        <WorkInProgress />
      </div>
    );
  }
};


export default ConfigureCompo;
