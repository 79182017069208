import axios from "axios";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { AutoPortalContext } from "../Context";
import WorkInProgress from "./WorkInProgress";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRef } from "react";
import "../css/ApplicationSetUp.css";
import { Modal } from "react-bootstrap";
import Header from "./Header";

function TableSetup() {
  const navigation = useNavigate();
  const { loca, token, getVerify } = useContext(AutoPortalContext);

  let height = "90%";

  const [rcd, setRcd] = useState([]);
  // const [resultrcd, setResultRcd] = useState([]);
  const [mainRcd, setMainRcd] = useState();
  const [loading, setLoading] = useState(true);
  const [isProcessing, setProcessing] = useState(false);
  // const [appRole, setAppRole ] = useState();
  const [allRoles, setAllRoles] = useState([]);
  const [fvalue, setFvalue] = useState([{ id: 0, name: "" }]);
  const [searchval, setSearchVal] = useState("");
  const [showPref, setShowPref] = useState(false);
  const [searchParam, setSearchParam] = useSearchParams();
  const [isClick, setIsClick] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const parentApp = useRef("");
  const appRole = useRef();
  const resultrcd = useRef();
  const outerCont = useRef([]);
  const topIp = useRef([]);
  const inputIndex = useRef(0);

  const getInitialRcd = (app) => {
    console.log("approleid", appRole);
    let vl = appRole.current;
    console.log("vl ", vl);
    let url = ``;
    let header;
    if (
      (app === "None" || app === "autoportal") &&
      parentApp.current !== "Marketplace"
    ) {
      url = `${loca}/lom/get/alltables/${1}`;
      header = {
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      };
    } else {
      url = `http://localhost:8084/marketplace/get/alltables/${1}`;
      header = {
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
        "market-application": "Marketplace",
      };
    }
    if (appRole.current !== undefined && appRole.current !== "0") {
      axios
        .get(url, {
          headers: header,
        })
        .then(
          (resp) => {
            let appModul = resp.data;
            console.log(appModul);
            let arrObj = [];
            for (let j = 0; j < appModul.appBasedTabel.length; j++) {
              let modul = appModul.appBasedTabel[j];
              let app = appModul.appBasedTabel[j].formTableSetUp[0].application;
              var lstt = [];
              for (let i = 0; i < modul.formTableSetUp[1].records.length; i++) {
                let val = false;
                if (
                  modul.formTableSetUp[1].records[i].read === "true" &&
                  modul.formTableSetUp[1].records[i].write === "true" &&
                  modul.formTableSetUp[1].records[i].update === "true" &&
                  modul.formTableSetUp[1].records[i].delete === "true"
                )
                  val = true;
                lstt.push({
                  form_table: modul.formTableSetUp[1].records[i].form_table,
                  read: modul.formTableSetUp[1].records[i].read,
                  write: modul.formTableSetUp[1].records[i].write,
                  update: modul.formTableSetUp[1].records[i].update,
                  delete: modul.formTableSetUp[1].records[i].delete,
                  ref: true,
                  all: val,
                });
              }
              let obj = { app: app, show: true, rcd: lstt };
              arrObj.push(obj);
            }
            setMainRcd(appModul);
            setRcd(arrObj);
            //   setResultRcd(lstt);
            resultrcd.current = arrObj;
            console.log(lstt);
            setLoading(false);
          },
          (error) => {
            navigation("/error");
            console.log(error);
          }
        );
    } else {
      setLoading(false);
      //  toast.error("You dont have any application")
    }
  };

  const getAllByRole = (key) => {
    axios
      .get(`${loca}/lom/get/allroles`, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then((resp) => {
        let modul = resp.data;
        console.log(modul);
        allRoles.splice(0, allRoles.length);
        for (var i = 0; i < modul.roleList.length; i++) {
          allRoles.push(modul.roleList[i]);
        }
        // setAppRole(modul.roleList[0].id);

        if (modul.roleList.length > 0) appRole.current = modul.roleList[0].id;
        else appRole.current = "0";

        let app = "autoportal";

        getInitialRcd(app);
      });
  };
  const selectedRcd = (role) => {
    console.log("aoi", role);
    axios
      .get(`${loca}/lom/get/alltables/${role}`, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let appModul = resp.data;
          console.log(appModul);
          let appArr = [];
          for (let k = 0; k < appModul.appBasedTabel.length; k++) {
            var lstt = [];
            let modul = appModul.appBasedTabel[k];
            let app = appModul.appBasedTabel[k].formTableSetUp[0].application;
            for (let i = 0; i < modul.formTableSetUp[1].records.length; i++) {
              let val = false;
              if (
                modul.formTableSetUp[1].records[i].read === "true" &&
                modul.formTableSetUp[1].records[i].write === "true" &&
                modul.formTableSetUp[1].records[i].update === "true" &&
                modul.formTableSetUp[1].records[i].delete === "true"
              ) {
                val = true;
              }

              lstt.push({
                form_table: modul.formTableSetUp[1].records[i].form_table,
                read: modul.formTableSetUp[1].records[i].read,
                write: modul.formTableSetUp[1].records[i].write,
                update: modul.formTableSetUp[1].records[i].update,
                delete: modul.formTableSetUp[1].records[i].delete,
                ref: true,
                all: val,
              });
            }
            appArr.push({ app: app, show: true, rcd: lstt });
          }
          setMainRcd(appModul);
          setRcd(appArr);
          //  setResultRcd(lstt)
          resultrcd.current = appArr;
          // setAppRole(role);
          console.log(resultrcd.current);

          appRole.current = role;
          //   console.log(lstt);
          setLoading(false);
          console.log("resultrcd", resultrcd.current);
          if (searchval !== "") searchModule(searchval, true);
        },
        (error) => {
          navigation("/error");
          console.log(error);
        }
      );
  };

  const closeApp = (index) => {
    let frcd = rcd;
    frcd[index].show = !frcd[index].show;
    setRcd([...frcd]);
  };

  const setSection = (o_i, index, val) => {
    var shrcd = rcd;
    console.log(o_i, index, val);
    console.log(shrcd);
    shrcd[o_i].rcd[index].ref = val;
    setRcd([...shrcd]);
  };

  const setAllTableAcc = (check, appIndex, index) => {
    let rcdd = rcd;
    console.log(check);

    rcdd[appIndex].rcd[index].read = check;
    rcdd[appIndex].rcd[index].write = check;
    rcdd[appIndex].rcd[index].update = check;
    rcdd[appIndex].rcd[index].delete = check;
    if (check === "true") rcdd[appIndex].rcd[index].all = true;
    else rcdd[appIndex].rcd[index].all = false;

    console.log(rcdd[index]);

    setRcd([...rcdd]);
    console.log(resultrcd.current);
    resultrcd.current[appIndex].rcd.splice(index, 1, rcdd[appIndex].rcd[index]);
    console.log(resultrcd.current[index]);
  };

  const setRWUD = (check, index, appIndex, type) => {
    console.log(check, index, type);
    console.log(index);
    let rcdd = rcd;
    switch (type) {
      case "read":
        rcdd[appIndex].rcd[index].read = check;
        break;
      case "write":
        rcdd[appIndex].rcd[index].write = check;
        break;
      case "update":
        rcdd[appIndex].rcd[index].update = check;
        break;
      case "delete":
        rcdd[appIndex].rcd[index].delete = check;
    }
    if (
      rcdd[appIndex].rcd[index].delete === "true" &&
      rcdd[appIndex].rcd[index].update === "true" &&
      rcdd[appIndex].rcd[index].write === "true" &&
      rcdd[appIndex].rcd[index].read === "true"
    ) {
      rcdd[appIndex].rcd[index].all = true;
    } else if (
      rcdd[appIndex].rcd[index].delete === "false" ||
      rcdd[appIndex].rcd[index].update === "false" ||
      rcdd[appIndex].rcd[index].write === "false" ||
      rcdd[appIndex].rcd[index].read === "false"
    ) {
      rcdd[appIndex].rcd[index].all = false;
    }

    setRcd([...rcd]);
    //   resultrcd.current.splice( index,1 ,rcdd[index])
    console.log(resultrcd.current);
    // mainRcd.formTableSetUp[1].records = resultrcd.current;
    // setMainRcd(mainRcd);
    console.log(mainRcd);
    //   console.log(resultrcd.current[index]);
    //    console.log(resultrcd.current);
  };

  const modSearch = (nam) => {
    const search = rcd;
    let xyz = [];
    console.log(nam, resultrcd.current, searchval);

    if (nam.length > 2) {
      for (let j = 0; j < resultrcd.current.length; j++) {
        let abc = [];
        let app = resultrcd.current[j].app;
        let mod = resultrcd.current[j].rcd;
        for (var i = 0; i < mod.length; i++) {
          let res = mod[i].form_table.label.toLowerCase();

          if (res.includes(nam.toLowerCase())) {
            abc.push(mod[i]);
          }
        }
        xyz.push({ app: app, show: true, rcd: abc });
      }

      setRcd(xyz);
      console.log(rcd);
    }
    if (nam.length === 1 || nam.length === 0 || nam.length === 2) {
      console.log("lest then");
      setRcd(resultrcd.current);
    }
  };

  const submitFn = () => {
    setIsClick(true);
    setProcessing(true);

    //  mrcd.applicatioMenu[1].records = rcd;
    console.log(mainRcd, resultrcd.current);
    for (let i = 0; i < mainRcd.appBasedTabel.length; i++) {
      mainRcd.appBasedTabel[i].formTableSetUp[1].records =
        resultrcd.current[i].rcd;
    }

    console.log(mainRcd);
    // let t=mainRcd.formTableSetUp[0].application;
    //   console.log(t);
    //   let obj ={formTableSetUp:[{application: mainRcd.formTableSetUp[0].application},{records:rcd}]}
    setMainRcd(mainRcd);
    //  let mrcd = mainRcd;
    let json = { role: fvalue, mainRecord: mainRcd };
    console.log(json);
    axios
      .post(loca + "/lom/set/table", json, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          console.log(resp.data);
          getVerify();
          toast("All set", { position: "bottom-center", type: "success" });
          setProcessing(false);
        },
        (error) => {
          navigation("/error");
          setProcessing(false);
          toast(error, { position: "bottom-center", type: "error" });
          console.log(error);
        }
      )
      .finally(() => {
        console.log("finally");
        setIsClick(false);
      });
  };

  const setRefRcd = (value, id) => {
    let fv = fvalue;
    console.log("value ", value, id, fvalue);
    fv[inputIndex.current].name = value;
    fv[inputIndex.current].id = id + "";
    setFvalue([...fvalue]);
    setSearchVal("");
  };

  const searchModule = (nam, index) => {
    // const search = allRoles;
    // document.getElementById().getBoundingClientRect().top
    console.log(outerCont.current.getBoundingClientRect().top);
    console.log(topIp.current[index]?.getBoundingClientRect().height);
    console.log(
      Number(
        topIp.current[index]?.getBoundingClientRect().top -
          outerCont.current.getBoundingClientRect().top
      )
    );
    inputIndex.current = index;
    let abc = [];
    let ID = 0;
    console.log(nam);
    if (nam.length > 2) {
      for (var i = 0; i < allRoles.length; i++) {
        let res = allRoles[i].name;
        let id = allRoles[i].id;
        if (res.toLowerCase().includes(nam.toLowerCase())) {
          abc.push({ id: id, name: res });
          setSearchVal(abc);
          ID = id;
          break;
        }
      }
      console.log(allRoles);
      console.log(searchval);
    }
    if (nam.length == 1 || nam.length == 0 || nam.length == 2) {
      console.log("lest then");
      setSearchVal("");
    }
    console.log(searchval);
    let fv = fvalue;
    fv[index].name = nam;
    fv[index].id = ID;
    setFvalue([...fv]);
  };

  const handleShow = (index) => {
    inputIndex.current = index;
    setShowPref(true);
  };

  const handleClose = () => {
    setShowPref(false);
  };

  const increamentIP = () => {
    fvalue.push({ id: 0, name: "" });
    setFvalue([...fvalue]);
  };

  const decreamentIP = (index) => {
    fvalue.splice(index, 1);
    setFvalue([...fvalue]);
  };

  const clearIP = () => {
    let fv = [{ id: 0, name: "" }];
    setFvalue([...fv]);
  };

  const getRoleByApp = (app) => {
    console.log(app);
    if (app === "None") {
      getAllByRole();
      getInitialRcd(app);
      return;
    }
    axios
      .get(`http://localhost:8084/marketplace/get/allroles`, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": app,
        },
      })
      .then((resp) => {
        let modul = resp.data;
        console.log(modul, allRoles);
        allRoles.splice(0, allRoles.length);
        for (var i = 0; i < modul.roleList.length; i++) {
          allRoles.push(modul.roleList[i]);
        }

        console.log("first", modul, modul.roleList.length);

        // setAppRole(modul.roleList[0].id);
        if (modul.roleList.length > 0) appRole.current = modul.roleList[0]?.id;
        else appRole.current = "0";

        getInitialRcd(app);
      });
  };

  const selectedAppRcd = (app) => {
    getRoleByApp(app);
  };

  useEffect(() => {
    parentApp.current = searchParam.get("parentApplication");
    setLoading(true);
    setFvalue([{ id: 0, name: "" }]);
    getAllByRole();
  }, [searchParam, refresh]);

  if (!loading) {
    return (
      <div style={{ flexGrow: 1 }}>
        {/* <div style={{ position: "sticky", top: "0" }}> */}
        <Header
          tabName={"FormTable SetUp"}
          isClick={isClick}
          refresh={refresh}
          setRefresh={setRefresh}
        />
        {/* </div> */}
        <div className="list-group ">
          {fvalue[0].id === 0 && (
            <div className="alert alert-warning mx-2 mt-2">
              Please Select Role!
            </div>
          )}
          <div className="p-2">
            <div className="card p-3" style={{ display: "flex" }}>
              <h4 className="m-0 text-center">Search And Set</h4>
              <hr />

              <div id="sidebar" className="mb-2">
                <h4 className="text-start">Application List</h4>
                <select
                  className="dropdn"
                  name=""
                  id=""
                  onChange={(e) => {
                    selectedAppRcd(e.target.value);
                  }}
                >
                  <option value={"None"}>None</option>
                  <option>marketplace</option>
                  <option>loomyarn</option>
                </select>
              </div>

              <div
                ref={(ref) => (outerCont.current = ref)}
                style={{ position: "relative" }}
              >
                <h4 className="text-start">Select Roles</h4>
                {console.log(fvalue)}
                {fvalue.length > 0 &&
                  fvalue.map((obj, ind) => (
                    <div
                      ref={(ref) => {
                        topIp.current[ind] = ref;
                      }}
                      key={ind}
                      className="mb-1"
                      style={{ display: "flex", height: "1.9rem" }}
                    >
                      <input
                        className="inpt_typefil_ref"
                        type="search"
                        placeholder="Search Role"
                        aria-label="Search"
                        onChange={(e) => searchModule(e.target.value, ind)}
                        value={obj.name}
                      ></input>
                      <div
                        className="btnsrc_fil vlpointer"
                        onClick={() => {
                          handleShow(ind);
                        }}
                        // onClick={(e) => {
                        // setObjIndex(index);
                        // setshowModelList(true);
                        // }}
                      >
                        <i
                          className="fa fa-search"
                          aria-hidden="true"
                          // data-bs-toggle="modal"
                          // data-bs-target="#staticBackdrop"
                        ></i>
                      </div>
                      <div>
                        <button
                          className="btn-dark ms-1"
                          onClick={() => {
                            increamentIP();
                          }}
                        >
                          +
                        </button>
                        {fvalue.length > 1 && (
                          <button
                            className="btn-dark ms-1"
                            onClick={() => {
                              decreamentIP(ind);
                            }}
                          >
                            -
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                {/* top: Number(1.9*(inputIndex.current+1)+(1.4*(inputIndex.current)))+"rem" */}
                {searchval.length > 0 && (
                  <div
                    className="ref_multircd"
                    style={{
                      top:
                        Number(
                          topIp.current[
                            inputIndex.current
                          ].getBoundingClientRect().top -
                            outerCont.current.getBoundingClientRect().top
                        ) +
                        topIp.current[
                          inputIndex.current
                        ].getBoundingClientRect().height,
                    }}
                  >
                    {console.log(topIp.current[0])}
                    {searchval.map((obj_ref, or_i) => (
                      <div
                        onClick={(e) => {
                          setRefRcd(obj_ref.name, obj_ref.id);
                          selectedRcd(obj_ref.id);
                        }}
                        key={or_i}
                      >
                        {obj_ref.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>

              <div id="sidebar" className="mb-2">
                <h4 className="text-start">Search Module</h4>
                <input
                  className="form-control mr-sm-2 "
                  type="search"
                  placeholder="Write and get required module ..."
                  aria-label="Search"
                  onChange={(e) => modSearch(e.target.value)}
                ></input>
              </div>

              <div className="p-0 text-center">
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    clearIP();
                  }}
                >
                  Clear All
                </button>
              </div>
              <Modal size="sm" show={showPref} onHide={handleClose}>
                <Modal.Header closeButton></Modal.Header>
                <Modal.Body
                  // style={{ display: "flex", justifyContent: "center" }}
                  style={{ display: "contents", textAlign: "center" }}
                >
                  <table className="table table-bordered table-striped table-hover p-1">
                    <thead>
                      <tr className="obj_name">
                        <th
                          className="vlpointer"
                          style={{ verticalAlign: "middle" }}
                        >
                          User Roles
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {allRoles.map((obj, obj_i) => (
                        <tr className="obj_value" key={obj_i}>
                          <td
                            key={obj_i}
                            className="val_pad vlpointer"
                            style={{ verticalAlign: "middle" }}
                            onClick={(e) => {
                              setRefRcd(obj.name, obj.id);
                              selectedRcd(obj.id);
                              handleClose();
                            }}
                          >
                            {obj.name}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  {/* <div className="row bck bck-rel">
                <div className="col-lg mid">User Role</div>
              </div> */}
                  {/* <PrefCompo
                pref="user"
                setRefer={(val) => setRef_Fields(val)}
                colarray={prefCall}
                tablename={tableName}
                handleClose={() => handleCloseRefresh()}
              ></PrefCompo> */}
                </Modal.Body>
                {/* <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                </Modal.Footer> */}
              </Modal>
            </div>

            {/* <select
              name=""
              id=""
              onChange={(e) => {
                selectedRcd(e.target.value);
              }}

            >
        
              {console.log(allRoles)}
              {allRoles.map((obj, id) => (

                <option key={id} value={obj.id}  >
                  {obj.name}
                </option>
              ))}

            
            </select>

            <div
              id="sidebar"
              className="search-block "
            >
              <input
                className="lfnavserach  form-control mr-sm-2 "
                type="search"
                placeholder="Search"
                aria-label="Search"
                onChange={(e) => searchModule(e.target.value)}
              ></input>

          
            </div> */}
            <div className="row fr">
              {console.log(rcd)}
              {fvalue[0].id > 0 &&
                rcd.map((ob, indx) => (
                  <div key={indx} className="card text-center mt-4">
                    <h4 className="card-header">
                      {ob.app.value}{" "}
                      {ob.show && (
                        <i
                          className="fa fa-arrow-down vlpointer arrow_sty ms-1"
                          onClick={(e) => closeApp(indx)}
                        ></i>
                      )}
                      {!ob.show && (
                        <i
                          className="fa fa-arrow-right vlpointer arrow_sty ms-1"
                          onClick={(e) => closeApp(indx)}
                        ></i>
                      )}
                    </h4>
                    <div className="card-body row fr">
                      {ob.show &&
                        ob.rcd.map((obj, index) => (
                          <div key={index} className="col-md-4 px-1">
                            <div className="bg-color">
                              <span className="fllf">
                                <input
                                  type="checkbox"
                                  checked={obj.all === true ? true : false}
                                  onChange={(e) => {
                                    setAllTableAcc(
                                      e.target.checked.toString(),
                                      indx,
                                      index
                                    );
                                  }}
                                ></input>
                              </span>
                              {obj.ref === true && (
                                <i
                                  className="fa fa-arrow-down vlpointer cog_pd"
                                  onClick={() => setSection(indx, index, false)}
                                ></i>
                              )}
                              {obj.ref === false && (
                                <i
                                  className="fa fa-arrow-right vlpointer cog_pd"
                                  onClick={() => setSection(indx, index, true)}
                                ></i>
                              )}
                              {obj.form_table.label}
                            </div>
                            {obj.ref === true && (
                              <div>
                                <div className="list-group-item list-group-item-action navpointer ">
                                  <p className="val ">
                                    <span className="space">
                                      <input
                                        className="space1"
                                        type="checkbox"
                                        checked={
                                          obj.read === "true" ? true : false
                                        }
                                        onChange={(e) => {
                                          setRWUD(
                                            e.target.checked.toString(),
                                            index,
                                            indx,
                                            "read"
                                          );
                                        }}
                                      ></input>
                                      Read
                                    </span>

                                    <span className="writespace">
                                      <input
                                        className="space1"
                                        type="checkbox"
                                        checked={
                                          obj.write === "true" ? true : false
                                        }
                                        onChange={(e) => {
                                          setRWUD(
                                            e.target.checked.toString(),
                                            index,
                                            indx,
                                            "write"
                                          );
                                        }}
                                      ></input>
                                      Write
                                    </span>
                                  </p>
                                  <span className="mod-set mod-type mod-col3">
                                    IN
                                  </span>
                                </div>

                                <div className="list-group-item list-group-item-action navpointer ">
                                  <p className="val ">
                                    <span className="space">
                                      <input
                                        className="space1"
                                        type="checkbox"
                                        checked={
                                          obj.update === "true" ? true : false
                                        }
                                        onChange={(e) => {
                                          setRWUD(
                                            e.target.checked.toString(),
                                            index,
                                            indx,
                                            "update"
                                          );
                                        }}
                                      ></input>
                                      Update
                                    </span>

                                    <span className="space">
                                      <input
                                        className="space1"
                                        type="checkbox"
                                        checked={
                                          obj.delete === "true" ? true : false
                                        }
                                        onChange={(e) => {
                                          setRWUD(
                                            e.target.checked.toString(),
                                            index,
                                            indx,
                                            "delete"
                                          );
                                        }}
                                      ></input>
                                      Delete
                                    </span>
                                  </p>
                                  <span className="mod-set mod-type mod-col3">
                                    IN
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
            </div>
          </div>

          {fvalue[0].id > 0 && (
            <div className="mb-2 text-center">
              <button
                className="btn btn-primary"
                disabled={fvalue[0].id === 0 ? true : isProcessing}
                onClick={() => {
                  submitFn();
                }}
              >
                {isProcessing ? (
                  <span>
                    <span
                      className="spinner-grow spinner-grow-sm me-2"
                      role="status"
                    ></span>
                    Loading...
                  </span>
                ) : (
                  "submit"
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="d-flex justify-content-center align-items-center w-100"
        style={{ height: "95vh" }}
      >
        <WorkInProgress />
      </div>
    );
  }
}

export default TableSetup;
