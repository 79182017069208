import axios from "axios";
import React, { useRef } from "react";
import { useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import "../css/FormCSS.css";
import "../css/relationlist.css";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { AutoPortalContext } from "../Context";
import WorkInProgress from "./WorkInProgress";
import { toast } from "react-toastify";
import { isMobile, isTablet } from "react-device-detect";
import Select from "react-select";
import Header from "./Header";

const ApplicationAccessComponent = () => {
  // useContext hooks
  const navigation = useNavigate();
  const { token, loca } = useContext(AutoPortalContext);

  //  //   useLocation hook
  //  const { i_d } = useLocation().state;

  // useState hooks
  const [isColArrSelected, setIsColArrSelected] = useState(true);
  const [isColArr2Selected, setIsColArr2Selected] = useState(true);
  const [columnarray, setColumnarray] = useState([]);
  const [columnarray2, setColumnarray2] = useState([]);
  const [loading, setLoading] = useState(true);
  const [roles, setRoles] = useState([{ label: "None", id: "none" }]);
  const [json, setJson] = useState({ role_id: 0, app: [] });
  const [flag, setFlag] = useState(false);
  const [isProcessing, setProcessing] = useState(false);
  const [isSet, setIsSet] = useState(false);
  const [page_error, setPageError] = useState(false);
  const [error, setError] = useState("");
  const [page_message, setPageMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [isClick, setIsClick] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [selectedValue, setSelectedValue] = useState({
    label: "None",
    id: "none",
    value: "none",
  });
  const [hideCLM, setHideCLM] = useState(true);
  const [searchParam, setSearchParam] = useSearchParams();
  const [srcRecord, setSrcRecord] = useState([]);

  // useRef hooks
  const parentApp = useRef("");
  const ind = useRef(0);
  const index = useRef(0);

  const getRoleList = () => {
    let url;
    let header;
    var tablere = '{"formRecordList":[';
    if (parentApp.current === "Marketplace") {
      url = "http://localhost:8084/marketplace/get/multiple/allrecord";
      header = {
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
        "market-application": "Marketplace",
      };
      tablere += '{"application":{"id":"1","name":"marketplace"}}';
      tablere += ',{"table":{"id":"","name":"local_user"}}';
    } else {
      url = loca + "/lom/get/multiple/allrecord";
      header = {
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      };
      tablere += '{"application":{"id":"1","name":"autoportal"}}';
      tablere += ',{"table":{"id":"","name":"local_user"}}';
    }

    tablere += ',{"records":[]}';
    tablere +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    tablere += ',{"sort":{"asc":"true","column":"id","init":"false"}}';
    tablere +=
      ',{"filter": [{"co": "","cl": "","mc": "", "an": "","ct": "","af": "","rf":{"id":"","value":""}}]}';
    tablere += ',{"timeLine":"' + "" + '"}]}';
    console.log(tablere);
    axios
      .post(url, tablere.toString(), {
        headers: header,
      })
      .then(
        (resp) => {
          let roleList = resp.data;
          console.log(JSON.stringify(roleList));
          let recordList = roleList.formRecordList[2].records;
          if ("Error" in roleList) {
          } else {
            let name = [];
            let id = [];
            for (let i = 0; i < recordList.length; i++) {
              for (let j = 0; j < recordList[i].record.length; j++) {
                if (j == 0 && recordList[i].record[j].name === "id") {
                  id.push(recordList[i].record[j].value);
                }
                if (recordList[i].record[j].name === "name") {
                  name.push(recordList[i].record[j].value);
                }
              }
            }
            let col_mn = [];
            for (let i = 0; i < name.length; i++) {
              if (name[i] !== "external") {
                col_mn.push({ label: name[i], id: id[i], value: id[i] });
              }
            }

            col_mn.unshift({ label: "None", id: "none", value: "none" });
            console.log("Rolename : " + JSON.stringify(col_mn));
            setRoles(col_mn);
            setLoading(false);
          }
        },
        (error) => {
          navigation("/error");
          console.log(error);
        }
      );
  };

  const getAppList = () => {
    let url;
    let header;
    var tablere = '{"formRecordList":[';
    if (parentApp.current === "Marketplace") {
      url = "http://localhost:8084/marketplace/get/multiple/allrecord";
      header = {
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
        "market-application": "Marketplace",
      };
      tablere += '{"application":{"id":"1","name":"marketplace"}}';
      tablere += ',{"table":{"id":"","name":"market_application"}}';
    } else {
      url = loca + "/lom/get/multiple/allrecord";
      header = {
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      };
      tablere += '{"application":{"id":"1","name":"autoportal"}}';
      tablere += ',{"table":{"id":"","name":"application"}}';
    }
    tablere += ',{"records":[]}';
    tablere +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    tablere += ',{"sort":{"asc":"true","column":"id" ,"init":"false"}}';
    tablere +=
      ',{"filter": [{"co": "","cl": "","mc": "", "an": "","ct": "","af": "","rf":{"id":"","value":""}}]}';
    tablere += ',{"timeLine":"' + "" + '"}]}';
    console.log(tablere);
    axios
      .post(url, tablere.toString(), {
        headers: header,
      })
      .then(
        (resp) => {
          let roleList = resp.data;
          console.log(roleList);
          let recordList = roleList.formRecordList[2].records;
          console.log(JSON.stringify(recordList));
          if ("Error" in roleList) {
          } else {
            let name = [];
            let label = [];
            let id = [];
            let app_code = [];
            for (let i = 0; i < recordList.length; i++) {
              for (let j = 0; j < recordList[i].record.length; j++) {
                if (j == 0 && recordList[i].record[j].name === "id") {
                  id.push(recordList[i].record[j].value);
                }
                if (recordList[i].record[j].name === "name") {
                  name.push(recordList[i].record[j].value);
                }
                if (recordList[i].record[j].name === "app_code") {
                  app_code.push(recordList[i].record[j].value);
                }
                if (recordList[i].record[j].name === "label") {
                  label.push(recordList[i].record[j].value);
                }
              }
            }
            let col_mn = [];
            for (let i = 0; i < name.length; i++) {
              col_mn.push({
                name: name[i],
                label: label[i],
                id: id[i],
                app_code: app_code[i],
                access: "false",
              });
            }
            console.log("name : " + JSON.stringify(col_mn));
            setColumnarray([...col_mn]);
            setColumnarray2([]);
            getRoleList();
          }
        },
        (error) => {
          navigation("/error");
          console.log(error);
        }
      );
  };

  const getInitialDetails = (val) => {
    console.log("run");
    setError("");
    setMessage("");
    setPageMessage(false);
    setPageError(false);
    let j = { role_id: val };
    let url = "";
    if (parentApp.current === "Marketplace") {
      url = "http:/localhost:8084/marketplace/get/appaccess";
    } else {
      url = loca + "/lom/get/appaccess";
    }
    axios
      .post(url, j, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let result = resp.data;
          console.log(JSON.stringify(result));
          setColumn(result.appList);
        },
        (error) => {
          navigation("/error");
          console.log(error);
        }
      );
  };

  const setColumn = (prf) => {
    var clm = columnarray;
    var clm2 = [];
    console.log("jjj : " + JSON.stringify(clm), prf);
    // var prf = prefarray;
    for (var ii = 0; ii < clm.length; ii++) {
      clm[ii].check = false;
    }
    for (var i = 0; i < clm.length; i++) {
      if (prf) {
        for (var p = 0; p < prf.length; p++) {
          if (clm[i].id === prf[p].id) {
            clm[i].check = true;
            clm2.push({
              name: clm[i].name,
              label: clm[i].label,
              id: clm[i].id,
              app_code: clm[i].app_code,
              access: "true",
            });
          }
        }
      }
    }
    console.log(clm);
    setColumnarray([...clm]);
    setColumnarray2([...clm2]);
    setHideCLM(false)
    // oneRef.current = false;
  };

  const changeCheckBox = (e, index) => {
    var col = columnarray;
    col[index].check = e;
    setColumnarray([...col]);
  };

  const selected = (index, clm) => {
    console.log(index, clm);
    if (clm === "clm1") {
      setIsColArrSelected(true);
      setIsColArr2Selected(false);
      for (let i = 0; i < srcRecord.length; i++) {
        if (i === index) {
          document
            .getElementById("clm1")
            .children.item(i)
            .classList.add("selected-row");
        } else {
          document
            .getElementById("clm1")
            .children.item(i)
            .classList.remove("selected-row");
        }
      }
      for (let i = 0; i < columnarray2.length; i++) {
        document
          .getElementById("clm2")
          .children.item(i)
          .classList.remove("selected-row");
      }
    } else {
      setIsColArrSelected(false);
      setIsColArr2Selected(true);
      ind.current = index;
      for (let i = 0; i < columnarray2.length; i++) {
        if (i === index) {
          document
            .getElementById("clm2")
            .children.item(i)
            .classList.add("selected-row");
        } else {
          document
            .getElementById("clm2")
            .children.item(i)
            .classList.remove("selected-row");
        }
      }
      for (let i = 0; i < srcRecord.length; i++) {
        document
          .getElementById("clm1")
          .children.item(i)
          .classList.remove("selected-row");
      }
    }
  };

  const shiftRight = () => {
    let clm = srcRecord;
    let clm2 = columnarray2;
    if (document.getElementsByClassName("selected-row").item(0)) {
      let lab = document
        .getElementsByClassName("selected-row")
        .item(0).innerText;
      console.log(lab);
      for (let i = 0; i < srcRecord.length; i++) {
        // console.log(clm[i].label, lab, clm[i].type);
        if (i === index.current) {
          console.log("checkkkkkkk");
          document
            .getElementsByClassName("selected-row")
            .item(0)
            .classList.remove("selected-row");
          if (clm[i].type !== "ui") {
            clm[i].check = true;
            clm[i].access = "true"
          }
          // let n_co = clm2.length + 1;
          // let n = {
          //   label: clm[i].label,
          //   name: clm[i].name,
          //   id: clm[i].id,
          //   access: "true",
          //   app_code: clm[i].app_code,
          // };
          clm2.push(clm[i]);
        }
      }
      console.log(clm);
      console.log(clm2);
      setIsColArrSelected(false);
      console.log(isColArrSelected);
      setSrcRecord([...clm]);
      // setRcd([...clm]);
      setColumnarray2([...clm2]);
      setIsSet(true);
    }
  };

  const shiftLeft = () => {
    let clm = columnarray;
    let clm2 = columnarray2;
    let name = "";
    if (document.getElementsByClassName("selected-row").item(0)) {
      console.log(
        "shift left : " +
        document.getElementsByClassName("selected-row").item(0)
      );
      let lab = document
        .getElementsByClassName("selected-row")
        .item(0).innerText;
      for (let i = 0; i < columnarray2.length; i++) {
        if (i === ind.current) {
          name = clm2[i].name;
          clm2.splice(i, 1);
          document
            .getElementsByClassName("selected-row")
            .item(0)
            .classList.remove("selected-row");
        }
      }
      for (let i = 0; i < columnarray.length; i++) {
        if (clm[i].name === name) {
          clm[i].check = false;
        }
      }
      console.log(clm);
      setIsColArr2Selected(false);
      console.log(isColArr2Selected);
      setColumnarray([...clm]);
      // setRcd([...clm]);
      setColumnarray2([...clm2]);
      setIsSet(true);
    }
  };

  const setColumnbtn = () => {
    if (json.role_id === 0 || json.role_id === "none") {
      setIsColArr2Selected(false);
    } else {
      var colm = columnarray;
      var colm2 = [];
      for (var i = 0; i < colm.length; i++) {
        if (colm[i].check === true) {
          colm2.push({
            name: colm[i].label,
            id: colm[i].id,
            app_code: colm[i].app_code,
            access: "true",
          });
        } else {
          colm2.push({
            name: colm[i].label,
            id: colm[i].id,
            app_code: colm[i].app_code,
            access: "false",
          });
        }
      }
      setColumnarray2([...colm2]);
      console.log("colm2 : " + JSON.stringify(colm2));
      setIsSet(true);
      setIsColArr2Selected(true);
    }
  };

  const submitColumnbtn = () => {
    // console.log(JSON.stringify(columnarray2));
    //    console.log("id : "+i_d );
    setIsClick(true);
    setLoading(true);
    setError("");
    setMessage("");
    setPageMessage(false);
    setPageError(false);
    setIsSet(false);
    setProcessing(true);
    json.app = [];
    for (let i = 0; i < columnarray2.length; i++) {
      json.app.push(columnarray2[i]);
    }

    console.log(JSON.stringify(json));
    axios
      .post(loca + "/lom/set/appaccess", json, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let result = resp.data;
          console.log("result : " + JSON.stringify(result));
          toast.success("Successfully  Inserted");
          if ("Error" in result) {
            setPageError(true);
            setError(result.Error);
          } else {
            setPageMessage(true);
            setMessage(result.Message);
          }
          setLoading(false);
          setProcessing(false);
        },
        (error) => {
          navigation("/error");
          setProcessing(false);
          toast(error, { position: "top-center", type: "error" });
          console.log(error);
        }
      )
      .finally(() => {
        console.log("finally");
        setIsClick(false);
      });
  };

  const setRoleId = (val) => {
    setSelectedValue(val);

    if (val.id !== "none") {
      val = val.id;
      let j = json;
      j.role_id = val;
      setJson({ ...j });
      // setHideCLM(false)
      getInitialDetails(val);
    } else {
      let clm = columnarray;
      for (var ii = 0; ii < clm.length; ii++) {
        clm[ii].check = false;
      }
      setHideCLM(true)
      setColumnarray([...clm]);
      setColumnarray2([]);
    }

    for (let i = 0; i < columnarray2.length; i++) {
      document
        .getElementById("clm2")
        .children.item(i)
        .classList.remove("selected-row");
    }
    if (selectedValue.id !== "none") {
      for (let i = 0; i < srcRecord.length; i++) {
        document
          .getElementById("clm1")
          .children.item(i)
          .classList.remove("selected-row");
      }
    }
    setIsColArrSelected(true);
    setIsColArr2Selected(true);
  };

  const searchApp = (nam) => {
    console.log(nam, srcRecord);
    let arr = [];
    if (nam.length > 2) {
      for (let i = 0; i < columnarray.length; i++) {
        console.log(columnarray[i].label, nam);
        let srcLabel = columnarray[i].label.toLowerCase();
        let includes = srcLabel.includes(nam.toLowerCase());
        console.log("includes", includes);
        if (includes) {
          arr.push(columnarray[i]);
        }
      }
    } else {
      arr = columnarray;
    }

    setSrcRecord([...arr]);
  };

  //   useEffect hooks
  useEffect(() => {
    setLoading(true);
    parentApp.current = searchParam.get("parentApplication");
    setSelectedValue({
      label: "None",
      id: "none",
      value: "none",
    });

    setHideCLM(true);
    getAppList();
  }, [searchParam, refresh]);

  useEffect(() => {
    console.log(columnarray, srcRecord);
    setSrcRecord([...columnarray]);
  }, [columnarray]);

  const refreshPage = () => {
    setLoading(true);
    setSelectedValue({
      label: "None",
      id: "none",
      value: "none",
    });
    setHideCLM(true);
    getAppList();
  };

  if (!loading) {
    return (
      <div id={"form"} className="pagesetup">
        <Header tabName={"Jwt Application Access"}
          isClick={isClick}
          refresh={refresh}
          setRefresh={setRefresh}
        />
        {/* <div
          className="btndiv"
          onContextMenu={(e) => {
            e.preventDefault();
            // m_rid.current = obj.id;
            // setContext(e.button, e.nativeEvent.pageX, e.nativeEvent.pageY);
          }}
        >
          <div className="row fr" style={{ padding: "8px 0px" }}>
            <div
              className="col-md-3"
              style={{ alignSelf: "center", textAlign: "start" }}
            >
              <span>
                {console.log(isClick)}
                <i
                  disabled={isClick}
                  className=" backic fa fa-arrow-left bck_btt_mrg"
                  
                  onClick={() => {
                    if (!isClick) {
                      navigation(-1);
                    }
                  }}
                ></i>
              </span>
              <span style={{ paddingLeft: "1.5rem" }}>
                <i
                  className="fa fa-refresh"
                  
                  title="Refresh"
                  onClick={refreshPage}
                  style={{ cursor: "pointer" }}
                ></i>
              </span>
            </div>
            <div className="col-md tab_head">Jwt Application Access</div>
            <div className="col-md-3"></div>
          </div>
        </div> */}
        <div
          style={{ flexGrow: 1 }}
          className="container justifyContentCenter align-items-center my-5 "
        >
          <div className="d-flex justify-content-center mb-2">
            <div style={{ width: "60%" }}>
              <h4 className="text-start">Select User </h4>

              {/* <select
              style={{width:"100%"}}
                onChange={(e) => {
                  setRoleId(e.target.value);
                }}
              >
                <option value={"none"}>None</option>
                {roles.map((obj, index) => (
                  <option key={index} value={obj.id}>
                    {obj.label}
                  </option>
                ))}
              </select> */}

              <Select
                isSearchable={true}
                options={roles}
                value={selectedValue}
                onChange={(e) => {
                  setRoleId(e);
                }}
              />
            </div>
            {page_error === true && (
              <div
                className="alert alert-danger"
                role="alert"
                style={{
                  padding: "0.2rem 0.2rem",
                  marginBottom: "0px",
                }}
              >
                {error}
              </div>
            )}
          </div>
          <div className="row  justify-content-center">
            <div id="" className="col-md-4">
              <div className="search-container">
                {/* search for columnarray */}

                <input
                  placeholder="Search... "
                  type="search"
                  className="form-control mb-2"
                  style={{
                    width: "100%",
                    transform: "translateX(-8px)",
                  }}
                  onChange={(e) => {
                    searchApp(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="up-down">
              <div>
                <button
                  style={{ visibility: "hidden" }}
                  className="ryt-left-btn"
                  disabled={isColArr2Selected}
                  onClick={() => {
                    shiftRight();
                  }}
                >
                  {">"}
                </button>
              </div>
            </div>
            <div className="col-md-4 "></div>
          </div>
          <div className="row" style={{ justifyContent: "center", gap: "3px" }}>
            <div id="clm1" className="col-md-4 box-pref">
              {srcRecord.length > 0 &&
                !hideCLM &&
                srcRecord.map((obj, obj_i) => (
                  <div key={obj_i}>
                    {!obj.check && (
                      <div className="row fr">
                        <div
                          className="col-md"
                          onClick={() => {
                            selected(obj_i, "clm1");
                            index.current = obj_i;
                          }}
                        >
                          {obj.label}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
            {!(isMobile || isTablet) ? (
              <div className="up-down">
                <div>
                  <button
                    className="ryt-left-btn"
                    disabled={isColArr2Selected}
                    onClick={() => {
                      shiftRight();
                    }}
                  >
                    {">"}
                  </button>
                </div>
                <div>
                  <button
                    className="ryt-left-btn"
                    disabled={isColArrSelected}
                    onClick={() => {
                      shiftLeft();
                    }}
                  >
                    {"<"}
                  </button>
                </div>
              </div>
            ) : (
              <div className="up_down d-flex justify-content-center">
                <button
                  disabled={isColArrSelected}
                  className="up-down-view-pref"
                  onClick={(e) => shiftLeft()}
                >
                  {">"}
                </button>
                <button
                  disabled={isColArr2Selected}
                  className="up-down-view-pref"
                  onClick={(e) => shiftRight()}
                >
                  {"<"}
                </button>
              </div>
            )}
            <div id="clm2" className="col-md-4 box-pref">
              {/* {page_message === true && (
                <div
                  className="alert alert-success"
                  role="alert"
                  style={{
                    padding: "0.2rem 0.2rem",
                    marginBottom: "0px",
                  }}
                >
                  {message}
                </div>
              )} */}
              {columnarray2.map((obj, index) => (
                <p
                  style={{ margin: 0 }}
                  key={index}
                  className="columnarray2"
                  onClick={() => {
                    selected(index, "clm2");
                  }}
                  value={obj.label}
                >
                  {obj.label}
                </p>
              ))}
            </div>
            <div className="row my-3  " style={{ justifyContent: "center" }}>
              <div className="col-md-3" style={{ textAlign: "center" }}>
                <button
                  type="button"
                  style={{ borderRadius: "5px" }}
                  className=" btnnn btn btn-primary"
                  disabled={!isSet}
                  onClick={() => {
                    submitColumnbtn();
                  }}
                >
                  {isProcessing ? (
                    <span>
                      <span
                        className="spinner-grow spinner-grow-sm me-2"
                        role="status"
                      ></span>
                      Loading...
                    </span>
                  ) : (
                    "submit"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
      className="d-flex justify-content-center align-items-center w-100"
      style={{ height: "95vh" }}
      >
        <WorkInProgress />
      </div>
    );
  }
};

export default ApplicationAccessComponent;
