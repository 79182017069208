import axios from "axios";
import React, { useContext, useEffect, useState, useRef } from "react";
import { AutoPortalContext } from "../Context";

const PreferenceComponent = ({
  handleClose,
  pref,
  setRefer,
  colarray,
  tablename,
}) => {
  const { token, loca } = useContext(AutoPortalContext);
  const firstRender = useRef(false);
  const oneRef = useRef(false);
  // const [tablename, setTableName] = useState("");
  const [columnarray, setColumnArray] = useState(colarray);
  const [columnarray2, setColumnarray2] = useState([]);
  const [prefarray, setPrefArray] = useState([]);
  const [json, setJson] = useState({});
  const [col_val, setCol_val] = useState(0);
  // const [pref, setPref] = useState("");
  const ind = useRef(0);
  console.log("ck", colarray);
  useEffect(() => {

    ind.current = -1;
    getPrefRecord();
  }, []);

  useEffect(() => {
    if (firstRender.current && oneRef.current) {
      setColumn();
    } else {
      firstRender.current = true;
      oneRef.current = true;
    }
  }, [prefarray]);

  const getPrefRecord = () => {

    axios
      .get(loca + "/lom/get/pref/" + pref + "/" + tablename, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then((resp) => {
        var sysrecord = resp.data;
        console.log(sysrecord);
        if (pref === "user") {
          console.log(sysrecord);
          setPrefArray([...sysrecord.userPreference[2].column]);

          setJson(sysrecord);
        } else if (pref === "owner") {
          setPrefArray([...sysrecord.ownerPreference[2].column]);
          setJson(sysrecord);
        }
      });
  };

  const setColumn = () => {
    var clm = columnarray;
    var clm2 = columnarray2;
    var prf = prefarray;
    for (var ii = 0; ii < clm.length; ii++) {
      clm[ii].check = false;
    }
    for (var i = 0; i < clm.length; i++) {
      for (var p = 0; p < prf.length; p++) {
        if (clm[i].name === prf[p].name) {
          clm[i].check = true;
          var jj = {
            label: clm[i].label,
            co: prf[p].co,
          };
          clm2.push(jj);
        }
      }
    }
    let clm22 = clm2.sort((a, b) => (a.co > b.co ? 1 : -1));
    console.log(clm22);
    setColumnArray([...clm]);
    setColumnarray2([...clm2]);
    oneRef.current = false;
  };

  const changeColumn = (e, index) => {
    var col = columnarray;
    col[index].check = e;
    setColumnArray([...col]);
  };

  const setColumnbtn = () => {
    var colm = columnarray;
    var colm2 = columnarray2;
    for (var i = 0; i < colm.length; i++) {
      var dd = false;
      var ind = 0;
      var ee = false;
      for (var c = 0; c < colm2.length; c++) {
        if (colm[i].check === true) {
          if (colm[i].label === colm2[c].label) {
            dd = true;
          }
        } else if (colm[i].check === false) {
          if (colm[i].label === colm2[c].label) {
            ee = true;
            ind = c;
          }
        }
      }
      if (dd === false && colm[i].check === true) {
        var ln = colm2.length;
        ln = ln + 1;
        colm2.push({ label: colm[i].label, co: "" + ln });
      }
      if (ee === true && colm[i].check === false) {
        colm2.splice(ind, 1);
      }
    }
    console.log(colm2);
    setColumnarray2([...colm2]);
  };

  const submitColumnbtn = () => {
    var colm = columnarray;
    var colm2 = columnarray2;
    var sub = [];
    // var count = 0;
    for (var i = 0; i < colm2.length; i++) {
      for (var j = 0; j < colm.length; j++) {
        if (colm2[i].label === colm[j].label) {
          sub.push({ name: colm[j].name, co: colm2[i].co });
        }
      }
    }

    if (pref === "user") {
      console.log("kmds;;");
      json.userPreference[2].column = sub;
      json.userPreference[1].table.name = tablename;
      console.log("kmds;;");
      axios
        .post(loca + "/lom/set/user/preference/", json, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
          },
        })
        .then((resp) => {
          var sysrecord = resp.data;
          handleClose();
        });
    } else if (pref === "owner") {
      json.ownerPreference[2].column = sub;
      json.ownerPreference[1].table.name = tablename;
      axios
        .post(loca + "/lom/set/owner/preference/", json, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
          },
        })
        .then((resp) => {
          var sysRecord = resp.data;
          console.log("syssssssssss : " + JSON.stringify(sysRecord));
          handleClose();
        });
    }
  };

  const selected = (index) => {
    console.log(index)
    for (let i = 0; i < columnarray2.length; i++) {
      if (i === index) {
        document.getElementById("clm2").children.item(i).classList.add("selected-row")
      } else {
        document.getElementById("clm2").children.item(i).classList.remove("selected-row")
      }

    }

    ind.current = index;
  }
  const setColumnOrder = (pm) => {
    var clm = [];
    var col_odr = ind.current;
    var col_odr2 = 0;
    console.log(col_odr)

    if (ind.current !== -1) {
      if (pm) {
        if (col_odr < columnarray2.length - 1) {
          col_odr2 = col_odr + 1;
          for (let i = 0; i < columnarray2.length; i++) {
            if (i !== col_odr2 && i !== col_odr) {
              clm.push(columnarray2[i]);
            } else if (i === col_odr2) {
              document.getElementById("clm2").children.item(col_odr2).classList.add("selected-row")
              console.log("col_odr2");
              let cjj = columnarray2[i];
              cjj.co = (col_odr + 1).toString();
              clm.push(cjj);
            } else if (i === col_odr) {
              document.getElementById("clm2").children.item(col_odr).classList.remove("selected-row")
              console.log("col_odr");
              let cjj = columnarray2[i];
              cjj.co = (col_odr2 + 1).toString();
              clm.push(cjj);
            }
          }
          clm.sort((a, b) => (a.co > b.co ? 1 : a.co < b.co ? -1 : 0));
          ind.current = col_odr2;
          console.log(clm);
          setColumnarray2([...clm]);
        }
      } else {
        if (col_odr > 0) {
          col_odr2 = col_odr - 1;
          for (let i = 0; i < columnarray2.length; i++) {
            if (i !== col_odr2 && i !== col_odr) {
              clm.push(columnarray2[i]);
            } else if (i === col_odr2) {
              document.getElementById("clm2").children.item(col_odr2).classList.add("selected-row")
              console.log("col_odr2");
              let cjj = columnarray2[i];
              cjj.co = (col_odr + 1).toString();
              clm.push(cjj);
            } else if (i === col_odr) {
              document.getElementById("clm2").children.item(col_odr).classList.remove("selected-row")
              console.log("col_odr");
              let cjj = columnarray2[i];
              cjj.co = (col_odr2 + 1).toString();
              clm.push(cjj);
            }
          }
          clm.sort((a, b) => (a.co > b.co ? 1 : a.co < b.co ? -1 : 0));
          ind.current = col_odr2;
          console.log(clm);
          setColumnarray2([...clm]);
        }
      }
    }
  };

  // const setColumnOrder = (pm) => {
  //   console.log("tf: " + columnarray2.length);
  //   var clm2 = columnarray2[ind.current].label;
  //   console.log("vl2: " + ind.current);
  //   var col = columnarray2;
  //   var clm = [];
  //   var col_odr = 0;
  //   var col_odr2 = 0;
  //   for (var i = 0; i < col.length; i++) {
  //     if (col[i].label === clm2) {
  //       col_odr = parseInt(col[i].co);
  //     }
  //   }
  //   console.log("col_order" + col_odr);
  //   if (pm) {
  //     col_odr2 = col_odr + 1;
  //     // setCol_val(col_val+1);
  //     ind.current += 1;
  //   } else {
  //     col_odr2 = col_odr - 1;
  //     // setCol_val(col_val-1);
  //     ind.current -= 1;
  //   }
  //   var cll = {};
  //   for (var i = 0; i < col.length; i++) {
  //     if (pm) {
  //       if (i + 1 !== col_odr2 && i + 1 !== col_odr) {
  //         clm.push(col[i]);
  //       } else if (i + 1 === col_odr && i+1<=col.length) {
  //         cll = col[i];
  //       } else if (i + 1 === col_odr2 && i+1<=col.length) {
  //         col[i].co = col_odr.toString();
  //         clm.push(col[i]);
  //         cll.co = col_odr2.toString();
  //         clm.push(cll);
  //       }
  //     } else {
  //       if (i + 1 !== col_odr2 && i + 1 !== col_odr) {
  //         clm.push(col[i]);
  //       } else if (i + 1 === col_odr2) {
  //         cll = col[i];
  //       } else if (i + 1 === col_odr) {
  //         col[i].co = col_odr2.toString();
  //         clm.push(col[i]);
  //         cll.co = col_odr.toString();
  //         clm.push(cll);
  //       }
  //     }
  //   }
  //   console.log("vl1: " + ind.current);
  //   console.log(clm);
  //   setColumnarray2([...clm]);
  // };

  return (
    <div style={{ flexGrow: 1 }}>
      <div className="row">
        <div className="col-md-6">
          {columnarray.length > 0 &&
            columnarray.map((obj, obj_i) => (
              <div key={obj_i}>
                <div className="row">
                  <div className="col-md-10">{obj.label}</div>
                  <div className="col-md-2">
                    <input
                      className="chx_bx"
                      type="checkbox"
                      checked={obj.check ? true : false}
                      onChange={(e) => changeColumn(e.target.checked, obj_i)}
                    ></input>
                  </div>
                </div>
              </div>
            ))}
          {/* <div className="col-md-3"> */}
          <button
            type="button"
            className=" btnnn btn btn-primary"
            onClick={() => setColumnbtn()}
          >
            Set
          </button>
          {/* </div> */}
        </div>
        <div id="clm2" className="col-md-3">
          {/* <select
              multiple
              class="form-select mlt_select"
              aria-label="Default"
              onClick={(e) => setCol_val(e.target.value)}
            >
              {columnarray2.map((obj, index) => (
                <option key={index} value={obj.label}>
                  {obj.label}
                </option>
              ))}
            </select> */}
          {columnarray2.map((obj, index) => (
            <p
              style={{ margin: 0 }}
              key={index}
              className="columnarray2"
              onClick={() => {
                selected(index)
              }}
              value={obj.label}
            >
              {obj.label}
            </p>
          ))}
        </div>
        <div className="col-md-3 up_down_btn">
          <div
            className="btn btn-primary fa fa-toggle-up"

            onClick={(e) => setColumnOrder(false)}
          ></div>
          <div
            className="btn btn-primary fa fa-toggle-down dis-flex"

            onClick={(e) => setColumnOrder(true)}
          ></div>
          {/* <div className="col-md-3"> */}
          <button
            type="button"
            className=" btnnn btn btn-primary"
            onClick={() => submitColumnbtn()}
          >
            Submit
          </button>
          {/* </div> */}
        </div>
      </div>
      <div className="row">
        <div className="col-md-4"></div>
        {/* <div className="col-md-3">
          <button
            type="button"
            className=" btnnn btn btn-primary"
            onClick={() => setColumnbtn()}
          >
            Set
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default PreferenceComponent;
