import React, { Component } from "react";
import { AppProperties } from "../AppProperties";
import axios from "axios";

export default class ExternalApi extends Component {
  state = {
    apiName: "",
    httpName: "",
    response: null,
    error: null,
    loca: AppProperties.loca,
  };

  constructor(apiName = null, httpName = null) {
    super();
    if (apiName != null && httpName != null) {
      this.state.apiName = apiName;
      this.state.httpName = httpName;
    } else if (apiName == null && httpName != null) {
      this.state.httpName = httpName;
    } else if (apiName != null && httpName == null) {
      this.state.apiName = apiName;
    }
  }

  getApiName() {
    return this.state.apiName;
  }

  setApiName(apiName) {
    this.state.apiName = apiName;
  }

  getHttpName() {
    return this.state.httpName;
  }

  setHttpName(httpName) {
    this.state.httpName = httpName;
  }

  async call() {
    let tt = await axios
      .get(`${this.state.loca}/lom/external/api/${this.state.apiName}/${this.state.httpName}`, {
        headers: {
          authorization: "Bearer " + this.state.token,
          "Content-Type": "application/json",
        },
      })
      .then(
        (res) => {
          console.log(res.data);
          if ("Error" in res.data) {
            this.state.error = res.data.Error;
          } else {
            this.state.response = res.data;
          }
          return;
        },
        (error) => {
          console.log(error);

          this.state.error = error;
          return;
        }
      );
  }

  getResponse() {
    return this.state.response;
  }

  getError() {
    return this.state.error;
  }

  render() {
    return <div>Exrternal Api</div>;
  }
}
