import React, { useContext, useState } from "react";
import { AutoPortalContext } from "../Context";
import { Card, CardBody, CardHeader, CardFooter } from "reactstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AlertCompo from "./AlertCompo";

const UpdatePassword = () => {
  const { token, loca } = useContext(AutoPortalContext);

  const navigation = useNavigate();

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [pageError, setPageError] = useState(false);
  const [error, setError] = useState("");
  const [alertType, setAlertType] = useState("");

  const logincall = () => {
    localStorage.removeItem("autoToken");
    navigation("/login");
  };

  const updateCall = () => {
    let eMsg = [];
    console.log(oldPassword, newPassword);
    if (
      newPassword === confirmPassword &&
      confirmPassword &&
      newPassword &&
      oldPassword &&
      newPassword !== oldPassword
    ) {
    } else {
      // if (!oldPassword) {
      //   eMsg.push("old password is empty");
      // } else if (!confirmPassword && !newPassword && !oldPassword) {
      //   eMsg.push("password fields are empty");
      // } else if (newPassword === oldPassword && newPassword && oldPassword) {
      //   eMsg.push("OldPassword and NewPassword cannot be same");`
      // } else if (!newPassword) {
      //   eMsg.push("new Password is empty");
      // } else if (!confirmPassword) {
      //   eMsg.push("confirm password is empty");
      // } else {
      //   eMsg.push("NewPassword and ConfirmPassword does not match!!");
      // }
      if (!oldPassword && !newPassword && !confirmPassword) {
        eMsg.push("Password fields are empty");
      } else if (oldPassword && !newPassword && !confirmPassword) {
        eMsg.push("NewPassword and confirmPassword is empty");
      } else if (oldPassword && newPassword && !confirmPassword) {
        eMsg.push("ConfirmPassword is empty");
      } else if (oldPassword && !newPassword && confirmPassword) {
        eMsg.push("NewPassword is empty");
      } else if (!oldPassword && newPassword && confirmPassword) {
        eMsg.push("OldPassword is empty");
      } else if (!oldPassword && newPassword && !confirmPassword) {
        eMsg.push("OldPassword and confirmPassword is empty");
      } else if (!oldPassword && !newPassword && confirmPassword) {
        eMsg.push("OldPassword and newPassword is empty");
      } else if (oldPassword === newPassword) {
        eMsg.push("OldPassword and NewPassword cannot be same");
      } else if (newPassword !== confirmPassword) {
        eMsg.push("NewPassword and ConfirmPassword does not match!!");
      }
    }
    if (!eMsg.length > 0) {
      let json = {
        oldPassword: oldPassword,
        newPassword: newPassword,
      };
      console.log(JSON.stringify(json));
      console.log("t : " + token);
      axios
        .post(loca + "/lom/set/resetpassword", json, {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
          },
        })
        .then(
          (resp) => {
            let result = resp.data;
            console.log(result);
            if ("Error" in result) {
              setAlertType("danger");
              setError(result.Error);
              setPageError(true);
              console.log(result.Error);
            } else {
              console.log(result.Success);
              navigation("/login");
            }
          },
          (error) => {
            navigation("/error");
            console.log(error);
          }
        );
    } else {
      console.log(JSON.stringify(eMsg));
      setAlertType("danger");
      setError(eMsg);
      setPageError(true);
    }
  };

  return (
    <div className="pagesetup overflow-hidden">
      <div className="parent-div">
        <Card className="bg-auth-1 card-width-manual">
          <CardHeader className="border-0">
            <h4 className="bg-auth-1 text-center mb-0 mt-1">UpdatePassword</h4>
            <hr className="my-2" />

            {pageError && <AlertCompo type={alertType} msg={error} />}
          </CardHeader>

          <CardBody className="bg-auth-1 pt-0 pb-0 text-center">
            <div className="mt-2">
              <i
                className="fa fa-user"

                style={{
                  border: "2.5px solid white",
                  height: "4.5rem",
                  width: "4.5rem",
                  fontSize: "62px",
                  textAlign: "center",
                  borderRadius: "39px",
                }}
              ></i>
            </div>
            <div className="mt-4">
              <div className="pb-2">
                <input
                  type="text"
                  name="password"
                  placeholder="Oldpassword"
                  className=" inpptt "
                  onChange={(e) => {
                    setOldPassword(e.target.value);
                  }}
                ></input>
              </div>
              <div className="pb-2">
                <input
                  type="text"
                  name="password"
                  placeholder="NewPassword"
                  className=" inpptt "
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                ></input>
              </div>
              <div className="pb-2">
                <input
                  type="text"
                  name="password"
                  placeholder="ConfirmPassword"
                  className=" inpptt "
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                ></input>
              </div>
            </div>
            <div className="mt-3 mt-2 mb-3">
              <button
                className="btn btn-warning btn-width"
                onClick={() => {
                  updateCall();
                }}
              >
                Update
              </button>
            </div>
            <hr className="mt-2 mb-0" />
          </CardBody>

          <CardFooter className="border-0">
            <div className="cursor-pointer d-flex align-items-center gap-1 mb-1 mx-1">
              <i className="fa fa-arrow-left"></i>
              <span onClick={logincall}>Login</span>
            </div>
          </CardFooter>
        </Card>
      </div>
    </div>
  );
};

export default UpdatePassword;
