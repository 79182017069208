import axios from "axios";
import React, { useState, useEffect, useContext, useRef } from "react";
import { AutoPortalContext } from "../Context";
import WorkInProgress from "./WorkInProgress";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Label,
  LabelList,
  Rectangle,
  DefaultTooltipContent,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
} from "recharts";
import { useSearchParams } from "react-router-dom";


const ReportCompo = ({ report_name, dash_parentApplication }) => {
  // useContext hook
  const { token , loca } = useContext(AutoPortalContext);

  const pie_colors = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

  // useState hook
  const [searchParam, setSearchParam] = useSearchParams();
  const [initialReport, setInitialReport] = useState(true);
  const [reportdata, setReportdata] = useState();
  const [reportlist, setReportlist] = useState([]);
  const [reporttype, setReporttype] = useState("");
  const [reportname, setReportname] = useState("");
  const [x_axis, setX_axis] = useState("");
  const [y_axis, setY_axis] = useState("");
  const [timeline, setTimeLine] = useState("");
  const [altColor, setAltColor] = useState(true);
  const [loading, setLoading] = useState(false);
  const [pageError, setPageError] = useState(false);
  const [error, setError] = useState("");
  const [flag, setFlag] = useState(false);
  const isSub = useRef(false);
  const parentApp = useRef('');

  // Chart.register(...registerables);
  const getAllReport = () => {
    var url = "";
    if (parentApp.current === "Marketplace") {
      url = "http://localhost:8084/marketplace/get/report/name";
    } else {
      url = loca + "/lom/get/report/name";
    }
    axios
      .get(url, {
        headers: {
          authorization: "Bearer " + token,
          "market-application": "marketplace",
        },
      })
      .then((res) => {
        const rcd = res.data;
        console.log(rcd);
        if ("Error" in rcd) {
          setLoading(false);
          setPageError(true);
          setError(rcd.Error);
        } else {
          console.log("checkkinggggg");
          setReportlist([...rcd.reportNameList[2].records]);
          if (isSub.current) {
            callReport(report_name)
          }
          setFlag(!flag);
        }
      });
  };

  const callReport = (nam) => {
    if (nam === "Report") {
      // this.setState({ initialReport: true });
      setInitialReport(true);
    } else {
      // this.setState({ loading: true, initialReport: false });
      setLoading(true);
      setInitialReport(false);
      console.log(nam);
      var url = "";
      if (parentApp.current === "Marketplace") {
        url = "http://localhost:8084/marketplace/get/report/";
      } else {
        url = loca + "/lom/get/report/";
      }
      axios
        .get(url + nam, {

          headers: {
            authorization: "Bearer " + token,
            "market-application": "marketplace",
          },
        })
        .then((res) => {
          const report = res.data;
          console.log(report,url);
          const rdata = reportdata;
          var reportty = reporttype;
          var tml = "";
          if ("Error" in report) {
            setLoading(false);
            setPageError(true);
            setError(report.Error);
          } else {
            if ("reportRecord" in report) {
              var newReportData = [];
              for (
                var i = 0;
                i < report.reportRecord[2].record.length;
                i++
              ) {
                newReportData.push({
                  name: report.reportRecord[2].record[i].name,
                  value: parseInt(report.reportRecord[2].record[i].value),
                });
              }
              tml = report.reportRecord[3].reportInformation.timeLine;
            console.log("lineeeeeeeee ",report.reportRecord[3].reportInformation.chart);
              setReportdata(newReportData);
              setReporttype(report.reportRecord[3].reportInformation.chart);
             setX_axis(report.reportRecord[4].axis.x_axis)
             setY_axis( report.reportRecord[4].axis.y_axis);
             setTimeLine(tml)
              setReportname(nam);
              setLoading(false);
              setInitialReport(false);
              setAltColor(altColor)
            }
          }
        });
    }
  };

  useEffect(() => {
    if (report_name) {
      parentApp.current = dash_parentApplication;
      isSub.current = true;
    } else {
      parentApp.current = searchParam.get("parentApplication");
    }
    getAllReport();
  }, []);


  const CustomTooltip = ({
    active,
    payload,
    label,
    reportname,
    reportlist,
    reporttype,
  }) => {
    if (active && payload && payload.length) {
      const legendItem = reportlist.find((item) => item.name === payload[0].name);
      return (
        <div className="custom-tooltip">
          {reporttype !== "pie" && <p className="label">{`${label}`}</p>}
          {/* <p className="valuee">{`${reportname} : ${payload[0].value}`}</p> */}
          {legendItem && (
            <div className="legend-item">
              <span
                className="legend-icon"
                style={{ backgroundColor: "legendItem.color" }}
              />
              <span className="legend-label">{legendItem.name}</span>
            </div>
          )}
        </div>
      );
    }
    return null;
  };
    
  const CustomizedLabel =({ x, y, stroke, value })=>{
    return (
      <text x={x} y={y} dy={-4} fill={stroke} fontSize={11} textAnchor="middle">
        {value}
      </text>
    );
  }

  const CustomizedAxisTick =({ x, y, stroke, payload })=>{
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="end"
          fill="#666"
          transform="rotate(-35)"
        >
          {payload.value}
        </text>
      </g>
    );
  }

  const reportL = () => {
    console.log("report List " + JSON.stringify(reportlist.length));
    if (reportlist.length > 0) {
      return (
        reportlist.length &&
        reportlist.map((item, i) => (
          <option key={i} value={item.name}>
            {item.name}
          </option>
        ))
      );
    }
  };

  return (
    <div className="pagesetup">
      {!isSub.current && initialReport === true ? <div>Please select a Report</div> : null}

      {!isSub.current && <select
        className="select_opt"
        onChange={(e) => {
          callReport(e.target.value);
        }}
      >
        <option value={"Report"}>Report</option>

        {reportL()}
      </select>}
      {loading === true ? <WorkInProgress></WorkInProgress> : null}
      {loading === false && initialReport === false && (
        <span>
          <p className="text-center h1">
                  <strong>{reportname}</strong>
                </p>
             {reporttype === "line" && (
              <ResponsiveContainer
                width="90%"
                height="90%"
                className="container"
              >
                
                <LineChart
                  // width={600}
                  // height={400}
                  data={reportdata}
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                  syncId="anyId"
                >
                  <CartesianGrid
                    stroke="#ccc"
                    strokeDasharray="5 5"
                    fill="#DDDFE2"
                  />
                  <XAxis
                    dataKey="name"
                    height={60}
                    // tick={<CustomizedAxisTick />}
                  >
                    <Label
                      value={x_axis}
                      offset={50}
                      position="insideTop"
                      scale="point"
                    />
                  </XAxis>
                  <YAxis>
                    <Label
                      value={y_axis}
                      angle={-90}
                      position="insideLeft"
                    />
                  </YAxis>
                  {/* <Legend
                    verticalAlign="top"
                    // iconType="square"
                    height={36}
                    wrapperStyle={{ top: 5 }}
                    payload={reportlist.map((item, index) => ({
                      id: item.name,
                      type: "rect",
                      value: `${item.name}`,
                      // color: colors[index % colors.length]
                      color: "#8884d8",
                    }))}
                  /> */}
                  <Tooltip
                    // wrapperStyle={{ width: 200, backgroundColor: "red" }}
                    content={
                      <CustomTooltip
                        reportname={reportname}
                        reportlist={reportlist}
                      />
                    }
                  />
        
                  <Line
                    type="monotone"
                    dataKey="value"
                    stroke="#8884d8"
                    strokeWidth={2}
                    label={<CustomizedLabel />}
                    activeDot={{ r: 8 }}
                    animationBegin={0}
                    animationDuration={1500}
                    animationEasing="ease-in-out"
                  />
                </LineChart>
           
              </ResponsiveContainer>
            )}
               {reporttype === "bar" && (
              <ResponsiveContainer
                width="95%"
                height="90%"
                className="container mrgrepo"
              >
                {console.log(reportdata,reportlist)}
                <BarChart
                  width={150}
                  height={40}
                  data={reportdata}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                  barSize={150}
                >
                  <CartesianGrid
                    strokeDasharray="4 4"
                    fill="#f0f0f0"
                    fillOpacity={0.7}
                  />
                  <XAxis
                    dataKey="name"
                    height={60}
                    // tick={<CustomizedAxisTick />}
                  >
                    <Label
                      value={x_axis}
                      offset={50}
                      position="insideTop"
                      scale="point"
                    />
                  </XAxis>
                  <YAxis>
                    <Label
                      value={y_axis}
                      angle={-90}
                      position="insideLeft"
                    />
                  </YAxis>
                  {/* <Legend
                    verticalAlign="top"
                    iconType="square"
                    wrapperStyle={{ top: 5 }}
                    payload={reportlist.map((item, index) => ({
                      id: item.name,
                      type: "rect",
                      value: `${item.name}`,
                      // color: colors[index % colors.length]
                      color: "#8884d8",
                    }))}
                  /> */}
                  <Tooltip
                    // wrapperStyle={{ width: 100, backgroundColor: "#ccc" }}
                    content={
                      <CustomTooltip
                        reportname={reportname}
                        reportlist={reportlist}
                      />
                    }
                  />
                  <Bar
                    dataKey="value"
                    
                    fill="#8884d8"
                    label={{ position: "top" }}
                    activeBar={<Rectangle fill="gold" stroke="blue" />}
                    isAnimationActive={true}
                    animationDuration={600}
                    animationEasing="ease-in-out"
                  >
                    {reportdata.map((entry, index) => (
                      <Cell key={`cell-${index}`} />
                    ))}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            )}
               {reporttype === "pie" && (
              <ResponsiveContainer
                width="50%"
                height="50%"
                className="container"
              >
                <PieChart width={600} height={600} className="mrgrepopie">
                  {/* <Legend verticalAlign="top" /> */}
                  <Pie
                    data={reportdata}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    innerRadius={5}
                    // label={this.renderCustomizedLabel}
                    label
                    fill="#8884d8"
                  >
                    {reportdata.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={pie_colors[index % pie_colors.length]}
                      />
                    ))}
                  </Pie>
                  <Tooltip
                    // wrapperStyle={{ width: 100, backgroundColor: "#ccc" }}
                    // content={
                    //   <CustomTooltip
                    //     reportname={reportname}
                    //     reportlist={reportlist}
                    //     reporttype={reporttype}
                    //   />
                    // }
                    payload={reportlist.map((entry, index) => ({
                      color: pie_colors[index % pie_colors.length],
                    }))}
                  />
                </PieChart>
              </ResponsiveContainer>
            )}

            {reporttype === "horizontalBar" && (
              <ResponsiveContainer
                width="85%"
                height="85%"
                className="container mrgrepo"
              >
                <BarChart
                  layout="vertical"
                  width={150}
                  height={40}
                  data={reportdata}
                // barSize={300}
                >
                  {/* <CartesianGrid strokeDasharray="3 3" /> */}

                  <XAxis type="number" height={60}>
                    <Label
                      value={y_axis}
                      offset={39}
                      position="insideTop"
                    />
                  </XAxis>
                  <YAxis dataKey="name" type="category" >
                    <Label
                      value={x_axis}
                      angle={-90}
                      position="insideLeft"
                    />
                  </YAxis>
                  <Tooltip
                    content={
                      <CustomTooltip
                        reportname={reportname}
                        reportlist={reportlist}
                      />
                    }
                  />
                  <Legend
                    verticalAlign="top"
                    wrapperStyle={{ top: 5 }}
                    payload={reportlist.map((item, index) => ({
                      id: item.name,
                      type: "rect",
                      value: `${item.name}`,
                      // color: bar_colors[index % bar_colors.length]
                      color: "#8884d8",
                    }))}
                  />
                  {/* <Area dataKey="amt" fill="#8884d8" stroke="#8884d8" /> */}
                  <Bar
                    dataKey="value"
                    activeBar={<Rectangle fill="gold" stroke="blue" />}
                    // barSize={20}
                    fill="#8884d8"
                    isAnimationActive={true}
                    animationDuration={600}
                    animationEasing="ease-in-out"
                  >
                    {reportdata.map((entry, index) => (
                      <Cell key={`cell-${index}`}
                      // fill={pie_colors[index % pie_colors.length]}
                      />
                    ))}
                    <LabelList position="right" />
                  </Bar>
                  {/* <Line dataKey="uv" stroke="#ff7300" /> */}
                </BarChart>
              </ResponsiveContainer>
            )}          
        </span>
      )}
    </div>
  );
};

export default ReportCompo;
