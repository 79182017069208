import React, { useContext, useEffect, useState } from "react";
import { AutoPortalContext } from "../Context";
import NewFilterCompo from "./NewFilterCompo";
import "../css/FormCSS.css";
import { UncontrolledTooltip } from "reactstrap";
import InvoiceCompo from "./InvoiceCompo";
import MultipleSelectComp from "./MultipleSelectComp";
import { Editor } from "@monaco-editor/react";

const FormInnerCompo = ({
  obj,
  rid,
  m_rid,
  setContextMenu,
  formChangefn,
  record,
  setRecord,
  validationfn,
  passwordReadOnly,
  tableName,
  selection,
  setShowSuggestion,
  getCaretPos,
  setY,
  setX,
  recordSuggestion,
  userRecordSuggestion,
  setSearchrcd,
  searchModule,
  afterCellEdit,
  x,
  y,
  showSuggestion,
  searchrcd,
  ListGroup,
  listRef,
  setCon,
  con,
  onSelection,
  clickRefrence,
  getSingleInfo,
  refrecord,
  showContainer,
  setRefRcd,
  showlist,
  col_mn,
  callfilter,
  setFiltArray,
  timeline,
  calltimeline,
  callScript,
  reScript,
  JoditEditor,
  handleFileChange,
  deleteImage,
  launchChoose,
  editor,
  choice_mn,
  choice_ref_mn,
  mscList,
  setMSC,
  index,
  verifyError,
  setref_filter,
  ref_filter,
  col_mn_ref,
  col_depend,
  dcFiltVal,
  keyValueJson,
  setKeyValueJson,
  groupkeyValue,
  setGroupKeyValue,
  // dynamicKeyValue,
  // setDynamicKeyValue,
  multiSv,
  add,
  deftimeline,
  isSub,
  form_id,
}) => {
  // const [group_key_value, setGroupKeyValue] = useState(setGroupKeyValue);
  const [editorHeight, setEditorHeight] = useState("15vh");

  const { imageloca } = useContext(AutoPortalContext);

  const addInput = (obj) => {
    console.log(obj, record[index], index);
    let t = {};
    let properties = JSON.parse(JSON.stringify(obj.value));
    properties.push(t);
    obj.value.push(t);
    setRecord([...record]);
  };

  const removeInput = (index, obj) => {
    console.log(obj.value);
    let array = JSON.parse(JSON.stringify(obj.value));
    if (index !== -1) {
      array.splice(index, 1);
    }
    obj.value = array;
    setRecord([...record]);
  };

  const handleAddParentInput = () => {
    let t = {
      name: "",
      choice: [{ value: "" }],
    };
    let properties = groupkeyValue.properties;
    console.log(properties);
    properties.push(t);
    setGroupKeyValue({ properties });
    console.log(groupkeyValue);
  };

  const handleAddChildInput = (parentIndex) => {
    console.log(groupkeyValue);
    let t = { value: "" };
    let childProps = groupkeyValue.properties[parentIndex].choice;
    console.log(childProps);
    childProps.push(t);
    console.log(childProps);
    groupkeyValue.properties[parentIndex].choice = childProps;
    setGroupKeyValue({ properties: groupkeyValue.properties });
  };

  const handleRemoveParentInput = (parentIndex) => {
    let array = groupkeyValue.properties;
    if (parentIndex !== -1) {
      array.splice(parentIndex, 1);
    }
    let prp = {};
    prp.properties = array;
    console.log(prp, array, groupkeyValue);
    setGroupKeyValue(prp);
  };

  const handleRemoveChildInput = (parentIndex, childindex) => {
    let array = groupkeyValue.properties[parentIndex].choice;
    if (childindex !== -1) {
      array.splice(childindex, 1);
    }
    console.log(array);
    groupkeyValue.properties[parentIndex].choice = array;
    setGroupKeyValue({ properties: groupkeyValue.properties });
  };

  useEffect(() => {
    if (obj.type === "image_upload") {
      console.log("abd149", obj, obj.type, obj.localimg);

      obj.localimg = false;
    }
    if (obj.type === "group_key_value") {
      console.log(obj.value);
      setGroupKeyValue(obj.value);
    }
    // if (obj.type === "dynamic_key_value") {
    //   setDynamicKeyValue(obj.value);
    // }
  }, [obj]);

  const handleExpandClick = () => {
    // Toggle between expanded and normal height
    const newHeight = editorHeight === "15vh" ? "100vh" : "15vh";
    setEditorHeight(newHeight);
  };

  return (
    <div>
      {obj.uivalid.visible === "true" && (
        <div className="  inppd " key={obj.name}>
          {obj.type === "String" ? (
            <div
              className=" form-group objpdg "
              style={{ width: obj.width === "50%" ? "50%" : "100%" }}
            >
              {obj.uivalid.mandatory === "true" && obj.value.trim() !== "" && (
                <i className="fa fa-asterisk mndtryfalse"></i>
              )}
              {obj.uivalid.mandatory === "true" && obj.value.trim() === "" && (
                <i className="fa fa-asterisk mndtrytrue"></i>
              )}
              <span
                id={"tiptar" + obj.index}
                className="field_hd"
                onTouchStart={(e) => {
                  setTimeout(() => {
                    e.preventDefault();
                    m_rid.current = obj.id;
                    setContextMenu(2, e.touches[0].pageX, e.touches[0].pageY);
                  }, 100);
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  if (isSub.current) {
                    let parent = document
                      .getElementById(form_id)
                      .getBoundingClientRect();
                    setContextMenu(
                      e.button,
                      e.nativeEvent.clientX - parent.left,
                      e.nativeEvent.clientY - parent.top
                    );
                  } else {
                    setContextMenu(
                      e.button,
                      e.nativeEvent.pageX,
                      e.nativeEvent.pageY
                    );
                  }
                }}
              // data-bs-custom-class="tooltip"
              // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
              >
                {obj.label.name}
              </span>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "small",
                      textAlign: "start",
                      margin: "0rem",
                    }}
                  >
                    {obj.label.tooltip}
                  </pre>
                </UncontrolledTooltip>
              )}
              {obj.label.showHelper === "true" && (
                <div className="alert alert-warning">
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: 14,
                      fontWeight: "bold",
                      margin: "0rem",
                      color: "black",
                    }}
                  >
                    {obj.label.helper}
                  </pre>
                </div>
              )}
              {obj.verified === "unverified" && (
                <div
                  className="alert alert-danger"
                  role="alert"
                  style={{
                    padding: "0.2rem 0.2rem",
                    marginBottom: "0px",
                  }}
                >
                  {verifyError}
                </div>
              )}
              <input
                type="text"
                className={
                  obj.verified === "unverified"
                    ? "form-control formpadd_danger unverifi"
                    : "form-control formpadd "
                }
                value={obj.value}
                disabled={obj.uivalid.read_only === "true"}
                maxLength={obj.uivalid.max_length}
                onChange={(e) =>
                  formChangefn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id,
                    false,
                    obj
                  )
                }
                onBlur={(e) =>
                  validationfn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id
                  )
                }
              ></input>
            </div>
          ) : null}
          {obj.type === "email" ? (
            <div
              className=" form-group objpdg "
              style={{ width: obj.width === "50%" ? "50%" : "100%" }}
            >
              {obj.uivalid.mandatory === "true" && obj.value !== "" && (
                <i className="fa fa-asterisk mndtryfalse"></i>
              )}
              {obj.uivalid.mandatory === "true" && obj.value === "" && (
                <i className="fa fa-asterisk mndtrytrue"></i>
              )}
              <span
                id={"tiptar" + obj.index}
                className="field_hd"
                onTouchStart={(e) => {
                  setTimeout(() => {
                    e.preventDefault();
                    m_rid.current = obj.id;
                    setContextMenu(2, e.touches[0].pageX, e.touches[0].pageY);
                  }, 100);
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  if (isSub.current) {
                    let parent = document
                      .getElementById(form_id)
                      .getBoundingClientRect();
                    setContextMenu(
                      e.button,
                      e.nativeEvent.clientX - parent.left,
                      e.nativeEvent.clientY - parent.top
                    );
                  } else {
                    setContextMenu(
                      e.button,
                      e.nativeEvent.pageX,
                      e.nativeEvent.pageY
                    );
                  }
                }}
              // data-bs-custom-class="tooltip"
              // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
              >
                {console.log(obj)}
                {obj.label.name}
              </span>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "small",
                      textAlign: "start",
                    }}
                  >
                    {obj.label.tooltip}
                  </pre>
                </UncontrolledTooltip>
              )}
              {obj.label.showHelper === "true" && (
                <div className="alert alert-warning">
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {obj.label.helper}
                  </pre>
                </div>
              )}
              {obj.verified === "unverified" && (
                <div
                  className="alert alert-danger"
                  role="alert"
                  style={{
                    padding: "0.2rem 0.2rem",
                    marginBottom: "0px",
                  }}
                >
                  Please verify your email!
                </div>
              )}
              <input
                type="text"
                className={
                  obj.verified === "unverified"
                    ? "form-control formpadd_danger unverifi"
                    : "form-control formpadd "
                }
                value={obj.value}
                disabled={obj.uivalid.read_only === "true"}
                maxLength={obj.uivalid.max_length}
                onChange={(e) =>
                  formChangefn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id,
                    false
                  )
                }
                onBlur={(e) =>
                  validationfn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id
                  )
                }
              ></input>
            </div>
          ) : null}
          {obj.type === "JSONObject" ? (
            <div className=" form-group objpdg ">
              {console.log(obj.value)}
              {obj.uivalid.mandatory === "true" && obj.value !== "" && (
                <i className="fa fa-asterisk mndtryfalse"></i>
              )}
              {obj.uivalid.mandatory === "true" && obj.value === "" && (
                <i className="fa fa-asterisk mndtrytrue"></i>
              )}
              <span
                id={"tiptar" + obj.index}
                className="field_hd"
                onTouchStart={(e) => {
                  setTimeout(() => {
                    e.preventDefault();
                    m_rid.current = obj.id;
                    setContextMenu(2, e.touches[0].pageX, e.touches[0].pageY);
                  }, 100);
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  if (isSub.current) {
                    let parent = document
                      .getElementById(form_id)
                      .getBoundingClientRect();
                    setContextMenu(
                      e.button,
                      e.nativeEvent.clientX - parent.left,
                      e.nativeEvent.clientY - parent.top
                    );
                  } else {
                    setContextMenu(
                      e.button,
                      e.nativeEvent.pageX,
                      e.nativeEvent.pageY
                    );
                  }
                }}
              // data-bs-custom-class="tooltip"
              // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
              >
                {obj.label.name}
              </span>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "small",
                      textAlign: "start",
                    }}
                  >
                    {obj.label.tooltip}
                  </pre>
                </UncontrolledTooltip>
              )}
              {obj.label.showHelper === "true" && (
                <div className="alert alert-warning">
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {obj.label.helper}
                  </pre>
                </div>
              )}
              {obj.verified === "unverified" && (
                <div
                  className="alert alert-danger"
                  role="alert"
                  style={{
                    padding: "0.2rem 0.2rem",
                    marginBottom: "0px",
                  }}
                >
                  {verifyError}
                </div>
              )}

              <textarea
                type="text"
                className={
                  obj.verified === "unverified"
                    ? "form-control formpadd_danger unverifi"
                    : "form-control formpadd "
                }
                value={JSON.stringify(obj.value)}
                readOnly={obj.uivalid.read_only === "true"}
                disabled={true}
                maxLength={obj.uivalid.max_length}
                onChange={(e) =>
                  formChangefn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id,
                    false
                  )
                }
                onBlur={(e) =>
                  validationfn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id
                  )
                }
              ></textarea>
            </div>
          ) : null}
          {obj.type === "password" ? (
            <div className=" form-group objpdg ">
              {obj.uivalid.mandatory === "true" && obj.value !== "" && (
                <i className="fa fa-asterisk mndtryfalse"></i>
              )}

              {obj.uivalid.mandatory === "true" && obj.value === "" && (
                <i className="fa fa-asterisk mndtrytrue"></i>
              )}
              <span
                id={"tiptar" + obj.index}
                className="field_hd"
                onTouchStart={(e) => {
                  setTimeout(() => {
                    e.preventDefault();
                    m_rid.current = obj.id;
                    setContextMenu(2, e.touches[0].pageX, e.touches[0].pageY);
                  }, 100);
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  if (isSub.current) {
                    let parent = document
                      .getElementById(form_id)
                      .getBoundingClientRect();
                    setContextMenu(
                      e.button,
                      e.nativeEvent.clientX - parent.left,
                      e.nativeEvent.clientY - parent.top
                    );
                  } else {
                    setContextMenu(
                      e.button,
                      e.nativeEvent.pageX,
                      e.nativeEvent.pageY
                    );
                  }
                }}
              // data-bs-custom-class="tooltip"
              // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
              >
                {console.log(obj)}
                {obj.label.name}
              </span>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "small",
                      textAlign: "start",
                    }}
                  >
                    {obj.label.tooltip}
                  </pre>
                </UncontrolledTooltip>
              )}
              {obj.label.showHelper === "true" && (
                <div className="alert alert-warning">
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {obj.label.helper}
                  </pre>
                </div>
              )}

              {obj.verified === "unverified" && (
                <div
                  className="alert alert-danger"
                  role="alert"
                  style={{
                    padding: "0.2rem 0.2rem",
                    marginBottom: "0px",
                  }}
                >
                  Please verify your charecter not accept number!
                </div>
              )}
              {/* <div className="row justify-content-center"> */}
              <input
                type="password"
                className={
                  obj.verified === "unverified"
                    ? "form-control formpadd_danger unverifi"
                    : "form-control formpadd  "
                }
                value={obj.value}
                disabled={
                  passwordReadOnly ? true : obj.uivalid.read_only === "true"
                }
                maxLength={obj.uivalid.max_length}
                onChange={(e) =>
                  formChangefn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id,
                    false
                  )
                }
                onBlur={(e) =>
                  validationfn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id
                  )
                }
              ></input>
            </div>
          ) : null}
          {
            // obj.type === "script" ||
            obj.type === "long_description" ||
              obj.type.toLowerCase() === "note" ? (
              <div className=" form-group objpdg ">
                {obj.uivalid.mandatory === "true" && obj.value !== "" && (
                  <i className="fa fa-asterisk mndtryfalse"></i>
                )}

                {obj.uivalid.mandatory === "true" && obj.value === "" && (
                  <i className="fa fa-asterisk mndtrytrue"></i>
                )}
                <span
                  id={"tiptar" + obj.index}
                  className="field_hd"
                  onTouchStart={(e) => {
                    setTimeout(() => {
                      e.preventDefault();
                      m_rid.current = obj.id;
                      setContextMenu(2, e.touches[0].pageX, e.touches[0].pageY);
                    }, 100);
                  }}
                  onContextMenu={(e) => {
                    e.preventDefault();
                    m_rid.current = obj.id;
                    if (isSub.current) {
                      let parent = document
                        .getElementById(form_id)
                        .getBoundingClientRect();
                      setContextMenu(
                        e.button,
                        e.nativeEvent.clientX - parent.left,
                        e.nativeEvent.clientY - parent.top
                      );
                    } else {
                      setContextMenu(
                        e.button,
                        e.nativeEvent.pageX,
                        e.nativeEvent.pageY
                      );
                    }
                  }}
                // data-bs-custom-class="tooltip"
                // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
                >
                  {console.log(obj)}
                  {obj.label.name}
                </span>
                {obj.label.showTooltip === "true" && (
                  <UncontrolledTooltip
                    arrowClassName="tip  "
                    className="tip "
                    innerClassName="tip text-dark text-nowrap"
                    target={"tiptar" + obj.index}
                    placement="right"
                  >
                    <pre
                      style={{
                        fontFamily: "sans-serif",
                        fontSize: "small",
                        textAlign: "start",
                      }}
                    >
                      {obj.label.tooltip}
                    </pre>
                  </UncontrolledTooltip>
                )}
                {obj.label.showHelper === "true" && (
                  <div className="alert alert-warning">
                    <pre
                      style={{
                        fontFamily: "sans-serif",
                        fontSize: 14,
                        fontWeight: "bold",
                        color: "black",
                      }}
                    >
                      {obj.label.helper}
                    </pre>
                  </div>
                )}

                {obj.verified === "unverified" && (
                  <div
                    className="alert alert-danger"
                    role="alert"
                    style={{
                      padding: "0.2rem 0.2rem",
                      marginBottom: "0px",
                    }}
                  >
                    Please verify your charecter not accept number!
                  </div>
                )}
                <div>
                  {/* {tableName === "ui_script" && (
                  <input
                    type="text"
                    className={
                      obj.verified === "unverified"
                        ? "form-control formpadd_danger unverifi"
                        : "form-control formpadd "
                    }
                    value={selection}
                    style={{
                      borderBottomRightRadius: 0,
                      borderBottomLeftRadius: 0,
                      backgroundColor: "white",
                      borderBottomWidth: 0,
                    }}
                    disabled={true}
                  ></input>
                )} */}

                  <div style={{ border: "2px solid black" }}>
                    <textarea
                      id={"script" + obj.index}
                      type="text"
                      className={
                        obj.verified === "unverified"
                          ? "form-control formpadd_danger unverifi"
                          : "form-control formpadd "
                      }
                      spellCheck={false}
                      style={{
                        borderRadius: 0,
                        borderTopWidth: 0,
                        borderBottomWidth: 0,
                      }}
                      value={obj.value}
                      disabled={obj.uivalid.read_only === "true"}
                      maxLength={obj.uivalid.max_length}
                      onChange={(e) => {
                        if (e.currentTarget.textLength <= 1) {
                          setShowSuggestion(false);
                        } else {
                          setShowSuggestion(true);
                        }
                        let offsetX = getCaretPos(e, obj.index)[0];
                        let yy = document.getElementById(
                          "script" + obj.index
                        ).offsetTop;
                        setY(yy);
                        setX(offsetX);

                        let sp = e.currentTarget.value.split(" ");
                        // let count = sp.length - 1;

                        // let temp = [...recordSuggestion];
                        // let temp2 = [...userRecordSuggestion];
                        // if (
                        //   sp[count].toString() === "ap_form." ||
                        //   sp[count].toString() === "ap_user."
                        // ) {
                        //   if (sp[count].toString() === "ap_user.") {
                        //     console.log("inside ap_form");
                        //     console.log(JSON.stringify(temp2[2].show));
                        //     for (let i = 0; i < temp2.length; i++) {
                        //       if (i === 0) {
                        //         temp2[i].show = false;
                        //       } else if (i !== 0) {
                        //         temp2[i].show = true;
                        //       }
                        //     }
                        //     setSearchrcd([...temp2]);
                        //   } else {
                        //     if (sp[count].toString() === "ap_form.") {
                        //       console.log("inside ap_form");
                        //       for (let i = 0; i < temp.length; i++) {
                        //         if (i === 0 || i === 1) {
                        //           temp[i].show = false;
                        //         } else if (i !== 0) {
                        //           temp[i].show = true;
                        //         }
                        //       }
                        //       setSearchrcd([...temp]);
                        //     } else {
                        //     }
                        //   }
                        // } else {
                        //   console.log("else");
                        //   let sp2 = sp[count].split(".");
                        //   console.log(JSON.stringify(sp2));

                        //   let spl = sp2.length - 1;

                        //   console.log("sp2_length  : " + spl);
                        //   if (spl > 0) {
                        //     console.log("ap_form222");

                        //     if (sp2[0] === "ap_form") {
                        //       searchModule(sp2[spl], false, "form");
                        //     } else if (sp2[0] === "ap_user") {
                        //       searchModule(sp2[spl], false, "user");
                        //     }
                        //   } else {
                        //     console.log("ap_formllll" + sp[count].length);
                        //     if (sp[count].length > 2) {
                        //       searchModule(sp[count], true, "form");
                        //     }
                        //   }
                        // }
                        formChangefn(
                          e.target.value,
                          obj.index,
                          obj.name,
                          obj.type,
                          obj.id,
                          false
                        );
                      }}
                      onClick={() => {
                        document.getElementById("script" + obj.index).focus();
                      }}
                      onKeyDown={(e) => {
                        if (showSuggestion && e.key === "ArrowDown") {
                          e.preventDefault();
                        }
                        let searchInput = document.getElementById("script");
                        let resultList = document.getElementsByClassName("lg");
                        if (document.activeElement === searchInput) {
                          if (e.key === "ArrowDown") {
                            resultList.item(0).focus();
                          }
                        }
                      }}
                      onBlur={(e) => {
                        afterCellEdit(e.target.value, obj.name);
                      }}
                    ></textarea>
                  </div>
                  <div
                    id="suggetion"
                    style={{
                      position: "absolute",
                      left: x + 22,
                      top: y + 25,
                    }}
                    onFocus={() => {
                      let d = document.getElementById("suggetion");
                      d.style.background = "blue";
                      d.style.color = "white";
                    }}
                  ></div>
                  {/* {tableName === "ui_script" && (
                  <input
                    type="text"
                    className={[
                      obj.verified === "unverified"
                        ? "form-control formpadd_danger unverifi"
                        : "form-control formpadd ",
                    ]}
                    value={selection !== "" ? "}" : ""}
                    style={{
                      borderTopRightRadius: 0,
                      borderTopLeftRadius: 0,
                      backgroundColor: "white",
                      borderTopWidth: 0,
                    }}
                    disabled={true}
                  ></input>
                )} */}
                </div>
              </div>
            ) : null
          }

          {obj.type === "script" ? (
            <div className=" form-group objpdg ">
              {obj.uivalid.mandatory === "true" && obj.value !== "" && (
                <i className="fa fa-asterisk mndtryfalse"></i>
              )}

              {obj.uivalid.mandatory === "true" && obj.value === "" && (
                <i className="fa fa-asterisk mndtrytrue"></i>
              )}
              <span
                id={"tiptar" + obj.index}
                className="field_hd"
                onTouchStart={(e) => {
                  setTimeout(() => {
                    e.preventDefault();
                    m_rid.current = obj.id;
                    setContextMenu(2, e.touches[0].pageX, e.touches[0].pageY);
                  }, 100);
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  if (isSub.current) {
                    let parent = document
                      .getElementById(form_id)
                      .getBoundingClientRect();
                    setContextMenu(
                      e.button,
                      e.nativeEvent.clientX - parent.left,
                      e.nativeEvent.clientY - parent.top
                    );
                  } else {
                    setContextMenu(
                      e.button,
                      e.nativeEvent.pageX,
                      e.nativeEvent.pageY
                    );
                  }
                }}
              >
                {console.log(obj)}
                {obj.label.name}
              </span>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "small",
                      textAlign: "start",
                    }}
                  >
                    {obj.label.tooltip}
                  </pre>
                </UncontrolledTooltip>
              )}
              {obj.label.showHelper === "true" && (
                <div className="alert alert-warning">
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {obj.label.helper}
                  </pre>
                </div>
              )}

              {obj.verified === "unverified" && (
                <div
                  className="alert alert-danger"
                  role="alert"
                  style={{
                    padding: "0.2rem 0.2rem",
                    marginBottom: "0px",
                  }}
                >
                  Please verify your charecter not accept number!
                </div>
              )}
              <div>
                <div>
                  <i
                    className="fa-solid fa-expand"
                    onClick={handleExpandClick}
                    style={{ cursor: "pointer" }}
                  ></i>
                </div>

                {editorHeight === "15vh" ? (
                  <div style={{ border: "2px solid black", height: "15vh" }}>
                    <Editor
                      // width="99vw"
                      // height='100vh'
                      language="javascript"
                      theme="vs"
                      value={obj.value}
                      onChange={(e, i) => {
                        formChangefn(
                          e,
                          obj.index,
                          obj.name,
                          obj.type,
                          obj.id,
                          false
                        );
                      }}
                      options={{
                        codeLens: false,
                        inlineSuggest: true,
                        fontSize: "16px",
                        formatOnType: true,
                        minimap: {
                          enabled: false,
                          showSlider: "always",
                        },
                      }}
                      onBlur={(e) => {
                        afterCellEdit(e.target.value, obj.name);
                      }}
                    />
                  </div>
                ) : (
                  <div
                    style={{
                      border: "2px solid black",
                      background: "white",
                      height: "100vh",
                      width: "99.1vw",
                      position: "absolute",
                      top: "0",
                      left: "0",
                      zIndex: 1050,
                    }}
                  >
                    <div style={{ marginLeft: "15px", marginTop: "10px" }}>
                      <i
                        className="fa-solid fa-compress"
                        style={{ cursor: "pointer" }}
                        onClick={handleExpandClick}
                      ></i>
                    </div>
                    <Editor
                      language="javascript"
                      theme="vs"
                      value={obj.value}
                      onChange={(e, i) => {
                        formChangefn(
                          e,
                          obj.index,
                          obj.name,
                          obj.type,
                          obj.id,
                          false
                        );
                      }}
                      options={{
                        codeLens: false,
                        inlineSuggest: true,
                        fontSize: "16px",
                        formatOnType: true,
                        minimap: {
                          enabled: false,
                          showSlider: "always",
                        },
                      }}
                      onBlur={(e) => {
                        afterCellEdit(e.target.value, obj.name);
                      }}
                    />
                  </div>
                )}
                <div
                  id="suggetion"
                  style={{
                    position: "absolute",
                    left: x + 22,
                    top: y + 25,
                  }}
                  onFocus={() => {
                    let d = document.getElementById("suggetion");
                    d.style.background = "blue";
                    d.style.color = "white";
                  }}
                ></div>
              </div>
            </div>
          ) : null}

          {obj.type === "choice" && (
            <div
              className="form-group "
              style={{ width: obj.width === "50%" ? "50%" : "100%" }}
            >
              {/* {console.log("checking")} */}
              {obj.uivalid.mandatory === "true" &&
                obj.value !== "None" &&
                obj.value !== "" && (
                  <i className="fa fa-asterisk mndtryfalse"></i>
                )}

              {obj.uivalid.mandatory === "true" &&
                (obj.value === "None" || obj.value === "") && (
                  <i className="fa fa-asterisk mndtrytrue"></i>
                )}
              <span
                id={"tiptar" + obj.index}
                className="field_hd"
                onTouchStart={(e) => {
                  setTimeout(() => {
                    e.preventDefault();
                    m_rid.current = obj.id;
                    setContextMenu(2, e.touches[0].pageX, e.touches[0].pageY);
                  }, 100);
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  if (isSub.current) {
                    let parent = document
                      .getElementById(form_id)
                      .getBoundingClientRect();
                    setContextMenu(
                      e.button,
                      e.nativeEvent.clientX - parent.left,
                      e.nativeEvent.clientY - parent.top
                    );
                  } else {
                    setContextMenu(
                      e.button,
                      e.nativeEvent.pageX,
                      e.nativeEvent.pageY
                    );
                  }
                }}
              >
                {obj.label.name}
              </span>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "small",
                      textAlign: "start",
                    }}
                  >
                    {obj.label.tooltip}
                  </pre>
                </UncontrolledTooltip>
              )}

              <select
                className="form-control form-select formpadd "
                aria-label="Default select example"
                value={obj.value}
                readOnly={obj.uivalid.read_only === "true"}
                onChange={(e) => {
                  onSelection(e.target.value);
                  formChangefn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id,
                    false,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    undefined,
                    obj.uivalid.read_only
                  );
                }}
                maxLength={obj.uivalid.max_length}
              // disabled={obj.uivalid.read_only === "true"}
              >
                {/* <option value="">None</option> */}

                {obj.choice?.map((ch, chi) => (
                  <option key={chi} value={ch.value}>
                    {ch.label}
                  </option>
                ))}
              </select>
            </div>
          )}
          {obj.type === "table_insert" ? (
            <div className="form-group ">
              <InvoiceCompo
                value={obj.value}
                setMSC={(val) => {
                  console.log(val);
                  setMSC(val, obj.index, obj.name, obj.type, obj.id);
                }}
              />
            </div>
          ) : null}
          {obj.type === "multi_select" ? (
            <div className="form-group ">
              {console.log(obj)}
              {obj.uivalid.mandatory === "true" &&
                obj.value.record.length !== 0 && (
                  <i className="fa fa-asterisk mndtryfalse"></i>
                )}
              {obj.uivalid.mandatory === "true" &&
                obj.uivalid.group_mandatory === "false" &&
                obj.value.record.length === 0 && (
                  <i className="fa fa-asterisk mndtrytrue"></i>
                )}

              {obj.uivalid.mandatory === "true" &&
                obj.uivalid.group_mandatory === "true" &&
                obj.value.record.length === 0 && (
                  <i
                    // className="fa fa-asterisk mndtrygptrue"
                    className="fa fa-asterisk mndtrytrue"
                  ></i>
                )}
              <span
                id={"tiptar" + obj.index}
                className="field_hd"
                onTouchStart={(e) => {
                  setTimeout(() => {
                    e.preventDefault();
                    m_rid.current = obj.id;
                    setContextMenu(2, e.touches[0].pageX, e.touches[0].pageY);
                  }, 100);
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  if (isSub.current) {
                    let parent = document
                      .getElementById(form_id)
                      .getBoundingClientRect();
                    setContextMenu(
                      e.button,
                      e.nativeEvent.clientX - parent.left,
                      e.nativeEvent.clientY - parent.top
                    );
                  } else {
                    setContextMenu(
                      e.button,
                      e.nativeEvent.pageX,
                      e.nativeEvent.pageY
                    );
                  }
                }}
              // data-bs-custom-class="tooltip"
              // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
              >
                {console.log(obj)}
                {obj.label.name}
              </span>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "small",
                      textAlign: "start",
                    }}
                  >
                    {obj.label.tooltip}
                  </pre>
                </UncontrolledTooltip>
              )}
              {obj.label.showHelper === "true" && (
                <div className="alert alert-warning">
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {obj.label.helper}
                  </pre>
                </div>
              )}
              {console.log(obj.type, obj, obj.value)}
              <div>
                {console.log(obj)}
                <MultipleSelectComp
                  record={record}
                  list={{ ...obj.value }}
                  ind={obj.index}
                  id={obj.id}
                  type={obj.type}
                  tabname={tableName}
                  dep_choice={obj.dep_choice}
                  setMSC={(val) => {
                    console.log(val);
                    setMSC(val, obj.index, obj.name, obj.type, obj.id);
                  }}
                />
              </div>
            </div>
          ) : null}
          {obj.type === "int" || obj.type === "long" ? (
            <div
              className="form-group"
              style={{ width: obj.width === "50%" ? "50%" : "100%" }}
            >
              {obj.uivalid.mandatory === "true" && obj.value !== "" && (
                <i className="fa fa-asterisk mndtryfalse"></i>
              )}

              {obj.uivalid.mandatory === "true" && obj.value === "" && (
                <i className="fa fa-asterisk mndtrytrue"></i>
              )}
              <span
                id={"tiptar" + obj.index}
                className="field_hd"
                onTouchStart={(e) => {
                  setTimeout(() => {
                    e.preventDefault();
                    m_rid.current = obj.id;
                    setContextMenu(2, e.touches[0].pageX, e.touches[0].pageY);
                  }, 100);
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  if (isSub.current) {
                    let parent = document
                      .getElementById(form_id)
                      .getBoundingClientRect();
                    setContextMenu(
                      e.button,
                      e.nativeEvent.clientX - parent.left,
                      e.nativeEvent.clientY - parent.top
                    );
                  } else {
                    setContextMenu(
                      e.button,
                      e.nativeEvent.pageX,
                      e.nativeEvent.pageY
                    );
                  }
                }}
              // data-bs-custom-class="tooltip"
              // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
              >
                {obj.label.name}
              </span>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "small",
                      textAlign: "start",
                      margin: "0rem",
                    }}
                  >
                    {obj.label.tooltip}
                  </pre>
                </UncontrolledTooltip>
              )}
              {obj.label.showHelper === "true" && (
                <div className="alert alert-warning">
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: 14,
                      fontWeight: "bold",
                      margin: "0rem",
                      color: "black",
                    }}
                  >
                    {obj.label.helper}
                  </pre>
                </div>
              )}

              {obj.verified === "unverified" && (
                <div
                  className="alert alert-danger"
                  role="alert"
                  style={{
                    padding: "0.2rem 0.2rem",
                    marginBottom: "0px",
                  }}
                >
                  Please verify your integer number!
                </div>
              )}
              <input
                type="text"
                className={
                  obj.verified === "unverified"
                    ? "form-control formpadd_danger unverifi"
                    : "form-control formpadd "
                }
                value={obj.value}
                disabled={obj.uivalid.read_only === "true"}
                maxLength={obj.uivalid.max_length}
                onChange={(e) =>
                  formChangefn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id,
                    false
                  )
                }
                onBlur={(e) =>
                  validationfn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id
                  )
                }
              ></input>
            </div>
          ) : null}
          {obj.type === "date" ? (
            <div className=" form-group">
              {console.log(obj.value)}
              {obj.uivalid.mandatory === "true" && obj.value !== "" && (
                <i className="fa fa-asterisk mndtryfalse"></i>
              )}

              {obj.uivalid.mandatory === "true" && obj.value === "" && (
                <i className="fa fa-asterisk mndtrytrue"></i>
              )}
              <span
                id={"tiptar" + obj.index}
                className="field_hd"
                onTouchStart={(e) => {
                  setTimeout(() => {
                    e.preventDefault();
                    m_rid.current = obj.id;
                    setContextMenu(2, e.touches[0].pageX, e.touches[0].pageY);
                  }, 100);
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  if (isSub.current) {
                    let parent = document
                      .getElementById(form_id)
                      .getBoundingClientRect();
                    setContextMenu(
                      e.button,
                      e.nativeEvent.clientX - parent.left,
                      e.nativeEvent.clientY - parent.top
                    );
                  } else {
                    setContextMenu(
                      e.button,
                      e.nativeEvent.pageX,
                      e.nativeEvent.pageY
                    );
                  }
                }}
              // data-bs-custom-class="tooltip"
              // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
              >
                {console.log(obj)}
                {obj.label.name}
              </span>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "small",
                      textAlign: "start",
                    }}
                  >
                    {obj.label.tooltip}
                  </pre>
                </UncontrolledTooltip>
              )}
              {obj.label.showHelper === "true" && (
                <div className="alert alert-warning">
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {obj.label.helper}
                  </pre>
                </div>
              )}

              {obj.verified === "unverified" && (
                <div
                  className="alert alert-danger"
                  role="alert"
                  style={{
                    padding: "0.2rem 0.2rem",
                    marginBottom: "0px",
                  }}
                >
                  please verify your date!
                </div>
              )}
              <input
                id="dt"
                type="date"
                // defaultValue={Date.now()}
                // placeholder="dd-mm-yyy"

                className={
                  obj.verified === "unverified"
                    ? "objmargin unverifi"
                    : "objmargin"
                }
                value={obj.value}
                disabled={obj.uivalid.read_only === "true"}
                maxLength={obj.uivalid.max_length}
                onChange={(e) => {
                  console.log(e.target.value);
                  formChangefn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id,
                    false
                  );
                }}
                onBlur={(e) =>
                  validationfn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id
                  )
                }
              // onFocus={(e)=>{e.preventDefault();e.target.type="date";document.getElementById("dt").showPicker()}}
              // onBlur={(e)=>{e.target.type="text"}}
              ></input>
            </div>
          ) : null}
          {obj.type === "datetime" ? (
            <div className=" form-group">
              {obj.uivalid.mandatory === "true" && obj.value !== "" && (
                <i className="fa fa-asterisk mndtryfalse"></i>
              )}
              {obj.uivalid.mandatory === "true" && obj.value === "" && (
                <i className="fa fa-asterisk mndtrytrue"></i>
              )}
              <span
                id={"tiptar" + obj.index}
                className="field_hd"
                onTouchStart={(e) => {
                  setTimeout(() => {
                    e.preventDefault();
                    m_rid.current = obj.id;
                    setContextMenu(2, e.touches[0].pageX, e.touches[0].pageY);
                  }, 100);
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  if (isSub.current) {
                    let parent = document
                      .getElementById(form_id)
                      .getBoundingClientRect();
                    setContextMenu(
                      e.button,
                      e.nativeEvent.clientX - parent.left,
                      e.nativeEvent.clientY - parent.top
                    );
                  } else {
                    setContextMenu(
                      e.button,
                      e.nativeEvent.pageX,
                      e.nativeEvent.pageY
                    );
                  }
                }}
              // data-bs-custom-class="tooltip"
              // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
              >
                {console.log(obj)}
                {obj.label.name}
              </span>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "small",
                      textAlign: "start",
                    }}
                  >
                    {obj.label.tooltip}
                  </pre>
                </UncontrolledTooltip>
              )}
              {obj.label.showHelper === "true" && (
                <div className="alert alert-warning">
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {obj.label.helper}
                  </pre>
                </div>
              )}

              {obj.verified === "unverified" && (
                <div
                  className="alert alert-danger"
                  role="alert"
                  style={{
                    padding: "0.2rem 0.2rem",
                    marginBottom: "0px",
                  }}
                >
                  please verify your date!
                </div>
              )}
              <input
                type="datetime-local"
                step={1}
                className={
                  obj.verified === "unverified"
                    ? "objmargin unverifi"
                    : "objmargin "
                }
                value={obj.value}
                disabled={obj.uivalid.read_only === "true"}
                maxLength={obj.uivalid.max_length}
                onChange={(e) =>
                  formChangefn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id,
                    false
                  )
                }
                onBlur={(e) =>
                  validationfn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id
                  )
                }
              ></input>
            </div>
          ) : null}
          {obj.type === "time" ? (
            <div className=" form-group">
              {obj.uivalid.mandatory === "true" && obj.value !== "" && (
                <i className="fa fa-asterisk mndtryfalse"></i>
              )}

              {obj.uivalid.mandatory === "true" && obj.value === "" && (
                <i className="fa fa-asterisk mndtrytrue"></i>
              )}
              <span
                id={"tiptar" + obj.index}
                className="field_hd"
                onTouchStart={(e) => {
                  setTimeout(() => {
                    e.preventDefault();
                    m_rid.current = obj.id;
                    setContextMenu(2, e.touches[0].pageX, e.touches[0].pageY);
                  }, 100);
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  if (isSub.current) {
                    let parent = document
                      .getElementById(form_id)
                      .getBoundingClientRect();
                    setContextMenu(
                      e.button,
                      e.nativeEvent.clientX - parent.left,
                      e.nativeEvent.clientY - parent.top
                    );
                  } else {
                    setContextMenu(
                      e.button,
                      e.nativeEvent.pageX,
                      e.nativeEvent.pageY
                    );
                  }
                }}
              // data-bs-custom-class="tooltip"
              // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
              >
                {console.log(obj)}
                {obj.label.name}
              </span>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "small",
                      textAlign: "start",
                    }}
                  >
                    {obj.label.tooltip}
                  </pre>
                </UncontrolledTooltip>
              )}
              {obj.label.showHelper === "true" && (
                <div className="alert alert-warning">
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {obj.label.helper}
                  </pre>
                </div>
              )}
              {obj.verified === "unverified" && (
                <div
                  className="alert alert-danger"
                  role="alert"
                  style={{
                    padding: "0.2rem 0.2rem",
                    marginBottom: "0px",
                  }}
                >
                  please verify your time!
                </div>
              )}
              <input
                type="time"
                step="1"
                className={
                  obj.verified === "unverified"
                    ? "objmargin unverifi"
                    : "objmargin "
                }
                value={obj.value}
                readOnly={obj.uivalid.read_only === "true"}
                maxLength={obj.uivalid.max_length}
                onChange={(e) =>
                  formChangefn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id,
                    false
                  )
                }
                onBlur={(e) =>
                  validationfn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id
                  )
                }
              ></input>
            </div>
          ) : null}
          {obj.type === "label" ? (
            <div className=" form-group">
              {obj.uivalid.mandatory === "true" && obj.value !== "" && (
                <i className="fa fa-asterisk mndtryfalse"></i>
              )}

              {obj.uivalid.mandatory === "true" && obj.value === "" && (
                <i className="fa fa-asterisk mndtrytrue"></i>
              )}
              <span
                id={"tiptar" + obj.index}
                className="field_hd"
                onTouchStart={(e) => {
                  setTimeout(() => {
                    e.preventDefault();
                    m_rid.current = obj.id;
                    setContextMenu(2, e.touches[0].pageX, e.touches[0].pageY);
                  }, 100);
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  if (isSub.current) {
                    let parent = document
                      .getElementById(form_id)
                      .getBoundingClientRect();
                    setContextMenu(
                      e.button,
                      e.nativeEvent.clientX - parent.left,
                      e.nativeEvent.clientY - parent.top
                    );
                  } else {
                    setContextMenu(
                      e.button,
                      e.nativeEvent.pageX,
                      e.nativeEvent.pageY
                    );
                  }
                }}
              // data-bs-custom-class="tooltip"
              // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
              >
                {console.log(obj)}
                {obj.label.name}
              </span>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "small",
                      textAlign: "start",
                    }}
                  >
                    {obj.label.tooltip}
                  </pre>
                </UncontrolledTooltip>
              )}
              {obj.label.showHelper === "true" && (
                <div className="alert alert-warning">
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {obj.label.helper}
                  </pre>
                </div>
              )}
            </div>
          ) : null}

          {obj.type === "boolean" && obj.name !== "is_reset_password" ? (
            <div className="form-check fmcheck">
              {obj.label.showHelper === "true" && (
                <div className="alert alert-warning">
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: 14,
                      fontWeight: "bold",
                      margin: "0rem",
                      color: "black",
                    }}
                  >
                    {obj.label.helper}
                  </pre>
                </div>
              )}

              <input
                type="checkbox"
                className={
                  obj.verified === "unverified"
                    ? "checkpadd unverifi"
                    : "checkpadd"
                }
                // value={obj.value === "true" ? true : false}

                checked={obj.value === "true" ? true : false}
                disabled={
                  obj.uivalid.read_only === "true" ||
                  (rid === "1" && tableName === "application")
                }
                onChange={(e) => {
                  formChangefn(
                    e.target.checked.toString(),
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id,
                    false
                  );
                }}
                onBlur={(e) =>
                  validationfn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id
                  )
                }
              ></input>

              <span
                id={"tiptar" + obj.index}
                className="field_hd"
                onTouchStart={(e) => {
                  setTimeout(() => {
                    e.preventDefault();
                    m_rid.current = obj.id;
                    setContextMenu(2, e.touches[0].pageX, e.touches[0].pageY);
                  }, 100);
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  if (isSub.current) {
                    let parent = document
                      .getElementById(form_id)
                      .getBoundingClientRect();
                    setContextMenu(
                      e.button,
                      e.nativeEvent.clientX - parent.left,
                      e.nativeEvent.clientY - parent.top
                    );
                  } else {
                    setContextMenu(
                      e.button,
                      e.nativeEvent.pageX,
                      e.nativeEvent.pageY
                    );
                  }
                }}
              // data-bs-custom-class="tooltip"
              // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
              >
                {obj.label.name}
              </span>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "small",
                      textAlign: "start",
                    }}
                  >
                    {obj.label.tooltip}
                  </pre>
                </UncontrolledTooltip>
              )}
            </div>
          ) : null}
          {obj.type === "boolean" && obj.name === "is_reset_password" ? (
            <div className="form-check fmcheck">
              {obj.label.showHelper === "true" && (
                <div className="alert alert-warning">
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {obj.label.helper}
                  </pre>
                </div>
              )}
              <input
                type="checkbox"
                className={
                  obj.verified === "unverified"
                    ? "checkpadd unverifi"
                    : "checkpadd"
                }
                checked={
                  passwordReadOnly ? true : obj.value === "true" ? true : false
                }
                disabled={obj.uivalid.read_only === "true"}
                onChange={(e) => {
                  if (!passwordReadOnly) {
                    formChangefn(
                      e.target.checked.toString(),
                      obj.index,
                      obj.name,
                      obj.type,
                      obj.id,
                      false
                    );
                  }
                }}
                onBlur={(e) =>
                  validationfn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id
                  )
                }
              ></input>
              <span
                id={"tiptar" + obj.index}
                className="field_hd"
                onTouchStart={(e) => {
                  setTimeout(() => {
                    e.preventDefault();
                    m_rid.current = obj.id;
                    setContextMenu(2, e.touches[0].pageX, e.touches[0].pageY);
                  }, 100);
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  if (isSub.current) {
                    let parent = document
                      .getElementById(form_id)
                      .getBoundingClientRect();
                    setContextMenu(
                      e.button,
                      e.nativeEvent.clientX - parent.left,
                      e.nativeEvent.clientY - parent.top
                    );
                  } else {
                    setContextMenu(
                      e.button,
                      e.nativeEvent.pageX,
                      e.nativeEvent.pageY
                    );
                  }
                }}
              // data-bs-custom-class="tooltip"
              // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
              >
                {console.log(obj)}
                {obj.label.name}
              </span>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "small",
                      textAlign: "start",
                    }}
                  >
                    {obj.label.tooltip}
                  </pre>
                </UncontrolledTooltip>
              )}
            </div>
          ) : null}
          {obj.type === "reference" ? (
            <div style={{ width: obj.width === "50%" ? "50%" : "100%" }}>
              <div className="form-group">
                {obj.uivalid.mandatory === "true" && obj.value.name !== "" && (
                  <i className="fa fa-asterisk mndtryfalse"></i>
                )}
                {obj.uivalid.mandatory === "true" &&
                  obj.uivalid.group_mandatory === "false" &&
                  obj.value.name === "" && (
                    <i className="fa fa-asterisk mndtrytrue"></i>
                  )}

                {obj.uivalid.mandatory === "true" &&
                  obj.uivalid.group_mandatory === "true" &&
                  obj.value.name === "" && (
                    <i className="fa fa-asterisk mndtrytrue"></i>
                  )}
                <span
                  id={"tiptar" + obj.index}
                  className="field_hd"
                  onTouchStart={(e) => {
                    setTimeout(() => {
                      e.preventDefault();
                      m_rid.current = obj.id;
                      setContextMenu(2, e.touches[0].pageX, e.touches[0].pageY);
                    }, 100);
                  }}
                  onContextMenu={(e) => {
                    e.preventDefault();
                    m_rid.current = obj.id;
                    setContextMenu(
                      e.button,
                      e.nativeEvent.pageX,
                      e.nativeEvent.pageY
                    );
                  }}
                // data-bs-custom-class="tooltip"
                // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
                >
                  {obj.label.name}
                </span>
                {obj.label.showTooltip === "true" && (
                  <UncontrolledTooltip
                    arrowClassName="tip  "
                    className="tip "
                    innerClassName="tip text-dark text-nowrap"
                    target={"tiptar" + obj.index}
                    placement="right"
                  >
                    <pre
                      style={{
                        fontFamily: "sans-serif",
                        fontSize: "small",
                        textAlign: "start",
                        margin: "0rem",
                      }}
                    >
                      {obj.label.tooltip}
                    </pre>
                  </UncontrolledTooltip>
                )}
                {obj.label.showHelper === "true" && (
                  <div className="alert alert-warning">
                    <pre
                      style={{
                        fontFamily: "sans-serif",
                        fontSize: 14,
                        fontWeight: "bold",
                        margin: "0rem",
                        color: "black",
                      }}
                    >
                      {obj.label.helper}
                    </pre>
                  </div>
                )}
                {console.log("objname1", obj.name)}
                {obj.verified === "unverified" && (
                  <div
                    className="alert alert-danger"
                    role="alert"
                    style={{
                      padding: "0.2rem 0.2rem",
                      marginBottom: "0px",
                    }}
                  >
                    record not found!
                  </div>
                )}
                <div style={{ display: "flex" }}>
                  {console.log("refo", obj.depend_column, "   ", obj)}
                  <input
                    type="text"
                    className={
                      obj.verified === "unverified"
                        ? "form-control formpadd formbor unverifi"
                        : "form-control formpadd formbor"
                    }
                    maxLength={obj.uivalid.max_length}
                    value={obj.value.name}
                    disabled={obj.uivalid.read_only === "true" ? true : false}
                    onChange={(e) => {
                      formChangefn(
                        e.target.value,
                        obj.index,
                        obj.name,
                        obj.type,
                        obj.id,
                        false,
                        obj
                      );
                    }}
                    onBlur={(e) => {
                      setTimeout(() => {
                        console.log("objjjjjjjjjjjjjjjjjjjjj ", obj.verified);
                        if (obj.verified !== "verified") {
                          validationfn(
                            obj.value,
                            obj.index,
                            obj.name,
                            obj.type,
                            obj.id
                          );
                        }
                      }, 500);
                    }}
                  ></input>
                  <div
                    className="btnsrc vlpointer"
                    onClick={(e) => {
                      clickRefrence(
                        e,
                        obj.id,
                        obj.name,
                        obj.type,
                        obj.index,
                        obj.label.name,
                        obj.uivalid.read_only,
                        obj.depend_column
                      );
                    }}
                  >
                    <i
                      className="fa fa-search"

                    // data-bs-toggle="modal"
                    // data-bs-target="#staticBackdrop"
                    ></i>
                  </div>

                  {obj?.rcd_info === "true" && obj.value.name !== "" && (
                    <div
                      className="btnsrc vlpointer"
                      onClick={(e) => {
                        getSingleInfo(
                          e,
                          obj,
                          obj.id,
                          obj.name,
                          obj.type,
                          obj.index,
                          obj.label.name,
                          obj.uivalid.read_only,
                          obj.depend_column
                        );
                      }}
                    >
                      <i className="fa-solid fa-circle-info"></i>
                    </div>
                  )}
                </div>
              </div>
              {refrecord.index === obj.index &&
                showContainer &&
                refrecord.record.length > 0 && (
                  <div className="sf_container">
                    {refrecord.record.map((obj_ref, or_i) => (
                      <div
                        className="refrcd"
                        onClick={(e) =>
                          setRefRcd(
                            obj_ref.value,
                            obj.index,
                            obj.name,
                            obj.type,
                            obj_ref.id,
                            obj
                          )
                        }
                        key={or_i}
                      >
                        {obj_ref.value}
                      </div>
                    ))}
                  </div>
                )}
            </div>
          ) : null}
          {obj.type === "filter" ? (
            <div className="form-group objpdg ">
              <span
                id={"tiptar" + obj.index}
                className="field_hd"
                onTouchStart={(e) => {
                  setTimeout(() => {
                    e.preventDefault();
                    m_rid.current = obj.id;
                    setContextMenu(2, e.touches[0].pageX, e.touches[0].pageY);
                  }, 100);
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  if (isSub.current) {
                    let parent = document
                      .getElementById(form_id)
                      .getBoundingClientRect();
                    setContextMenu(
                      e.button,
                      e.nativeEvent.clientX - parent.left,
                      e.nativeEvent.clientY - parent.top
                    );
                  } else {
                    setContextMenu(
                      e.button,
                      e.nativeEvent.pageX,
                      e.nativeEvent.pageY
                    );
                  }
                }}
              // data-bs-custom-class="tooltip"
              // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
              >
                {console.log(obj)}
                {obj.label.name}
              </span>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "small",
                      textAlign: "start",
                    }}
                  >
                    {obj.label.tooltip}
                  </pre>
                </UncontrolledTooltip>
              )}
              {obj.label.showHelper === "true" && (
                <div className="alert alert-warning">
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {obj.label.helper}
                  </pre>
                </div>
              )}

              <div>
                <NewFilterCompo
                  showlist={showlist}
                  col_mn={obj.dep_choice}
                  call_fil={(filtarray, in_index, col_id) =>
                    callfilter(filtarray, obj.index, in_index, col_id)
                  }
                  filtarray={obj.value.filter}
                  setFiltArray={setFiltArray}
                  timeline={timeline}
                  setTimeLine={(timeline) => calltimeline(timeline, obj.index)}
                  setScript={(script) => callScript(script, obj.index)}
                  isScript={false}
                  script={reScript}
                  col_depend={col_depend}
                  dcFiltVal={dcFiltVal}
                  deftimeline={deftimeline}
                ></NewFilterCompo>
              </div>
            </div>
          ) : null}
          {obj.type === "condition" ? (
            <div className="form-group objpdg ">
              <span
                id={"tiptar" + obj.index}
                className="field_hd"
                onTouchStart={(e) => {
                  setTimeout(() => {
                    e.preventDefault();
                    m_rid.current = obj.id;
                    setContextMenu(2, e.touches[0].pageX, e.touches[0].pageY);
                  }, 100);
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  if (isSub.current) {
                    let parent = document
                      .getElementById(form_id)
                      .getBoundingClientRect();
                    setContextMenu(
                      e.button,
                      e.nativeEvent.clientX - parent.left,
                      e.nativeEvent.clientY - parent.top
                    );
                  } else {
                    setContextMenu(
                      e.button,
                      e.nativeEvent.pageX,
                      e.nativeEvent.pageY
                    );
                  }
                }}
              // data-bs-custom-class="tooltip"
              // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
              >
                {console.log(obj)}
                {obj.label.name}
              </span>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "small",
                      textAlign: "start",
                    }}
                  >
                    {obj.label.tooltip}
                  </pre>
                </UncontrolledTooltip>
              )}
              {obj.label.showHelper === "true" && (
                <div className="alert alert-warning">
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {obj.label.helper}
                  </pre>
                </div>
              )}

              <div>
                <NewFilterCompo
                  showlist={showlist}
                  col_mn={col_mn}
                  call_fil={(filtarray, in_index, col_id) =>
                    callfilter(filtarray, obj.index, in_index, col_id)
                  }
                  filtarray={obj.value.filter}
                  setFiltArray={setFiltArray}
                  timeline={timeline}
                  setTimeLine={(timeline) => calltimeline(timeline, obj.index)}
                  setScript={(script) => callScript(script, obj.index)}
                  isScript={false}
                  script={reScript}
                  col_depend={col_depend}
                  dcFiltVal={dcFiltVal}
                  deftimeline={deftimeline}
                ></NewFilterCompo>
              </div>
            </div>
          ) : null}

          {obj.type === "filter_ref" ? (
            <div className="form-group objpdg ">
              <span
                id={"tiptar" + obj.index}
                className="field_hd"
                onTouchStart={(e) => {
                  setTimeout(() => {
                    e.preventDefault();
                    m_rid.current = obj.id;
                    setContextMenu(2, e.touches[0].pageX, e.touches[0].pageY);
                  }, 100);
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  if (isSub.current) {
                    let parent = document
                      .getElementById(form_id)
                      .getBoundingClientRect();
                    setContextMenu(
                      e.button,
                      e.nativeEvent.clientX - parent.left,
                      e.nativeEvent.clientY - parent.top
                    );
                  } else {
                    setContextMenu(
                      e.button,
                      e.nativeEvent.pageX,
                      e.nativeEvent.pageY
                    );
                  }
                }}
              // data-bs-custom-class="tooltip"
              // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
              >
                {console.log(obj)}
                {obj.label.name}
              </span>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "small",
                      textAlign: "start",
                    }}
                  >
                    {obj.label.tooltip}
                  </pre>
                </UncontrolledTooltip>
              )}
              {obj.label.showHelper === "true" && (
                <div className="alert alert-warning">
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {obj.label.helper}
                  </pre>
                </div>
              )}

              <div>
                <NewFilterCompo
                  showlist={showlist}
                  col_mn={col_mn_ref}
                  call_fil={(filtarray, in_index, col_id) =>
                    callfilter(filtarray, obj.index, in_index, col_id)
                  }
                  filtarray={obj.value.filter}
                  setFiltArray={setref_filter}
                  timeline={timeline}
                  setTimeLine={(timeline) => calltimeline(timeline, obj.index)}
                  setScript={(script) => callScript(script, obj.index)}
                  isScript={false}
                  script={reScript}
                  col_depend={col_depend}
                  dcFiltVal={dcFiltVal}
                  deftimeline={deftimeline}
                ></NewFilterCompo>
              </div>
            </div>
          ) : null}
          {obj.type === "filter_script" ? (
            <div className="form-group objpdg ">
              <span
                id={"tiptar" + obj.index}
                className="field_hd"
                onTouchStart={(e) => {
                  setTimeout(() => {
                    e.preventDefault();
                    m_rid.current = obj.id;
                    setContextMenu(2, e.touches[0].pageX, e.touches[0].pageY);
                  }, 100);
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  if (isSub.current) {
                    let parent = document
                      .getElementById(form_id)
                      .getBoundingClientRect();
                    setContextMenu(
                      e.button,
                      e.nativeEvent.clientX - parent.left,
                      e.nativeEvent.clientY - parent.top
                    );
                  } else {
                    setContextMenu(
                      e.button,
                      e.nativeEvent.pageX,
                      e.nativeEvent.pageY
                    );
                  }
                }}
              // data-bs-custom-class="tooltip"
              // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
              >
                {console.log(obj)}
                {obj.label.name}
              </span>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "small",
                      textAlign: "start",
                    }}
                  >
                    {obj.label.tooltip}
                  </pre>
                </UncontrolledTooltip>
              )}
              {obj.label.showHelper === "true" && (
                <div className="alert alert-warning">
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {obj.label.helper}
                  </pre>
                </div>
              )}
              <div>
                <NewFilterCompo
                  showlist={showlist}
                  col_mn={col_mn}
                  call_fil={(filtarray, in_index, col_id) =>
                    callfilter(filtarray, obj.index, in_index, col_id)
                  }
                  filtarray={obj.value.filter}
                  setFiltArray={setFiltArray}
                  timeline={timeline}
                  setTimeLine={(timeline) => calltimeline(timeline)}
                  setScript={(script) => callScript(script, obj.index)}
                  isScript={true}
                  script={reScript}
                  col_depend={col_depend}
                  dcFiltVal={dcFiltVal}
                  selectedScript={obj.value.script}
                  deftimeline={deftimeline}
                ></NewFilterCompo>
              </div>
            </div>
          ) : null}
          {obj.type === "html" ? (
            <div className=" form-group objpdg ">
              {obj.uivalid.mandatory === "true" && obj.value !== "" && (
                <i className="fa fa-asterisk mndtryfalse"></i>
              )}

              {obj.uivalid.mandatory === "true" && obj.value === "" && (
                <i className="fa fa-asterisk mndtrytrue"></i>
              )}
              <span
                id={"tiptar" + obj.index}
                className="field_hd"
                onTouchStart={(e) => {
                  setTimeout(() => {
                    e.preventDefault();
                    m_rid.current = obj.id;
                    setContextMenu(2, e.touches[0].pageX, e.touches[0].pageY);
                  }, 100);
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  if (isSub.current) {
                    let parent = document
                      .getElementById(form_id)
                      .getBoundingClientRect();
                    setContextMenu(
                      e.button,
                      e.nativeEvent.clientX - parent.left,
                      e.nativeEvent.clientY - parent.top
                    );
                  } else {
                    setContextMenu(
                      e.button,
                      e.nativeEvent.pageX,
                      e.nativeEvent.pageY
                    );
                  }
                }}
              // data-bs-custom-class="tooltip"
              // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
              >
                {console.log(obj)}
                {obj.label.name}
              </span>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "small",
                      textAlign: "start",
                    }}
                  >
                    {obj.label.tooltip}
                  </pre>
                </UncontrolledTooltip>
              )}
              {obj.label.showHelper === "true" && (
                <div className="alert alert-warning">
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {obj.label.helper}
                  </pre>
                </div>
              )}

              {obj.verified === "unverified" && (
                <div
                  className="alert alert-danger"
                  role="alert"
                  style={{
                    padding: "0.2rem 0.2rem",
                    marginBottom: "0px",
                  }}
                >
                  Please verify your charecter not accept number!
                </div>
              )}
              <div
                className="row"
                style={{
                  background: "#ffffff",
                  color: "#000",
                }}
              >
                <div className="col-md">
                  <JoditEditor
                    value={obj.value}
                    ref={editor}
                    // config={{readOnly:false}}
                    onChange={(content) => {
                      formChangefn(
                        content,
                        obj.index,
                        obj.name,
                        obj.type,
                        obj.id,
                        false
                      );
                    }}
                  />
                </div>
                <div className=" card col-md-3 side-cont">
                  {col_mn.length > 0 &&
                    col_mn.map((co, ci) => (
                      <div
                        key={ci}
                        className="list-group-item side-list "
                        onClick={() => {
                          let cont = obj.value + " ${" + co.name + "} ";
                          formChangefn(
                            cont,
                            obj.index,
                            obj.name,
                            obj.type,
                            obj.id,
                            false
                          );
                        }}
                      >
                        {co.name}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : null}
          {obj.type === "table_lookup" ? (
            <div className="form-check fmcheck">
              {obj.uivalid.mandatory === "true" && obj.value !== "" && (
                <i className="fa fa-asterisk mndtryfalse"></i>
              )}

              {obj.uivalid.mandatory === "true" && obj.value === "" && (
                <i className="fa fa-asterisk mndtrytrue"></i>
              )}
              <span
                id={"tiptar" + obj.index}
                className="field_hd"
                onTouchStart={(e) => {
                  setTimeout(() => {
                    e.preventDefault();
                    m_rid.current = obj.id;
                    setContextMenu(2, e.touches[0].pageX, e.touches[0].pageY);
                  }, 100);
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  if (isSub.current) {
                    let parent = document
                      .getElementById(form_id)
                      .getBoundingClientRect();
                    setContextMenu(
                      e.button,
                      e.nativeEvent.clientX - parent.left,
                      e.nativeEvent.clientY - parent.top
                    );
                  } else {
                    setContextMenu(
                      e.button,
                      e.nativeEvent.pageX,
                      e.nativeEvent.pageY
                    );
                  }
                }}
              // data-bs-custom-class="tooltip"
              // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
              >
                {console.log(obj)}
                {obj.label.name}
              </span>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "small",
                      textAlign: "start",
                    }}
                  >
                    {obj.label.tooltip}
                  </pre>
                </UncontrolledTooltip>
              )}
              {obj.label.showHelper === "true" && (
                <div className="alert alert-warning">
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {obj.label.helper}
                  </pre>
                </div>
              )}

              <select
                className="form-control form-select formpadd "
                aria-label="Default select example"
                value={obj.value}
                onChange={(e) => {
                  onSelection(e.target.value);
                  formChangefn(
                    e.target.value,
                    obj.index,
                    obj.name,
                    obj.type,
                    obj.id,
                    false
                  );
                }}
                maxLength={obj.uivalid.max_length}
                disabled={obj.uivalid.read_only === "true"}
              >
                <option value="">None</option>

                {obj.lookup.map((ch, chi) => (
                  <option key={chi} value={ch.name}>
                    {ch.name}
                  </option>
                ))}
              </select>
            </div>
          ) : null}
          {obj.type === "image_upload" ? (
            <div className=" form-group objpdg">
              {console.log(obj)}
              {obj.uivalid.mandatory === "true" && obj.value !== "" && (
                <i className="fa fa-asterisk mndtryfalse"></i>
              )}
              {obj.uivalid.mandatory === "true" && obj.value === "" && (
                <i className="fa fa-asterisk mndtrytrue"></i>
              )}

              <span
                id={"tiptar" + obj.index}
                className="field_hd"
                onTouchStart={(e) => {
                  setTimeout(() => {
                    e.preventDefault();
                    m_rid.current = obj.id;
                    setContextMenu(2, e.touches[0].pageX, e.touches[0].pageY);
                  }, 100);
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  if (isSub.current) {
                    let parent = document
                      .getElementById(form_id)
                      .getBoundingClientRect();
                    setContextMenu(
                      e.button,
                      e.nativeEvent.clientX - parent.left,
                      e.nativeEvent.clientY - parent.top
                    );
                  } else {
                    setContextMenu(
                      e.button,
                      e.nativeEvent.pageX,
                      e.nativeEvent.pageY
                    );
                  }
                }}
              // data-bs-custom-class="tooltip"
              // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
              >
                {console.log(obj)}
                {obj.label.name}
              </span>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "small",
                      textAlign: "start",
                    }}
                  >
                    {obj.label.tooltip}
                  </pre>
                </UncontrolledTooltip>
              )}
              {obj.label.showHelper === "true" && (
                <div className="alert alert-warning">
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {obj.label.helper}
                  </pre>
                </div>
              )}

              {obj.verified === "unverified" && (
                <div
                  className="alert alert-danger"
                  role="alert"
                  style={{
                    padding: "0.2rem 0.2rem",
                    marginBottom: "0px",
                  }}
                >
                  Please verify your charecter not accept number!
                </div>
              )}
              {console.log("abd3169", obj, obj.value)}
              <div className="my-2">
                {obj.value !== "" ? (
                  <div className="saved-imgs">
                    <div className="rr-img-div">
                      <div
                        className="cancel-mark-div"
                        onClick={() => {
                          deleteImage(obj);
                        }}
                      >
                        <i
                          className="fa-solid fa-xmark fa-lg"
                          style={{ color: "red", cursor: "pointer" }}
                        ></i>
                      </div>
                      <img
                        className="user-feedback-img"
                        src={obj.localimg ? obj.value : imageloca + obj.value}
                      />
                    </div>
                  </div>
                ) : (
                  <div
                    id="add-image-for-preview"
                    className="add-img-div rr-img-div"
                    onClick={launchChoose}
                  >
                    <div className="my-2">
                      <i className="fa-solid fa-plus fa-2xl"></i>
                    </div>
                    <div>Add Image</div>
                    <input
                      className="ourCustomized"
                      type="file"
                      accept=".jpg, .jpeg, .png, .pdf"
                      onChange={(e) => handleFileChange(e, obj)}
                    />
                  </div>
                )}
              </div>
            </div>
          ) : null}
          {obj.type === "depend_table" && (
            <div className="form-group ">
              {console.log(obj)}
              {obj.uivalid.mandatory === "true" &&
                obj.value.name !== "None" &&
                obj.value.name !== "" && (
                  <i className="fa fa-asterisk mndtryfalse"></i>
                )}

              {obj.uivalid.mandatory === "true" &&
                obj.value.name === "" &&
                obj.uivalid.group_mandatory === "false" && (
                  <i className="fa fa-asterisk mndtrytrue"></i>
                )}
              {obj.uivalid.mandatory === "true" &&
                obj.uivalid.group_mandatory === "true" &&
                obj.value.name === "" && (
                  <i className="fa fa-asterisk mndtrytrue"></i>
                )}
              <span
                id={"tiptar" + obj.index}
                className="field_hd"
                onTouchStart={(e) => {
                  setTimeout(() => {
                    e.preventDefault();
                    m_rid.current = obj.id;
                    setContextMenu(2, e.touches[0].pageX, e.touches[0].pageY);
                  }, 100);
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  if (isSub.current) {
                    let parent = document
                      .getElementById(form_id)
                      .getBoundingClientRect();
                    setContextMenu(
                      e.button,
                      e.nativeEvent.clientX - parent.left,
                      e.nativeEvent.clientY - parent.top
                    );
                  } else {
                    setContextMenu(
                      e.button,
                      e.nativeEvent.pageX,
                      e.nativeEvent.pageY
                    );
                  }
                }}
              // data-bs-custom-class="tooltip"
              // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
              >
                {console.log(obj)}
                {obj.label.name}
              </span>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "small",
                      textAlign: "start",
                    }}
                  >
                    {obj.label.tooltip}
                  </pre>
                </UncontrolledTooltip>
              )}
              {obj.label.showHelper === "true" && (
                <div className="alert alert-warning">
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {obj.label.helper}
                  </pre>
                </div>
              )}
              {/* <span className="field_hd">{obj.label.name}</span> */}
              {console.log(obj)}
              <div style={{ display: "flex" }}>
                <select
                  className="form-control form-select formpadd "
                  aria-label="Default select example"
                  value={obj.value.id}
                  onChange={(e) => {
                    console.log(e.target.value);
                    let id = 0;
                    let name = "";
                    for (let i = 0; i < obj.dep_choice.length; i++) {
                      if (obj.dep_choice[i].id === e.target.value) {
                        id = obj.dep_choice[i].id;
                        name = obj.dep_choice[i].name;
                      }
                    }
                    formChangefn(
                      name,
                      obj.index,
                      obj.name,
                      obj.type,
                      id,
                      false,
                      obj
                    );
                  }}
                  maxLength={obj.uivalid.max_length}
                  disabled={obj.uivalid.read_only === "true"}
                >
                  <option value="">None</option>

                  {obj.dep_choice.sort((a, b) => a.label.localeCompare(b.label)).map((ch, chi) => (
                    <option key={chi} label={ch.label} value={ch.id}>
                      {ch.label}
                    </option>
                  ))}
                </select>
                {obj?.rcd_info === "true" && (
                  <div
                    className="btnsrc vlpointer"
                    onClick={(e) => {
                      getSingleInfo(
                        e,
                        obj,
                        obj.id,
                        obj.name,
                        obj.type,
                        obj.index,
                        obj.label.name,
                        obj.uivalid.read_only,
                        obj.depend_column
                      );
                    }}
                    style={{ marginLeft: "2px" }}
                  >
                    <i className="fa-solid fa-circle-info"></i>
                  </div>
                )}
              </div>
            </div>
          )}

          {obj.type === "dynamic_key_value" && (
            <div className="form-group ">
              {/* {console.log(obj, obj.value[0], Object.keys(obj.value[0]), Object.keys(obj.value[0]) !== "")} */}
              {obj.uivalid.mandatory === "true" &&
                ((Object.keys(obj.value[0])[0] !== "" &&
                  Object.keys(obj.value[0]).length > 0) ||
                  (Object.values(obj.value[0])[0] !== "" &&
                    Object.values(obj.value[0]).length > 0)) && (
                  <i className="fa fa-asterisk mndtryfalse"></i>
                )}

              {obj.uivalid.mandatory === "true" &&
                (Object.keys(obj.value[0])[0] === "" ||
                  Object.keys(obj.value[0]).length === 0) &&
                (Object.values(obj.value[0])[0] === "" ||
                  Object.values(obj.value[0]).length === 0) &&
                obj.uivalid.group_mandatory === "false" && (
                  <i className="fa fa-asterisk mndtrytrue"></i>
                )}
              {obj.uivalid.mandatory === "true" &&
                obj.uivalid.group_mandatory === "true" &&
                (Object.keys(obj.value[0])[0] === "" ||
                  Object.keys(obj.value[0]).length === 0) &&
                (Object.values(obj.value[0])[0] === "" ||
                  Object.values(obj.value[0]).length === 0) && (
                  <i className="fa fa-asterisk mndtrygptrue"></i>
                )}
              <span
                id={"tiptar" + obj.index}
                className="field_hd"
                onTouchStart={(e) => {
                  setTimeout(() => {
                    e.preventDefault();
                    m_rid.current = obj.id;
                    setContextMenu(2, e.touches[0].pageX, e.touches[0].pageY);
                  }, 100);
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  if (isSub.current) {
                    let parent = document
                      .getElementById(form_id)
                      .getBoundingClientRect();
                    setContextMenu(
                      e.button,
                      e.nativeEvent.clientX - parent.left,
                      e.nativeEvent.clientY - parent.top
                    );
                  } else {
                    setContextMenu(
                      e.button,
                      e.nativeEvent.pageX,
                      e.nativeEvent.pageY
                    );
                  }
                }}
              // data-bs-custom-class="tooltip"
              // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
              >
                {console.log(obj)}
                {obj.label.name}
              </span>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "small",
                      textAlign: "start",
                    }}
                  >
                    {obj.label.tooltip}
                  </pre>
                </UncontrolledTooltip>
              )}
              {obj.label.showHelper === "true" && (
                <div className="alert alert-warning">
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {obj.label.helper}
                  </pre>
                </div>
              )}
              {obj.value.map((objj, outerIndex) => (
                <div key={outerIndex} className="d-flex">
                  <div className="d-flex">
                    {console.log(objj)}
                    <input
                      type="text"
                      name="child"
                      className="form-control formpadd me-2 my-1"
                      value={Object.keys(objj)[0]}
                      readOnly={obj.uivalid.read_only === "true"}
                      disabled={obj.uivalid.read_only === "true"}
                      maxLength={obj.uivalid.max_length}
                      onChange={(e) =>
                        formChangefn(
                          e.target.value,
                          obj.index,
                          obj.name,
                          obj.type,
                          obj.id,
                          false,
                          obj,
                          outerIndex,
                          0,
                          "string"
                        )
                      }
                      onBlur={(e) =>
                        validationfn(
                          e.target.value,
                          obj.index,
                          obj.name,
                          obj.type,
                          obj.id
                        )
                      }
                    />
                    <label className="me-2" style={{ alignContent: "center" }}>
                      {" "}
                      :{" "}
                    </label>
                    <input
                      type="text"
                      name="child"
                      className="form-control formpadd me-2 my-1"
                      value={Object.values(objj)[0]}
                      readOnly={obj.uivalid.read_only === "true"}
                      disabled={obj.uivalid.read_only === "true"}
                      maxLength={obj.uivalid.max_length}
                      onChange={(e) =>
                        formChangefn(
                          e.target.value,
                          obj.index,
                          obj.name,
                          obj.type,
                          obj.id,
                          false,
                          obj,
                          outerIndex,
                          1,
                          "string"
                        )
                      }
                      onBlur={(e) =>
                        validationfn(
                          e.target.value,
                          obj.index,
                          obj.name,
                          obj.type,
                          obj.id
                        )
                      }
                    />
                    {obj.uivalid.read_only !== "true" && (
                      <div>
                        {outerIndex === 0 ? (
                          <i
                            style={{
                              fontSize: "26px",
                              cursor: "pointer",
                              marginTop: "9px",
                            }}
                            className="fa-solid fa-plus"
                            onClick={() => addInput(obj)}
                          ></i>
                        ) : (
                          <i
                            style={{
                              fontSize: "26px",
                              cursor: "pointer",
                              marginTop: "9px",
                            }}
                            className="fa-solid fa-minus"
                            onClick={() => removeInput(outerIndex, obj)}
                          ></i>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              ))}
              {/* <span className="field_hd">{obj.label.name}</span> */}
              {console.log(obj)}
              {/* <div style={{ display: "flex" }}>
                <select
                  className="form-control form-select formpadd "
                  aria-label="Default select example"
                  value={obj.value.id}
                  onChange={(e) => {
                    console.log(e.target.value);
                    let id = 0;
                    let name = "";
                    for (let i = 0; i < obj.dep_choice.length; i++) {
                      if (obj.dep_choice[i].id === e.target.value) {
                        id = obj.dep_choice[i].id;
                        name = obj.dep_choice[i].name;
                      }
                    }
                    formChangefn(
                      name,
                      obj.index,
                      obj.name,
                      obj.type,
                      id,
                      false,
                      obj
                    );
                  }}
                  maxLength={obj.uivalid.max_length}
                  disabled={obj.uivalid.read_only === "true"}
                >
                  <option value="">None</option>

                  {obj.dep_choice.map((ch, chi) => (
                    <option key={chi} label={ch.name} value={ch.id}>
                      {ch.name}
                    </option>
                  ))}
                </select>
                {obj?.rcd_info === "true" && (
                  <div
                    className="btnsrc vlpointer"
                    onClick={(e) => {
                      getSingleInfo(
                        e,
                        obj,
                        obj.id,
                        obj.name,
                        obj.type,
                        obj.index,
                        obj.label.name,
                        obj.uivalid.read_only,
                        obj.depend_column
                      );
                    }}
                    style={{ marginLeft: "2px" }}
                  >
                    <i className="fa-solid fa-circle-info"></i>
                  </div>
                )}
              </div> */}
            </div>
          )}
          {obj.type === "group_key_value" ? (
            <div className="form-group objpdg">
              {console.log(obj.value, obj.value !== "")}
              {obj.uivalid.mandatory === "true" &&
                !obj.value.properties
                  .map((obj) => [
                    obj.name,
                    ...obj.choice.map((choice) => choice.value),
                  ])
                  .flat()
                  .some((value) => value === "") && (
                  <i className="fa fa-asterisk mndtryfalse"></i>
                )}
              {obj.uivalid.mandatory === "true" &&
                obj.value.properties
                  .map((obj) => [
                    obj.name,
                    ...obj.choice.map((choice) => choice.value),
                  ])
                  .flat()
                  .some((value) => value === "") && (
                  <i className="fa fa-asterisk mndtrytrue"></i>
                )}
              <span
                id={"tiptar" + obj.index}
                className="field_hd"
                onTouchStart={(e) => {
                  setTimeout(() => {
                    e.preventDefault();
                    m_rid.current = obj.id;
                    setContextMenu(2, e.touches[0].pageX, e.touches[0].pageY);
                  }, 100);
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  if (isSub.current) {
                    let parent = document
                      .getElementById(form_id)
                      .getBoundingClientRect();
                    setContextMenu(
                      e.button,
                      e.nativeEvent.clientX - parent.left,
                      e.nativeEvent.clientY - parent.top
                    );
                  } else {
                    setContextMenu(
                      e.button,
                      e.nativeEvent.pageX,
                      e.nativeEvent.pageY
                    );
                  }
                }}
              // data-bs-custom-class="tooltip"
              // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
              >
                {console.log(obj)}
                {obj.label.name}
              </span>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "small",
                      textAlign: "start",
                    }}
                  >
                    {obj.label.tooltip}
                  </pre>
                </UncontrolledTooltip>
              )}
              {obj.label.showHelper === "true" && (
                <div className="alert alert-warning">
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {obj.label.helper}
                  </pre>
                </div>
              )}

              {obj.verified === "unverified" && (
                <div
                  className="alert alert-danger"
                  role="alert"
                  style={{
                    padding: "0.2rem 0.2rem",
                    marginBottom: "0px",
                  }}
                >
                  Please verify your character not accept number!
                </div>
              )}

              {/* Your existing code */}
              <div style={{ padding: "2px 15px" }}>
                {console.log(obj)}
                {obj.value.properties.map((parent, parentIndex) => (
                  <div key={parentIndex} className="d-flex flex-column">
                    {console.log(parentIndex)}
                    <label className="me-2">Name:</label>
                    <div className="d-flex align-items-center">
                      <input
                        type="text"
                        name="child"
                        className="form-control formpadd me-2 my-1"
                        value={parent.name}
                        readOnly={obj.uivalid.read_only === "true"}
                        maxLength={obj.uivalid.max_length}
                        onChange={(e) =>
                          formChangefn(
                            e.target.value,
                            obj.index,
                            obj.name,
                            obj.type,
                            obj.id,
                            false,
                            obj,
                            parentIndex,
                            null,
                            "name"
                          )
                        }
                        onBlur={(e) =>
                          validationfn(
                            e.target.value,
                            obj.index,
                            obj.name,
                            obj.type,
                            obj.id
                          )
                        }
                      />

                      {parentIndex === 0 ? (
                        <i
                          style={{
                            fontSize: "26px",
                            cursor: "pointer",
                            marginTop: "9px",
                          }}
                          className="fa-solid fa-plus"
                          onClick={() => handleAddParentInput()}
                        ></i>
                      ) : (
                        <i
                          style={{
                            fontSize: "26px",
                            cursor: "pointer",
                            marginTop: "9px",
                          }}
                          className="fa-solid fa-minus"
                          onClick={() => handleRemoveParentInput(parentIndex)}
                        ></i>
                      )}
                    </div>
                    <div className="d-flex flex-column">
                      <label>Choices:</label>
                      {parent.choice.map((child, childIndex) => (
                        <div className="d-flex">
                          <div className="d-flex">
                            <input
                              type="text"
                              name="child"
                              className="form-control formpadd me-2 my-1"
                              value={child.value}
                              readOnly={obj.uivalid.read_only === "true"}
                              maxLength={obj.uivalid.max_length}
                              onChange={(e) =>
                                formChangefn(
                                  e.target.value,
                                  obj.index,
                                  obj.name,
                                  obj.type,
                                  obj.id,
                                  false,
                                  obj,
                                  parentIndex,
                                  childIndex,
                                  "choice"
                                )
                              }
                              onBlur={(e) =>
                                validationfn(
                                  e.target.value,
                                  obj.index,
                                  obj.name,
                                  obj.type,
                                  obj.id
                                )
                              }
                            />
                            {childIndex === 0 ? (
                              <i
                                style={{
                                  fontSize: "26px",
                                  cursor: "pointer",
                                  marginTop: "9px",
                                }}
                                className="fa-solid fa-plus"
                                onClick={() => handleAddChildInput(parentIndex)}
                              ></i>
                            ) : (
                              <i
                                style={{
                                  fontSize: "26px",
                                  cursor: "pointer",
                                  marginTop: "9px",
                                }}
                                className="fa-solid fa-minus"
                                onClick={() =>
                                  handleRemoveChildInput(
                                    parentIndex,
                                    childIndex
                                  )
                                }
                              ></i>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
          {obj.type === "key_value" && obj.value.properties.length > 0 ? (
            <div className="form-group objpdg">
              {console.log(obj)}
              {obj.uivalid.mandatory === "true" &&
                !keyValueJson.properties.some((obj) =>
                  Object.values(obj).includes("")
                ) && <i className="fa fa-asterisk mndtryfalse"></i>}
              {obj.uivalid.mandatory === "true" &&
                keyValueJson.properties.some((obj) =>
                  Object.values(obj).includes("")
                ) && <i className="fa fa-asterisk mndtrytrue"></i>}

              <span
                id={"tiptar" + obj.index}
                className="field_hd"
                onTouchStart={(e) => {
                  setTimeout(() => {
                    e.preventDefault();
                    m_rid.current = obj.id;
                    setContextMenu(2, e.touches[0].pageX, e.touches[0].pageY);
                  }, 100);
                }}
                onContextMenu={(e) => {
                  e.preventDefault();
                  m_rid.current = obj.id;
                  if (isSub.current) {
                    let parent = document
                      .getElementById(form_id)
                      .getBoundingClientRect();
                    setContextMenu(
                      e.button,
                      e.nativeEvent.clientX - parent.left,
                      e.nativeEvent.clientY - parent.top
                    );
                  } else {
                    setContextMenu(
                      e.button,
                      e.nativeEvent.pageX,
                      e.nativeEvent.pageY
                    );
                  }
                }}
              // data-bs-custom-class="tooltip"
              // data-bs-toggle="tooltip" data-bs-placement="left" title={"First letter should be lowercase \n no special character will be accepted except _ "}
              >
                {console.log(obj)}
                {obj.label.name}
              </span>
              {obj.label.showTooltip === "true" && (
                <UncontrolledTooltip
                  arrowClassName="tip  "
                  className="tip "
                  innerClassName="tip text-dark text-nowrap"
                  target={"tiptar" + obj.index}
                  placement="right"
                >
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: "small",
                      textAlign: "start",
                    }}
                  >
                    {obj.label.tooltip}
                  </pre>
                </UncontrolledTooltip>
              )}
              {obj.label.showHelper === "true" && (
                <div className="alert alert-warning">
                  <pre
                    style={{
                      fontFamily: "sans-serif",
                      fontSize: 14,
                      fontWeight: "bold",
                      color: "black",
                    }}
                  >
                    {obj.label.helper}
                  </pre>
                </div>
              )}

              {obj.verified === "unverified" && (
                <div
                  className="alert alert-danger"
                  role="alert"
                  style={{
                    padding: "0.2rem 0.2rem",
                    marginBottom: "0px",
                  }}
                >
                  Please verify your character not accept number!
                </div>
              )}

              <div style={{ padding: "2px 15px" }}>
                {obj.value.properties.map((data, index) => (
                  <div key={index} className="d-flex flex-column">
                    <div className="d-flex flex-column">
                      <div className="field_hd text-capitalize">
                        {data.name}:
                      </div>
                      <div className="d-flex">
                        {console.log(data)}
                        {data.choice &&
                          data.choice.map((d, ind) => (
                            <div
                              className="form-control my-2 ms-1 "
                              style={{ width: "auto", fontSize: "18px" }}
                            >
                              <label key={d.value}>
                                {console.log(
                                  keyValueJson,
                                  keyValueJson.properties[index],
                                  keyValueJson.properties[index].value,
                                  d.value,
                                  keyValueJson.properties[index] &&
                                  keyValueJson.properties[index].value ===
                                  d.value
                                )}
                                <input
                                  className="me-2"
                                  type="checkbox"
                                  checked={
                                    keyValueJson.properties[index] &&
                                    keyValueJson.properties[index].value ===
                                    d.value
                                  }
                                  value={d.value}
                                  readOnly={obj.uivalid.read_only === "true"}
                                  maxLength={obj.uivalid.max_length}
                                  onChange={(e) =>
                                    formChangefn(
                                      e.target.value,
                                      obj.index,
                                      obj.name,
                                      obj.type,
                                      obj.id,
                                      false,
                                      obj,
                                      index,
                                      ind,
                                      data.name
                                    )
                                  }
                                  onBlur={(e) =>
                                    validationfn(
                                      e.target.value,
                                      obj.index,
                                      obj.name,
                                      obj.type,
                                      obj.id
                                    )
                                  }
                                />
                                {d.value}
                              </label>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default FormInnerCompo;

{
  /* {showSuggestion &&
                    searchrcd.map((objj, i) => (
                      <div key={i} style={{ overflowY: "auto" }}>
                        {objj.show && (
                          <ListGroup.Item
                            tabIndex={i}
                            className="lg"
                            ref={(ref) => (listRef.current[i] = ref)}
                            id={i}
                            onClick={() => {
                              document.getElementById("script").focus();
                              let newVal = obj.value.split(" ");
                              let leng = newVal.length - 1;
                              let newVal2 = newVal[leng].split(".");
                              let leng2 = newVal2.length - 1;
                              let newValue = "";
                              console.log("length :" + leng2);
                              console.log(
                                "newValll : " + JSON.stringify(newVal)
                              );
                              console.log(
                                "newValll22 : " + JSON.stringify(newVal2)
                              );

                              if (leng2 > 0) {
                                if (newVal2[leng2].length === 0) {
                                  newValue = obj.value + objj.name;
                                } else {
                                  console.log("checking");
                                  // newValue = obj.value.replace(
                                  //   newVal2[leng2],
                                  //   objj.name
                                  // );
                                  for (let i = 0; i <= leng; i++) {
                                    console.log(
                                      "checkkkkkk1212211221" +
                                      " :" +
                                      i +
                                      ": " +
                                      newVal[i]
                                    );
                                    if (i === leng) {
                                      let newval3 = newVal[i].split(".");
                                      newValue += newval3[0] + ".";

                                      newValue += objj.name;
                                    } else {
                                      newValue += newVal[i] + " ";
                                    }
                                  }
                                }
                                setCon(con + 1);
                              } else {
                                if (leng === 0) {
                                  newValue = obj.value.replace(
                                    newVal[leng],
                                    objj.name
                                  );
                                } else {
                                  console.log("kkkkkkkkkkkkkkkkkk : " + leng);
                                  //  newVal[leng]=objj.name;
                                  for (let i = 0; i <= leng; i++) {
                                    console.log(
                                      "checkkkkkk1212211221  " +
                                      " :" +
                                      i +
                                      ": " +
                                      newVal[i]
                                    );
                                    if (i === leng) {
                                      newValue += objj.name;
                                    } else {
                                      newValue += newVal[i] + " ";
                                    }
                                  }
                                }
                              }

                              formChangefn(
                                newValue,
                                obj.index,
                                obj.name,
                                obj.type,
                                obj.id,
                                false
                              );
                              let temp = [...recordSuggestion];
                              for (let i = 0; i < temp.length; i++) {
                                if (i === 0) {
                                  temp[i].show = false;
                                }
                                if (i !== 0) {
                                  temp[i].show = false;
                                }
                              }
                              setSearchrcd([...temp]);
                              setShowSuggestion(false);
                            }}
                            // onMouseEnter={()=>{
                            //   setActiveSuggestion("get")

                            // }}
                            onKeyDown={(e) => {
                              let resultList =
                                document.getElementsByClassName("lg");
                              // console.log("done");
                              if (e.key === "ArrowDown") {
                                e.preventDefault();
                                for (let i = 0; i < resultList.length; i++) {
                                  if (
                                    document.activeElement ===
                                    resultList.item(i) &&
                                    i < resultList.length - 1
                                  ) {
                                    console.log("jjjj" + i);
                                    resultList.item(i + 1).focus();
                                    break;
                                  }
                                }
                              }
                              if (e.key === "ArrowUp") {
                                console.log("arrowUp");
                                e.preventDefault();
                                if (
                                  document.activeElement === resultList.item(0)
                                ) {
                                  let searchInput =
                                    document.getElementById("script");
                                  searchInput.focus();
                                } else {
                                  for (let i = 0; i < resultList.length; i++) {
                                    console.log(
                                      "jjjjkkkkkkkkUPUPUP" + resultList.length
                                    );
                                    if (
                                      document.activeElement ===
                                      resultList.item(i)
                                    ) {
                                      console.log("jjjjupupupupup" + i);
                                      resultList.item(i - 1).focus();
                                      break;
                                    }
                                  }
                                }
                              }
                            }}
                            onKeyUp={(e) => {
                              e.preventDefault();
                              if (e.key === "Enter") {
                                console.log("checkinggga");

                                let newVal = obj.value.split(" ");
                                let leng = newVal.length - 1;
                                let newVal2 = newVal[leng].split(".");
                                let leng2 = newVal2.length - 1;
                                let newValue = "";
                                console.log("length :" + leng2);
                                console.log(
                                  "newValll : " + JSON.stringify(newVal)
                                );
                                console.log(
                                  "newValll22 : " + JSON.stringify(newVal2)
                                );

                                if (leng2 > 0) {
                                  if (newVal2[leng2].length === 0) {
                                    newValue = obj.value + objj.name;
                                  } else {
                                    console.log("checking");
                                    for (let i = 0; i <= leng; i++) {
                                      console.log(
                                        "checkkkkkk1212211221  " +
                                        " :" +
                                        i +
                                        ": " +
                                        newVal[i]
                                      );
                                      if (i === leng) {
                                        let newval3 = newVal[i].split(".");
                                        newValue += newval3[0] + ".";

                                        newValue += objj.name;
                                      } else {
                                        newValue += newVal[i] + " ";
                                      }
                                    }
                                  }
                                  setCon(con + 1);
                                } else {
                                  if (leng === 0) {
                                    newValue = obj.value.replace(
                                      newVal[leng],
                                      objj.name
                                    );
                                  } else {
                                    console.log("kkkkkkkkkkkkkkkkkk : " + leng);
                                    for (let i = 0; i <= leng; i++) {
                                      console.log(
                                        "checkkkkkk1212211221  " +
                                        " :" +
                                        i +
                                        ": " +
                                        newVal[i]
                                      );
                                      if (i === leng) {
                                        newValue += objj.name;
                                      } else {
                                        newValue += newVal[i] + " ";
                                      }
                                    }
                                  }
                                }

                                formChangefn(
                                  newValue,
                                  obj.index,
                                  obj.name,
                                  obj.type,
                                  obj.id,
                                  false
                                );
                                let temp = [...recordSuggestion];
                                for (let i = 0; i < temp.length; i++) {
                                  if (i === 0) {
                                    temp[i].show = false;
                                  }
                                  if (i !== 0) {
                                    temp[i].show = false;
                                  }
                                }
                                setSearchrcd([...temp]);
                                setShowSuggestion(false);
                                document.getElementById("script").focus();
                              }
                            }}
                            onFocus={() => {
                              console.log(
                                "focuseddddddd : " + document.activeElement.id
                              );
                            }}
                          >
                            {objj.name}
                          </ListGroup.Item>
                        )}
                      </div>
                    ))} */
}
