import React, { Component, useContext, useEffect, useState } from "react";
import axios from "axios";
import WorkInProgress from "./WorkInProgress";
import { AutoPortalContext } from "../Context";
import { useLocation, useNavigate } from "react-router-dom";
import ApForm from "../HelperClasses/ApForm";

const AppRequestCompo = () => {
  const { loca } = useContext(AutoPortalContext);
  const { type } = useLocation().state || {};

  const [record, setRecord] = useState([]);
  const [formrecord, setFormRecord] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page_error, setPage_error] = useState(false);
  const [error, setError] = useState();
  const [page_message, setpage_message] = useState(false);
  const [message, setMessage] = useState();
  const [ap_form, setap_form] = useState();
  const [ap_user, setap_user] = useState();
  const [uiScript, setUiScript] = useState([]);

  useEffect(() => {
    getInitialRecord();
  }, []);

  function getInitialRecord() {
    axios

      .get(loca + "/lom/newapp/req", {
        headers: {},
      })
      .then((resp) => {
        const mltpgrecord = resp.data;
        console.log(resp.data);
        if ("Error" in mltpgrecord) {
          setLoading(false);
          setPage_error(true);
          setError(mltpgrecord.Error);
        } else {
          var mmm = mltpgrecord.formRecord[2].record;
          var script = mltpgrecord.formRecord[3].uiscript;
          console.log(script);
          for (var i = 0; i < mmm.length; i++) {
            mmm[i].verified = "initial";
          }

          console.log("kkkk");
          setLoading(false);
          setUiScript(script);
          setRecord(mmm);
          setFormRecord(mltpgrecord);
          console.log(mltpgrecord);

          setLoading(false);
        }
      });
  }

  const navigation = useNavigate();

  const logincall = () => {
    navigation("/login");
  };

  // const onChange = (func, val) => {
  //   console.log(func,ap_form);
  //   let fn = new Function(["ap_user", "ap_form", "val"], func);
  //   fn(ap_user, ap_form, val);
  //   console.log(fn);
  // };

  const getRegisterRecord = () => {
    setLoading(true);
    axios

      .get(loca + "/lom/registration/" + type, {
        headers: {},
      })
      .then((resp) => {
        const mltpgrecord = resp.data;
        console.log(resp.data);
        console.log("registerrrrrr " + JSON.stringify(mltpgrecord));
        if ("Error" in mltpgrecord) {
          setLoading(false);
          setPage_error(true);
          setError(mltpgrecord.Error);
        } else {
          var mmm = mltpgrecord.formRecord[2].record;
          var script = mltpgrecord.formRecord[3].uiscript;
          console.log(script);
          for (var i = 0; i < mmm.length; i++) {
            mmm[i].verified = "initial";
          }
          console.log("kkkk");
          setLoading(false);
          setUiScript(script);
          setRecord(mmm);
          setFormRecord(mltpgrecord);
          console.log(mltpgrecord);

          setap_form(new ApForm(mmm, setRecord, {}, null));
        }
        setLoading(false);
      });
  };

  const fieldverify = (type, vl) => {
    console.log(type);
    if (type === "email") {
      if (/^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }
    if (type === "String") {
      if (/[a-zA-Z0-9]/g.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }

    if (type === "int") {
      console.log("verify int");
      if (/^[0-9]*[1-9][0-9]*$/.test(vl)) {
        return "verified";
      } else {
        return "unverified";
      }
    }
    if (type === "choice" || type === "table_lookup") {
      if (vl !== "None") {
        return "verified";
      } else {
        return "unverified";
      }
    }
    if (type === "date") {
      return "verified";
    }
  };

  const validationfn = (vl, index, ob) => {
    var formrecord = record;
    var minLength = formrecord[index].uivalid.min_length;
    if (minLength !== 0 && vl.length < minLength) {
      formrecord[index].verified = "unverified";
    } else {
      if (formrecord[index].name === ob) {
        if (vl !== "") {
          formrecord[index].verified = fieldverify(formrecord[index].type, vl);
        } else {
          formrecord[index].verified = "initial";
        }
      }
    }
    setRecord(formrecord);
  };

  const onChange = (func, val, obj) => {
    console.log(func, ap_form, val);
    let fn = new Function(["ap_user", "ap_form", "val", "obj"], func);
    fn(ap_user, ap_form, val, obj);
    console.log(fn);
  };

  const formChangefn = (vl, index, ob, obj) => {
    var frecord = record;
    /* if (frecord[index].name === ob) {
      frecord[index].value = vl;
    }
    setRecord([...frecord]); */
    console.log(vl, index, ob, obj);
    if (frecord[index].name === ob) {
      if (frecord[index].type === "String" && vl != "") {
        console.log(frecord[index].validation);
        if (frecord[index].validation === "number") {
          if (/^[0-9]*$/.test(vl)) {
            frecord[index].value = vl;
            setRecord([...frecord]);
          }
        } else if (frecord[index].validation === "character") {
          if (/^[a-zA-Z\s]+$/.test(vl)) {
            frecord[index].value = vl;
            setRecord([...frecord]);
          }
        } else if (frecord[index].validation === "withoutSpecialCharacter") {
          if (/^[_A-z0-9\s]*((-|\s)*[_A-z0-9])*$/.test(vl)) {
            frecord[index].value = vl;
            setRecord([...frecord]);
          }
        } else if (frecord[index].validation === "zipCode") {
          if (/^[0-9]{5}(?:-[0-9]{4})?$/.test(vl)) {
            frecord[index].value = vl;
            setRecord([...frecord]);
          }
        } else if (frecord[index].validation === "decimal") {
          if (/^\d*\.?\d*$/.test(vl)) {
            frecord[index].value = vl;
            setRecord([...frecord]);
          }
        } else if (frecord[index].validation === "ipAddress") {
          if (/((([0-9a-fA-F]){1,4})\\:){7}([0-9a-fA-F]){1,4}$/.test(vl)) {
            frecord[index].value = vl;
            setRecord([...frecord]);
            //Ipv4 = (([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])\\.){3}([0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-4][0-9]|25[0-5])
            //Ipv6 = ((([0-9a-fA-F]){1,4})\\:){7}([0-9a-fA-F]){1,4}
          }
        } else {
          console.log("its work");
          // if (/^[a-zA-Z0-9_\s]*$/.test(vl)) {
          frecord[index].value = vl;
          setRecord([...frecord]);
          // }
        }
      } else {
        console.log(index, vl);
        frecord[index].value = vl;
        setRecord([...frecord]);
      }
    }

    for (let i = 0; i < uiScript.length; i++) {
      let field = uiScript[i].field.name;
      console.log(field, ob);
      let func = uiScript[i].script;
      let type = uiScript[i].type;
      console.log(type);
      if (field === ob && type === "onchange") {
        console.log("runnner");
        onChange(func, vl, obj);
      }
    }
  };

  const requestFrApp = () => {
    var rcd = record;
    setError("");
    setPage_error(false);
    setMessage("");
    setpage_message(false);
    console.log(rcd);
    var mandatory = [];
    var unverified = [];
    for (var i = 0; i < rcd.length; i++) {
      if (rcd[i].uivalid.visible === "true") {
        if (rcd[i].uivalid.mandatory === "true") {
          if (rcd[i].value === "" || rcd[i].value === "None") {
            mandatory.push(rcd[i].name);
          }
        }
        if (
          rcd[i].type === "int" ||
          rcd[i].type === "String" ||
          rcd[i].type === "email" ||
          rcd[i].type === "date"
        ) {
          var veri = fieldverify(rcd[i].type, rcd[i].value);
          if (veri === "unverified") {
            unverified.push(rcd[i].name);
          }
        }
      }
    }
    if (mandatory.length === 0 && unverified.length === 0) {
      var frcd = formrecord;
      frcd.formRecord[2].record = record;
      setLoading(true);
      axios
        .post(loca + "/lom/newapp/set/req", frcd, {
          headers: {},
        })
        .then((resp) => {
          var registerrcd = resp.data;
          console.log("registerrrrrrrrr " + JSON.stringify(registerrcd));
          console.log("inn");
          if ("Error" in registerrcd) {
            setPage_error(true);
            setError(registerrcd.Error);
            setLoading(false);
          } else {
            setpage_message(true);
            setMessage("Thanks for the request ! We will connect you soon");

            setLoading(false);
          }
        });
    } else {
      console.log("mandatory" + JSON.stringify(mandatory));
      setPage_error(true);
      setError(" Check Mandatory fields not set:" + mandatory);
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  };

  return (
    <div className="d-flex justify-content-center">
      {loading === true ? (
        <WorkInProgress />
      ) : (
        <div className="maincss bg-auth-1 p-2 mb-5">
          <div onClick={logincall} className="cursor-pointer">
            <i
              className="fa fa-arrow-left"
              style={{
                color: "white",
                marginTop: "6px",
                marginRight: "4px",
                cursor: "pointer",
              }}
            ></i>
            <span>Back</span>
          </div>
          <div className="useric">
            <i
              className="fa fa-user"
              
              style={{
                border: "2.5px solid white",
                height: "4.5rem",
                width: "4.5rem",
                fontSize: "62px",
                textAlign: "center",
                borderRadius: "39px",
              }}
            ></i>
          </div>

          <div>
            <h2 className="text-center">Request For New Application</h2>
          </div>
          <hr />
          {page_error === true && (
            <div
              className="alert alert-danger"
              role="alert"
              style={{
                padding: "0.2rem 0.2rem",
                marginBottom: "0px",
              }}
            >
              {error}
            </div>
          )}
          {page_message === true && (
            <div
              className="alert alert-success"
              role="alert"
              style={{
                padding: "0.2rem 0.2rem",
                marginBottom: "0px",
              }}
            >
              {message}
            </div>
          )}
          {record.length === 0 && (
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          )}
          <div className="d-gridd px-3">
            {record.map(
              (obj, index) =>
                obj.uivalid.visible === "true" && (
                  <div key={index} className=" dblock">
                    <div className="inppd ">
                      {obj.type === "String" ? (
                        <div className="form-group   objpdg ">
                          {obj.uivalid.mandatory === "true" &&
                            obj.value !== "" && (
                              <i
                                className="fa fa-asterisk mndtryfalse"
                                
                              ></i>
                            )}

                          {obj.uivalid.mandatory === "true" &&
                            obj.value === "" && (
                              <i
                                className="fa fa-asterisk mndtrytrue"
                                
                              ></i>
                            )}
                          <span className="txt-white">{obj.label}</span>

                          <input
                            type="text"
                            className="inpptt"
                            value={obj.value}
                            maxLength={obj.uivalid.max_length}
                            onChange={(e) => {
                              formChangefn(e.target.value, index, obj.name);
                            }}
                            onMouseOut={(e) =>
                              validationfn(e.target.value, index, obj.name)
                            }
                          ></input>
                        </div>
                      ) : null}

                      {obj.type === "int" ? (
                        <div className="form-group">
                          {obj.verified === "unverified" && (
                            <div
                              className="alert alert-danger"
                              role="alert"
                              style={{
                                padding: "0.2rem 0.2rem",
                                marginBottom: "0px",
                              }}
                            >
                              Please verify your integer number!
                            </div>
                          )}

                          {obj.uivalid.mandatory === "true" &&
                            obj.value !== "" && (
                              // <span className="mndtryfals">*</span>
                              <i
                                className="fa fa-asterisk mndtryfalse"
                                
                              ></i>
                            )}
                          {obj.uivalid.mandatory === "true" &&
                            obj.value === "" && (
                              // <i className="mndtrytru ">*</i>
                              <i
                                className="fa fa-asterisk mndtrytrue"
                                
                              ></i>
                            )}
                          <span className="txt-white">{obj.label}</span>
                          <input
                            type="text"
                            className="inpptt"
                            value={obj.value}
                            maxLength={obj.uivalid.max_length}
                            // readOnly={obj.uivalid.read_only === "true"}
                            onChange={(e) =>
                              formChangefn(e.target.value, index, obj.name)
                            }
                            onMouseOut={(e) =>
                              validationfn(e.target.value, index, obj.name)
                            }
                          ></input>
                        </div>
                      ) : null}

                      {obj.type === "email" ? (
                        <div className=" emailpadd form-group">
                          {obj.verified === "unverified" && (
                            <div
                              className="alert alert-danger"
                              role="alert"
                              style={{
                                padding: "0.2rem 0.2rem",
                                marginBottom: "0px",
                              }}
                            >
                              please verify your email
                            </div>
                          )}
                          {obj.uivalid.mandatory === "true" &&
                            obj.value !== "" && (
                              <i
                                className="fa fa-asterisk mndtryfalse"
                                
                              ></i>
                            )}

                          {obj.uivalid.mandatory === "true" &&
                            obj.value === "" && (
                              <i
                                className="fa fa-asterisk mndtrytrue"
                                
                              ></i>
                            )}
                          <span className="txt-white">{obj.label}</span>
                          <input
                            type="email"
                            className={
                              obj.verified === "unverified"
                                ? "form-control formpadd_danger"
                                : "form-control formpadd"
                            }
                            value={obj.value}
                            maxLength={obj.uivalid.max_length}
                            readOnly={obj.uivalid.read_only === "true"}
                            onChange={(e) =>
                              formChangefn(e.target.value, index, obj.name)
                            }
                            onMouseOut={(e) =>
                              validationfn(e.target.value, index, obj.name)
                            }
                          ></input>
                        </div>
                      ) : null}
                      {obj.type === "choice" ? (
                        <div className="form-group ">
                          {obj.uivalid.mandatory === "true" &&
                            obj.value !== "None" &&
                            obj.value !== "" && (
                              <i
                                className="fa fa-asterisk mndtryfalse"
                                
                              ></i>
                            )}

                          {obj.uivalid.mandatory === "true" &&
                            (obj.value === "None" || obj.value === "") && (
                              <i
                                className="fa fa-asterisk mndtrytrue"
                                
                              ></i>
                            )}
                          <span className="txt-white">{obj.label}</span>
                          <select
                            value={obj.value}
                            className="inpptt-select "
                            aria-label="Default select example"
                            onMouseOut={(e) =>
                              validationfn(e.target.value, index, obj.name)
                            }
                            onChange={(e) =>
                              formChangefn(e.target.value, index, obj.name)
                            }
                            maxLength={obj.uivalid.max_length}
                            readOnly={obj.uivalid.read_only === "true"}
                          >
                            <option value="None">None</option>
                            {obj.choice.map((ch, chi) => (
                              <option key={chi} value={ch.value}>
                                {ch.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      ) : null}

                      {obj.type === "date" ? (
                        <div className=" form-group">
                          {obj.verified === "unverified" && (
                            <div
                              className={
                                obj.verified === "unverified"
                                  ? "form-control formpadd_danger"
                                  : "form-control formpadd"
                              }
                              role="alert"
                              style={{
                                padding: "0.2rem 0.2rem",
                                marginBottom: "0px",
                              }}
                            >
                              please verify your date of birth!
                            </div>
                          )}
                          {obj.uivalid.mandatory === "true" &&
                            obj.value !== "None" &&
                            obj.value !== "" && (
                              <i
                                className="fa fa-asterisk mndtryfalse"
                                
                              ></i>
                            )}

                          {obj.uivalid.mandatory === "true" &&
                            (obj.value === "None" || obj.value === "") && (
                              <isetRecord
                                className="fa fa-asterisk mndtrytrue"
                                
                              ></isetRecord>
                            )}
                          <span className="txt-white">{obj.label}</span>

                          <input
                            type="date"
                            className="form-control formpadd"
                            value={obj.value}
                            maxLength={obj.uivalid.max_length}
                            readOnly={obj.uivalid.read_only === "true"}
                            onChange={(e) =>
                              formChangefn(e.target.value, index, obj.name)
                            }
                            onMouseOut={(e) =>
                              validationfn(e.target.value, index, obj.name)
                            }
                          ></input>
                        </div>
                      ) : null}
                      {obj.type === "boolean" ? (
                        <div className="form-check">
                          <span className="txt-white">{obj.label}</span>
                          <input
                            type="checkbox"
                            className="checkpadd"
                            // className="form-control checkpadd"
                            maxLength={obj.uivalid.max_length}
                            value={obj.value === "true" ? true : false}
                            readOnly={obj.uivalid.read_only === "true"}
                            onChange={(e) =>
                              formChangefn(e.target.value, index, obj.name)
                            }
                            onMouseOut={(e) =>
                              validationfn(e.target.value, index, obj.name)
                            }
                          ></input>
                        </div>
                      ) : null}

                      {obj.type === "table_lookup" ? (
                        <div className="form-group ">
                          {obj.uivalid.mandatory === "true" &&
                            obj.value !== "None" &&
                            obj.value !== "" && (
                              <i
                                className="fa fa-asterisk mndtryfalse"
                                
                              ></i>
                            )}

                          {obj.uivalid.mandatory === "true" &&
                            (obj.value === "None" || obj.value === "") && (
                              <i
                                className="fa fa-asterisk mndtrytrue"
                                
                              ></i>
                            )}
                          <span className="txt-white">{obj.label}</span>
                          {console.log(obj)}
                          <select
                            className="form-control form-select formpadd "
                            aria-label="Default select example"
                            onMouseOut={(e) =>
                              validationfn(e.target.value, index, obj.name)
                            }
                            onChange={(e) =>
                              formChangefn(e.target.value, index, obj.name)
                            }
                            maxLength={obj.uivalid.max_length}
                            readOnly={obj.uivalid.read_only === "true"}
                            value={obj.value}
                          >
                            <option value="None">None</option>
                            {console.log(obj.lookup)}
                            {obj.lookup.map((ch, chi) => (
                              <option key={chi} value={ch.name}>
                                {ch.value}
                              </option>
                            ))}
                          </select>
                        </div>
                      ) : null}

                      {obj.type === "script" ||
                      obj.type === "long_description" ? (
                        <div className=" form-group objpdg ">
                          {obj.uivalid.mandatory === "true" &&
                            obj.value !== "" && (
                              <i
                                className="fa fa-asterisk mndtryfalse"
                                
                              ></i>
                            )}

                          {obj.uivalid.mandatory === "true" &&
                            obj.value === "" && (
                              <i
                                className="fa fa-asterisk mndtrytrue"
                                
                              ></i>
                            )}
                          <span className="txt-white">{obj.label}</span>

                          {obj.verified === "unverified" && (
                            <div
                              className="alert alert-danger"
                              role="alert"
                              style={{
                                padding: "0.2rem 0.2rem",
                                marginBottom: "0px",
                              }}
                            >
                              Please verify your charecter not accept number!
                            </div>
                          )}
                          <div>
                            <textarea
                              id={"script" + obj.index}
                              type="text"
                              className={
                                obj.verified === "unverified"
                                  ? "form-control formpadd_danger unverifi"
                                  : "form-control formpadd "
                              }
                              spellCheck={false}
                              style={{
                                borderRadius: 0,
                                borderTopWidth: 0,
                                borderBottomWidth: 0,
                              }}
                              value={obj.value}
                              disabled={obj.uivalid.read_only === "true"}
                              maxLength={obj.uivalid.max_length}
                              onChange={(e) => {
                                console.log("gggg");
                                formChangefn(e.target.value, index, obj.name);
                              }}
                              onMouseOut={(e) =>
                                validationfn(e.target.value, index, obj.name)
                              }
                            ></textarea>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                )
            )}
          </div>
          <div className="text-center my-3">
            <button
              className="btn btn-warning"
              onClick={requestFrApp}
            >
              Request
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AppRequestCompo;
