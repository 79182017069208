import React, { useContext, useEffect, useRef, useState } from "react";
import WorkInProgress from "./WorkInProgress";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { AutoPortalContext } from "../Context";

const KnowledgeBase = () => {
  // Base Hooks
  const { loca, token } = useContext(AutoPortalContext);
  const navigation = useNavigate();
  const location = useLocation().state;
  const [searchParam, setSearchParam] = useSearchParams();

  // UseStates
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(true);
  const [pageError, setPageError] = useState(true);
  const [title, setTitle] = useState("");
  const [selected, setSelected] = useState("");
  const [categories, setCategories] = useState([]);
  const [knowledgeBases, setKnowledgeBases] = useState([]);
  const [html, setHtml] = useState("");

  // UseRefs
  const tableName = useRef("");

  // Functions
  const getKnowledgeBase = () => {
    // tableName.current = searchParam.get("tableName");
    // console.log(tableName.current);
    // console.log(token);
    // setLoading(true);
    // if (tableName.current) {
    axios
      .get(loca + "/lom/get/knowledgeBase", {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          const rvrecord = resp.data.knowledgeBase;
          console.log(rvrecord);
          if ("Error" in rvrecord) {
            setPageError(true);
            setError(rvrecord.error);
            setLoading(false);
          } else {
            setHtml(rvrecord.html);
            setCategories(rvrecord)
            setTitle(rvrecord.title);
            setLoading(false);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    // } else {
    //   toast.error("Table Name not Found");
    //   setLoading(false);
    //   return;
    // }
  };

  const selectFn = (e) => {
    setSelected(e);
    callKnowledge(e);
  };

  const callKnowledge = (name) => {
    navigation("/knowledgeCategory", { state: { value: name } })
  };

  // UseEffects
  useEffect(() => {
    getKnowledgeBase();
  }, []);

  if (!loading) {
    return (
      <div className="pagesetup">
        <div className="card p-3" style={{ display: "flex" }}>
          <div className="container justify-content-center">
            <select
              onChange={(e) => {
                selectFn(e.target.value);
              }}
            >
              <option value={""}>{"All"}</option>
              {categories.length > 0 &&
                categories.map((obj, ind) => (
                  <option key={ind} value={obj.value}>
                    {obj.name}
                  </option>
                ))}
            </select>
          </div>
          <div >
            <h2 className="m-4">Knowledge Bases</h2>
          </div>
          {categories.length > 0 &&
            categories.map((obj, ind) => (
              <div className="card w-75" onClick={() => callKnowledge(obj.name)} style={{ cursor: "pointer" }}>
                <div className="card-body">
                  <h5 className="card-title" key={ind} value={obj.value}>{obj.name}</h5>
                  <p className="card-text">{obj.articleSize + " Articles"} </p>
                </div>
              </div>
            ))}
          {/* <div className="mt-4 card card-body">
          {knowledgeBases.length > 0 &&
            knowledgeBases.map((obj, ind) => <div key={ind}></div>)}
        </div> */}
        </div>
      </div >
    );
  } else {
    return <WorkInProgress />;
  }
};

export default KnowledgeBase;
