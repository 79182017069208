import axios from "axios";
import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { AutoPortalContext } from "../Context";
import WorkInProgress from "./WorkInProgress";
import { toast } from "react-toastify";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRef } from "react";
import "../css/filterCompo.css";
import "../css/FormCSS.css";
import "../css/ApplicationSetUp.css";
import "../css/ListComponent.css";
import "../css/RoleSelectionComponent.css";
import { Button, Modal } from "react-bootstrap";
import Header from "./Header";

const ApplicationSetup = () => {
  const navigation = useNavigate();
  const { loca, token, getVerify } = useContext(AutoPortalContext);

  let height = "90%";

  const [cls_all, setCls_all] = useState(true);
  const [record, setRecord] = useState([]); //{app:    rcd}
  const [rcd, setRcd] = useState([]);
  const [mainRcd, setMainRcd] = useState();
  const [loading, setLoading] = useState(true);
  const [isProcessing, setProcessing] = useState(false);
  // const [appRole, setAppRole ] = useState();
  const [allRoles, setAllRoles] = useState([]);
  const [searchval, setSearchVal] = useState([]);
  const [appn, setAppn] = useState();
  const [fvalue, setFvalue] = useState([{ id: 0, name: "" }]);
  const [showPref, setShowPref] = useState(false);
  const [error, setError] = useState("");
  // const [noOfRolesInput, setNoOfRolesInput] = useState([1]);
  const [searchParam, setSearchParam] = useSearchParams();
  const [srch, setSrch] = useState(true);
  const [srchVal, setSrchVal] = useState("");
  const [srchRole, setSrchRole] = useState([]);
  const [isClick, setIsClick] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const parentApp = useRef("");
  const appRole = useRef();
  const inputIndex = useRef(0);
  const topIp = useRef([]);
  const outerCont = useRef([]);

  const getInitialRcd = (app) => {
    console.log("approleid", appRole);
    console.log("cuurrr ", appRole.current);
    console.log(app, parentApp);
    setAppn(app);
    let vl = appRole.current;
    let url = ``;
    let header;
    if (
      (app === "None" || app === "autoportal") &&
      parentApp.current !== "Marketplace"
    ) {
      url = `${loca}/lom/get/pack/${1}`;
      header = {
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
      };
    } else {
      url = `http://localhost:8084/marketplace/get/pack/${1}`;
      header = {
        "Content-Type": "application/json",
        authorization: "Bearer " + token,
        "market-application": "Marketplace",
      };
    }
    if (appRole.current !== undefined && appRole.current !== "0") {
      axios
        .get(url, {
          headers: header,
        })
        .then(
          (resp) => {
            let modul = resp.data;
            console.log(modul);
            let arrObj = [];
            for (var m = 0; m < modul.appBasedModule.length; m++) {
              let app = modul.appBasedModule[m].applicatioMenu[0].application;
              console.log(
                modul.appBasedModule[m].applicatioMenu[1].records.length
              );
              var lstt = [];
              for (
                var k = 0;
                k < modul.appBasedModule[m].applicatioMenu[1].records.length;
                k++
              ) {
                for (
                  var j = 0;
                  j <
                  modul.appBasedModule[m].applicatioMenu[1].records[k].section
                    .length;
                  j++
                ) {
                  modul.appBasedModule[m].applicatioMenu[1].records[k].section[
                    j
                  ].ref = true;
                }
              }
              for (
                var i = 0;
                i < modul.appBasedModule[m].applicatioMenu[1].records.length;
                i++
              ) {
                lstt.push({
                  application_menu:
                    modul.appBasedModule[m].applicatioMenu[1].records[i]
                      .application_menu,
                  section:
                    modul.appBasedModule[m].applicatioMenu[1].records[i]
                      .section,
                  menuId:
                    modul.appBasedModule[m].applicatioMenu[1].records[i].menuId,
                  showing:
                    modul.appBasedModule[m].applicatioMenu[1].records[i]
                      .showing,
                  ref: false,
                  ref_all: true,
                });
              }

              let obj = { app: app, show: true, rcd: lstt };
              arrObj.push(obj);
              //   setRcd(lstt);
            }
            console.log(arrObj);
            setRecord(arrObj);
            setRcd(arrObj);
            setMainRcd(modul);
            setLoading(false);
          },
          (error) => {
            navigation("/error");
            console.log(error);
          }
        );
    } else {
      // toast.error("You Don't  Have Any Application")
      setLoading(false);
    }
  };

  const getAllByRole = (key) => {
    axios
      .get(`${loca}/lom/get/allroles`, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then((resp) => {
        let modul = resp.data;
        console.log(modul, allRoles);
        allRoles.splice(0, allRoles.length);
        srchRole.splice(0, srchRole.length);
        for (var i = 0; i < modul.roleList.length; i++) {
          allRoles.push(modul.roleList[i]);
          srchRole.push(modul.roleList[i]);
        }

        console.log("first", modul, modul.roleList.length, srchRole);

        // setAppRole(modul.roleList[0].id);
        if (modul.roleList.length > 0) appRole.current = modul.roleList[0]?.id;
        else appRole.current = "0";

        let app = "autoportal";
        getInitialRcd(app);
      });
  };

  const selectedRcd = (role) => {
    console.log("aoi", role, appn);
    setLoading(true);
    let url = ``;
    if (appn === "None" || appn === "autoportal") {
      url = `${loca}/lom/get/pack/${role}`;
    } else {
      url = `http://localhost:8084/marketplace/get/pack/${role}`;
    }
    axios
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": appn,
        },
      })
      .then(
        (resp) => {
          let modul = resp.data;
          console.log(modul);
          let arrObj = [];
          for (var m = 0; m < modul.appBasedModule.length; m++) {
            let app = modul.appBasedModule[m].applicatioMenu[0].application;
            console.log(
              modul.appBasedModule[m].applicatioMenu[1].records.length
            );
            var lstt = [];
            for (
              var k = 0;
              k < modul.appBasedModule[m].applicatioMenu[1].records.length;
              k++
            ) {
              for (
                var j = 0;
                j <
                modul.appBasedModule[m].applicatioMenu[1].records[k].section
                  .length;
                j++
              ) {
                modul.appBasedModule[m].applicatioMenu[1].records[k].section[
                  j
                ].ref = true;
              }
            }
            for (
              var i = 0;
              i < modul.appBasedModule[m].applicatioMenu[1].records.length;
              i++
            ) {
              lstt.push({
                application_menu:
                  modul.appBasedModule[m].applicatioMenu[1].records[i]
                    .application_menu,
                section:
                  modul.appBasedModule[m].applicatioMenu[1].records[i].section,
                menuId:
                  modul.appBasedModule[m].applicatioMenu[1].records[i].menuId,
                showing:
                  modul.appBasedModule[m].applicatioMenu[1].records[i].showing,
                ref: false,
                ref_all: true,
              });
            }

            let obj = { app: app, show: true, rcd: lstt };
            arrObj.push(obj);
            //   setRcd(lstt);
          }
          console.log(arrObj);
          setMainRcd(modul);
          setRecord(arrObj);
          setRcd(arrObj);
          // setAppRole(role);
          appRole.current = role;
          setLoading(false);
        },
        (error) => {
          navigation("/error");
          console.log(error);
        }
      );
  };

  const setSection = (o_i, sec_i, ind, val) => {
    console.log(rcd, o_i, ind, val, sec_i);
    var shrcd = record;
    console.log(shrcd);
    shrcd[ind].rcd[o_i].section[sec_i].ref = val;
    // this.setState({ rcd: rcd });
    setRcd([...shrcd]);
  };

  const setOpen = (i, ind, rf) => {
    console.log(rf, i, ind);
    let rcddt = record;
    let rcdd = rcddt[ind].rcd;
    rcdd[i].ref = !rf;
    rcddt[ind].rcd = rcdd;
    setRecord([...rcddt]);
    console.log(record);
  };

  const closeAppMenu = () => {
    var search = rcd;
    for (var s = 0; s < rcd.length; s++) {
      search[s].ref = !cls_all;
    }
    // this.setState({ rcd: rcd, cls_all: !this.state.cls_all });
    setRcd(search);
    setCls_all(!cls_all);
  };

  const closeSection = (ind, o_i) => {
    let rcddt = record;
    var sarchrcd = rcddt[ind].rcd;
    console.log(ind, o_i);
    // console.log(sarchrcd[ind].section.length);
    for (var j = 0; j < sarchrcd[o_i].section.length; j++) {
      console.log(sarchrcd[o_i].section[j].ref, sarchrcd[o_i].ref_all);
      sarchrcd[o_i].section[j].ref = !sarchrcd[o_i].ref_all;
    }
    sarchrcd[o_i].ref_all = !sarchrcd[o_i].ref_all;
    // this.setState({
    //   rcd: rcd,
    // });
    setRcd([...sarchrcd]);
  };

  const setCheckMenu = (check, index, ind) => {
    console.log(index);
    let rcddt = record;
    let rcdd = rcddt[ind].rcd;
    let menuId = rcddt[ind].rcd[index].menuId;
    rcdd[index].showing = check;
    for (let j = 0; j < rcdd[index].section.length; j++) {
      rcdd[index].section[j].showing = check;
      for (let k = 0; k < rcdd[index].section[j].module.length; k++) {
        rcdd[index].section[j].module[k].showing = check;
      }
    }
    rcddt[ind].rcd = rcdd;
    let realRcd = rcd;
    for (let i = 0; i < realRcd.length; i++) {
      if (realRcd[i].app.id === rcddt[ind].app.id) {
        for (let j = 0; j < realRcd[i].rcd.length; j++) {
          if (realRcd[i].rcd[j].menuId === menuId) {
            realRcd[i].rcd[j].showing = check;
            console.log(
              realRcd[i].rcd[j].section,
              i,
              j,
              realRcd[i].rcd[j].section.length
            );
            for (let u = 0; u < realRcd[i].rcd[j].section.length; u++) {
              realRcd[i].rcd[j].section[u].showing = check;
              for (
                let k = 0;
                k < realRcd[i].rcd[j].section[u].module.length;
                k++
              ) {
                realRcd[i].rcd[j].section[u].module[k].showing = check;
              }
            }
          }
        }
      }
    }
    console.log(realRcd);
    setRcd([...realRcd]);
    setRecord([...rcddt]);
  };

  const setCheckSection = (check, index, menu_ind, sec_ind) => {
    // let rcddt = record;
    let rcdd = record;
    console.log(rcdd, index, menu_ind, sec_ind);
    rcdd[index].rcd[menu_ind].section[sec_ind].showing = check;
    let menuId = rcdd[index].rcd[menu_ind].menuId;
    let secId = rcdd[index].rcd[menu_ind].section[sec_ind].sectionId;

    if (check === "true") {
      rcdd[index].rcd[menu_ind].showing = check;
    } else if (check === "false") {
      let checkSec = rcdd[index].rcd[menu_ind].section.find(
        (e) => e.showing === "true" && e.sectionId !== secId
      );
      if (!checkSec) {
        rcdd[index].rcd[menu_ind].showing = check;
      }
    }

    rcdd[index].rcd[menu_ind].section[sec_ind].showing = check;

    for (
      let k = 0;
      k < rcdd[index].rcd[menu_ind].section[sec_ind].module.length;
      k++
    ) {
      rcdd[index].rcd[menu_ind].section[sec_ind].module[k].showing = check;
    }
    // let realRcd = rcd;
    // for (let i = 0; i < realRcd.length; i++) {
    //   console.log(realRcd[i]);

    //   if (realRcd[i].app.id === rcdd[index].app.id) {
    //     for (let j = 0; j < realRcd[i].rcd.length; j++) {
    //       if (realRcd[i].rcd[j].menuId === menuId) {
    //         // if (check === "true") {
    //         //   realRcd[i].rcd[j].showing = check;
    //         // }
    //         for (let u = 0; u < realRcd[i].rcd[j].section.length; u++) {
    //           if (realRcd[i].rcd[j].section[u].sectionId === secId) {
    //             console.log("kalo", secId);
    //             realRcd[i].rcd[j].section[u].showing = check;
    //             for (
    //               let k = 0;
    //               k < realRcd[i].rcd[j].section[u].module.length;
    //               k++
    //             ) {
    //               realRcd[i].rcd[j].section[u].module[k].showing = check;
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    // console.log(realRcd);
    // setRcd([...realRcd]);
    setRecord([...rcdd]);
  };

  const setCheckModule = (check, indx, menu_ind, sec_ind, mod_ind) => {
    let rcdd = record;
    let menuId = rcdd[indx].rcd[menu_ind].menuId;
    let secId = rcdd[indx].rcd[menu_ind].section[sec_ind].sectionId;
    let modId =
      rcdd[indx].rcd[menu_ind].section[sec_ind].module[mod_ind].moduleId;
    console.log(check, indx, menu_ind, sec_ind, mod_ind);
    console.log(indx);
    console.log(menu_ind);

    if (check === "false") {
      rcdd[indx].rcd[menu_ind].section[sec_ind].module[mod_ind].showing = check;

      let checkMod = rcdd[indx].rcd[menu_ind].section[sec_ind].module.find(
        (e) => e.showing === "true" && e.moduleId !== modId
      );
      if (!checkMod) {
        rcdd[indx].rcd[menu_ind].section[sec_ind].showing = check;

        let checkSec = rcdd[indx].rcd[menu_ind].section.find(
          (e) => e.showing === "true" && e.sectionId !== secId
        );
        if (!checkSec) {
          rcdd[indx].rcd[menu_ind].showing = check;
        }
      }

      // rcdd[indx].rcd[menu_ind].showing = check;
      // rcdd[indx].rcd[menu_ind].section[sec_ind].showing = check;
    } else {
      rcdd[indx].rcd[menu_ind].showing = check;
      rcdd[indx].rcd[menu_ind].section[sec_ind].showing = check;
      rcdd[indx].rcd[menu_ind].section[sec_ind].module[mod_ind].showing = check;
    }
    // let secValue = "true";
    // let flag = false;
    // for (
    //   let i = 0;
    //   i < rcdd[indx].rcd[menu_ind].section[sec_ind].module.length;
    //   i++
    // ) {
    //   if (
    //     rcdd[indx].rcd[menu_ind].section[sec_ind].module[i].showing === "false"
    //   ) {
    //     secValue = "false";
    //     break;
    //   }
    // }
    // rcdd[indx].rcd[menu_ind].section[sec_ind].showing = secValue;
    // let realRcd = rcd;
    // console.log(modId);
    // for (let i = 0; i < realRcd.length; i++) {
    //   if (realRcd[i].app.id === rcdd[indx].app.id) {
    //     for (let j = 0; j < realRcd[i].rcd.length; j++) {
    //       if (realRcd[i].rcd[j].menuId === menuId) {
    //         for (let u = 0; u < realRcd[i].rcd[j].section.length; u++) {
    //           if (realRcd[i].rcd[j].section[u].sectionId === secId) {
    //             // realRcd[i].rcd[j].section[u].showing = check;
    //             for (
    //               let k = 0;
    //               k < realRcd[i].rcd[j].section[u].module.length;
    //               k++
    //             ) {
    //               if (
    //                 realRcd[i].rcd[j].section[u].module[k].moduleId === modId
    //               ) {
    //                 console.log("klklklklklklklklklk");
    //                 if (check === "false") {
    //                   realRcd[i].rcd[j].showing = check;
    //                   realRcd[i].rcd[j].section[u].showing = check;
    //                   realRcd[i].rcd[j].section[u].module[k].showing = check;
    //                 } else {
    //                   realRcd[i].rcd[j].section[u].module[k].showing = check;
    //                 }
    //                 let secValue = "true";
    //                 let flag = false;
    //                 for (
    //                   let m = 0;
    //                   m < realRcd[i].rcd[j].section[u].module.length;
    //                   m++
    //                 ) {
    //                   if (
    //                     realRcd[i].rcd[j].section[u].module[m].showing ===
    //                     "false"
    //                   ) {
    //                     secValue = "false";
    //                     break;
    //                   }
    //                 }
    //               }

    //               // realRcd[i].rcd[j].section[u].module[k].showing = check;
    //             }
    //           }
    //         }
    //       }
    //     }
    //   }
    // }
    // console.log(realRcd);
    // setRcd([...realRcd]);
    setRecord([...rcdd]);
  };

  const submitFn = () => {
    setIsClick(true);
    setProcessing(true);
    console.log(fvalue);
    for (let j = 0; j < fvalue.length; j++) {
      if (fvalue[j].id === 0) {
        toast.error("Select Role");
        setProcessing(false);
        window.scrollTo({ top: 0 });
        return;
      }
    }

    let json = { role: fvalue, mainRcd: record };
    console.log(json);
    let url = ``;
    if (appn === "None" || appn === "autoportal") {
      url = `${loca}/lom/set/pack`;
    } else {
      url = `http://localhost:8084/marketplace/set/pack`;
    }
    axios
      .post(url, json, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": appn,
        },
      })
      .then(
        (resp) => {
          console.log(resp.data);
          getVerify();
          toast("All set", { position: "bottom-center", type: "success" });
          setProcessing(false);
        },
        (error) => {
          navigation("/error");
          setProcessing(false);
          toast(error, { position: "bottom-center", type: "error" });
          console.log(error);
        }
      )
      .finally(() => {
        console.log("finally");
        setIsClick(false);
      });
  };

  const modSearch = (nam) => {
    if (nam.length > 2) {
      var srch = JSON.parse(JSON.stringify(record));
      let xyz = [];
      for (let u = 0; u < srch.length; u++) {
        let search = srch[u].rcd;
        var abc = [];
        console.log(srch, search);
        for (var i = 0; i < search.length; i++) {
          var app_m = search[i].application_menu.toLowerCase();
          console.log(nam, search[i]);
          if (app_m.includes(nam.toLowerCase())) {
            abc.push({
              application_menu: search[i].application_menu,
              menuId: search[i].menuId,
              section: search[i].section,
              ref: true,
              ref_all: true,
              showing: search[i].showing,
            });
          } else {
            var cnt = 0;
            var mod = [];

            for (var j = 0; j < search[i].section.length; j++) {
              var sec_m = search[i].section[j].sec_label.toLowerCase();
              if (sec_m.includes(nam.toLowerCase())) {
                mod.push(search[i].section[j]);
                cnt++;
              } else {
                for (var s = 0; s < search[i].section[j].module.length; s++) {
                  // console.log(
                  //   "modules : " + JSON.stringify(search[i].section[j].module[s])
                  // );
                  var mod_m =
                    search[i].section[j].module[s].form_label.toLowerCase();
                  // console.log(mod_m);
                  if (mod_m.includes(nam.toLowerCase())) {
                    // console.log(mod_m);
                    var sec = {};
                    sec = search[i].section[j].module[s];
                    search[i].section[j].module = [];
                    search[i].section[j].module.push(sec);
                    mod.push(search[i].section[j]);
                    cnt++;
                  }
                }
              }
            }
            if (cnt > 0) {
              abc.push({
                application_menu: search[i].application_menu,
                menuId: search[i].menuId,
                section: mod,
                ref: true,
                ref_all: true,
                showing: search[i].showing,
              });
            }
          }
        }
        console.log(abc, srch[u]);
        xyz.push({ app: srch[u].app, show: true, rcd: abc });
      }
      console.log(xyz);
      setRecord([...xyz]);
    } else if (nam === "") {
      var srch = JSON.parse(JSON.stringify(rcd));
      setRecord([...srch]);
    }
  };

  const searchModule = (nam, index) => {
    // const search = allRoles;
    // document.getElementById().getBoundingClientRect().top
    console.log(outerCont.current.getBoundingClientRect().top);
    console.log(topIp.current[index]?.getBoundingClientRect().height);
    console.log(
      Number(
        topIp.current[index]?.getBoundingClientRect().top -
          outerCont.current.getBoundingClientRect().top
      )
    );
    inputIndex.current = index;
    let abc = [];
    let ID = 0;
    console.log(nam);
    if (nam.length > 2) {
      for (var i = 0; i < allRoles.length; i++) {
        let res = allRoles[i].name;
        let id = allRoles[i].id;
        if (res.toLowerCase().includes(nam.toLowerCase())) {
          abc.push({ id: id, name: res });
          setSearchVal(abc);
          ID = id;
          // break;
        }
      }
      console.log(allRoles, abc);
      console.log(searchval);
    }
    if (nam.length == 1 || nam.length == 0 || nam.length == 2) {
      console.log("lest then");
      setSearchVal("");
    }
    console.log(searchval);
    let fv = fvalue;
    fv[index].name = nam;
    fv[index].id = ID;
    setFvalue([...fv]);
  };

  const roleSearch = (nam) => {
    if (nam.length > 0) {
      setSrch(false);
      setSrchVal(nam);
    } else {
      setSrchVal("");
    }
    let abc = [];
    let ID = 0;
    if (nam.length > 2) {
      for (var i = 0; i < allRoles.length; i++) {
        let res = allRoles[i].name;
        let id = allRoles[i].id;
        if (res.toLowerCase().includes(nam.toLowerCase())) {
          abc.push({ id: id, name: res });
          setSrchRole([...abc]);
          ID = id;
        } else {
          setSrchRole([...abc]);
        }
      }
    }
    if (nam.length == 1 || nam.length == 0 || nam.length == 2) {
      setSrchRole([...allRoles]);
    }
  };

  const setRefRcd = (value, id) => {
    let fv = fvalue;
    console.log("value ", value, id, fvalue);
    fv[inputIndex.current].name = value;
    fv[inputIndex.current].id = id + "";
    setFvalue([...fvalue]);
    setSearchVal("");
  };

  const handleShow = (index) => {
    inputIndex.current = index;
    setShowPref(true);
  };

  const handleClose = () => {
    setShowPref(false);
    setSrchRole([...allRoles]);
  };

  const increamentIP = () => {
    fvalue.push({ id: 0, name: "" });
    setFvalue([...fvalue]);
  };

  const decreamentIP = (index) => {
    fvalue.splice(index, 1);
    setFvalue([...fvalue]);
  };

  const clearIP = () => {
    let fv = [{ id: 0, name: "" }];
    setFvalue([...fv]);
  };

  const getRoleByApp = (app) => {
    console.log(app);
    if (app === "None") {
      getAllByRole();
      // getInitialRcd(app);
      return;
    }
    axios
      .get(`http://localhost:8084/marketplace/get/allroles`, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": app,
        },
      })
      .then((resp) => {
        let modul = resp.data;
        console.log(modul, allRoles);
        allRoles.splice(0, allRoles.length);
        for (var i = 0; i < modul.roleList.length; i++) {
          allRoles.push(modul.roleList[i]);
        }

        console.log("first", modul, modul.roleList.length);

        // setAppRole(modul.roleList[0].id);
        if (modul.roleList.length > 0) appRole.current = modul.roleList[0]?.id;
        else appRole.current = "0";

        getInitialRcd(app);
      });
  };

  const selectedAppRcd = (app) => {
    getRoleByApp(app);
  };

  const closeApp = (index) => {
    let rcd = record;
    rcd[index].show = !rcd[index].show;
    setRecord([...rcd]);
  };

  useEffect(() => {
    parentApp.current = searchParam.get("parentApplication");
    setLoading(true);
    setFvalue([{ id: 0, name: "" }]);
    getAllByRole();
  }, [searchParam, refresh]);

  if (!loading) {
    return (
      <div style={{ flexGrow: 1 }}>
        {/* <div style={{ position: "sticky", top: "0" }}> */}
        <Header
          tabName={"Application SetUp"}
          isClick={isClick}
          refresh={refresh}
          setRefresh={setRefresh}
        />
        {/* </div> */}
        <div className="list-group ">
          {fvalue[0].id === 0 && (
            <div className="alert alert-warning mx-2 mt-2">
              Please Select Role!
            </div>
          )}
          <div className=" p-2">
            {/* {allRoles.map((obj, id) => (

                <option key={id} value={obj.id}  >
                  {obj.name}
                </option>
              ))} */}

            <div className="card p-3" style={{ display: "flex" }}>
              <h4 className="m-0 text-center">Search And Set</h4>
              <hr />

              <div id="sidebar" className="mb-2">
                <h4 className="text-start">Application List</h4>
                <select
                  className="dropdn"
                  name=""
                  id=""
                  onChange={(e) => {
                    selectedAppRcd(e.target.value);
                  }}
                >
                  <option value={"None"}>None</option>
                  <option>marketplace</option>
                  <option>loomyarn</option>
                </select>
              </div>

              <div
                ref={(ref) => (outerCont.current = ref)}
                style={{ position: "relative" }}
              >
                <h4 className="text-start">Select Roles</h4>
                {fvalue.length > 0 &&
                  fvalue.map((obj, ind) => (
                    <div
                      ref={(ref) => {
                        topIp.current[ind] = ref;
                      }}
                      key={ind}
                      className="mb-1"
                      style={{ display: "flex", height: "1.9rem" }}
                    >
                      <input
                        className="inpt_typefil_ref"
                        type="search"
                        placeholder="Search Role"
                        aria-label="Search"
                        onChange={(e) => searchModule(e.target.value, ind)}
                        value={obj.name}
                      ></input>
                      <div
                        className="btnsrc_fil vlpointer"
                        onClick={() => {
                          handleShow(ind);
                        }}
                        // onClick={(e) => {
                        // setObjIndex(index);
                        // setshowModelList(true);
                        // }}
                      >
                        <i className="fa fa-search"></i>
                      </div>
                      <div>
                        <button
                          className="btn-dark ms-1"
                          onClick={() => {
                            increamentIP();
                          }}
                        >
                          +
                        </button>
                        {fvalue.length > 1 && (
                          <button
                            className="btn-dark ms-1"
                            onClick={() => {
                              decreamentIP(ind);
                            }}
                          >
                            -
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                {/* top: Number(1.9*(inputIndex.current+1)+(1.4*(inputIndex.current)))+"rem" */}
                {searchval.length > 0 && (
                  <div
                    className="ref_multircd"
                    style={{
                      top:
                        Number(
                          topIp.current[
                            inputIndex.current
                          ].getBoundingClientRect().top -
                            outerCont.current.getBoundingClientRect().top
                        ) +
                        topIp.current[
                          inputIndex.current
                        ].getBoundingClientRect().height,
                    }}
                  >
                    {console.log(topIp.current[0])}
                    {searchval.map((obj_ref, or_i) => (
                      <div
                        onClick={(e) => {
                          setRefRcd(obj_ref.name, obj_ref.id);
                          selectedRcd(obj_ref.id);
                        }}
                        key={or_i}
                      >
                        {obj_ref.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div id="sidebar" className="my-2">
                <h4 className="text-start">Search Module</h4>
                <input
                  className="form-control mr-sm-2 "
                  type="search"
                  placeholder="Write and get required module ..."
                  aria-label="Search"
                  onChange={(e) => modSearch(e.target.value)}
                ></input>
              </div>
              <div className="p-0 text-center">
                <button
                  className="btn btn-danger"
                  onClick={() => {
                    clearIP();
                  }}
                >
                  Clear All
                </button>
              </div>
              <Modal
                size="sm"
                show={showPref}
                onHide={handleClose}
                dialogClassName="modal-centered-of"
                style={{ overflowY: "hidden" }}
              >
                <Modal.Header
                  closeButton
                  className="custom-header"
                ></Modal.Header>
                <div className="search-container mar">
                  {srch && <i className="fa fa-search"></i>}
                  <input
                    className="form-control mr-sm-2 "
                    type="search"
                    aria-label="Search"
                    onChange={(e) => roleSearch(e.target.value)}
                    onMouseOver={(e) => setSrch(false)}
                    onMouseOut={(e) => {
                      if (srchVal.length > 0) {
                        setSrch(false);
                      } else {
                        setSrch(true);
                      }
                    }}
                  ></input>
                </div>
                <Modal.Body
                  // style={{ display: "flex", justifyContent: "center" }}

                  style={{ textAlign: "center" }}
                >
                  <div className="mod-max-ht">
                    <table className="table table-bordered table-striped table-hover p-1">
                      <thead style={{ position: "sticky", top: "0px" }}>
                        <tr className="obj_name">
                          <th
                            className="vlpointer"
                            style={{ verticalAlign: "middle" }}
                          >
                            User Roles
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {srchRole.map((obj, obj_i) => (
                          <tr className="obj_value" key={obj_i}>
                            <td
                              key={obj_i}
                              className="val_pad vlpointer"
                              style={{ verticalAlign: "middle" }}
                              onClick={(e) => {
                                setRefRcd(obj.name, obj.id);
                                selectedRcd(obj.id);
                                handleClose();
                              }}
                            >
                              {obj.name}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {/* <div className="row bck bck-rel">
                <div className="col-lg mid">User Role</div>
              </div> */}
                  {/* <PrefCompo
                pref="user"
                setRefer={(val) => setRef_Fields(val)}
                colarray={prefCall}
                tablename={tableName}
                handleClose={() => handleCloseRefresh()}
              ></PrefCompo> */}
                </Modal.Body>
                {/* <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                </Modal.Footer> */}
              </Modal>
            </div>
            {/* ))} */}
            {/* {obj.choice.map((ch, chi) => (
                <option key={chi} value={ch.value}>
                  {ch.label}
                </option>
              ))} */}
            {/* </select> */}
            {fvalue[0].id > 0 &&
              record.map((ob, indx) => (
                <div key={indx} className="card text-center mt-4">
                  <h4 className="card-header">
                    {ob.app.value}
                    {ob.show && (
                      <i
                        className="fa fa-arrow-down vlpointer arrow_sty ms-1"
                        onClick={(e) => closeApp(indx)}
                      ></i>
                    )}
                    {!ob.show && (
                      <i
                        className="fa fa-arrow-right vlpointer arrow_sty ms-1"
                        onClick={(e) => closeApp(indx)}
                      ></i>
                    )}
                  </h4>
                  <div className="card-body row fr">
                    {ob.show &&
                      ob.rcd.map((obj, o_i) => (
                        <div key={o_i} className="col-md-4 px-1">
                          <div className="list-group-item appm d-flex justify-content-between align-items-center">
                            <div className="fllf">
                              <input
                                type="checkbox"
                                checked={obj.showing === "true" ? true : false}
                                onChange={(e) => {
                                  console.log(e.target.checked);
                                  {
                                    console.log(obj.showing);
                                  }
                                  setCheckMenu(
                                    e.target.checked.toString(),
                                    o_i,
                                    indx
                                  );
                                }}
                              ></input>
                            </div>
                            <div>{obj.application_menu}</div>
                            <div className="flri d-flex">
                              {obj.ref_all === true && (
                                <i
                                  className="fa fa-arrow-down vlpointer arrow_sty"
                                  onClick={(e) => closeSection(indx, o_i)}
                                ></i>
                              )}
                              {obj.ref_all === false && (
                                <i
                                  className="fa fa-arrow-right vlpointer arrow_sty"
                                  onClick={(e) => closeSection(indx, o_i)}
                                ></i>
                              )}
                              <button
                                type="button"
                                className={obj.ref ? "buttn" : "buttnp"}
                                onClick={(pr) => setOpen(o_i, indx, obj.ref)}
                              >
                                {obj.ref ? "-" : "+"}
                              </button>
                            </div>
                          </div>
                          {obj.ref && (
                            <div>
                              {obj.section.map((obj_sec, sec_i) => (
                                <div key={sec_i}>
                                  <div className="bg-color">
                                    <span className="fllf">
                                      <input
                                        type="checkbox"
                                        checked={
                                          obj_sec.showing === "true"
                                            ? true
                                            : false
                                        }
                                        onChange={(e) => {
                                          setCheckSection(
                                            e.target.checked.toString(),
                                            indx,
                                            o_i,
                                            sec_i
                                          );
                                        }}
                                      ></input>
                                    </span>
                                    {obj_sec.ref === true && (
                                      <i
                                        className="fa fa-arrow-down vlpointer cog_pd"
                                        onClick={() =>
                                          setSection(o_i, sec_i, indx, false)
                                        }
                                      ></i>
                                    )}
                                    {obj_sec.ref === false && (
                                      <i
                                        className="fa fa-arrow-right vlpointer cog_pd"
                                        onClick={() =>
                                          setSection(o_i, sec_i, indx, true)
                                        }
                                      ></i>
                                    )}
                                    {obj_sec.sec_label}
                                  </div>
                                  {obj_sec.ref === true && (
                                    <div id={obj.application_menu}>
                                      {obj_sec.module.map((obj_m, m_i) => (
                                        <div key={m_i}>
                                          {obj_m.type === "new" && (
                                            <div
                                              //   onClick={() =>
                                              //     call_list(obj_m.form_table, m_i)
                                              //   }
                                              className="list-group-item list-group-item-action navpointer"
                                              style={{
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <p className="val ">
                                                <span className="fllf">
                                                  <input
                                                    type="checkbox"
                                                    checked={
                                                      obj_m.showing === "true"
                                                        ? true
                                                        : false
                                                    }
                                                    onChange={(e) => {
                                                      setCheckModule(
                                                        e.target.checked.toString(),
                                                        indx,
                                                        o_i,
                                                        sec_i,
                                                        m_i
                                                      );
                                                    }}
                                                  ></input>
                                                </span>
                                                {obj_m.form_label}
                                              </p>
                                              <span className="mod-set mod-type mod-col3">
                                                IN
                                              </span>
                                            </div>
                                          )}

                                          {obj_m.type === "view" && (
                                            <div
                                              //   onClick={() =>
                                              //     callFormView(
                                              //       obj_m.form_table,
                                              //       "0",
                                              //       obj_m.type
                                              //     )
                                              //   }
                                              className="list-group-item list-group-item-action navpointer"
                                            >
                                              {obj_m.form_module}
                                              <span className="mod-set mod-type mod-col3">
                                                IN
                                              </span>
                                            </div>
                                          )}

                                          {obj_m.type === "list" && (
                                            <div
                                              //   onClick={() =>
                                              //     call_list(obj_m.form_table, m_i)
                                              //   }
                                              className="list-group-item list-group-item-action navpointer"
                                              style={{
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <span className="fllf">
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    obj_m.showing === "true"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    setCheckModule(
                                                      e.target.checked.toString(),
                                                      indx,
                                                      o_i,
                                                      sec_i,
                                                      m_i
                                                    );
                                                  }}
                                                ></input>
                                              </span>
                                              {obj_m.form_label}
                                              <span className="mod-set mod-type mod-col1">
                                                LI
                                              </span>
                                            </div>
                                          )}
                                          {obj_m.type === "flownew" && (
                                            <div
                                              //   onClick={() => {
                                              //     callFlowCompo(
                                              //       obj_m.form_table,
                                              //       "0",
                                              //       obj_m.type,
                                              //       m_i
                                              //     );
                                              //   }}
                                              className="list-group-item list-group-item-action navpointer"
                                              style={{
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <span className="fllf">
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    obj_m.showing === "true"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    setCheckModule(
                                                      e.target.checked.toString(),
                                                      indx,
                                                      o_i,
                                                      sec_i,
                                                      m_i
                                                    );
                                                  }}
                                                ></input>
                                              </span>
                                              {obj_m.form_label}
                                              <span className="mod-set mod-type mod-col12 ">
                                                FN
                                              </span>
                                            </div>
                                          )}

                                          {obj_m.type === "setup" && (
                                            <div
                                              //   onClick={() => {
                                              //     callInitialSetupCompo(
                                              //       obj_m.form_module,
                                              //       "0",
                                              //       obj_m.type,
                                              //       m_i
                                              //     );
                                              //   }}
                                              className="list-group-item list-group-item-action navpointer"
                                              style={{
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <span className="fllf">
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    obj_m.showing === "true"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    setCheckModule(
                                                      e.target.checked.toString(),
                                                      indx,
                                                      o_i,
                                                      sec_i,
                                                      m_i
                                                    );
                                                  }}
                                                ></input>
                                              </span>
                                              {obj_m.form_label}
                                              <span className="mod-set mod-type mod-col12 ">
                                                IS
                                              </span>
                                            </div>
                                          )}
                                          {obj_m.type === "flowlist" && (
                                            <div
                                              //   onClick={() =>
                                              //     call_list(obj_m.form_table, m_i)
                                              //   }
                                              className="list-group-item list-group-item-action navpointer"
                                              style={{
                                                justifyContent: "space-between",
                                              }}
                                            >
                                              <span className="fllf">
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    obj_m.showing === "true"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    setCheckModule(
                                                      e.target.checked.toString(),
                                                      indx,
                                                      o_i,
                                                      sec_i,
                                                      m_i
                                                    );
                                                  }}
                                                ></input>
                                              </span>
                                              {obj_m.form_label}
                                              <span className="mod-set mod-type mod-col13">
                                                FL
                                              </span>
                                            </div>
                                          )}

                                          {obj_m.type === "record" && (
                                            <div
                                              //   onClick={() =>
                                              //     callRecord(
                                              //       obj_m.form_table,
                                              //       obj_m.recordid,
                                              //       obj_m.type
                                              //     )
                                              //   }
                                              className="list-group-item list-group-item-action navpointer"
                                            >
                                              <span className="fllf">
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    obj_m.showing === "true"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    setCheckModule(
                                                      e.target.checked.toString(),
                                                      indx,
                                                      o_i,
                                                      sec_i,
                                                      m_i
                                                    );
                                                  }}
                                                ></input>
                                              </span>
                                              {obj_m.form_label}
                                              <span className="mod-set mod-type mod-col2">
                                                RC
                                              </span>
                                            </div>
                                          )}
                                          {obj_m.type === "other" && (
                                            <div
                                              //   onClick={() => {
                                              //     callOther();
                                              //     setActive(m_i);
                                              //   }}
                                              className="list-group-item list-group-item-action navpointer"
                                            >
                                              Report
                                              <span className="mod-set mod-type mod-col4">
                                                OT
                                              </span>
                                            </div>
                                          )}
                                          {obj_m.type === "multiinsert" && (
                                            <div
                                              //   onClick={() => callMulti(obj_m.form_table)}
                                              className="list-group-item list-group-item-action navpointer"
                                            >
                                              <span className="fllf">
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    obj_m.showing === "true"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    setCheckModule(
                                                      e.target.checked.toString(),
                                                      indx,
                                                      o_i,
                                                      sec_i,
                                                      m_i
                                                    );
                                                  }}
                                                ></input>
                                              </span>
                                              {obj_m.form_label}
                                              <span className="mod-set mod-type mod-col5">
                                                MI
                                              </span>
                                            </div>
                                          )}
                                          {obj_m.type === "multipage" && (
                                            <div
                                              //   onClick={() =>
                                              //     callMultipage(obj_m.form_table)
                                              //   }
                                              className="list-group-item list-group-item-action navpointer"
                                            >
                                              <span className="fllf">
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    obj_m.showing === "true"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    setCheckModule(
                                                      e.target.checked.toString(),
                                                      indx,
                                                      o_i,
                                                      sec_i,
                                                      m_i
                                                    );
                                                  }}
                                                ></input>
                                              </span>
                                              {obj_m.form_label}
                                              <span className="mod-set mod-type mod-col6">
                                                MP
                                              </span>
                                            </div>
                                          )}
                                          {obj_m.type === "multitable" && (
                                            <div
                                              //   onClick={() =>
                                              //     callMultiTable(obj_m.form_table, m_i)
                                              //   }
                                              className="list-group-item list-group-item-action navpointer"
                                            >
                                              <span className="fllf">
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    obj_m.showing === "true"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    setCheckModule(
                                                      e.target.checked.toString(),
                                                      indx,
                                                      o_i,
                                                      sec_i,
                                                      m_i
                                                    );
                                                  }}
                                                ></input>
                                              </span>
                                              {obj_m.form_label}
                                              <span className="mod-set mod-type mod-col6">
                                                MT
                                              </span>
                                            </div>
                                          )}
                                          {obj_m.type === "register" && (
                                            <div
                                              //   onClick={() => callRegister(obj_m.form_table)}
                                              className="list-group-item list-group-item-action navpointer"
                                            >
                                              <span className="fllf">
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    obj_m.showing === "true"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    setCheckModule(
                                                      e.target.checked.toString(),
                                                      indx,
                                                      o_i,
                                                      sec_i,
                                                      m_i
                                                    );
                                                  }}
                                                ></input>
                                              </span>
                                              {obj_m.form_label}
                                              <span className="mod-set mod-type mod-col7">
                                                RG
                                              </span>
                                            </div>
                                          )}

                                          {obj_m.type === "set" && (
                                            <div
                                              //   onClick={() => applicationSet(m_i)}
                                              className="list-group-item list-group-item-action navpointer"
                                            >
                                              <span className="fllf">
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    obj_m.showing === "true"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    console.log(
                                                      e.target.checked.toString(),
                                                      indx,
                                                      o_i,
                                                      sec_i,
                                                      m_i
                                                    );
                                                    setCheckModule(
                                                      e.target.checked.toString(),
                                                      indx,
                                                      o_i,
                                                      sec_i,
                                                      m_i
                                                    );
                                                  }}
                                                ></input>
                                              </span>
                                              {obj_m.form_label}
                                              <span className="mod-set mod-type mod-col5">
                                                AS
                                              </span>
                                            </div>
                                          )}

                                          {obj_m.type === "uipolicy" && (
                                            <div
                                              //   onClick={() => call_uipolicy(m_i)}
                                              className="list-group-item list-group-item-action navpointer"
                                            >
                                              <span className="fllf">
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    obj_m.showing === "true"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    setCheckModule(
                                                      e.target.checked.toString(),
                                                      indx,
                                                      o_i,
                                                      sec_i,
                                                      m_i
                                                    );
                                                  }}
                                                ></input>
                                              </span>
                                              {obj_m.form_label}
                                              <span className="mod-set mod-type mod-col15">
                                                UIP
                                              </span>
                                            </div>
                                          )}

                                          {obj_m.type === "uipolicy_action" && (
                                            <div
                                              //   onClick={() => call_uipolicy_action(m_i)}
                                              className="list-group-item list-group-item-action navpointer"
                                            >
                                              <span className="fllf">
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    obj_m.showing === "true"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    setCheckModule(
                                                      e.target.checked.toString(),
                                                      indx,
                                                      o_i,
                                                      sec_i,
                                                      m_i
                                                    );
                                                  }}
                                                ></input>
                                              </span>
                                              {obj_m.form_label}
                                              <span className="mod-set mod-type mod-col15">
                                                UIPA
                                              </span>
                                            </div>
                                          )}

                                          {obj_m.type === "fview" && (
                                            <div
                                              //   onClick={() => callFormView(m_i)}
                                              className="list-group-item list-group-item-action navpointer"
                                            >
                                              <span className="fllf">
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    obj_m.showing === "true"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    setCheckModule(
                                                      e.target.checked.toString(),
                                                      indx,
                                                      o_i,
                                                      sec_i,
                                                      m_i
                                                    );
                                                  }}
                                                ></input>
                                              </span>
                                              {obj_m.form_label}
                                              <span className="mod-set mod-type mod-col6">
                                                FV
                                              </span>
                                            </div>
                                          )}

                                          {obj_m.type === "checklist" && (
                                            <div
                                              //   onClick={() => callchecklist(m_i)}
                                              className="list-group-item list-group-item-action navpointer"
                                            >
                                              <span className="fllf">
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    obj_m.showing === "true"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    setCheckModule(
                                                      e.target.checked.toString(),
                                                      indx,
                                                      o_i,
                                                      sec_i,
                                                      m_i
                                                    );
                                                  }}
                                                ></input>
                                              </span>
                                              {obj_m.form_label}
                                              <span className="mod-set mod-type mod-col8">
                                                Cl
                                              </span>
                                            </div>
                                          )}

                                          {obj_m.type === "up" && (
                                            <div
                                              //   onClick={() => callUpdateCompo(m_i)}
                                              className="list-group-item list-group-item-action navpointer"
                                            >
                                              <span className="fllf">
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    obj_m.showing === "true"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    setCheckModule(
                                                      e.target.checked.toString(),
                                                      indx,
                                                      o_i,
                                                      sec_i,
                                                      m_i
                                                    );
                                                  }}
                                                ></input>
                                              </span>
                                              {obj_m.form_label}
                                              <span className="mod-set mod-type mod-col7">
                                                Up
                                              </span>
                                            </div>
                                          )}

                                          {obj_m.type === "appAccess" && (
                                            <div
                                              //   onClick={() => callAppAccessCompo(m_i)}
                                              className="list-group-item list-group-item-action navpointer"
                                            >
                                              <span className="fllf">
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    obj_m.showing === "true"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    setCheckModule(
                                                      e.target.checked.toString(),
                                                      indx,
                                                      o_i,
                                                      sec_i,
                                                      m_i
                                                    );
                                                  }}
                                                ></input>
                                              </span>
                                              {obj_m.form_label}

                                              <span className="mod-set mod-type mod-col8">
                                                AA
                                              </span>
                                            </div>
                                          )}

                                          {obj_m.type === "preference" && (
                                            <div
                                              //   onClick={() => {
                                              //     callPreference(obj_m.form_table);
                                              //     setActive(m_i + 4);
                                              //   }}
                                              className="list-group-item list-group-item-action navpointer"
                                            >
                                              <span className="fllf">
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    obj_m.showing === "true"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    setCheckModule(
                                                      e.target.checked.toString(),
                                                      indx,
                                                      o_i,
                                                      sec_i,
                                                      m_i
                                                    );
                                                  }}
                                                ></input>
                                              </span>
                                              {obj_m.form_label}
                                              <span className="mod-set mod-type mod-col8">
                                                PF
                                              </span>
                                            </div>
                                          )}
                                          {obj_m.type === "properties" && (
                                            <div
                                              //   onClick={callProperties}
                                              className="list-group-item list-group-item-action navpointer"
                                            >
                                              <span className="fllf">
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    obj_m.showing === "true"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    setCheckModule(
                                                      e.target.checked.toString(),
                                                      indx,
                                                      o_i,
                                                      sec_i,
                                                      m_i
                                                    );
                                                  }}
                                                ></input>
                                              </span>
                                              {obj_m.form_label}
                                              <span className="mod-set mod-type mod-col9">
                                                PP
                                              </span>
                                            </div>
                                          )}
                                          {obj_m.type === "theme" && (
                                            <div
                                              //   onClick={callTheme}
                                              className="list-group-item list-group-item-action navpointer"
                                            >
                                              <span className="fllf">
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    obj_m.showing === "true"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    setCheckModule(
                                                      e.target.checked.toString(),
                                                      indx,
                                                      o_i,
                                                      sec_i,
                                                      m_i
                                                    );
                                                  }}
                                                ></input>
                                              </span>
                                              {obj_m.form_label}
                                              <span className="mod-set mod-type mod-col10">
                                                TE
                                              </span>
                                            </div>
                                          )}
                                          {obj_m.type === "view" && (
                                            <div
                                              //   onClick={() => callView(obj_m.form_table)}
                                              className="list-group-item list-group-item-action navpointer"
                                            >
                                              <span className="fllf">
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    obj_m.showing === "true"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    setCheckModule(
                                                      e.target.checked.toString(),
                                                      indx,
                                                      o_i,
                                                      sec_i,
                                                      m_i
                                                    );
                                                  }}
                                                ></input>
                                              </span>
                                              {obj_m.form_label}
                                              <span className="mod-set mod-type mod-col11">
                                                VE
                                              </span>
                                            </div>
                                          )}
                                          {obj_m.type === "clienInfo" && (
                                            <div
                                              //   onClick={() => {
                                              //     console.log(obj_m);
                                              //     callClienInfo(m_i, obj_m.filter);
                                              //   }}
                                              className="list-group-item list-group-item-action navpointer"
                                            >
                                              <span className="fllf">
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    obj_m.showing === "true"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    setCheckModule(
                                                      e.target.checked.toString(),
                                                      indx,
                                                      o_i,
                                                      sec_i,
                                                      m_i
                                                    );
                                                  }}
                                                ></input>
                                              </span>
                                              {obj_m.form_label}
                                              <span className="mod-set mod-type mod-col11">
                                                CI
                                              </span>
                                            </div>
                                          )}
                                          {obj_m.type === "clienInfoRecord" && (
                                            <div
                                              //   onClick={() => {
                                              //     console.log(obj_m);
                                              //     callClienInfoRecord(m_i, obj_m.filter);
                                              //   }}
                                              className="list-group-item list-group-item-action navpointer"
                                            >
                                              clientInfoRecord
                                              <span className="mod-set mod-type mod-col11">
                                                CR
                                              </span>
                                            </div>
                                          )}
                                          {obj_m.type === "portal" && (
                                            <div
                                              //   onClick={() => callPortalCompo(m_i)}
                                              className="list-group-item list-group-item-action navpointer"
                                            >
                                              <span className="fllf">
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    obj_m.showing === "true"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    setCheckModule(
                                                      e.target.checked.toString(),
                                                      indx,
                                                      o_i,
                                                      sec_i,
                                                      m_i
                                                    );
                                                  }}
                                                ></input>
                                              </span>
                                              {obj_m.form_label}
                                              <span className="mod-set mod-type mod-col1">
                                                PP
                                              </span>
                                            </div>
                                          )}
                                          {obj_m.type === "gd" && (
                                            <div
                                              //   onClick={() => callGridCompo(m_i)}
                                              className="list-group-item list-group-item-action navpointer"
                                            >
                                              <span className="fllf">
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    obj_m.showing === "true"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    setCheckModule(
                                                      e.target.checked.toString(),
                                                      indx,
                                                      o_i,
                                                      sec_i,
                                                      m_i
                                                    );
                                                  }}
                                                ></input>
                                              </span>
                                              {obj_m.form_label}
                                              <span className="mod-set mod-type mod-col1">
                                                gd
                                              </span>
                                            </div>
                                          )}
                                          {obj_m.type === "var" && (
                                            <div
                                              //   onClick={() => callVariable(m_i)}
                                              className="list-group-item list-group-item-action navpointer"
                                            >
                                              <span className="fllf">
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    obj_m.showing === "true"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    setCheckModule(
                                                      e.target.checked.toString(),
                                                      indx,
                                                      o_i,
                                                      sec_i,
                                                      m_i
                                                    );
                                                  }}
                                                ></input>
                                              </span>
                                              {obj_m.form_label}
                                              <span className="mod-set mod-type mod-col1">
                                                vr
                                              </span>
                                            </div>
                                          )}

                                          {obj_m.type === "appsetup" && (
                                            <div
                                              //   onClick={() => callVariable(m_i)}
                                              className="list-group-item list-group-item-action navpointer"
                                            >
                                              <span className="fllf">
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    obj_m.showing === "true"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    console.log(
                                                      e.target.checked.toString(),
                                                      indx,
                                                      o_i,
                                                      sec_i,
                                                      m_i
                                                    );
                                                    setCheckModule(
                                                      e.target.checked.toString(),
                                                      indx,
                                                      o_i,
                                                      sec_i,
                                                      m_i
                                                    );
                                                  }}
                                                ></input>
                                              </span>
                                              {obj_m.form_label}
                                              <span className="mod-set mod-type mod-col12">
                                                AS
                                              </span>
                                            </div>
                                          )}
                                          {obj_m.type === "tablesetup" && (
                                            <div
                                              //   onClick={() => callVariable(m_i)}
                                              className="list-group-item list-group-item-action navpointer"
                                            >
                                              <span className="fllf">
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    obj_m.showing === "true"
                                                      ? true
                                                      : false
                                                  }
                                                  onChange={(e) => {
                                                    console.log(
                                                      e.target.checked.toString(),
                                                      indx,
                                                      o_i,
                                                      sec_i,
                                                      m_i
                                                    );
                                                    setCheckModule(
                                                      e.target.checked.toString(),
                                                      indx,
                                                      o_i,
                                                      sec_i,
                                                      m_i
                                                    );
                                                  }}
                                                ></input>
                                              </span>
                                              {obj_m.form_label}
                                              <span className="mod-set mod-type mod-col12">
                                                FS
                                              </span>
                                            </div>
                                          )}
                                        </div>
                                      ))}
                                    </div>
                                  )}
                                </div>
                              ))}
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              ))}
          </div>

          {fvalue[0].id > 0 && (
            <div className="mb-2 text-center">
              <button
                className="btn btn-primary"
                disabled={fvalue[0].id === 0 ? true : isProcessing}
                onClick={() => {
                  submitFn();
                }}
              >
                {isProcessing ? (
                  <span>
                    <span
                      className="spinner-grow spinner-grow-sm me-2"
                      role="status"
                    ></span>
                    Loading...
                  </span>
                ) : (
                  "submit"
                )}
              </button>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div
        className="d-flex justify-content-center align-items-center w-100"
        style={{ height: "95vh" }}
      >
        <WorkInProgress />
      </div>
    );
  }
};

export default ApplicationSetup;
