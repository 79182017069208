import axios from "axios";
import React, { useContext, useRef, useState } from "react";
import { useEffect } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { AutoPortalContext } from "../Context";
import "../css/test.css";
import Tester from "./Tester";
import { stringToComponent } from "./StringTofunction";

const Portal_Result = () => {
  const { token, loca, user } = useContext(AutoPortalContext);
  const navigation = useNavigate();
  const { html, css, script } = useLocation().state;
  const [load, setLoad] = useState(0);
  const [loadBody, setLoadBody] = useState([]);
  const [fullbody, setFullBody] = useState([]);
  const oneRender = useRef(false);
  // console.log("html : "+html,css,script);

  const Template = stringToComponent("GenY", script, html, token, loca)

  // useEffect(() => {
  //   document.getElementById("te").innerHTML = html;
  //   let body = [];

  //   let bd = script.split("};");
  //   bd.splice(bd.length - 1, 1);
  //   console.log("befSplit : " + JSON.stringify(bd));
  //   for (let i = 0; i < bd.length; i++) {
  //     let bd_split = bd[i].split(/\)+ *{+/);
  //     let bd_name = "";
  //     a: for (let j = 0; j < bd_split.length; j++) {
  //       let last_Split = bd_split[j].split(" ");
  //       for (let k = 0; k < last_Split.length; k++) {
  //         if (/\w+\(+/.test(last_Split[k])) {
  //           bd_name = last_Split[k] + ")";
  //           break a;
  //         }
  //       }
  //     }
  //     const open = bd[i].indexOf("{");
  //     // const close = scpt.indexOf("}");
  //     const by = bd[i].slice(open, bd[i].length) + "}";
  //     body.push({ body: by, name: bd_name });



  //   }
  //   let innerCss = document.getElementsByTagName("style").item(0).textContent;
  //   document.getElementsByTagName("style").item(0).textContent = innerCss + css;
  //   // const styleTag = document.createElement("style");
  //   // styleTag.textContent = css;
  //   // document.body.appendChild(styleTag);
  //   // console.log("afterSplit : " + JSON.stringify(body));
  //   setFullBody(body);
  //   eventType(html, body, script);
  // }, []);

  // useEffect(() => {
  //   if (oneRender.current) {
  //     pageLoad().then((e) => {
  //       console.log(e);
  //     });
  //   } else {
  //     oneRender.current = true;
  //   }
  // }, [loadBody]);


  // const pageLoad = async () => {
  //   console.log(loadBody);
  //   let f = new Function(["axios,navigation,token"], loadBody);
  //   let promise = new Promise((resolve, reject) => {
  //     f(axios, navigation, token);
  //     setTimeout(() => resolve("finished"), 1000);
  //   });
  //   promise.then((res) => {
  //     let html3 = document.getElementById("te").innerHTML;
  //     eventType(html3, fullbody, script);
  //   });
  //   // console.log("ffg");
  // };

  // const eventType = async (tag, body, full_body) => {
  //   // console.log("tag : " + tag);
  //   let elmnt = document.getElementById("te");

  //   if (full_body.includes("window.load")) {
  //     let sp = full_body.split(/window.load+ *=+/);
  //     // console.log("sp "+sp)
  //     for (let i = 0; i < body.length; i++) {
  //       // console.log("body "+JSON.stringify(body[i]))
  //       if (sp[sp.length - 1].includes(body[i].name)) {
  //         setLoadBody( script+";"+body[i].name);
  //         setLoad(1);
  //       }
  //     }
  //   }
  //   if (tag.includes("()")) {
  //     // if (tag.includes("onclick")) {
  //     let arrTag = tag.split(">");
  //     // console.log("split : " + JSON.stringify(arrTag));
  //     let fnName = "";
  //     let arrFunc = [];
  //     for (let j = 0; j < arrTag.length; j++) {
  //       let firstTag = arrTag[j];

  //       let sameid = -1;
  //       let arr = firstTag.split("=");
  //       for (let i = 0, z = 0, x = -1; i < arr.length; i++) {
  //         if (arr[i].includes("()")) {
  //           let k = arr[i];
  //           fnName = k.slice(k.indexOf('"'), k.lastIndexOf('"') + 1);
  //           let fnName2 = k.slice(k.indexOf('"') + 1, k.lastIndexOf('"'));
  //           let repName = fnName2;
  //           let typ = "";
  //           // console.log(" onclick=" + fnName2);
  //           let withoutFn;
  //           if (arr[i - 1].includes("onclick")) {
  //             if (z > 0 && sameid==-1) {
  //               sameid = arrFunc.length-1;
  //             } 
  //             // else {
  //             //   if (x == -1) {
  //             //     x = i;
  //             //   }
  //             // }
  //             if (sameid == -1) {
  //               withoutFn = tag.replace(
  //                 " onclick=" + fnName,
  //                 " name='" + repName + "'"
  //               );
  //             } else {
  //               withoutFn = tag.replace(
  //                 " onclick=" + fnName,
  //                 " "
  //               );
  //             }

  //             typ = "onclick";
  //             z++;
  //           } else if (arr[i - 1].includes("onload")) {
  //             if (z > 0 && sameid==-1) {
  //               sameid = arrFunc.length-1;
  //             } 
  //             // else {
  //             //   if (x == -1) {
  //             //     x = i;
  //             //   }
  //             // }
  //             if (sameid == -1) {
  //               withoutFn = tag.replace(
  //                 " onload=" + fnName,
  //                 " name='" + repName + "'"
  //               );
  //             } else {
  //               withoutFn = tag.replace(
  //                 " onload=" + fnName,
  //                 " "
  //               );
  //             }
  //             typ = "onload";
  //             z++;
  //           } else if (arr[i - 1].includes("onmouseover")) {
  //             if (z > 0 && sameid==-1) {
  //               sameid = arrFunc.length-1;
  //             } 
  //             // else {
  //             //   if (x == -1) {
  //             //     x = i;
  //             //   }
  //             // }
  //             if (sameid == -1) {
  //               withoutFn = tag.replace(
  //                 " onmouseover=" + fnName,
  //                 " name='" + repName + "'"
  //               );
  //             } else {
  //               withoutFn = tag.replace(
  //                 " onmouseover=" + fnName,
  //                 " "
  //               );
  //             }
  //             typ = "onmouseover";
  //             z++;
  //           } else if (arr[i - 1].includes("onmouseout")) {
  //             if (z > 0 && sameid==-1) {
  //               sameid = arrFunc.length-1;
  //             } 
  //             // else {
  //             //   if (x == -1) {
  //             //     x = i;
  //             //   }
  //             // }
  //             if (sameid == -1) {
  //               withoutFn = tag.replace(
  //                 " onmouseout=" + fnName,
  //                 " name='" + repName + "'"
  //               );
  //             } else {
  //               withoutFn = tag.replace(
  //                 " onmouseout=" + fnName,
  //                 " "
  //               );
  //             }
  //             typ = "onmouseout";
  //             z++;
  //           } else {
  //             typ = "non";
  //           }
  //           // console.log("withoutfn" + withoutFn);
  //           if (typ !== "non") {

  //               elmnt.innerHTML = withoutFn;
  //               tag = withoutFn;

  //             arrFunc.push({ name: repName, type: typ, sameid: sameid });
  //           }
  //           // elmnt.removeEventListener("onclick")
  //         }
  //       }
  //     }
  //     // document.getElementsByClassName("aa").item(0).before(elmnt);
  //     console.log("arrfunc : " + JSON.stringify(arrFunc));

  //     for (let i = 0; i < arrFunc.length; i++) {
  //       let click_elmnt;
  //       if (arrFunc[i].sameid != -1) {
  //         console.log(arrFunc[arrFunc[i].sameid].name);
  //         click_elmnt = document.getElementsByName(arrFunc[arrFunc[i].sameid].name).item(0);
  //       } else {
  //         console.log(arrFunc[i].name);
  //         click_elmnt = document.getElementsByName(arrFunc[i].name).item(0);
  //       }

  //       for (let j = 0; j < body.length; j++) {
  //         let func_name = arrFunc[i].name.slice(
  //           0,
  //           arrFunc[i].name.indexOf(")") + 1
  //         );
  //         console.log("checking outside " + func_name, JSON.stringify(body));
  //         if (body[j].name === func_name) {
  //           if (arrFunc[i].type === "onclick") {
  //             console.log("checking click" + body[j].body);
  //             click_elmnt.addEventListener("click", () => {
  //               run(script + ";" + body[j].name);
  //             });
  //           }
  //           if (arrFunc[i].type === "onmouseover") {
  //             console.log("checking click" + body[j].body);
  //             click_elmnt.addEventListener("mouseover", () => {
  //               run(script + ";" + body[j].name);
  //             });
  //           }
  //           if (arrFunc[i].type === "onmouseout") {
  //             console.log("checking click" + body[j].body);
  //             click_elmnt.addEventListener("mouseout", () => {
  //               run(script + ";" + body[j].name);
  //             });
  //           }
  //           if (arrFunc[i].type === "onload") {
  //             // console.log("checking load");
  //             click_elmnt.addEventListener("load", () => {
  //               let f = new Function(["axios,loca,navigation"], script+";"+body[j].name);
  //               f(axios, loca,navigation);
  //             });
  //           } else {
  //             // console.log("checking click");
  //           }
  //         }
  //       }
  //     }
  //   }
  // };

  // const run = async (func) => {
  //   console.log(func);
  //   let f = new Function(["axios,navigation,createSearchParams,loca,token,React"], func);
  //   await f(axios, navigation, createSearchParams,loca+"/api", token,React);
  //   let html3 = document.getElementById("te").innerHTML;

  //   // eventType(html3, fullbody, script);
  // };

  return (
    <div className="iframe" style={{ flexGrow: 1 }}>


      <Template />
      <button className="btn btn-primary "
        onClick={(e) => {


          // ?portal=puma&page=home
          navigation(-1);
        }}
      >

        Back
      </button >
    </div>
  );
};

export default Portal_Result;



