import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { AutoPortalContext } from "../Context";
import "../css/UpdateJson.css";
import { createSearchParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";

const UpdateJsonCompo = ({ unRec, currRec, getInitial }) => {
  const { token, loca, user } = useContext(AutoPortalContext);
  const navigation = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [srcRecord, setSrcRecord] = useState(unRec);
  console.log(srcRecord);

  // const getInitial=()=>{
  //     axios
  //     .get(loca + "/lom/get/updatejson", {
  //       headers: {
  //         "Content-Type": "application/json",
  //         authorization: "Bearer " + token,
  //       },
  //     })
  //     .then(
  //       (resp) => {
  //         let res=resp.data;
  //         console.log(res);
  //       },
  //       (error) => {console.log(error);}
  //     )
  // }

  // const setRec = (ind) => {
  //   if (rec[ind].selected === "true") {
  //     return;
  //   }

  //   let js = { id: rec[ind].id };
  //   console.log("test", js);
  //   axios
  //     .post(loca + "/lom/set/updatejson", js, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         authorization: "Bearer " + token,
  //       },
  //     })
  //     .then(
  //       (resp) => {
  //         let res = resp.data;
  //         console.log(res);
  //         if ("Error" in res) {
  //           console.log(res.Error);
  //           toast.error(res.Error);
  //         } else {
  //           getInitial();
  //           toast.success(res.message);
  //         }
  //       },
  //       (error) => {
  //         console.log(error);
  //       }
  //     );
  // };

  // const unSelect = () => {

  //   // check wheter any record is selected or not
  //   let isSelected = rec.every((e) => e.selected === "false");
  //   console.log(isSelected);
  //   if (!isSelected) {
  //     axios
  //       .get(loca + "/lom/unselect/updatejson", {
  //         headers: {
  //           "Content-Type": "application/json",
  //           authorization: "Bearer " + token,
  //         },
  //       })
  //       .then(
  //         (resp) => {
  //           let res = resp.data;
  //           console.log(res);
  //           if ("Error" in res) {
  //             console.log(res.Error);
  //             toast.error(res.Error);
  //           } else {
  //             getInitial();
  //             toast.success(res.message);
  //           }
  //         },
  //         (error) => {
  //           console.log(error);
  //         }
  //       );
  //   }
  // };

  const setRec = (ind) => {
    // if (unRec[ind].selected === "true") {
    //   return;
    // }
    cancelModal()
    let js = { id: unRec[ind].id };
    console.log("test", js);
    axios
      .post(loca + "/lom/set/updatejson2", js, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let res = resp.data;
          console.log(res);
          if ("Error" in res) {
            console.log(res.Error);
            toast.error(res.Error);
          } else {
            getInitial();
            toast.success(res.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const unSelect = () => {
    axios
      .get(loca + "/lom/unselect/updatejson", {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let res = resp.data;
          console.log(res);
          if ("Error" in res) {
            console.log(res.Error);
            toast.error(res.Error);
          } else {
            getInitial();
            toast.success(res.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const goToForm = () => {
    // document.getElementById("updatemenu")?.classList.remove("show");
    // document.getElementById("updatedrop")?.classList.remove("show")
    // document.getElementById("dropdown-basic")?.classList.remove("show")
    // document.getElementById("dropdown-basic")?.setAttribute("aria-expanded","false")
    navigation({
      pathname: user + "/form",
      search: createSearchParams({
        parentApplication: "autoportal",
        tableName: "updatejson",
        rty: "new",
      }).toString(),
    });
  };

  //   class="text-white  dropdown-toggle show"
  // class="text-white  dropdown-toggle"
  // text-white dropdown-toggle

  const goToList = () => {
    // document.getElementById("updatemenu")?.classList.remove("show");
    // document.getElementById("updatedrop")?.classList.remove("show")
    // document.getElementById("dropdown-basic")?.classList.remove("show")
    // document.getElementById("dropdown-basic")?.setAttribute("aria-expanded","false")
    navigation({
      pathname: user + "/list",
      search: createSearchParams({
        parentApplication: "autoportal",
        tableName: "update_json_record",
      }).toString(),
    });
  };

  const searchRcd=(nam)=>{
    let arr = [];
    if (nam.length > 2) {
      for (let i = 0; i < srcRecord.length; i++) {
        let includes = srcRecord[i].name.includes(nam.toLowerCase());
        if (includes) {
          arr.push(srcRecord[i]);
        }
      }
    } else {
      arr = JSON.parse(JSON.stringify(unRec));
    }

    setSrcRecord([...arr]);
  }

  const cancelModal = () => {
    // setSrcRecord(unRec)
    setShowModal(false);
  };

  useEffect(() => {setSrcRecord(unRec)}, [unRec]);

  return (
    <div>
      <Modal show={showModal} onHide={cancelModal} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Update JSON</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
          <div className="text-center ">
            <input
              style={{ width: "90%" }}
              className="mb-2"
              type="search"
              placeholder="Search"
              aria-label="Search"
              onChange={(e) => {
                searchRcd(e.target.value);
              }}
            ></input>
          </div>
          <div className="container"  style={{ width: "90%" }}>
            {console.log(srcRecord,unRec)}
            {srcRecord.map((obj, ind) => (
              <div
                key={ind}
                tabIndex={1}
                onClick={() => setRec(ind)}
                className="d-flex justify-content-between px-1 item-list"
              >
                <span className="fw-medium h3">{obj.name}</span>
                {/* {obj.selected === "true" && (
              <span>
                <i style={{ color: "green" }} className="fa fa-check"></i>
              </span>
            )} */}
              </div>
            ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <h5 className="text-center underline">Update JSON</h5>
      {/* {rec.length > 0 &&
        rec.map((obj, ind) => (
          <div
          key={ind}
            tabIndex={1}
            onClick={() => setRec(ind)}
            className="d-flex justify-content-between px-1 item-list"
          >
            <span className="fw-medium">{obj.name}</span>
            {obj.selected === "true" && (
              <span>
              <i style={{ color: "green" }} className="fa fa-check"></i>
              </span>
              )}
              </div>
              ))} */}
      {currRec && (
        <div
          // onClick={() => setRec(ind)}
          className="d-flex justify-content-between px-1 "
        >
          <span className="fw-medium">{currRec.name}</span>
          <span>
            <i style={{ color: "green" }} className="fa fa-check"></i>
          </span>
        </div>
      )}
      {unRec.length > 0 && (
        <div
          onClick={() => {
            setShowModal(true);
          }}
          className="px-1 mb-1 item-list d-flex justify-content-between"
        >
          <span>See More </span>
          <span className="text-center"><i  className="fa fa-search"></i></span>
        </div>
      )}
      <hr className="mb-1 mt-1" />
      {console.log(currRec, unRec)}
      {/* {(unRec.length > 0 || currRec) && (
        <div
          onClick={() => {
            unSelect();
          }}
          className="px-1 mb-1 item-list"
        >
          <span>Unselect</span>
        </div>
      )} */}
      
      <div
        onClick={() => {
          goToForm();
        }}
        className="px-1  item-list"
      >
        <span>Create New</span>
      </div>
      <div
        onClick={() => {
          goToList();
        }}
        className="px-1  item-list"
      >
        <span>See Records</span>
      </div>
    </div>
  );
};

export default UpdateJsonCompo;

// state requried to store current update json
// function to open modal list
// inside  modal list of unselected update json
// on click of list item set that as current json
