import axios from "axios";
import React, {
  useState,
  useEffect,
  useRef,
} from "react";
import { useContext } from "react";
import { useNavigate, useLocation, json, createSearchParams } from "react-router-dom";
import { AutoPortalContext } from "../Context";
import "../css/DashboardComponent.css";
import "../css/FormCSS.css";
import FormComponent from "./FormComponent.jsx";
import ListComponent from "./ListComponent.jsx";
import ReportCompo from "./ReportCompo.jsx";
import WorkInProgress from "./WorkInProgress.jsx";
import db from "../img/db_3.jpg";
const DashboradComponent = () => {
  const navigate = useNavigate();
  const { error } = useLocation().state || {};
  const { token, loca, user } = useContext(AutoPortalContext);
  const [loading, setLoading] = useState(true);
  const [json, setJson] = useState({});
  const navigation = useNavigate();


  const getDashBoard = () => {
    axios
      .get(loca + "/lom/get/dashboard/record", {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let res = resp.data;
          console.log(res);
          setJson(res)
          setLoading(false)
        },
        (error) => { console.log(error); }
      )
  }


  useEffect(() => {
    console.log("dashboard");

    getDashBoard()
  }, []);



  const openForm = (tableName, rty, rid) => {
    navigation({
      pathname: user + "/form",
      search: createSearchParams({
        parentApplication: undefined,
        rty: rty,
        tableName: tableName,
        rid: rid,
      }).toString(),
    });
  };

  const openList = (tableName) => {
    navigation({
      pathname: user + "/list",
      search: createSearchParams({
        parentApplication: undefined,
        tableName: tableName,
      }).toString(),
    });
  };

  // useEffect(
  //   useCallback(() => {
  //     console.log("hey");
  //     setNav("setting");
  //   }, [setN])
  // );

  // useEffect(() => {
  //   console.log("kkkkkkk")
  //   axios
  //     .get(loca+`/lom/get/user/client/theme`, {
  //       headers: {
  //         // "Content-Type": "application/json",
  //         authorization: "Bearer " + token,
  //       },
  //     })
  //     .then(
  //       (resp) => {
  //         let res = resp.data;
  //         console.log(res);
  //         // let r=JSON.parse(JSON.stringify(res.style[0].css))
  //         // console.log(r);
  //         // setStyle({...r})
  //         const styleTag = document.createElement("style");
  //         const scriptTag = document.createElement("script");
  //         styleTag.textContent = ".back{" + res.style[0].css + "}";
  //         let gg = "function gg(){navigation('/setting')}";
  //         scriptTag.textContent = gg;
  //         let open = gg.indexOf("{");
  //         let close = gg.indexOf("}");
  //         let ggf = gg.slice(open, close + 1);

  //         console.log(ggf);
  //         // scriptTag.firstChild.nodeValue
  //         document.head.appendChild(styleTag);
  //         // document.body.appendChild(scriptTag);
  //         // console.log(document.activeElement.nodeName)
  //         let ggk = "<p onclick='gg()'>hey</p>";

  //         // eventType(ggk,ggf);
  //       },
  //       (error) => { navigation("/error")
  //         console.log(error);
  //       }
  //     );
  // }, []);

  // useEventListener("onClick",()=>{console.log("heug");})
  // return (
  //   <div style={{ flexGrow: 1, width: "100%" }}>
  // <Tester />
  {/* <button
        onClick={() => {
          navigation("/tester");
        }}
      >
        sub
      </button>
      {error !== "" && error !== undefined && (
        <div className="alert alert-danger">{error}</div>
      )}
      <div className="maindv">
        <div
          className="card text-white mb-3 card-dv"
          style={{
            width: "15rem",
            borderRadius: "16px",
            background: " radial-gradient(#f588d8, #c0a3e5)",
            animation: "",
          }}
        >
          <div
            className="card-header"
            style={{ fontFamily: "bold", fontSize: "20px" }}
          >
            ApplicationUser
          </div>
          <div className="card-body">
            <h5
              className="card-title"
              style={{
                textAlign: "center",
                marginTop: "25px",
                fontSize: "30px",
              }}
            >
              10
            </h5>
          </div>
        </div>
        <div
          className="card text-white  mb-3 secondcard-dv"
          style={{
            width: "15rem",
            marginLeft: "18px",
            borderRadius: "16px",
            background: " radial-gradient(#76b2fe, #b69efe)",
          }}
        >
          <div
            className="card-header"
            style={{ fontFamily: "bold", fontSize: "20px" }}
          >
            Application
          </div>
          <div className="card-body">
            <h5
              className="card-title"
              style={{
                textAlign: "center",
                marginTop: "25px",
                fontSize: "30px",
              }}
            >
              10
            </h5>
          </div>
        </div>
        <div
          className="card text-white  mb-3 "
          style={{
            width: "15rem",
            marginLeft: "18px",
            borderRadius: "16px",
            background: " radial-gradient(#1fe4f5, #3fbafe)",
          }}
        >
          <div
            className="card-header"
            style={{ fontFamily: "bold", fontSize: "20px" }}
          >
            Application Verified
          </div>
          <div className="card-body">
            <h5
              className="card-title"
              style={{
                textAlign: "center",
                marginTop: "25px",
                fontSize: "30px",
              }}
            >
              09
            </h5>
          </div>
        </div>
      </div> */}
  //   </div>
  // );


  //---------------------COMMENTED ON 16-AUGUST-2024-----------------


  // if (!loading) {
  //   return (
  //     <div style={{ flexGrow: 1, width: "100%" }}>
  //       <div>
  //         {/* {<button onClick={()=>{navigation("/tester")}} >tester</button>} */}
  //         {json.dashboard.row.length > 0 && json.dashboard.row.map((ro, ri) => (
  //           <div className="d-flex flex-row container" key={ri}>
  //             {ro.col.map((co, ci) => (
  //               <div
  //                 id={co.compo.type + ri + ci}
  //                 className="m-2 card card-body col-md-2"
  //                 key={ci}
  //               >
  //                 {co.compo.type === "form" && (
  //                   <div>
  //                     <div style={{ maxHeight: "500px", overflow: "auto" }}>
  //                       <FormComponent
  //                         dash_tabname={co.compo.props.tableName}
  //                         dash_rty={co.compo.props.rty}
  //                         dash_rid={co.compo.props.rid}
  //                         form_id={co.compo.type + ri + ci}
  //                       />
  //                     </div>
  //                     <div>
  //                       <button
  //                         onClick={() =>
  //                           openForm(
  //                             co.compo.props.tableName,
  //                             co.compo.props.rty,
  //                             co.compo.props?.rid
  //                           )
  //                         }
  //                       >
  //                         open form
  //                       </button>
  //                     </div>
  //                   </div>
  //                 )}
  //                 {co.compo.type === "list" && (
  //                   <div>
  //                     <div style={{ maxHeight: "500px", overflow: "auto" }}>
  //                       <ListComponent
  //                         dash_parentApplication={co.compo.props.parentApp}
  //                         dash_tableName={co.compo.props.listName}
  //                         list_id={co.compo.type + ri + ci}
  //                       />
  //                     </div>
  //                     <div>
  //                       <button onClick={() => openList(co.compo.props.listName)}>
  //                         open List
  //                       </button>
  //                     </div>
  //                   </div>
  //                 )}
  //                 {co.compo.type === "report" && (
  //                   <ReportCompo
  //                     report_name={co.compo.props.reportName}
  //                     dash_parentApplication={co.compo.props.parentApp}
  //                   />
  //                 )}
  //               </div>
  //             ))}
  //           </div>
  //         ))}
  //       </div>
  //     </div >
  //   );
  // } else {
  //   <div className="compo-loader">
  //     <WorkInProgress />
  //   </div>
  // }

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <div
        className="ps-3"
        style={{ position: "absolute", width: "80%", height: "100%" }}
      >
        <div className="mt-5 ms-5">
          <div>
            <h2 className="fw-bold text-white" style={{ fontFamily: "math" }}>
              Build apps that solve business problems faster.
            </h2>
          </div>
          <div className="mt-3">
            <h4 className="text-white fw-light">
              Rapidly build, test, and deploy applications that make work better
              for your organization. Start building on Now Platform with a free
              account.
            </h4>
          </div>
          <div className="mt-3">
            <button className="btn btn-warning fw-bold" onClick={() => {
              navigate("/form?parentApplication=Autoportal&rty=new&tableName=application")
            }}>
              Start Building Application
            </button>
          </div>
        </div>
      </div>
      <img
        style={{
          width: "100%",
          height:"100%",
          position: "absolute",
          top: "0px",
          left: "0px",
          zIndex: "-1",
        }}
        src={db}
      />
    </div>
  );
};

export default DashboradComponent;
