import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom';
import DateFormatter from './DateFormatter';
import "../css/Invoice.css";
import { useReactToPrint } from "react-to-print";

function Invoice({ inv, rcd, setInvRcd }) {

  // const { inv, rcd } = useLocation().state;
  console.log(rcd);

  const [seller, setSeller] = useState(inv.SellerDetails);
  const [buyer, setBuyer] = useState(inv.BuyerDetails);
  const [transport, setTransport] = useState(inv.TransporterDetails);
  const [words, setWords] = useState();
  const [taxInWords, setTaxInWords] = useState();
  const [taxAmount, setTaxAmount] = useState(0);
  const [heading, setHeading] = useState([]);
  const invoiceRef = useRef();

  useEffect(() => {
    console.log("use effect");
    var head = [];
    var colr = rcd.record[0].colr;
    for (let i = 0; i < colr.length; i++) {
      if (colr[i].name != "GST Ledger" && colr[i].name != "Tax Type" && colr[i].name != "Tax Amount") {
        head.push(colr[i].name);
      }
    }
    setHeading(head);
    const words = convertNumberToWords(rcd.record[2].colr[5].value);
    setWords(words);
    setTaxAmount(Number(rcd.record[rcd.record.length - 1].colr[3].value) + Number(rcd.record[rcd.record.length - 1].colr[1].value) + ".00");

  }, [])

  useEffect(() => {
    const taxWords = convertNumberToWords(taxAmount);
    console.log(taxWords);
    setTaxInWords(taxWords);
    setTimeout(() => {

      if (heading.length > 0 && invoiceRef.current) {
        invoiceRef.current.style.display = "block";
        handlePrint();
        setInvRcd(null);
        // invoiceRef.current.style.display = "none";
      }
    }, 500);
  }, [heading])


  const handlePrint = useReactToPrint({
    content: () => invoiceRef.current,
  });

  const convertNumberToWords = (number) => {
    let num = number.toString().split('.')[0];
    console.log(num);
    const ones = [
      "",
      "One ",
      "Two ",
      "Three ",
      "Four ",
      "Five ",
      "Six ",
      "Seven ",
      "Eight ",
      "Nine ",
      "Ten ",
      "Eleven ",
      "Twelve ",
      "Thirteen ",
      "Fourteen ",
      "Fifteen ",
      "Sixteen ",
      "Seventeen ",
      "Eighteen ",
      "Nineteen ",
    ];
    const tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ];

    if ((num = num.toString()).length > 9)
      return "Overflow: Maximum 9 digits supported";
    const n = ("000000000" + num)
      .substr(-9)
      .match(/^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
    if (!n) return "";
    let str = "";
    str +=
      n[1] !== "00"
        ? (ones[Number(n[1])] || tens[n[1][0]] + " " + ones[n[1][1]]) + "Crore "
        : "";
    str +=
      n[2] !== "00"
        ? (ones[Number(n[2])] || tens[n[2][0]] + " " + ones[n[2][1]]) + "Lakh "
        : "";
    str +=
      n[3] !== "00"
        ? (ones[Number(n[3])] || tens[n[3][0]] + " " + ones[n[3][1]]) +
        "Thousand "
        : "";
    str +=
      n[4] !== "0"
        ? (ones[Number(n[4])] || tens[n[4][0]] + " " + ones[n[4][1]]) +
        "Hundred "
        : "";
    str +=
      n[5] !== "00"
        ? (str !== "" ? "and " : "") +
        (ones[Number(n[5])] || tens[n[5][0]] + " " + ones[n[5][1]])
        : "";
    return str;
  };

  console.log(seller, buyer, transport);

  return (
    <div style={{ visibility: "hidden", position: "absolute" }}>
      {/* <div > */}
      {inv && (
        <div className="container container-inside" >
          <div className="carddss text-capitalize invoice-resp-f-size" style={{ width: "775px" }}>
            <div
              style={{ display: "none" }}
              className="cardchildd"
              ref={invoiceRef}
            >
              <div className="cardchild2">
                <h4>Tax Invoice </h4>
                <div className="userdetails">
                  <ul className="ul-nopadding text-start item" style={{ listStyleType: "none" }}>
                    {/* <li className=""> */}
                    <li className="list">
                      <li className="fw-bold list1 dcolor">
                        <span>
                          {seller.seller}
                        </span>
                      </li>
                      <li className="text-muted">
                        <span className="dcolor">
                          {seller.address}
                        </span>
                      </li>
                      <li className="text-muted">
                        <span className="dcolor"> MOB: </span>
                        <span className="dcolor">
                          {seller.mobile}
                        </span>
                      </li>
                      <li className="text-muted">
                        <span className="dcolor"> GSTIN: </span>
                        <span className="dcolor">
                          {seller.gstin}
                        </span>
                      </li>
                      <li className="text-muted">
                        <span className="dcolor"> State Name: </span>
                        <span className="dcolor">
                          {seller.state}
                        </span>
                      </li>
                      <li className="text-muted">
                        <span className="dcolor"> E-Mail: </span>
                        <span className="dcolor">
                          {seller.email}
                        </span>
                      </li>
                    </li>
                  </ul>
                  <ul className="lastul ul-nopadding  text-start item">
                    <li className="fourthlist list">
                      <li className="text-muted list1">
                        {/* <i className="fa fa-circle iconss" /> */}
                        <span className="dcolor">Invoice No: </span>
                        <span className="fw-bold dcolor">
                          {inv.InvNumber}
                        </span>
                      </li>
                      <li className="text-muted">
                        <span className="dcolor">
                          Dated: </span>
                        <span className="fw-bold dcolor">
                          <DateFormatter date={inv.date} />
                        </span>
                      </li>
                      {inv.challanNo !== "" &&
                        <>
                          <li className="text-muted">
                            <span className="dcolor">
                              Challan No: </span>
                            <span className="fw-bold dcolor">
                              {inv.ChallanNumber}
                            </span>
                          </li>
                          <li className="text-muted">
                            <span className="dcolor">
                              Challan Date: </span>
                            <span className="fw-bold dcolor">
                              <DateFormatter date={inv.ChallanDate} />
                            </span>
                          </li>
                        </>
                      }
                      {/* <li className="text-muted">
                        <i className="fa fa-circle iconss" />
                        <span className="fw-bold ms-1 dcolor">Status:</span>
                        <span className="badge bg-warning text-black fw-bold ms-1 dcolor">
                          {details.status}
                          {"paid".slice(0, 1).toUpperCase() + "paid".slice(1)}
                        </span>
                      </li> */}
                    </li>
                  </ul>
                </div>
                <div className="userdetails2nd">
                  <ul className="row ul-nopadding text-start item" style={{ listStyleType: "none" }}>
                    <li className="thirdlist list">
                      <li className="text-muted  thirdlist1 ">
                        <span className="dcolor">
                          Details of receiver (Billed to)
                        </span>
                      </li>
                      <li className="text-muted fw-bold">
                        <span className="dcolor">
                          {buyer.buyer}
                        </span>
                      </li>
                      <li className="text-muted">
                        <span className="dcolor">
                          {buyer.address}
                        </span>
                      </li>
                      <li className="text-muted">
                        <span className="dcolor"> GSTIN: </span>
                        <span className="dcolor">
                          {buyer.gstin}
                        </span>
                      </li>
                      <li className="text-muted">
                        <span className="dcolor"> State Name: </span>
                        <span className="dcolor">
                          {buyer.state}
                        </span>
                      </li>
                    </li>
                  </ul>
                  <ul className="ul-nopadding text-start item" style={{ listStyleType: "none" }}>
                    {/* <li className=""> */}
                    <li className="list">
                      <li className="text-muted list1">
                        <span className="dcolor"> Transport: </span>
                        <span className="dcolor">
                          {transport.name}
                        </span>
                      </li>
                      <li className="text-muted">
                        <span className="dcolor"> Vehicle No: </span>
                        <span className="dcolor">
                          {transport.vehicleNo}
                        </span>
                      </li>
                      <li className="text-muted">
                        <span className="dcolor"> Date : </span>
                        <span className="dcolor">
                          {transport.date}
                        </span>
                      </li>
                      <li className="text-muted">
                        <span className="dcolor"> Destination: </span>
                        <span className="dcolor">
                          {transport.destination}
                        </span>
                      </li>
                    </li>
                  </ul>
                </div>
                {console.log(heading)}
                {/* <table className="table table-bordered brColor" style={{ fontSize: "x-small" }}> */}
                <table className="brColor" style={{ fontSize: "smaller" }}>
                  <thead className="text-ctr" style={{ borderBottom: "0.5px solid black" }}>
                    <tr >
                      {heading.length > 0 && heading.map((obj, ind) => (
                        <th key={ind} scope="col" style={obj === "Description" ? { width: "55.8%" } : {}}>{obj}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {rcd.record.map((obj, index) => (
                      <>
                        {index !== rcd.record.length - 1 &&
                          <tr key={index}>
                            {obj.colr.map((item, indx) => (
                              <>
                                {
                                  item.name !== "GST Ledger" && item.name !== "Tax Type" && item.name !== "Tax Amount" &&
                                  <td key={indx} className={index !== rcd.record.length - 2 ? "text-ctr" : "fw-bold text-ctr dcolor"}
                                    style={(index === rcd.record.length - 2 && { borderTop: "0.5px solid black" })
                                      || (indx === 0 && { fontFamily: "math" })
                                      || {}} >
                                    {item.value}
                                    {index === rcd.record.length - 3 &&
                                      <>
                                        <br></br><br></br><br></br><br></br><br></br>
                                      </>
                                    }
                                  </td >
                                }
                              </>
                            ))}
                          </tr >
                        }
                      </>
                    ))}
                  </tbody>
                  <tfoot>
                    {/* <tr className="">
                      <td colSpan="6" className="fw-bold text-start dcolor">
                        Total
                      </td>
                      <td className="allTotal dcolor">
                        ₹{details.totalCost}
                      </td>
                    </tr> */}
                    {/* <tr className="" style={{ borderTop: "0.5px solid black" }}>
                      <td colSpan="1" className="fw-bold text-ctr dcolor">
                        Amount In Word :{" "}
                      </td>
                      <td
                        colSpan="6"
                        className="textinword fw-bold text-start dcolor"
                      >
                        Indian Rupees {words} only
                      </td>
                    </tr> */}
                  </tfoot>
                </table>
                {/* <table className="table table-bordered brColor" style={{ fontSize: "x-small" }}> */}
                <table className="brColor" style={{ fontSize: "x-small" }}>
                  <thead className="text-ctr" style={{ borderBottom: "0.5px solid black" }}>
                    {/* <tr className=" table-dark"> */}
                    <tr >
                      <th scope="col" style={{ width: "55.8%" }}>Description</th>
                      <th scope="col">Taxable Value</th>
                      <th scope="col">Tax</th>
                      <th scope="col">Total Tax Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rcd.record.map((ob, index) => (
                      <>
                        {index !== rcd.record.length - 1 && index !== rcd.record.length - 2 &&
                          <tr className="text-ctr" key={index} >
                            {ob.colr.map((objj, indx) => (
                              <>
                                {(indx === 0 || objj.name === "Amount") &&
                                  <td key={indx} className="pos-rel ">
                                    <div value={objj.value}>
                                      {objj.value}
                                    </div>
                                  </td>
                                }
                                {objj.name === "GST Ledger" &&

                                  <td className="pos-rel" >
                                    <table className="inner-table" style={index < rcd.record.length - 3 ? { borderBottom: "0.5px solid black" } : {}}>
                                      {/* <table class="inner-table"> */}
                                      {(ob.colr[7].value === "Central Tax" || ob.colr[7].value === "State Tax") &&
                                        <>
                                          <tr className='fw-bold'>
                                            <td style={{ width: '50%' }} className="gst-td">{ob.colr[7].value}</td>
                                            {index === 0 ?
                                              <>
                                                <td style={{ borderBottom: "0.5px solid black" }} className="gst-td">Rate</td>
                                                <td style={{ width: '30%', borderBottom: "0.5px solid black" }} className="gst-td">Amount</td>
                                              </> : <>
                                                <td className="gst-td"></td>
                                                <td style={{ width: '30%' }} className="gst-td"></td>
                                              </>
                                            }
                                          </tr>
                                          <tr>
                                            <td style={{ width: '50%' }} className="gst-td">CGST</td>
                                            <td className="gst-td">{Number(objj.value.replace(/%/g, '')) / 2 + "%"}</td>
                                            <td style={{ width: '30%' }} className="gst-td">{Number(ob.colr[8].value) / 2}</td>
                                          </tr>
                                          <tr>
                                            <td style={{ width: '50%' }} className="gst-td">SGST</td>
                                            <td className="gst-td">{Number(objj.value.replace(/%/g, '')) / 2 + "%"}</td>
                                            <td style={{ width: '30%' }} className="gst-td">{Number(ob.colr[8].value) / 2}</td>
                                          </tr>
                                        </>
                                      }
                                      {ob.colr[7].value === "Integrated Tax" &&
                                        <>
                                          <tr className='fw-bold'>
                                            <td style={{ width: '50%' }} className="gst-td">{ob.colr[7].value}</td>
                                            {index === 0 ?
                                              <>
                                                <td style={{ borderBottom: "0.5px solid black" }} className="gst-td">Rate</td>
                                                <td style={{ width: '30%', borderBottom: "0.5px solid black" }} className="gst-td">Amount</td>
                                              </> : <>
                                                <td className="gst-td"></td>
                                                <td style={{ width: '30%' }} className="gst-td"></td>
                                              </>
                                            }
                                          </tr>
                                          <tr>
                                            <td style={{ width: '50%' }} className="gst-td">IGST</td>
                                            <td className="gst-td">{objj.value}</td>
                                            <td style={{ width: '30%' }} className="gst-td">{ob.colr[8].value}</td>
                                          </tr>
                                        </>
                                      }
                                    </table>
                                    {/* <div value={objj.value}>
                                                                {objj.value}
                                                            </div> */}
                                  </td >
                                }
                                {objj.name === "Tax Amount" &&
                                  <td className="pos-rel ">
                                    <div value={objj.value}>
                                      {objj.value}
                                    </div>
                                  </td>}
                              </>
                            ))}
                          </tr >
                        }
                      </>
                    ))}
                    <tr style={{ borderTop: "0.5px solid black" }}>
                      {rcd.record[rcd.record.length - 1].colr.map((obj, ind) => (
                        <td>
                          <div className="lom_flx fw-bold" key={ind} value={obj.value}>
                            {obj.value}
                          </div>
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
                <div >
                  <text style={{ fontSize: "13px" }}>
                    <span className=" ms-1 dcolor">Amount Chargeable: </span>
                    <span className="fw-bold dcolor">
                      ₹ {taxAmount}
                    </span>
                  </text>
                </div >
                <div >
                  {console.log(taxInWords)}
                  <text style={{ fontSize: "13px" }}>
                    <span className=" ms-1 dcolor">Amount Chargeable (in words) : </span>
                    <span className="fw-bold dcolor">
                      Indian Rupees {taxInWords} only
                    </span>
                  </text>
                </div >
                <div >
                  <text style={{ fontSize: "13px" }}>
                    <span className=" ms-1 dcolor">company's PAN: </span>
                    <span className="fw-bold dcolor">
                      {seller.companysPan}
                    </span>
                    {/* <text className="dcolor" style={{ fontSize: "15px" }}>company's PAN: </text> */}
                  </text>
                </div>
                <div className="aftertablediv list">
                  <div className="aftertblclddiv">
                    {" "}
                    <div className="in2">
                      <text
                        className="fw-bold dcolor"
                        style={{ fontSize: "15px" }}
                      >
                        Terms and Condition
                      </text>
                    </div>
                  </div>
                  <div className='terms'>
                    <ul className="text-start item" style={{ listStyleType: "none", padding: "0" }}>
                      {/* <li className=""> */}
                      <li className="">
                        <li className="text-muted list1">
                          <span className="dcolor">
                            1. Payment is due within 30 days of the invoice
                            date.
                          </span>
                        </li>
                        <li className="text-muted">
                          <span className="dcolor">
                            2. All products are sold as-is and are
                            non-refundable.
                          </span>
                        </li>
                        <li className="text-muted">
                          <span className="dcolor">3. Any disputes must be resolved within 60 days.  </span>
                        </li>
                        {/* <li className="text-muted">
                          <span className="dcolor"> GSTIN: </span>
                        </li>
                        <li className="text-muted">
                          <span className="dcolor"> State Name: </span>
                        </li>
                        <li className="text-muted">
                          <span className="dcolor"> E-Mail: </span>
                        </li> */}
                      </li>
                    </ul>
                    <ul className="text-start item" style={{ listStyleType: "none", padding: "0" }}>
                      <li className="fourthlist">
                        <li className="text-muted list1">
                          <span className=" ms-1 dcolor">company's Bank Details</span>
                        </li>
                        <li className="text-muted">
                          <span className="ms-1 dcolor label">
                            A/c Holder's Name</span>
                          <span className="ms-1 dcolor colon">
                            : </span>
                          <span className="fw-bold dcolor value">
                            {seller.accHolderName}
                          </span>
                        </li>
                        {inv.challanNo !== "" &&
                          <>
                            <li className="text-muted">
                              <span className="ms-1 dcolor label">
                                Bank Name</span>
                              <span className="ms-1 dcolor colon">
                                : </span>
                              <span className="fw-bold dcolor value">
                                {seller.bankName}
                              </span>
                            </li>
                            <li className="text-muted">
                              <span className="ms-1 dcolor label">
                                A/c No </span>
                              <span className="ms-1 dcolor colon">
                                : </span>
                              <span className="fw-bold dcolor value">
                                {seller.accNumber}
                              </span>
                            </li>
                            <li className="text-muted">
                              <span className="ms-1 dcolor label">
                                Branch & IFSC Code</span>
                              <span className="ms-1 dcolor colon">
                                : </span>
                              <li className="fw-bold dcolor value">
                                {seller.branchAndIfsc}
                              </li>
                            </li>
                          </>
                        }
                      </li>
                    </ul>
                  </div>
                  <div className='text-center' style={{ border: "0.5px solid black" }}>
                    <div className='row' style={{ margin: "0px" }}>
                      <div className="col" style={{ padding: "0px" }}>
                        <div style={{ paddingTop: "2.8rem" }}>Received by</div>
                      </div>
                      <div className="col" style={{ padding: "0px" }}>
                        <div style={{ paddingTop: "2.8rem", paddingRight: "1.5rem" }}>Prepared by</div>
                      </div>
                      <div className="col" style={{ padding: "0px" }}>
                        <div className='fw-bold' style={{ marginTop: "3px", paddingRight: "1.5rem" }}> For {seller.seller} </div>
                        <div style={{ paddingTop: "1.7rem", paddingRight: "1.5rem" }}>Authorised Signatory</div>
                      </div>
                    </div>
                  </div>
                  {/* <table className="table footertable" style={{ border: "1px solid" }}>
                    <thead>
                      <tr className="border-dark" style={{ verticalAlign: "bottom", justifyContent: "center" }}>
                        <th scope="col dcolor " style={{ padding: "0" }}>
                          Received by
                        </th>
                        <th scope="col dcolor" style={{ padding: "0" }}>
                          Prepared by
                        </th>
                        <th scope="col dcolor " style={{ padding: "0" }}>
                          <span className="col dcolor" style={{ display: "block", marginBottom: "26px" }}>
                            For {seller.seller}
                          </span>
                          Authorised Signatory
                        </th>
                      </tr>
                    </thead>
                  </table> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
      }
    </div >
  )
}

export default Invoice