import React, { useState, useContext, useEffect, useRef } from "react";
import { AutoPortalContext } from "../Context";
import WorkInProgress from "./WorkInProgress";
// import { useLocation, useNavigate } from "react-router-dom";
import "../css/Uipolicy.css";
import "../css/ListComponent.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const UiPolicy = () => {
  console.log("gggg");

  const navigation = useNavigate();
  const { token,loca } = useContext(AutoPortalContext);
  const [loading, setLoading] = useState(false);
  let oneRender = useRef(true);
  //   const {listName} = useLocation().state;
  // const onerender = useRef(false);
  const [active, setActive] = useState(false);
  const [record, setRecord] = useState([]);
  // const [name, setName] = useState("");
  // const [col_mn, setCol_mn] = useState([]);
  // const [list, setlist] = useState([]);
  // const [showlist, setShowList] = useState(false);
  // const [showbtn, setShowbtn] = useState(false);
  // const [showupdownbttn, setShowUpDownbtn] = useState(false);
  // const [int_fields, setInt_Fields] = useState([]);
  // const [email_fields, setEmail_Fields] = useState([]);
  // const [date_fields, setDate_Fields] = useState([]);
  // const [boln_fields, setBoln_Fields] = useState([]);
  // const [str_fields, setStr_Fields] = useState([]);
  // const [ref_fields, setRef_Fields] = useState([]);
  // const [booleanfld, setBooleanFld] = useState([]);
  // const [tm_list, setTm_List] = useState([]);
  // const [button, setButton] = useState([]);
  const [mainRecord, setMainRecord] = useState({});
  // const [sortColumn, setSortColumn] = useState({ name: "", sort: false });
  // const [prefCall, setPrefCall] = useState([]);
  // const [filter_unset, setFilter_Unset] = useState(false);
  // const [filtarray, setFiltArray] = useState([]);
  // const [filString, setfilString] = useState("");
  // const [filter, setFilter] = useState();
  // const [showFilter, setShowFilter] = useState(false);
  const [filt, setFilt] = useState({
    co: "",
    cl: "",
    mc: "",
    an: "",
    ct: "",
    af: "",
  });
  // const [page_error, setPage_Error] = useState(false);
  // const [error, setError] = useState("");
  // const [pageMessage, setPageMessage] = useState(false);
  // const [message, setMessage] = useState("");
  // const [timeline, setTimeline] = useState("");
  // const [ClearButtonLoading, setClearButtonLoading] = useState(false);
  // const [RunButtonLoading, setRunButtonLoading] = useState(false);
  // const [pageClicked, setPageClicked] = useState(0);
  // const [pageCount, setPageCount] = useState(0);
  // const [pageRecords, setPageRecords] = useState("");
  // const [recordCount, setRecordCount] = useState(0);
  // const [listName, setListName] = useState(useLocation().state.tableName);
  // const [showPref, setShowPref] = useState(false);
  const [tableList, setTableList] = useState([]);
  const [setTab] = useState(false);

  useEffect(() => {
    if (oneRender.current) {
      getTableName();
      getFormDetails();
    } else {
      oneRender.current = false;
    }
  }, []);

  const getFormDetails = () => {
    axios
      .get(loca + `/lom/get/blankrecord/uipolicy`, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          const blkrecord = resp.data;
          console.log(JSON.stringify(blkrecord));

          // setState({
          // 	loading: false,
          // 	record: blkrecord.formRecord[2].record,
          // 	button: blkrecord.formRecord[3].button,
          // });
          // const uiScript=JSON.parse(blkrecord.formRecord[3].uiscript[0])
          // console.log("script : "+JSON.stringify(uiScript));
          // var fnString="const fn=()=>console.log('hey javaScript!!!!!!!!!')"

          // let script = blkrecord.formRecord[4].uiscript;

          // // onload
          // for (let i = 0; i < script.length; i++) {
          //   let field = script[i].field.name;
          //   let func = script[i].script;
          //   let type = script[i].type;
          //   if (type === "onload" && count.current === 1) {
          //     count.current = 0;
          //     onLoad(func);
          //   }
          // }
          // setUiScript([...script]);

          setLoading(false);
          setMainRecord(blkrecord);
          setRecord(blkrecord.formRecord[2].record);
          console.log(
            "recorddddddddddddddddd : " +
              JSON.stringify(blkrecord.formRecord[2].record)
          );
          // setap_form(new ApForm(blkrecord.formRecord[2].record, setRecord));
        },
        (error) => { navigation("/error")
          console.log(error);
        }
      );
  };

  const getTableName = () => {
    axios
      .get(loca + "/lom/get/tables", {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let tableList = resp.data;
          setTableList(tableList.tableRecords);
          setTab(true);
        },
        (error) => { navigation("/error")
          console.log(error);
        }
      );
  };

  const changeActive = (e) => {
    let frecord = record;
    console.log(e);
    setActive(e);
    for (let i = 0; i < frecord.length; i++) {
      if (frecord[i].name === "active") {
        if (e) {
          frecord[i].value = "true";
          break;
        } else {
          frecord[i].value = "false";
          break;
        }
      }
      setRecord([...frecord]);
    }
  };

  if (loading === false) {
    return (
      <div style={{ flexGrow: 1 }} className="Card cardcss">
        <h1 className="hhh">UiPolicy</h1>
        <div className="" style={{ display: "flex" }}>
          <div className="ttt">
            <text>Table</text>
          </div>
          <select
            className=" inpttt "
            aria-label="Default select example"
            // onChange={(e) => changeApp(e.target.value)}
          >
            <option>None</option>
            {tableList.map((obj, index) => (
              <option key={index} value={obj.name}>
                {obj.label}
              </option>
            ))}
          </select>
          <div style={{ display: "flex", marginTop: "5px" }}>
            <div className="ttt">
              <text>Name</text>
            </div>
            <input
              type="text"
              className=" inpttt2 "
              aria-label="Default select example"
            ></input>
          </div>
        </div>
        <div className="divvvvvcheck">
          <div className="ttt">
            <text>Active</text>
          </div>
          <input
            type="checkbox"
            className=" inpcheckbox "
            aria-label="Default select example"
            checked={active}
            onChange={(e) => changeActive(e.target.checked)}
          ></input>
        </div>

        <div className="">
          <input
            type="button"
            className="submitbtnnnnnn"
            value="submit"
            onClick={() => console.log(JSON.stringify(record))}
          ></input>
        </div>
        <div style={{ marginTop: "22px" }}>
          <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className="nav-link active"
                id="UiPolicy Action-tab"
                data-bs-toggle="tab"
                data-bs-target="#UiPolicy Action"
                type="button"
                role="tab"
                aria-controls="UiPolicy Action"
                aria-selected="true"
              >
                UiPolicy Action
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className="nav-link"
                id="UiPolicy Related List Actions-tab"
                data-bs-toggle="tab"
                data-bs-target="#UiPolicy Related List Actions"
                type="button"
                role="tab"
                aria-controls="UiPolicy Related List Actions"
                aria-selected="true"
              >
                UiPolicy Related List Actions
              </button>
            </li>
          </ul>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="UiPolicy Action"
              role="tabpanel"
              aria-labelledby="UiPolicy Action-tab"
            ></div>
            <div
              className="tab-pane fade"
              id="UiPolicy Related List Actions"
              role="tabpanel"
              aria-selected="true"
              aria-labelledby="UiPolicy Related List Actions-tab"
            ></div>
          </div>
        </div>
      </div>
    );
  } else {
    <div className="compo-loader">
      <WorkInProgress />
    </div>;
  }
};
export default UiPolicy;
