import React, { useEffect, useState ,useRef  ,useContext} from 'react'
import { AutoPortalContext } from "../Context";
import axios from "axios";
import "../css/tabularReport.css"
import WorkInProgress from "./WorkInProgress";

import {
  
    useSearchParams,
  } from "react-router-dom";




function TabularReportCompo() {
    const [reportlist , setReportList ] =useState();
    const  [ singleCol , setSingleCol] =useState();
    const [searchParam, setSearchParam] = useSearchParams();
    const [singleColumn , setSingleColumn] =useState();
    const [recordColumn , setRecordColumn] =useState();
    const [nm_list , setnm_List] =useState();
   

    const {loca ,token} =
    useContext(AutoPortalContext);

    let tableName = useRef("");
    let rty = useRef("");
    let rid=useRef("");
    rty.current = searchParam.get("rty");
    let tabname = searchParam.get("tableName");
    tableName.current = tabname;
    rid.current=searchParam.get("rid");

    useEffect(()=>{
        console.log(rty);
        console.log(tableName);
        console.log(rid);

        if (rid.current !== "" || rid.current !== "none" || rid.current !== undefined) {
        
            console.log(" type of " , typeof rid.current);
     
            let id=rid.current
            axios
                .get(loca + `/lom/get/tabular/report/${id}` , {
                    headers: {
                        authorization: "Bearer " + token,
                      },
                })
                .then((res) => {
                    const rcd = res.data;
                    console.log("response: ", rcd);
                    if(rcd !=null)
                    {
                        if ("Error" in rcd) {
                               
                    }else{
                        var nm_list = [];;
                        for (var i = 0; i < rcd.tabularRecord[0].recordColumn[0].record.length; i++) {
                            nm_list.push(rcd.tabularRecord[0].recordColumn[0].record[i].name);
                        }

                        setnm_List(nm_list);
                        setSingleColumn(rcd.tabularRecord[1].singleColumn);
                        setRecordColumn(rcd.tabularRecord[0].recordColumn);
                        setReportList(rcd)


                    }

                }});
        }

    },[])

   return (
    <div className="pagesetup">
 
        <div>
            <div className="row">
                <div className="col-md-1"></div>
                <div className="col-md-3"></div>
                <div className="col-md-3 heading">
                    <label>Tabular Report</label>
                </div>
           
            </div>

            <div >
                <div className="container">
                    <div className="rpt_border rddd">
                       {singleColumn && (
                            <table>
                                <thead>
                                    {singleColumn.map((obj, index) => (
                                        <tr key={index}>
                                            <td className="tdddd">{obj.name}</td>
                                            <td className="tddd1">{obj.value}</td>
                                        </tr>
                                    ))}
                                </thead>
                            </table>
                        )}
                        <div className="rpt_border">
                           {recordColumn && (
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            {nm_list.map((obj, index) => (
                                                <th key={index}>{obj}</th>
                                            ))}
                                        </tr>
                                        {recordColumn.map((obj, index) => (
                                            <tr key={index}>
                                                {obj.record.map((obj_in, index_in) => (
                                                    <td key={index_in}>{obj_in.value}</td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
</div>
   )
}

export default TabularReportCompo