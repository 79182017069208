import React from 'react'
import { Button, Dropdown, Modal } from "react-bootstrap";

export default function CommonModal({show, hide, title, body, footer}) {
  return (
    <Modal show={show} onHide={hide}>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body>{body}</Modal.Body>
    <Modal.Footer>{footer}</Modal.Footer>
  </Modal>
  )
}
