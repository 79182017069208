import React from 'react'
  
  export default function DateFormatter({date}) {
    const dateObject = new Date(date);
  
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1; // Adding 1 since months are 0-based (0 for January)
    const year = dateObject.getFullYear();
  
    return `${day}/${month}/${year}`;
  }
  