import axios from "axios";
import { AutoPortalContext } from "../Context";
import React, { useContext, useState, useEffect } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { EventSourcePolyfill } from "event-source-polyfill";
import WorkInProgress from "./WorkInProgress";
import { Navigate, useNavigate } from "react-router";
import "../css/initialCSS.css";

const InitialSetupcomponent = () => {
  const { token, loca, getVerify, setReloadLeft, user } =
    useContext(AutoPortalContext);
  const [initButton1, setInitButton1] = useState(false);
  const [initButton2, setInitButton2] = useState(false);
  const [initButton3, setInitButton3] = useState(false);
  const [initButton4, setInitButton4] = useState(false);
  const [initButton5, setInitButton5] = useState(false);
  const [initButton6, setInitButton6] = useState(false);
  const [isProcessing, setProcessing] = useState([
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
  ]);
  const [initRecordButton, setInitRecordButton] = useState(false);
  const [currentAppButton, setCurrentAppButton] = useState(false);
  const [disableArr, setDisableArr] = useState([
    { state: "true" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
    { state: "false" },
  ]);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const [now, setNow] = useState(0);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("Yasmin");
    // setSubscribe();
  }, []);

  const initialsetuprecordcallapi = () => {
    let proArr = isProcessing;
    proArr[0].state = "true";
    setProcessing([...proArr]);
    setShowProgressBar(true);
    setNow(10);
    axios
      .get(loca + "/lom/initial/setuprecord", {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          proArr[0].state = "false";
          setProcessing([...proArr]);
          const modul = res.data;
          let arr = disableArr;
          arr[0].state = "success";
          arr[1].state = "true";
          setDisableArr(arr);
          setInitRecordButton(true);
          setNow(100);
          setShowProgressBar(false);
          console.log(res);
          initialsetupcallapi1();
        }
      });
  };

  const initialdisplaynull = () => {
    console.log("initial inn");
    axios
      .get(loca + "/lom/initialsetup/delete", {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        console.log("initial resp");
        if (res.status === 200) {
          console.log(" ress status");
          const modul = res.data;
          if ("result" in modul) {
            console.log("in here");
            setReloadLeft(true);
          }
        }
      });
  };
  const initialsetupcallapi1 = () => {
    // setShowProgressBar(true);
    let proArr = isProcessing;
    proArr[1].state = "true";
    setProcessing([...proArr]);
    setLoading(true);
    setNow(0);
    axios
      .get(loca + "/lom/initial/setup1", {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          proArr[1].state = "false";
          setProcessing([...proArr]);
          const modul = res.data;
          let arr = disableArr;
          arr[1].state = "success";
          arr[2].state = "true";
          setDisableArr(arr);
          setInitButton1(true);
          setNow(100);
          // setShowProgressBar(false);
          setLoading(false);
          console.log("hifzur" + res);
          initialsetupcallapi2();
        }
      });
  };

  const initialsetupcallapi2 = () => {
    // setShowProgressBar(true);
    let proArr = isProcessing;
    proArr[2].state = "true";
    setProcessing([...proArr]);
    setLoading(true);
    setNow(0);
    axios
      .get(loca + "/lom/initial/setup2", {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          proArr[2].state = "false";
          setProcessing([...proArr]);
          const modul = res.data;
          let arr = disableArr;
          arr[2].state = "success";
          arr[3].state = "true";
          setDisableArr(arr);
          setInitButton2(true);
          setNow(100);
          // setShowProgressBar(false);
          setLoading(false);
          console.log("hifzur" + res);
          initialsetupcallapi3();
        }
      });
  };

  const initialsetupcallapi3 = () => {
    let proArr = isProcessing;
    proArr[3].state = "true";
    setProcessing([...proArr]);
    // setShowProgressBar(true);
    setLoading(true);
    setNow(0);
    axios
      .get(loca + "/lom/initial/setup3", {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          proArr[3].state = "false";
          setProcessing([...proArr]);
          const modul = res.data;
          let arr = disableArr;
          arr[3].state = "success";
          arr[4].state = "true";
          setDisableArr(arr);
          setInitButton3(true);
          setNow(100);
          // setShowProgressBar(false);
          setLoading(false);
          console.log("hifzur" + res);
          initialsetupcallapi4();
        }
      });
  };

  const initialsetupcallapi4 = () => {
    let proArr = isProcessing;
    proArr[4].state = "true";
    setProcessing([...proArr]);
    // setShowProgressBar(true);
    setLoading(true);
    setNow(0);
    axios
      .get(loca + "/lom/initial/setup4", {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          proArr[4].state = "false";
          setProcessing([...proArr]);
          const modul = res.data;
          let arr = disableArr;
          arr[4].state = "success";
          arr[5].state = "true";
          setDisableArr(arr);
          setInitButton4(true);
          setNow(100);
          // setShowProgressBar(false);
          setLoading(false);
          console.log("hifzur" + res);
          initialsetupcallapi5();
        }
      });
  };

  const initialsetupcallapi5 = () => {
    let proArr = isProcessing;
    proArr[5].state = "true";
    setProcessing([...proArr]);
    // setShowProgressBar(true);
    setLoading(true);
    setNow(0);
    axios
      .get(loca + "/lom/initial/setup5", {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          proArr[5].state = "false";
          setProcessing([...proArr]);
          const modul = res.data;
          let arr = disableArr;
          arr[5].state = "success";
          arr[6].state = "true";
          setDisableArr(arr);
          setInitButton5(true);
          setNow(100);
          // setShowProgressBar(false);
          setLoading(false);
          console.log("hifzur" + res);
          initialsetupcallapi6();
        }
      });
  };

  const initialsetupcallapi6 = () => {
    let proArr = isProcessing;
    proArr[6].state = "true";
    setProcessing([...proArr]);
    // setShowProgressBar(true);
    setLoading(true);
    setNow(0);
    axios
      .get(loca + "/lom/initial/setup6", {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          proArr[6].state = "false";
          setProcessing([...proArr]);
          const modul = res.data;
          let arr = disableArr;
          arr[6].state = "success";
          arr[7].state = "true";
          setDisableArr(arr);
          setInitButton6(true);
          setNow(100);
          // setShowProgressBar(false);
          setLoading(false);
          initialdisplaynull();
          console.log("hifzur" + res);
          setCurrentApplication();
        }
      });
  };

  const setCurrentApplication = () => {
    let proArr = isProcessing;
    proArr[7].state = "true";
    setProcessing([...proArr]);
    axios
      .get(loca + "/lom/set/userapplication/autoportal", {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (res.status === 200) {
          proArr[7].state = "false";
          setProcessing([...proArr]);
          const modul = res.data;
          let arr = disableArr;
          arr[7].state = "success";
          setDisableArr(arr);
          setCurrentAppButton(true);
          console.log(res);
          getVerify();
          navigate(user + "/dashboard");
        }
      });
  };
  const setSubscribe = async () => {
    console.log("hhh" + token);
    let eventSource = new EventSource(loca + "/lom/subscribe", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    var es = new EventSourcePolyfill(loca + "/lom/subscribe", {
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    es.addEventListener(
      "initial_setup",
      function (event) {
        console.log("yasmin");
        var dt = JSON.parse(event.data);
        setNow(dt.percent);
      }.bind(this)
    );
  };

  return (
    <div className="container text-center">
      <div className="alert alert-warning mt-2">Do not refresh this page!</div>
      {loading === true ? <WorkInProgress /> : ""}
      <div className="row row-cols-4 justify-content-center  mt-2">
        <div className="col-sm-2">
          <button
            type="button"
            onClick={() => initialsetuprecordcallapi()}
            disabled={
              disableArr[0].state === "false" ||
              disableArr[0].state === "success" || isProcessing[0].state === "true"
            }
            className={
              disableArr[0].state === "success"
                ? " btnnn btn btn-success"
                : " btnnn btn btn-primary"
            }
          >
            {isProcessing[0].state === "true" ? (
              <span>
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"
                  
                ></span>
                Loading...
              </span>
            ) : (
              "Initial Setup Record"
            )}
          </button>
        </div>
      </div>
      <div className="row row-cols-4 justify-content-center  mt-5">
        <div className="col-sm-2">
          <button
            type="button"
            onClick={() => initialsetupcallapi1()}
            disabled={
              disableArr[1].state === "false" ||
              disableArr[1].state === "success" || isProcessing[1].state === "true"
            }
            className={
              disableArr[1].state === "success"
                ? " btnnn btn btn-success"
                : " btnnn btn btn-primary"
            }
          >
            {isProcessing[1].state === "true" ? (
              <span>
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"
                  
                ></span>
                Loading...
              </span>
            ) : (
              "Initial Setup 1"
            )}
          </button>
        </div>
        <div className="col-sm-2">
          <button
            type="button"
            onClick={() => initialsetupcallapi2()}
            disabled={
              disableArr[2].state === "false" ||
              disableArr[2].state === "success" || isProcessing[2].state === "true"
            }
            className={
              disableArr[2].state === "success"
                ? " btnnn btn btn-success"
                : " btnnn btn btn-primary"
            }
          >
            {isProcessing[2].state === "true" ? (
              <span>
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"
                  
                ></span>
                Loading...
              </span>
            ) : (
              "Initial Setup 2"
            )}
          </button>
        </div>
        <div className="col-sm-2">
          <button
            type="button"
            onClick={() => initialsetupcallapi3()}
            disabled={
              disableArr[3].state === "false" ||
              disableArr[3].state === "success" || isProcessing[3].state === "true"
            }
            className={
              disableArr[3].state === "success"
                ? " btnnn btn btn-success"
                : " btnnn btn btn-primary"
            }
          >
            {isProcessing[3].state === "true" ? (
              <span>
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"
                  
                ></span>
                Loading...
              </span>
            ) : (
              "Initial Setup 3"
            )}
          </button>
        </div>
      </div>
      <div className="row row-cols-4 justify-content-center  mt-5">
        <div className="col-sm-2">
          <button
            type="button"
            onClick={() => initialsetupcallapi4()}
            disabled={
              disableArr[4].state === "false" ||
              disableArr[4].state === "success" || isProcessing[4].state === "true"
            }
            className={
              disableArr[4].state === "success"
                ? " btnnn btn btn-success"
                : " btnnn btn btn-primary"
            }
          >
            {isProcessing[4].state === "true" ? (
              <span>
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"
                  
                ></span>
                Loading...
              </span>
            ) : (
              "Initial Setup 4"
            )}
          </button>
        </div>
        <div className="col-sm-2">
          <button
            type="button"
            onClick={() => initialsetupcallapi5()}
            disabled={
              disableArr[5].state === "false" ||
              disableArr[5].state === "success" || isProcessing[5].state === "true"
            }
            className={
              disableArr[5].state === "success"
                ? " btnnn btn btn-success"
                : " btnnn btn btn-primary"
            }
          >
            {isProcessing[5].state === "true" ? (
              <span>
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"
                  
                ></span>
                Loading...
              </span>
            ) : (
              "Initial Setup 5"
            )}
          </button>
        </div>
        <div className="col-sm-2">
          <button
            type="button"
            onClick={() => initialsetupcallapi6()}
            disabled={
              disableArr[6].state === "false" ||
              disableArr[6].state === "success" || isProcessing[6].state === "true"
            }
            className={
              disableArr[6].state === "success"
                ? " btnnn btn btn-success"
                : " btnnn btn btn-primary"
            }
          >
            {isProcessing[6].state === "true" ? (
              <span>
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"
                  
                ></span>
                Loading...
              </span>
            ) : (
              "Initial Setup 6"
            )}
          </button>
        </div>
      </div>
      <div className="row row-cols-4 justify-content-center  mt-5">
        <div className="col-sm-2">
          <button
            type="button"
            onClick={() => setCurrentApplication()}
            disabled={
              disableArr[7].state === "false" ||
              disableArr[7].state === "success" || isProcessing[7].state === "true"
            }
            className={
              disableArr[7].state === "success"
                ? " btnnn btn btn-success"
                : " btnnn btn btn-primary"
            }
          >
            {isProcessing[7].state === "true" ? (
              <span>
                <span
                  className="spinner-grow spinner-grow-sm me-2"
                  role="status"
                  
                ></span>
                Loading...
              </span>
            ) : (
              "Set Current Application"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default InitialSetupcomponent;
