import React, { useState, useContext } from "react";
import axios from "axios";
import "../css/Authentication.css";
import { useNavigate } from "react-router-dom";
import { AutoPortalContext } from "../Context";
import { Card, CardBody, CardHeader, CardFooter } from "reactstrap";
import WorkInProgress from "./WorkInProgress";
import AlertCompo from "./AlertCompo";

const LoginForm = () => {
  const {
    setToken,
    loca,
    getUserDetails,
    setUserName,
    setU_Name,
  } = useContext(AutoPortalContext);
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  // const [showlogin, setShowLogin] = useState(true);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [application, setApplication] = useState("");
  const [pageError, setPageError] = useState(false);
  const [error, setError] = useState("");
  const [showpassword, setShowPassword] = useState("fa fa-eye hidepassword");
  const [flag, setFlag] = useState(true);

  const callUpdateCompo = () => {
    navigation("/ForgetPassword");
  };

  const logincall = () => {
    setPageError(false);
    let eMsg = [];
    console.log(username, password, application);
    console.log("loca", loca);

    if (!username && !password && !application) {
      eMsg.push("Fields are empty");
    } else if (username && !password && !application) {
      eMsg.push("Application and password is empty");
    } else if (username && password && !application) {
      eMsg.push("Application is empty");
    } else if (username && !password && application) {
      eMsg.push("Password is empty");
    } else if (!username && password && application) {
      eMsg.push("Usename is empty");
    } else if (!username && password && !application) {
      eMsg.push("Username and Application is empty");
    } else if (!username && !password && application) {
      eMsg.push("Username and Password is empty");
    }

    if (!eMsg.length > 0) {
      setLoading(true);
      axios
        .post(
          loca + "/authenticate",
          {
            username: username,
            password: password,
            application: application,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(
          (res) => {
            if (res.status === 200) {
              if (res.data.authenticated === true) {
                console.log(res.data);
                
                var token = res.data.token;
                var un = res.data.username;
                var nm = res.data.name;
                // console.log("name " + nm);
                // setU_Name(nm);
                // setUserName(un);
                if (res.data.passwordReset) {
                  localStorage.setItem("autoToken", token);
                  setToken(token);
                  callNewPassword(password, username);
                  getUserDetails(token);
                  // setLoading(false);
                } else {
                  localStorage.setItem("autoToken", token);
                  setToken(token);
                  getUserDetails(token);
                  // setLoading(false);
                }
              } else {
                setPageError(true);
                setError(res.data.error);
                setLoading(false);
                // setTimeout(() => {
                //   setLoading(false);
                // }, 200);
              }
            }
          },
          (error) => {
            // navigation("/error");
            console.log(error);
            setPageError(true);
            setLoading(false);
            setError("Please check your username and password ");
          }
        );
    } else {
      console.log(JSON.stringify(eMsg));
      setError(eMsg);
      setPageError(true);
    }
  };

  const callNewPassword = (op, un) => {
    navigation("/setNewPassword", {
      state: { op: password, un: username, app: application },
    });
  };

  const showRegister = (type) => {
    navigation("/signup", { state: { type: type } });
  };
  const newapp = () => {
    navigation("/NewApplication");
  };

  const hideshowpassword = () => {
    if (!flag) {
      setShowPassword("fa fa-eye hidepassword");
      setFlag(!flag);
    } else {
      setShowPassword("fa fa-eye-slash hidepassword");
      setFlag(!flag);
    }
  };

  return (
    <div className="parent-div">
      {/* {!loading && <WorkInProgress />} */}
      <div className="d-flex justify-content-center">
        <Card className="bg-auth-1 box-shadoww card-width-manual">
          <CardHeader className="border-0">
            <h4 className="text-center mb-0 mt-2">Sign In</h4>
            <hr />
            {pageError && (
              <AlertCompo type="danger" msg={error} />
            )}
          </CardHeader>

          <CardBody className="pt-0 pb-0 text-center">
            <div className="mt-2">
              <i
                className="fa fa-user"

                style={{
                  border: "2.5px solid white",
                  height: "4.5rem",
                  width: "4.5rem",
                  fontSize: "62px",
                  textAlign: "center",
                  borderRadius: "39px",
                }}
              ></i>
            </div>
            <div className="mt-4">
              <div className=" usericpadd input-group form-group pb-2">
                <input
                  type="username"
                  name="username"
                  placeholder="username"
                  className=" inpptt "
                  onChange={(username) => {
                    setUsername(username.target.value);
                  }}
                ></input>
              </div>
              <div className=" usericpadd input-group form-group pb-2 inp-passwordd">
                <input
                  type={flag ? "password" : "text"}
                  name="password"
                  placeholder="password"
                  className=" inpptt "
                  // maxLength={10}
                  onChange={(password) => {
                    setPassword(password.target.value);
                  }}
                ></input>
                <div className="input-group-prepend inp-pass-eye">
                  <i
                    className={showpassword}

                    onClick={hideshowpassword}
                  ></i>
                </div>
              </div>
              <div className=" usericpadd input-group form-group pb-2">
                <input
                  type="text"
                  name="app"
                  placeholder="application"
                  className=" inpptt"
                  onChange={(e) => {
                    setApplication(e.target.value);
                  }}
                ></input>
              </div>
            </div>
            <div className="mt-2 mb-3">
              {!loading ? (
                <button
                  className="btn btn-warning btn-width"
                  onClick={logincall}
                >
                  Login
                </button>
              ) : (
                <button className="btn btn-warning" type="button" disabled>
                  <span
                    className="spinner-grow spinner-grow-sm me-1"
                  ></span>
                  Logging In...
                </button>
              )}
            </div>
            <hr className="my-2" />
          </CardBody>

          <CardFooter className="border-0 pt-0 pb-3">
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex">
                <span
                  className="text-warning hvr-yellow cursor-pointer"
                  onClick={newapp}
                >
                  Create new application
                </span>
              </div>
            </div>
            <hr className="my-2" />
            <div className="d-flex justify-content-between align-items-center mb-2">
              <div className="d-flex">
                <span
                  className="text-warning hvr-yellow cursor-pointer"
                  onClick={callUpdateCompo}
                >
                  Forgot password?
                </span>
              </div>
            </div>
            <div className="text-start bbr d-flex flex-column">
              <span>Don't have an account?</span>
              <div className="d-flex justify-content-between align-items-start text-sml">
                <span
                  className="text-warning hvr-yellow cursor-pointer"
                  onClick={() => showRegister("client")}
                >
                  Sign Up for client
                </span>
                <span
                  className="text-warning hvr-yellow cursor-pointer"
                  onClick={() => showRegister("user")}
                >
                  Sign Up for user
                </span>
              </div>
            </div>
          </CardFooter>
        </Card>
      </div>
    </div>
  );
};

export default LoginForm;
