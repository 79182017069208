import React, { Component, useEffect, useState, useContext } from "react";
import WorkInProgress from "./WorkInProgress";
import { Link, useNavigate } from "react-router-dom";
import { AutoPortalContext } from "../Context";
import imgg from "../img/newapplication.png";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import { Card, CardBody, CardHeader, CardFooter } from "reactstrap";

const NewApplication = () => {
  const navigation = useNavigate();
  const [pageError, setPageError] = useState(false);
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loginuser, setLoginUser] = useState(false);
  const [loginclient, setLoginclient] = useState(false);

  const onsubmit = () => {
    console.log("running");
    if (loginuser) {
      console.log("one");
      navigation("/urlogin");
    } else if (loginclient) {
      console.log("two");
      navigation("/cllogin");
    } else {
    }
  };
  const selectfn = (e) => {
    console.log("ssssss");
    if (e == "userlogin") {
      console.log("ddd");
      setLoginUser(true);
      setLoginclient(false);
    } else if (e == "clientlogin") {
      setLoginclient(true);
      setLoginUser(false);
    } else {
      setLoginUser(false);
      setLoginclient(false);
    }
  };

  function reqNewAppCreate() {
    navigation("/reqNewAppCreate");
  }
  return (
    <div>
      <div className="parent-div">
        <Card className="bg-auth-1 card-width-manual">
          <CardHeader className="border-0">
            <h4 className="text-center mb-0 mt-1">Application</h4>
            {pageError && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}
            <hr />
          </CardHeader>

          <CardBody>
            <div className="mt-2 text-center">
              <img style={{ height: "100px" }} src={imgg} alt="..." />
            </div>

            <div>
              <div>
                <h5>Get Your Customized Application Today !</h5>
                <p>
                  From iphones to Android devices and web browser we cover all
                  plateforms, whether its your business, education, or any other
                  purpose we bring your ideas to life.Take first steps towards
                  some changes and click on button
                </p>
              </div>
            </div>

            <div className="d-flex justify-content-center gap-2 mb-3">
              <button
                className="btn btn-warning btn-width"
                onClick={reqNewAppCreate}
              >
                Create App
              </button>
            </div>
            <hr className="mb-2 mt-4" />
          </CardBody>

          <CardFooter className="border-0 p-0">
            <div className="d-flex align-items-center gap-1 mb-3 mx-4">
              Don't have an account?
              <span className="underline text-warning cursor-pointer">
                Sign Up
              </span>
            </div>
          </CardFooter>
        </Card>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Application Login! </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mod-ht">
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Select Login Type?</Form.Label>
              <Form.Select
                onChange={(e) => selectfn(e.target.value)}
                type="select"
                placeholder="select type of login"
              >
                <option>None</option>
                <option id="user" value={"userlogin"}>
                  User Login
                </option>
                <option id="client" value={"clientlogin"}>
                  Client Login
                </option>
              </Form.Select>
              <button
                style={{
                  marginTop: "23px",
                  width: "105px",
                  height: "37px",
                  borderRadius: "20px",
                  background: "#010b38",
                  color: "white",
                }}
                onClick={onsubmit}
              >
                submit
              </button>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default NewApplication;
