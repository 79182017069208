import React from 'react'

const Setting = () => {
  return (
    <div style={{flexGrow:1}}>
        <div>
          <h1>Setting</h1>
        </div>
    </div>
  )
}

export default Setting