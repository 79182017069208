import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import "font-awesome/css/font-awesome.min.css";

if (process.env.NODE_ENV === 'production') {
    console.error = () => {};
  }
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <App />
);
