import axios from "axios";
import React, {
  useState,
  useContext,
  useEffect,
  input,
  Grid,
  Paper,
} from "react";
import { useRef } from "react";
import { Image } from "react-bootstrap";
import { createSearchParams, useNavigate } from "react-router-dom";
import { AutoPortalContext } from "../Context";
import "../css/GridCompo.css";
import { isMobile,isTablet } from "react-device-detect";

// import { createStyles, Grid, makeStyles, Paper } from '@mui/material';
const GridCompo = () => {
  const { token,loca ,user} = useContext(AutoPortalContext);
  const [top_sty, setTop_sty] = useState(0);
  const [left_sty, setLeft_sty] = useState(0);
  const [postinX, setPostinX] = useState(0);
  const [postinY, setPostinY] = useState(0);
  const [left, setLeft] = useState(0);
  const [top, setTop] = useState(0);
  const [wdget, setWdget] = useState([]);
  const [index, setIndex] = useState(0);
  const [arrFunction, setArrFunction] = useState([]);
  const [arrBody, setArrBody] = useState([]);
  const [script, setScript] = useState("");
  const [noOfRows, setNoOfRows] = useState(0);
  const [noOfColumns, setNoOfColumns] = useState([]);
  const [visible, setVisible] = useState(false);
  const [column, setColumn] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [vcount, setvCount] = useState([1,2,3,4,5,6,7,8,9,10]);
  const [selected, setSelected] = useState("");
  const [option, setOption] = useState();
  const [numFields, setNumFields] = useState(0);


  // co;nst classes = useStyles();
  const navigation = useNavigate();

  // const changeSelectOptionHandler = (event) => {
  //   setSelected(event.target.value);
  // };
  // const changeSelect = (e) => {
  //   const column = ["0","1","2","3",];
  // const row = ["0","1", "2", "3", "4","5","6","7","8","9","10"];
  // const button = ["None","small", "larg"];
  // let type = null;
  // let options = null;
  // if (selected === "Column") {
  //   type = column;
  // } else if (selected === "Row") {
  //   type = row;

  // } else if (selected === "Button") {
  //   type = button;
  // }
 
  // if (type) {
  //   options = type.map((el) => <option key={el}>{el}</option>);
  // }

  // };
  const changeSelectOptionHandler = (event) => {
    setSelected(event.target.value);
};
  // const column = ["0","1","2","3",];
  // const row = ["0","1", "2", "3", "4","5","6","7","8","9","10"];
  // const button = ["None","small", "larg"];
  // let type = null;
  // let options = null;
  // if (selected === "Column") {
  //   type = column;
  // } else if (selected === "Row") {
  //   type = row;
  //   const inputs = [];
  // for (let i = 0; i < numFields; i++) {
  //   inputs.push(<input key={i} type="text" />);
  // }
  // return inputs;
  // } else if (selected === "Button") {
  //   type = button;
  // }
  // if (type) {
  //   options = type.map((el) => <option key={el}>{el}</option>);
  // }



  const renderInputs = () =>{
    const inputs = [];
  for (let i = 0; i < numFields; i++) {
    inputs.push(<input key={i} type="text" />);
  }
  return inputs;
  };


  useEffect(() => {
    getRcd();
    window.onclick = function abc(e) {
      document.getElementById("menu").style.display = "none";
    };
    return () => {
      window.onclick = null;
    };
  }, []);

  const getRcd = () => {
    axios
      .get(loca+"/lom/get/widget", {
        headers: {
          // "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then((resp) => {
        let wd = resp.data;
        console.log("qpqpqpqpqppq " + JSON.stringify(wd));

        if ("Error" in wd) {
        } else {
          // setWdgetp(wd.widget);
          console.log("humayun " + JSON.stringify(wd.widget));
          let nm = wd.widget;
          // let htl = wd.widget[0].html;
          setWdget(nm);

          const styleTag = document.createElement("style");
          const scriptTag = document.createElement("script");
          let csstyle = "";
          let scpt = "";
          let htl = "";
          let scp = "";
          for (let i = 0; i < wd.widget.length; i++) {
            if (wd.widget[i].css != "") {
              csstyle += wd.widget[i].css;
            }

            if (wd.widget[i].script != "") {
              scp += wd.widget[i].script + " ";
            }
            // if (wd.widget[i].html != "") {
            //   htl+=wd.widget[i].html
            // }
          }
          const open = scpt.indexOf("{");
          const close = scpt.indexOf("}");
          const body = scpt.slice(open, close + 1);
          styleTag.textContent = csstyle;
          setScript(scp);
          // scriptTag.textContent = wd.widget.script;
          document.body.appendChild(styleTag);
          // eventType(htl, body);
        }
      });
  };

  const eventType = (tag, body, elmnt) => {
    console.log("tag : " + tag);
    if (tag.includes("onclick")) {
      let arrTag = tag.split(">");
      console.log("split : " + JSON.stringify(arrTag));
      let fnName = "";
      let arrFunc = [];
      for (let j = 0; j < arrTag.length; j++) {
        let firstTag = arrTag[j];

        let arr = firstTag.split("=");
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].includes("()")) {
            let k = arr[i];
            fnName = k.slice(k.indexOf("'"), k.lastIndexOf("'") + 1);
            let fnName2 = k.slice(k.indexOf("'") + 1, k.lastIndexOf("'"));
            let repName = fnName2 + j;
            console.log(" onclick=" + repName);
            let withoutFn = tag.replace(
              " onclick=" + fnName,
              " name='" + repName + "'"
            );
            console.log("withoutfn" + withoutFn);
            elmnt.innerHTML = withoutFn;
            tag = withoutFn;
            arrFunc.push(repName);
            // elmnt.removeEventListener("onclick")
          }
        }
      }
      document.getElementsByClassName("aa").item(0).before(elmnt);
      console.log("arrfunc : " + JSON.stringify(arrFunc));
      for (let i = 0; i < arrFunc.length; i++) {
        let click_elmnt = document.getElementsByName(arrFunc[i]).item(0);
        for (let j = 0; j < body.length; j++) {
          let func_name = arrFunc[i].slice(0, arrFunc[i].indexOf(")") + 1);
          console.log(
            "checking outside " + func_name,
            JSON.stringify(body[j].name)
          );
          if (body[j].name === func_name) {
            console.log("checking inside");
            click_elmnt.addEventListener("click", () => {
              let f = new Function(["navigation"], body[j].body);
              f(navigation);
            });
          }
        }
      }

      setArrBody(body);
      setArrFunction(arrFunc);
    } else if (tag.includes("onload")) {
      document
        .getElementById("te")
        .firstChild.addEventListener("onload", () => {
          let f = new Function(["navigation"], "{navigation('setting')}");
          f(navigation);
        });
    } else if (tag.includes("onmove")) {
      document
        .getElementById("te")
        .firstChild.addEventListener("onmove", () => {
          let f = new Function(["navigation"], "{navigation('setting')}");
          f(navigation);
        });
    } else {
      document.getElementsByClassName("aa").item(0).before(elmnt);
    }
  };

  const sub = () => {
    console.log("jjjj");
    setTop_sty(document.getElementById("top").value);
    console.log(top_sty);
    setLeft_sty(document.getElementById("left").value);
  };

  const submi = () => {
    // document.removeEventListener("mousedown", clickComp);
    // let oldNode = document.getElementsByTagName("body").item(0);
    // let newNode = oldNode.cloneNode(true);
    // oldNode.parentNode.replaceChild(newNode, oldNode);

    // document.getElementById("g1").style.border = ` 0px solid black`;
    // document.getElementById("g2").style.border = ` 0px solid black`;
    // document.getElementById("g3").style.border = ` 0px solid black`;
    // document.getElementById("g4").style.border = ` 0px solid black`;
    // document.getElementById("g5").style.border = ` 0px solid black`;
    // document.getElementById("g6").style.border = ` 0px solid black`;
    for (let i = 1; i <= noOfRows; i++) {
      if (noOfColumns[i - 1].row === i) {
        for (let j = 1; j <= noOfColumns[i - 1].no_column; j++) {
          document.getElementById(
            "g" + i + j
          ).style.border = ` 0px solid black`;
        }
      }
    }
    navigation(user+"/portal", {
      state: {
        html2: document.getElementById("out").innerHTML,
        script2: script,
        arrFunction: arrFunction,
        arrBody: arrBody,
        css2:"",
      },
    });
  };

  const clickComp = (html, scpt, e, index) => {
    // getRcd(e);
    // document.getElementById(id).addEventListener("click", (e) => {
    // document.getElementById("zak").style.position="absolute"
    console.log("bigdog " + JSON.stringify(html));
    e = e || window.event;
    e.preventDefault();

    // scripting part
    let body = [];
    let bd = scpt.split("}");
    bd.splice(bd.length - 1, 1);
    console.log("afterSplit : " + JSON.stringify(bd));
    for (let i = 0; i < bd.length; i++) {
      let bd_split = bd[i].split("{");
      let bd_name = "";
      a: for (let j = 0; j < bd_split.length; j++) {
        let last_Split = bd_split[j].split(" ");
        for (let k = 0; k < last_Split.length; k++) {
          if (last_Split[k].includes("()")) {
            bd_name = last_Split[k];
          }
        }
      }
      const open = bd[i].indexOf("{");
      // const close = scpt.indexOf("}");
      const by = bd[i].slice(open, bd[i].length) + "}";
      body.push({ body: by, name: bd_name });
    }

    var rect = e.target.getBoundingClientRect();
    setPostinX(e.clientX - rect.left); //x position within the element.
    setPostinY(e.clientY - rect.top); //y position within the element.
    // console.log("YY : " + postinY);
    // console.log();
    // let clone = document.getElementsByClassName("te").item(index).cloneNode(true);
    let div = document.createElement("div");
    div.innerHTML = html;

    eventType(html, body, div);

    div.id = "";
    div.style.position = "absolute";
    div.style.flexGrow = 1;
    div.style.zIndex = "9";
    div.style.cursor = "move";

    div.style.top = 5;
    div.style.left = 5;
    // console.log(document.getElementById("g" + 1).innerHTML);
    dragElement(div);
  };

  const dragElement = (elmnt) => {
    var pos1 = 0,
      pos2 = 0,
      pos3 = 0,
      pos4 = 0,
      g = -1,
      lastMove = null;
    // console.log("kkkkk : " + document.activeElement.nodeName);

    // }

    const dragMouseDown = (e) => {
      console.log("hey");
      if (isMobile || isTablet) {
        e.preventDefault();
        e = e.touches.item(0);
      } else {
        e = e || window.event;
        e.preventDefault();
      }
      // get the mouse cursor position at startup:
      pos3 = e.clientX;
      pos4 = e.clientY;
      // if (elmnt.style.position !== "relative") {
      // call a function whenever the cursor moves:
      if (isMobile || isTablet) {
        document.ontouchmove = elementDrag;
        document.ontouchend = closeDragElement;
      } else {
        document.onmousemove = elementDrag;
        document.onmouseup = closeDragElement;
      }
    };
    // document.ontouchstart
    elmnt.onmousedown = dragMouseDown;
    elmnt.ontouchstart = dragMouseDown;
    const elementDrag = (e) => {
      if (isMobile || isTablet) {
        e.preventDefault();
        e = e.touches.item(0);
        lastMove = e;
      } else {
        e = e || window.event;
        e.preventDefault();
      }
      document.getElementById("menu").style.display = "none";
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX;
      pos2 = pos4 - e.clientY;

      pos3 = e.clientX;
      pos4 = e.clientY;
      elmnt.style.top = elmnt.offsetTop - pos2 + "px";
      elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
      if (elmnt.style.position === "relative") {
        elmnt.style.position = "absolute";
      }

      // set the element's new position:
      // logic for hover on grid when dragging the element
      for (let i = 1; i <= noOfRows; i++) {
        // console.log(1,noOfColumns)
        if (noOfColumns[i - 1].row === i) {
          for (let j = 1; j <= noOfColumns[i - 1].no_column; j++) {
            // console.log(i,j)
            let g1StartX = document.getElementById("g" + i + j).offsetLeft;
            let g1EndX =
              g1StartX + document.getElementById("g" + i + j).clientWidth;
            let g1StartY = document.getElementById("g" + i + j).offsetTop;
            let g1EndY =
              g1StartY + document.getElementById("g" + i + j).clientHeight;
            if (
              g1StartX < e.clientX &&
              e.clientX < g1EndX &&
              g1StartY < e.clientY &&
              e.clientY < g1EndY
            ) {
              document.getElementById(
                "g" + i + j
              ).style.border = ` 1px solid black`;
            } else {
              document.getElementById(
                "g" + i + j
              ).style.border = ` 1px solid rgba(0, 0, 0, 0.125) `;
            }
          }
        }
      }
    };

    const closeDragElement = (e) => {
      /* stop moving when mouse button is released:*/

      if (isMobile || isTablet) {
        console.log(e);
        e.preventDefault();
        e = lastMove;
      } else {
        e = e || window.event;
        e.preventDefault();
      }
      console.log(e);
      console.log("llllll");
      for (let i = 1; i <= noOfRows; i++) {
        if (noOfColumns[i - 1].row === i) {
          for (let j = 1; j <= noOfColumns[i - 1].no_column; j++) {
            document.getElementById("g" + i + j).style.border =
              "1px solid blue";
          }
        }
      }
      if (!isMobile) {
        const dlt = document.getElementById("menu").children.item(0);
        let style = document.getElementById("menu").style;
        dlt.onclick = dtt;
        if (e.which === 3) {
          elmnt.addEventListener(
            "contextmenu",
            function (e) {
              console.log("eclient : " + e.clientY, e.clientX);
              style.display = "block";
              style.top = e.clientY + "px";
              style.left = e.clientX + "px";
              console.log("aclient : " + style.top, style.left);
              // style.left = "500";
              e.preventDefault();
            },
            false
          );
        }
      }

      for (let i = 1; i <= noOfRows; i++) {
        if (noOfColumns[i - 1].row === i) {
          for (let j = 1; j <= noOfColumns[i - 1].no_column; j++) {
            let g1StartX = document.getElementById("g" + i + j).offsetLeft;
            let g1EndX =
              g1StartX + document.getElementById("g" + i + j).clientWidth;
            let g1StartY = document.getElementById("g" + i + j).offsetTop;
            let g1EndY =
              g1StartY + document.getElementById("g" + i + j).clientHeight;
            if (
              g1StartX < e.clientX &&
              e.clientX < g1EndX &&
              g1StartY < e.clientY &&
              e.clientY < g1EndY
            ) {
              elmnt.style.position = "relative";
              elmnt.style.top = top_sty;
              elmnt.style.left = left_sty;
              console.log(toString(i + j));
              if (g !== toString(i + j)) {
                document.getElementById("g" + i + j).appendChild(elmnt.childNodes.item(0).childNodes.item(0));
                console.log("gg : " + g);
                g = i + j;
                console.log("gg : " + g);
              }
              document.getElementById("g" + i + j).style.border =
                "1px solid black";
            }
          }
        }
      }
      if (isMobile || isTablet) {
        document.ontouchend = null;
        document.ontouchmove = null;
      } else {
        document.onmouseup = null;
        document.onmousemove = null;
      }

      // elmnt.focus()
    };
    const dtt = () => {
      console.log("kkklllppp");
      elmnt.remove();
    };
  };

  // const callgdviewtype = () =>{
  //   // document.getElementById("out").style.direction("ColumnGrid");

  //   document.getElementById("outt1");

  // }

  // Declare a loop variable

  // // List View
  // const listView = () => {
  //   var i;
  //   var elements = document.getElementById("co");
  //   for (i = 0; i < elements.length; i++) {
  //     elements[i].style.width = "100%";
  //   }
  // }

  // // Grid View
  // const gridView = () => {
  //   var i;
  //   var elements = document.getElementById("co");
  //   for (i = 0; i < elements.length; i++) {
  //     elements[i].style.width = "50%";
  //   }
  // }
  // useEffect(() => {
  //     main();
  // }, [])

  // const main=()=>{
  //   const  container = document.getElementById("btnContainer");
  // const  btns = container.getElementById("tbn");
  // for (var i = 0; i < btns.length; i++) {
  //   btns[i].addEventListener("click", function() {
  //     var current = document.getElementsByClassName("active");
  //     current[0].className = current[0].className.replace(" active", "");
  //     this.className += " active";
  //   });
  // }
  const setGridclm = (e, i) => {
    console.log(e, i);
    let arr = noOfColumns;

    for (let k = 0; k < arr.length; k++) {
      if (i === arr[k].row) {
        arr[k].no_column = e;
      }
    }
    setNoOfColumns([...arr]);
    let r_html = "";

    for (let j = 1; j <= e; j++) {
      r_html +=
        "<div style='min-height:100px'  class='container-fluid d-flex justify-content-center align-items-center'   id='g" +
        i +
        j +
        "' ></div>";
    }
    console.log(r_html);
    document.getElementById("c" + i).innerHTML = r_html;
  };

  const gridfn = (e) => {
    let html = "";
    if (e !== "none") {
      setNoOfRows(e);
      setVisible(true);
      let arr = [];
      let clm = [];
      for (let i = 1; i <= e; i++) {
        html +=
          "<div  class='container-fluid d-flex' id='c" +
          i +
          "' ><div style='min-height:100px'  class='container-fluid d-flex justify-content-center align-items-center'  id='g" +
          i +
          "1' ></div></div>";
        clm.push(i);
        arr.push({ row: i, no_column: 1 });
      }
      setColumn(clm);
      setNoOfColumns([...arr]);
      setTimeout(() => {
        document.getElementById("rroww").innerHTML = html;
        console.log("happy " + html);
      }, 100);
    } else {
      setVisible(false);
      setNoOfRows(-1);
    }
    // setVisible(true);
    // setHidden(true);
    // var i;
    // var ii;
    // if (visible == true) {
    //  i =  document.getElementById("innn");
    //  ii =  i.document.getElementById("slr")
    // setHidden(false);

    // }
    // else if (hidden == true) {
    //   setVisible(false);
    //   i =  document.getElementById("innn");
    //   ii =  i.document.getElementById("rroww")
    //  }
    // else{
    //   console.log("sorry");
    // }
  };

  return (
    <div style={{flexGrow:1}} className="ac" >
      <div>
        <label className="grdview">Change_View_Type</label>
        <select
          onChange={(e) => gridfn(e.target.value)}
          id="innn"
          className="form-select"
          aria-label="Default select example"
        >
          <option selected value="none">
            None
          </option>
          <option id="slr" value="1">
            1
          </option>
          <option value="2">2</option>
          <option value="3">3</option>
        </select>
      </div>
      {visible &&
        column.map((e, i) => (
          <div>
            <label htmlFor={"s" + i}>{i + 1}</label>
            <select
              onChange={(e) => setGridclm(e.target.value, i + 1)}
              key={i}
              id={"s" + i}
            >
              <option>1</option>
              <option>2</option>
              <option>3</option>
            </select>
          </div>
        ))}
      <div
        id="menu"
        style={{
          display: "none",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: 10,
          background: "aliceblue",

          // overflow: "scroll",
          // width: "8em",
          // marginTop: "30em",
          // marginLeft: "47",
        }}
        onBlur={() => {}}
      >
        <button>Delete</button>
      </div>
      {visible && (
        <div className="aa" style={{ marginTop: "36px" }}>
          <div
          className="main-container"
            // style={{
            //  x
            //   // flexDirection: "row",
            //   // justifyContent: "center",
            // }}
          >
            <div
              className="card"
              style={{
                width: "25%",
                marginRight: "4px",
                border: "2px solid lightgray",
              }}
            >
              <ul style={{ display: "flex",padding: 0 }} className="card-body list-group">
                {wdget.length > 0 &&
                  wdget.map((obj, index) => (
                    <li
                      id="zk"
                      className="list-group-item zk"
                      style={{ cursor: "pointer" }}
                      key={index}
                      onClick={(e) => clickComp(obj.html, obj.script, e, index)}
                    >
                      {obj.name}
                    </li>
                  ))}
              </ul>
            </div>

            <div
              id="out"
              style={{
                width: "75%",
                border: "1px solid rgba(0, 0, 0, 1)",
                // display: "flex",
                // justifyContent: "center",
                // alignItems: "center",
                // flexDirection: "column",
              }}
            >
              <div
                id="rroww"
                style={{
                  gap: "4px",
                }}
              >
                {/* <div
                id="g1"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: 283,
                  minHeight: 170,
                  alignSelf: "center",
                  border: " 1px solid rgba(0, 0, 0, 0.125) ",
                }}
              ></div>
              <div
                id="g2"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: 283,
                  minHeight: 170,
                  alignSelf: "center",
                  border: " 1px solid rgba(0, 0, 0, 0.125) ",
                }}
              ></div>
              <div
                id="g3"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: 283,
                  minHeight: 170,
                  alignSelf: "center",
                  border: " 1px solid rgba(0, 0, 0, 0.125) ",
                }}
              ></div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 5,
                justifyContent: "center",
                gap:"4px",
              }}
            >
              <div
                id="g4"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: 283,
                  minHeight: 170,
                  alignSelf: "center",
                  border: " 1px solid rgba(0, 0, 0, 0.125) ",
                }}
              ></div>
              <div
                id="g5"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: 283,
                  minHeight: 170,
                  alignSelf: "center",
                  border: " 1px solid rgba(0, 0, 0, 0.125) ",
                }}
              ></div>
              <div
                id="g6"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  minWidth: 283,
                  minHeight: 170,
                  alignSelf: "center",
                  flexDirection: "column",
                  border: " 1px solid rgba(0, 0, 0, 0.125) ",
                }}
              ></div> */}
              </div>
            </div>
          </div>
        </div>
      )}
      {/* <div id="ay" className=" ay abc4 " style={{ justifyContent: "center" }}>
         <form action="https://www.google.com" style={{width:"251px"}}>
                <div className="mb-3">
                  <label for="exampleInputEmail1" className="form-label">
                    Email address
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                  />
                  <div id="emailHelp" className="form-text">
                    We'll never share your email with anyone else.
                  </div>
                </div>
                <div className="mb-3">
                  <label for="exampleInputPassword1" className="form-label">
                    Password
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="exampleInputPassword1"
                  />
                </div>
                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="exampleCheck1"
                  />
                  <label className="form-check-label" for="exampleCheck1">
                    Check me out
                  </label>
                </div>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
              </form> 
        <div style={{ marginLeft: "47px", marginTop: "-175px", width: "94%" }}>
          {/* <!-- onsubmit="sumi()" --> 
          <form
            className="card"
            action="#"
            style={{
              width: "288px",
              marginLeft: "19px",
              border: "2px solid lightgray",
            }}
          >
            <h1 className="card-header"style={{ fontSize: "medium" }}>Position</h1>
            <div className="card-body">
            <div className="mb-3">
              <label for="exampleInputEmail1" className="form-label">
                Top
              </label>
              <input
                type="number"
                className="form-control"
                id="top"
                aria-describedby="emailHelp"
              />
            </div>
            <div className="mb-3">
              <label for="exampleInputEmail1" className="form-label">
                Left
              </label>
              <input
                type="number"
                className="form-control"
                id="left"
                aria-describedby="emailHelp"
              />
            </div>
            </div>
            <div className="card-footer">
            <button type="button" onClick={sub} className=" btn btn-primary">
              change
            </button>
            </div>
            
          </form>
        </div> 

      </div> */}
      <button
        // style={{ width: "35em" }}
        type="button"
        className="btn btn-success"
        onClick={submi}
        // name="abc()2"
      >
        submit
      </button>
    </div>

  );
};

export default GridCompo;

// const useStyles = makeStyle((theme) =>
//     createStyles({
//         paper: {
//             padding: theme.spacing(2),
//             textAlign: 'center',
//             color: theme.palette.text.secondary,
//         },
//         root: {
//             flexGrow: 1,
//         },
//     }),
// );

// { hidden == true &&
//   <div className="row" style={{justifyContent:"center" ,marginTop:"4px" ,gap:"18px"}}>
// <div className="card" style={{width:"30%",height:"168px"}}>
// <div className="card-body"></div>
// {/* <div className="card-footer"></div> */}

// </div>
// <div className="card" style={{width:"30%",height:"168px"}}>
// <div className="card-body"></div>
// {/* <div className="card-footer"></div> */}

// </div>
// <div className="card" style={{width:"30%",height:"168px"}}>
// <div className="card-body"></div>
// {/* <div className="card-footer"></div> */}

// </div>
// </div>
// }

// { visible == true &&
//   <div className="input-group gg">
//     <input id="inp" type="text"
//      style={{width:"97%",height:"2.5em",marginTop:"6px"}}
//      ></input> <button style={{fontSize:"12px"}} type="button" data-bs-dismiss="gg" class="btn-close" aria-label="Close"></button>
//   </div>
//   }
