import axios from "axios";
import React, { useContext, useEffect, useState, useRef } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
import { AutoPortalContext } from "../Context";
import { Modal, Button } from "react-bootstrap";
import ModelList from "./ModelList";
import "../css/filterCompo.css";
import AlertCompo from "./AlertCompo";

const NewFilterCompo = ({
  filtarray,
  col_mn,
  timeline,
  setTimeLine,
  call_fil,
  showlist,
  tableName,
  tabId,
  isMobile,
  isScript,
  script,
  setScript,
  selectedScript,
  col_depend,
  setFiltArray,
  dcFiltVal,
  deftimeline,
  isRelatedList,
}) => {
  console.log(
    "1",
    timeline,
    "2",
    col_mn,
    "3",
    col_depend,
    "4",
    dcFiltVal,
    "5",
    tableName,
    "6",
    script
  );
  const { token, loca } = useContext(AutoPortalContext);
  // const navigation = useNavigate();
  // const { listName } = useLocation().state;
  const onerender = useRef(true);
  // const [record, setRecord] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [name, setName] = useState("");
  //   const [col_mn, setCol_mn] = useState([]);
  // const [list, setlist] = useState([]);
  //   const [showlist, setShowList] = useState(false);
  // const [showbtn, setShowbtn] = useState(false);
  // const [showupdownbttn, setShowUpDownbtn] = useState(false);
  const int_fields = useRef([]).current;
  const email_fields = useRef([]).current;
  const date_fields = useRef([]).current;
  const dtm_fields = useRef([]).current;
  const choice_fields = useRef([]).current;
  const boln_fields = useRef([]).current;
  const str_fields = useRef([]).current;
  const ref_fields = useRef([]).current;
  const refUT_fields = useRef([]).current;
  const booleanfld = useRef([]).current;
  const tm_list = useRef([]).current;
  const [filter_unset, setFilter_Unset] = useState(false);
  const [columnId, setColumnId] = useState();
  const [objIndex, setObjIndex] = useState();
  const [showModelList, setshowModelList] = useState(false);
  const [depColVal, setDepColVal] = useState();
  const [dcVal, setDcVal] = useState(dcFiltVal);
  const [userTable, setUserTable] = useState("false");
  const [unverified, setUnverified] = useState(false);
  // const [showLC, setShowLC] = useState([]);
  // const [filt, setFilt] = useState({
  //   co: "",
  //   cl: "",
  //   mc: "",
  //   an: "",
  //   ct: "",
  //   af: "",
  //   rf: { id: "", value: "" },
  //   dc: { id: "", value: "" },
  //   ch: [],
  // });
  const [page_error, setPage_Error] = useState(false);
  const [error, setError] = useState("");
  const [flag, setFlag] = useState(false);
  const [refrecord, setRefrecord] = useState({});

  const initallist = () => {
    str_fields.push("");
    str_fields.push("is");
    str_fields.push("is filled");
    str_fields.push("is empty");
    str_fields.push("is not");
    str_fields.push("starts with");
    str_fields.push("ends with");
    str_fields.push("contains");
    int_fields.push("");
    int_fields.push(">");
    int_fields.push("<");
    int_fields.push("is");
    int_fields.push("is filled");
    int_fields.push("is empty");
    email_fields.push("");
    email_fields.push("is");
    email_fields.push("starts with");
    boln_fields.push("");
    boln_fields.push("is");
    date_fields.push("");
    date_fields.push(">");
    date_fields.push("is");
    date_fields.push("is empty");
    date_fields.push("is filled");
    date_fields.push("<");
    dtm_fields.push("");
    dtm_fields.push(">");
    dtm_fields.push("is");
    dtm_fields.push("<");
    choice_fields.push("");
    choice_fields.push("is");
    choice_fields.push("is empty");
    choice_fields.push("is filled");
    choice_fields.push("is not");
    choice_fields.push("starts with");
    choice_fields.push("contains");
    choice_fields.push("ends with");
    booleanfld.push("");
    booleanfld.push("true");
    booleanfld.push("false");
    ref_fields.push("");
    ref_fields.push("is");
    ref_fields.push("is empty");
    ref_fields.push("is filled");
    refUT_fields.push("");
    refUT_fields.push("is");
    refUT_fields.push("is me");
    refUT_fields.push("is empty");
    refUT_fields.push("is filled");
    // refUT_fields.push("is me");
    refUT_fields.push("is dependent");
    if (!isScript) {
      tm_list.push({ name: "", label: "None" });
      tm_list.push({ name: "current24", label: "Current 24" });
      tm_list.push({ name: "previous24", label: "Previous 24" });
      tm_list.push({ name: "week", label: "Week" });
      tm_list.push({ name: "last14days", label: "Last 14 Days" });
      tm_list.push({ name: "last30", label: "Last 30" });
      tm_list.push({ name: "last6month", label: "Last 6 Month" });
      tm_list.push({ name: "last1year", label: "Last 1 Year" });
    }
    // if (filtarray && filtarray[0].cl !== "" && filtarray[0].cl !== null) {
    //   for (let i = 0; i < col_mn.length; i++) {
    //     if (col_mn[i].label === filtarray[0].cl) {
    //       setColumnId(col_mn[i].id);
    //       break;
    //     }
    //   }
    // }
    // for (let i = 0; i < filtarray.length; i++) {
    //   showLC.push(true);
    // }
    // console.log(ref_fields,userTable);
    setFlag(!flag);
    // filtarray.push(filt);
    // getListRecord();
  };

  const dependColumnValue = (abc, index) => {
    console.log(abc, index, col_depend);
    let farray = filtarray;
    if (col_depend.length > 0) {
      for (let i = 0; i < col_depend.length; i++) {
        if (col_depend[i].name === abc) {
          console.log(abc);
          setDepColVal(col_depend[i].label);
          farray[index].dc = {
            id: col_depend[i].id,
            value: abc.toString(),
            label: col_depend[i].label,
          };
          setFiltArray([...farray]);
          break;
        } else {
          setDepColVal();
          setDcVal("");
        }
      }
    } else {
      console.log("depCol");
      setDepColVal();
      setDcVal("");
    }
  };

  const changecolumn = (e, index, col_id) => {
    const vl = e;
    var farray = filtarray;
    // var col_id = "";
    var ut = "";
    console.log(col_mn);
    for (var i = 0; i < col_mn.length; i++) {
      if (col_mn[i].name === vl) {
        console.log(col_mn[i].type);
        farray[index].ct = col_mn[i].type;
        farray[index].cl = col_mn[i].label;
        farray[index].co = col_mn[i].name.toString();
        farray[index].mc = "";
        farray[index].an = "";
        ut = col_mn[i].userTable;
        col_id = col_mn[i].id;
        console.log("col_idddd : " + col_id);
        break;
      }
    }
    console.log(
      "col_id : " + JSON.stringify(col_id),
      ut,
      col_id,
      farray,
      index
    );
    call_fil(farray, index, col_id);
    setFiltArray([...farray]);
    setUserTable(ut);
    // setColumnId(col_id);
  };

  // set Modal list parameters before opening the modal
  const setModalListParam = (column, index) => {
    let col_id = "";
    for (var i = 0; i < col_mn.length; i++) {
      if (col_mn[i].name === column) {
        col_id = col_mn[i].id;
        console.log("col_idddd : " + col_id);
        break;
      }
    }
    setColumnId(col_id);
    setObjIndex(index);
    setshowModelList(true);
  };

  const changemiddle = (e, index) => {
    var farray = filtarray;
    if (e !== undefined) {
      farray[index].mc = e.toString();
    }
    // let slc=showLC;
    if (e.toString() === "is filled" || e.toString() === "is empty") {
      farray[index].rf.id = "";
      farray[index].rf.value = "";
      farray[index].an = "";

      // slc[index] = false;
    }
    // else {
    //   slc[index] = true;
    // }
    call_fil(farray, index, -1);
  };

  const changelast = (e, index) => {
    var farray = filtarray;
    var val = farray[0].co;
    var max_length = "";
    var validation = "";
    console.log(farray, val);
    for (let i = 0; i < col_mn.length; i++) {
      if (col_mn[i].name === val) {
        console.log(col_mn[i]);
        console.log(col_mn[i].uiValidation.max_length);
        max_length = col_mn[i].uiValidation.max_length;
        validation = col_mn[i].uiValidation.uiValidation;
      }
    }
    var regx = new RegExp("^[0-9]{0," + max_length + "}$");
    if (validation === "number") {
      if (e !== undefined && regx.test(e)) {
        farray[index].an = e.toString();
      }
      console.log(farray);
    } else {
      farray[index].an = e.toString();
    }
    console.log(farray);
    call_fil(farray, index, -1);
  };

  const changelastForInt = (e, index) => {
    var farray = filtarray;
    var val = farray[0].co;
    var max_length = "";
    console.log(farray, val, col_mn);
    for (let i = 0; i < col_mn.length; i++) {
      if (col_mn[i].name === val) {
        console.log(col_mn[i].uiValidation.max_length);
        max_length = col_mn[i].uiValidation.max_length;
      }
    }
    var regx = new RegExp("^[0-9]{0," + max_length + "}$");
    if (e !== undefined && /^[0-9]*$/.test(e) && regx.test(e)) {
      farray[index].an = e.toString();
      console.log(farray);
    }
    console.log(farray);
    call_fil(farray, index, -1);
  };

  const changelastref = (e, index, name, type) => {
    console.log(e.target.value);
    var farray = filtarray;
    var vl = farray[index].co;
    var col_id = "";
    for (var i = 0; i < col_mn.length; i++) {
      if (col_mn[i].name === vl) {
        col_id = col_mn[i].id;
        break;
      }
    }
    if (e.target.value !== undefined) {
      formChangefn(e.target.value, index, name, type, col_id, false);
      farray[index].rf = { id: "0", value: e.target.value.toString() };
    }
    call_fil(farray, index, -1);
  };

  const setRef = (r_id, val) => {
    var farray = filtarray;
    var col_index = objIndex;
    console.log(val);
    farray[col_index].rf = { id: r_id, value: val };
    call_fil(filtarray, objIndex, -1);
    cancelModelList();
  };

  const formChangefn = async (vl, index, ob, type, id, vrf) => {
    console.log("intest");
    if (vl.length > 2) {
      if (vrf === true) {
        console.log("intrest");
        // console.log(index);
        var farray = filtarray;
        farray[index].rf.value = vl;
        farray[index].rf.id = id;
        // console.log(farray);
        call_fil(farray, index, -1);
        setRefrecord({});
        // setState({ filtarray: farray, refrecord: rfrcd2 });
      } else {
        var veri = '{"referenceRecord":[{"columnid":"' + id + '"},';
        veri += '{"tabvalue":"' + vl + '"}]}';
        var rff = [];
        axios
          .post(loca + `/lom/reference/record`, veri.toString(), {
            headers: {
              "Content-Type": "application/json",
              authorization: "Bearer " + token,
            },
          })
          .then((resp) => {
            const refrencercd = resp.data;
            console.log(refrencercd);
            if (refrencercd !== "") {
              if ("Error" in refrencercd) {
                setLoading(false);
                setPage_Error(true);
                setError(refrencercd.Error);
                // setState({
                //   loading: false,
                //   page_error: true,
                //   error: refrencercd.Error,
                // });
              } else {
                var ref_rcd = refrencercd.referenceRecordList[2].records.length;
                console.log(ref_rcd);
                console.log(vl, index, ob, type, id, vrf);
                if (ref_rcd === 0) {
                  setUnverified(true);
                } else {
                  setUnverified(false);
                }
                if (ref_rcd >= 5) {
                  ref_rcd = 5;
                }

                let rcod = refrencercd.referenceRecordList[2].records;

                for (var i = 0; i < ref_rcd; i++) {
                  rff.push(refrencercd.referenceRecordList[2].records[i]);

                  if (rcod[i].value === vl) {
                    let farray = filtarray;
                    farray[index].rf.value = rcod[i].value;
                    farray[index].rf.id = rcod[i].id;
                    call_fil(farray, index, -1);
                  }
                }
              }
            }
            var rf = { index: index, record: rff };
            setRefrecord(rf);
            // setState({ refrecord: rf });
          });
      }
    } else {
      var farray = filtarray;
      farray[index].rf.value = "";
      farray[index].rf.id = "";
      setUnverified(false);
      setRefrecord({});
    }
  };

  const cancelModelList = () => {
    // this.setState({ show: false });
    setshowModelList(false);
  };

  const addbtn = (ind) => {
    if (filterset()) {
      console.log("filter add");
      filtarray.push({
        co: "",
        cl: "",
        mc: "",
        an: "",
        ct: "",
        af: "AND",
        rf: { id: "", value: "" },
        dc: { id: "", value: "" },
        ch: [],
      });
      call_fil(filtarray, ind, -1);
      setFilter_Unset(false);
    } else {
      setFilter_Unset(true);
    }
  };

  const orbtn = (ind) => {
    if (filterset()) {
      // var len = filtarray.length;
      filtarray.push({
        co: "",
        cl: "",
        mc: "",
        an: "",
        ct: "",
        af: "OR",
        rf: { id: "", value: "" },
        dc: { id: "", value: "" },
        ch: [],
      });
      // call_fil(filtarray);
      call_fil(filtarray, ind, -1);
      setFilter_Unset(false);
    } else {
      setFilter_Unset(true);
    }
  };

  const filterset = () => {
    console.log(filtarray);
    for (var i = 0; i < filtarray.length; i++) {
      console.log(filtarray[i].ct);
      if (filtarray[i].ct === "" || filtarray[i].ct === undefined) {
        return false;
      } else {
        if (filtarray[i].mc !== "is empty" && filtarray[i].mc !== "is filled") {
          if (
            filtarray[i].ct === "String" ||
            filtarray[i].ct === "int" ||
            filtarray[i].ct === "choice" ||
            filtarray[i].ct === "boolean" ||
            filtarray[i].ct === "date" ||
            filtarray[i].ct === "datetime" ||
            filtarray[i].ct === "filter" ||
            filtarray[i].ct === "json" ||
            filtarray[i].ct === "html" ||
            filtarray[i].ct === "email"
          ) {
            if (filtarray[i].an === "") {
              console.log("ttt");
              return false;
            }
          } else if (
            filtarray[i].ct === "reference" ||
            filtarray[i].ct === "depend_table"
          ) {
            console.log("checkk");
            if (filtarray[i].rf.value === "") {
              return false;
            }
          }
        }
      }
    }
    return true;
  };

  const setRefRcd = (vl, index, ob, type, id) => {
    formChangefn(vl, index, ob, type, id, true);
  };

  const cancelfilt = (i) => {
    filtarray.splice(i, 1);
    if (filtarray.length === 0) {
      filtarray.push({
        co: "",
        cl: "",
        mc: "",
        an: "",
        ct: "",
        af: "",
        rf: { id: "", value: "" },
        dc: { id: "", value: "" },
        ch: [],
      });
    }
    if (filterset) {
      setFilter_Unset(false);
    }
    call_fil(filtarray, i, -1);
  };

  const changeDependentRefColumn = (e, index) => {
    var farray = filtarray;
    var col_id = "";
    var col_lb = "";
    for (var i = 0; i < col_depend.length; i++) {
      if (col_depend[i].name === e.target.value) {
        col_id = col_depend[i].id;
        col_lb = col_depend[i].label;
        break;
      }
    }
    if (e.target.value !== undefined) {
      farray[index].dc = {
        id: col_id,
        value: e.target.value.toString(),
        label: col_lb,
      };
    }
    setFiltArray([...farray]);
  };

  const changeDependentColumn = (e, index) => {
    dependColumnValue(e.target.value, index);
    var farray = filtarray;
    var col_id = "";
    for (var i = 0; i < col_mn.length; i++) {
      if (col_mn[i].name === e.target.value) {
        col_id = col_mn[i].id;
        break;
      }
    }
    console.log(e.target.value, col_id);
    if (e.target.value !== undefined) {
      farray[index].rf = { id: col_id, value: e.target.value.toString() };
    }
    setFiltArray([...farray]);
  };

  useEffect(() => {
    var filt = filtarray;
    var col = col_mn;
    console.log(col, filtarray);
    var ut = "";
    for (var f = 0; f < filt?.length; f++) {
      if (filt[f].co !== "") {
        if (filt[f].ct === "reference") {
          for (var c = 0; c < col.length; c++) {
            if (filt[f].co === col[c].name) {
              ut = col[c].userTable;
            }
          }
        } else if (filt[f].ct === "reference" && filt[f].mc === "is me") {
          for (var c = 0; c < col.length; c++) {
            if (filt[f].co === col[c].name) {
              ut = col[c].userTable;
            }
          }
        }
      }
    }
    //   console.log(filt[0]);
    // if (filt && filt[0]?.cl !== "" && filt[0]?.cl !== null) {
    //   for (let i = 0; i < col.length; i++) {
    //     if (col[i].label === filt[0].cl) {
    //       setColumnId(col[i].id);
    //       break;
    //     }
    //   }
    // }

    console.log(ut);
    setUserTable(ut);

    console.log("col_mn", col_mn);
    if (onerender.current) {
      console.log(script);
      initallist();
    } else {
      onerender.current = false;
    }
  }, []);

  let strlist = () => {
    if (str_fields.length > 0) {
      return str_fields.map((item, st_i) => (
        <option key={st_i} value={item}>
          {item}
        </option>
      ));
    }
  };

  let intlist = () => {
    if (int_fields.length > 0) {
      return int_fields.map((item, s_i) => (
        <option key={s_i} value={item}>
          {item}
        </option>
      ));
    }
  };

  let bolnlist = () => {
    if (boln_fields.length > 0) {
      return boln_fields.map((item, bo_i) => (
        <option key={bo_i} value={item}>
          {item}
        </option>
      ));
    }
  };

  let reflist = () => {
    if (ref_fields.length > 0) {
      console.log("refflist");
      return ref_fields.map((item, b_i) => (
        <option key={b_i} value={item}>
          {item}
        </option>
      ));
    }
  };

  let reflist_UT = () => {
    console.log("reflist_UT ");
    if (refUT_fields.length > 0) {
      return refUT_fields.map((item, b_i) => (
        <option key={b_i} value={item}>
          {item}
        </option>
      ));
    }
  };

  let datelist = () => {
    if (date_fields.length > 0) {
      return date_fields.map((item, b_i) => (
        <option key={b_i} value={item}>
          {item}
        </option>
      ));
    }
  };

  let emailist = () => {
    if (email_fields.length > 0) {
      return email_fields.map((item, b_i) => (
        <option key={b_i} value={item}>
          {item}
        </option>
      ));
    }
  };

  let choicelist = () => {
    console.log("choicelist");
    if (choice_fields.length > 0) {
      return choice_fields.map((item, b_i) => (
        <option key={b_i} value={item}>
          {item}
        </option>
      ));
    }
  };

  let dtmlist = () => {
    if (dtm_fields.length > 0) {
      return dtm_fields.map((item, b_i) => (
        <option key={b_i} value={item}>
          {item}
        </option>
      ));
    }
  };

  let booleanfd = () => {
    if (booleanfld.length > 0) {
      return booleanfld.map((item, bb_i) => (
        <option key={bb_i} value={item}>
          {item}
        </option>
      ));
    }
  };

  return (
    <div style={{ textAlign: "start" }}>
      <div className={isMobile ? "scrl" : "form-control"}>
        {console.log(isScript)}
        {isScript ? (
          <div className="row ">
            {console.log("script check true")}
            <div className="col-md-2 mb-2">Script :</div>
            <div className="col-md-2 mb-2">
              <select
                className="inpt_typefil inpu_rad"
                type="text"
                onChange={(e) => setScript(e.target.value)}
                value={selectedScript}
              >
                <option value={""}>None</option>
                {script.map((tm, tm_o) => (
                  <option value={tm.name} key={tm_o}>
                    {tm.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ) : (
          <div className="disfl ">
            {console.log("time", timeline)}
            <div className="pdrt10 pdlf10 pdtp5">Time Line :</div>
            <div className="fntSz15">
              <select
                className="inpt_typefil inpu_rad"
                type="text"
                onChange={(e) => setTimeLine(e.target.value)}
                value={deftimeline?.current}
              >
                {tm_list.map((tm, tm_o) => (
                  <option value={tm.name} key={tm_o}>
                    {tm.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        )}
        {filter_unset && (
          <div className="my-2">
            <AlertCompo
              type={"danger"}
              msg={"Please set previous filter before adding a new filter"}
            />
          </div>
        )}
        {filtarray?.length > 0 &&
          filtarray.map((abc, index) => (
            <div key={index}>
              {isMobile ? (
                <div className="disfl martop" key={index}>
                  {abc.af === "AND" && filtarray.length > 1 && (
                    <div className="pdrt10">
                      <span className="addandcancel"> And </span>
                    </div>
                  )}
                  {abc.af === "OR" && filtarray.length > 1 && (
                    <div className="pdrt10">
                      <span className="addandcancel"> OR </span>
                    </div>
                  )}
                  <div className="pdrt10">
                    <select
                      className=" selct_optfil "
                      onChange={(e) => changecolumn(e.target.value, index)}
                      value={abc.co}
                    >
                      {col_mn.map((clm, cl_o) => (
                        <option value={clm.name} key={cl_o}>
                          {clm.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="pdrt10">
                    <div>
                      {console.log(abc.ct, userTable)}
                      <select
                        className=" selct_optfil"
                        onChange={(e) => changemiddle(e.target.value, index)}
                        value={abc.mc}
                      >
                        {abc.ct === "String" ? strlist() : null},
                        {abc.ct === "int" ? intlist() : null},
                        {abc.ct === "boolean" ? bolnlist() : null},
                        {abc.ct === "reference" && userTable === false
                          ? reflist()
                          : null}
                        ,
                        {abc.ct === "reference" && userTable === true
                          ? reflist_UT()
                          : null}
                        ,
                        {abc.ct === "multi_select" && userTable === true
                          ? reflist_UT()
                          : null}
                        ,{abc.ct === "date" ? datelist() : null},
                        {abc.ct === "datetime" ? dtmlist() : null},
                        {abc.ct === "email" ? emailist() : null},
                        {abc.ct === "choice" ? choicelist() : null},
                        {abc.ct === "depend_table" ? reflist() : null},
                      </select>
                    </div>
                  </div>
                  <div className="pdrt10">
                    {abc.mc !== "is empty" && abc.mc !== "is filled" && (
                      <div>
                        {abc.ct === "String" ? (
                          <input
                            className="inpt_typefil"
                            type="text"
                            placeholder="type"
                            value={abc.an}
                            onChange={(e) => changelast(e.target.value, index)}
                          ></input>
                        ) : null}
                        {abc.ct === "int" ? (
                          <input
                            className="inpt_typefil"
                            type="text"
                            placeholder="type"
                            value={abc.an}
                            onChange={(e) =>
                              changelastForInt(e.target.value, index)
                            }
                          ></input>
                        ) : null}
                        {abc.ct === "date" ? (
                          <input
                            className="inpt_typefil"
                            type="date"
                            value={abc.an}
                            onChange={(e) => changelast(e.target.value, index)}
                          ></input>
                        ) : null}

                        {abc.ct === "boolean" ? (
                          <select
                            className="inpt_typefil"
                            type="text"
                            value={abc.an}
                            onChange={(e) => changelast(e.target.value, index)}
                          >
                            {abc.ct === "boolean" ? booleanfd() : null},
                          </select>
                        ) : null}
                        {abc.ct === "datetime" ? (
                          <input
                            className="inpt_typefil"
                            type="datetime-local"
                            step="1"
                            value={abc.an}
                            onChange={(e) => changelast(e.target.value, index)}
                          ></input>
                        ) : null}
                        {abc.ct === "choice" ? (
                          <select
                            className=" selct_optfil"
                            // defaultValue={abc.ch[0].name}
                            value={abc.an}
                            onChange={(e) => changelast(e.target.value, index)}
                          >
                            {abc.ch.length > 0 &&
                              abc.ch.map((ch, ind) => (
                                <option key={ind} value={ch.name}>
                                  {ch.name}
                                </option>
                              ))}
                          </select>
                        ) : null}
                        {(abc.ct === "reference" ||
                          abc.ct === "depend_table" ||
                          abc.ct === "multi_select") &&
                        abc.mc === "is dependent" ? (
                          <div>
                            <div style={{ display: "flex", height: "1.9rem" }}>
                              <select
                                className="selct_optfil"
                                onChange={(e) =>
                                  changeDependentColumn(e, index)
                                }
                                value={abc.rf.value}
                              >
                                {col_mn.map((clm, cl_o) => (
                                  <option value={clm.name} key={cl_o}>
                                    {clm.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        ) : null}
                        {(abc.ct === "reference" ||
                          abc.ct === "depend_table" ||
                          abc.ct === "multi_select") &&
                        abc.mc !== "is me" &&
                        abc.mc !== "is dependent" ? (
                          <div style={{ position: "relative" }}>
                            <div style={{ display: "flex", height: "1.9rem" }}>
                              <input
                                className="inpt_typefil_ref"
                                type="text"
                                placeholder="type"
                                value={abc.rf.value}
                                onChange={(e) => changelastref(e, index)}
                              ></input>
                              <div
                                className="btnsrc_fil vlpointer"
                                onClick={(e) => {
                                  console.log("col" + columnId);
                                  setModalListParam(abc.co, index);
                                }}
                              >
                                <i
                                  className="fa fa-search"
                                  data-bs-toggle="modal"
                                  data-bs-target="#staticBackdrop"
                                ></i>
                              </div>
                              {refrecord.index === index &&
                                refrecord.record.length > 0 && (
                                  <div className="ref_multircd">
                                    {refrecord.record.map((obj_ref, or_i) => (
                                      <div
                                        onClick={(e) =>
                                          setRefRcd(
                                            obj_ref.value,
                                            index,
                                            obj_ref.name,
                                            obj_ref.type,
                                            obj_ref.id
                                          )
                                        }
                                        key={or_i}
                                      >
                                        {obj_ref.value}
                                      </div>
                                    ))}
                                  </div>
                                )}
                            </div>
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>
                  <div className="selct_optfil" style={{ display: "flex" }}>
                    {(abc.ct === "reference" ||
                      abc.ct === "depend_table" ||
                      abc.ct === "multi_select") &&
                    abc.mc === "is dependent" ? (
                      <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span>Dependent on:</span>
                          <div style={{ paddingLeft: "5px" }}>
                            <input
                              className="inpt_typefil inpu_rad"
                              disabled={true}
                              type="text"
                              value={depColVal ? depColVal : dcVal ? dcVal : ""}
                            />
                            {/* <select
                                className="selct_optfil"
                                onChange={(e) =>
                                  changeDependentRefColumn(e, index)
                                  // changeDependentRefColumn(e, abc.rf.value)
                                }
                                value={abc.dc.value}
                              >
                                {col_depend.map((clm, cl_o) => (
                                  <option value={clm.name} key={cl_o}>
                                    {clm.label}
                                  </option>
                                ))}
                              </select> */}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="pdrt10"></div>
                  <div className="col-md-2 ">
                    <div className="padbtnnn">
                      <div className="cancelbtn">
                        <i
                          className="fa fa-times"
                          onClick={(e) => cancelfilt(index)}
                        ></i>
                      </div>
                      <button
                        type="button"
                        className=" btnnn-sm btn btn-primary btn-sm"
                        onClick={() => {
                          addbtn(index);
                        }}
                      >
                        AND
                      </button>

                      <button
                        type="button"
                        className=" btnnn-sm btn btn-primary btn-sm"
                        onClick={() => {
                          orbtn(index);
                        }}
                      >
                        OR
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="disfl martop" key={index}>
                  {abc.af === "AND" && filtarray.length > 1 && (
                    <div className="pdrt10">
                      <span className="addandcancel"> And </span>
                    </div>
                  )}
                  {abc.af === "OR" && filtarray.length > 1 && (
                    <div className="pdrt10">
                      <span className="addandcancel"> OR </span>
                    </div>
                  )}
                  <div className="pdlf10 pdrt10">
                    <select
                      className=" selct_optfil "
                      onChange={(e) => changecolumn(e.target.value, index)}
                      value={abc.co}
                      disabled={isRelatedList && index === 0}
                    >
                      {col_mn.length > 0 &&
                        [
                          col_mn[0],
                          ...col_mn
                            .slice(1)
                            .sort((a, b) => a.label.localeCompare(b.label)),
                        ].map((clm, cl_o) => (
                          <option value={clm.name} key={cl_o}>
                            {clm.label}
                          </option>
                        ))}
                    </select>
                  </div>
                  <div className="pdrt10">
                    <div>
                      {console.log(abc, ref_fields, userTable)}

                      <select
                        className=" selct_optfil"
                        onChange={(e) => changemiddle(e.target.value, index)}
                        value={abc.mc}
                        disabled={isRelatedList && index === 0}
                      >
                        {abc.ct === "String" ? strlist() : null},
                        {abc.ct === "int" ? intlist() : null},
                        {abc.ct === "boolean" ? bolnlist() : null},
                        {abc.ct === "reference" && userTable === "false"
                          ? reflist()
                          : null}
                        ,
                        {abc.ct === "reference" && userTable === "true"
                          ? reflist_UT()
                          : null}
                        ,
                        {abc.ct === "multi_select" && userTable === "true"
                          ? reflist_UT()
                          : null}
                        ,{abc.ct === "date" ? datelist() : null},
                        {abc.ct === "datetime" ? dtmlist() : null},
                        {abc.ct === "email" ? emailist() : null},
                        {abc.ct === "choice" ? choicelist() : null},
                        {abc.ct === "depend_table" ? reflist() : null}
                      </select>
                    </div>
                  </div>
                  <div className="pdrt10">
                    {abc.mc !== "is empty" && abc.mc !== "is filled" && (
                      <div>
                        {abc.ct === "String" || abc.ct === "email" ? (
                          <input
                            disabled={isRelatedList && index === 0}
                            className="inpt_typefil inpu_rad"
                            type="text"
                            placeholder="type"
                            value={abc.an}
                            onChange={(e) => changelast(e.target.value, index)}
                          ></input>
                        ) : null}
                        {abc.ct === "int" ? (
                          <input
                            disabled={isRelatedList && index === 0}
                            className="inpt_typefil inpu_rad"
                            type="text"
                            placeholder="type"
                            value={abc.an}
                            onChange={(e) =>
                              changelastForInt(e.target.value, index)
                            }
                          ></input>
                        ) : null}
                        {abc.ct === "date" ? (
                          <input
                            disabled={isRelatedList && index === 0}
                            className="inpt_typefil inpu_rad"
                            type="date"
                            value={abc.an}
                            onChange={(e) => changelast(e.target.value, index)}
                          ></input>
                        ) : null}

                        {abc.ct === "boolean" ? (
                          <select
                            className="inpt_typefil inpu_rad"
                            type="text"
                            value={abc.an}
                            onChange={(e) => changelast(e.target.value, index)}
                            disabled={isRelatedList && index === 0}
                          >
                            {abc.ct === "boolean" ? booleanfd() : null},
                          </select>
                        ) : null}
                        {abc.ct === "datetime" ? (
                          <input
                            className="inpt_typefil"
                            type="datetime-local"
                            step="1"
                            value={abc.an}
                            onChange={(e) => changelast(e.target.value, index)}
                            disabled={isRelatedList && index === 0}
                          ></input>
                        ) : null}
                        {abc.ct === "choice" ? (
                          <select
                            className=" selct_optfil inpu_rad"
                            disabled={isRelatedList && index === 0}
                            value={abc.an}
                            onChange={(e) => changelast(e.target.value, index)}
                          >
                            {abc.ch.length > 0 &&
                              abc.ch.map((ch, ind) => (
                                <option key={ind} value={ch.name}>
                                  {ch.name}
                                </option>
                              ))}
                          </select>
                        ) : null}
                        {(abc.ct === "reference" ||
                          abc.ct === "depend_table" ||
                          abc.ct === "multi_select") &&
                        abc.mc === "is dependent" ? (
                          <div>
                            <div style={{ display: "flex", height: "1.9rem" }}>
                              <select
                                className="selct_optfil"
                                onChange={(e) =>
                                  changeDependentColumn(e, index)
                                }
                                value={abc.rf.value}
                                disabled={isRelatedList && index === 0}
                              >
                                {col_mn.map((clm, cl_o) => (
                                  <option value={clm.name} key={cl_o}>
                                    {clm.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        ) : null}
                        {(abc.ct === "reference" ||
                          abc.ct === "depend_table" ||
                          abc.ct === "multi_select") &&
                        abc.mc !== "is me" &&
                        abc.mc !== "is dependent" ? (
                          <div style={{ position: "relative" }}>
                            {/* {unverified && (
                              <div
                                className="alert alert-danger"
                                role="alert"
                                style={{
                                  padding: "0.2rem 0.2rem",
                                  marginBottom: "0px",
                                }}
                              >
                                Record not found!
                              </div>
                            )} */}
                            <div style={{ display: "flex", height: "1.9rem" }}>
                              <input
                                className="inpt_typefil_ref"
                                type="text"
                                placeholder="type"
                                value={abc.rf.value}
                                onChange={(e) => changelastref(e, index)}
                                disabled={isRelatedList && index === 0}
                              ></input>
                              <div
                                style={
                                  isRelatedList && index === 0
                                    ? {
                                        background: "#dfdfdf",
                                        cursor: "not-allowed",
                                      }
                                    : {}
                                }
                                className="btnsrc_fil vlpointer"
                                onClick={(e) => {
                                  if (isRelatedList) {
                                    if (index !== 0) {
                                      setModalListParam(abc.co, index);
                                    }
                                  } else {
                                    setModalListParam(abc.co, index);
                                  }
                                }}
                              >
                                <i className="fa fa-search"></i>
                              </div>
                            </div>
                            {refrecord.index === index &&
                              refrecord.record.length > 0 && (
                                <div className="ref_multircd">
                                  {refrecord.record.map((obj_ref, or_i) => (
                                    <div
                                      onClick={(e) =>
                                        setRefRcd(
                                          obj_ref.value,
                                          index,
                                          obj_ref.name,
                                          obj_ref.type,
                                          obj_ref.id
                                        )
                                      }
                                      key={or_i}
                                    >
                                      {obj_ref.value}
                                    </div>
                                  ))}
                                </div>
                              )}
                          </div>
                        ) : null}
                      </div>
                    )}
                  </div>
                  <div className="pdrt10" style={{ display: "flex" }}>
                    {(abc.ct === "reference" ||
                      abc.ct === "depend_table" ||
                      abc.ct === "multi_select") &&
                    abc.mc === "is dependent" ? (
                      <div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span>Dependent on:</span>
                          <div style={{ paddingLeft: "5px" }}>
                            <input
                              className="inpt_typefil inpu_rad"
                              disabled={true}
                              type="text"
                              value={depColVal ? depColVal : dcVal ? dcVal : ""}
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>
                  <div className="pdrt10"></div>

                  <div className="pdrt10">
                    <div className="padbtnnn">
                      <div className="cancelbtn">
                        {isRelatedList && index === 0 ? (
                          ""
                        ) : (
                          <i
                            className="fa fa-times"
                            onClick={(e) => cancelfilt(index)}
                          ></i>
                        )}
                      </div>
                      <button
                        type="button"
                        className=" btnnn-sm btn btn-primary btn-sm"
                        onClick={() => {
                          addbtn(index);
                        }}
                      >
                        AND
                      </button>

                      <button
                        type="button"
                        className=" btnnn-sm btn btn-primary btn-sm"
                        onClick={() => {
                          orbtn(index);
                        }}
                      >
                        OR
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
      </div>
      <Modal show={showModelList} onHide={cancelModelList} size="xl">
        <Modal.Header closeButton>
          <Modal.Title>Personalized List Column</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mod-ht">
          <ModelList
            setRef={setRef}
            columnid={columnId}
            onCancel={cancelModelList}
            previousFilter={"null"}
            previousTimeLine={" "}
            tableName={tableName}
            tabId={tabId}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={cancelModelList}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      {console.log("filt array9")}
    </div>
  );
};

export default NewFilterCompo;
