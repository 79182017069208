import axios from "axios";
import "../css/ApplicationSet.css";
import React, { useState, useContext, useEffect } from "react";

import { AutoPortalContext } from "../Context";
import WorkInProgress from "./WorkInProgress";
import { useNavigate } from "react-router-dom";
import Header from "./Header";

const ApplicationSet = () => {
  const navigation = useNavigate();
  const { token, loca, showAll, setAppName, getVerify, appName } =
    useContext(AutoPortalContext);
  const [loading, setLoading] = useState(true);
  const [record, setRecord] = useState([]);
  const [userApplication, setUserApplication] = useState({});
  const [isClick, setIsClick] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [app, setApp] = useState("");

  useEffect(() => {
    setLoading(true);
    getApplicationsDetails();
  }, [refresh]);

  const getApplicationsDetails = () => {
    axios
      .get(loca + "/lom/get/applications", {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (response) => {
          const appsRcd = response.data;
          console.log(appsRcd.user_application[1].application_set.name,appsRcd);
          setRecord([...appsRcd.user_application[0].applications]);
          setUserApplication(appsRcd.user_application[1].application_set);
          setApp(appsRcd.user_application[1].application_set.name);
          setLoading(false);
        },
        (error) => {
          navigation("/error");
        }
      );
  };

  const changeApp = (val) => {
    console.log(app, appName);
    setApp(val);
  };

  const setUserApp = () => {
    console.log("set User App");
    axios
      .get(loca + "/lom/set/userapplication/" + app, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then((resp) => {
        setAppName(record[record.findIndex((a) => a.name === app)].label);
        getVerify();
        getApplicationsDetails();
      });
  };
  if (!loading) {
    return (
      <div style={{ width: "100%" }}>
        <Header
          tabName={"Application Set"}
          isClick={isClick}
          refresh={refresh}
          setRefresh={setRefresh}
        />
        {/* <div style={{ flexGrow: 1 }} className="container  cardcss p-1"> */}
        <div className="container  cardcss p-1">
          {showAll === "false" ? (
            <div>
              {" "}
              <div className="text-start d-flex align-items-center mt-3 mx-2">
                <h5>Application:</h5>
                <select
                  value={app}
                  className=" form-select py-1 ms-2"
                  onChange={(e) => changeApp(e.target.value)}
                >
                  {record.map((obj, index) => (
                    <option
                      key={index}
                      value={obj.name}
                      // selected={userApplication.label === obj.label}
                    >
                      {obj.label}
                    </option>
                  ))}
                </select>
              </div>
              <button
                // className="btn btn-primary sm "
                className="btn btn-dark  mt-2 mb-2 ms-2 px-2 py-1"
                value="Set"
                disabled={app === appName}
                onClick={() => {
                    setUserApp();
                }}
              >
                Set
              </button>
            </div>
          ) : (
            <div>
              <h1>Show All Is Checked</h1>
            </div>
          )}
        </div>
      </div>
    );
  } else {
    return (
      <div className="d-flex justify-content-center align-items-center w-100" style={{height:"95vh"}}>
        <WorkInProgress />
      </div>
    );
  }
};

export default ApplicationSet;
