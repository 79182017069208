import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AutoPortalContext } from "../Context";

const AiCompo = () => {
  const { loca, token } = useContext(AutoPortalContext);
  const [sentence, setSentence] = useState("");
  const [chat, setChat] = useState([]);
  const accessToken = useRef("");
  const session_id = useRef("");
  const formRef = useRef();
  const containerRef = useRef();

  // const pred = () => {
  //   let json = { sentence: sentence };
  //   axios
  //     .post(`${loca}/lom/predict`, json, {
  //       headers: {
  //         authorization: "Bearer " + token,
  //       },
  //     })
  //     .then(
  //       (resp) => {
  //         let rs = resp.data;
  //       },
  //       (error) => {
  //         console.log(error);
  //       }
  //     );
  // };

  const detectIntent = async () => {
    if (!accessToken) {
      return;
    }
    let obj = { user: sentence, bot: "" };
    console.log(token);
    const apiUrl = `https://dialogflow.googleapis.com/v2/projects/zenit-yjgk/agent/sessions/${session_id}:detectIntent`;
    // try {
    const response = await axios
      .post(
        apiUrl,
        {
          queryInput: {
            text: {
              text: sentence,
              languageCode: "en",
            },
            parameters: {
              token: "zakir",
            },
          },

          queryParams: {
            payload: {
              token: token,
            },
          },
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken.current}`,
            "Content-Type": "application/json",
          },
        }
      )
      .then(
        (resp) => {
          let response = resp.data;
          if (response) {
            let text = response.queryResult.fulfillmentText;
            text = renderTextWithLinks(text);
            console.log(text);
            obj.bot = text;
            console.log(obj);
            let cht = chat;
            cht.push(obj);
            setChat([...cht]);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    // console.log(response.data);
    // return response.data;
    // } catch (error) {
    //   console.error("Error calling Dialogflow:", error);
    //   throw error;
    // }
  };

  const getToken = async () => {
    const response = await axios
      .get(loca + "/access-token", {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then(
        (resp) => {
          let res = resp.data;
          console.log(res);
          accessToken.current = res.token;
          session_id.current = res.session;
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const autoResize = (e) => {
    setSentence(e);
    const textarea = document.getElementById("textarea");
    const scrollHeight = textarea.scrollHeight;
    if (scrollHeight > 62) {
      textarea.style.height = "auto";
      textarea.style.height = scrollHeight + "px";
    } else {
      textarea.style.height = "40px";
    }
  };

  const initDetectIntent = () => {
    detectIntent();
    setTimeout(() => {
    
      const textarea = document.getElementById("textarea");
      textarea.style.height = "40px";
      setSentence("");
      scrollToBottom();
    }, 500);
  };

  const onEnterPress = (e) => {
    if (e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      initDetectIntent();
    }
  };

  const scrollToBottom = () => {
    if (containerRef.current) {
      containerRef.current.lastElementChild.scrollIntoView({
        behavior: "smooth",
      });
      console.log("botttom" + containerRef.current.scrollTop);
    }
    // containerRef.current.scrollTop = containerRef.current.scrollHeight+50;
  };

  const renderTextWithLinks = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part, index) => {
      if (part.match(urlRegex)) {
        return (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        );
      } else {
        return <span key={index}>{part}</span>;
      }
    });
  };

  useEffect(() => {
    getToken();
  }, []);

  return (
    <div className="container">
      <div
        className="chat-container"
        style={{
          maxHeight: window.screen.height > 700 ? "95vh" : "85vh",
          overflow: "auto",
          paddingBottom: "50px",
        }}
      >
        {chat.length > 0 &&
          chat.map((obj, index) => (
            <div key={index}>
              <section style={{ marginTop: 20, marginBottom: 20 }}>
                <h5>User</h5>
                <span key={index}>{obj.user}</span>
              </section>
              <section
                ref={containerRef}
                style={{ marginTop: 20, marginBottom: 20 }}
              >
                <h5>Bot</h5>
                <p key={index}>{obj.bot}</p>
              </section>
            </div>
          ))}
      </div>
      <form
        ref={formRef}
        onSubmit={(e) => {
          e.preventDefault();
          initDetectIntent();
        }}
      >
        <div
          className="row p-2"
          style={{
            position: "absolute",
            bottom: 0,
            zIndex: 10,
            width: "70%",
            flexWrap: "nowrap",
          }}
        >
          <div className="col-md d-flex justify-content-center align-item-center">
            <textarea
              id="textarea"
              onKeyDown={onEnterPress}
              style={{
                width: "100%",
                height: 40,
                padding: 8,
                overflow: "hidden",
                resize: "none",
                alignSelf: "center",
              }}
              value={sentence}
              onChange={(e) => {
                e.preventDefault();
                autoResize(e.target.value);
              }}
            />
          </div>
          <div className="col-sm-2 ps-0 d-flex">
            <button
              type="submit"
              className=" btn btn-dark"
              style={{ alignSelf: "end", height: "fit-content" }}
              // onClick={() => {
              //   detectIntent();
              // }}
            >
              {">>"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default AiCompo;
