import React from "react";
import LoginForm from "./LoginForm.jsx";
import "../css/MainComponent.css";
import RegisterComponent from "./RegisterComponent.jsx";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import DashboradComponent from "./DashboradComponent.jsx";
import InitialSetupcomponent from "./InitialSetupComponent.jsx";
import FormComponent from "./FormComponent.jsx";
import PrivateWrapper from "./PrivateWrapper.jsx";
import ListComponent from "./ListComponent.jsx";
import ApplicationSet from "./ApplicationSet.jsx";
import Profile from "./Profile.jsx";
import OwnerPrefComp from "./OwnerPrefrence_component.jsx";
import FormViewComponent from "./FormViewComponent.jsx";
import ChecklistComponent from "./ChecklistComponent.jsx";
import UpdatePassword from "./UpdatePassword.jsx";
import ForgetPassword from "./ForgetPassword.jsx";
import OtpVerification from "./OtpVerification.jsx";
import GenerateNewPin from "./GenerateNewPin.jsx";
import FlowComponent from "./FlowComponent.jsx";
import ReportCompo from "./ReportCompo.jsx";
import UiPolicy from "./UiPolicy.jsx";
import UiPolicyAction from "./UiPolicyAction.jsx";
import RoleSelectionComponent from "./RoleSelectionComponent.jsx";
import ApplicationAccessComponent from "./ApplicationAccessComponent.jsx";
import SetNewPassword from "./SetNewPassword.jsx";
import MultiTableRelation from "./MultiTableRelation.jsx";
import MultiInsertComponent from "./MultiInsertComponent.jsx";
import NewApplication from "./NewApplication.jsx";
import ClientLogin from "./ClientLogin.jsx";
import ClientInfoCompo from "./ClientInfoCompo.jsx";
import ClientInfoRecCompo from "./ClientInfoRecCompo.jsx";
import EditorComponent from "./EditorComponent.jsx";
import TableSetup from "./TableSetup.jsx";
import HtmlPage from "./HtmlPage.jsx";
import Setting from "./Setting.jsx";
// import Test from "./Test.jsx";
import UserCompo from "./UserCompo.jsx";
import UserWrapper from "./UserWrapper.jsx";
import PortalCompo from "./PortalCompo.jsx";
import PortalResult from "./PortalResult.jsx";
import GridCompo from "./GridCompo.jsx";
import LandingCompo from "./LandingCompo.jsx";
import Test from "./Test.jsx";
import NewPageTest from "./NewPageTest.jsx";
import VarBlankCompo from "./VarBlankCompo.jsx";
import "react-toastify/dist/ReactToastify.css";
import ApplicationSetup from "./ApplicationSetup.jsx";
import Tester from "./Tester.jsx";
import ErrorPage from "./ErrorPage.jsx";
import { ToastContainer, toast } from "react-toastify";
import AppRequestCompo from "./AppRequestCompo.jsx";
import TabularReportCompo from "./TabularReportCompo.jsx";
import ConfigureCompo from "./ConfigureCompo.jsx";
import KnowledgeCompo from "./KnowledgeCompo.jsx";
import KnowledgeBase from "./KnowledgeBase.jsx";
import KnowledgeCategoryCompo from "./KnowledgeCategoryCompo.jsx";
import AiCompo from "./AiCompo.jsx";
import InvoiceCompo from "./InvoiceCompo.jsx";
import Invoice from "./Invoice.jsx";
import DashBoardSelectCompo from "./DashBoardSelectCompo.jsx";
import MultipleAppReq from "./MultipleAppReq.jsx";
import WebWrapper from "./WebWrapper.jsx";

const MainComponent = () => {
  const routers = createBrowserRouter([
    {
      path: "/",
      element: <WebWrapper />,
      children: [
        {
          path: "/error",
          element: <ErrorPage error={null} />,
        },
        {
          path: "/login",
          element: <LoginForm />,
        },
        {
          path: "/ForgetPassword",
          element: <ForgetPassword />,
        },
        {
          path: "/otpverify",
          element: <OtpVerification />,
        },
        {
          path: "/Newpin",
          element: <GenerateNewPin />,
        },
        {
          path: "/signup",
          element: <RegisterComponent />,
        },
        {
          path: "/reqNewAppCreate",
          element: <AppRequestCompo />,
        },
        {
          path: "/NewApplication",
          element: <NewApplication />,
        },
        {
          path: "/setNewPassword",
          element: <SetNewPassword />,
        },
        {
          path: "/landing",
          element: <LandingCompo />,
        },
        {
          path: "/newtest",
          element: <NewPageTest />,
        },
        {
          path: "/tester",
          element: <Tester />,
        },
        {
          element: <PrivateWrapper />,
          children: [
            {
              path: "/dashboard",
              element: <DashboradComponent />,
            },
            {
              path: "/dashboard-select",
              element: <DashBoardSelectCompo />,
            },
            {
              path: "/profilePage",
              element: <Profile />,
            },
            {
              path: "/test",
              element: <Test />,
            },
            {
              path: "/form",
              element: <FormComponent />,
            },
            {
              path: "/list",
              element: <ListComponent />,
            },
            {
              path: "/config_list",
              element: <ConfigureCompo />,
            },
            {
              path: "/report_view",
              element: <TabularReportCompo />,
            },
            {
              path: "/appsetup",
              element: <ApplicationSetup />,
            },
            {
              path: "/tablesetup",
              element: <TableSetup />,
            },
            {
              path: "/OwnerPref",
              element: <OwnerPrefComp />,
            },
            {
              path: "/application",
              element: <ApplicationSet />,
            },
            {
              path: "/var",
              element: <VarBlankCompo />,
            },
            {
              path: "/flow",
              element: <FlowComponent />,
            },
            {
              path: "/report",
              element: <ReportCompo />,
            },
            {
              path: "/viewcompo",
              element: <FormViewComponent />,
            },
            {
              path: "/invoiceCompo",
              element: <InvoiceCompo />,
            },
            {
              path: "/invoice",
              element: <Invoice />,
            },
            {
              path: "/policy",
              element: <UiPolicy />,
            },
            {
              path: "/policy_action",
              element: <UiPolicyAction />,
            },
            {
              path: "/htmlcall",
              element: <HtmlPage />,
            },
            {
              path: "/setting",
              element: <Setting />,
            },
            {
              path: "/ai",
              element: <AiCompo />,
            },
            {
              path: "/initialsetup",
              element: <InitialSetupcomponent />,
            },
            {
              path: "/checklicompo",
              element: <ChecklistComponent />,
            },
            {
              path: "/updatepassword",
              element: <UpdatePassword />,
            },
            {
              path: "/role-selection",
              element: <RoleSelectionComponent />,
            },
            {
              path: "/appaccess",
              element: <ApplicationAccessComponent />,
            },
            {
              path: "/multipleAppRequest",
              element: <MultipleAppReq />,
            },
            {
              path: "/multiinsert",
              element: <MultiInsertComponent />,
            },
            {
              path: "/multitable",
              element: <MultiTableRelation />,
            },
            {
              path: "/clientinfo",
              element: <ClientInfoCompo />,
            },
            {
              path: "/portalOutPut",
              element: <PortalResult />,
            },
            {
              path: "/clientinforecord",
              element: <ClientInfoRecCompo />,
            },
            {
              path: "/portal",
              element: <PortalCompo />,
            },
            {
              path: "/grid",
              element: <GridCompo />,
            },
            {
              path: "/texteditor",
              element: <EditorComponent />,
            },
            {
              path: "/knowledge",
              element: <KnowledgeCompo />,
            },
            {
              path: "/knowledgeBase",
              element: <KnowledgeBase />,
            },
            {
              path: "/knowledgeCategory",
              element: <KnowledgeCategoryCompo />,
            },
          ],
        },
        {
          element: <UserWrapper />,
          children: [
            {
              path: "/user/dashboard",
              element: <DashboradComponent />,
            },
            {
              path: "/user/dashboard-select",
              element: <DashBoardSelectCompo />,
            },
            {
              path: "/user",
              element: <UserCompo />,
            },
            {
              path: "/user/profilePage",
              element: <Profile />,
            },
            {
              path: "/user/test",
              element: <Test />,
            },
            {
              path: "/user/form",
              element: <FormComponent />,
            },
            {
              path: "/user/list",
              element: <ListComponent />,
            },
            {
              path: "/user/report_view",
              element: <TabularReportCompo />,
            },
            {
              path: "/user/appsetup",
              element: <ApplicationSetup />,
            },
            {
              path: "/user/tablesetup",
              element: <TableSetup />,
            },
            {
              path: "/user/OwnerPref",
              element: <OwnerPrefComp />,
            },
            {
              path: "/user/application",
              element: <ApplicationSet />,
            },
            {
              path: "/user/var",
              element: <VarBlankCompo />,
            },
            {
              path: "/user/flow",
              element: <FlowComponent />,
            },
            {
              path: "/user/report",
              element: <ReportCompo />,
            },
            {
              path: "/user/viewcompo",
              element: <FormViewComponent />,
            },
            {
              path: "/user/policy",
              element: <UiPolicy />,
            },
            {
              path: "/user/config_list",
              element: <ConfigureCompo />,
            },
            {
              path: "/user/knowledge",
              element: <KnowledgeCompo />,
            },
            {
              path: "/user/knowledgeBase",
              element: <KnowledgeBase />,
            },
            {
              path: "/user/knowledgeCategory",
              element: <KnowledgeCategoryCompo />,
            },
            {
              path: "/user/policy_action",
              element: <UiPolicyAction />,
            },
            {
              path: "/user/htmlcall",
              element: <HtmlPage />,
            },
            {
              path: "/user/setting",
              element: <Setting />,
            },
            {
              path: "/user/checklicompo",
              element: <ChecklistComponent />,
            },
            {
              path: "/user/updatepassword",
              element: <UpdatePassword />,
            },
            {
              path: "/user/role-selection",
              element: <RoleSelectionComponent />,
            },
            {
              path: "/user/appaccess",
              element: <ApplicationAccessComponent />,
            },
            {
              path: "/user/multiinsert",
              element: <MultiInsertComponent />,
            },
            {
              path: "/user/multitable",
              element: <MultiTableRelation />,
            },
            {
              path: "/user/clientinfo",
              element: <ClientInfoCompo />,
            },
            {
              path: "/user/portalOutPut",
              element: <PortalResult />,
            },
            {
              path: "/user/clientinforecord",
              element: <ClientInfoRecCompo />,
            },
            {
              path: "/user/portal",
              element: <PortalCompo />,
            },
            {
              path: "/user/grid",
              element: <GridCompo />,
            },
            {
              path: "/user/texteditor",
              element: <EditorComponent />,
            },
          ],
        },
      ],
    },
  ]);

  return (
    <>
      <RouterProvider router={routers} />
      <ToastContainer
        toastStyle={{ marginTop: "2vh" }}
        position="top-center"
        autoClose={1800}
      />
    </>
  );
};
export default MainComponent;
