import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { AutoPortalContext } from "../Context";
// import CodeEditor from "@uiw/react-textarea-code-editor";
// import AceEditor from "react-ace";

import "../css/Portal.css";

import { Editor, useMonaco } from "@monaco-editor/react";
import { toast } from "react-toastify";
import Select from "react-select";
import Header from "./Header";
import WorkInProgress from "./WorkInProgress";
// import MonacoEditor from "react-monaco-editor/lib/editor";

const PortalCompo = () => {
  const { token, loca, user } = useContext(AutoPortalContext);

  const navigation = useNavigate();
  const { html2, css2, script2, arrFunction, arrBody, portalFrom, pageFrom } =
    useLocation().state || "";

  const [searchParam, setSearchParam] = useSearchParams();
  // console.log(html2, css2, script2);


  const [html, setHtml] = useState(html2 ?? "");
  const [script, setScript] = useState(script2 ?? "");
  const [css, setCss] = useState(css2 ?? "");
  const [portalList, setPortalList] = useState([]);
  const [isClick, setIsClick] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [editorHeight, setEditorHeight] = useState('60vh');
  const [editorJsxHeight, setEditorJsxHeight] = useState('60vh');
  const [portalPageList, setPortalPageList] = useState([
    { id: -1, value: -1, name: "none", label: "none" },
  ]);
  const [portalId, setPortalId] = useState({
    id: -1,
    value: -1,
    name: "none",
    label: "none",
  });
  const [portalPageId, setPortalPageId] = useState({
    id: -1,
    value: -1,
    name: "none",
    label: "none",
  });
  // const [name, setName] = useState("");

  // useRef Hooks
  const portalListRef = useRef("");
  const portalPageListRef = useRef([
    { id: -1, value: -1, name: "none", label: "none" },
  ]);
  const portal = useRef("");
  const page = useRef("");
  const isBack = useRef(false);

  const submitFn = () => {
    setIsClick(true);
    setLoading(true);
    if (portalPageId.id === -1) {
      // console.log("select page!");
      toast.info("select page!");
      return;
    }
    let newHtml = html
      // .replaceAll('"', '\"')
      // .replaceAll("\n", "\\n")
      // .replaceAll("\r", "\\r")
      // .replaceAll("\t", "\\t");

    let newScript = script
      // .replaceAll('"', '\"')
      // .replaceAll("\n", "\\n")
      // .replaceAll("\r", "\\r")
      // .replaceAll("\t", "\\t");

    let newCss = css
      // .replaceAll('"', '\"')
      // .replaceAll("\n", "\\n")
      // .replaceAll("\r", "\\r")
      // .replaceAll("\t", "\\t");

    // console.log("csssss " +css);
    let page_JSON = {
      html: newHtml,
      script: newScript,
      css: newCss,
      portalPageId: portalPageId.id + "",
    };

    // console.log(JSON.stringify(page));
    axios
      .post(loca + "/lom/update/portal/pages", page_JSON, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let result = resp.data;
          console.log(result);
          toast.success("Updated successfully");
          setLoading(false);
        },
        (error) => {
          navigation("/error");
          console.log(error);
        }
      )
      .finally(() => {
        console.log("finally");
        setIsClick(false);
      });
  };

  const callPortalList = () => {
    console.log(portal.current, page.current);

    axios
      .get(loca + "/lom/get/portal", {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then((resp) => {
        let pd = resp.data;
        setLoading(false);
        console.log("i m in " + JSON.stringify(pd));
        let pt = pd.portal;
        pt.unshift({ id: -1, value: -1, name: "none", label: "none" });
        let selected = pt.find((e) => e.name === portal.current);

        setPortalList([...pt]);
        portalListRef.current = pt;

        if (selected) {
          setPortalId(selected);
          portalSelect(selected.id);
        } else {
          setPortalPageId({ id: -1, value: -1, name: "none", label: "none" });
          setPortalId({ id: -1, value: -1, name: "none", label: "none" });
          setCss("");
          setScript("");
          setHtml("");
          // searchParam.delete("portal");
          // searchParam.delete("page");
          // setSearchParam(searchParam);
        }
      });
  };

  const testFn = () => {
    if (portalPageId.id === -1) {
      // console.log("select page!");
      toast.info("select page!");
      return;
    }

    let selectedPortal = portalListRef.current.find((e) => e.name === portalId.name);

    let selectedPage = portalPageListRef.current.find((e) => e.name === portalPageId.name);

    let pj = { html: html, css: css, script: script, portal: selectedPortal.name, page: selectedPage.name }
    localStorage.setItem('portal_JSON', JSON.stringify(pj))
    navigation(user + "/portalOutPut", {
      state: {
        html: html,
        script: script,
        css: css,
        arrFunction: arrFunction,
        arrBody: arrBody,
      },
    });
  };

  const portalSelect = (id) => {
    console.log(id);

    if (id !== -1) {
      let selected = portalListRef.current.find((e) => e.id === id);
      console.log(id, selected, portalListRef.current);
      if (selected.id != -1) {
        // searchParam.set("portal", selected.name);
        // setSearchParam(searchParam);
      }
      axios
        .get(loca + "/lom/get/portal/pages/" + id, {
          headers: {
            authorization: "Bearer " + token,
          },
        })
        .then((resp) => {
          let pd = resp.data;
          console.log(pd);
          let pt = pd.portalPages;
          pt.unshift({ id: -1, value: -1, name: "none", label: "none" });
          portalPageListRef.current = pt;

          let selected = pt.find((e) => e.name === page.current);
          if (selected) {
            console.log(isBack.current);

            if (!isBack.current) {
              portalPageSelect(selected.id);
            }
            setPortalPageId(selected);
          } else {
            resetPortalPage();
          }

          setPortalPageList([...pt]);
        });
    } else {
      resetAll();
    }
  };

  const resetAll = () => {
    setPortalPageId({ id: -1, value: -1, name: "none", label: "none" });
    setPortalPageList([{ id: -1, value: -1, name: "none", label: "none" }]);
    setCss("");
    setScript("");
    setHtml("");
    portalPageListRef.current = [
      { id: -1, value: -1, name: "none", label: "none" },
    ];
    // searchParam.delete("portal");
    // searchParam.delete("page");
    // setSearchParam(searchParam);
  };

  const resetPortalPage = () => {
    setPortalPageId({ id: -1, value: -1, name: "none", label: "none" });
    setCss("");
    setScript("");
    setHtml("");
    // searchParam.delete("page");
    // setSearchParam(searchParam);
  };

  const portalPageSelect = (id) => {
    let selected = portalPageListRef.current.find((e) => e.id === id);
    console.log(selected);

    if (selected.id !== -1) {
      // searchParam.set("page", selected.name);
      // setSearchParam(searchParam);
      setCss(selected.css);
      setScript(selected.script);
      setHtml(selected.html);
    } else {
      setCss("");
      setScript("");
      setHtml("");
      // searchParam.delete("page");
      // setSearchParam(searchParam);
    }
  };

  const monaco = useMonaco();

  useEffect(() => {
    if (monaco) {
      // Configure JavaScript/TypeScript language support to handle JSX
      monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
        jsx: monaco.languages.typescript.JsxEmit.React,
        reactNamespace: "React",
        allowNonTsExtensions: true,
        allowJs: true, // Allow JavaScript files
      });

      // Disable unnecessary diagnostics to avoid false positives with JSX
      monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
        noSemanticValidation: true, // Disable semantic validation
        noSyntaxValidation: true, // Disable syntax validation (optional, if you face issues)
      });

      // Register a custom theme to highlight JSX tags and template tags
      monaco.editor.defineTheme("myCustomTheme", {
        base: "vs-dark",
        inherit: true,
        rules: [
          { token: "delimiter.angle", foreground: "808080" }, // Gray for < and >
          { token: "tag", foreground: "0000FF" }, // Blue for tag names
          { token: "attribute.name", foreground: "87CEFA" }, // Light sky blue
          { token: "attribute.value", foreground: "FFD700" }, // Gold
        ],
        colors: {
          "editor.background": "#1E1E1E",
        },
      });

      // Register a custom tokenizer for JSX
      monaco.languages.setMonarchTokensProvider("javascript", {
        tokenizer: {
          root: [
            [/[{}()\[\]]/, "@brackets"],
            [/\<[\/]?/, "delimiter.angle"], // Highlight < and >
            [/>/, "delimiter.angle"], // Highlight >
            [/(\w+)(?=\s|\/?>)/, "tag"], // Match tag name followed by whitespace or end of tag
            [
              /(\w+)(=)("[^"]*"|'[^']*'|{[^{]*{)/,
              ["attribute.name", "delimiter", "attribute.value"],
            ], // Attributes
            [/"/, { token: "string.quote", bracket: "@open", next: "@string" }],
          ],
          string: [
            [/[^\\"]+/, "string"],
            [/"/, { token: "string.quote", bracket: "@close", next: "@pop" }],
          ],
        },
      });

      // Define a list of common HTML and JSX tags for autocompletion
      const htmlTags = [
        "div",
        "span",
        "h1",
        "h2",
        "h3",
        "h4",
        "h5",
        "h6",
        "p",
        "a",
        "ul",
        "li",
        "table",
        "tr",
        "td",
        "thead",
        "tbody",
        "img",
        "form",
        "input",
        "button",
        "section",
        "article",
        "footer",
        "header",
        "nav",
        "aside",
        "main",
        "figure",
        "figcaption",
        "blockquote",
        "code",
        "pre",
        "canvas",
        "audio",
        "video",
        "svg",
        // Add more HTML/JSX tags as needed
      ];

      // Register HTML/JSX tag autocompletion
      monaco.languages.registerCompletionItemProvider("javascript", {
        provideCompletionItems: (model, position) => {
          const textUntilPosition = model.getValueInRange({
            startLineNumber: position.lineNumber,
            startColumn: position.column - 1,
            endLineNumber: position.lineNumber,
            endColumn: position.column,
          });

          // Only show suggestions if the user is typing an HTML/JSX tag
          if (textUntilPosition === "<") {
            return {
              suggestions: htmlTags.map((tag) => ({
                label: tag,
                kind: monaco.languages.CompletionItemKind.Property,
                insertText: tag,
                range: {
                  startLineNumber: position.lineNumber,
                  startColumn: position.column,
                  endLineNumber: position.lineNumber,
                  endColumn: position.column,
                },
              })),
            };
          }

          return { suggestions: [] };
        },
      });
    }
  }, [monaco]);

  useEffect(() => {
    console.log("kkgg", portalFrom, pageFrom, html2);
    setLoading(true);
    let pj = localStorage.getItem("portal_JSON")

    if (pj) {
      console.log(pj);
      pj = JSON.parse(pj);

      portal.current = pj.portal;
      page.current = pj.page;
      setHtml(pj.html)
      setCss(pj.css)
      setScript(pj.script)
      isBack.current = true
      localStorage.removeItem("portal_JSON")
    } else {
      portal.current = searchParam.get("portal");
      page.current = searchParam.get("page");
      isBack.current = false
    }
    callPortalList();

    console.log(portal.current, page.current);

    callPortalList();
  }, [refresh]);

  const handleExpandClick = () => {
    // Toggle between expanded and normal height
    const newHeight = editorHeight === '60vh' ? '100vh' : '60vh';
    setEditorHeight(newHeight);
  };

  const handleJsxExpandClick = () => {
    // Toggle between expanded and normal height
    const newHeight = editorJsxHeight === '60vh' ? '100vh' : '60vh';
    setEditorJsxHeight(newHeight);
  };

  const commonStyles = {
    background: "#1e1e1e",
    position: "absolute",
    width: "99vw",
    top: "0",
    left: "0",
    zIndex: "1050",
  };

  // useEffect(() => {
  //   // setHtml(html2)
  //   call_Widget();
  // }, []);
  if (!loading) {
    return (
      <div style={{ flexGrow: 1 }} id="container" className="cont">
        <Header tabName={"Portal Page"}
          isClick={isClick}
          refresh={refresh}
          setRefresh={setRefresh}
        />
        <div className="upper-box">
          <div className="d-flex justify-content-center align-items-center">
            <p className="mg-z ">Portal : </p>
            <div style={{ width: "28vw" }}>
              <Select
                className="mx-2"
                isSearchable={true}
                options={portalList}
                value={portalId}
                onChange={(e) => {
                  portalSelect(e.id);
                  setPortalId(e);
                }}
              />
              {/* <select
              className="slct mx-1"
              style={{ widht: "50%" }}
              value={portalId}
              onChange={(e) => {
                portalSelect(e.target.value);
                setPortalId(e.target.value);
              }}
            >
              <option value={-1}>None</option>
              {portalList.length > 0 &&
                portalList.map((obj, index) => (
                  <option key={index} value={obj.id}>
                    {obj.name}
                  </option>
                ))}
            </select> */}
            </div>
            <p className="mg-z ">Page : </p>
            <div style={{ width: "28vw" }}>
              <Select
                className="mx-2"
                isSearchable={true}
                options={portalPageList}
                value={portalPageId}
                onChange={(e) => {
                  portalPageSelect(e.id);
                  setPortalPageId(e);
                }}
              />
              {/* <select
              className="slct mx-1"
              style={{ widht: "50%" }}
              value={portalPageId}
              onChange={(e) => {
                portalPageSelect(e.target.value);
                setPortalPageId(e.target.value);
              }}
            >
              <option value={-1}>None</option>
              {portalPageList.length > 0 &&
                portalPageList.map((obj, index) => (
                  <option key={index} value={obj.id}>
                    {obj.name}
                  </option>
                ))}
            </select> */}
            </div>
            {/* <div>
            <input
              type="text"
              className="me-1 slct"
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </div> */}
          </div>
          <div className="d-flex">
            <button
              className="btn btn-primary mx-1"
              style={{ margin: 0, marginTop: 5, marginRight: 5 }}
              onClick={testFn}
            >
              Test
            </button>
            <button
              className="btn btn-primary mx-1"
              style={{ margin: 0, marginRight: 5, marginTop: 5 }}
              onClick={submitFn}
            >
              Save
            </button>
          </div>
        </div>
        <div className="row ct" style={{ height: "80%" }}>
          <div className="wd">
            <h1 style={{ height: "10%", margin: 0 }}>JSX</h1>
            <div className="" style={{ height: editorJsxHeight, background: "#1e1e1e", ...(editorJsxHeight === "100vh" ? commonStyles : {}) }}>
              {editorJsxHeight === "100vh" ? (
                <div >
                  <i className="fa-solid fa-compress expandicon " onClick={handleJsxExpandClick}></i>
                </div>
              ) : (
                <div >
                  <i className="fa-solid fa-expand expandicon " onClick={handleJsxExpandClick}></i>
                </div>
              )
              }
              {/* <AceEditor
              value={html}
              mode="html"
              theme="monokai"
              fontSize="16px"
              highlightActiveLine={true}
              onChange={(e)=>{setHtml(e)}}
              setOptions={{
                enableLiveAutocompletion: true,
                showLineNumbers: true,
                tabSize: 2,
              }}
            /> */}
              <Editor
                language="javascript"
                theme="vs-dark"
                value={html}
                onChange={(e, i) => {
                  setHtml(e);
                }}
                options={{
                  inlineSuggest: true,
                  fontSize: "16px",
                  formatOnType: true,
                  autoClosingBrackets: true,
                  minimap: {
                    scale: 10,
                    enabled: false,
                    showSlider: "always",
                  },
                }}
              // beforeMount={handleEditorWillMount}
              />
            </div>
            {/* <textarea
            value={html}
            style={{ height: "90%", width: "100%", margin: 0 }}
            onChange={(e) => setHtml(e.target.value)}
          ></textarea> */}
          </div>
          {/* <div className="wd"> */}
          {/* <div style={{ height: "70vh", width: "45vh", overflowY: "scroll" }}> */}
          {/* <h1 style={{ height: "10%", margin: 0 }}>css</h1>
            <div className="editor">
              <Editor
                language="css"
                theme="vs-dark"
                value={css}
                onChange={(e, i) => {
                  setCss(e);
                }}
                options={{
                  inlineSuggest: true,
                  fontSize: "16px",
                  formatOnType: true,
                  autoClosingBrackets: true,
                  minimap: {
                    scale: 10,
                    enabled: false,
                    showSlider: "always",
                  },
                }}
              />
            </div> */}
          {/* <textarea
            value={css}
            style={{ height: "90%", width: "100%", margin: 0 }}
            onChange={(e) => setCss(e.target.value)}
          ></textarea> */}
          {/* </div> */}
          <div className="wd">
            <h1 style={{ height: "10%", margin: 0 }}>js</h1>
            <div className="" style={{ height: editorHeight, background: "#1e1e1e", ...(editorHeight === "100vh" ? commonStyles : {}) }}>
              {editorHeight === "100vh" ? (
                <div >
                  <i className="fa-solid fa-compress expandicon " onClick={handleExpandClick} ></i>
                </div>
              ) : (
                <div >
                  <i className="fa-solid fa-expand expandicon " onClick={handleExpandClick} ></i>
                </div>
              )
              }
              <Editor
                language="javascript"
                theme="vs-dark"
                value={script}
                onChange={(e, i) => {
                  setScript(e);
                }}
                options={{
                  codeLens: false,
                  inlineSuggest: true,
                  fontSize: "16px",
                  formatOnType: true,
                  minimap: {
                    enabled: false,
                    showSlider: "always",
                  }
                  // autoClosingBrackets: true,
                  // autoSurround: true,
                }}
              // options={{
              //   inlineSuggest: true,
              //   fontSize: "16px",
              //   formatOnType: true,
              //   autoClosingBrackets: true,
              //   minimap: { scale: 10 },
              // }}
              />
              {/* <CodeEditor
              value={script}
              autoComplete
              language="js"
              onChange={(e) => setScript(e.target.value)}
              style={{
                minHeight: "100%",
                width: "100%",
                margin: 0,
                fontSize: 24,
              }}
            /> */}
            </div>
            {/* <textarea
            value={script}
            style={{ height: "90%", width: "100%", margin: 0 }}
            onChange={(e) => setScript(e.target.value)}
          ></textarea> */}
          </div>
        </div>
        <div className="m-bottom">
          <button
            id="testButton"
            className="btn btn-primary align-self-end"
            style={{ margin: 0, marginRight: 5, marginTop: 5 }}
            onClick={testFn}
          >
            Test
          </button>
          <button
            className="btn btn-primary align-self-end"
            style={{ margin: 0, marginRight: 5, marginTop: 5 }}
            onClick={submitFn}
          >
            Save
          </button>
        </div>
        <canvas
          data-mdb-chart="bar"
          data-mdb-dataset-label="Traffic"
          data-mdb-labels="['Monday', 'Tuesday' , 'Wednesday' , 'Thursday' , 'Friday' , 'Saturday' , 'Sunday ']"
          data-mdb-dataset-data="[2112, 2343, 2545, 3423, 2365, 1985, 987]"
        ></canvas>
      </div>
    );
  } else {
    return (
      <div
        className="d-flex justify-content-center align-items-center w-100"
        style={{ height: "95vh" }}
      >
        <WorkInProgress />
      </div>
    );
  }
};

export default PortalCompo;

//   const handleEditorWillMount = (monaco) => {
//     // Configure JavaScript/TypeScript language support to handle JSX
//     monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
//       jsx: monaco.languages.typescript.JsxEmit.React,
//       reactNamespace: 'React',
//       allowNonTsExtensions: true,
//       allowJs: true, // Allow JavaScript files
//     });

//     // Disable unnecessary diagnostics to avoid false positives with JSX
//     monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
//       noSemanticValidation: true, // Disable semantic validation
//       noSyntaxValidation: true, // Disable syntax validation (optional, if you face issues)
//     });

//     // Optionally, you can define additional typings for React to aid in JSX support
//     const extraLibs = `
//       declare namespace JSX {
//         interface IntrinsicElements {
//           [elemName: string]: any;
//         }
//       }
//     `;
//     monaco.languages.typescript.javascriptDefaults.addExtraLib(extraLibs, 'ts:filename/react-jsx.d.ts');

//  // Register a custom theme to highlight JSX tags and template tags
//  monaco.editor.defineTheme('myCustomTheme', {
//   base: 'vs-dark',
//   inherit: true,
//   rules: [
//     { token: 'delimiter.angle', foreground: '808080' }, // Gray for < and >
//     { token: 'tag', foreground: '00f0FF' }, // Blue for tag names
//     { token: 'attribute.name', foreground: '87CEFA' }, // Light sky blue
//     { token: 'attribute.value', foreground: 'FFD700' }, // Gold
//   ],
//   colors: {
//     'editor.background': '#1E1E1E',
//   },
// });

// // Register a custom tokenizer for JSX
// monaco.languages.setMonarchTokensProvider('javascript', {
//   tokenizer: {
//     root: [
//       [/[{}()\[\]]/, '@brackets'],
//       [/<[\/]?[\w]+/, [{ cases: { '@default': 'tag' } }]], // Highlight tag names
//       [/<[\/]?/, 'delimiter.angle'], // Highlight < and >
//       [/>/, 'delimiter.angle'], // Highlight >
//       [/(\w+)(=)("[^"]*"|'[^']*'|{[^{]*{)/, ['attribute.name', 'delimiter', 'attribute.value']], // Attributes
//       [/"/, { token: 'string.quote', bracket: '@open', next: '@string' }],
//     ],
//     string: [
//       [/[^\\"]+/, 'string'],
//       [/"/, { token: 'string.quote', bracket: '@close', next: '@pop' }],
//     ],
//   },
// });

//     // Define a list of HTML tags for autocompletion
//     const htmlTags = [
//       'div', 'span', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'a', 'ul', 'li',
//       'table', 'tr', 'td', 'thead', 'tbody', 'img', 'form', 'input', 'button',
//       // Add more HTML tags as needed
//     ];

//     // Register HTML tag autocompletion
//     monaco.languages.registerCompletionItemProvider('javascript', {
//       provideCompletionItems: (model, position) => {
//         const textUntilPosition = model.getValueInRange({
//           startLineNumber: position.lineNumber,
//           startColumn: position.column - 1,
//           endLineNumber: position.lineNumber,
//           endColumn: position.column
//         });

//         // Only show suggestions if the user is typing an HTML tag
//         if (textUntilPosition === '<') {
//           return {
//             suggestions: htmlTags.map(tag => ({
//               label: tag,
//               kind: monaco.languages.CompletionItemKind.Keyword,
//               insertText: tag,
//               range: {
//                 startLineNumber: position.lineNumber,
//                 startColumn: position.column,
//                 endLineNumber: position.lineNumber,
//                 endColumn: position.column
//               }
//             }))
//           };
//         }

//         return { suggestions: [] };
//       }
//     });

//   };
