import React, { useState, useContext, useEffect, useRef } from "react";
import { AutoPortalContext } from "../Context";
import axios from "axios";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { useCallback } from "react";
import { stringToComponent } from "./StringTofunction";

const LandingCompo = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [loadBody, setLoadBody] = useState([]);
  const [loading, setLoading] = useState(true);
  const [render, setRender] = useState(false);
  const [script, setScript] = useState("");
  const [html, setHtml] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [fullbody, setFullBody] = useState([]);
  const app = searchParams.get("app");
  var page = searchParams.get("page");
  const oneRender = useRef(false);
  const loadFnName = useRef("");
  const { token, loca, getVerify } = useContext(AutoPortalContext);
  const navigation = useNavigate();

  const getInitialData = () => {
    if (page !== "" && page !== null && app !== "" && app !== null) {
      console.log("page" + page, app);
      axios
        .get(loca + `/lom/get/index/` + app + `/` + page)
        .then(
          (resp) => {
            let res = resp.data;
            console.log("", res);

            if ("Error" in res) {
              console.log(res.Error);
              return;
            }

            let css = res.landing.page[0].css;
            let html = res.landing.page[0].html;

            let scrip = res.landing.page[0].script;
            if (res.access_type === "private") {
              
              console.log(token);
              if (!token) {
                html = "<h1>Error </h1>";
                scrip = "";
              }
              // let res =  getVerify()
              // console.log(res);
            }
            setScript(scrip);
            setHtml(html);
            setLoading(false);
          },
          (error) => {
            navigation("/error");
            console.log(error);
          }
        );
    } else if (app !== "" && app !== null) {
      // console.log("app"+app);
      axios
        .get(loca + `/lom/get/index/` + app, {
          headers: {
            // "Content-Type": "application/json",
            authorization: "Bearer " + token,
          },
        })
        .then(
          (resp) => {
            let res = resp.data;
            console.log(res);

            if ("Error" in res) {
              console.log(res.Error);
              return;
            }
            let html = res.landing.page[0].html;

            let scrip = res.landing.page[0].script;
            setScript(scrip);
            setHtml(html);
            setLoading(false);
          },
          (error) => {
            navigation("/error");
            console.log(error);
          }
        );
    }
  };

  useEffect(() => {
    getInitialData();
    console.log("checks");
  }, [searchParams, refresh]);

  if (!loading) {
    const Template = stringToComponent("GenY", script, html, token, loca);
    return (
      <div style={{ flexGrow: 1 }} className="iframe">
        {html.length > 0 && <Template />}
      </div>
    );
  } else {
    return <></>;
  }
};

export default LandingCompo;

// useEffect( () => {
//   if (oneRender.current) {
//     pageLoad();
//     oneRender.current=false;
//   } else {
//     oneRender.current=true;
//   }
// }, [loadBody]);

// const mayaEffect = useCallback((gg) => {
//   console.log(gg);
// }, []);

// const reload = () => {
//   setRefresh(!refresh);
// };

// const onload = (functionName) => {
//   loadFnName.current = functionName;
// };

// const pageLoad= async()=>{
//   mayaEffect("zakirrrrrrrrrrrrrrr")
//   console.log(loadBody);
//   let f = new Function(["axios,navigation,token,loca"], loadBody);
//   await f(axios, navigation, token,loca);
//   // console.log("fgff");
//   // setLoad(0);
//   let html3 = document.getElementById("sd").innerHTML;
//   eventType(html3, fullbody, script);
// }

// const eventType =  (tag, body, full_body) => {
//   // console.log("tag : " + tag);
//   let elmnt = document.getElementById("sd");

// console.log("func_type "+JSON.stringify(full_body))
// if (full_body.includes("loadInitial")) {
// //   let sp = full_body.split(/loadInitial+\(+/);
// //  let last_sp =sp[sp.length-1].split(")");
// //  let spl=last_sp[0]+"()";
// //  if (spl !== "") {
// //   console.log("sp ")
//   for (let i = 0; i < body.length; i++) {
//     // console.log("body "+JSON.stringify(sp[sp.length - 1]))
//      let spl=loadFnName.current+"()";
//     if (spl===body[i].name) {
//       console.log("what do you want to");
//         console.log("checking click" + body[i].body);
//           run(full_body+";"+body[i].name)
//     }
//   // }
// }
// }

//   if (tag.includes("()")) {
//     // if (tag.includes("onclick")) {
//     let arrTag = tag.split(">");
//     // console.log("split : " + JSON.stringify(arrTag));
//     let fnName = "";
//     let arrFunc = [];
//     for (let j = 0; j < arrTag.length; j++) {
//       let firstTag = arrTag[j];

//       let arr = firstTag.split("=");
//       for (let i = 0; i < arr.length; i++) {
//         if (arr[i].includes("()")) {
//           let k = arr[i];
//           fnName = k.slice(k.indexOf('"'), k.lastIndexOf('"') + 1);
//           let fnName2 = k.slice(k.indexOf('"') + 1, k.lastIndexOf('"'));
//           let repName = fnName2;
//           let typ = "";
//           // console.log(" onclick=" + fnName2);
//           let withoutFn;
//           if (arr[i - 1].includes("onclick")) {
//             withoutFn = tag.replace(
//               " onclick=" + fnName,
//               " name='" + repName + "'"
//             );
//             typ = "onclick";
//           }
//           else if (arr[i - 1].includes("onload")) {
//             withoutFn = tag.replace(
//               " onload=" + fnName,
//               " name='" + repName + "'"
//             );
//             typ = "onload";
//           } else {
//             typ = "non";
//           }
//           // console.log("withoutfn" + withoutFn);
//           if (typ !== "non") {
//             elmnt.innerHTML = withoutFn;
//             tag = withoutFn;
//             arrFunc.push({ name: repName, type: typ });

//           }
//           // elmnt.removeEventListener("onclick")
//         }
//       }
//     }
//     // document.getElementsByClassName("aa").item(0).before(elmnt);
//     // console.log("arrfunc : " + JSON.stringify(arrFunc));
//     for (let i = 0; i < arrFunc.length; i++) {
//       let click_elmnt = document.getElementsByName(arrFunc[i].name).item(0);
//       for (let j = 0; j < body.length; j++) {
//         let func_name = arrFunc[i].name.slice(
//           0,
//           arrFunc[i].name.indexOf(")") + 1
//         );
//         // console.log("checking outside " + func_name, JSON.stringify(body));
//         if (body[j].name === func_name) {
//           if (arrFunc[i].type === "onclick") {
//             console.log("checking click" + body[j].body);
//             click_elmnt.addEventListener("click", () => {
//               run(full_body+";"+body[j].name)
//             });
//           }
//          else {
//             // console.log("checking click");
//           }
//         }
//       }
//     }
//   }
//   setRender(!render)

// };

// const run =async(func)=>{
//   let f = new Function(
//     ["React,axios,loca,navigation,createSearchParams,token,reload,loadInitial"],
//     func
//   );
//   await f(React,axios,loca, navigation, createSearchParams, token,reload,onload);
//       let html3 = document.getElementById("sd").innerHTML;
//       console.log(html3)
//       if (/on+[a-zA-z]{4,} *=+ *[a-zA-z0-9]+\(\)+/.test(html3)) {
//         console.log("message")
//         eventType(html3,fullbody,script,"click");
//       }

// }
