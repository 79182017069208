// import axios from "axios";
import React, {
  // Component,
  useEffect,
  useState,
  useContext,
  useRef,
} from "react";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
// import { ListGroup, ListGroupItem } from "reactstrap";
import { AutoPortalContext } from "../Context";
import "../css/LeftCompo.css";
import { auto } from "@popperjs/core";

const LeftNavigation_Component = () => {
  let height = "100%";
  const {
    setFlag,
    flag,
    leftNavData,
    user,
    headHeight,
    callType,
    setCallType,
    setFormHeight,
  } = useContext(AutoPortalContext);

  const [records] = useState(leftNavData);
  const [searchrcd, setSearchrcd] = useState(JSON.parse(JSON.stringify(leftNavData)));
  const [searchValue, setSearchValue] = useState("");
  const [showLeftPane, setshowLeftPane] = useState(true);
  const [contHeight, setContHeight] = useState(0);
  const [searchParam, setSearchParam] = useSearchParams();

  // const [leftNav, setLeftNav] = useState(true);

  const navigation = useNavigate();

  const path = useLocation().pathname;

  const onRender = useRef(false);
  let searchBar = useRef();
  // const [setNavOpen] = useState(true);

  // const [flag, setFlag] = useState(false);
  const [cls_all, setCls_all] = useState(true);
  const [cls, setCls] = useState(true);
  const [srchHeight, setSrchHeight] = useState(true);



  const searchModule = (nam) => {
    setSearchValue(nam);
    if (nam.length > 2) {
      var searchh = JSON.parse(JSON.stringify(records));
      var abc = [];
      var auto = [];
      var market = [];
      for (let n = 0; n < searchh.length; n++) {
        var search = searchh[n].appMenus;
        for (var i = 0; i < search.length; i++) {
          var app_m = search[i].application_menu.toLowerCase();
          if (app_m.includes(nam.toLowerCase())) {
            if (n === 0) {
              auto.push({
                application_menu: search[i].application_menu,
                section: search[i].section,
                ref: true,
                ref_all: true,
              });
            } else {
              market.push({
                application_menu: search[i].application_menu,
                section: search[i].section,
                ref: true,
                ref_all: true,
              });
            }
            // abc.push({
            //   application_menu: search[i].application_menu,
            //   section: search[i].section,
            //   ref: true,
            //   ref_all: true,
            // });
          } else {
            var cnt = 0;
            var mod = [];

            for (var j = 0; j < search[i].section.length; j++) {
              var sec_m = search[i].section[j].sec_label.toLowerCase();
              if (sec_m.includes(nam.toLowerCase())) {
                mod.push(search[i].section[j]);
                cnt++;
              } else {
                for (var s = 0; s < search[i].section[j].module.length; s++) {
                  // console.log(
                  //   "modules : " + JSON.stringify(search[i].section[j].module[s])
                  // );
                  var mod_m =
                    search[i].section[j].module[s].form_label.toLowerCase();
                  // console.log(mod_m);
                  if (mod_m.includes(nam.toLowerCase())) {
                    console.log(search[i].section[j]);
                    var sec = {};
                    sec = search[i].section[j].module[s];
                    search[i].section[j].module = [];
                    search[i].section[j].module.push(sec);
                    mod.push(search[i].section[j]);
                    cnt++;
                  }
                }
              }
            }
            if (cnt > 0) {
              if (n === 0) {
                auto.push({
                  application_menu: search[i].application_menu,
                  section: mod,
                  ref: true,
                  ref_all: true,
                });
              } else {
                market.push({
                  application_menu: search[i].application_menu,
                  section: mod,
                  ref: true,
                  ref_all: true,
                });
              }
              // abc.push({
              //   application_menu: search[i].application_menu,
              //   section: mod,
              //   ref: true,
              //   ref_all: true,
              // });
            }
          }
        }
      }
      console.log(searchh[0]);
      if (auto.length > 0) {
        abc.push({
          parentApplication: "Autoportal",
          application: searchh[0].application,
          app_ref: true,
          appMenus: auto,
        });
      }
      if (market.length > 0) {
        abc.push({
          parentApplication: "Marketplace",
          application: searchh[1].application,
          app_ref: true,
          appMenus: market,
        });
      }
      console.log(abc);
      setSearchrcd([...abc]);
    } else if (nam === "") {
      var srch = JSON.parse(JSON.stringify(records));
      setSearchrcd([...srch]);
    }
  };

  const setOpen = (o_ii, i, rf) => {
    const rcd = searchrcd;
    rcd[o_ii].appMenus[i].ref = !rf;
    setSearchrcd([...rcd]);
  };

  const handleLeft = () => {
    var showlfp = showLeftPane;
    showlfp = !showlfp;
    setshowLeftPane(showlfp);
    setFlag(false);
  };

  const callRecord = (form_table, form_label, rid, type, index, parentApp) => {
    console.log("type", type, parentApp);
    console.log("formTable : " + form_table);
    console.log("user : " + user);
    if (window.screen.width < 600) {
      handleLeft();
    }
    let param = searchParam.get("rty");
    let paramTab = searchParam.get("tableName");
    console.log(param, paramTab, form_table, type);
    if (param !== type || paramTab !== form_table) {
      console.log("user : " + user);

      navigation({
        pathname: user + "/form",
        search: createSearchParams({
          parentApplication: parentApp,
          rty: type,
          tableName: form_table,
        }).toString(),
      });
    }
    let n = index;
    localStorage.setItem("active", n);
    setActive(n);

    // window.location.reload()
  };

  const callFlowCompo = (form_table, rid, type, index) => {
    if (window.screen.width < 600) {
      handleLeft();
    }
    navigation(user + "/flow", {
      state: { rty: type, tableName: form_table, rid: rid },
    });
    let n = index;
    // localStorage.setItem("active", n);
    setActive(n);

    // window.location.reload()
  };

  const applicationSet = (index) => {
    if (window.screen.width < 600) {
      handleLeft();
    }
    navigation(user + "/application");
    setActive(index);
  };

  const callInitialSetupCompo = (form_table, rid, type, index) => {
    if (window.screen.width < 600) {
      handleLeft();
    }
    navigation(user + "/initialsetup", {
      state: { rty: type, tableName: form_table, rid: rid },
    });
    let n = index;
    // localStorage.setItem("active", n);
    setActive(n);

    // window.location.reload()
  };

  const callchecklist = (index) => {
    if (window.screen.width < 600) {
      handleLeft();
    }
    navigation(user + "/checklicompo");
    setActive(index);
  };

  const callUpdateCompo = (index) => {
    if (window.screen.width < 600) {
      handleLeft();
    }
    navigation(user + "/updatepassword");
    setActive(index);
  };

  const callAppAccessCompo = (index, parentApp) => {
    if (window.screen.width < 600) {
      handleLeft();
    }
    navigation({
      pathname: user + "/appaccess",
      search: createSearchParams({ parentApplication: parentApp }).toString(),
    });
    // navigation(user + "/appaccess");
    setActive(index);
  };
  const callMultipleAppReq = (index) => {
    if (window.screen.width < 600) {
      handleLeft();
    }
    navigation(user + "/multipleAppRequest");
    setActive(index);
  };

  const callClienInfo = (index, filter) => {
    if (window.screen.width < 600) {
      handleLeft();
    }
    navigation(user + "/clientinfo", { state: { filter: filter } });
    setActive(index);
  };

  const callClienInfoRecord = (index, filter) => {
    if (window.screen.width < 600) {
      handleLeft();
    }
    navigation(user + "/clientinforecord", { state: { filter: filter } });
    setActive(index);
  };

  const callPortalCompo = (index) => {
    if (window.screen.width < 600) {
      handleLeft();
    }
    navigation(user + "/portal");
    setActive(index);
  };

  const callGridCompo = (index) => {
    if (window.screen.width < 600) {
      handleLeft();
    }
    navigation(user + "/grid");
    setActive(index);
  };

  const callVariable = (index) => {
    if (window.screen.width < 600) {
      handleLeft();
    }
    navigation(user + "/var");
    setActive(index);
  };

  const callAppSetup = (index, parentApp) => {
    if (window.screen.width < 600) {
      handleLeft();
    }
    navigation({
      pathname: user + "/appsetup",
      search: createSearchParams({ parentApplication: parentApp }).toString(),
    });
    // navigation(user + "/appsetup");
    setActive(index);
  };

  const callTableSetup = (index, parentApp) => {
    if (window.screen.width < 600) {
      handleLeft();
    }
    navigation({
      pathname: user + "/tablesetup",
      search: createSearchParams({ parentApplication: parentApp }).toString(),
    });
    // navigation(user + "/tablesetup");
    setActive(index);
  };
  const callFormView = (tb, index, parentApp) => {
    if (window.screen.width < 600) {
      handleLeft();
    }
    navigation({
      pathname: user + "/viewcompo",
      search: createSearchParams({
        parentApplication: parentApp,
        tableName: tb,
      }).toString(),
    });
    // navigation(user + "");
    setActive(index);
  };

  const callDashBoardSetUp = (index) => {
    if (window.screen.width < 600) {
      handleLeft();
    }
    navigation(user + "/dashboard-select");
    setActive(index);
  };

  const call_uipolicy = (index) => {
    if (window.screen.width < 600) {
      handleLeft();
    }
    // console.log("innnnnnn");
    navigation(user + "/policy");
    setActive(index);
  };

  const call_uipolicy_action = (index) => {
    if (window.screen.width < 600) {
      handleLeft();
    }
    // console.log("innnnnnn");
    navigation(user + "/policy_action");
    setActive(index);
  };

  const callMultiTable = (tabName, index) => {
    if (window.screen.width < 600) {
      handleLeft();
    }
    // console.log("innnnnnn");
    navigation(user + "/multitable", { state: { tableName: tabName } });
    setActive(index);
  };

  const callOther = () => {
    if (window.screen.width < 600) {
      handleLeft();
    }
    navigation(user + "/report");
  };

  const callMulti = (md) => {
    if (window.screen.width < 600) {
      handleLeft();
    }
    // navigation(user + "/multiinsert", { state: { tableName: md } });
    navigation({
      pathname: user + "/multiinsert",
      search: createSearchParams({
        // parentApplication: parentApp,
        rty: "multiinsert",
        tableName: md,
      }).toString(),
    });
    // showMultiCompo(md);
  };

  const callMultipage = (md) => {
    // showMultiPage(md);
  };

  const callRegister = (md) => {
    // showClientReg(md);
  };

  const callPreference = (index) => {
    if (window.screen.width < 600) {
      handleLeft();
    }
    // showOwnerPrefComp(md);
    navigation(user + "/OwnerPref");
    setActive(index);
  };

  const callProperties = () => {
    // showPropCompo();
  };

  const callTheme = () => {
    // showThemeCompo();
  };

  const callView = (md) => {
    // showViewCompo(md);
  };

  // const callClientRPage = (md) => {
  //   // showC(md);
  // };

  const setActive = (index) => {
    // localStorage.setItem("active", index);
    // let active = document.getElementsByClassName("navpointer");
    // for (let i = 0; i < active.length; i++) {
    //   if (i === index) {
    //     active[i].classList.add("activeResult");
    //   } else {
    //     active[i].classList.remove("activeResult");
    //   }
    // }
  };

  const call_list = (tb, filter, mod_i, mod_obj, parentApp) => {
    console.log("mod_i  sec", mod_i);
    console.log(mod_i, filter, tb);
    console.log(" mod_obj ", mod_obj);
    setCallType({ callType: mod_obj.type, table: tb });
    console.log(" call Type ", callType, parentApp);
    console.log();
    if (window.screen.width < 600) {
      handleLeft();
    }
    localStorage.removeItem("pageClicked");

    let param = searchParam.get("rty");
    let paramTab = searchParam.get("tableName");
    if (path !== "/list" || paramTab !== tb) {
      console.log(" setting for list");
      navigation(
        {
          pathname: user + "/list",
          search: createSearchParams({
            parentApplication: parentApp,
            tableName: tb,
          }).toString(),
        },
        { state: { filter: filter } }
      );
      let n = mod_i;
      // localStorage.setItem("active", n);
      setActive(n);
      // window.location.reload()
    }
  };
  const setSection = (o_ii, o_i, sec_i, val) => {
    var shrcd = searchrcd;
    shrcd[o_ii].appMenus[o_i].section[sec_i].ref = val;
    // this.setState({ searchrcd: searchrcd });
    setSearchrcd([...shrcd]);
  };

  const closeAppMenu = () => {
    var search = searchrcd;
    for (var i = 0; i < searchrcd.length; i++) {
      for (var s = 0; s < searchrcd[i].appMenus.length; s++) {
        search[i].appMenus[s].ref = !cls_all;
      }
    }
    // this.setState({ searchrcd: searchrcd, cls_all: !this.state.cls_all });
    setSearchrcd(search);
    setCls_all(!cls_all);
  };

  const closeMenu = (o_ii) => {
    var search = searchrcd;
    console.log(o_ii);
    for (var s = 0; s < searchrcd[o_ii].appMenus.length; s++) {
      search[o_ii].appMenus[s].ref = !searchrcd[o_ii].app_ref;
    }
    search[o_ii].app_ref = !searchrcd[o_ii].app_ref;

    setSearchrcd(search);
    setCls(!cls);
  };

  const closeSection = (o_ii, o_i) => {
    var sarchrcd = searchrcd;
    for (var j = 0; j < searchrcd[o_ii].appMenus[o_i].section.length; j++) {
      sarchrcd[o_ii].appMenus[o_i].section[j].ref =
        !sarchrcd[o_ii].appMenus[o_i].ref_all;
    }
    sarchrcd[o_ii].appMenus[o_i].ref_all =
      !sarchrcd[o_ii].appMenus[o_i].ref_all;
    // this.setState({
    //   searchrcd: searchrcd,
    // });
    setSearchrcd([...sarchrcd]);
  };

  useEffect(() => {
    let h = window.innerHeight - headHeight - 50;
    setContHeight(h);
    setFormHeight(h);
    if (onRender.current && path !== "/dashboard") {
      let active = localStorage.getItem("active");
      if (active !== null && active !== undefined) {
        setActive(parseInt(active));
      }
    }

    leftNavData.sort((a, b) =>
      parseInt(a.menu_order) > parseInt(b.menu_order)
        ? 1
        : parseInt(a.menu_order) < parseInt(b.menu_order)
          ? -1
          : 0
    );
    setSearchrcd(leftNavData);
  }, [onRender.current, headHeight]);

  useEffect(() => {
    if (path === "/dashboard") {
      let active = document.getElementsByClassName("navpointer");
      for (let i = 0; i < active.length; i++) {
        active[i].classList.remove("activeResult");
      }
    }
  }, [path]);

  // useEffect(() => {
  //   setSearchrcd(JSON.parse(JSON.stringify(leftNavData)));
  // }, [leftNavData]);

  return (
    <div style={{ flexGrow: 1 }} id="mySidebar">
      <div
        className="list-group "
        style={{ height: height, minHeight: height }}
      >
        <div>
          {(flag || window.screen.width < 600) && (
            <div>
              <div id="sidebar" className="search-block ">
                <input
                  className="lfnavserach  form-control mr-sm-2 "
                  type="search"
                  placeholder="Search"
                  aria-label="Search"
                  value={searchValue}
                  onChange={(e) => searchModule(e.target.value)}
                ></input>
                <div className="arr_width">
                  <div className={"left_icon"} onClick={handleLeft}>
                    <i className="fa fa-arrow-left"></i>
                  </div>
                </div>
                <div className="arr_width">
                  <div
                    type="button"
                    className="left_icon"
                    onClick={closeAppMenu}
                  >
                    {cls_all ? "-" : "+"}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div style={{ height: contHeight, overflow: auto }}>
            {/* <div style={{ height: "100vh", overflow: auto }}> */}
            {searchrcd.map((objj, o_ii) => (
              <div key={o_ii}>
                {searchrcd.length > 1 && (
                  <div
                    className="list-group-item"
                    style={{ backgroundColor: "lightsteelblue" }}
                  >
                    {objj.application}
                    <span className="flri">
                      {objj.app_ref === true && (
                        <i
                          className="fa fa-arrow-down vlpointer arrow_sty"

                          onClick={(e) => closeMenu(o_ii)}
                        ></i>
                      )}
                      {objj.app_ref === false && (
                        <i
                          className="fa fa-arrow-right vlpointer arrow_sty"

                          onClick={(e) => closeMenu(o_ii)}
                        ></i>
                      )}
                    </span>
                  </div>
                )}
                {objj.appMenus.map((obj, o_i) => (
                  <div key={o_i}>
                    <div className="list-group-item appm wrap">
                      {obj.application_menu}
                      <span className="flri">
                        {obj.ref_all === true && (
                          <i
                            className="fa fa-arrow-down vlpointer arrow_sty"

                            onClick={(e) => closeSection(o_ii, o_i)}
                          ></i>
                        )}
                        {obj.ref_all === false && (
                          <i
                            className="fa fa-arrow-right vlpointer arrow_sty"

                            onClick={(e) => closeSection(o_ii, o_i)}
                          ></i>
                        )}
                        <button
                          type="button"
                          className={obj.ref ? "buttn" : "buttnp"}
                          onClick={(pr) => setOpen(o_ii, o_i, obj.ref)}
                        >
                          {obj.ref ? "-" : "+"}
                        </button>
                      </span>
                    </div>
                    {obj.ref && (
                      <div>
                        {obj.section.map((obj_sec, sec_i) => (
                          <div key={sec_i}>
                            <div className="sec">
                              {obj_sec.ref === true && (
                                <i
                                  className="fa fa-arrow-down vlpointer cog_pd"

                                  onClick={() =>
                                    setSection(o_ii, o_i, sec_i, false)
                                  }
                                ></i>
                              )}
                              {obj_sec.ref === false && (
                                <i
                                  className="fa fa-arrow-right vlpointer cog_pd"

                                  onClick={() =>
                                    setSection(o_ii, o_i, sec_i, true)
                                  }
                                ></i>
                              )}
                              <span>{obj_sec.sec_label}</span>
                            </div>
                            {obj_sec.ref === true && (
                              <div id={obj.application_menu}>
                                {obj_sec.module.map((obj_m, m_i) => (
                                  <div key={m_i}>
                                    {obj_m.type === "new" && (
                                      <div
                                        onClick={() =>
                                          callRecord(
                                            obj_m.form_table,
                                            obj_m.form_label,
                                            "0",
                                            obj_m.type,
                                            m_i,
                                            objj.parentApplication
                                          )
                                        }
                                        className="list-group-item list-group-item-action navpointer wrap"
                                      >
                                        <p className="val ">
                                          {obj_m.form_label}
                                        </p>
                                        <span className="mod-set mod-type mod-col3">
                                          IN
                                        </span>
                                      </div>
                                    )}

                                    {obj_m.type === "view" && (
                                      <div
                                        onClick={() =>
                                          callFormView(
                                            obj_m.form_table,
                                            "0",
                                            obj_m.type
                                          )
                                        }
                                        className="list-group-item list-group-item-action navpointer wrap"
                                      >
                                        {obj_m.form_module}
                                        <span className="mod-set mod-type mod-col3">
                                          IN
                                        </span>
                                      </div>
                                    )}

                                    {obj_m.type === "dashBoardSetUp" && (
                                      <div
                                        onClick={() => {
                                          callDashBoardSetUp(
                                            obj_m.form_table,
                                            "0",
                                            obj_m.type
                                          );
                                        }}
                                        className="list-group-item list-group-item-action navpointer wrap"
                                      >
                                        {obj_m.form_label}
                                        <span className="mod-set mod-type mod-col3">
                                          DS
                                        </span>
                                      </div>
                                    )}

                                    {obj_m.type === "invoice" && (
                                      <div
                                        onClick={() =>
                                          callFormView(
                                            obj_m.form_table,
                                            "0",
                                            obj_m.type
                                          )
                                        }
                                        className="list-group-item list-group-item-action navpointer wrap"
                                      >
                                        {obj_m.form_module}
                                        <span className="mod-set mod-type mod-col3">
                                          IN
                                        </span>
                                      </div>
                                    )}

                                    {obj_m.type === "list" && (
                                      <div
                                        onClick={() =>
                                          call_list(
                                            obj_m.form_table,
                                            obj_m.filter,
                                            m_i,
                                            obj_m,
                                            objj.parentApplication
                                          )
                                        }
                                        className="list-group-item list-group-item-action navpointer wrap"
                                      // style={{ justifyContent: "space-between" }}
                                      >
                                        {obj_m.form_label}
                                        <span className="mod-set mod-type mod-col1">
                                          LI
                                        </span>
                                      </div>
                                    )}
                                    {obj_m.type === "viewreport" && (
                                      <div
                                        onClick={() =>
                                          call_list(
                                            obj_m.form_table,
                                            obj_m.filter,
                                            m_i,
                                            obj_m
                                          )
                                        }
                                        className="list-group-item list-group-item-action navpointer wrap"
                                      // style={{ justifyContent: "space-between" }}
                                      >
                                        {obj_m.form_label}
                                        <span className="mod-set mod-type mod-col1">
                                          VR
                                        </span>
                                      </div>
                                    )}
                                    {obj_m.type === "flownew" && (
                                      <div
                                        onClick={() => {
                                          callFlowCompo(
                                            obj_m.form_table,
                                            "0",
                                            obj_m.type,
                                            m_i
                                          );
                                        }}
                                        className="list-group-item list-group-item-action navpointer wrap"
                                      // style={{ justifyContent: "space-between" }}
                                      >
                                        {obj_m.form_label}
                                        <span className="mod-set mod-type mod-col12 ">
                                          FN
                                        </span>
                                      </div>
                                    )}

                                    {obj_m.type === "setup" && (
                                      <div
                                        onClick={() => {
                                          callInitialSetupCompo(
                                            obj_m.form_module,
                                            "0",
                                            obj_m.type,
                                            m_i
                                          );
                                        }}
                                        className="list-group-item list-group-item-action navpointer wrap"
                                      // style={{ justifyContent: "space-between" }}
                                      >
                                        {obj_m.form_label}
                                        <span className="mod-set mod-type mod-col12 ">
                                          IS
                                        </span>
                                      </div>
                                    )}
                                    {obj_m.type === "flowlist" && (
                                      <div
                                        onClick={() =>
                                          call_list(
                                            obj_m.form_table,
                                            obj_m.filter,
                                            m_i,
                                            obj_m
                                          )
                                        }
                                        className="list-group-item list-group-item-action navpointer wrap"
                                      // style={{ justifyContent: "space-between" }}
                                      >
                                        {obj_m.form_label}
                                        <span className="mod-set mod-type mod-col13">
                                          FL
                                        </span>
                                      </div>
                                    )}

                                    {obj_m.type === "record" && (
                                      <div
                                        onClick={() =>
                                          callRecord(
                                            obj_m.form_table,
                                            obj_m.recordid,
                                            obj_m.type
                                          )
                                        }
                                        className="list-group-item list-group-item-action navpointer wrap"
                                      >
                                        {obj_m.form_label}
                                        <span className="mod-set mod-type mod-col2">
                                          RC
                                        </span>
                                      </div>
                                    )}
                                    {obj_m.type === "other" && (
                                      <div
                                        onClick={() => {
                                          callOther();
                                          setActive(m_i);
                                        }}
                                        className="list-group-item list-group-item-action navpointer wrap"
                                      >
                                        {obj_m.form_label}
                                        <span className="mod-set mod-type mod-col4">
                                          OT
                                        </span>
                                      </div>
                                    )}

                                    {obj_m.type === "multi insert" && (
                                      <div
                                        onClick={() =>
                                          callMulti(obj_m.form_table)
                                        }
                                        className="list-group-item list-group-item-action navpointer wrap"
                                      >
                                        {obj_m.form_label}
                                        <span className="mod-set mod-type mod-col5">
                                          MI
                                        </span>
                                      </div>
                                    )}
                                    {obj_m.type === "multipage" && (
                                      <div
                                        onClick={() =>
                                          callMultipage(obj_m.form_table)
                                        }
                                        className="list-group-item list-group-item-action navpointer wrap"
                                      >
                                        {obj_m.form_label}
                                        <span className="mod-set mod-type mod-col6">
                                          MP
                                        </span>
                                      </div>
                                    )}
                                    {obj_m.type === "multitable" && (
                                      <div
                                        onClick={() =>
                                          callMultiTable(obj_m.form_table, m_i)
                                        }
                                        className="list-group-item list-group-item-action navpointer wrap"
                                      >
                                        {obj_m.form_label}
                                        <span className="mod-set mod-type mod-col6">
                                          MT
                                        </span>
                                      </div>
                                    )}
                                    {obj_m.type === "register" && (
                                      <div
                                        onClick={() =>
                                          callRegister(obj_m.form_table)
                                        }
                                        className="list-group-item list-group-item-action navpointer wrap"
                                      >
                                        {obj_m.form_label}
                                        <span className="mod-set mod-type mod-col7">
                                          RG
                                        </span>
                                      </div>
                                    )}

                                    {obj_m.type === "set" && (
                                      <div
                                        onClick={() => applicationSet(m_i)}
                                        className="list-group-item list-group-item-action navpointer wrap"
                                      >
                                        {obj_m.form_label}
                                        <span className="mod-set mod-type mod-col5">
                                          AS
                                        </span>
                                      </div>
                                    )}

                                    {obj_m.type === "uipolicy" && (
                                      <div
                                        onClick={() => call_uipolicy(m_i)}
                                        className="list-group-item list-group-item-action navpointer wrap"
                                      >
                                        {obj_m.form_label}
                                        <span className="mod-set mod-type mod-col15">
                                          UIP
                                        </span>
                                      </div>
                                    )}

                                    {obj_m.type === "uipolicy_action" && (
                                      <div
                                        onClick={() =>
                                          call_uipolicy_action(m_i)
                                        }
                                        className="list-group-item list-group-item-action navpointer wrap"
                                      >
                                        {obj_m.form_label}
                                        <span className="mod-set mod-type mod-col15">
                                          UIPA
                                        </span>
                                      </div>
                                    )}

                                    {obj_m.type === "fview" && (
                                      <div
                                        onClick={() =>
                                          callFormView(
                                            obj_m.form_table,
                                            m_i,
                                            objj.parentApplication
                                          )
                                        }
                                        className="list-group-item list-group-item-action navpointer wrap"
                                      >
                                        {obj_m.form_label}
                                        <span className="mod-set mod-type mod-col6">
                                          FV
                                        </span>
                                      </div>
                                    )}

                                    {obj_m.type === "checklist" && (
                                      <div
                                        onClick={() => callchecklist(m_i)}
                                        className="list-group-item list-group-item-action navpointer wrap"
                                      >
                                        {obj_m.form_label}
                                        <span className="mod-set mod-type mod-col8">
                                          Cl
                                        </span>
                                      </div>
                                    )}

                                    {obj_m.type === "up" && (
                                      <div
                                        onClick={() => callUpdateCompo(m_i)}
                                        className="list-group-item list-group-item-action navpointer wrap"
                                      >
                                        {obj_m.form_label}
                                        <span className="mod-set mod-type mod-col7">
                                          Up
                                        </span>
                                      </div>
                                    )}

                                    {obj_m.type === "appAccess" && (
                                      <div
                                        onClick={() =>
                                          callAppAccessCompo(
                                            m_i,
                                            objj.parentApplication
                                          )
                                        }
                                        className="list-group-item list-group-item-action navpointer wrap"
                                      >
                                        {obj_m.form_label}
                                        <span className="mod-set mod-type mod-col8">
                                          AA
                                        </span>
                                      </div>
                                    )}
                                    {obj_m.type === "mulAppreq" && (
                                      <div
                                        onClick={() => callMultipleAppReq(m_i)}
                                        className="list-group-item list-group-item-action navpointer wrap"
                                      >
                                        {obj_m.form_label}
                                        <span className="mod-set mod-type mod-col8">
                                          AA
                                        </span>
                                      </div>
                                    )}

                                    {obj_m.type === "preference" && (
                                      <div
                                        onClick={() => {
                                          callPreference(obj_m.form_table);
                                          setActive(m_i + 4);
                                        }}
                                        className="list-group-item list-group-item-action navpointer wrap"
                                      >
                                        {obj_m.form_label}
                                        <span className="mod-set mod-type mod-col8">
                                          PF
                                        </span>
                                      </div>
                                    )}
                                    {obj_m.type === "properties" && (
                                      <div
                                        onClick={callProperties}
                                        className="list-group-item list-group-item-action navpointer wrap"
                                      >
                                        {obj_m.form_label}
                                        <span className="mod-set mod-type mod-col9">
                                          PP
                                        </span>
                                      </div>
                                    )}
                                    {obj_m.type === "theme" && (
                                      <div
                                        onClick={callTheme}
                                        className="list-group-item list-group-item-action navpointer wrap"
                                      >
                                        {obj_m.form_label}
                                        <span className="mod-set mod-type mod-col10">
                                          TE
                                        </span>
                                      </div>
                                    )}
                                    {obj_m.type === "view" && (
                                      <div
                                        onClick={() =>
                                          callView(obj_m.form_table)
                                        }
                                        className="list-group-item list-group-item-action navpointer wrap"
                                      >
                                        {obj_m.form_label}
                                        <span className="mod-set mod-type mod-col11">
                                          VE
                                        </span>
                                      </div>
                                    )}
                                    {obj_m.type === "clienInfo" && (
                                      <div
                                        onClick={() => {
                                          console.log(obj_m);
                                          callClienInfo(m_i, obj_m.filter);
                                        }}
                                        className="list-group-item list-group-item-action navpointer wrap"
                                      >
                                        {obj_m.form_label}
                                        <span className="mod-set mod-type mod-col11">
                                          CI
                                        </span>
                                      </div>
                                    )}
                                    {obj_m.type === "clienInfoRecord" && (
                                      <div
                                        onClick={() => {
                                          console.log(obj_m);
                                          callClienInfoRecord(
                                            m_i,
                                            obj_m.filter
                                          );
                                        }}
                                        className="list-group-item list-group-item-action navpointer wrap"
                                      >
                                        {obj_m.form_label}
                                        <span className="mod-set mod-type mod-col11">
                                          CR
                                        </span>
                                      </div>
                                    )}
                                    {obj_m.type === "portal" && (
                                      <div
                                        onClick={() => callPortalCompo(m_i)}
                                        className="list-group-item list-group-item-action navpointer wrap"
                                      >
                                        {obj_m.form_label}
                                        <span className="mod-set mod-type mod-col1">
                                          PP
                                        </span>
                                      </div>
                                    )}
                                    {obj_m.type === "gd" && (
                                      <div
                                        onClick={() => callGridCompo(m_i)}
                                        className="list-group-item list-group-item-action navpointer wrap"
                                      >
                                        {obj_m.form_label}
                                        <span className="mod-set mod-type mod-col1">
                                          gd
                                        </span>
                                      </div>
                                    )}
                                    {obj_m.type === "var" && (
                                      <div
                                        onClick={() => callVariable(m_i)}
                                        className="list-group-item list-group-item-action navpointer wrap"
                                      >
                                        {obj_m.form_label}
                                        <span className="mod-set mod-type mod-col1">
                                          vr
                                        </span>
                                      </div>
                                    )}
                                    {obj_m.type === "appsetup" && (
                                      <div
                                        onClick={() =>
                                          callAppSetup(
                                            m_i,
                                            objj.parentApplication
                                          )
                                        }
                                        className="list-group-item list-group-item-action navpointer wrap"
                                      >
                                        {obj_m.form_label}
                                        <span className="mod-set mod-type mod-col12">
                                          AS
                                        </span>
                                        {console.log(objj)}
                                      </div>
                                    )}
                                    {obj_m.type === "tablesetup" && (
                                      <div
                                        onClick={() =>
                                          callTableSetup(
                                            m_i,
                                            objj.parentApplication
                                          )
                                        }
                                        className="list-group-item list-group-item-action navpointer wrap"
                                      >
                                        {obj_m.form_label}
                                        <span className="mod-set mod-type mod-col12">
                                          FS
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))}
            {/* </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LeftNavigation_Component;
{
  /* <div
                className="col-md-2"
                style={{
                  padding: "0px",
                  display: "flex",
                  alignItems:"center"
                }}
              >
                {!flag && (
                  // <div style={{    marginLeft:" 20.3em", marginTop: "0.1em"}}
                  //   className={"left_icon left_icon_zero"}
                  //   onClick={left_nav_close}
                  // >
                    <i className="fa fa-arrow-left" style={{backgroundColor:"darkblue",color:"white",paddingTop:10,paddingBottom:10,paddingLeft:8,paddingRight:8,borderRadius:5,cursor:"pointer"}} onClick={left_nav_close} ></i>
                  // </div>
                )}
                {/* <div
                className={"left_icon left_icon_zero"}
                
              >      
                  <i className="fa fa-arrow-right"></i>
                
              </div> */
}
