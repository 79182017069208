import axios from "axios";
import React, {
  Component,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import { useLocation ,useNavigate} from "react-router-dom";
import { AutoPortalContext } from "../Context";
import { Row, Col } from "react-bootstrap";
import "../css/FLowCompo.css";

const FlowComponent = () => {

  const navigation = useNavigate();
  const { token, loca,firstrender } = useContext(AutoPortalContext);

  const { rty, tableName, rid } = useLocation().state;

  // useState hooks

  const onerender = useRef(false);
  const [record, setRecord] = useState([]);
  const [record2, setRecord2] = useState([]);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [col_mn, setCol_mn] = useState([]);
  const [col_mn2, setCol_mn2] = useState([]);
  const [list, setlist] = useState([]);
  const [showlist, setShowList] = useState(false);
  const [showbtn, setShowbtn] = useState(false);
  const [showupdownbttn, setShowUpDownbtn] = useState(false);
  const [int_fields, setInt_Fields] = useState([]);
  const [email_fields, setEmail_Fields] = useState([]);
  const [date_fields, setDate_Fields] = useState([]);
  const [boln_fields, setBoln_Fields] = useState([]);
  const [str_fields, setStr_Fields] = useState([]);
  const [ref_fields, setRef_Fields] = useState([]);
  const [booleanfld, setBooleanFld] = useState([]);

  const [tm_list, setTm_List] = useState([]);
  const [button, setButton] = useState([]);
  const [mainRecord, setMainRecord] = useState({});
  const [mainRecord2, setMainRecord2] = useState({});
  const [sortColumn, setSortColumn] = useState({ name: "", sort: false });
  const [prefCall, setPrefCall] = useState([]);
  const [filter_unset, setFilter_Unset] = useState(false);
  const [filtarray, setFiltArray] = useState([]);
  // const [flowId, setFlowId] = useState();
  // const [flowName, setFlowName] = useState();

  const [filString, setfilString] = useState("");
  const [filter, setFilter] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [filt, setFilt] = useState({
    co: "",
    cl: "",
    mc: "",
    an: "",
    ct: "",
    af: "",
    rf:{id:"",value:""}
  });
  const [page_error, setPage_Error] = useState(false);
  const [error, setError] = useState("");
  const [pageMessage, setPageMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [timeline, setTimeline] = useState("");
  const [ClearButtonLoading, setClearButtonLoading] = useState(false);
  const [RunButtonLoading, setRunButtonLoading] = useState(false);
  const [pageClicked, setPageClicked] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageRecords, setPageRecords] = useState("");
  const [recordCount, setRecordCount] = useState(0);
  const [listName, setListName] = useState(useLocation().state.tableName);
  const [showPref, setShowPref] = useState(false);

  const [checkForInsert, setCheckedForInsert] = useState(false);
  const [checkForUpdate, setCheckedForUpdate] = useState(false);
  const [checkForDelete, setCheckedForDelte] = useState(false);
  const [tableList, setTableList] = useState([]);
  const [tab, setTab] = useState(false);
  const [actionList, setActionList] = useState(["insert", "fetch"]);
  const [insertArray, setInsertArray] = useState([
    { name: "None", value: "", type: "" },
  ]);
  const [flowAction, setFlowAction] = useState([
    {
      show: true,
      showTabDD:false,
      showCol2:false,
      showInsetCondition:false,
      action: "None",
      table: "None",
      insertArray: [{ name: "None", value: "", type: "" }],
      addButton: false,
    },
  ]);

  const [showTabDD, setShowTabDD] = useState(false);
  const [showInsetCondition, setShowInsetCondition] = useState(false);
  const [showCol1, setShowCol1] = useState(false);
  const [showCol2, setShowCol2] = useState(false);
  const [showFLowAction, setShowFLowAction] = useState(false);
  const [showAddBtn, setShowAddBtn] = useState(false);

  const [selection, setSelection] = useState("");
  const [uiScript, setUiScript] = useState([]);
  const [ap_form, setap_form] = useState();
  const [fn_type, seFn_type] = useState("");
  const [showModelList, setshowModelList] = useState(false);
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);
  const [con, setCon] = useState(1);

  const [columnId, setColumnId] = useState();
  const [objName, setObjName] = useState();
  const [objIndex, setObjIndex] = useState();
  const [objType, setObjType] = useState();
  const [showSuggestion, setShowSuggestion] = useState(false);
  const [activeSuggestion, setActiveSuggestion] = useState("");
  const [searchrcd, setSearchrcd] = useState([
    { name: "ap_form", show: false },
    { name: "getFieldValue()", show: false },
    { name: "setFieldValue()", show: false },
  ]);
  const [recordSuggestion, setRecordSuggestion] = useState([
    { name: "ap_form", show: false },
    { name: "getFieldValue()", show: false },
    { name: "setFieldValue()", show: false },
  ]);
  // useRef Hook
  let count = useRef(1);
  let listRef = useRef([]);
  let oneRender = useRef(true);
  let flowId = useRef(0);
  let flowName = useRef("");
  let flowOrder = useRef(1);

  // initial calling function

  const getFlowActionDetails = (id, name) => {
    axios
      .get(loca+`/lom/get/blankrecord/flow_action`, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          const blkrecord = resp.data;
          let frecord = blkrecord.formRecord[2].record;
          for (let i = 0; i < frecord.length; i++) {
            if (frecord[i].name === "flow_id") {
              frecord[i].value.id = flowId.current;
              frecord[i].value.name = flowName.current;
              break;
            }
          }     
          console.log("frecord : " + JSON.stringify(frecord));
          setMainRecord2(blkrecord);
          setRecord2([...frecord]);
          setShowFLowAction(true);
        },
        (error) => { navigation("/error")
          console.log(error);
        }
      );
  };

  const getFormDetails = () => {
    console.log("rty : " + rty);
    // if (rty === "new") {
      console.log("checking  ...");
      axios
        .get(loca+`/lom/get/blankrecord/${tableName}`, {
          headers: {
            authorization: "Bearer " + token,
          },
        })
        .then(
          (resp) => {
            const blkrecord = resp.data;
            // console.log(JSON.stringify(blkrecord.formRecord[2].record));

            // setState({
            // 	loading: false,
            // 	record: blkrecord.formRecord[2].record,
            // 	button: blkrecord.formRecord[3].button,
            // });
            // const uiScript=JSON.parse(blkrecord.formRecord[3].uiscript[0])
            // console.log("script : "+JSON.stringify(uiScript));
            // var fnString="const fn=()=>console.log('hey javaScript!!!!!!!!!')"

            // let script = blkrecord.formRecord[4].uiscript;

            // // onload
            // for (let i = 0; i < script.length; i++) {
            //   let field = script[i].field.name;
            //   let func = script[i].script;
            //   let type = script[i].type;
            //   if (type === "onload" && count.current === 1) {
            //     count.current = 0;
            //     onLoad(func);
            //   }
            // }
            // setUiScript([...script]);

            setMainRecord(blkrecord);
            setRecord(blkrecord.formRecord[2].record);
            setLoading(false);
            console.log(
              "recorddddddddddddddddd : " +
                JSON.stringify(blkrecord.formRecord[2].record)
            );
            // setap_form(new ApForm(blkrecord.formRecord[2].record, setRecord));
          },
          (error) => { navigation("/error")
            console.log(error);
          }
        );
    // } else if (rty === "record") {
    //   // console.log("check 1...");
    //   axios
    //     .get(loca+`/lom/get/singlerecord/${tableName}/${rid}`, {
    //       headers: {
    //         // "Content-Type": "application/json",
    //         authorization: "Bearer " + token,
    //       },
    //     })
    //     .then((resp) => {
    //       const record = resp.data;
    //       var mmm = record.formRecord[2].record;
    //       for (var i = 0; i < mmm.length; i++) {
    //         if (mmm[i].value !== "") {
    //           mmm[i].verified = "verified";
    //         } else {
    //           mmm[i].verified = "initial";
    //         }
    //         //   if (mmm[i].type === "datetime") {
    //         //     console.log("iiiiiiiiiiiii : " + i);
    //         //     // showcal.push({ value: false });
    //         //     showCalender.push({ index: i, value: false });
    //         //   }
    //       }

    //       // console.log("check :" + JSON.stringify(showCalender));
    //       // console.log(
    //       //   "check :" +
    //       //     JSON.stringify(showCalender.find((sh) => sh.index === 5))
    //       // );

    //       setMainRecord(record);
    //       setRecord(mmm);
    //       // setButton(record.formRecord[3].button);
    //       setLoading(false);
    //     });
    // }
  };
  const initiallist = () => {
    if (oneRender.current) {
      // console.log("kkkkkkk" + firstrender.current);

      str_fields.push("");
      str_fields.push("=");
      str_fields.push("starts with");
      int_fields.push("");
      int_fields.push(">");
      int_fields.push("=");
      email_fields.push("");
      email_fields.push("=");
      email_fields.push("starts with");
      boln_fields.push("");
      boln_fields.push("=");
      date_fields.push("");
      date_fields.push("8-11-21");
      date_fields.push("new date");
      booleanfld.push("");
      booleanfld.push("true");
      booleanfld.push("false");
      ref_fields.push("");
      ref_fields.push("=");
      ref_fields.push("starts with");
      tm_list.push({ name: "", label: "None" });
      tm_list.push({ name: "current24", label: "Current 24" });
      tm_list.push({ name: "previous24", label: "Previous 24" });
      tm_list.push({ name: "week", label: "Week" });
      tm_list.push({ name: "last14days", label: "Last 14 Days" });
      tm_list.push({ name: "last30", label: "Last 30" });
      tm_list.push({ name: "last6month", label: "Last 6 Month" });
      tm_list.push({ name: "last1year", label: "Last 1 Year" });

      filtarray.push(filt);
      console.log("Filter Array" + JSON.stringify(filtarray));

      oneRender.current = false;
    }
  };
  const getListRecord = (tableName) => {

    // console.log("tablenAMEEE : " + tableName);

    var filterstate = setFilter;

    var farray = [];
    if (
      setFilter.filter === "" ||
      setFilter.filter === "undefined" ||
      setFilter.filter === undefined ||
      setFilter.filter === "null" ||
      setFilter.filter === null
    ) {
      farray.push(filt);
      filterstate = farray;
    } else {
      farray = filter.filter;
      filterstate = farray;
    }

    var tablere = '{"formRecordList":[';
    tablere += '{"application":{"id":"2","name":"loom"}}';
    tablere += ',{"table":{"id":"","name":"' + tableName + '"}}';
    tablere += ',{"records":[]}';
    tablere +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    tablere += ',{"sort":{"asc":"true","column":"id"}}';
    tablere += ',{"filter":' + JSON.stringify(filterstate) + "}";
    tablere += ',{"timeLine":"' + "" + '"}]}';
    console.log("rccccccccc " + tablere);

    setList(tablere);
  };

  const setList = (tablere) => {
    axios
      .post(
        loca+"/lom/get/multiple/record",
        tablere.toString(),
        {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
          },
        }
      )
      .then(
        (resp) => {
          const listrecord = resp.data;
          console.log(" aaaaaaaaaaa " + JSON.stringify(listrecord));
          var columnarry = [];
          var hd = [];
          var va_ll = [];
          if ("Error" in listrecord) {
            let temp = [];
            setCol_mn([...temp]);
          } else {
            var scnd = listrecord.formRecordList[2];
            if ("Error" in scnd) {
              if (scnd.Error === "No record found.") {
                setlist([]);
              }
            } else {
              var pageClicked1 = parseInt(
                listrecord.formRecordList[3].page.page_clicked
              );
              var pageCount1 = parseInt(
                listrecord.formRecordList[3].page.page_count
              );
              var page_records1 =
                listrecord.formRecordList[3].page.page_records;
              var record_count1 = parseInt(
                listrecord.formRecordList[3].page.record_count
              );

              // var flt = listrecord.formRecordList[5];
              var fltarr = listrecord.formRecordList[5].filter;
              var tmln = listrecord.formRecordList[6].timeLine;
              var filterString = "";
              var leng = fltarr.length;
              for (var f = 0; f < fltarr.length; f++) {
                if (leng === 1 && fltarr[f].cl === "") {
                  console.log();
                  break;
                } else {
                  if (f > 0) {
                    filterString += fltarr[f].af + " ";
                  }
                  filterString += fltarr[f].cl + " ";
                  filterString += fltarr[f].mc + " ";
                  filterString += fltarr[f].an + " ";
                }
              }

              for (
                var i = 0;
                i < listrecord.formRecordList[2].records.length;
                i++
              ) {
                var in_vl = [];
                var record_id;
                for (
                  var j = 0;
                  j < listrecord.formRecordList[2].records[i].record.length;
                  j++
                ) {
                  if (i === 0) {
                    if (j === 0) {
                    } else {
                      hd.push({
                        label:
                          listrecord.formRecordList[2].records[i].record[j]
                            .label,
                        name: listrecord.formRecordList[2].records[i].record[j]
                          .name,
                      });
                    }
                  }
                  if (j === 0) {
                    record_id =
                      listrecord.formRecordList[2].records[i].record[j].value;
                  } else if (j === 1) {
                    if (
                      listrecord.formRecordList[2].records[i].record[j].type ===
                      "reference"
                    ) {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value.value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: true,
                        table: listrecord.formRecordList[1].table.value,
                        r_id: record_id,
                      });
                    } else {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: true,
                        table: listrecord.formRecordList[1].table.value,
                        r_id: record_id,
                      });
                    }
                  } else {
                    if (
                      listrecord.formRecordList[2].records[i].record[j].type ===
                      "reference"
                    ) {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value.value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: false,
                        table: listrecord.formRecordList[1].table.value,
                        r_id: record_id,
                      });
                    } else {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: false,
                        table: listrecord.formRecordList[1].table.value,
                        r_id: record_id,
                      });
                    }
                  }
                }
                va_ll.push({ colr: in_vl });
              }
              columnarry.push({ heading: hd, rcd: va_ll });
              var cla = JSON.parse(
                JSON.stringify(listrecord.formRecordList[9].column)
              );
              cla.unshift({ label: "None", name: "none" });

              console.log("checkinggggggggggggg");
              setlist(columnarry);
              setPageClicked(pageClicked1);
              setPageCount(pageCount1);
              setPageRecords(page_records1);
              setRecordCount(record_count1);
              setCol_mn(cla);
              setPrefCall(listrecord.formRecordList[9].column);
              setFilter(fltarr);
              setTimeline(tmln);
              setClearButtonLoading(false);
              setRunButtonLoading(false);
              setLoading(false);
            }
          }
        },
        (error) => { navigation("/error")
          console.log(error);
          let temp = [];
          setCol_mn([...temp]);
        }
      );
  };

  const getListRecord2 = (tableName) => {
    console.log("tablenAMEEE : " + tableName);
    var filterstate = setFilter;

    var farray = [];
    if (
      setFilter.filter === "" ||
      setFilter.filter === "undefined" ||
      setFilter.filter === undefined ||
      setFilter.filter === "null" ||
      setFilter.filter === null
    ) {
      farray.push(filt);
      filterstate = farray;
    } else {
      farray = filter.filter;
      filterstate = farray;
    }

    var tablere = '{"formRecordList":[';
    tablere += '{"application":{"id":"2","name":"loom"}}';
    tablere += ',{"table":{"id":"","name":"' + tableName + '"}}';
    tablere += ',{"records":[]}';
    tablere +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    tablere += ',{"sort":{"asc":"true","column":"id"}}';
    tablere += ',{"filter":' + JSON.stringify(filterstate) + "}";
    tablere += ',{"timeLine":"' + "" + '"}]}';
    console.log("rccccccccc " + tablere);

    setList2(tablere);
  };

  const setList2 = (tablere) => {
    axios
      .post(
        loca+"/lom/get/multiple/record",
        tablere.toString(),
        {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
          },
        }
      )
      .then(
        (resp) => {
          const listrecord = resp.data;
          console.log(" aaaaaaaaaaa " + JSON.stringify(listrecord));
          var columnarry = [];
          var hd = [];
          var va_ll = [];
          if ("Error" in listrecord) {
            let temp = [];
            setCol_mn2([...temp]);
          } else {
            var scnd = listrecord.formRecordList[2];
            if ("Error" in scnd) {
              if (scnd.Error === "No record found.") {
                setlist([]);
              }
            } else {
              var pageClicked1 = parseInt(
                listrecord.formRecordList[3].page.page_clicked
              );
              var pageCount1 = parseInt(
                listrecord.formRecordList[3].page.page_count
              );
              var page_records1 =
                listrecord.formRecordList[3].page.page_records;
              var record_count1 = parseInt(
                listrecord.formRecordList[3].page.record_count
              );

              // var flt = listrecord.formRecordList[5];
              var fltarr = listrecord.formRecordList[5].filter;
              var tmln = listrecord.formRecordList[6].timeLine;
              var filterString = "";
              var leng = fltarr.length;
              for (var f = 0; f < fltarr.length; f++) {
                if (leng === 1 && fltarr[f].cl === "") {
                  console.log();
                  break;
                } else {
                  if (f > 0) {
                    filterString += fltarr[f].af + " ";
                  }
                  filterString += fltarr[f].cl + " ";
                  filterString += fltarr[f].mc + " ";
                  filterString += fltarr[f].an + " ";
                }
              }

              for (
                var i = 0;
                i < listrecord.formRecordList[2].records.length;
                i++
              ) {
                var in_vl = [];
                var record_id;
                for (
                  var j = 0;
                  j < listrecord.formRecordList[2].records[i].record.length;
                  j++
                ) {
                  if (i === 0) {
                    if (j === 0) {
                    } else {
                      hd.push({
                        label:
                          listrecord.formRecordList[2].records[i].record[j]
                            .label,
                        name: listrecord.formRecordList[2].records[i].record[j]
                          .name,
                      });
                    }
                  }
                  if (j === 0) {
                    record_id =
                      listrecord.formRecordList[2].records[i].record[j].value;
                  } else if (j === 1) {
                    if (
                      listrecord.formRecordList[2].records[i].record[j].type ===
                      "reference"
                    ) {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value.value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: true,
                        table: listrecord.formRecordList[1].table.value,
                        r_id: record_id,
                      });
                    } else {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: true,
                        table: listrecord.formRecordList[1].table.value,
                        r_id: record_id,
                      });
                    }
                  } else {
                    if (
                      listrecord.formRecordList[2].records[i].record[j].type ===
                      "reference"
                    ) {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value.value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: false,
                        table: listrecord.formRecordList[1].table.value,
                        r_id: record_id,
                      });
                    } else {
                      in_vl.push({
                        value:
                          listrecord.formRecordList[2].records[i].record[j]
                            .value,
                        type: listrecord.formRecordList[2].records[i].record[j]
                          .type,
                        firstrecord: false,
                        table: listrecord.formRecordList[1].table.value,
                        r_id: record_id,
                      });
                    }
                  }
                }
                va_ll.push({ colr: in_vl });
              }
              columnarry.push({ heading: hd, rcd: va_ll });
              var cla = JSON.parse(
                JSON.stringify(listrecord.formRecordList[9].column)
              );
              cla.unshift({ label: "None", name: "none" });

              let cla2 = [];

              for (let i = 0; i < cla.length; i++) {
                if (
                  cla[i].name === "id" ||
                  cla[i].name === "created" ||
                  cla[i].name === "created_by" ||
                  cla[i].name === "updated" ||
                  cla[i].name === "updated_by" ||
                  cla[i].name === "uni_id" ||
                  cla[i].name === "cid"
                ) {
                } else {
                  cla2.push(cla[i]);
                }
              }

              console.log("claccccccccccccccccccc : " + JSON.stringify(cla2));
              setLoading(false);
              setlist(columnarry);
              setPageClicked(pageClicked1);
              setPageCount(pageCount1);
              setPageRecords(page_records1);
              setRecordCount(record_count1);
              setCol_mn2(cla2);
              setPrefCall(listrecord.formRecordList[9].column);
              setFilter(fltarr);
              setTimeline(tmln);
              setClearButtonLoading(false);
              setRunButtonLoading(false);
              setLoading(false);
            }
          }
        },
        (error) => { navigation("/error")
          console.log(error);
          let temp = [];
          setCol_mn2([...temp]);
        }
      );
  };

  const getTableName = () => {
    axios
      .get(loca+"/lom/get/tables", {
        headers: {
          // "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let tableList = resp.data;
          console.log("tableList : " + JSON.stringify(tableList));
          setTableList(tableList.tableRecords);
          setTab(true);
          console.log("kkkkkkllllllllllllll");
          initiallist();
        },
        (error) => { navigation("/error")
          console.log(error);
        }
      );
  };

  const onSubmission = () => {
    let frecord = record;
    console.log(">>>>>>>>>>>>> : " + JSON.stringify(mainRecord));
    frecord[9].value = JSON.stringify(filtarray);
    if ("formRecord" in mainRecord) {
      console.log("checccccc");
      mainRecord.formRecord[2].record = frecord;
    } else {
      if ("appRecord" in mainRecord) {
        console.log("appppppppprcd");
        mainRecord.appRecord[2].record = frecord;
      } else {
        if ("tableRecord" in mainRecord) {
          mainRecord.tableRecord[2].record = frecord;
        } else {
          if ("columnRecord" in mainRecord) {
            mainRecord.columnRecord[2].record = frecord;
          } else {
            if ("typeRecord" in mainRecord) {
              mainRecord.typeRecord[2].record = frecord;
            } else {
              if ("uivalidationRecord" in mainRecord) {
                mainRecord.uivalidationRecord[2].record = frecord;
              } else {
                if ("languageRecord" in mainRecord) {
                  mainRecord.languageRecord[2].record = frecord;
                } else {
                  if ("labelRecord" in mainRecord) {
                    mainRecord.labelRecord[2].record = frecord;
                  } else {
                    if ("buttonRecord" in mainRecord) {
                      mainRecord.buttonRecord[2].record = frecord;
                    } else {
                      if ("choiceRecord" in mainRecord) {
                        mainRecord.choiceRecord[2].record = frecord;
                      } else {
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    console.log("mainRecordddddddddddddd : " + JSON.stringify(mainRecord));
    axios
      .post(loca+"/lom/insert/record", mainRecord, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let result = resp.data;
          console.log(result);
          let id = result.formRecord[2].record[0].value;

          flowId.current = id;
          // console.log("flow Id : " + id + " flowName: " + name);
          getFlowActionDetails();
        },
        (error) => { navigation("/error")
          console.log(error);
        }
      );
  };

  const onSubmission2 = (index) => {
    let fn=flowAction
    let frecord = record2;
    console.log("flowOrder : " + JSON.stringify(flowOrder.current));
    console.log("flow_order : "+flowOrder.current)
          for (let i = 0; i < frecord.length; i++) {
            if (frecord[i].name === "flow_order") {
              frecord[i].value = flowOrder.current.toString();      break;
            }
          }
    for (let i = 0; i < frecord.length; i++) {
      if (frecord[i].name === "filter") {
        frecord[i].value = JSON.stringify(fn[index].insertArray);
        break;
      }
    }
    console.log("frecord : " + JSON.stringify(frecord));

    if ("formRecord" in mainRecord2) {
      mainRecord2.formRecord[2].record = frecord;
    } else {
      if ("appRecord" in mainRecord) {
        mainRecord2.appRecord[2].record = frecord;
      } else {
        if ("tableRecord" in mainRecord) {
          mainRecord2.tableRecord[2].record = frecord;
        } else {
          if ("columnRecord" in mainRecord) {
            mainRecord2.columnRecord[2].record = frecord;
          } else {
            if ("typeRecord" in mainRecord) {
              mainRecord2.typeRecord[2].record = frecord;
            } else {
              if ("uivalidationRecord" in mainRecord) {
                mainRecord2.uivalidationRecord[2].record = frecord;
              } else {
                if ("languageRecord" in mainRecord) {
                  mainRecord2.languageRecord[2].record = frecord;
                } else {
                  if ("labelRecord" in mainRecord) {
                    mainRecord2.labelRecord[2].record = frecord;
                  } else {
                    if ("buttonRecord" in mainRecord) {
                      mainRecord2.buttonRecord[2].record = frecord;
                    } else {
                      if ("choiceRecord" in mainRecord) {
                        mainRecord2.choiceRecord[2].record = frecord;
                      } else {
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    console.log("mainRecordddddddddddddd : " + JSON.stringify(mainRecord2));
    axios
      .post(loca+"/lom/insert/record", mainRecord2, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let result = resp.data;
          console.log(result);
          let fn=flowAction;
          fn[index].addButton=true;
          setFlowAction([...fn])
          // showAddBtn(true);
          // let id = result.formRecord[2].record[0].value;

          // setFlowId(id);
          // console.log("flow Id : " + id + " flowName: " + name);
          // getFlowActionDetails();
        },
        (error) => { navigation("/error")
          console.log(error);
        }
      );
  };
  const changecolumn = (e, index) => {
    console.log(e);
    const vl = e;
    var farray = filtarray;
    for (var i = 0; i < col_mn.length; i++) {
      if (col_mn[i].name === vl) {
        farray[index].ct = col_mn[i].type;
        farray[index].cl = col_mn[i].label;
        farray[index].co = col_mn[i].name.toString();
        break;
      }
    }
    setFiltArray([...farray]);
  };

  const changemiddle = (e, index) => {
    var farray = filtarray;
    if (e !== undefined) {
      farray[index].mc = e.toString();
    }
    setFiltArray([...farray]);
  };

  const changelast = (e, index) => {
    var farray = filtarray;
    if (e !== undefined) {
      farray[index].an = e.toString();
    }
    setFiltArray([...farray]);
  };

  const setTimeLine = (e) => {
    setTimeline(e);
  };
  const cancelfilt = (i) => {
    filtarray.splice(i, 1);
    if (filtarray.length === 0) {
      filtarray.push({
        co: "",
        cl: "",
        mc: "",
        an: "",
        af: "",
      });
    }
    setFiltArray([...filtarray]);
  };

  const filterset = () => {
    for (var i = 0; i < filtarray.length; i++) {
      if (filtarray[i].ct !== "String") {
        if (filtarray[i].an === "") {
          return false;
        }
      }
    }
    return true;
  };

  const addbtn = () => {
    if (filterset()) {
      filtarray.push({
        co: "",
        cl: "",
        mc: "",
        an: "",
        ct: "",
        af: "AND",
      });
      setFiltArray(filtarray);
      setFilter_Unset(false);
    } else {
      setFilter_Unset(true);
    }
  };

  const orbtn = () => {
    if (filterset()) {
      // var len = filtarray.length;
      filtarray.push({
        co: "",
        cl: "",
        mc: "",
        an: "",
        af: "OR",
      });
      setFiltArray(filtarray);
      setFilter_Unset(false);
    } else {
      setFilter_Unset(true);
    }
  };
  const filterClear = () => {
    var fltarray = [];
    fltarray.push(filt);
    setFiltArray(fltarray);
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"autoportal"}}';
    pp += ',{"table":{"id":"","name":"' + listName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';

    setList(pp);
  };

  const filtersubmit = () => {
    console.log(" fillllll" + JSON.stringify(filtarray));
    var fs = '{"formRecordList":[';
    fs += '{"application":{"id":"","name":"autoportal"}}';
    fs += ',{"table":{"id":"","name":"' + listName + '"}}';
    fs += ',{"records":[]}';
    fs +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    fs += ',{"sort":{"asc":"true","column":"id"}}';
    fs += ',{"filter":' + JSON.stringify(filtarray) + "}";
    fs += ',{"timeLine":"' + timeline + '"}]}';
    setList(fs);
  };
  const setSort = () => {
    var clmn = "";
    var pp = "";
    var srt = sortColumn.name;
    if (srt !== "") {
      for (var l = 0; l < list.length; l++) {
        for (var ll = 0; ll < list[l].heading.length; ll++) {
          if (list[l].heading[ll].label === sortColumn.name) {
            clmn = list[l].heading[ll].name;
            break;
          }
        }
      }
      if (sortColumn.sort === true) {
        pp += ',{"sort":{"asc":"true","column":"' + clmn + '"}}';
      } else {
        pp += ',{"sort":{"asc":"false","column":"' + clmn + '"}}';
      }
      return pp;
    } else {
      pp +=
        ',{"sort":{"asc":"true","column":"' + list[0].heading[0].name + '"}}';
    }
    return pp;
  };
  const setFormTable = (val) => {
    if (val !== "None" && val !== null && val !== undefined) {
      let frecord = record;
      console.log(JSON.stringify(frecord[13]))
      let name = tableList.find((e) => e.id === val).name;
      frecord[13].value.id = val;
      frecord[13].value.name = name;
      setRecord([...frecord]);
      setShowCol1(true);
      // console.log("nammmmeeeeeeeeee : " + name);
      getListRecord(name);
    } else {
      setShowCol1(false);
    }
  };
  const setCheckBoxValue = (index, val) => {
    let frecord = record;

    console.log(
      checkForInsert +
        " init Frecordd : " +
        frecord[index].name +
        "  val : " +
        frecord[index].value
    );

    if (val) {
      frecord[index].value = "true";
    } else {
      frecord[index].value = "false";
    }
    console.log(
      "after Frecordd : " +
        frecord[index].name +
        "  val : " +
        frecord[index].value
    );
    console.log("frecordddd : " + JSON.stringify(frecord));
    setRecord([...frecord]);
  };

  const actionFunc = (type,index) => {
    let fa=flowAction
    switch (type) {
      case "insert":
        let frecord = record2;
        for (let i = 0; i < frecord.length; i++) {
          if (frecord[i].name === "action") {
            frecord[i].value = type;
            fa[index].action=type
            fa[index].showTabDD=true
            break;
          }
        }
        
        setFlowAction([...fa])
        setRecord2([...frecord]);
        setShowTabDD(true);
        break;
      case "fetch":
        fa[index].showTabDD=true
        setFlowAction([...fa])
        setShowTabDD(false);
        break;

      default:
        fa[index].showTabDD=true
        setFlowAction([...fa])
        setShowTabDD(false);
        break;
    }
  };

  const setFormTableForAction = (val,index) => {
    let fn=flowAction;
    if (val !== null && val !== undefined && val !== "None") {
      let name = tableList.find((e) => e.id === val).name;
      let frecord = record2;
      for (let i = 0; i < frecord.length; i++) {
        if (frecord[i].name === "form_table_id") {
          frecord[i].value.id = val;
          frecord[i].value.name = name;
        }
      }
      fn[index].showCol2=true
      fn[index].showInsetCondition=true
      fn[index].table=val

      getListRecord2(name);
      setRecord2([...frecord]);
      setFlowAction([...fn])
      setShowCol2(true);
      setShowInsetCondition(true);
    } else {
      setShowCol2(true);
      setShowInsetCondition(false);
    }
  };
  const addInsertbtn = ( i,index) => {
    let fn=flowAction
    let frecord = fn[index].insertArray;
    // console.log("heyyyyyyyyyyyyyyy22222222 : "+JSON.stringify(frecord));
    if (frecord[i].value !== "" && frecord[i].name !== "None") {
      // console.log("heyyyyyyyyyyyyyyy");
      frecord.push({ name: "", value: "",type:"" });
    } else {
    }
    fn[index].insertArray=frecord
    setFlowAction([...fn])
    // setInsertArray([...frecord]);
  };

  const changeInsertName = (ii, val,index) => {
    let fn=flowAction
    let frecord = fn[index].insertArray;
    let type = col_mn2[ii].type;
    console.log("typep  : " + val);
    for (let i = 0; i < col_mn2.length; i++) {
      if (col_mn2[i].name === val) {
        frecord[ii].type = col_mn2[i].type;
      }
    }

    frecord[ii].name = val;
    fn[index].insertArray=frecord
    console.log("Frecorddddd : " + JSON.stringify(frecord));
    setFlowAction([...fn])
    // setInsertArray([...frecord]);
  };

  const changeInsertValue = (ii, val,index) => {
    console.log("valllllll : " + val);
    let fn=flowAction
    let frecord = fn[index].insertArray;
    frecord[ii].value = val;
    fn[index].insertArray=frecord
    setFlowAction([...fn])
    // setInsertArray([...frecord]);
  };
  const cancelInsert = (i,index) => {
    let fn=flowAction
    let frecord = fn[index].insertArray;
    frecord.splice(i, 1);
    if (frecord.length === 0) {
      frecord.push({
        name: "None",
        value: "",
      });
    }
    fn[index].insertArray=frecord
    setFlowAction([...fn])
    // setInsertArray([...frecord]);
  };

  const setFlowNameFunc = (val) => {
    let frecord = record;
    for (let i = 0; i < frecord.length; i++) {
      if (frecord[i].name === "name") {
        flowName.current = val;
        frecord[i].value = val;
        break;
      }
    }
    console.log(frecord);
    setRecord([...frecord]);
  };

  const addFlowAtion=()=>{

    let fn =flowAction;
    fn.push({
      show: true,
      showTabDD:false,
      showCol2:false,
      showInsetCondition:false,
      action: "None",
      table: "None",
      insertArray: [{ name: "None", value: "", type: "" }],
      addButton: false,
    })
    flowOrder.current = flowOrder.current + 1;
    console.log("flow Order"+flowOrder.current)
    setFlowAction([...fn])
  }

  useEffect(() => {
    // console.log("adnnnn " + token);

    getFormDetails();
    getTableName();
  },[]);

  let strlist = () => {
    if (str_fields.length > 0) {
      return str_fields.map((item, st_i) => (
        <option key={st_i} value={item} label={item} />
      ));
    }
  };

  let intlist = () => {
    if (int_fields.length > 0) {
      return int_fields.map((item, s_i) => (
        <option key={s_i} value={item} label={item} />
      ));
    }
  };

  let bolnlist = () => {
    if (boln_fields.length > 0) {
      return boln_fields.map((item, bo_i) => (
        <option key={bo_i} value={item} label={item} />
      ));
    }
  };

  let reflist = () => {
    if (ref_fields.length > 0) {
      return ref_fields.map((item, b_i) => (
        <option key={b_i} value={item} label={item} />
      ));
    }
  };

  let booleanfd = () => {
    if (booleanfld.length > 0) {
      return booleanfld.map((item, bb_i) => (
        <option key={bb_i} value={item} label={item} />
      ));
    }
  };

  return (
    <div style={{flexGrow:1}}>
      <div className="container card mt-3 shadow main-background">
        {tab && (
          <div>
            <div className="fr row justify-content-center alignt-items-center">
              <div className="col " style={{ alignSelf: "center" }}>
                <input
                  placeholder="Enter Name Here"
                  className="form-control"
                  type={"text"}
                  value={flowName.current}
                  onChange={(e) => {
                    setFlowNameFunc(e.target.value);
                  }}
                ></input>
              </div>
              <div className="col select-container">
                <select
                  className="select "
                  onChange={(e) => {
                    console.log(
                      "iiiiiiiiii : " + JSON.stringify(e.target.value)
                    );
                    setFormTable(e.target.value);
                  }}
                >
                  <option value={null}>None</option>
                  {tableList.map((tl, i) => (
                    <option key={i} value={tl.id}>
                      {tl.label}
                    </option>
                  ))}
                </select>
              </div>
              <div
                className="col fr row checkbox-container  "
                style={{ alignSelf: "center" }}
              >
                <div className="col checkbox">
                  <span className="span">Insert</span>
                  <input
                    className="input-checkbox"
                    type={"checkbox"}
                    checked={checkForInsert}
                    onChange={(e) => {
                      setCheckedForInsert(!checkForInsert);
                      console.log("checkeed " + e.target.checked);
                      setCheckBoxValue(10, e.target.checked);
                    }}
                  ></input>
                </div>
                <div className="col checkbox">
                  <span className="span">Update</span>
                  <input
                    className="input-checkbox"
                    type={"checkbox"}
                    checked={checkForUpdate}
                    onChange={(e) => {
                      setCheckedForUpdate(!checkForUpdate);
                      setCheckBoxValue(11, e.target.checked);
                    }}
                  ></input>
                </div>
                <div className="col checkbox">
                  <span className="span">Delete</span>
                  <input
                    className="input-checkbox"
                    type={"checkbox"}
                    checked={checkForDelete}
                    onChange={(e) => {
                      setCheckedForDelte(!checkForDelete);
                      setCheckBoxValue(12, e.target.checked);
                    }}
                  ></input>
                </div>
              </div>
            </div>
          </div>
        )}
        <div>
          <div className="row fr ">
            <div className="col-md-2 " style={{ color: "white" }}>
              Time Line :
            </div>
            <div className="col-md-2 ">
              <select
                className="inpt_type"
                type="text"
                onChange={(e) => setTimeLine(e.target.value)}
                value={timeline}
              >
                {tm_list.map((tm, tm_o) => (
                  <option value={tm.name} key={tm_o}>
                    {tm.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
          {filter_unset === true && (
            <div className="alert alert-danger">
              Please set previous filter before adding a new filter
            </div>
          )}
          {filtarray.length > 0 &&
            filtarray.map((abc, index) => (
              <div className="row fr " key={index}>
                {abc.af === "AND" && filtarray.length > 1 && (
                  <div className="col-md-1 ">
                    <span className="addandcancel"> And </span>
                  </div>
                )}
                {abc.af === "OR" && filtarray.length > 1 && (
                  <div className="col-md-1 ">
                    <span className="addandcancel"> OR </span>
                  </div>
                )}
                <div className="col-md-2 ">
                  <select
                    className=" selct_opt "
                    onChange={(e) => changecolumn(e.target.value, index)}
                    value={abc.co}
                  >
                    {showCol1 &&
                      col_mn.map((clm, cl_o) => (
                        <option value={clm.name} key={cl_o}>
                          {clm.label}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="col-md-2">
                  <div>
                    <select
                      className=" selct_opt"
                      onChange={(e) => changemiddle(e.target.value, index)}
                      value={abc.mc}
                    >
                      {abc.ct === "String" ? strlist() : null},
                      {abc.ct === "int" ? intlist() : null},
                      {abc.ct === "boolean" ? bolnlist() : null},
                      {abc.ct === "reference" ? reflist() : null},
                    </select>
                  </div>
                </div>
                <div className="col-md-2 ">
                  <div>
                    {abc.ct === "String" ? (
                      <input
                        className="inpt_type"
                        type="text"
                        placeholder="type"
                        value={abc.an}
                        onChange={(e) => changelast(e.target.value, index)}
                      ></input>
                    ) : null}
                    {abc.ct === "int" ? (
                      <input
                        className="inpt_type"
                        type="text"
                        placeholder="type"
                        value={abc.an}
                        onChange={(e) => changelast(e.target.value, index)}
                      ></input>
                    ) : null}
                    {abc.ct === "date" ? (
                      <input
                        className="inpt_type"
                        type="date"
                        value={abc.an}
                        onChange={(e) => changelast(e.target.value, index)}
                      ></input>
                    ) : null}

                    {abc.ct === "boolean" ? (
                      <select
                        className="inpt_type"
                        type="text"
                        value={abc.an}
                        onChange={(e) => changelast(e.target.value, index)}
                      >
                        {abc.ct === "boolean" ? booleanfd() : null},
                      </select>
                    ) : null}
                    {abc.ct === "reference" ? (
                      <input
                        className="inpt_type"
                        type="text"
                        placeholder="type"
                        value={abc.an}
                        onChange={(e) => changelast(e.target.value, index)}
                      ></input>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-1">
                
                </div>
                <div className="col-md-2">
             
                  <div className="padbtnnn">
                  <div className="cancelbtn">
                    <i
                      className="fa fa-times"
                      
                      onClick={(e) => cancelfilt(index)}
                    ></i>
                  </div>
                    <button
                      type="button"
                      className=" btnnn btn btn-primary"
                      onClick={addbtn}
                    >
                      AND
                    </button>

                    <button
                      type="button"
                      className=" btnnn btn btn-primary"
                      onClick={orbtn}
                    >
                      OR
                    </button>
                  </div>
                </div>
              </div>
            ))}
          <input
            className=" btn btn-primary  sub-btn"
            type="button"
            value="Submit"
            
            onClick={() => {
              onSubmission();
            }}
          ></input>
        </div>
      </div>
      {showFLowAction &&
        flowAction.map((obj, index) => (
          <div key={index}>
            {obj.show && (
              <div className="container card mt-3 shadow main-background2">
                <div className="select-container2">
                  <select
                    className="select"
                    value={obj.action}
                    onChange={(e) => {
                      actionFunc(e.target.value,index);
                    }}
                  >
                    <option value={null}>None</option>
                    {actionList.map((val, i) => (
                      <option key={i} value={val}>
                        {val}
                      </option>
                    ))}
                  </select>
                </div>
                {obj.showTabDD && (
                  <div className="select-container">
                    <select
                      className="select "
                      value={obj.table}
                      onChange={(e) => {
                        console.log(
                          "iiiiiiiiii : " + JSON.stringify(e.target.value)
                        );
                        setFormTableForAction(e.target.value,index);
                      }}
                    >
                      <option value={null}>None</option>
                      {tableList.map((tl, i) => (
                        <option key={i} value={tl.id}>
                          {tl.label}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                {obj.showInsetCondition &&
                  obj.insertArray.map((abc, i) => (
                    <div
                      key={i}
                      className="fr row"
                      style={{ alignItems: "center",justifyContent:"center" }}
                    >
                      <div className="col-md select-container3">
                        <select
                          className=" select "
                          onChange={(e) => {
                            changeInsertName(i, e.target.value,index);
                          }}
                          value={abc.name}
                        >
                          {obj.showCol2 &&
                            col_mn2.map((clm, cl_o) => (
                              <option value={clm.name} key={cl_o}>
                                {clm.label}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div
                        className="col-md"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "start",
                          marginLeft: "20px",
                        }}
                      >
                        {(abc.type === "String" || abc.type === "int") && (
                          <input
                            placeholder="Enter Value Here"
                            className="form-control"
                            type={"text"}
                            value={abc.value}
                            onChange={(e) => {
                              changeInsertValue(i, e.target.value,index);
                            }}
                          ></input>
                        )}
                        {abc.type === "boolean" && (
                          <select
                            className="inpt_type"
                            value={abc.value}
                            onChange={(e) => {
                              console.log("etarget : " + e.target.value);
                              changeInsertValue(i, e.target.value,index);
                            }}
                          >
                            {booleanfld.map((item, bb_i) => (
                              <option key={bb_i} value={item} label={item} />
                            ))}
                          </select>
                        )}
                      </div>
                      <div className="col-md-1">
                        <div className="cancelbtn">
                          <i
                            className="fa fa-times"
                            
                            onClick={(e) => cancelInsert(i,index)}
                          ></i>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="padbtnnn">
                          <button
                            type="button"
                            className=" btnnn btn btn-primary"
                            onClick={() => addInsertbtn(i,index)}
                          >
                            AND
                          </button>

                          {/* <button
                      type="button"
                      className=" btnnn btn btn-primary"
                      onClick={orbtn}
                    >
                      OR
                    </button> */}
                        </div>
                      </div>
                    </div>
                  ))}
                <div
                  style={{
                    display: "block",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {!obj.addButton && (
                    <input
                      className=" btn btn-primary  sub-btn"
                      type="button"
                      value="Submit"
                      
                      onClick={() => {
                        onSubmission2(index);
                      }}
                    ></input>
                  )}
                  {obj.addButton && (
                    <input
                      className=" btn btn-primary  sub-btn"
                      type="button"
                      value="Add"
                      
                      onClick={() => {addFlowAtion()}}
                    ></input>
                  )}
                </div>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default FlowComponent;
