import axios from "axios";
import React, {
  Component,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { Modal, Button } from "react-bootstrap";
import "../css/ListComponent.css";
import { AutoPortalContext } from "../Context";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import WorkInProgress from "./WorkInProgress";
import { tab } from "@testing-library/user-event/dist/tab";
import NewFilterCompo from "./NewFilterCompo";
import PrefCompo from "./PrefCompo";
import { toast } from "react-toastify";
import { isMobile } from "react-device-detect";
import { useFilePicker } from "use-file-picker";
import AlertCompo from "./AlertCompo";
import ContextMenu from "./ContextMenu";
// import Modal from "react-modal";

const ListComponent = ({ dash_tableName, dash_parentApplication, list_id }) => {
  const {
    token,
    loca,
    userDetails,
    contextMenu,
    showAll,
    user,
    setMainError,
    listAccess,
    callType,
    formHeight,
  } = useContext(AutoPortalContext);

  const { openFilePicker, filesContent, loadingg, clear } = useFilePicker({
    accept: [".txt", ".csv"],
  });
  const navigation = useNavigate();

  const location = useLocation().state;

  // const [all, setAll] = useState(showAll);
  const [searchParam, setSearchParam] = useSearchParams();
  const [record, setRecord] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState([]);
  const [loading, setLoading] = useState(true);
  // const [name, setName] = useState("");
  const [col_mn, setCol_mn] = useState([]);
  const [list, setlist] = useState([]);
  const [listRcd, setListRcd] = useState([]);
  const [showlist, setShowList] = useState(false);
  const [showbtn, setShowbtn] = useState(false);
  const [showupdownbttn, setShowUpDownbtn] = useState(false);
  // const [int_fields, setInt_Fields] = useState([]);
  // const [email_fields, setEmail_Fields] = useState([]);
  // const [date_fields, setDate_Fields] = useState([]);
  // const [dtm_fields, setDtm_Fields] = useState([]);
  // const [choice_fields, setChoice_Fields] = useState([]);
  // const [boln_fields, setBoln_Fields] = useState([]);
  // const [str_fields, setStr_Fields] = useState([]);
  const [ref_fields, setRef_Fields] = useState([]);
  // const [booleanfld, setBooleanFld] = useState([]);
  const [srch_column, setSrch_column] = useState("name");
  const [button, setButton] = useState([]);
  const [btnNamee, setbtnNamee] = useState("");
  const [url, setUrl] = useState("");
  const [mainrecord, setMainRecord] = useState({});
  const [sortColumn, setSortColumn] = useState({
    name: "",
    sort: true,
    init: false,
  });
  const [prefCall, setPrefCall] = useState([]);
  const [srch, setSrch] = useState("");
  const [filtarray, setFiltArray] = useState([]);

  const [filString, setfilString] = useState("");
  const [filter, setFilter] = useState();
  const [showFilter, setShowFilter] = useState(false);
  const [tableLabel, setTableLabel] = useState("");

  let isSub = useRef(false);
  let menuX = useRef(0);
  let menuY = useRef(0);
  let filt1 = useRef();
  let mySelect = useRef();
  // console.log(location.filter);
  let checkMenu = useRef(false);
  const [filt, setFilt] = useState({
    co: "",
    cl: "",
    mc: "",
    an: "",
    ct: "",
    af: "",
    rf: { id: "", value: "" },
  });
  const [page_error, setPage_Error] = useState(false);
  const [alertType, setAlertType] = useState("");
  const [error, setError] = useState("");
  const [timeline, setTimeline] = useState("");
  const [ClearButtonLoading, setClearButtonLoading] = useState(false);
  const [RunButtonLoading, setRunButtonLoading] = useState(false);
  const [pageClicked, setPageClicked] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [pageRecords, setPageRecords] = useState("");
  const [recordCount, setRecordCount] = useState(0);
  const [columnList, setColumnList] = useState([]);
  const [showPref, setShowPref] = useState(false);
  const [showFilePicker, setShowFilePicker] = useState(false);
  const [modal, setModal] = useState(false);
  const [isClick, setIsClick] = useState(false);
  const [showContext, setShowContext] = useState(false);
  const [column_depend, setColumn_depend] = useState([]);
  const [tableName, setTableName] = useState("");
  const [tabId, setTabId] = useState("");
  const [showListContext, setShowListContext] = useState(false);
  const [all_ref_cols, setAll_ref_cols] = useState([]);
  const [recordId, setRecordId] = useState(0);
  let listName = useRef("");
  let parentApp = useRef("");
  // let alreadyRun = useRef(true);
  // filter which is already executed
  let executedFilter = useRef([]);

  //................ Functions ............//

  // const initallist = () => {
  //   let temp = [];
  //   temp.push({
  //     co: "",
  //     cl: "",
  //     mc: "",
  //     an: "",
  //     ct: "",
  //     af: "",
  //     rf: { id: "", value: "" },
  //     dc: { id: "", value: "" },
  //   });
  //   console.log("temp  : " + JSON.stringify(temp));
  //   setFiltArray([...temp]);
  //   getListRecord();
  // };

  //   window.onload=()=>{
  //     let reloading = sessionStorage.getItem("reloading");
  //     if(reloading){
  // console.log("hey this is reload");
  //     }
  //   }
  //   const handlePDF = () => {

  //     sessionStorage.setItem("reloading", "true");
  //     window.location.reload(false);
  // };

  const exportData = () => {
    console.log(listRcd);
    axios
      .post(loca + "/data/export", listRcd.formRecordList, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then((resp) => {
        const record = resp.data;
        download(record, listRcd.formRecordList[1].table.name);
      });
  };

  const importData = () => {
    setShowFilePicker(true);
  };

  const importt = (files) => {
    if (
      files.length !== 0 &&
      files[0].name.replace(/(\(\d+\))|(\.csv$)/g, "").trim() === tableName
    ) {
      setIsClick(true);
      const blob = new Blob([files[0].content], { type: "text/csv" });
      const file = new File([blob], files[0].name, {
        type: "text/csv",
      });
      const formData = new FormData();
      formData.append("file", file);
      axios
        .post(loca + "/data/import", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            authorization: "Bearer " + token,
          },
        })
        .then(
          (resp) => {
            const record = resp.data;
            toast(record, {
              position: "top-center",
              theme: "colored",
              type: "success",
              style: {
                marginBottom: userDetails.OS !== "null" ? 12 : 0,
              },
            });
            clear();
            refresh();
          },
          (error) => {
            setLoading(false);
            setModal(false);
            toast("Something went wrong", {
              position: "top-center",
              theme: "colored",
              type: "error",
            });
          }
        )
        .finally(() => {
          setShowFilePicker(false);
          setIsClick(false);
        });
    } else {
      toast("Table not match", {
        position: "top-center",
        theme: "colored",
        type: "error",
      });
      setShowFilePicker(false);
      clear();
    }
  };

  const download = (res, tablenm) => {
    const element = document.createElement("a");
    // for csv
    element.href = `data:text/csv;charset=utf-8,${res}`;
    element.download = tablenm;
    // for image
    // element.href = `data:image/png;base64,${res}`;
    // element.download = 'image';
    element.click();
    element.remove();
  };

  const getSortAndFilter = () => {
    let isAccessable = true;
    let listNam = "";
    if (!isSub.current) {
      listNam = searchParam.get("tableName");
      parentApp.current = searchParam.get("parentApplication");
      isAccessable = checkAccess() !== null;
    } else {
      listNam = listName.current;
    }
    axios
      .get(loca + "/lom/get/sortfilter/" + listNam, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let rsp = resp.data;
          // let isAccessable = checkAccess() !== null;
          console.log(rsp);

          getListRecord(isAccessable, listNam, rsp.value[0].sort, rsp.value[1]);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const goToFormTable = () => {
    let parentApp = searchParam.get("parentApplication");

    navigation({
      pathname: user + "/form",
      search: createSearchParams({
        parentApplication: parentApp,
        rty: "record",
        tableName: "form_table",
        rid: tabId,
      }).toString(),
    });
  };

  const refresh = () => {
    // setSelectedRecord([]);
    // getListRecord();
    setSrch("");
    getSortAndFilter();
    checkMenu.current = true;
    setLoading(true);
  };

  const back = () => {
    navigation(-1);
  };

  const menuFn = (body) => {
    let fn = new Function(
      [
        "back",
        "refresh",
        "exportData",
        "importData",
        "navigation",
        "goToFormTable",
        "tableName",
        "rid",
        "parentApplication",
        "user",
        "all_ref_cols",
        "tabId",
      ],
      body
    );
    fn(
      back,
      refresh,
      exportData,
      importData,
      navigation,
      goToFormTable,
      tableName,
      recordId,
      parentApp.current,
      user,
      all_ref_cols,
      tabId
    );
  };

  const setContext = (val, x, y) => {
    if (val === 2) {
      menuX.current = x;
      menuY.current = y;
      setShowContext(true);
    } else {
      setShowContext(false);
    }
  };
  const setListContext = (val, x, y, rid) => {
    if (val === 2) {
      menuX.current = x;
      menuY.current = y;
      setRecordId(rid);
      setShowListContext(true);
    } else {
      setShowListContext(false);
    }
  };

  const selectedRows = (checked, index) => {
    list[0].rcd[index].colr[0].ref = checked;
    if (!checked) {
      list[0].heading[0].ref = false;
    }
    setlist([...list]);
  };

  const selectedAllRows = (checked) => {
    let rcd = list[0].rcd;
    if (checked) {
      list[0].heading[0].ref = true;
      for (let i = 0; i < rcd.length; i++) {
        list[0].rcd[i].colr[0].ref = true;
      }
      setlist([...list]);
    } else {
      list[0].heading[0].ref = false;
      for (let i = 0; i < rcd.length; i++) {
        list[0].rcd[i].colr[0].ref = false;
      }
      setlist([...list]);
    }
  };

  const selectedAction = (url) => {
    if (list[0].rcd.length > 0) {
      if (url === "/lom/delete/record") {
        setbtnNamee("Delete");
        setModal(true);
        setUrl(url);
      } else if (url === "/lom/accept") {
        setbtnNamee("Accept");
        setModal(true);
        setUrl(url);
      } else {
        selectAction(url);
      }
    }
  };

  const selectAction = (url) => {
    setIsClick(true);
    let rcd = list[0].rcd;
    let postBody = [];
    for (let i = 0; i < rcd.length; i++) {
      if (rcd[i].colr[0].ref) {
        // rcd[i].colr.splice(0,1)
        postBody.push(
          rcd[i].colr[1].r_id
          // table: rcd[i].colr[1].table
        );
        continue;
      }
    }
    var jso = {
      url: url,
      table: rcd[0].colr[1].table,
      record: postBody,
    };
    // setLoading(true);
    axios
      .post(loca + "/lom/selectedAction", jso, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          const record = resp.data;
          if (record.Error) {
            toast(record.Error, {
              position: "top-center",
              theme: "colored",
              type: "error",
              style: {
                marginBottom: userDetails.OS !== "null" ? 12 : 0,
              },
            });
          } else if (record.Inform) {
            toast(record.Inform, {
              position: "top-center",
              theme: "colored",
              type: "info",
            });
          } else {
            if (record.message) {
              toast(record.message, {
                position: "top-center",
                theme: "colored",
                type: "success",
                style: {
                  marginBottom: userDetails.OS !== "null" ? 12 : 0,
                },
              });
            } else {
              toast(record, {
                position: "top-center",
                theme: "colored",
                type: "success",
                style: {
                  marginBottom: userDetails.OS !== "null" ? 12 : 0,
                },
              });
            }
            refresh();
            // setLoading(false);
          }
        },
        (error) => {
          setLoading(false);
          setModal(false);
          toast("Something went wrong", {
            position: "top-center",
            theme: "colored",
            type: "error",
          });
        }
      )
      .finally(() => {
        setModal(false);
        setIsClick(false);
      });

    if (url === "1") {
    }
  };

  useEffect(() => {
    setMainError(false);
    setSelectedRecord([]);
    setSrch("");
    setLoading(true);
    if (location) {
      filt1.current = location.filter;
    } else {
      filt1.current = null;
    }
    // setFilter("");
    document
      .getElementsByTagName("body")
      .item(0)
      .addEventListener("mousedown", () => {
        setShowContext(false);
        setShowListContext(false);
      });
    if (dash_tableName) {
      dashBoardForm();
    } else {
      getSortAndFilter();
    }
  }, [searchParam, showAll]);

  const checkAccess = () => {
    let tabname = searchParam.get("tableName");
    let listCheck = listAccess.current.find((e) => e === tabname);
    if (listCheck) {
      return tabname;
    }
    return null;
  };

  const dashBoardForm = () => {
    listName.current = dash_tableName;
    parentApp.current = dash_parentApplication;
    isSub.current = true;
    // getListRecord();
    getSortAndFilter();
  };

  const getListRecord = (isAccessable, listName, srrt, flt) => {
    if (isAccessable) {
      setTableName(listName);
      let pg = localStorage.getItem("pageClicked");
      sortColumn.name = srrt.column;
      sortColumn.sort = srrt.asc === "true" ? true : false;
      let p = 1;
      // let filt=[];
      // let srt = true;
      let srt = srrt.asc;
      let srtPage = srrt.column;
      // let srtPage = "id";
      var filterstate = "";
      filt1.current = flt.filter;
      if (pg) {
        let pag = JSON.parse(pg);
        p = pag.page;
        filterstate = pag.filter;
        let sort = pag.sort;
        srt = sort.sort;
        if (sort.name !== "") {
          srtPage = sort.name;
        }
        setFiltArray([...filterstate]);
        setSortColumn(sort);
        localStorage.removeItem("pageClicked");
      }
      var farray = [];
      if (filterstate === "") {
        if (
          filt1.current === "" ||
          filt1.current === "undefined" ||
          filt1.current === undefined ||
          filt1.current === "null" ||
          filt1.current === null
        ) {
          let fl = {
            co: "",
            cl: "",
            mc: "",
            an: "",
            ct: "",
            af: "",
            rf: {
              id: "",
              value: "",
            },
            dc: {
              id: "",
              value: "",
            },
            ch: [],
          };
          farray.push(fl);
          sortColumn.name = "";
          sortColumn.sort = true;
          filterstate = farray;
        } else {
          // if ("filter" in filter) {
          //   farray = filter;
          //   filterstate = farray;
          // } else {
          // farray = filt1.current.filter;
          farray = filt1.current;
          filterstate = farray;
          // }
        }
        setFiltArray(farray);
      }
      var tablere = '{"formRecordList":[';
      if (parentApp.current === "Marketplace") {
        tablere += '{"application":{"id":"2","name":"marketplace"}}';
      } else {
        tablere += '{"application":{"id":"2","name":"autoportal"}}';
      }
      tablere += ',{"table":{"id":"","name":"' + listName + '"}}';
      tablere += ',{"records":[]}';
      tablere +=
        ',{"page":{"record_count":"0","page_count":"1",' +
        '"page_clicked":"' +
        p +
        '","page_records":"0"}}';
      tablere +=
        ',{"sort":{"asc":"' +
        srt +
        '","column":"' +
        srtPage +
        '","init":"true"}}';
      tablere += ',{"filter":' + JSON.stringify(filterstate) + "}";
      tablere += ',{"timeLine":"' + "" + '"}]}';

      setList(tablere);
    } else {
      setLoading(false);
      toast(
        "Incorrect search query or you don't have permission to access this",
        { pauseOnHover: true, theme: "colored", type: "error" }
      );
    }
  };

  const handleShow = () => {
    setShowPref(true);
  };

  const handleClose = () => {
    setShowPref(false);
  };

  const handleCloseRefresh = () => {
    handleClose();
    var tablere = '{"formRecordList":[';
    tablere += '{"application":{"id":"","name":"autoportal"}}';
    tablere += ',{"table":{"id":"","name":"' + tableName + '"}}';
    tablere += ',{"records":[]}';
    tablere +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    tablere +=
      ',{"sort":{"asc":"' +
      sortColumn.sort +
      '","column":"' +
      sortColumn.name +
      '","init":"' +
      sortColumn.init +
      '"}}';
    tablere += ',{"filter":' + JSON.stringify(filtarray) + "}";
    tablere += ',{"timeLine":"' + timeline + '"}]}';
    console.log(tablere);

    setList(tablere);
  };

  const leftReadOnly = () => {
    if (pageClicked === 1) {
      return true;
    } else {
      return false;
    }
  };

  const rightReadOnly = () => {
    // console.log(pageClicked, pageCount, (pageClicked === pageCount));
    // if (
    //   pageClicked === pageCount ||
    //   (pageClicked + 1 === pageCount && pageClicked !== 1)
    // )
    // if (
    //   pageClicked === pageCount && pageClicked !== 1
    // )
    if (pageClicked === pageCount) {
      return true;
    } else {
      return false;
    }
  };

  const previousPage = () => {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"autoportal"}}';
    pp += ',{"table":{"id":"","name":"' + tableName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      (pageClicked - 1) +
      '","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';

    setList(pp);
  };

  const nextPage = () => {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"autoportal"}}';
    pp += ',{"table":{"id":"","name":"' + tableName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      (pageClicked + 1) +
      '","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';
    setList(pp);
  };

  const firstPage = () => {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"autoportal"}}';
    pp += ',{"table":{"id":"","name":"' + tableName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';

    setList(pp);
  };

  const lastPage = () => {
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"autoportal"}}';
    pp += ',{"table":{"id":"","name":"' + tableName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"' +
      (recordCount % 50 == 0 ? pageCount - 1 : pageCount) +
      '","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(filtarray) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';

    setList(pp);
  };

  const getChoiceRcd = async (col_id) => {
    let chk = [];
    let ck = await axios
      .get(loca + "/lom/get/choice/" + col_id, {
        headers: {
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let res = resp.data;
          let chk = res.choiceRecords;
          return chk;
          // console.log(rcd);
        },
        (error) => {
          let err = { message: error.message, code: error.response.status };
          navigation("/error", { state: { err: err } });
        }
      );
    return ck;
  };

  const callfilter = async (farray, in_index, col_id) => {
    if (col_id !== -1) {
      // for (let i = 0; i < rcd.length; i++) {
      //   console.log(index+" index "+i+" rcddddddd "+JSON.stringify(rcd[i]));
      if (
        farray[in_index].ct === "choice" &&
        farray[in_index].ch.length === 0
      ) {
        let chc = await getChoiceRcd(col_id).then((res) => {
          if (res.length > 0) {
            farray[in_index].ch = res;
            farray[in_index].an = res[0].name;
          } else {
            farray[in_index].ch = [];
          }
        });
        //  console.log(chcRcd.current);
      }
      // }
    }
    setFiltArray([...farray]);
  };

  const calltimeline = (timeline) => {
    setTimeline(timeline);
  };

  const callScript = (script, index) => {
    var rcd = record;
    rcd[index].value.script = script;
    // rcd[index].value = { filter: rcd[index].value.filter,script:script };
    // console.log(rcd[index].value);
    setRecord([...rcd]);
  };

  const setTimeLine = (e) => {
    setTimeline(e);
  };

  const showupdownbtn = (hd) => {
    var sarray = sortColumn;
    let flt = JSON.parse(JSON.stringify(executedFilter.current));
    // if (!alreadyRun.current) {
    //   flt.pop();
    //   let ff = {
    //     co: "",
    //     cl: "",
    //     mc: "",
    //     an: "",
    //     ct: "",
    //     af: "",
    //     rf: { id: "", value: "" },
    //     dc: { id: "", value: "" },
    //   };
    //   flt.push(ff);
    // }
    console.log(flt);

    var clmn = "";
    var srt = "";
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"autoportal"}}';
    pp += ',{"table":{"id":"","name":"' + tableName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';

    if (sarray.name === hd) {
      sarray.sort = !sarray.sort;
      for (var l = 0; l < list.length; l++) {
        for (var ll = 0; ll < list[l].heading.length; ll++) {
          console.log(list[l].heading[ll].name === hd, list[l].heading[ll].name, hd);
          if (list[l].heading[ll].name === hd) {
            clmn = list[l].heading[ll].name;
            break;
          }
        }
      }

      if (sarray.sort === true) {
        srt = '{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
        pp += "," + srt;
        // pp += ',{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
      } else {
        srt = '{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
        pp += "," + srt;
        // pp +=
        //   ',{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
      }
    } else {
      sarray.name = hd;
      sarray.sort = true;
      for (var li = 0; li < list.length; li++) {
        for (var lll = 0; lll < list[li].heading.length; lll++) {
          if (list[li].heading[lll].name === hd) {
            clmn = list[li].heading[lll].name;
            break;
          }
        }
      }
      if (sarray.sort === true) {
        srt += '{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
        pp += "," + srt;

        // pp += ',{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
      } else {
        srt +=
          '{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
        pp += "," + srt;

        // pp +=',{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
      }
    }
    // setCol_mn({ sortColumn: sarray });
    pp += ',{"filter":' + JSON.stringify(flt) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';
    storeSrot(srt, pp);
    // setList(pp);
  };

  const storeSrot = (sort, pp) => {
    let js = { tableName: tableName, sort: sort };
    axios
      .post(loca + "/lom/set/sort", js, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(
        (rsp) => {
          let resp = rsp.data;
          setList(pp);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  // if (val === "laptop") {
  //   ap_form.removeAllOption("u_model");
  //   ap_form.addOption("acer", "acer", "u_model");
  //   ap_form.addOption("hp", "hp", "u_model");
  // } else if (val === "mobile") {
  //   ap_form.removeAllOption("u_model");
  //   ap_form.addOption("samsung", "samsung", "u_model");
  //   ap_form.addOption("motorola", "motorola", "u_model");
  // }

  const setSort = () => {
    var clmn = "";
    var pp = "";
    var srt = sortColumn.name;
    if (srt !== "") {
      for (var l = 0; l < list.length; l++) {
        for (var ll = 0; ll < list[l].heading.length; ll++) {
          if (list[l].heading[ll].name === sortColumn.name) {
            clmn = list[l].heading[ll].name;
            break;
          }
        }
      }
      if (sortColumn.sort === true) {
        pp += ',{"sort":{"asc":"true","column":"' + clmn + '","init":"false"}}';
      } else {
        pp +=
          ',{"sort":{"asc":"false","column":"' + clmn + '","init":"false"}}';
      }
      return pp;
    } else {
      pp +=
        ',{"sort":{"asc":"true","column":"' +
        list[0].heading[1].name +
        '","init":"false"}}';
    }

    return pp;
  };

  //.................. Api Call ...............//

  const processListRecords = (listrecord) => {
    var columnarry = [];
    var hd = [];
    var va_ll = [];
    setPage_Error(false);
    setAlertType("");
    setError("");

    if ("Error" in listrecord) {
      setAlertType("danger");
      setError(listrecord.Error);
      setPage_Error(true);
      setLoading(false);
      // throw new Error(listrecord.Error);
    } else {
      var scnd = listrecord.formRecordList[2];
      if ("Error" in scnd) {
        if (scnd.Error === "No record found.") {
          setlist([]);
        }
      } else {
        var scnd = listrecord.formRecordList[2];
        if ("Error" in scnd) {
          if (scnd.Error === "No record found.") {
            setlist([]);
          }
        } else {
          setTableLabel(listrecord.formRecordList[1].table.label);

          var pageClicked1 = parseInt(
            listrecord.formRecordList[3].page.page_clicked
          );
          var pageCount1 = parseInt(
            listrecord.formRecordList[3].page.page_count
          );
          var page_records1 = listrecord.formRecordList[3].page.page_records;
          var record_count1 = parseInt(
            listrecord.formRecordList[3].page.record_count
          );
          var fltarr = listrecord.formRecordList[5].filter;
          var tmln = listrecord.formRecordList[6].timeLine;
          var filterString = "";
          var leng = fltarr.length;
          for (var f = 0; f < fltarr.length; f++) {
            if (leng === 1 && fltarr[f].cl === "") {
              break;
            } else {
              if (f > 0) {
                filterString += fltarr[f].af + " ";
              }
              filterString += fltarr[f].cl + " ";
              filterString += fltarr[f].mc + " ";
              if (
                fltarr[f].ct === "reference" ||
                fltarr[f].ct === "depend_table"
              ) {
                filterString += fltarr[f].rf.value + " ";
              } else {
                filterString += fltarr[f].an + " ";
              }
            }
          }
          for (
            var i = 0;
            i < listrecord.formRecordList[2].records.length;
            i++
          ) {
            var in_vl = [];
            var record_id;
            for (
              var j = 0;
              j < listrecord.formRecordList[2].records[i].record.length;
              j++
            ) {
              if (i === 0) {
                // if (j === 0) {
                // } else {
                //   hd.push({
                //     label:
                //       listrecord.formRecordList[2].records[i].record[j]
                //         .label,
                //     name: listrecord.formRecordList[2].records[i].record[j]
                //       .name,
                //   });
                // }
              }
              if (j === 0) {
                record_id =
                  listrecord.formRecordList[2].records[i].record[j].value;
              } else if (j === 1) {
                if (
                  listrecord.formRecordList[2].records[i].record[j].type ===
                  "reference"
                ) {
                  in_vl.push({
                    value:
                      listrecord.formRecordList[2].records[i].record[j].value
                        .value,
                    type: listrecord.formRecordList[2].records[i].record[j]
                      .type,
                    firstrecord: true,
                    table: listrecord.formRecordList[1].table.name
                      ? listrecord.formRecordList[1].table.name
                      : listrecord.formRecordList[1].table.value,
                    r_id: record_id,
                    name: listrecord.formRecordList[2].records[i].record[j]
                      .value.name,
                  });
                } else {
                  in_vl.push({
                    value:
                      listrecord.formRecordList[2].records[i].record[j].value,
                    type: listrecord.formRecordList[2].records[i].record[j]
                      .type,
                    firstrecord: true,
                    table: listrecord.formRecordList[1].table.name
                      ? listrecord.formRecordList[1].table.name
                      : listrecord.formRecordList[1].table.value,
                    r_id: record_id,
                    name: listrecord.formRecordList[2].records[i].record[j]
                      .name,
                  });
                }
              } else {
                if (
                  listrecord.formRecordList[2].records[i].record[j].type ===
                  "reference"
                ) {
                  in_vl.push({
                    value:
                      listrecord.formRecordList[2].records[i].record[j].value,
                    type: listrecord.formRecordList[2].records[i].record[j]
                      .type,
                    firstrecord: false,
                    table: listrecord.formRecordList[1].table.name
                      ? listrecord.formRecordList[1].table.name
                      : listrecord.formRecordList[1].table.value,
                    r_id: record_id,
                    name: listrecord.formRecordList[2].records[i].record[j]
                      .name,
                  });
                } else {
                  in_vl.push({
                    value:
                      listrecord.formRecordList[2].records[i].record[j].value,
                    type: listrecord.formRecordList[2].records[i].record[j]
                      .type,
                    firstrecord: false,
                    table: listrecord.formRecordList[1].table.name
                      ? listrecord.formRecordList[1].table.name
                      : listrecord.formRecordList[1].table.value,
                    r_id: record_id,
                    name: listrecord.formRecordList[2].records[i].record[j]
                      .name,
                  });
                }
              }
            }

            in_vl.splice(0, 0, { ref: false });
            va_ll.push({ colr: in_vl });
          }
          let col_list = [];
          for (var c = 0; c < listrecord.formRecordList[9].column.length; c++) {
            if (listrecord.formRecordList[9].column[c].type === "String") {
              col_list.push({
                label: listrecord.formRecordList[9].column[c].label,
                name: listrecord.formRecordList[9].column[c].name,
                type: listrecord.formRecordList[9].column[c].type,
              });
            }
          }
          for (
            var p = 0;
            p < listrecord.formRecordList[10].preference.length;
            p++
          ) {
            hd.push({
              label: listrecord.formRecordList[10].preference[p].label,
              name: listrecord.formRecordList[10].preference[p].name,
            });
          }
          hd.splice(0, 0, { ref: false });
          columnarry.push({ heading: hd, rcd: va_ll });
          let column = listrecord.formRecordList[9].column;
          if ("Error" in column) {
            setPage_Error(true);
            setError(column.Error);
            setLoading(false);
            toast(column.Error, {
              type: "error",
              position: "top-center",
            });
          } else {
            var cla = JSON.parse(JSON.stringify(column));
            cla.unshift({ label: "None", name: "none" });
            setlist(columnarry);
            setListRcd(listrecord);
            setPageClicked(pageClicked1);
            setPageCount(pageCount1);
            setPageRecords(page_records1);
            setRecordCount(record_count1);
            setCol_mn(cla);
            setPrefCall(column);
            executedFilter.current = JSON.parse(JSON.stringify(fltarr));
            setFilter(fltarr);
            setfilString(filterString);
            setTimeline(tmln);
            setClearButtonLoading(false);
            setRunButtonLoading(false);
            setButton(listrecord.formRecordList[11].button);
            setColumnList(col_list);
            let name_srch = false;
            console.log(srch_column);

            for (let z = 0; z < col_list.length; z++) {
              console.log(col_list[z].name, srch_column);

              if (col_list[z].name === srch_column) {
                name_srch = true;
                break;
              }
            }
            if (!name_srch) {
              setSrch_column(col_list[0].name);
            }
            setLoading(false);
          }
        }
      }
    }
  };

  const setList = (tablere) => {
    let url = "";
    if (parentApp.current === "Marketplace") {
      url = `http://localhost:8084/marketplace/get/multiple/record`;
    } else {
      url = loca + `/lom/get/multiple/record`;
    }
    axios
      .post(url, tablere.toString(), {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
          "market-application": "marketplace",
        },
      })
      .then(
        (resp) => {
          const listrecord = resp.data;
          console.log(listrecord);
          setTabId(listrecord.formRecordList[1].table.id);
          let rcd = listrecord.formRecordList[9].column;
          let ref_cols = [];
          for (let i = 0; i < rcd.length; i++) {
            rcd[i].index = i;
            if (rcd[i].type === "reference" && rcd[i].name === "form_table_id") {

              ref_cols.push(rcd[i]);
            }
          }
          setAll_ref_cols(ref_cols);
          processListRecords(listrecord);
        },
        (error) => {
          let err = { message: error.message, code: error.response?.status };
          navigation("/error", { state: { err: err } });
        }
      );
  };

  const changeSearch = (val) => {
    if (val === "") {
      // this.setState({ srch: "" });
      setSrch("");
      filterClear();
    } else {
      // this.setState({ srch: val });
      setSrch(val);
    }
  };

  const callSearchbtn = () => {
    // var fld = this.state.srch_column;
    // var val = this.state.srch;
    // var col = this.state.columnList;
    if (srch === "") {
      return;
    }

    var type = "";
    var label = "";
    for (var c = 0; c < columnList.length; c++) {
      console.log(srch_column, columnList[c].name);

      if (srch_column === columnList[c].name) {
        type = columnList[c].type;
        label = columnList[c].label;
      }
    }

    console.log(type, columnList);

    let filter = [
      {
        ct: type,
        af: "",
        mc: "contains",
        cl: label,
        co: srch_column,
        an: srch,
        rf: { id: "", value: "" },
        dc: { id: "", value: "" },
      },
    ];
    var fs = '{"formRecordList":[';
    fs += '{"application":{"id":"","name":"autoportal"}}';
    fs += ',{"table":{"id":"","name":"' + tableName + '","label":""}}';
    fs += ',{"records":[]}';
    fs +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    fs += ',{"sort":{"asc":"true","column":"id" , "init":"false"}}';
    fs += ',{"filter":' + JSON.stringify(filter) + "}";
    fs += ',{"timeLine":""}]}';
    setFiltArray([...filter]);
    setList(fs);
  };

  const callform = (nam, tab, r_id, e) => {
    if (nam === "first") {
      let pg = { page: pageClicked, filter: filtarray, sort: sortColumn };
      localStorage.setItem("pageClicked", JSON.stringify(pg));



      if (e.button === 0) {
        // navigateWithSearchParams(
        //   "/private/form",
        //   {
        //     rty: "record",
        //     tableName: tab,
        //     rid: r_id,
        //   },
        //   {}
        // );
        console.log("21");

        navigation({
          pathname: user + "/form",
          search: createSearchParams({
            parentApplication: parentApp.current,
            rty: "record",
            tableName: tab,
            rid: r_id,
          }).toString(),
        });
      } else if (e.button === 1) {


        window.open(user + `/form?parentApplication=${parentApp.current}&rty=record&tableName=${tab}&rid=${r_id}`,
          `_blank`
        );

      }
      // showFormCompo(tab, r_id, "record");
    } else if (nam === "second") {
    } else if (nam === "new") {
      navigation({
        pathname: user + "/form",
        search: createSearchParams({
          state: {
            parentApplication: parentApp.current,
            rty: "new",
            tableName: tab,
            rid: 0,
          },
        }).toString(),
      });
      // navigation("/form", );
      // showFormCompo(tab, 0, "new");
    }
  };

  const callReport = (nam, tab, r_id, rcdId) => {
    if (nam === "first") {
      let pg = { page: pageClicked, filter: filtarray, sort: sortColumn };
      navigation({
        pathname: user + "/report_view",
        search: createSearchParams({
          parentApplication: parentApp.current,
          rty: "viewReport",
          tableName: tab,
          rid: r_id,
        }).toString(),
        state: {},
      });

      //     navigation("/htmlcall", { state: { html: ht_ml, script: script } });

      // showFormCompo(tab, r_id, "record");
    } else if (nam === "second") {
    } else if (nam === "new") {
      navigation({
        pathname: user + "/form",
        search: createSearchParams({
          state: {
            parentApplication: parentApp.current,
            rty: "new",
            tableName: tab,
            rid: 0,
          },
        }).toString(),
      });
      // navigation("/form", );
      // showFormCompo(tab, 0, "new");
    }
  };

  // const dismiss = () => {
  //   unmountMe();
  // }

  const searchColumn = (e) => {
    // this.setState({ srch_column: e.target.value });
    setSrch_column(e.target.value);
  };

  const filterItem = () => {
    console.log("filterItem", filtarray);

    setShowList(!showlist);
    setShowbtn(!showbtn);
  };

  const filterClear = () => {
    var fltarray = [];
    let flt = {
      co: "",
      cl: "",
      mc: "",
      an: "",
      ct: "",
      af: "",
      rf: { id: "", value: "" },
      dc: { id: "", value: "" },
      ch: [],
    };
    fltarray.push(flt);
    setFiltArray(fltarray);
    var pp = '{"formRecordList":[';
    pp += '{"application":{"id":"","name":"autoportal"}}';
    pp += ',{"table":{"id":"","name":"' + tableName + '"}}';
    pp += ',{"records":[]}';
    pp +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    pp += setSort();
    pp += ',{"filter":' + JSON.stringify(fltarray) + "}";
    pp += ',{"timeLine":"' + timeline + '"}]}';

    setList(pp);
  };

  const filtersubmit = () => {
    console.log(filtarray);

    // alreadyRun.current = true;
    // let runFilt = false;

    sortColumn.sort = true;
    var fs = '{"formRecordList":[';
    fs += '{"application":{"id":"","name":"autoportal"}}';
    fs += ',{"table":{"id":"","name":"' + tableName + '"}}';
    fs += ',{"records":[]}';
    fs +=
      ',{"page":{"record_count":"0","page_count":"1",' +
      '"page_clicked":"1","page_records":"0"}}';
    fs += ',{"sort":{"asc":"true","column":"id","init":"false"}}';
    fs += ',{"filter":' + JSON.stringify(filtarray) + "}";
    fs += ',{"timeLine":"' + timeline + '"}]}';

    let len = filtarray.length;

    // for (let i = 0; i < filtarray.length; i++) {
    //   if (filtarray[i].ct === "reference") {
    //     if (filtarray[i].rf.value !== "" && filtarray[i].rf.id !== "0") {

    //       runFilt = true;
    //     } else {
    //       runFilt = false;
    //       break;
    //     }
    //   } else {
    //     if (filtarray[i].an !== "") {

    //       runFilt = true;
    //     } else {
    //       runFilt = false;
    //       break;
    //     }
    //   }
    // }

    // if (runFilt) {

    if (
      filtarray[len - 1].mc === "is filled" ||
      filtarray[len - 1].mc === "is empty" ||
      filtarray[len - 1].an !== "" ||
      filtarray[len - 1].rf?.value !== "" ||
      filtarray[len - 1].dc?.value !== "" ||
      timeline != ""
    ) {
      // executedFilter.current=JSON.parse(JSON.stringify(filtarray))
      setList(fs);
    }
    // }
  };

  const callbtn = (nam) => {
    var btn = button;
    var mnrecord = mainrecord;
    for (var ij = 0; ij < btn.length; ij++) {
      if (btn[ij].value === nam) {
        var btntype = btn[ij].buttonWebType;
        var nextPage = btn[ij].returnWebLocation;
        var callType = btn[ij].call_type;
        var token = localStorage.getItem("token");

        setPage_Error(false);
        setError("");
        setLoading(true);
        if (callType === "module") {
          var scpt = btn[ij].script;
          var f = new Function(
            ["navigation", "createSearchParams", "user"],
            scpt
          );
          f(navigation, createSearchParams, user);
        } else {
          if (nextPage === "NextPage") {
            callform("new", tableName, 0);
          } else {
            mnrecord.formRecord[2].record = record;
            axios
              .post(loca + btn[ij].webUrl, mnrecord, {
                headers: {
                  "Content-Type": "application/json",
                  authorization: "Bearer " + token,
                },
              })
              .then((resp) => {
                const rcd = resp.data;
                setLoading(false);
                var msg = rcd.formRecord[4].message;
                if (msg !== "") {
                  // setState({ page_message: true, message: msg });
                }
                if (btntype === "NextPage") {
                }
              });
          }
        }
      }
    }
  };

  const saveFilter = () => {
    let ft = { filter: filtarray, timeLine: timeline };
    let js = { tableName: tableName, filter: ft };
    axios
      .post(loca + "/lom/save/filter", js, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(
        (rsp) => {
          let resp = rsp.data;
          // setList(pp);
        },
        (error) => {
          console.log(error);
        }
      );
  };
  const removeFilter = () => {
    let js = { tableName: tableName };
    axios
      .post(loca + "/lom/remove/filter", js, {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(
        (rsp) => {
          let resp = rsp.data;
          setShowList(!showlist);
          setShowbtn(!showbtn);
          getSortAndFilter();
        },
        (error) => {
          console.log(error);
        }
      );
  };

  useEffect(() => {
    if (mySelect.current) {
      console.log(mySelect.current);
    }
  }, [modal]);

  const handleEnterPress = (event) => {
    if (event.key === "Enter") {
      callSearchbtn();
      console.log("Enter key pressed!");
    }
  };

  const setDefaultOption = () => {
    const selectElement = mySelect.current;
    selectElement.selectedIndex = 0;
  };

  try {
    if (!loading) {
      return (
        <div id="list" className="overfl-tab">
          <Modal
            show={modal}
            onHide={() => {
              setModal(!modal);
              setDefaultOption();
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Confirm {btnNamee}</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => {
                  setModal(!modal);
                  setDefaultOption();
                }}
                disabled={isClick}
                style={{ backgroundColor: isClick ? "gray" : "" }}
              >
                Cancel
              </Button>
              <Button
                variant="danger"
                onClick={() => selectAction(url)}
                disabled={isClick}
                style={{ backgroundColor: isClick ? "gray" : "" }}
              >
                {btnNamee}
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={showFilePicker}
            onHide={() => setShowFilePicker(!showFilePicker)}
          >
            <Modal.Header closeButton onClick={() => clear()}>
              <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body className="" sty>
              <div>
                {filesContent.length === 0 && (
                  <button onClick={() => openFilePicker()}>
                    Select files{" "}
                  </button>
                )}
                {filesContent.map((file, index) => (
                  <div key={index}>
                    {/* <h2>{file.name}</h2> */}
                    {/* <img alt={file.name} src={file.content}></img> */}
                    <div key={file.name}>{file.name}</div>
                  </div>
                ))}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => clear()}>
                Clear
              </Button>
              <Button
                variant="danger"
                onClick={() => importt(filesContent)}
                disabled={isClick}
                style={{ backgroundColor: isClick ? "gray" : "" }}
              >
                Upload
              </Button>
            </Modal.Footer>
          </Modal>
          {/* {loading ? (
          <WorkInProgress />
        ) : ( */}
          <div>
            {isMobile === true || isSub.current ? (
              // <div className="bck bck-rel" style={{display:"flex"}}>
              <div
                className="row bck bck-rel"
                onContextMenu={(e) => {
                  e.preventDefault();
                  // m_rid.current = obj.id;
                  if (isSub.current) {
                    let parent = document
                      .getElementById(list_id)
                      .getBoundingClientRect();
                    setContext(
                      e.button,
                      e.nativeEvent.clientX - parent.left,
                      e.nativeEvent.clientY - parent.top
                    );
                  } else {
                    setContext(
                      e.button,
                      e.nativeEvent.pageX,
                      e.nativeEvent.pageY
                    );
                  }
                }}
              >
                <div>
                  {showContext && (
                    <ContextMenu
                      menuX={menuX}
                      menuY={menuY}
                      contextMenu={contextMenu}
                      menuFn={menuFn}
                      excluded_menu={["copy", "paste"]}
                      conditionFn={true}
                    />
                  )}
                </div>
                <div className="col-lg mid">
                  <span
                    className="obj_head"
                    onTouchStart={(e) => {
                      setTimeout(() => {
                        e.preventDefault();
                        setContext(2, e.touches[0].pageX, e.touches[0].pageY);
                      }, 100);
                    }}
                  >
                    {tableLabel}
                  </span>
                </div>
                <div className=" filter-ic">
                  {showlist === false && (
                    <select
                      className="form-select namelist-mob"
                      aria-label="Default"
                      value={srch_column}
                      onChange={(e) => searchColumn(e)}
                    >
                      {columnList.map((obj2, index) => (
                        <option key={index} value={obj2.name}>
                          {obj2.label}
                        </option>
                      ))}
                    </select>
                  )}

                  {showlist === false && (
                    <input
                      className="srch-mob form-control"
                      type="search"
                      aria-label="Search"
                      value={srch}
                      onChange={(e) => changeSearch(e.target.value)}
                    ></input>
                  )}

                  {showlist === false && (
                    <input
                      className="csm_btn csm_btn_pri col-md-2 sub-btn"
                      type="button"
                      value="Search"
                      onClick={() => callSearchbtn()}
                    ></input>
                  )}
                </div>
                <div className=" martop10 disfl filter-ic">
                  <i
                    className="fa fa-filter vlpointer pdtop5"
                    onClick={filterItem}
                  ></i>
                  <i
                    className="fa fa-cog vlpointer cog_pd pdtop5"
                    onClick={handleShow}
                  ></i>
                  <div className="text-end">
                    <span style={{ marginLeft: "0.5em" }}>
                      <input
                        className=" mybt"
                        type="button"
                        value="<<"
                        onClick={firstPage}
                        disabled={leftReadOnly()}
                      />
                      <input
                        className=" mybt"
                        type="button"
                        value="<"
                        onClick={previousPage}
                        disabled={leftReadOnly()}
                      />
                    </span>
                    <span>
                      <span className="in-put">{pageRecords}</span>
                    </span>
                    <span className="text-uppercase spfont"> of </span>
                    <span className="rcd_count spfont">{recordCount}</span>
                    <span>
                      <input
                        className=" mybt"
                        type="button"
                        value=">"
                        onClick={nextPage}
                        disabled={rightReadOnly()}
                      />
                    </span>
                    <span>
                      <input
                        className=" mybt"
                        type="button"
                        value=">>"
                        disabled={rightReadOnly()}
                        onClick={lastPage}
                      />
                    </span>
                  </div>
                </div>

                <div className="d-flex flex-row align-items-center col-lg martop10">
                  <div>
                    <select
                      ref={mySelect}
                      className="dropdn"
                      onChange={(e) => {
                        e.target.value !== "none" &&
                          selectedAction(e.target.value);
                      }}
                    >
                      <option value={"none"}>None</option>
                      {button.length > 0 &&
                        list[0].rcd.length > 0 &&
                        button.map(
                          (obj, oo_i) =>
                            obj.ui_type === "selected_action" && (
                              <option key={oo_i} value={obj.webUrl}>
                                {obj.value}
                              </option>
                            )
                        )}
                    </select>
                  </div>
                  {button.length > 0 &&
                    button.map(
                      (obj, oo_i) =>
                        (obj.ui_type === "form_button" ||
                          obj.ui_type === "market_button") && (
                          <button
                            style={{ height: "1.75rem" }}
                            className=" insrtbtn2 btn btn btn-primary btn-sm"
                            key={oo_i}
                            onClick={(e) => callbtn(obj.value)}
                          >
                            {obj.name}
                          </button>
                        )
                    )}
                </div>
                <div className="col-lg martop10">
                  {showlist === true && (
                    <input
                      className="csm_btn csm_btn_pri col-md-2 sub-btn"
                      type="button"
                      value="Run"
                      onClick={filtersubmit}
                    ></input>
                  )}

                  {showlist === true && (
                    <input
                      className="csm_btn csm_btn_pri col-md-2 sub-btn"
                      type="button"
                      value="Clear"
                      onClick={filterClear}
                    ></input>
                  )}
                </div>
              </div>
            ) : (
              <div
                style={{ zIndex: 1000 }}
                className="row bck bck-rel"
                onContextMenu={(e) => {
                  e.preventDefault();
                  if (isSub.current) {
                    let parent = document
                      .getElementById(list_id)
                      .getBoundingClientRect();
                    setContext(
                      e.button,
                      e.nativeEvent.clientX - parent.left,
                      e.nativeEvent.clientY - parent.top
                    );
                  } else {
                    setContext(
                      e.button,
                      e.nativeEvent.pageX,
                      e.nativeEvent.pageY
                    );
                  }
                }}
              >
                <div>
                  {showContext && (
                    <ContextMenu
                      menuX={menuX}
                      menuY={menuY}
                      contextMenu={contextMenu}
                      menuFn={menuFn}
                      excluded_menu={["copy", "paste", "open_in_new_tab"]}
                      conditionFn={true}
                    />
                  )}
                  {showListContext && (
                    <ContextMenu
                      menuX={menuX}
                      menuY={menuY}
                      contextMenu={contextMenu}
                      menuFn={menuFn}
                      excluded_menu={[
                        "copy",
                        "paste",
                        "refresh",
                        "form_view_setUp",
                        "back",
                        "export_data",
                        "import_data",
                        "configure_all",
                      ]}
                      conditionFn={true}
                    />
                  )}
                </div>
                <div className="col-lg filter-ic pt-1">
                  <i
                    className="fa fa-filter vlpointer"
                    onClick={filterItem}
                  ></i>

                  <i
                    className="fa fa-cog vlpointer cog_pd"
                    onClick={handleShow}
                  ></i>

                  {showlist === true && (
                    <input
                      className="btn btn-primary sub-btn btn-sm"
                      type="button"
                      value="Run"
                      onClick={filtersubmit}
                    ></input>
                  )}

                  {showlist === true && (
                    <input
                      className="btn btn-primary sub-btn btn-sm"
                      type="button"
                      value="Clear"
                      onClick={filterClear}
                    ></input>
                  )}

                  {showlist === false && (
                    <select
                      style={{
                        width: "10em",
                        display: "inline",
                      }}
                      className="form-select namelist"
                      aria-label="Default"
                      value={srch_column}
                      onChange={(e) => searchColumn(e)}
                    >
                      {columnList.map((obj2, index) => (
                        <option key={index} value={obj2.name}>
                          {obj2.label}
                        </option>
                      ))}
                    </select>
                  )}

                  {showlist === false && (
                    <input
                      className="srch form-control"
                      type="search"
                      aria-label="Search"
                      value={srch}
                      onChange={(e) => changeSearch(e.target.value)}
                      onKeyDown={handleEnterPress}
                      style={{ height: "1.72rem" }}
                    ></input>
                  )}

                  {showlist === false && (
                    <input
                      className="btn btn-primary btn-sm col-md sub-btn mt-1"
                      type="button"
                      value="Search"
                      onClick={() => callSearchbtn()}
                      style={{ height: "1.72rem" }}
                    ></input>
                  )}
                </div>
                <div
                  className="col-lg mid"
                  onTouchStart={(e) => {
                    setTimeout(() => {
                      e.preventDefault();
                      setContext(2, e.touches[0].pageX, e.touches[0].pageY);
                    }, 100);
                  }}
                >
                  <p className="obj_head ">{tableLabel}</p>
                </div>
                <div className="col-lg bt_padd ">
                  <div>
                    <select
                      ref={mySelect}
                      className="dropdn"
                      onChange={(e) => {
                        e.target.value !== "none" &&
                          selectedAction(e.target.value);
                      }}
                    >
                      <option value={"none"}>None</option>
                      {button.length > 0 &&
                        list[0].rcd.length > 0 &&
                        button.map(
                          (obj, oo_i) =>
                            obj.ui_type === "selected_action" && (
                              <option key={oo_i} value={obj.webUrl}>
                                {obj.value}
                              </option>
                            )
                        )}
                    </select>
                    {/* <select onChange={(e) => { selectAction(e.target.value) }}>
                      <option>None</option>
                      <option value={1}>Delete</option>
                    </select> */}
                  </div>
                  {button.length > 0 &&
                    button.map(
                      (obj, oo_i) =>
                        (obj.ui_type === "form_button" ||
                          obj.ui_type === "market_button") && (
                          <button
                            style={{ height: "1.72rem" }}
                            className=" insrtbtn2 btn btn btn-primary btn-sm"
                            key={oo_i}
                            onClick={(e) => callbtn(obj.value)}
                          >
                            {obj.name}
                          </button>
                        )
                    )}
                  <div className="d-flex align-items-center">
                    <input
                      className=" mybt"
                      type="button"
                      value="<<"
                      onClick={firstPage}
                      disabled={leftReadOnly()}
                    />
                    <input
                      className=" mybt"
                      type="button"
                      value="<"
                      onClick={previousPage}
                      disabled={leftReadOnly()}
                    />

                    <span className="in-put">{pageRecords}</span>
                    <span className="text-uppercase spfont"> of </span>

                    <span className="rcd_count spfont">{recordCount}</span>
                    <input
                      className=" mybt"
                      type="button"
                      value=">"
                      onClick={nextPage}
                      disabled={rightReadOnly()}
                    />
                    <input
                      className=" mybt"
                      type="button"
                      value=">>"
                      disabled={rightReadOnly()}
                      onClick={lastPage}
                    />
                  </div>
                </div>
              </div>
            )}
            {showlist === false && filString !== "" && (
              <div style={{ textAlign: "start" }}>{filString}</div>
            )}
            {showlist === true && (
              <div>
                <div className="d-flex mb-2">
                  <input
                    className=" btn btn-sm btn-primary m-0 py-1 ms-2 mt-2"
                    type="button"
                    value="Save Filter"
                    // disabled={rightReadOnly()}
                    onClick={saveFilter}
                  />

                  <input
                    className="btn btn-sm btn-primary m-0 py-1 ms-2 mt-2"
                    type="button"
                    value="Remove Filter"
                    // disabled={rightReadOnly()}
                    onClick={removeFilter}
                  />
                </div>
                <NewFilterCompo
                  showlist={showlist}
                  col_mn={col_mn}
                  call_fil={(filtarray, in_index, col_id) => {
                    callfilter(filtarray, in_index, col_id);
                  }}
                  filtarray={filtarray}
                  setFiltArray={(e) => {
                    console.log("setFilt");

                    // alreadyRun.current = false;
                    setFiltArray(e);
                  }}
                  tableName={tableName}
                  timeline={timeline}
                  setTimeLine={(timeline) => calltimeline(timeline)}
                  isMobile={isMobile}
                  setScript={(script) => { }}
                  isScript={false}
                  col_depend={column_depend}
                  setColumn_depend={setColumn_depend}
                />
              </div>
            )}
            {page_error && (
              <div className="container mt-1">
                <AlertCompo type={alertType} msg={error} />
              </div>
            )}
            {console.log(list)}

            {list.map((lstobj, lst_i) => (
              <div
                className="heading_top table_set overflow-auto"
                style={
                  isSub.current
                    ? { position: "relative" }
                    : { height: formHeight - 2, position: "relative" }
                }
                key={lst_i}
              >
                <table className="table table-bordered table-striped table-hover p-1 ">
                  <thead style={{ position: "sticky", top: "0px" }}>
                    <tr className="obj_name">
                      {lstobj.heading.map((obj, obj_i) => (
                        <th
                          key={obj_i}
                          className="vlpointer p-0"
                          style={
                            obj_i === 0
                              ? { verticalAlign: "middle", width: "4%" }
                              : { verticalAlign: "middle" }
                          }
                        >
                          {obj_i === 0 ? (
                            <span>
                              <input
                                type="checkbox"
                                checked={obj.ref}
                                onChange={(e) => {
                                  selectedAllRows(e.target.checked);
                                }}
                              ></input>
                            </span>
                          ) : (
                            <div
                              style={{
                                height: "100%",
                                width: "100%",
                                padding: "0.5rem",
                              }}
                              onClick={(e) => {
                                showupdownbtn(obj.name);
                              }}
                            >
                              {obj.label}
                              {/* {console.log(sortColumn, obj)} */}
                              {sortColumn.name === obj.name &&
                                sortColumn.sort === true && (
                                  <i className=" icpadding fa fa-arrow-up"></i>
                                )}
                              {sortColumn.name === obj.name &&
                                sortColumn.sort === false && (
                                  <i className=" icpadding fa fa-arrow-down"></i>
                                )}
                            </div>
                          )}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {lstobj.rcd.map((objj, objj_i) => (
                      <tr className="obj_value" key={objj_i}>
                        {objj.colr.map((objr, objr_i) => (
                          <td
                            key={objr_i}
                            className={
                              objr.firstrecord === true
                                ? "val_pad val_under vlpointer"
                                : "val_pad"
                            }
                            onMouseDown={
                              objr.firstrecord === true
                                ? callType.callType === "viewreport"
                                  ? (e) =>
                                    callReport(
                                      "first",
                                      objr.table,
                                      objr.r_id,
                                      objj_i
                                    )
                                  : (e) =>
                                    callform("first", objr.table, objr.r_id, e)
                                : (e) => callform("second", "", "", e)
                            }
                            onContextMenu={(e) => {
                              e.preventDefault();
                              setListContext(
                                e.button,
                                e.nativeEvent.pageX,
                                e.nativeEvent.pageY,
                                objr.r_id
                              );
                            }}
                          >
                            {/* {console.log(objr)} */}
                            {objr_i == 0 ? (
                              <span>
                                <input
                                  type="checkbox"
                                  checked={objr.ref}
                                  onChange={(e) => {
                                    selectedRows(e.target.checked, objj_i);
                                  }}
                                ></input>
                              </span>
                            ) : (
                              <span>
                                {(objr.type === "filter" ||
                                  objr.type === "json") &&
                                  objr.value !== null &&
                                  objr.value !== "null" ? (
                                  JSON.stringify(objr.value)
                                ) : objr.type === "reference" ||
                                  objr.type === "depend_table" ? (
                                  objr.value.name
                                ) : objr.type === "group_key_value" ? (
                                  JSON.stringify(
                                    objr.value.properties?.length > 50
                                      ? objr.value.properties.slice(0, 50) +
                                      " ..."
                                      : objr.value.properties
                                  )
                                ) : objr.type === "html" ? (
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        objr.value?.length > 50
                                          ? objr.value.slice(0, 50) + " ..."
                                          : objr.value,
                                    }}
                                  ></span>
                                ) : objr.value?.length > 50 ? (
                                  objr.value.slice(0, 50) + " ..."
                                ) : (
                                  objr.value
                                )}
                              </span>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
                {lstobj.rcd.length === 0 && (
                  <div
                    style={{
                      fontWeight: "bold",
                      textDecoration: "none",
                      fontStyle: "normal",
                    }}
                  >
                    No Record Found
                  </div>
                )}
              </div>
            ))}
          </div>
          {/* )} */}

          <Modal
            dialogClassName="modal-dialog my-modal-personalized modal-centered"
            size="xl"
            show={showPref}
            onHide={handleClose}
          >
            <Modal.Header closeButton>
              <Modal.Title>Personalized List Column</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <PrefCompo
                pref="user"
                setRefer={(val) => setRef_Fields(val)}
                colarray={prefCall}
                tablename={tableName}
                parentApp={parentApp.current}
                handleClose={() => handleCloseRefresh()}
              ></PrefCompo>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    } else {
      return (
        <div
          className="d-flex justify-content-center align-items-center w-100"
          style={{ height: "95vh" }}
        >
          <WorkInProgress />
        </div>
      );
    }
  } catch (error) {
    let err = { message: error.message, code: error.response?.status };
    navigation("/error", { state: { err: err } });
  }
};
export default ListComponent;
