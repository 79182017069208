import React, { useEffect } from "react";
import Modal from "react-modal";
import img from "../ppp.png";
import { useNavigate } from "react-router-dom";

export default function LogoutModal({ modalIsOpen, closeModal, username }) {
  const navigate = useNavigate();
  const logOut = () => {
    localStorage.removeItem("autoToken");
    localStorage.clear();
    navigate("/login");
  };

  const customStyles = {
    content: {
      // zIndex:1000,
      width: "320px",
      height: "350px",
      height: "fit-content",
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "11px",
      background: "#010154",
    },
  };
  useEffect(() => {
    console.log(modalIsOpen, closeModal);
  }, [modalIsOpen]);
  return (
    <Modal
    overlayClassName="logout-overlay"
    // overlayElement={<div style={{background:"background-color: rgba(255, 255, 255, 0.75)",zIndex:100}}>za</div>}
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={customStyles}
      ariaHideApp={false}
    >
      <div
        className="profile_img text-center p4"
        style={{ color: "white", textAlign: "center" }}
      >
        <div className="">
          <img className="imgggg" src={img} />
        </div>
        <p
          className="mt-2 text-capitalize"
          style={{
            fontSize: "20px",
          }}
        >
          {username}
        </p>
      </div>
      <div className="d-flex flex-column gap-2">
        <button
          className="btn btn-danger form-control"
          onClick={() => logOut()}
        >
          Logout
        </button>
        <button className="btn btn-primary form-control" onClick={closeModal}>
          Close
        </button>
      </div>
    </Modal>
  );
}
