import React from "react";

const Tester = () => {
  return (
    <div className="container">
      <div
        style={{
          background: "grey",
          height: "200px",
          border: "3px solid black",
        }}
      >
        test
      </div>
      <div
        style={{
          background: "grey",
          height: "200px",
          border: "3px solid black",
        }}
      >
        test
      </div>
      <div
        style={{
          background: "grey",
          height: "200px",
          border: "3px solid black",
        }}
      >
        test
      </div>
      <div
        style={{
          background: "grey",
          height: "200px",
          border: "3px solid black",
        }}
      >
        test
      </div>
      <div
        style={{
          background: "grey",
          height: "200px",
          border: "3px solid black",
        }}
      >
        test
      </div>
      <div
        style={{
          background: "grey",
          height: "200px",
          border: "3px solid black",
        }}
      >
        test
      </div>
      <div
        style={{
          background: "grey",
          height: "200px",
          border: "3px solid black",
        }}
      >
        test
      </div>
      <div
        style={{
          background: "grey",
          height: "200px",
          border: "3px solid black",
        }}
      >
        test
      </div>
      <div
        style={{
          background: "grey",
          height: "200px",
          border: "3px solid black",
        }}
      >
        test
      </div>
      <div
        style={{
          background: "grey",
          height: "200px",
          border: "3px solid black",
        }}
      >
        test
      </div>
      <div
        style={{
          background: "grey",
          height: "200px",
          border: "3px solid black",
        }}
      >
        test
      </div>
      <div
        style={{
          background: "grey",
          height: "200px",
          border: "3px solid black",
        }}
      >
        test
      </div>
      <div
        style={{
          background: "grey",
          height: "200px",
          border: "3px solid black",
        }}
      >
        test
      </div>
      <div
        style={{
          background: "grey",
          height: "200px",
          border: "3px solid black",
        }}
      >
        test
      </div>
      <div
        style={{
          background: "grey",
          height: "200px",
          border: "3px solid black",
        }}
      >
        test
      </div>
      <div
        style={{
          background: "grey",
          height: "200px",
          border: "3px solid black",
        }}
      >
        test
      </div>
      <div
        style={{
          background: "grey",
          height: "200px",
          border: "3px solid black",
        }}
      >
        test
      </div>
      <div
        style={{
          background: "grey",
          height: "200px",
          border: "3px solid black",
        }}
      >
        test
      </div>
    </div>
  );
};

export default Tester;

// const [jsxCode, setJsxCode] = useState('<div>sajid {greeting && <h1>zakir</h1>}</div>');
// const [jsCode, setJsCode] = useState('');

// const handleConvert = () => {
//   try {
//     const result = Babel.transform(jsxCode, {
//       presets: ['react'],
//       comments: false, // Disable adding comments
//       minified: true  ,

//     });
//     setJsCode(result.code);
//   } catch (error) {
//     console.error('Error converting JSX:', error);
//   }
// };

// React.createElement('div', {},
// [React.createElement('div', {},
// [React.createElement('button', {className:"bg-warning",onChange:()=>{null},onClick: ()=>{print()}},'sub'),React.createElement('h1', {className:"null",onChange:()=>{null},onClick: ()=>{null}},'Zakir'),React.createElement('div', {},[React.createElement('button', {className:"btn btn-primary",onChange:()=>{null},onClick: ()=>{print2()}},'sub2')])])])

// function htmlToReact(htmlString) {
//   // Regular expression to match HTML tags
//   const tagRegex = /<([a-zA-Z0-9]+)(.*?)>(.*?)<\/\1>|<([a-zA-Z0-9]+) *>/g;

//   // Replace class with className and convert inline styles
//   console.log(tagRegex.test(htmlString));
//   const reactString = htmlString.replace(
//     tagRegex,
//     (match, tag, attrs, content) => {
//       // Convert class attribute to className
//       attrs = attrs.replace(/class=/g, "className=");

//       // Convert inline styles
//       attrs = attrs.replace(/style="([^"]+)"/g, (_, styles) => {
//         const styleObject = {};
//         styles.split(";").forEach((style) => {
//           const [key, value] = style.split(":").map((s) => s.trim());
//           if (key && value) {
//             styleObject[key] = value;
//           }
//         });
//         return `style={${JSON.stringify(styleObject)}}`;
//       });

//       // Convert onclick to onClick
//       attrs = attrs.replace(/onclick=/g, "onClick:()=>");
//       attrs = attrs.replace(/'/g, "");
//       // Convert tag name to lowercase
//       tag = tag.toLowerCase();

//       // Recursively convert children
//       const children = content ? htmlToReact(content) : null;

//       // Return React.createElement format
//       return `React.createElement('${tag}', ${attrs ? `{${attrs}}` : "null"}, ${
//         content ? `'${content}'` : "null"
//       })`;
//     }
//   );

//   return reactString;
// }

// function home() {
//   navigation({
//     pathname: "/landing",
//     search: createSearchParams({ app: "nike", page: "home" }).toString(),
//   });
// };
// function load() {
//   let j = { name: "nk_product" };
//   console.log(j);
//   axios
//     .post(loca + "/api/get/record", j, {
//       headers: { authorization: "Bearer " + token },
//     })
//     .then((res) => {
//       console.log(res.data);
//       let head = document.getElementById("tab").innerHTML;
//       let da = res.data.list.record;
//       head += "<tbody>";
//       for (let i = 0; i < da.length; i++) {
//         console.log("for");
//         head += "<tr>";
//         head += "<td>" + da[i].u_name + "</td>";
//         head += "<td>" + da[i].u_type + "</td>";
//         head += "<td>" + da[i].u_model + "</td>";
//         head += "</tr>";
//       }
//       head += "</tbody>";
//       document.getElementById("tab").innerHTML = head;
//     });
// };

// function sub() {
//   let name = document.getElementById("exampleInput1").textContent;
//   let type = document.getElementById("exampleInput2").textContent;
//   let model = document.getElementById("exampleInput3").textContent;
//   let j = { table: "nk_product", name: name, model: model, type: type };
//   console.log(j);
//   axios
//     .post(loca + "/api/insert/record", j, {
//       headers: { authorization: "Bearer " + token },
//     })
//     .then((res) => {
//       console.log(res.data);
//     });
// };
// window.load = load();

// {
//     let name = document.getElementById("exampleInput1").textContent;
//     let type = document.getElementById("exampleInput2").textContent;
//     let model = document.getElementById("exampleInput3").textContent;
//     let j = { table: "nk_product", name: name, model: model, type: type } ;
//     console.log(j);
//     axios
//       .post(loca + "/api/insert/record", j, {
//         headers: { authorization: "Bearer " + token },
//       })
//       .then((res) => {
//         console.log(res.data);
//       });
//   }

//   React.createElement("div",)

// const Tester = ({ rigth }) => {
// const [loading, setLoading] = useState(true);
// const [jsx, setJsx] = useState("");
// const navigation=useNavigate()
// //   let result = Function(["useEffect", "useState"], rigth);
// //   let html = result(useEffect, useState);
// //   console.log(html);
// function check() {
//   console.log("zakir");
// }

// function logincall() {
//   setTimeout(() => {
//     setLoading(false);
//   }, 200);
// }
// if (loading) {
//   return (
//     <div style={{display:"flex",height:"90vh",flexDirection:"column"}}>
//        <Editor
//             language="jsx"
//             theme="vs-dark"
//             value={jsx}
//             onChange={(e, i) => {
//               setJsx(e);
//             }}
//             options={{
//               inlineSuggest: true,
//               fontSize: "16px",
//               formatOnType: true,
//               autoClosingBrackets: true,
//               minimap: { scale: 10 },
//             }}
//           />
//           <div>
//           <button className="btn btn-primary" onClick={()=>{navigation("/test",{state:{jsx:"<React.Component>"+jsx+""}})}} >test</button>
//           </div>
//     </div>
//     // <div>
//     //   { <JsxParser
//     //    blacklistedAttrs={[]}
//     //     bindings={{ onClick:logincall}}
//     //     jsx={`<input type="text" onChange={onChange}/>
//     //     <div className="form-group" style={{ marginTop: "22px" }}>
//     //     <input
//     //       type="button"
//     //       value="Login"
//     //       className="btn-hvr"
//     //       onClick={onClick}
//     //     ></input>
//     //     {/* <Link  >Login</Link> */}
//     //   </div>
//     //     `}
//     //   /> }
//     // </div>
//   );
// } else {
//   return (
//     <div className="compo-loader">
//       <WorkInProgress />
//     </div>
//   );
// }

// const Test = ({ functionString, jsxString} ) => {
//   // Define the function using the provided functionString
//   functionString

//   const renderJSX = new Function(["React"],'return (' + jsxString + ')');

//   // Render JSX using the provided jsxString
//   return (
//     <div >
//       {renderJSX(React)}
//     </div>
//   );
// };

// const Greeting = ({ html }) => {
//   console.log(html);
//   const print = () => {
//     console.log("printed");
//   };
//   return <div>{html}</div>;
// };
