import React, { useContext, useState, useEffect } from "react";
import { AutoPortalContext } from "../Context";
import { useLocation } from "react-router-dom";
import OTPInput from "otp-input-react";

import { Card, CardBody, CardHeader, CardFooter } from "reactstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";

const OtpVerification = () => {
  const { token, loca } = useContext(AutoPortalContext);
  // const [otp, setOtp] = useState(false);
  const [time, setTime] = useState(60);
  const [otpContent, setOtpContent] = useState("");
  const { email } = useLocation().state || 0;
  const navigation = useNavigate();

  const forgetpasscall = () => {
    navigation("/ForgetPassword");
  };

  const GenerateNewpinfn = () => {
    let o = otpContent;
    console.log(o);
    // console.log(otp);
    let email = localStorage.getItem("email");
    console.log(email);
    let fp = { forget_password: { email: email, otp: o } };
    // if (otp === true && time != 0) {
    if ( time != 0) {
      axios.post(loca + "/lom/set/matchOtp", fp, {}).then(
        (resp) => {
          let optresp = resp.data;
          console.log(optresp);
          if ("Error" in optresp) {
            console.log(optresp.Error);
            toast.error(optresp.Error);
          } else {
            navigation("/Newpin");
          }
        },
        (error) => {
          navigation("/error");
          console.log(error);
        }
      );
    } else {
      toast.warning("resend otp");
    }
  };


  const sendOTPcallfn = () => {
    if (email === "") {
      alert("check your email");
    } else {
      let fp = { forget_password: { email: email } };
      console.log(token);
      console.log("ggg " + fp.toString());
      axios.post(loca + "/lom/set/forgetpassword", fp, {}).then(
        (resp) => {
          console.log(resp.data);
          const fpd = resp.data;
          console.log("gggggggggg" + JSON.stringify(fpd));
          //  let c = "Error" in fpd;
          //   console.log(c);
          console.log(fpd.Message === "OTP send successfully");
          if (fpd.Message === "OTP send successfully")
            toast.success(fpd.Message);

          if ("Error" in fpd) {
            alert(fpd.Error);
          } else {
            let e = fpd.email;
            console.log("forgettttttt" + JSON.stringify(e));
            localStorage.setItem("email", e);

            navigation("/otpverify", {
              state: { email: email },
            });
            /*  navigation("/setNewPassword", {
                state: { op: password, un: username,app:application }, */
          }
        },
        (error) => {
          navigation("/error");
          console.log(error);
        }
      );
    }
  };
  const handleResendOtp = () => {
    //  GenerateNewpinfn();
    sendOTPcallfn();
    setTime(60);
  };
  useEffect(() => {
    if (time > 0) {
      const interval = setInterval(() => {
        setTime(time - 1);
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [time]);

  return (
    <div>
      <div className="d-flex justify-content-center ">
        <Card className="bg-auth-1 mt-5">
          <CardHeader className="border-0">
            <h4 className="text-center mt-1">OTP Verification</h4>
            <hr className="my-2" />
          </CardHeader>

          <CardBody className="text-center py-0">
            <div className="d-flex ps-2 mb-1 mt-2">
              <OTPInput
                value={otpContent}
                onChange={setOtpContent}
                autoFocus={true}
                OTPLength={4}
                otpType="number"
                inputStyles={{
                  width: "50px",
                  height: "50px",
                  fontSize: "22px",
                  fontWeight: "500",
                  marginRight: "10px",
                }}
              />
            </div>
            {time === 0 ? (
              <div className="my-2">
                Didn't receive otp?{" "}
                <span
                  className="text-warning underline cursor-pointer"
                  onClick={handleResendOtp}
                >
                  Resend OTP
                </span>
              </div>
            ) : (
              <p className="my-2">Time left: {time} seconds</p>
            )}

            <div className="form-group mt-4">
              <button
                className="btn btn-warning btn-width"
                onClick={(e) => GenerateNewpinfn(e.target.value)}
              >
                Verify
              </button>
            </div>
            <hr className="mt-3 mb-2" />
          </CardBody>

          <CardFooter className="border-0 pt-0">
            <div className="d-flex align-items-center gap-1 px-1 cursor-pointer">
              <i className="fa fa-arrow-left"></i>
              <span onClick={forgetpasscall}>Back</span>
            </div>
          </CardFooter>
        </Card>
      </div>
    </div>
  );
};
export default OtpVerification;
