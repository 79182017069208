import React, { useState, useEffect, useContext } from "react";
import "../css/PrivateWrapper.css";
import { Outlet, useNavigate } from "react-router-dom";
import LeftNavigation_Component from "./LeftNavigation_Component";
import Modal from "react-modal";
import { AutoPortalContext } from "../Context";
import { Button, Modal as Mod } from "react-bootstrap";
import UserNotificationCompo from "./UserNotificationCompo";
import axios from "axios";
import img from "../ppp.png";
import Avatar from "react-avatar-edit";
import ImpersonateCompo from "./ImpersonateCompo";
import LogoutModal from "./LogoutModal";
import CommonModal from "./CommonModal";

const UserWrapper = () => {
  let subtitle;
  const {
    token,
    loca,
    appName,
    flag,
    setFlag,
    setShowContainer,
    username,
    userDetails,
    impersonate,
    setheadHeight,
    msg,
    setCnt,
    cnt,
    getVerify,
  } = useContext(AutoPortalContext);
  const navigation = useNavigate();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  // const [msg, setMsg] = useState([]);
  // const [cnt, setCnt] = useState("");
  const [showEye, setShowEye] = useState(false);
  const [pfile, setProfile] = useState([]);
  const [imageCrop, setImageCrop] = useState(false);
  const [pview, setPview] = useState(false);
  const profileFinal = pfile.map((item) => item.pview);

  const onClose = () => {
    setPview(null);
  };

  const onCrop = (view) => {
    console.log(view);
    setPview(view);
  };

  const saveCropImage = () => {
    setProfile([...pfile, { pview }]);
    setImageCrop(false);
  };

  const logOut = () => {
    localStorage.removeItem("autoToken");
    navigation("/login");
  };

  const Home = () => {
    navigation("/user/dashboard");
  };

  const opennav = () => {
    document.getElementById("mySidebar").style.width = "100%";
    document.getElementById("mySidebar").style.marginLeft = "0";
    // window.scroll({ top: 0, behavior: "smooth" });
    setFlag(true);
  };

  const openModal = () => {
    console.log("open");
    setIsOpen(true);
  };

  const cancelNotification = () => {
    // console.log("open");
    setShowNotification(false);
  };

  const afterOpenModal = () => {
    // references are now sync'd and can be accessed.
    subtitle.style.textAlign = "center";
    subtitle.style.color = "#FFFFFF";
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const cancelImpersonation = () => {
    setShowEye(false);
  };

  const eyefn = () => {
    // var ImpJs = {role:impRole , localuser:impName}
    console.log("aaaaasss" + token);
    axios
      .get(loca + "/lom/set/impersonation/end", {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let res = resp.data;
          console.log(res);
          getVerify();
        },
        (error) => {
          navigation("/error");
          console.log(error);
          //  setLoading(false)
        }
      );
  };

  useEffect(() => {
    let h = document.getElementById("topbar")?.getBoundingClientRect().height;
    if (h > 10) {
      setheadHeight(h);
    }
  }, []);

  const navModals = (show, hide, title, body, footer) => {
    return (
      <CommonModal
        show={show}
        hide={hide}
        title={title}
        body={body}
        footer={footer}
      />
    );
  };

  return (
    <div>
      <div id="topbar" className="mainTopBar">
        <div className="disfl mainbgcolor gutter">
          {!flag && (
            <div className="gutter">
              <div className="arrow_icon no_pad">
                <span>
                  <i
                    className="primary_clr backic fa fa-arrow-right  "
                    
                    onClick={opennav}
                  ></i>
                </span>
              </div>
            </div>
          )}
          <div className="fl1 no_pad disfl ">
            <div className="marleft10 marright10">
              <div className="homeicon">
                <i
                  className="fa fa-home home_top"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Home"
                  
                  onClick={() => Home()}
                ></i>
              </div>
            </div>
            {/* </div>
          <div> */}
            <span className="text-light u-name text-capitalize">{appName}</span>
          </div>
          <div className="fl1 no_pad">
            <div className="rig">
              {impersonate ? (
                <span className="u-name text-capitalize">{userDetails.name}</span>
              ) : (
                <span className="u-name ">{userDetails.name}</span>
              )}
              <span className="usericon ">
                <i
                  className="fa fa-user-circle-o"
                  
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Profile"
                  onClick={() => openModal()}
                ></i>
              </span>

              <span className="usericon ">
                <i
                  className="fa fa-bell"
                  data-toggle="tooltip"
                  data-placement="bottom"
                  title="Notification"
                  
                  onClick={() => setShowNotification(true)}
                >
                  {cnt > 0 && <span className="span-cnt">{cnt}</span>}
                </i>
              </span>
              {impersonate ? (
                <span className="usericon ">
                  <i
                    className="fa fa-eye-slash"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Impersonate"
                    
                    onClick={() => eyefn()}
                  >
                    {cnt > 0 && <span className="span-cnt">{cnt}</span>}{" "}
                  </i>
                </span>
              ) : (
                /*    <span className="usericon ">
                     <i
                       className="fa fa-eye"
                       data-toggle="tooltip"
                       data-placement="bottom"
                       title="Impersonate"
                       
                       onClick={() => openImpersonation()}
                     >
                       {cnt > 0 && <span className="span-cnt">{cnt}</span>}{" "}
                     </i>
                   </span> */
                <div></div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className="row fr"
        onClick={() => {
          setShowContainer(false);
        }}
      >
        <div id="windowscreen" className={flag ? "cl_small" : "cl_close"}>
          <LeftNavigation_Component />
        </div>

        <div className={flag ? "cl_big" : "cl_bigClose"}>
          {/*     <ToastContainer toastStyle={{ marginTop: "10vh" }} autoClose={1800} /> */}
          <Outlet />
        </div>
      </div>

      <div>
        {modalIsOpen === true && (
          <LogoutModal
            modalIsOpen={modalIsOpen}
            closeModal={closeModal}
            username={userDetails.userId}
          />
        )}
        {navModals(
          showNotification,
          cancelNotification,
          "Notification",
          <UserNotificationCompo
            setCnt={setCnt}
            msg={msg}
            token={token}
            loca={loca}
          ></UserNotificationCompo>,
          <Button variant="secondary" onClick={cancelNotification}>
            Close
          </Button>
        )}
        {navModals(
          showEye,
          cancelImpersonation,
          "Impersonation",
          <ImpersonateCompo cancel={cancelImpersonation} />,
          <button
            className="btn btn-danger"
            variant="secondary"
            onClick={cancelImpersonation}
          >
            Close
          </button>
        )}

        {/* <Mod show={showNotification} onHide={cancelNotification} size="xl">
          <Mod.Header closeButton>
            <Mod.Title>Notification</Mod.Title>
          </Mod.Header>
          <Mod.Body>
            <UserNotificationCompo
              setCnt={setCnt}
              msg={msg}
              token={token}
              loca={loca}
            ></UserNotificationCompo>
          </Mod.Body>
          <Mod.Footer>
            <Button variant="secondary" onClick={cancelNotification}>
              Close
            </Button>
          </Mod.Footer>
        </Mod>
        <Mod show={showEye} onHide={cancelImpersonation} size="xl">
          <Mod.Header closeButton>
            <Mod.Title>Impersonation</Mod.Title>
          </Mod.Header>
          <Mod.Body>
            <ImpersonateCompo cancel={cancelImpersonation} />
          </Mod.Body>
          <Mod.Footer>
            <button
              className="btn btn-danger"
              variant="secondary"
              onClick={cancelImpersonation}
            >
              Close
            </button>
          </Mod.Footer>
        </Mod> */}
      </div>
    </div>
  );
};
const customStyles = {
  content: {
    width: "320px",
    height: "350px",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "11px",
    background: "#010154",
  },
};
export default UserWrapper;
