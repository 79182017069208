import React, { Component, useState } from "react";
import { AutoPortalContext } from "../Context";

export class ApUser extends Component {

  state = {
    sr: null,
    userDetails:{},
  };



  constructor(userDetails, setRecord) {
    super(userDetails);
    this.state.userDetails = userDetails;
    this.state.sr = setRecord;
  }

  getUserId() {
    let userid=this.state.userDetails.userId;
    return userid;
  }

  getId() {
    let id=this.state.userDetails.id;
    return id;
  }

  getName() {
    let name=this.state.userDetails.name;
    return name;
  }
  getUserName() {
    let username=this.state.userDetails.username;
    return username;
  }

  getLabel() {
    let label=this.state.userDetails.label;
    return label;
  }

  getUniId() {
    let uniId=this.state.userDetails.uniId;
    return uniId;
  }

  render() {
    return <div>ApUser</div>;
  }
}

export default ApUser;
