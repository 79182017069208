import React, { useEffect, useRef, useState } from "react";
import { AutoPortalContext } from "../Context";
import {
  Outlet,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
// import { isMobile, isTablet } from "react-device-detect";
import axios from "axios";
import { toast } from "react-toastify";
import WorkInProgress from "./WorkInProgress";
import { AppProperties } from "../AppProperties";

export default function WebWrapper() {
  // const myref = React.createRef();
  // const [showlogin, setShowLogin] = useState(true);
  // const [showRegister, setShowRegister] = useState(false);

  const isMobile=window.screen.width<700;
  const isTablet=window.screen.width<1000 && window.screen.width>700;


  const [loading, setLoading] = useState(true);
  const [oneLoading] = useState(false);
  const [mainLoading, setMainLoading] = useState(false);
  const [token, setToken] = useState("");

  const [formtable, setFormTable] = useState("");
  const [formid, setFormId] = useState("");
  const [formtype, setFormType] = useState("");
  const [userDetails, setUserDetails] = useState(null);
  const [flag, setFlag] = useState(isMobile || isTablet ? false : true);
  const [reLoadLeft, setReloadLeft] = useState(true);
  const [leftNavData, setLeftNavData] = useState([]);
  // const [showFormCompo, setShowFormCompo] = useState(false);
  const [showContainer, setShowContainer] = useState(false);
  const [username, setUserName] = useState("");
  const [u_name, setU_Name] = useState("");
  const [impersonate, setImpersonate] = useState(false);
  const [lst, setLst] = useState([]);
  const [appList, setAppList] = useState([]);
  const [msg, setMsg] = useState([]);
  const [showAll, setShowAll] = useState("false");
  const [appName, setAppName] = useState("");
  const [appCode, setAppCode] = useState("");
  const [headHeight, setheadHeight] = useState(0);
  const [formHeight, setFormHeight] = useState(0);
  const [cnt, setCnt] = useState("");
  const locData = useLocation();

  // const loca = "http://192.168.0.132:8083";
  const loca = AppProperties.loca;
  // const [loca, setLoca] = useState("http://localhost:8083");
  const imageloca = AppProperties.imageloca;
  // const [loca, setLoca] = useState("http://apis.autoportal.co.in");
  const [adminVerified, setAdminVerified] = useState(false);
  const [user, setUser] = useState("");

  const oneRender = useRef(true);
  const [contextMenu, setContextMenu] = useState([]);
  const [labelContext, setLabelContext] = useState([]);
  const [mainError, setMainError] = useState(false);
  const [callType, setCallType] = useState({});
  const navigate = useNavigate();
  const listAccess = useRef([]);
  const newAccess = useRef([]);

  const getVerify = async () => {
    console.log("getVerify", locData.pathname);
    setLoading(true);
    let tkn = localStorage.getItem("autoToken");
    // let impr = localStorage.getItem("impersonate");
    // let impt;
    // if (impr !== "" && impr !== null) {
    //   setImpersonate(true);
    //   impt = true;
    // } else {
    //   setImpersonate(false);
    //   impt = false;
    // }
    if (tkn !== "" && tkn !== null) {
      await axios
        .get(loca + "/verify", {
          headers: {
            authorization: "Bearer " + tkn,
          },
        })
        .then(
          (res) => {
            var rsp = res.data[0].response;
            console.log(rsp);
            if (rsp === "verified") {
              setAdminVerified(true);
              setToken(tkn);
              setUserName(res.data[4].userId);
              setU_Name(res.data[2].name);
              setShowAll(res.data[6].showAll);
              // navigate("/dashboard")
              getUserDetails(tkn, null);
            } else if (rsp === "refereshed") {
              localStorage.setItem("autoToken", res.data[1].token);
              setAdminVerified(true);
              setToken(res.data[1].token);
              setShowAll(res.data[6].showAll);
              // navigate("/dashboard")
              getUserDetails(tkn, null);
            } else if (rsp === "not_verified" || rsp === "failed") {
              localStorage.setItem("autoToken", "");
              localStorage.clear();
              setLoading(false);
              navigate("/login");
            }
          },
          (error) => {
            if (error.response) {
              if (error.response.status === 401) {
                localStorage.setItem("autoToken", "");
                // setShowLogin(true);
                setLoading(false);
              }
            } else if (error.request) {
              setLoading(false);
            } else {
            }
          }
        )
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              localStorage.setItem("autoToken", "");
              // setShowLogin(true);
              setLoading(false);
            }
          }
        });
    } else {
      navigate("/login");
      setLoading(false);
      return false;
    }
  };

  const getUserDetails = (tkn, nav) => {
    setLoading(true);

    axios
      .get(loca + "/lom/get/userdetails", {
        headers: {
          authorization: "Bearer " + tkn,
        },
      })
      .then(
        (resp) => {
          let result = resp.data;
          console.log(result);
          localStorage.setItem(
            "userDetails",
            JSON.stringify(result.userdetails)
          );
          console.log(result.userdetails.isadmin);
          let user = "";
          console.log("zzz " + impersonate);
          if (
            result.userdetails.isadmin === "true" &&
            result.userdetails.isImpersonated === "false"
          ) {
            setAdminVerified(true);
            setUser("");
          } else {
            setUser("/user");
            user = "/user";
          }
          if (result.userdetails.isImpersonated === "true") {
            setImpersonate(true);
          } else {
            setImpersonate(false);
          }
          setUserDetails({ ...result.userdetails });
          getInitialData(tkn, result.userdetails, nav, user);
        },
        (error) => {
          console.log(error);
          let str = error.response.data?.cause;
          // toast.error(str.split(":")[1]);
          setLoading(false);
          localStorage.setItem("autoToken", "");
        }
      );
  };

  const getInitialData = (tkn, ud, nav, user) => {
    console.log("getInitialData", locData.pathname);
    axios
      .get(loca + "/lom/get/Notification", {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + tkn,
        },
      })
      .then(
        (resp) => {
          let result = resp.data;
          console.log(resp.data);
          // let result = JSON.parse(resp.data)
          setMsg(result.notification.messages);
          setCnt(result.notification.count);
          // setTimeout(() => {
          loadleftnav(tkn, nav, ud, user);
          // }, 500);
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const loadleftnav = (tkn, navigation, ud, user) => {
    console.log("loadleftnav", locData.pathname);
    axios
      .get(loca + "/lom/get/modules", {
        headers: {
          authorization: "Bearer " + tkn,
        },
      })
      .then((res) => {
        const modul = res.data;
        console.log(modul);
        if ("Error" in modul) {
        } else {
          var lstt = [];
          let auto = [];
          let mar = [];
          console.log(modul);
          let la = [];
          let na = [];
          if ("MultiApplicatioMenu" in modul) {
            console.log(modul.MultiApplicatioMenu.length);
            for (let n = 0; n < modul.MultiApplicatioMenu.length; n++) {
              let module = modul.MultiApplicatioMenu[n];
              for (
                var k = 0;
                k < module.applicatioMenu[2].records.length;
                k++
              ) {
                for (
                  var j = 0;
                  j < module.applicatioMenu[2].records[k].section.length;
                  j++
                ) {
                  for (
                    let o = 0;
                    o <
                    module.applicatioMenu[2].records[k].section[j].module
                      .length;
                    o++
                  ) {
                    if (
                      module.applicatioMenu[2].records[k].section[j].module[o]
                        .type === "new"
                    ) {
                      na.push(
                        module.applicatioMenu[2].records[k].section[j].module[o]
                          .form_table
                      );
                    } else if (
                      module.applicatioMenu[2].records[k].section[j].module[o]
                        .type === "list"
                    ) {
                      la.push(
                        module.applicatioMenu[2].records[k].section[j].module[o]
                          .form_table
                      );
                    }
                  }
                  module.applicatioMenu[2].records[k].section[j].ref = true;
                }
              }

              for (
                var i = 0;
                i < module.applicatioMenu[2].records.length;
                i++
              ) {
                if (
                  module.applicatioMenu[2].records[i].application_menu ===
                  "Webservice"
                ) {
                  console.log(module.applicatioMenu[2].records[i].section);
                }
                if (n === 0) {
                  auto.push({
                    application_menu:
                      module.applicatioMenu[2].records[i].application_menu,
                    section: module.applicatioMenu[2].records[i].section,
                    menu_order: module.applicatioMenu[2].records[i].menu_order,
                    ref: true,
                    ref_all: true,
                  });
                } else {
                  mar.push({
                    application_menu:
                      module.applicatioMenu[2].records[i].application_menu,
                    section: module.applicatioMenu[2].records[i].section,
                    menu_order: module.applicatioMenu[2].records[i].menu_order,
                    ref: true,
                    ref_all: true,
                  });
                }
              }
              listAccess.current = la;
              newAccess.current = na;
            }
            if (auto.length > 0) {
              lstt.push({
                parentApplication: "Autoportal",
                application:
                  modul.MultiApplicatioMenu[0].applicatioMenu[0].application
                    .label,
                app_ref: true,
                appMenus: auto,
              });
            }
            if (mar.length > 0) {
              lstt.push({
                parentApplication: "Marketplace",
                application:
                  modul.MultiApplicatioMenu[1].applicatioMenu[0].application
                    .label,
                app_ref: true,
                appMenus: mar,
              });
            }
            console.log(lstt);
            setAppName(
              modul.MultiApplicatioMenu[0].applicatioMenu[0].application.name
            );
            setAppCode(
              modul.MultiApplicatioMenu[0].applicatioMenu[0].application.appCode
            );
            getContextMenu(tkn);
            setLeftNavData(lstt);
            setMainLoading(true);
            console.log(lstt);
            if ("landingPref" in module) {
              sendLandingPref(module.landingPref, ud, user);
            } else {
              sendLandingPref({ type: null }, ud, user);
            }
          }
        }
      });
  };

  const sendLandingPref = (lannPref, ud, user) => {
    console.log("sendLandingPref");
    setLoading(false);
    var lan_type = lannPref.type;
    let pref = "";
    if (lan_type === "dashboard") {
      pref = "/dashboard";
    } else if (lan_type === "report") {
      pref = "/report";
    } else if (lan_type === "checklist") {
      pref = "/checklicompo";
    } else {
      pref = "/dashboard";
    }
    let url = loca + locData.pathname;
    console.log(url, user, url.includes(loca + "/user"));
    if (locData.pathname === "/" || locData.pathname === "/login") {
      navigate(user + pref);
      if (ud.OS !== "null" && isMobile) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ landing: user + pref })
        );
      }
    }
    // works if impersonation changes
    else if (url.includes(loca + "/user") && user == "") {
      navigate(pref);
    } else if (!url.includes(loca + "/user") && user == "/user") {
      navigate(user + pref);
    }
  };

  const getContextMenu = (tkn) => {
    console.log("getContextMenu", locData.pathname);
    axios
      .get(loca + "/fetch/contextMenu", {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + tkn,
        },
      })
      .then(
        (resp) => {
          console.log(resp.data);
          setContextMenu(resp.data);
          getLabelContext(tkn);
          console.log(resp.data);
        },
        (error) => {
          console.log(error);
          console.log("client error");
        }
      );
  };

  const getLabelContext = (tkn) => {
    console.log("getLabelContext", locData.pathname);
    axios
      .get(loca + "/fetch/labelContext", {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + tkn,
        },
      })
      .then(
        (resp) => {
          setLabelContext(resp.data);
          console.log(resp.data);
          setLoading(false);
        },
        (error) => {
          console.log(error);
        }
      );
  };


  // for landing components

  const getVerifyLanding = async () => {
    console.log("getVerify", locData.pathname);
    setLoading(true);
    let tkn = localStorage.getItem("autoToken");
    let impr = localStorage.getItem("impersonate");
    let impt;
    if (impr !== "" && impr !== null) {
      setImpersonate(true);
      impt = true;
    } else {
      setImpersonate(false);
      impt = false;
    }
    if (tkn !== "" && tkn !== null) {
      await axios
        .get(loca + "/verify", {
          headers: {
            authorization: "Bearer " + tkn,
          },
        })
        .then(
          (res) => {
            var rsp = res.data[0].response;
            console.log(rsp);
            if (rsp === "verified") {
              setAdminVerified(true);
              setToken(tkn);
              setUserName(res.data[4].userId);
              setU_Name(res.data[2].name);
              setShowAll(res.data[6].showAll);
              // navigate("/dashboard")
              getUserDetailsLanding(tkn, null);
            } else if (rsp === "refereshed") {
              localStorage.setItem("autoToken", res.data[1].token);
              setAdminVerified(true);
              setToken(res.data[1].token);
              setShowAll(res.data[6].showAll);
              // navigate("/dashboard")
              getUserDetailsLanding(tkn, null);
            } else if (rsp === "not_verified" || rsp === "failed") {
              localStorage.setItem("autoToken", "");
              localStorage.clear();
              setLoading(false);
              // navigate("/login");
            }
          },
          (error) => {
            if (error.response) {
              if (error.response.status === 401) {
                localStorage.setItem("autoToken", "");
                // setShowLogin(true);
                setLoading(false);
              }
            } else if (error.request) {
              setLoading(false);
            } else {
            }
          }
        )
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              localStorage.setItem("autoToken", "");
              // setShowLogin(true);
              setLoading(false);
            }
          }
        });
    } else {
      // navigate("/login");
      setLoading(false);
      return false;
    }
  };

  const getUserDetailsLanding = (tkn, nav) => {
    setLoading(true);

    axios
      .get(loca + "/lom/get/userdetails", {
        headers: {
          authorization: "Bearer " + tkn,
        },
      })
      .then(
        (resp) => {
          let result = resp.data;
          console.log(result);
          localStorage.setItem(
            "userDetails",
            JSON.stringify(result.userdetails)
          );
          console.log(result.userdetails.isadmin);
          let user = "";
          console.log("zzz " + impersonate);
          if (
            result.userdetails.isadmin === "true" &&
            result.userdetails.isImpersonated === "false"
          ) {
            setAdminVerified(true);
            setUser("");
          } else {
            setUser("/user");
            user = "/user";
          }
          if (result.userdetails.isImpersonated === "true") {
            setImpersonate(true);
          } else {
            setImpersonate(false);
          }
          setUserDetails({ ...result.userdetails });
          setLoading(false);
        },
        (error) => {
          console.log(error);
          setLoading(false);
          localStorage.setItem("autoToken", "");
        }
      );
  };



  useEffect(() => {
    if (token === "") {
      let body = document.getElementsByTagName("body");
      body[0].style.overflow = "auto";
      console.log(body[0].style);
    }
  }, [token]);
  const detectEdgeBrowser = () => {
    const userAgent = window.navigator.userAgent;
    console.log("Browser!!", userAgent);
    if (userAgent.indexOf("Edge") > -1 || userAgent.indexOf("Edg") > -1) {
      document.body.classList.add("edge-browser");
    }
  };

  useEffect(() => {
    if (oneRender.current) {
      detectEdgeBrowser();
      if (locData.pathname === "/landing") {
        getVerifyLanding()
      } else {
        getVerify();
      }
    } else {
      oneRender.current = false;
    }
  }, []);
  useEffect(() => {
    console.log(loading);
  }, [loading]);

  if (!loading) {
    return (
      <AutoPortalContext.Provider
        value={{
          token,
          loca,
          setToken,
          formtable,
          setFormTable,
          formid,
          setFormId,
          formtype,
          setFormType,
          getUserDetails,
          userDetails,
          flag,
          setFlag,
          showContainer,
          setShowContainer,
          username,
          setUserName,
          u_name,
          setU_Name,
          lst,
          setLst,
          impersonate,
          setImpersonate,
          getVerify,
          reLoadLeft,
          setReloadLeft,
          msg,
          cnt,
          setCnt,
          setMainLoading,
          mainLoading,
          oneLoading,
          leftNavData,
          loadleftnav,
          showAll,
          setShowAll,
          appList,
          imageloca,
          user,
          contextMenu,
          labelContext,
          appName,
          appCode,
          setAppName,
          setheadHeight,
          headHeight,
          mainError,
          setMainError,
          listAccess,
          newAccess,
          callType,
          setCallType,
          formHeight,
          setFormHeight,
          adminVerified,
          setAdminVerified,
          isMobile
        }}
      >
        <Outlet />
      </AutoPortalContext.Provider>
    );
  } else {
    <div>
      <WorkInProgress />
    </div>;
  }
}
