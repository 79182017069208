import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AutoPortalContext } from "../Context";
import "../css/UpdateJson.css";
import { createSearchParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const DashBoardSelectCompo = () => {
  const { token, loca, user } = useContext(AutoPortalContext);
  const [srcRecord, setSrcRecord] = useState([]);
  const record = useRef(srcRecord);
  const navigation = useNavigate();
  const getInitial = () => {
    axios
      .get(loca + "/lom/get/dashboard", {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let res = resp.data;
          console.log(res);
          setSrcRecord(res.record);
          record.current = res.record;
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const setRec = (ind) => {
    let rec = srcRecord;
    console.log(ind);
    if (rec[ind].selected === "true") {
      return;
    }

    let js = { id: rec[ind].id };
    console.log("test", js);
    axios
      .post(loca + "/lom/set/dashboard", js, {
        headers: {
          "Content-Type": "application/json",
          authorization: "Bearer " + token,
        },
      })
      .then(
        (resp) => {
          let res = resp.data;
          console.log(res);
          if ("Error" in res) {
            console.log(res.Error);
            toast.error(res.Error);
          } else {
            getInitial();
            toast.success(res.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const unSelect = () => {
    let rec = srcRecord;
    // check wheter any srcRecord is selected or not
    let isSelected = rec.every((e) => e.selected === "false");
    console.log(isSelected);
    if (!isSelected) {
      axios
        .get(loca + "/lom/unselect/dashboard", {
          headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token,
          },
        })
        .then(
          (resp) => {
            let res = resp.data;
            console.log(res);
            if ("Error" in res) {
              console.log(res.Error);
              toast.error(res.Error);
            } else {
              getInitial();
              toast.success(res.message);
            }
          },
          (error) => {
            console.log(error);
          }
        );
    }
  };

  const searchDash = (nam) => {
    let arr = [];
    if (nam.length > 2) {
      for (let i = 0; i < srcRecord.length; i++) {
        let includes = srcRecord[i].name.includes(nam.toLowerCase());
        if (includes) {
          arr.push(srcRecord[i]);
        }
      }
    } else {
      arr = record.current;
    }

    setSrcRecord([...arr]);
  };

  const goToForm = () => {
    navigation({
      pathname: user + "/form",
      search: createSearchParams({
        parentApplication: "autoportal",
        tableName: "dash_board",
        rty: "new",
      }).toString(),
    });
  };

  const goToList = () => {
    navigation({
      pathname: user + "/list",
      search: createSearchParams({
        parentApplication: "autoportal",
        tableName: "dash_board",
      }).toString(),
    });
  };

  useEffect(() => {
    getInitial();
  }, []);

  return (
    <div className="container justify-content-center">
      <h1 className="text-center underline">DashBoard Select</h1>
      {srcRecord.length > 0 && (
        <div>
          <div className="text-center ">
            <input
              style={{ width: "60%" }}
              className=""
              type="search"
              placeholder="Search"
              aria-label="Search"
              onChange={(e) => {
                searchDash(e.target.value);
              }}
            ></input>
          </div>

          {srcRecord.map((obj, ind) => (
            <div
              key={ind}
              tabIndex={1}
              onClick={() => setRec(ind)}
              style={{ width: "60%" }}
              className=" container d-flex justify-content-between align-items-center my-1 item-list"
            >
              <span className="fs-4 ">{obj.name}</span>
              {obj.selected === "true" && (
                <span>
                  <i
                    style={{ color: "green" }}
                    className="fa fa-check fs-4"
                  ></i>
                </span>
              )}
            </div>
          ))}
        </div>
      )}
      {srcRecord.length === 0 && (
        <div className="text-center my-3">
          <h3>No Dashboards Found...</h3>
          <h5>Create New DashBoard by Clicking Create New Button</h5>
        </div>
      )}
      <div className="d-flex flex-row justify-content-center mx-1 ">
        <button
          onClick={() => {
            unSelect();
          }}
          className="btn btn-primary m-1"
        >
          Unselect
        </button>
        <button
          onClick={() => {
            goToForm();
          }}
          className="btn btn-primary m-1"
        >
          Create New
        </button>
        <button
          onClick={() => {
            goToList();
          }}
          className="btn btn-primary m-1"
        >
          See Records
        </button>
      </div>
    </div>
  );
};

export default DashBoardSelectCompo;
