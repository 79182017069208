import axios from "axios";
import React, { Component } from "react";
import { AutoPortalContext } from "../Context";
import { AppProperties } from "../AppProperties";

export default class RestApi extends Component {
  state = {
    url: "",
    method: "",
    header: null,
    payLoad: null,
    response: null,
    error: null,
    loca: AppProperties.loca,
    token: "",
  };

  constructor(url = null) {
    super();
    if (url != null) {
      this.state.url = url;
    }
    this.state.token = localStorage.getItem("autoToken");
  }

  getUrl() {
    return this.state.url;
  }

  setUrl(url) {
    this.state.url = url;
  }

 
  async call() {
    console.log(this.state.url,this.state.token);
    
   let tt= await axios
      .get(this.state.loca + "/api/" + this.state.url, {
        headers: {
          authorization: "Bearer " + this.state.token,
          "Content-Type": "application/json",
        },
      })
      .then(
        (res) => {
          console.log(res.data);
          if ("Error" in res.data) {
            this.state.error = res.data.Error;
          } else {
            this.state.response = res.data;
          }
          return;
        },
        (error) => {
          console.log(error);

          this.state.error = error;
          return;
        }
      );
  }

  async post() {
    console.log(this.state.url,this.state.token);
    
   let tt= await axios
      .post(this.state.loca + "/api/" + this.state.url, this.state.payLoad,{
        headers: {
          authorization: "Bearer " + this.state.token,
          "Content-Type": "application/json",
        },
      })
      .then(
        (res) => {
          console.log(res.data);
          if ("Error" in res.data) {
            this.state.error = res.data.Error;
          } else {
            this.state.response = res.data;
          }
          return;
        },
        (error) => {
          console.log(error);

          this.state.error = error;
          return;
        }
      );
  }

  getResponse() {
    console.log("sending resp" ,this.state.response);
    
    return this.state.response;
  }

  getError() {
    return this.state.error;
  }

  setBody(payLoad) {
     this.state.payLoad=payLoad;
  }

  render() {
    return <div> RestApi </div>;
  }
}
